import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { tradingGatewaysHeader } from '../../../data/placeholderData/allOrderBooksData';
// Atoms
import ExchangeStatus from '../../Atoms/ExchangeStatus';
import {
	LeftControls, RightControls, TableCommandsWrapper, TableWrapper,
} from '../Table/Atoms/TableAtoms';
import { GrayControlIcon } from '../../Atoms/ControlIcons';
import { MediumVerticalDivider } from '../../Atoms/Divider';
// Molecules
import SearchFiled from '../../Molecules/Input/SearchField/SearchField';
// Organisms
import TableView from '../Table/TableView';

// Hooks
import useSearch from '../../Hooks/useSearch';
import useSortBy from '../../Hooks/useSortBy';
import useSelect from '../../Hooks/useSelect';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';
import { deleteTradingGateway, fetchTradingGateways } from '../../../data/tradingGateways/TradingGatewaysActions';
import { toggleAddTradingGatewayModal, toggleEditTradingGatewayModal, toggleModal } from '../../../data/ui/UIActions';
import AddTradingGatewayModal from '../Modals/AddTradingGatewayModal';
import EditTradingGatewayModal from '../Modals/EditTradingGatewayModal';
import {
	getActiveExchange,
	getActiveExchangeTradingGateways,
} from '../../../data/exchanges/selectors';
import { showError } from '../../../lib/helpers';
import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal';
import { MODALS } from '../../../lib/constants/General';

function TradingGatewaysTable(props) {
	const tradingGateways = useSelector(state => getActiveExchangeTradingGateways(state));
	const exchangeStatus = useSelector(state => getActiveExchange(state)?.get('status'));
	// const fixVersion = useSelector(state => getActiveExchangeFixType(state));

	const [tableRows, setTableRows] = React.useState([]);
	const [tableHeader, setTableHeader] = React.useState([...tradingGatewaysHeader]);
	const initialRows = useMemo(() => tradingGateways.toList().toJS(), [tradingGateways]);
	const dispatch = useDispatch();
	const { match } = props;

	// @NOTE Table specific logic for mapping data to table rows
	const getDataToRender = React.useCallback(r => r.map((row, i) => ({
		data: [
				row?.name || '',
				row?.port || '',
				row?.fixSessions?.length || '',
				row?.activeSessions || 0,
				{
					render: () => <ExchangeStatus status={row.status} />,
					accessor: row.status,
				},
		],
		metaData: {
			selected: false,
			key: row.trGwyId,
			index: i,
		},
	})), []);

	useEffect(() => {
		fetchTradingGateways(match.params.slug)(dispatch);
	}, [dispatch, match.params.slug]);

	useEffect(() => {
		setTableRows(initialRows);
	}, [initialRows, tradingGateways]);

	// Getting data


	const { searchInputValue, setSearchInputValue } = useSearch(initialRows, setTableRows, tableHeader);
	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, tableRows);
	const { selectRowCallback, handleSelectAll, allSelected } = useSelect(dataToRender, setDataToRender);

	const selectedTradingGatewaysIds = useMemo(() => dataToRender
		.filter(row => row.metaData.selected)
		.map((item => item.metaData.key)), [dataToRender]);

	const handleDeleteTradingGateway = useCallback(() => {
		dispatch(toggleModal(MODALS.DELETE_CONFIRMATION_MODAL, true, { title: 'Delete Trading Gateway(s)', message: 'Please confirm that you would like to delete the selected trading gateway(s)' }));
	}, [dispatch]);

	const handleHeaderVisibilityChange = useCallback((header) => {
		setTableHeader((prevState) => {
			const newState = [...prevState];
			const index = newState.findIndex(item => item.name === header);
			if (!newState[index].required) {
				newState[index].visible = !newState[index].visible;
			} else {
				showError('This field is required', '');
			}
			return newState;
		});
	}, []);

	const actionButtonsDisabled = !exchangeStatus || (exchangeStatus !== EXCHANGE_STATUS.STOPPED && exchangeStatus !== EXCHANGE_STATUS.ERROR);

	return (
		<TableWrapper className="trading-gateways-tab">
			<TableCommandsWrapper>
				<LeftControls>
					<GrayControlIcon
						className="tr-add-button"
						name="plusCircle"
						onClick={() => dispatch(toggleAddTradingGatewayModal())}
						data-tip="Create trading gateway"
						disabled={actionButtonsDisabled}
					/>
					<MediumVerticalDivider />
					<GrayControlIcon
						name="editPen"
						onClick={() => {
							if (selectedTradingGatewaysIds?.[0]) {
								dispatch(toggleEditTradingGatewayModal(true));
							}
						}}
						data-tip="Edit trading gateway"
						disabled={actionButtonsDisabled || selectedTradingGatewaysIds?.length !== 1}
					/>
					<MediumVerticalDivider />
					<GrayControlIcon
						name="trashCan"
						onClick={() => handleDeleteTradingGateway()}
						data-tip="Delete trading gateways"
						disabled={actionButtonsDisabled}
					/>
				</LeftControls>
				<RightControls>
					<SearchFiled
						value={searchInputValue}
						onChange={setSearchInputValue}
						placeholder="Search"
					/>
				</RightControls>
			</TableCommandsWrapper>
			<TableView
				sortedBy={sortedBy}
				header={tableHeader}
				rows={dataToRender}
				hasSettings
				handleSort={handleSort}
				selectable
				handleHeaderVisibilityChange={handleHeaderVisibilityChange}
				handleSelectAll={handleSelectAll}
				handleSelectRow={selectRowCallback}
				allSelected={allSelected}
			/>
			<AddTradingGatewayModal />
			<EditTradingGatewayModal
				selectedTradingGateway={tradingGateways.find(tr => tr.get('trGwyId') === selectedTradingGatewaysIds?.[0])}
			/>
			<DeleteConfirmationModal onConfirm={() => deleteTradingGateway(selectedTradingGatewaysIds)(dispatch)} />
		</TableWrapper>
	);
}
export default withRouter(TradingGatewaysTable);

TradingGatewaysTable.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
};
