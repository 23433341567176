import {
	fromJS, List, Map, OrderedMap,
} from 'immutable';
import EXCHANGE_TYPES from './ExchangeTypes';
import ORDER_BOOK_TYPES from '../orderBooks/OrderBooksTypes';
import EVENT from '../../lib/constants/Websockets';
import {
	getExchangeData,
	transformExchangeData,
	transformOrderBookListToMap,
	transformTradingGatewaysListToMap,
} from '../../lib/transformApiData';
import WEBSOCKET_TYPES from '../websockets/types';
import { mergeDeepOverwriteLists } from '../../lib/immutableHelpers';
import { stringToNumber } from '../../lib/exchange';
import { COMPONENT_TYPE } from '../../lib/constants/Exchange';

const initialState = fromJS({
	exchanges: OrderedMap(),
	types: [],
	sessions: [],
	activeExchangeInstanceId: '',
	exchangeNames: {},
	exchangesLoading: undefined,
});
export default function ExchangeReducer(state = initialState, action) {
	switch (action.type) {
	case EXCHANGE_TYPES.FETCH: {
		return state.set('exchangesLoading', true);
	}
	case EXCHANGE_TYPES.FETCH_SUCCESS: {
		const {
			exchanges,
		} = action.payload;
		const dataForMerge = exchanges.reduce((acc, value) => acc.set(value.clientExchangeId, Map()
			.set('clientExchangeId', value.clientExchangeId)
			.set('name', value.name)
			.set('description', value.description)
			.set('exchangeType', Map().set('name', value.exchangeType.name))
			.set('displayId', value.displayId)
			.set('machineInstance', Map().set('status', value.machineInstance.status))
			.set('machineInstanceId', value.machineInstanceId)),
		OrderedMap());
		return state.set('exchangesLoading', false).mergeDeepIn(['exchanges'], dataForMerge);
	}
	case EXCHANGE_TYPES.FETCH_FAIL: {
		return state.set('exchangesLoading', false);
	}
	case EXCHANGE_TYPES.FETCH_TYPES_SUCCESS: {
		return state
			.set('types', fromJS(action.payload));
	}
	case EXCHANGE_TYPES.FETCH_SESSIONS_SUCCESS: {
		return state
			.set('sessions', fromJS(action.payload));
	}
	case EXCHANGE_TYPES.FETCH_EXCHANGES_EXTRA_DATA_SUCCESS: {
		const newState = action.payload.reduce((acc, value) => {
			const id = value.clientExchangeId;
			const exchange = state.getIn(['exchanges', Number(id)]);
			if (!exchange) {
				return transformExchangeData(value, acc);
			}

			const { orderBooks, tradingGateways } = value;
			// transform orderBooks list to map
			const orderBooksMap = transformOrderBookListToMap(orderBooks);
			// transform tradingGateways list to map
			const tradingGatewaysMap = transformTradingGatewaysListToMap(tradingGateways);
			const exchangeData = getExchangeData(value);

			const tr = mergeDeepOverwriteLists(state.getIn(['exchanges', id, 'tradingGateways']), tradingGatewaysMap);
			const ob = mergeDeepOverwriteLists(state.getIn(['exchanges', id, 'orderBooks']), orderBooksMap);
			return acc.mergeIn(['exchanges', id], exchangeData)
				.setIn(['exchanges', id, 'orderBooks'], ob)
				.setIn(['exchanges', id, 'tradingGateways'], tr);
		}, state);
		return newState.set('exchangesLoading', false);
	}
	case EXCHANGE_TYPES.DELETE_EXCHANGES_SUCCESS: {
		try {
			return action.payload.deleteIds.reduce((acc, id) => acc.deleteIn(['exchanges', id]), state);
		} catch (e) {
			return state;
		}
	}
	case EXCHANGE_TYPES.SET_ACTIVE_EXCHANGE_INSTANCE: {
		return state.set('activeExchangeInstanceId', action.payload.id);
	}
	case EXCHANGE_TYPES.UPDATE_EXCHANGE_STATUS: {
		const { clientExchangeId, status } = action.payload;
		return state.setIn(['exchanges', Number(clientExchangeId), 'status'], status.status);
	}
	case EXCHANGE_TYPES.FETCH_SINGLE_EXCHANGE_EXTRA_DATA: {
		const response = action.payload;
		const id = Number(response.clientExchangeId);
		const exchange = state.getIn(['exchanges', Number(id)]);
		if (!exchange) {
			return transformExchangeData(response, state);
		}

		const { orderBooks, tradingGateways } = response;
		// transform orderBooks list to map
		const orderBooksMap = transformOrderBookListToMap(orderBooks);
		// transform tradingGateways list to map
		const tradingGatewaysMap = transformTradingGatewaysListToMap(tradingGateways);

		const data = getExchangeData(response);
		// Merge exchange data, orderBooks, tradingGateways
		const tr = mergeDeepOverwriteLists(state.getIn(['exchanges', id, 'tradingGateways']), tradingGatewaysMap);
		const ob = mergeDeepOverwriteLists(state.getIn(['exchanges', id, 'orderBooks']), orderBooksMap);


		return state.mergeIn(['exchanges', id], data)
			.setIn(['exchanges', id, 'orderBooks'], ob)
			.setIn(['exchanges', id, 'tradingGateways'], tr);
	}
	case EVENT.ExchangeStatusReply: {
		const id = Number(state.get('activeExchangeInstanceId'));
		const exchange = state.getIn(['exchanges', id]);
		if (!exchange) {
			return state;
		}
		const tradingGateways = action.payload.components.filter(c => c.type === COMPONENT_TYPE.TRADING_GATEWAY);

		const updatedTrStatus = tradingGateways.reduce((acc, value) => {
			const foundTrGw = state.getIn(['exchanges', id, 'tradingGateways']).find(val => val.get('displayId') === value.id);
			if (foundTrGw) {
				return acc.setIn(['exchanges', id, 'tradingGateways', foundTrGw.get('trGwyId'), 'status'], value.status);
			}
			return acc;
		}, state);

		return updatedTrStatus.setIn(['exchanges', id, 'exchangeStartedTime'], action.payload.exchangeStartedTime)
			.setIn(['exchanges', id, 'status'], action.payload.status)
			.setIn(['exchanges', id, 'coreComponents'], fromJS(action.payload.components.filter(c => c.type === COMPONENT_TYPE.CORE)))
			.setIn(['exchanges', id, 'openedComponents'], action.payload.openedComponents)
			.setIn(['exchanges', id, 'totalComponents'], action.payload.totalComponents);
	}
	case EVENT.OpenExchangeReply: {
		const id = Number(state.get('activeExchangeInstanceId'));
		return state.setIn(['exchanges', id, 'status'], action.payload.status);
	}
	case EVENT.CloseExchangeReply: {
		const id = Number(state.get('activeExchangeInstanceId'));
		return state.setIn(['exchanges', id, 'status'], action.payload.status);
	}
	case EXCHANGE_TYPES.FETCH_ORDER_BOOKS_SUCCESS: {
		const id = Number(state.get('activeExchangeInstanceId'));
		const newState = action.payload.reduce((acc, value) => {
			const { orderBookId } = value;
			// @NOTE if we find order book in state then we should merge data, otherwise just push new order book to the list
			const book = state.getIn(['exchanges', id, 'orderBooks', orderBookId]);
			if (!book) {
				return acc.setIn(['exchanges', id, 'orderBooks', orderBookId], fromJS(value));
			}
			return acc.mergeIn(['exchanges', id, 'orderBooks', orderBookId], fromJS(value));
		}, state);
		return newState.setIn(['orderBooks', 'loading'], false)
			.setIn(['orderBooks', 'error'], null);
	}
	case ORDER_BOOK_TYPES.DELETE_ORDER_BOOK_SUCCESS: {
		const id = Number(state.get('activeExchangeInstanceId'));
		try {
			return action.payload.deleteIds.reduce((acc, orderBookId) => acc.deleteIn(['exchanges', id, 'orderBooks', orderBookId]), state);
		} catch (e) {
			return state;
		}
	}
	case EXCHANGE_TYPES.DELETE_TRADING_GATEWAY_SUCCESS: {
		const id = Number(state.get('activeExchangeInstanceId'));
		try {
			return action.payload.deleteIds.reduce((acc, tradingGatewayId) => acc.deleteIn(['exchanges', id, 'tradingGateways', tradingGatewayId]), state);
		} catch (e) {
			return state;
		}
	}
	case EVENT.OrderBooksStatusReply: {
		const id = Number(state.get('activeExchangeInstanceId'));
		const { orderBooks } = action.payload;
		return orderBooks.reduce((acc, book) => {
			// book.id from ws response is actually displayId from API response
			const foundBook = state.getIn(['exchanges', id, 'orderBooks']).find(val => val.get('displayId') === book.id);
			if (!foundBook) {
				// return acc.setIn(['exchanges', id, 'orderBooks', book.orderBookId], fromJS(book));
				return acc;
			}
			return acc.mergeIn(['exchanges', id, 'orderBooks', foundBook.get('orderBookId')], fromJS(book));
		}, state);
	}
	case EXCHANGE_TYPES.FETCH_TRADING_GATEWAYS_SUCCESS: {
		const id = Number(state.get('activeExchangeInstanceId'));
		try {
			const newState = action.payload.reduce((acc, value) => {
				const foundTradingGateway = state.getIn(['exchanges', id, 'tradingGateways', value.trGwyId]);
				if (!foundTradingGateway) {
					return acc.setIn(['exchanges', id, 'tradingGateways', value.trGwyId], fromJS(value));
				}
				const tradingGatewayPathResolver = key => ['exchanges', id, 'tradingGateways', value.trGwyId, key];

				return acc.setIn(tradingGatewayPathResolver('trGwyId'), value.trGwyId)
					.setIn(tradingGatewayPathResolver('name'), value.name)
					.setIn(tradingGatewayPathResolver('port'), value.port)
					.setIn(tradingGatewayPathResolver('displayId'), value.displayId)
					.setIn(tradingGatewayPathResolver('overrideFields'), value.overrideFields)
					.setIn(tradingGatewayPathResolver('resetOnLogon'), value.resetOnLogon)
					.setIn(tradingGatewayPathResolver('cancelOnDisconnect'), value.cancelOnDisconnect)
					.setIn(tradingGatewayPathResolver('fixSessions'), fromJS(value.fixSessions));
			}, state);
			return newState;
		} catch (e) {
			console.log(e);
		}
		return state;
	}
	case WEBSOCKET_TYPES.UPDATE_ORDER_BOOK_STATUS: {
		const id = Number(state.get('activeExchangeInstanceId'));
		const foundBook = state.getIn(['exchanges', id, 'orderBooks']).find(val => val.get('displayId') === action.payload.componentId);
		if (!foundBook) {
			return state;
		}
		return state.setIn(['exchanges', id, 'orderBooks', foundBook.get('orderBookId'), 'status'], action.payload.componentStatus);
	}
	case WEBSOCKET_TYPES.UPDATE_TRADING_GATEWAY_STATUS: {
		const id = Number(state.get('activeExchangeInstanceId'));
		const foundBook = state.getIn(['exchanges', id, 'tradingGateways']).find(val => val.get('displayId') === action.payload.componentId);
		if (!foundBook) {
			return state;
		}
		return state.setIn(['exchanges', id, 'tradingGateways', foundBook.get('trGwyId'), 'status'], action.payload.componentStatus);
	}
	case WEBSOCKET_TYPES.UPDATE_CORE_COMPONENT_STATUS: {
		const id = Number(state.get('activeExchangeInstanceId'));
		const coreComponentIndex = state.getIn(['exchanges', id, 'coreComponents']).findIndex(c => c.get('id') === action.payload.componentId);
		if (coreComponentIndex !== -1) {
			return state.setIn(['exchanges', id, 'coreComponents', coreComponentIndex, 'status'], action.payload.componentStatus);
		}
		return state;
	}
	case EXCHANGE_TYPES.UPDATE_ALL_INSTANCES_STATUS: {
		return Object.entries(action.payload).reduce((acc, entry) => {
			const [machineInstanceId, status] = entry;
			const exchange = state.get('exchanges').find(e => e.get('machineInstanceId') === Number(machineInstanceId));
			if (!exchange) {
				return acc;
			}
			return acc.setIn(['exchanges', exchange.get('clientExchangeId'), 'machineInstance', 'status'], status);
		}, state);
	}
	case EXCHANGE_TYPES.RESET_ACTIVE_EXCHANGE: {
		const id = Number(state.get('activeExchangeInstanceId'));
		return state.set('activeExchangeInstanceId', null).setIn(['exchanges', id, 'orderBlotterLoadingStatus'], true);
	}
	case EVENT.ExchangeStatusUpdate: {
		const id = Number(state.get('activeExchangeInstanceId'));
		return state.setIn(['exchanges', id, 'status'], action.payload.status);
	}
	case EVENT.OrderBookImageReply: {
		try {
			const { symbol, entries } = action.payload;
			const id = Number(state.get('activeExchangeInstanceId'));
			const buyEntries = entries.slice(28);
			const sellEntries = entries.slice(0, 28).reverse();

			let maxBuy = 0;
			let maxSell = 0;
			for (let i = 0; buyEntries.length > i; i += 1) {
				if (buyEntries[i].quantity !== null) {
					maxBuy = Math.max(stringToNumber(buyEntries[i].quantity), maxBuy);
				}
				if (sellEntries[i].quantity !== null) {
					maxSell = Math.max(stringToNumber(sellEntries[i].quantity), maxSell);
				}
			}

			const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === symbol);
			return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'buyEntries'], fromJS(buyEntries))
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'sellEntries'], fromJS(sellEntries))
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'totalBuy'], maxBuy)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'totalSell'], maxSell);
		} catch (e) {
			return state;
		}
	}
	case EVENT.OrderBookBBBOReply: {
		try {
			const { orderBookBBBO } = action.payload;
			const id = Number(state.get('activeExchangeInstanceId'));
			// orderBookId from response is actually viewCode
			const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === orderBookBBBO.orderBookId);

			return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bestBidPrice'], orderBookBBBO.bestBidPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bestBidQuantity'], orderBookBBBO.bestBidQuantity)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bestOfferPrice'], orderBookBBBO.bestOfferPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bestOfferQuantity'], orderBookBBBO.bestOfferQuantity)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'lastCrossingPrice'], orderBookBBBO.lastCrossingPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'lastCrossingQuantity'], orderBookBBBO.lastCrossingQuantity)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'lastCrossingPriceChangeAbsolute'], orderBookBBBO.lastCrossingPriceChangeAbsolute)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'lastCrossingPriceChangePct'], orderBookBBBO.lastCrossingPriceChangePct)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'spread'], orderBookBBBO.spread)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bidMemberId'], orderBookBBBO.bidMemberId)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'offerMemberId'], orderBookBBBO.offerMemberId)
			// set counters
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'counters'], fromJS(orderBookBBBO.orderBookCounters));
		} catch {
			return state;
		}
	}
	case EVENT.TradeTickerReplyEvent: {
		try {
			const { tradeTicker } = action.payload;
			const id = Number(state.get('activeExchangeInstanceId'));
			// orderBookId from response is actually viewCode
			const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === tradeTicker.viewCode);


			return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'price'], tradeTicker.price)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'changeAbsolute'], tradeTicker.changeAbsolute)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'changePct'], tradeTicker.changePct)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'high'], tradeTicker.high)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'low'], tradeTicker.low)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'openingPrice'], tradeTicker.openingPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'lastClosingPrice'], tradeTicker.lastClosingPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'totalVolume'], tradeTicker.totalVolume)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'totalTurnover'], tradeTicker.totalTurnover);
		} catch {
			return state;
		}
	}
	case EVENT.OrderBookTradeReply: {
		try {
			const { trades } = action.payload;
			const id = Number(state.get('activeExchangeInstanceId'));
			// orderBookId from response is actually viewCode
			const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === trades?.[0].instrumentCode);
			if (!book) {
				return state;
			}
			const tradesImmutable = fromJS(trades);
			const data = tradesImmutable.reduce((acc, value, i, array) => {
				if (i === 0) {
					return acc.unshift(value.set('higher', true));
				}
				if (stringToNumber(value.get('tradePrice')) < stringToNumber(array.get(i - 1).get('tradePrice'))) {
					return acc.unshift(value.set('lower', true));
				} if (stringToNumber(value.get('tradePrice')) > stringToNumber(array.get(i - 1).get('tradePrice'))) {
					return acc.unshift(value.set('higher', true));
				} return acc.unshift(value);
			}, List());
			return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'trades'], data);
		} catch {
			return state;
		}
	}
	case EVENT.BookRefreshJsonEvent: {
		try {
			const { sym, entries } = action.payload;
			const id = Number(state.get('activeExchangeInstanceId'));
			const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === sym);
			if (!book) {
				return state;
			}
			const buyEntries = entries.slice(28);
			const sellEntries = entries.slice(0, 28).reverse();
			let maxBuy = 0;
			let maxSell = 0;
			for (let i = 0; buyEntries.length > i; i += 1) {
				if (buyEntries[i].quantity !== null) {
					maxBuy = Math.max(stringToNumber(buyEntries[i].quantity), maxBuy);
				}
				if (sellEntries[i].quantity !== null) {
					maxSell = Math.max(stringToNumber(sellEntries[i].quantity), maxSell);
				}
			}

			return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'buyEntries'], fromJS(buyEntries))
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'sellEntries'], fromJS(sellEntries))
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'totalBuy'], maxBuy)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'totalSell'], maxSell);
		} catch (e) {
			console.log(e);
			return state;
		}
	}
	case EVENT.BBBOJsonEvent: {
		try {
			const { entries: orderBookBBBO } = action.payload;
			const id = Number(state.get('activeExchangeInstanceId'));
			// orderBookId from response is actually viewCode
			const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === orderBookBBBO.orderBookId);


			return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bestBidPrice'], orderBookBBBO.bestBidPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bestBidQuantity'], orderBookBBBO.bestBidQuantity)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bestOfferPrice'], orderBookBBBO.bestOfferPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bestOfferQuantity'], orderBookBBBO.bestOfferQuantity)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'lastCrossingPrice'], orderBookBBBO.lastCrossingPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'lastCrossingQuantity'], orderBookBBBO.lastCrossingQuantity)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'lastCrossingPriceChangeAbsolute'], orderBookBBBO.lastCrossingPriceChangeAbsolute)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'lastCrossingPriceChangePct'], orderBookBBBO.lastCrossingPriceChangePct)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'spread'], orderBookBBBO.spread)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'bidMemberId'], orderBookBBBO.bidMemberId)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'bbbo', 'offerMemberId'], orderBookBBBO.offerMemberId)
			// set counters
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'counters'], fromJS(orderBookBBBO.orderBookCounters));
		} catch {
			return state;
		}
	}
	case EVENT.TradeTickerUpdateJsonEvent: {
		try {
			const { sym, entries: tradeTicker } = action.payload;
			const id = Number(state.get('activeExchangeInstanceId'));
			// orderBookId from response is actually viewCode
			const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === sym);


			return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'price'], tradeTicker.price)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'changeAbsolute'], tradeTicker.changeAbsolute)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'changePct'], tradeTicker.changePct)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'high'], tradeTicker.high)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'low'], tradeTicker.low)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'openingPrice'], tradeTicker.openingPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'lastClosingPrice'], tradeTicker.lastClosingPrice)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'totalVolume'], tradeTicker.totalVolume)
				.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'tradeTicker', 'totalTurnover'], tradeTicker.totalTurnover);
		} catch {
			return state;
		}
	}
	case EVENT.TradeJsonEvent: {
		const { sym, entries } = action.payload;
		const id = Number(state.get('activeExchangeInstanceId'));
		// orderBookId from response is actually viewCode
		const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === sym);
		const currentTrades = state.getIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'trades']);
		const trade = fromJS(entries[0]);
		if (!currentTrades) {
			const addedTrades = List().push(trade.set('higher', true));
			return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'trades'], addedTrades);
		}
		const firstElement = currentTrades.get(0);
		let formattedTrade = null;
		if (stringToNumber(firstElement.get('tradePrice')) > stringToNumber(trade.get('tradePrice'))) {
			formattedTrade = trade.set('lower', true);
		} else if (stringToNumber(firstElement.get('tradePrice')) < stringToNumber(trade.get('tradePrice'))) {
			formattedTrade = trade.set('higher', true);
		} else {
			formattedTrade = trade;
		}
		const addedTrades = state.getIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'trades']).unshift(formattedTrade);
		let checkedLength = null;
		if (addedTrades.size > 28) {
			checkedLength = addedTrades.pop();
		}
		return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'trades'], checkedLength || addedTrades);
	}
	case EXCHANGE_TYPES.CHOOSE_EXPORT_EXTENSION: {
		const { extension } = action.payload;
		const id = Number(state.get('activeExchangeInstanceId'));
		return state.setIn(['exchanges', id, 'exportExtension'], extension);
	}
	case EXCHANGE_TYPES.CLEAR_ORDER_BOOK_TRADES: {
		const { exchangeId, orderBookId } = action.payload;
		return state.setIn(['exchanges', Number(exchangeId), 'orderBooks', Number(orderBookId), 'trades'], null);
	}
	case EVENT.BlotterRefreshJsonEvent: {
		const { sym, entries } = action.payload;
		const id = Number(state.get('activeExchangeInstanceId'));

		const newBlotters = fromJS(entries);
		return state.setIn(['exchanges', id, 'blotters', sym], newBlotters);
	}
	case EXCHANGE_TYPES.SET_EXCHANGE_ACTIVE_USER: {
		const id = Number(state.get('activeExchangeInstanceId'));
		return state.setIn(['exchanges', id, 'selectedMember'], action.payload.user);
	}
	case EXCHANGE_TYPES.SET_ORDER_BLOTTER_TRADES: {
		const id = Number(state.get('activeExchangeInstanceId'));
		const data = Map().set('orderId', action.payload.orderId)
			.set('instrumentCode', action.payload.instrumentCode);
		return state.setIn(['exchanges', id, 'blotterTradesData'], data);
	}
	case EXCHANGE_TYPES.FETCH_EXCHANGE_TYPES_NAMES_SUCCESS: {
		return state.set('exchangeNames', fromJS(action.payload));
	}
	// These two events should be the same, TODO double check this
	case EVENT.TickerPlantResendPacketMessagesReply: {
		const id = Number(state.get('activeExchangeInstanceId'));
		return state.setIn(['exchanges', id, 'messages'], fromJS(action.payload.messages));
	}
	case EVENT.TradingGatewayMessageUpdate: {
		const id = Number(state.get('activeExchangeInstanceId'));
		return state.updateIn(['exchanges', id, 'messages'], messages => messages.concat(fromJS(action.payload.messages)));
	}
	case EVENT.TradingGatewayActiveSessionsReplyEvent: {
		const { entries, port } = action.payload;
		const id = Number(state.get('activeExchangeInstanceId'));
		const foundGateway = state.getIn(['exchanges', id, 'tradingGateways']).find(tr => tr.get('port') === Number(port));
		return state.setIn(['exchanges', id, 'tradingGateways', foundGateway.get('trGwyId'), 'activeSessions'], entries.length);
	}
	case EXCHANGE_TYPES.SET_ORDER_BLOTTERS_LOADING_STATUS: {
		const { status } = action.payload;
		const id = Number(state.get('activeExchangeInstanceId'));
		// if we have no order books we have to set loading to false no matter what since we won't receive any event
		if (!state.getIn(['exchanges', id, 'orderBooks'])?.size) {
			return state.setIn(['exchanges', id, 'orderBlotterLoadingStatus'], false);
		}
		return state.setIn(['exchanges', id, 'orderBlotterLoadingStatus'], status);
	}
	case EXCHANGE_TYPES.UPDATE_LOADED_ORDER_BLOTTERS: {
		const id = Number(state.get('activeExchangeInstanceId'));
		const count = state.getIn(['exchanges', id, 'loadedBlotters']) ?? 0;
		const updatedState = state.setIn(['exchanges', id, 'loadedBlotters'], count + 1);

		if (updatedState.getIn(['exchanges', id, 'loadedBlotters']) === state.getIn(['exchanges', id, 'orderBooks'])?.size) {
			return updatedState.setIn(['exchanges', id, 'orderBlotterLoadingStatus'], false)
				.setIn(['exchanges', id, 'loadedBlotters'], null);
		}
		return updatedState;
	}
	case EVENT.OrderBookPhaseUpdate: {
		const {
			orderBookId,
			orderBookPhase,
		} = action.payload;
		const id = Number(state.get('activeExchangeInstanceId'));
		// orderBookId from response is actually viewCode
		const book = state.getIn(['exchanges', id, 'orderBooks']).find(b => b.get('viewCode') === orderBookId);
		return state.setIn(['exchanges', id, 'orderBooks', book.get('orderBookId'), 'phase'], fromJS(orderBookPhase));
	}
	default: {
		return state;
	}
	}
}
