import React, {
	useCallback, useContext, useEffect, useState,
} from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import htmlToImage from 'html-to-image';

// Atoms
import {
	Placeholder, Tab, Tabs,
} from '../../components/Atoms/Tabs';
import {
	LeftTable,
	OrderBookContainer,
	RightSectionWrapper,
	RightTable,
	Tables,
	TabsSection,
} from '../../components/Organisms/OrderBook/OrderBook.Atoms';

// Molecules
import OrderBookBBBOTable from '../../components/Molecules/OrderBookBBBOTable';
import OrderBookTradeTickerTable from '../../components/Molecules/OrderBookTradeTickerTable';
import ModifyOrderModal from '../../components/Organisms/Modals/ModifyOrderModal';
import ChooseExtensionModal from '../../components/Organisms/Modals/ChooseExportExtension';
import Graph from '../../components/Molecules/OrderBookGraph';
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import OrderEntrySection from '../../components/Organisms/OrderBook/OrderEntrySection';
import CountersSection from '../../components/Organisms/OrderBook/CountersSection';
import OrderBookMdReplaySection from '../../components/Organisms/OrderBook/OrderBookMDReplaySection';
import OrderBookHeader from '../../components/Organisms/OrderBook/OrderBookHeader';
import MarketDepthTable from '../../components/Organisms/TableModels/MarketDepthTable/MarketDepthTable';
import TimesAndSalesTable from '../../components/Organisms/TableModels/TimesAndSalesTable';
import MultiFillModal from '../../components/Organisms/Modals/MultiFillModal/MultiFillModal';

// Actions
import {
	clearOrderBookTrades,
	fetchSingleExchangeExtraData,
	chooseExportExtension,
} from '../../data/exchanges/ExchangesActions';
import { fetchOrderBooks } from '../../data/orderBooks/OrderBooksActions';
import { toggleChooseExtensionModal, toggleModal } from '../../data/ui/UIActions';

// Context
import WebSocketConnectionContext from '../../components/Context/WebsocketConnectionContext';

// Selectors
import { getActiveExchange } from '../../data/exchanges/selectors';

// Lib
import {
	clearOrderBook,
	orderBookBBBORequest,
	orderBookTradeRequest,
	orderBookTradeTickerRequest,
	requestOrderBookImage, requestOrderBookStatus,
	orderBookExportRequest,
} from '../../lib/websockets';
import { uploadXlFile, uploadCsvFile, showError } from '../../lib/helpers';
import { loadOrderBooks } from '../../lib/orderBook';
import { MODALS } from '../../lib/constants/General';
import LoaderModal from '../../components/Organisms/Modals/LoaderModal';
import { EXCHANGE_STATUS } from '../../lib/constants/Exchange';

function initOrderBook(client, exchangeType, sessionId, symbol) {
	requestOrderBookImage(client, exchangeType, sessionId, symbol);
	orderBookTradeRequest(client, exchangeType, sessionId, symbol);
	orderBookTradeTickerRequest(client, exchangeType, sessionId, symbol);
	orderBookBBBORequest(client, exchangeType, sessionId, symbol);
}

function OrderBook(props) {
	const {
		fix,
		match,
		activeExchangeStatus,
		machineInstanceStatus,
		orderBookViewCode,
		orderBookName,
		activeExchangeClientExchangeId,
		activeExchangeDisplayId,
		activeExchangeIpAddress,
		activeOrderBookId,
		activeOrderBookPhaseName,
	} = props;

	const bookId = match?.params?.bookId;
	const instanceId = match?.params?.slug;
	const WebsocketContext = useContext(WebSocketConnectionContext);
	const dispatch = useDispatch();
	const [exportImageLoader, setExportImageLoader] = useState(false);

	const { client, sessionId } = WebsocketContext;
	const title = `${orderBookName} (${orderBookViewCode})`;

	useEffect(() => {
		if (client?.isConnected()) {
			// need this request for phase in the header
			requestOrderBookStatus(client, fix, sessionId, [orderBookViewCode]);
			initOrderBook(client, fix, sessionId, orderBookViewCode);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [WebsocketContext.connected]);

	const handleOrderBookClear = useCallback(() => {
		if (client?.isConnected()) {
			clearOrderBook(client, fix, orderBookViewCode);
			// We have to clean trades manually because we don't get update event about new trades after clearance
			dispatch(clearOrderBookTrades(instanceId, bookId));
		}
	}, [bookId, dispatch, instanceId, orderBookViewCode, client, fix]);

	const handleExport = (extension) => {
		dispatch(chooseExportExtension(extension));
		if (client?.isConnected()) {
			orderBookExportRequest(client, fix, sessionId, orderBookViewCode);
			dispatch(toggleChooseExtensionModal(false));
		}
	};

	const openChooseExtensionModal = () => {
		dispatch(toggleChooseExtensionModal(true));
	};

	const startLoading = useCallback((length) => {
		WebsocketContext.countImports(length);
		dispatch(toggleModal(MODALS.LOADING_MODAL, true, { title: 'Importing...' }));
	}, [WebsocketContext, dispatch]);

	const handleImport = (event) => {
		// start loading
		const { files } = event.target;
		const file = files[0];
		const extension = file.name.split('.').pop();
		if (extension === 'xlsx' || extension === 'xls') {
			uploadXlFile(file, (orderBookForUpload) => {
				try {
					startLoading(orderBookForUpload.length);
					loadOrderBooks(orderBookForUpload, client, fix, sessionId, orderBookViewCode);
				} catch (error) {
					dispatch(toggleModal(MODALS.LOADING_MODAL, false));
					showError('Loading error', error.message);
				}
			});
		} else if (extension === 'csv') {
			uploadCsvFile(file, (orderBookForUpload) => {
				try {
					startLoading(orderBookForUpload.length);
					loadOrderBooks(orderBookForUpload, client, fix, sessionId, orderBookViewCode);
				} catch (error) {
					dispatch(toggleModal(MODALS.LOADING_MODAL, false));
					showError('Loading error', error.message);
				}
			});
		}
	};

	const handleExportAsImage = () => {
		setExportImageLoader(true);
		htmlToImage.toJpeg(document.getElementById('ETPGems'), { quality: 0.95 })
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.download = 'Order Book.jpeg';
				link.href = dataUrl;
				link.click();
				setExportImageLoader(false);
			}).catch((error) => {
				showError('Exporting image error', error.message);
				setExportImageLoader(false);
			});
	};

	return (
		<PageWrapper
			breadcrumbs={[
				{
					link: `exchanges/${activeExchangeClientExchangeId}`,
					name: activeExchangeDisplayId,
				},
				{
					link: `order-book/${activeOrderBookId}`,
					name: title,
				},
			]}
			title={title}
			pageWrapperRightSide={(
				<OrderBookHeader
					ipAddress={activeExchangeIpAddress}
					vmStatus={machineInstanceStatus}
					exchangeStatus={activeExchangeStatus}
					onClearOrderBookClick={handleOrderBookClear}
					onExportAsImageClick={handleExportAsImage}
					exportImageLoader={exportImageLoader}
					onExportClick={openChooseExtensionModal}
					onImportClick={event => handleImport(event)}
					tradingPhaseStatus={activeOrderBookPhaseName}
				/>
			)}
		>
			<DocumentTitle title={`${activeExchangeDisplayId} - ${orderBookViewCode}`} />
			<ChooseExtensionModal
				onConfirmClick={handleExport}
				onCancelClick={() => dispatch(toggleChooseExtensionModal(false))}
			/>
			<OrderBookContainer>
				<MarketDepthTable
					bookId={bookId}
				/>
				<TimesAndSalesTable
					bookId={bookId}
				/>
				<RightSectionWrapper>
					<Tables className="bbbo-and-tt-table">
						<LeftTable>
							<OrderBookBBBOTable
								bookId={bookId}
								instanceId={instanceId}
							/>
						</LeftTable>
						<RightTable>
							<OrderBookTradeTickerTable
								bookId={bookId}
								instanceId={instanceId}
							/>
						</RightTable>
					</Tables>
					<TabsSection>
						<Tabs>
							<Tab
								replace
								to={`/app/exchanges/${instanceId}/order-book/${bookId}/order-entry`}
							>
								<p>Order Entry</p>
							</Tab>
							<Tab
								replace
								to={`/app/exchanges/${instanceId}/order-book/${bookId}/md-replay`}
							>
								<p>MD Replay</p>
							</Tab>
							<Tab
								replace
								to={`/app/exchanges/${instanceId}/order-book/${bookId}/graph`}
							>
								<p>Price Graph</p>
							</Tab>
							<Tab
								replace
								to={`/app/exchanges/${instanceId}/order-book/${bookId}/counters`}
							>
								<p>Counters</p>
							</Tab>
							<Placeholder />
						</Tabs>
						<OrderBookMdReplaySection
							client={client}
							fix={fix}
							sessionId={sessionId}
							symbol={orderBookViewCode}
							exchangeStatus={activeExchangeStatus}
							bookId={bookId}
							instanceId={instanceId}
							visible={props.location.pathname.includes('md-replay')}
						/>
						<Switch>
							<Route
								path="/app/exchanges/:slug/order-book/:bookId/graph"
								component={Graph}
							/>
							<Route
								path="/app/exchanges/:slug/order-book/:bookId/order-entry"
								render={() => (
									<OrderEntrySection
										bookId={bookId}
									/>
								)}
							/>

							<Route
								path="/app/exchanges/:slug/order-book/:bookId/counters"
								render={() => (
									<CountersSection
										instanceId={instanceId}
										bookId={bookId}
									/>
								)}
							/>
							{/* <Redirect to="/app/exchanges/:slug/order-book/:bookId/graph" /> */}
						</Switch>
					</TabsSection>
				</RightSectionWrapper>
			</OrderBookContainer>
			<ModifyOrderModal />
			<MultiFillModal />
			<LoaderModal
				percentage={WebsocketContext.totalImports === -1 ? 100 : (WebsocketContext.importedOrders / WebsocketContext.totalImports) * 100}
			/>
		</PageWrapper>
	);
}

function mapStateToProps(state, props) {
	const activeExchange = getActiveExchange(state);
	const bookId = props.match?.params?.bookId;

	return {
		fix: activeExchange.get('exchangeType')?.get('code'),
		activeExchangeStatus: activeExchange.get('status'),
		activeExchangeClientExchangeId: activeExchange.get('clientExchangeId'),
		activeExchangeDisplayId: activeExchange.get('displayId'),
		activeExchangeIpAddress: activeExchange.getIn(['machineInstance', 'ipAddress']),
		machineInstanceStatus: activeExchange.getIn(['machineInstance', 'status']),
		machineInstanceIpAddress: activeExchange.getIn(['exchangeType', 'code']),
		orderBookName: activeExchange.getIn(['orderBooks', Number(bookId), 'name']),
		orderBookViewCode: activeExchange.getIn(['orderBooks', Number(bookId), 'viewCode']),
		activeOrderBookPhaseName: activeExchange.getIn(['orderBooks', Number(bookId)]).getIn(['phase', 'phaseName']),
		activeOrderBookId: activeExchange.getIn(['orderBooks', Number(bookId)]).get('orderBookId'),
	};
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		fetchSingleExchangeExtraData,
		fetchOrderBooks,
	}, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(OrderBook);
OrderBook.defaultProps = {
	activeExchangeStatus: EXCHANGE_STATUS.NA,
	activeOrderBookPhaseName: null,
};
OrderBook.propTypes = {
	orderBookBBBO: PropTypes.shape({}).isRequired,
	orderBookTradeTicker: PropTypes.shape({}).isRequired,
	activeExchangeStatus: PropTypes.string,
	machineInstanceStatus: PropTypes.string.isRequired,
	orderBookViewCode: PropTypes.string.isRequired,
	orderBookName: PropTypes.string.isRequired,
	activeExchangeClientExchangeId: PropTypes.number.isRequired,
	activeExchangeDisplayId: PropTypes.string.isRequired,
	activeExchangeIpAddress: PropTypes.string.isRequired,
	activeOrderBookId: PropTypes.number.isRequired,
	activeOrderBookPhaseName: PropTypes.string,
	actions: PropTypes.shape({
		fetchSingleExchangeExtraData: PropTypes.func,
		fetchOrderBooks: PropTypes.func,
	}).isRequired,
	fix: PropTypes.string.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
			bookId: PropTypes.string.isRequired,
		}),
	}).isRequired,
};
