import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Lib
import { DEVICE } from '../../../lib/constants/Device';

// Images
import arrow from '../../../assets/images/MarketingSite/arrow.png';

const SliderWrapper = styled.div`
	position: relative;
	text-align: center;
`;

const Arrows = styled.span`
	${props => (props.left ? 'left: 15px;' : 'right: 15px;')}
	position: absolute;
	cursor: pointer;
	z-index: 10;
	top: 50%;

	@media ${DEVICE.mobileL} {
		top: 90px;
	}
`;

const Image = styled.img`
	height: 24px;
	${prop => (prop.left ? 'transform: rotate(180deg)' : '')}
`;

const Slider = (props) => {
	const { children } = props;
	const numberOfElements = children.length - 1;
	const [elementShown, setElementShown] = useState(0);

	function changeSownElement(value) {
		if (elementShown + value > numberOfElements) {
			return setElementShown(0);
		}
		if (elementShown + value < 0) {
			return setElementShown(numberOfElements);
		}
		return setElementShown(elementShown + value);
	}

	return (
		<SliderWrapper>
			<Arrows left onClick={() => changeSownElement(-1)}>
				<Image src={arrow} left />
			</Arrows>
			<Arrows onClick={() => changeSownElement(1)}>
				<Image src={arrow} />
			</Arrows>
			{children[elementShown]}
		</SliderWrapper>
	);
};

Slider.propTypes = {
	children: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Slider;
