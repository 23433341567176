import { getRandomClientOrderId } from './exchange';
import { hitOrder, cancelOrder, modifyOrder } from './websockets';
import { OPERATION, ORDER_SIDE } from './constants/Exchange';

export const loadOrderBooks = (orderBooks, client, fix, sessionId, symbol) => {
	if (orderBooks != null) {
		// check first if required values are not empty
		let isRequiredEmpty = false;
		let hasErrorField = false;

		orderBooks.forEach((obj, index) => {
			if (obj.MEMBER_ID == null || obj.SIDE == null || obj.ORDER_TYPE == null || obj.VALIDITY == null || obj.QUANTITY == null) {
				isRequiredEmpty = true;
			}

			if (obj.ORDER_TYPE === 'Limit') {
				if (obj.PRICE == null) {
					isRequiredEmpty = true;
				}
			}

			if (obj.VALIDITY === 'GTD' || obj.VALIDITY === 'GTC') {
				if (obj.EXPIRY_DATE == null) {
					isRequiredEmpty = true;
				}
			}

			if (obj.SIDE === 'B') {
				orderBooks[index].SIDE = 'BUY';
			} else if (obj.SIDE === 'S') {
				orderBooks[index].SIDE = 'SELL';
			} else {
				hasErrorField = true;
			}

			if (obj.ORDER_TYPE !== 'Limit' && obj.ORDER_TYPE !== 'Market') {
				hasErrorField = true;
			}

			if (obj.VALIDITY !== 'Day' && obj.VALIDITY !== 'GTD' && obj.VALIDITY !== 'GTC' && obj.VALIDITY !== 'FAK' && obj.VALIDITY !== 'FOK') {
				hasErrorField = true;
			}

			if (obj.EXPIRY_DATE === '' || obj.EXPIRY_DATE == null) {
				orderBooks[index].EXPIRY_DATE = '';
			}
		});

		if (!isRequiredEmpty && !hasErrorField) {
			// upload each orders
			// $('#upload-orderbook-dialog').modal('show');
			const clientOrderId = getRandomClientOrderId();

			orderBooks.forEach((obj) => {
				const data = {
					memberId: obj.MEMBER_ID,
					price: obj.PRICE,
					quantity: obj.QUANTITY,
					side: obj.SIDE,
					ordType: obj.ORDER_TYPE.toUpperCase(),
					timeInForce: obj.VALIDITY.toUpperCase(),
					instrumentCode: symbol,
					clientOrderId,
				};
				hitOrder(client, fix, sessionId, data);
			});
		} else if (isRequiredEmpty) {
			// required empty
			throw new Error('Required fields are empty. Please check and try again.');
		} else if (hasErrorField) {
			// invalid value
			throw new Error('Some fields are invalid. Please check and try again.');
		}
	} else {
		// empty
		throw new Error('File format is invalid. Please try again.');
	}
};

export const loadMdrOrderBooks = (orderBooks, callback, symbol = false) => {
	if (orderBooks != null) {
		// check first if required values are not empty
		let isRequiredEmpty = false;
		let hasErrorField = false;
		let mdrCounter = 0;

		orderBooks.forEach((obj, index) => {
			if (obj.TIME == null || obj.OPERATION == null || obj.VIEW_CODE == null) {
				isRequiredEmpty = true;
			}
			if (obj.OPERATION === OPERATION.ADD) {
				if (obj.SIDE == null || obj.QUANTITY == null || obj.PRICE == null) {
					isRequiredEmpty = true;
				}

				if (obj.SIDE === 'B') {
					orderBooks[index].SIDE = ORDER_SIDE.BUY;
				} else if (obj.SIDE === 'S') {
					orderBooks[index].SIDE = ORDER_SIDE.SELL;
				} else {
					hasErrorField = true;
				}
			} else if (obj.OPERATION === OPERATION.MODIFY) {
				if (obj.SIDE === 'B') {
					orderBooks[index].SIDE = ORDER_SIDE.BUY;
				} else if (obj.SIDE === 'S') {
					orderBooks[index].SIDE = ORDER_SIDE.SELL;
				} else {
					hasErrorField = true;
				}
				if (obj.CLIENT_ORDER_ID == null || (obj.QUANTITY == null || obj.PRICE == null)) {
					isRequiredEmpty = true;
				}
			} else if (obj.OPERATION === OPERATION.CANCEL) {
				if (obj.CLIENT_ORDER_ID == null) {
					isRequiredEmpty = true;
				}
			}

			orderBooks[index].ORDER_TYPE = 'Limit';
			orderBooks[index].VALIDITY = 'Day';
			orderBooks[index].MEMBER_ID = '99999';
			if (!symbol) {
				mdrCounter += 1;
			}
			if (symbol && obj.VIEW_CODE === symbol) {
				mdrCounter += 1;
			}
		});

		if (!isRequiredEmpty && !hasErrorField) {
			if (mdrCounter === 0) {
				throw new Error('There are no orders for this orderbook.');
			}
		} else if (isRequiredEmpty) {
			throw new Error('Required fields are empty. Please check and try again.');
		} else if (hasErrorField) {
			throw new Error('Some fields are invalid. Please check and try again.');
		}
		callback(orderBooks);
	} else {
		throw new Error('File format is invalid. Please try again.');
	}
};
export const addOperation = (client, fix, sessionId, symbol, order) => {
	const data = {
		orderBookHit: false,
		failedHitMessage: 'Failed to add order.',
		memberId: order.MEMBER_ID,
		price: order.PRICE,
		quantity: order.QUANTITY,
		side: order.SIDE,
		ordType: order.ORDER_TYPE.toUpperCase(),
		timeInForce: order.VALIDITY.toUpperCase(),
		instrumentCode: symbol,
		clientOrderId: getRandomClientOrderId(),
		orderId: '',
	};
	hitOrder(client, fix, sessionId, data);
};

export const modifyOperation = (client, fix, sessionId, symbol, order) => {
	const data = {
		orderBookHit: false,
		failedHitMessage: 'Failed to add order.',
		memberId: order.MEMBER_ID,
		price: order.PRICE,
		quantity: order.QUANTITY,
		side: order.SIDE,
		ordType: order.ORDER_TYPE.toUpperCase(),
		timeInForce: order.VALIDITY.toUpperCase(),
		instrumentCode: symbol,
		clientOrderId: order.CLIENT_ORDER_ID ? order.CLIENT_ORDER_ID : getRandomClientOrderId(),
		orderId: '',
		origClientOrderId: order.CLIENT_ORDER_ID,
	};
	modifyOrder(client, fix, sessionId, data);
};

export const cancelOperation = (client, fix, sessionId, symbol, order) => {
	const data = {
		orderBookHit: false,
		failedHitMessage: 'Failed to add order.',
		memberId: order.MEMBER_ID,
		price: order.PRICE,
		quantity: order.QUANTITY,
		side: order.SIDE,
		ordType: order.ORDER_TYPE.toUpperCase(),
		timeInForce: order.VALIDITY.toUpperCase(),
		instrumentCode: symbol,
		clientOrderId: order.CLIENT_ORDER_ID ? order.CLIENT_ORDER_ID : getRandomClientOrderId(),
		orderId: '',
		origClientOrderId: order.CLIENT_ORDER_ID,
	};
	cancelOrder(client, fix, sessionId, data);
};
