export const marketReplyHeader = [
	{
		name: 'Code', sortable: true, visible: true, accessor: 'code',
	},
	{
		name: 'Price', sortable: true, visible: true, accessor: 'price',
	},
	{
		name: 'Change', sortable: true, visible: true, accessor: 'change',
	},
	{
		name: 'Best Bid', sortable: true, visible: true, accessor: 'bestBid',
	},
	{
		name: 'Best Offer', sortable: true, visible: true, accessor: 'bestOffer',
	},
	{
		name: 'Added', sortable: true, visible: true, accessor: 'addedBids',
	},
	{
		name: 'Modified', sortable: true, visible: true, accessor: 'modifiedBids',
	},
	{
		name: 'Cancelled', sortable: true, visible: true, accessor: 'cancelledBids',
	},
	{
		name: 'Added', sortable: true, visible: true, accessor: 'addedOffers',
	},
	{
		name: 'Modified', sortable: true, visible: true, accessor: 'modifiedOffers',
	},
	{
		name: 'Cancelled', sortable: true, visible: true, accessor: 'canceledOffers',
	},
	{
		name: 'Added', sortable: true, visible: true, accessor: 'addedTrades',
	},
	{
		name: 'Modified', sortable: true, visible: true, accessor: 'modifiedTrades',
	},
	{
		name: 'Cancelled', sortable: true, visible: true, accessor: 'canceledTrades',
	},

];
export const rows = [

];
