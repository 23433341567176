import { css } from 'styled-components';

export const SUPPORTED_ANIMATIONS = {
	SHOW_ELEMENT: 'show-element',
	BREATHE: 'breath',
	REAPPEAR: 'reappear',
	SLIDE: 'slide',
};

export const Animation = (name, duration = 0.5, timing = '', delay = 0, iterationCount = '', direction = '', fillMode = '') => {
	const animation = `${name} ${duration}s ${timing} ${delay}s ${iterationCount} ${direction} ${fillMode}`;
	return css`
		-webkit-animation: ${animation};
		-moz-animation: ${animation};
		-o-animation: ${animation};
		-ms-animation: ${animation};
		animation: ${animation};
	`;
};

export const Slide = (
	duration,
	timing,
	delay,
	iterationCount,
	direction,
	fillMode = 'forwards'
) => Animation(SUPPORTED_ANIMATIONS.SLIDE, duration, timing, delay, iterationCount, direction, fillMode);


export const ShowElement = (
	duration,
	timing,
	delay,
	iterationCount,
	direction,
	fillMode = 'forwards'
) => Animation(SUPPORTED_ANIMATIONS.SHOW_ELEMENT, duration, timing, delay, iterationCount, direction, fillMode);

export const Breathe = (
	duration,
	timing,
	delay,
	iterationCount,
	direction,
	fillMode
) => Animation(SUPPORTED_ANIMATIONS.BREATHE, duration, timing, delay, iterationCount, direction, fillMode);

export const Reappear = (
	duration,
	timing,
	delay,
	iterationCount,
	direction,
	fillMode
) => Animation(SUPPORTED_ANIMATIONS.REAPPEAR, duration, timing, delay, iterationCount, direction, fillMode);
