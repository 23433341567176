import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// selectors
import { getOrderBookBBBOData } from '../../data/exchanges/selectors';

// Atoms
import { MediumDivider } from '../Atoms/Divider';
import { InfoTable, OrderBookInfoCell, Wrapper } from '../Atoms/Table';
import { BlockTitle } from '../Atoms/Text';
import { stringToNumber } from '../../lib/exchange';

// Molecules
import MarketDataCell from './MarketDataCell';
import { ScrollableContentWrapper } from '../Atoms/Wrapper';
import Scrollbar from '../Organisms/Scrollbar';

function OrderBookBBBOTable(props) {
	const { bookId, instanceId } = props;

	const bbboData = useSelector(state => getOrderBookBBBOData(state, instanceId, bookId));

	const bestBid = bbboData?.get('bestBidPrice');
	const bestOffer = bbboData?.get('bestOfferPrice');
	const bestBidQty = bbboData?.get('bestBidQuantity');
	const bestOfferQty = bbboData?.get('bestOfferQuantity');
	const lastCrossing = bbboData?.get('lastCrossingQuantity');
	const lastCrossingQty = bbboData?.get('lastCrossingQuantity');

	return (
		<Wrapper>
			<BlockTitle>
				BBBO
			</BlockTitle>
			<MediumDivider />
			<ScrollableContentWrapper>
				<Scrollbar>
					<InfoTable>
						<tbody>
							<tr>
								<OrderBookInfoCell>Best Bid:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(bestBid)}
									formattedData={(bestBid === '-1.00' || bestBid === undefined) ? 'N/A' : `$${bestBid}`}
								/>
							</tr>

							<tr>
								<OrderBookInfoCell>Best Offer:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(bestOffer)}
									formattedData={(bestOffer === '-1.00' || bestOffer === undefined) ? 'N/A' : `$${bestOffer}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Best Bid Qty:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(bestBidQty)}
									formattedData={(bestBidQty === '-1.00' || bestBidQty === undefined) ? 'N/A' : `${bestBidQty}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Best Offer Qty:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(bestOfferQty)}
									formattedData={(bestOfferQty === '-1.00' || bestOfferQty === undefined) ? 'N/A' : `${bestOfferQty}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Last Crossing:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(lastCrossing)}
									formattedData={(lastCrossing === '-1.00' || lastCrossing === undefined) ? '$0' : `$${lastCrossing}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Last Crossing Qty:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(lastCrossingQty)}
									formattedData={(lastCrossingQty === '-1.00' || lastCrossingQty === undefined) ? '$0' : `$${lastCrossingQty}`}
								/>
							</tr>
						</tbody>
					</InfoTable>
				</Scrollbar>
			</ScrollableContentWrapper>
		</Wrapper>
	);
}

OrderBookBBBOTable.propTypes = {
	bookId: PropTypes.string.isRequired,
	instanceId: PropTypes.string.isRequired,
};

export default OrderBookBBBOTable;
