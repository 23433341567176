import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Atoms
import { BuyCheckBox, SellCheckBox } from './AddOrderBlotterModal.Atoms';
import { SmallVerticalDivider } from '../../../Atoms/Divider';
import { FlexRowBase } from '../../../Atoms/Flex';
import { ORDER_SIDE } from '../../../../lib/constants/Exchange';

const StyledOrderBlotterCheckBox = styled.div`
	${FlexRowBase};
	width: 100%;
	justify-content: space-between;
	height: 50px;
	${props => props.smaller && 'height: 36px'};
`;

const OrderEntryInput = (props) => {
	const {
		selected,
		setSelected,
		smaller,
	} = props;
	return (
		<StyledOrderBlotterCheckBox smaller={smaller}>
			<BuyCheckBox
				selected={selected}
				onClick={() => setSelected(ORDER_SIDE.BUY)}
			>
				BUY
			</BuyCheckBox>
			<SmallVerticalDivider />
			<SellCheckBox
				selected={selected}
				onClick={() => setSelected(ORDER_SIDE.SELL)}
			>
				SELL
			</SellCheckBox>
		</StyledOrderBlotterCheckBox>
	);
};
OrderEntryInput.propTypes = {
	selected: PropTypes.oneOf([ORDER_SIDE.BUY, ORDER_SIDE.SELL]),
	setSelected: PropTypes.func.isRequired,
	smaller: PropTypes.bool,
};
OrderEntryInput.defaultProps = {
	selected: null,
	smaller: false,
};
export default OrderEntryInput;
