import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Actions
import { requestTickerPlantMessage } from '../../lib/websockets';

// Molecules
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import MessageTrafficHeader from '../../components/Organisms/MessageTrafficHeader';
import MessageTrafficModal from '../../components/Organisms/Modals/MessageTrafficModal/MessageTrafficModal';
import MessageTrafficTable from '../../components/Organisms/TableModels/MessageTrafficTable/MessageTrafficTable';

import { getActiveExchange } from '../../data/exchanges/selectors';
import WebSocketConnectionContext from '../../components/Context/WebsocketConnectionContext';

function MessageTraffic() {
	const activeExchange = useSelector(getActiveExchange);
	const fix = activeExchange.get('exchangeType')?.get('code');
	const { client, sessionId } = useContext(WebSocketConnectionContext);

	useEffect(() => {
		if (client?.isConnected()) {
			requestTickerPlantMessage(client, fix, sessionId);
		}
	}, [client, fix, sessionId]);

	return (
		<PageWrapper
			breadcrumbs={[
				{
					link: `exchanges/${activeExchange.get('clientExchangeId')}`,
					name: activeExchange.get('displayId'),
				},
				{
					link: 'gateways-traffic',
					name: 'Gateways Traffic',
				},
			]}
			title="Gateways Traffic"
			pageWrapperRightSide={(
				<MessageTrafficHeader
					ipAddress={activeExchange?.get('machineInstance').get('ipAddress')}
					vmStatus={activeExchange?.get('machineInstance').get('status')}
					exchangeStatus={activeExchange.get('status')}
				/>
			)}
		>
			<DocumentTitle title={`${activeExchange.get('displayId')} - Gateway Traffic`} />
			<MessageTrafficTable />
			<MessageTrafficModal />
		</PageWrapper>

	);
}
export default MessageTraffic;
