import styled, { css } from 'styled-components';
import { ZIndex } from '../../../Css/Css';

const inputErrorCss = css`
    border: 1px solid #d73e3e;
`;

export const BaseInputTextArea = styled.textarea`
    border: 1px solid #e2eaf0;
    border-radius: 4px;
    font-size: 16px;
	${props => (props.error ? inputErrorCss : null)};
    outline: none;
    padding: 10px 10px;
    width: 100%;
    height: 90px;
    resize: none;

    ::placeholder{
    	color: #8c959d;
    }
`;

export const ErrorMessage = styled.div`
    height: 15px;
    display: flex;
    align-items: end;
    color: #d73e3e;
    font-size: 12px;
`;

const smallerLabel = css`
    font-size: 14px;
`;

export const InputLabel = styled.label`
   position: absolute;
   top: 50%;
   left: 10px;
   transform: translateY(-50%);
   font-weight: normal;
   transition: all ease-in 0.2s;
   text-align: left;
   color: #8c959d;
   pointer-events: none;
   ${ZIndex.medium};
   ${props => (props.smaller ? smallerLabel : '')};
   will-change: transition, top, font-size;
`;

const smallerInput = css`
    padding: 10px;
    font-size: 14px;
    height: 36px;

    &:focus, &:not([value=""]) {
       padding: 14px 10px 6px 8px;
    }
    &:focus ~ ${InputLabel},
    &:not([value=""]) ~ ${InputLabel} {
   		top: 2px;
    	left: 8px;
      	transform: none;
      	font-size: 10px;
   	}
`;


export const InputElement = styled.input`
    z-index: 1;
    border: 1px solid #e2eaf0;
    border-radius: 4px;
    position: relative;
    background: white;
    font-size: 16px;
    height: 50px;
    width: 100%;
    outline: none;
    padding-left: 10px;
    ${props => (props.error ? inputErrorCss : null)};
   &:focus, &:not([value=""]) {
       padding: 20px 10px 10px;
   }
   &:focus ~ ${InputLabel},
   &:not([value=""]) ~ ${InputLabel}
   {
      top: 8px;
      transform: none;
      font-size: 12px;
   }

   ${props => (props.smaller ? smallerInput : '')};
`;

export const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;
