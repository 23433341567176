import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

// Atoms
import { BaseTable, TableViewWrapper } from './Atoms/TableAtoms';
// Molecules
import TableHeading from './Moleculs/TableHeading';
import TableData from './Moleculs/TableData';
import TableSettings from './Moleculs/TableSettings';
// Organisms
import Scrollbar from '../Scrollbar';


function TableView(props) {
	const {
		header,
		rows,
		handleSort,
		hasSettings,
		handleSelectRow,
		handleSelectAll,
		selectable,
		allSelected,
		headerHeight,
		rowHeight,
		rowClick,
		handleHeaderVisibilityChange,
		noScrollbar,
		withContextMenu,
		sortedBy,
		selectedRow,
	} = props;

	return (
		<TableViewWrapper>
			<Scrollbar noScrollbar={noScrollbar}>
				<BaseTable>
					<TableHeading
						header={header}
						handleSort={handleSort}
						hasSettings={hasSettings}
						handleHeaderVisibilityChange={handleHeaderVisibilityChange}
						handleSelectAll={handleSelectAll}
						allSelected={allSelected}
						height={headerHeight}
						selectable={selectable}
						sortedBy={sortedBy}
					/>
					<TableData
						rowClick={rowClick}
						header={header}
						rows={rows}
						selectable={selectable}
						handleSelectRow={handleSelectRow}
						height={rowHeight}
						withContextMenu={withContextMenu}
						selectedRow={selectedRow}
					/>
				</BaseTable>
			</Scrollbar>
			{hasSettings && (
				<TableSettings
					header={header}
					handleHeaderVisibilityChange={handleHeaderVisibilityChange}
				/>
			)}
			<ReactTooltip />
		</TableViewWrapper>
	);
}

TableView.defaultProps = {
	header: [],
	rows: [],
	hasSettings: true,
	selectable: true,
	handleSort: () => {},
	handleSelectAll: () => {},
	allSelected: false,
	headerHeight: null,
	rowHeight: null,
	noScrollbar: false,
	withContextMenu: false,
	sortedBy: {},
	selectedRow: -1,
	rowClick: null,
};

TableView.propTypes = {
	header: PropTypes.arrayOf(PropTypes.shape({})),
	rows: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.shape({})),
		PropTypes.shape({}),
	]),
	rowClick: PropTypes.func,
	hasSettings: PropTypes.bool,
	selectable: PropTypes.bool,
	handleSort: PropTypes.func,
	handleHeaderVisibilityChange: PropTypes.func,
	handleSelectAll: PropTypes.func,
	handleSelectRow: PropTypes.func,
	allSelected: PropTypes.bool,
	headerHeight: PropTypes.string,
	rowHeight: PropTypes.string,
	noScrollbar: PropTypes.bool,
	withContextMenu: PropTypes.bool,
	sortedBy: PropTypes.shape({}),
	selectedRow: PropTypes.number,
};

export default TableView;
