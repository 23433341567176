import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

// Actions
import { toggleChooseExtensionModal } from '../../../data/ui/UIActions';

// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton } from '../../Atoms/Button';
import { MediumVerticalDivider, BaseDivider } from '../../Atoms/Divider';

// Molecules
import Modal from '../../Molecules/Modal';

class ChooseExtensionModal extends PureComponent {
	componentWillUnmount() {
		const { actions } = this.props;
		actions.toggleChooseExtensionModal(false);
	}

	render() {
		const {
			modalInfo,
			actions,
			onConfirmClick,
		} = this.props;

		const modalInfoJS = modalInfo?.toJS();

		if (modalInfoJS.visible !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={() => actions.toggleChooseExtensionModal(false)}
				size={MODAL_SIZES.SMALL}
			>
				<FlexColumn>
					<ModalTitle>
					Choose Extension
					</ModalTitle>
					<BaseDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={() => onConfirmClick('xls')}>
						xls
						</PrimaryButton>
						<MediumVerticalDivider />
						<PrimaryButton onClick={() => onConfirmClick('csv')}>
						csv
						</PrimaryButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}

ChooseExtensionModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleChooseExtensionModal: PropTypes.func,
	}).isRequired,
	onConfirmClick: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.CHOOSE_EXCHANGE_EXTENSION]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleChooseExtensionModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseExtensionModal));
