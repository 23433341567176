import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// CSS
import { TextBase } from '../../../Css/Css';

// Lib
import { COLORS } from '../../../../lib/constants/Style';

// Atoms
import { FlexRowSpaceBetweenCss } from '../../../Atoms/Flex';

const AccordionLabelWrapper = styled.div`
	height: 60px;
	cursor: pointer;
	${FlexRowSpaceBetweenCss};
`;
const AccordionLabelText = styled.div`
	${({ open }) => open && 'font-weight: bold'};
	${TextBase}
`;

const AccordionLabel = (props) => {
	const {
		open,
		text,
		onClick,
	} = props;
	return (
		<AccordionLabelWrapper onClick={onClick}>
			<AccordionLabelText open={open}>
				{text}
			</AccordionLabelText>
			{!open ? <FontAwesomeIcon icon={faSortDown} color={COLORS.grey} /> : <FontAwesomeIcon icon={faSortUp} color={COLORS.grey} />}
		</AccordionLabelWrapper>
	);
};
AccordionLabel.propTypes = {
	open: PropTypes.bool,
	text: PropTypes.string.isRequired,
};
AccordionLabel.defaultProps = {
	open: false,
};

export default AccordionLabel;
