import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

// Layouts
import PublicLayout from '../layouts/PublicLayout';

// Pages
import SignIn from '../pages/Auth/SignIn';
import SignUp from '../pages/Auth/SignUp';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import { getItem } from '../../lib/localStorage';


const AuthRouter = () => {
	if (getItem('loginInfo')) {
		return <Redirect to="/app" />;
	}

	return (
		<PublicLayout>
			<Switch>
				{/* This route is not needed with Keycloak */}
				{/* <Route */}
				{/* 	path="/auth/callback" */}
				{/* 	component={AuthCallback} */}
				{/* /> */}
				<Route
					exact
					path="/auth/sign-in"
					component={SignIn}
				/>
				<Route
					exact
					path="/auth/sign-up"
					component={SignUp}
				/>
				<Route
					exact
					path="/auth/forgot-password"
					component={ForgotPassword}
				/>
				<Redirect to="/" />
			</Switch>
		</PublicLayout>
	);
};

export default AuthRouter;
