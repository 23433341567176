import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import GlobalHotKeys from '../../../lib/hotkeys';
import { COLORS } from '../../../lib/constants/Style';
// Atoms
import {
	LeftControls, RightControls, TableCommandsWrapper, TableWrapper,
} from '../Table/Atoms/TableAtoms';
import { GrayControlIcon } from '../../Atoms/ControlIcons';
import { MediumVerticalDivider } from '../../Atoms/Divider';
import VMStatus from '../../Atoms/VMStatus';
import { AlternateLink } from '../../Atoms/Link';
import { RelativeWrapper, SearchFieldWrapper, SelectBoxWrapper } from '../../Atoms/Wrapper';
// Molecules
import SelectBox from '../../Molecules/Input/SelectBox/SelectBox';
import SearchField from '../../Molecules/Input/SearchField/SearchField';
// Organisms
import TableView from '../Table/TableView';
import CreateExchangeModal from '../Modals/CreateExchangeModal';
// Actions
import {
	deleteExchanges,
} from '../../../data/exchanges/ExchangesActions';
import { toggleCreateExchangeModal, toggleModal } from '../../../data/ui/UIActions';
// Selectors
import { getAllExchangeStatuses, getExchanges } from '../../../data/exchanges/selectors';
import { exchangesHeader } from '../../../data/placeholderData/exchangesHeader';

// Hooks
import useSearch from '../../Hooks/useSearch';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';
import useSortBy from '../../Hooks/useSortBy';
import useSelect from '../../Hooks/useSelect';
import { NoContentInfo } from '../../Atoms/Text';
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal';
import { MODALS } from '../../../lib/constants/General';
import Loader from '../../Molecules/Loader';

function ExchangeInstancesTable() {
	// redux data
	const exchanges = useSelector(state => getExchanges(state));
	const loading = useSelector(state => state.getIn(['exchange', 'exchangesLoading']));
	const statuses = useSelector(state => getAllExchangeStatuses(state));
	const modalOpened = useSelector(state => state.getIn(['ui', 'modal', MODALS.CREATE_EXCHANGE_MODAL, 'visible']));
	// local state
	const [tableRows, setTableRows] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState('All');
	const [tableHeader, setTableHeader] = useState([...exchangesHeader]);
	const initialRows = useMemo(() => exchanges.toList().toJS(), [exchanges]);
	// dispatch
	const dispatch = useDispatch();

	// map initial data to local state
	useEffect(() => {
		setTableRows(initialRows);
	}, [initialRows]);

	// @NOTE Table specific logic for mapping data to table rows
	const getDataToRender = useCallback(r => r.map((row, i) => ({
		data: [
			{
				render: () => <AlternateLink to={`/app/exchanges/${row.clientExchangeId}`}>{row.displayId}</AlternateLink>,
				accessor: row.displayId,
			},
			row.name,
			row.exchangeType?.name,
			{
				render: () => <VMStatus status={row.machineInstance?.status} />,
				accessor: row.machineInstance?.status,
			},
		],
		metaData: {
			selected: false,
			key: row.clientExchangeId,
			index: i,
			link: `/app/exchanges/${row.clientExchangeId}`,
		},
	})), []);

	const { searchInputValue, setSearchInputValue } = useSearch(initialRows, setTableRows, tableHeader);

	// Custom filter
	useEffect(() => {
		if (selectedStatus === 'All') {
			setTableRows(initialRows);
		} else {
			setTableRows(initialRows.filter(row => row.machineInstance.status === selectedStatus));
		}
	}, [initialRows, selectedStatus]);

	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, tableRows);
	const { selectRowCallback, handleSelectAll, allSelected } = useSelect(dataToRender, setDataToRender);

	const toggleModalCallback = useCallback(() => {
		dispatch(toggleCreateExchangeModal());
	}, [dispatch]);

	// Selected ids, used on and delete actions
	const selectedExchangeInstanceIds = useMemo(() => dataToRender
		.filter(row => row.metaData.selected)
		.map((item => item.metaData.key)), [dataToRender]);

	// Callback for deleting exchange instance
	const handleDeleteExchangeInstance = useCallback(() => {
		dispatch(toggleModal(MODALS.DELETE_CONFIRMATION_MODAL, true, { title: 'Delete Exchange(s)', message: 'Please confirm that you would like to delete the selected exchange(s)' }));
	}, [dispatch]);

	// prevent actions if create exchange modal is opened
	const preventAction = useCallback((action) => {
		if (!modalOpened) {
			return action;
		}
		return () => {};
	}, [modalOpened]);
	const handlers = {
		DELETE_EXCHANGE: preventAction(handleDeleteExchangeInstance),
		CREATE_EXCHANGE: preventAction(toggleModalCallback),
	};
	const keyMap = {
		DELETE_EXCHANGE: 'a+d',
		CREATE_EXCHANGE: 'a+c',
	};
	const getTableMessage = () => {
		if (typeof loading === 'undefined' || loading === true) {
			return <Loader loading />;
		}
		if (exchanges?.size === 0) {
			return "You don't have any exchanges";
		}
		if (dataToRender?.length === 0) {
			if (searchInputValue !== '') {
				return `No exchanges match the search term ${searchInputValue}`;
			}
		}
		return null;
	};
	const message = getTableMessage();
	return (
		<TableWrapper className="exchange-instances-table">
			<GlobalHotKeys
				handlers={handlers}
				keyMap={keyMap}
				allowChanges
			>
				<TableCommandsWrapper>
					<LeftControls>
						<GrayControlIcon
							name="plusCircle"
							className="add-exchange-icon"
							onClick={toggleModalCallback}
							data-tip="Create exchange"
						/>
						<MediumVerticalDivider />
						<GrayControlIcon
							name="trashCan"
							onClick={handleDeleteExchangeInstance}
							data-tip="Delete exchange(s)"
						/>
					</LeftControls>
					<RightControls>
						<SelectBoxWrapper>
							<SelectBox
								smaller
								noBorder
								backgroundColor={COLORS.lightestGrey}
								label="Status:"
								options={statuses}
								selected={selectedStatus}
								onClick={setSelectedStatus}
							/>
						</SelectBoxWrapper>
						<SearchFieldWrapper>
							<SearchField
								value={searchInputValue}
								onChange={setSearchInputValue}
								placeholder="Search"
							/>
						</SearchFieldWrapper>
					</RightControls>
				</TableCommandsWrapper>
				<RelativeWrapper>
					<TableView
						rowClick={r => dispatch(push(r.metaData.link))}
						sortedBy={sortedBy}
						header={tableHeader}
						rows={dataToRender}
						hasSettings={false}
						handleSort={handleSort}
						selectable
						handleSelectAll={handleSelectAll}
						handleSelectRow={selectRowCallback}
						allSelected={allSelected}
					/>
				</RelativeWrapper>
				{message && (
					<NoContentInfo>
						{message}
					</NoContentInfo>
				)}
				<CreateExchangeModal />
				<DeleteConfirmationModal onConfirm={() => deleteExchanges(selectedExchangeInstanceIds)(dispatch)} />
			</GlobalHotKeys>
		</TableWrapper>
	);
}
export default ExchangeInstancesTable;
