import React from 'react';
import { useSelector } from 'react-redux';

// Atoms
import { FlexColumn } from '../../Atoms/Flex';
import { BaseDivider } from '../../Atoms/Divider';
// Molecules
import MarketStatus from '../../Molecules/MarketStatus';
// Selectors
import { getActiveExchange } from '../../../data/exchanges/selectors';

const MarketDataReplyHeader = () => {
	const activeExchange = useSelector(getActiveExchange);

	return (
		<FlexColumn>
			<BaseDivider />
			<MarketStatus
				ipAddress={activeExchange?.get('machineInstance')?.get('ipAddress')}
				vmStatus={activeExchange?.get('machineInstance')?.get('status')}
				exchangeStatus={activeExchange?.get('status')}
			/>
			<BaseDivider />
		</FlexColumn>
	);
};

export default MarketDataReplyHeader;
