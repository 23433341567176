import ORDER_BOOK_TYPES from './OrderBooksTypes';
import Api from '../../lib/api';
import { createFormDataFromObject } from '../../lib/helpers';
import { toggleModal, updateModal } from '../ui/UIActions';
import { MODALS } from '../../lib/constants/General';

function fetchOrderBooksStart() {
	return {
		type: ORDER_BOOK_TYPES.FETCH_ORDER_BOOKS,
	};
}
function fetchOrderBooksSuccess(payload) {
	return {
		type: ORDER_BOOK_TYPES.FETCH_ORDER_BOOKS_SUCCESS,
		payload,
	};
}
function fetchOrderBooksFail(error) {
	return {
		type: ORDER_BOOK_TYPES.FETCH_ORDER_BOOKS_FAIL,
		payload: {
			error,
		},
	};
}

export function fetchOrderBooks(clientExchangeId) {
	return async (dispatch) => {
		try {
			dispatch(fetchOrderBooksStart());
			const response = await Api.get(`exchanges/${clientExchangeId}/order-books`);
			dispatch(fetchOrderBooksSuccess(response.data));
		} catch (err) {
			dispatch(fetchOrderBooksFail(err));
		}
	};
}

function addNewOrderBookSuccess() {
	return {
		type: ORDER_BOOK_TYPES.ADD_NEW_ORDER_BOOK_SUCCESS,
	};
}
function addNewOrderBookFail(payload) {
	return {
		type: ORDER_BOOK_TYPES.ADD_NEW_ORDER_BOOK_FAIL,
		payload,
	};
}
export function addNewOrderBook(clientExchangeId, data) {
	return async (dispatch) => {
		try {
			const response = await Api.post(`exchanges/${clientExchangeId}/order-books`, createFormDataFromObject(data));
			dispatch(addNewOrderBookSuccess());
			return response;
		} catch (err) {
			dispatch(addNewOrderBookFail(err?.response?.data?.message));
			throw err;
		}
	};
}

function editOrderBookSuccess() {
	return {
		type: ORDER_BOOK_TYPES.EDIT_ORDER_BOOK_SUCCESS,
	};
}
function editOrderBookFail(payload) {
	return {
		type: ORDER_BOOK_TYPES.EDIT_ORDER_BOOK_FAIL,
		payload,
	};
}
export function editOrderBook(data) {
	return async (dispatch) => {
		try {
			await Api.put('exchanges/order-books', createFormDataFromObject(data));
			dispatch(editOrderBookSuccess());
		} catch (err) {
			dispatch(editOrderBookFail(err?.response?.data?.message));
			throw err;
		}
	};
}

function deleteOrderBookSuccess(deleteIds) {
	return {
		type: ORDER_BOOK_TYPES.DELETE_ORDER_BOOK_SUCCESS,
		payload: {
			deleteIds,
		},
	};
}
export function deleteOrderBook(deleteIds) {
	return async (dispatch) => {
		try {
			await Api.delete('exchanges/order-books', createFormDataFromObject({ deleteIds }));
			dispatch(deleteOrderBookSuccess(deleteIds));
			dispatch(toggleModal(MODALS.DELETE_CONFIRMATION_MODAL, false));
		} catch (err) {
			dispatch(updateModal(MODALS.DELETE_CONFIRMATION_MODAL, { error: err?.response?.data?.message }));
		}
	};
}
export function clearAddOrderBookError() {
	return {
		type: ORDER_BOOK_TYPES.CLEAR_ADD_ORDER_BOOK_ERROR,
	};
}
export function resetOrderBooks() {
	return {
		type: ORDER_BOOK_TYPES.CLEAR_ORDER_BOOKS,
	};
}
