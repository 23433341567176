import React from 'react';
import PropTypes from 'prop-types';
import {
	LeftSection, RightSection, SectionWrapper, TwoSideSectionWrapper,
} from '../../Atoms/WebSite/Wrappers';

const TwoSideSection = ({ flip, leftSection, rightSection }) => (
	<SectionWrapper>
		<TwoSideSectionWrapper flip={flip}>
			<LeftSection>
				{leftSection}
			</LeftSection>
			<RightSection>
				{rightSection}
			</RightSection>
		</TwoSideSectionWrapper>
	</SectionWrapper>
);

TwoSideSection.defaultProps = {
	flip: false,
	leftSection: null,
	rightSection: null,
};

TwoSideSection.propTypes = {
	flip: PropTypes.bool,
	leftSection: PropTypes.node,
	rightSection: PropTypes.node,
};

export default TwoSideSection;
