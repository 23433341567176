import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

// Lib
import { DEVICE } from '../../../lib/constants/Device';
import { COLORS } from '../../../lib/constants/Style';

// Images
import arrow from '../../../assets/images/MarketingSite/arrow.png';
import logo from '../../../assets/images/logo.png';

// Atoms
import { LargeDivider, BigDivider, SmallDivider } from '../../Atoms/Divider';

const Image = styled.img`
	height: 24px;
	${prop => (prop.left ? 'transform: rotate(180deg)' : '')}
`;

const SectionWrapper = styled.div`
	padding: 160px 80px;
	background-color: ${COLORS.yellow};

	@media ${DEVICE.tablet} {
		padding: 150px 25px;
	}

	@media ${DEVICE.mobileM} {
		padding: 50px 15px;
	}
`;

const ColumnItemWrapper = styled.div`
	position: relative;
	text-align: center;
	height: 400px;
`;

const Arrows = styled.span`
	${props => (props.left ? 'left: 0px;' : 'right: 0px;')};
	position: absolute;
	cursor: pointer;
	z-index: 10;
	top: 50%;

	@media ${DEVICE.mobileL} {
		top: 0;
	}
`;

const Wrapper = styled.div`
	width: 100%;
	color: #353944;
	max-width: 850px;
	font-size: 24px;
	margin: 0 auto auto;
	z-index: 1;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media ${DEVICE.mobileL} {
		font-size: 14px;
	}
`;

const Item = styled.div`
	height: 0;
	${props => props.active && css`
		height: auto;
		transition: all 1s ease-in;
	`};
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	border-radius: 5px;
`;

const ItemTitle = styled.h3`
	font-size: 18px;

	@media ${DEVICE.mobileL} {
		font-size: 14px;
	}
`;

const Quotes = styled.p`
	width: 100%;
	margin: auto;
	max-width: 850px;
	line-height: 1.7;
`;

const ItemSubtitle = styled.h4`
	font-size: 16px;
	color: ${COLORS.darkestGrey};
	@media ${DEVICE.mobileL} {
		font-size: 12px;
	}
`;

const TestimonialLogo = styled.img`
	width: 40px;
	height: 40px;
	margin: 0 auto;
    filter: grayscale(40%) brightness(20%);
    opacity: 0.1;
`;

const TestimonialImage = styled.img`
	width: 27px;
	height: 27px;
	border-radius: 4px;
	background: ${COLORS.blue}
`;

const renderColumnItem = (active, title, subtitle, description) => (
	<Item active={active}>
		<Wrapper limit>
			<Quotes>{description}</Quotes>
			<LargeDivider />
			<LargeDivider />
			<TestimonialImage src={logo} alt="testimonial image"/>
			<SmallDivider />
			<ItemTitle>{title}</ItemTitle>
			<ItemSubtitle>{subtitle}</ItemSubtitle>
		</Wrapper>
	</Item>
);

const Testimonials = () => {
	const [show, toggleShow] = useState(true);

	function toggleTestimonials() {
		toggleShow(!show);
	}

	useEffect(() => {
		const timer = setInterval(() => {
			toggleTestimonials();
		}, 5000);

		return function cleanup() {
			clearInterval(timer);
		};
	});

	return (
		<SectionWrapper>
			<ColumnItemWrapper>
				<Arrows left onClick={toggleTestimonials}>
					<Image src={arrow} left />
				</Arrows>
				<Arrows onClick={toggleTestimonials}>
					<Image src={arrow} />
				</Arrows>
				<TestimonialLogo src={logo} alt="ETP Logo"/>
				<BigDivider />
					Impeccable service
				<BigDivider />
				{show && renderColumnItem(
					true,
					'Mark Stoneberg',
					'CEO at Stoneberg Inc',
					`ETP Markets support enterprise auditing and compliance reporting, enabling multiple users to collect
				test data across multiple trading components, record test sessions, store documents, and generate
				reports.`
				)}
				{!show && renderColumnItem(
					true,
					'Oskar Bard',
					'Big Boss',
					`ETP Markets support enterprise auditing and compliance reporting, enabling multiple users to collect
				test data across multiple trading components, record test sessions, store documents, and generate
				reports.`
				)}
			</ColumnItemWrapper>
		</SectionWrapper>
	);
};

export default Testimonials;
