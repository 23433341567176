import React from 'react';
import styled from 'styled-components';

// Atoms
import { PageTitle } from '../../../Atoms/Title';
import { FlexColumn, FlexRow } from '../../../Atoms/Flex';
import { BaseDivider } from '../../../Atoms/Divider';

// Organisms
import Breadcrumb from '../../Breadcrumb';

// Component Specific
const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const PageWrapper = (props) => {
	const {
		breadcrumbs,
		title,
		children,
		pageWrapperRightSide,
	} = props;
	return (
		<Wrapper>
			<FlexRow justifyContent="space-between" alignItems="flex-start">
				<FlexColumn>
					<Breadcrumb crumbs={breadcrumbs}/>
					<PageTitle>
						{title}
					</PageTitle>
				</FlexColumn>
				{pageWrapperRightSide}
			</FlexRow>
			<BaseDivider />
			{children}
		</Wrapper>
	);
};
export default PageWrapper;
