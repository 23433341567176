import DICTIONARIES_TYPES from './DictionariesTypes';
import Api from '../../lib/api';
import { env } from '../../env';

function fetchStart() {
	return {
		type: DICTIONARIES_TYPES.DICTIONARIES_FETCH,
	};
}
function fetchSuccess(payload) {
	return {
		type: DICTIONARIES_TYPES.DICTIONARIES_FETCH_SUCCESS,
		payload,
	};
}
function fetchFail(payload) {
	return {
		type: DICTIONARIES_TYPES.DICTIONARIES_FETCH_FAIL,
		payload,
	};
}

export function resetExchangeDictionary() {
	return {
		type: DICTIONARIES_TYPES.DICTIONARIES_RESET,
	};
}

function fetchDictionaries(fix) {
	return (dispatch) => {
		dispatch(fetchStart());
		Api.get(`${env.REACT_APP_BACKEND_URL}/assets/fixJson/${fix}.json`, {}, true)
			.then(response => dispatch(fetchSuccess(response.data)))
			.catch(error => dispatch(fetchFail(error)));
	};
}

export default fetchDictionaries;
