import styled, { css } from 'styled-components';
import { BaseText, FontFamilyExo, TextBase, TextBaseSmall, TextSmall, ZIndex } from '../../../Css/Css';

const smallerArrow = css`
    top: 17px;
`;

export const ArrowDown = styled.div`
    position: absolute;
    right: 10px;
    top: 25px;
    width: 0; 
    height: 0; 
    z-index: 10;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #b9c1c9;
    ${props => (props.smaller ? smallerArrow : '')}
`;

const smallerLabel = css`
    font-size: 12px;
`;

export const SelectBoxLabel = styled.div`
   position: absolute;
   font-weight: normal;
   transition: all ease 0.2s;
   text-align: left;
   color: #8c959d;
   pointer-events: none;
   z-index: 1;
   top: 50%;
   left: 10px;
   transform: translateY(-50%);
   ${props => (props.smaller ? smallerLabel : '')};
`;

export const SelectBoxWrapper = styled.div`
    position: relative;
    width: 100%;
    outline: none;

    :hover .arrow {
        border-top-color: #e8ad01;
    }

    :hover .select {
        color: #e8ad01;
    }
`;

const inputErrorCss = css`
    border: 1px solid #d73e3e;
`;

const smallerInput = css`
    ${TextBaseSmall};
    height: 36px;

    &:focus, &:not([value=""]) {
       padding: 14px 10px 6px 8px;
    }
    &:focus ~ ${SelectBoxLabel},
    &:not([value=""]) ~ ${SelectBoxLabel}
   {
      top: 3px;
      left: 9px;
      transform: none;
      ${BaseText};
      font-size: 10px;
      color: #8c959d;
   }
`;

export const Select = styled.input`
    z-index: 1;
    border: 1px solid #e2eaf0;
    border-radius: 4px;
    position: relative;
    background: white;
    ${FontFamilyExo};
    ${TextBase};
    height: 50px;
    width: 100%;
    outline: none;
    cursor: pointer;
    ${props => (props.error ? inputErrorCss : null)};

   &:focus, &:not([value=""]) {
       padding: 20px 10px 8px;
   }
   &:not([value=""]) ~ ${SelectBoxLabel}
   {
      top: 6px;
      transform: none;
      ${TextSmall};
   }

   ${props => (props.smaller ? smallerInput : '')};
`;

export const Options = styled.div`
    padding: 5px 0;
    text-align: left;
    cursor: pointer;
    ${ZIndex.high};    
    ${props => props.smaller && css`
        ${TextBaseSmall};
    `}
    
    :hover {
        color: #e8ad01;
    }

    :first-child {
        padding-top: 0;
    }

    :last-child {
        padding-bottom: 0;
    }
`;

export const OptionWrapper = styled.div`
    display: ${props => (props.showOptions ? 'block' : 'none')};
    max-height: 140px;
    overflow: auto;
    position: absolute;
    border: 1px solid #e2eaf0;
    top: calc(100% - 1px);
    width: 100%;
    left: 0%;
    padding: 10px 10px;
    ${ZIndex.high};
    background-color: #ffffff;
    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #ffc10e;
    }
`;
