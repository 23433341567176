import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import { FlexRow } from '../Atoms/Flex';
import { GrayLabel } from '../Atoms/Text';
import { BaseVerticalDivider } from '../Atoms/Divider';
import { ExchangeStatusSmall, VMStatusSmall } from '../Atoms/Status';
import { EXCHANGE_STATUS, VIRTUAL_MACHINE_STATUS } from '../../lib/constants/Exchange';

const MarketStatus = (props) => {
	const {
		ipAddress,
		vmStatus,
		exchangeStatus,
	} = props;
	return (
		<FlexRow justifyContent="flex-end">
			<GrayLabel>IP:</GrayLabel>
			<GrayLabel>{ipAddress}</GrayLabel>
			<BaseVerticalDivider />
			<GrayLabel>VM:</GrayLabel>
			<VMStatusSmall status={vmStatus}>{vmStatus}</VMStatusSmall>
			<BaseVerticalDivider />
			<GrayLabel>EX:</GrayLabel>
			<ExchangeStatusSmall status={exchangeStatus}>{exchangeStatus}</ExchangeStatusSmall>
		</FlexRow>
	);
};

MarketStatus.defaultProps = {
	ipAddress: '',
	vmStatus: VIRTUAL_MACHINE_STATUS.NA,
	exchangeStatus: EXCHANGE_STATUS.NA,
};

MarketStatus.propTypes = {
	ipAddress: PropTypes.string,
	vmStatus: PropTypes.string,
	exchangeStatus: PropTypes.string,
};
export default MarketStatus;
