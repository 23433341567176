import styled from 'styled-components';
import { FlexColumnBase } from '../../../Atoms/Flex';
import { PrimaryButtonCSS } from '../../../Atoms/Button';
import { TextBaseSmall, TextBig } from '../../../Css/Css';
import { COLORS } from '../../../../lib/constants/Style';

export const ModalTitle = styled.div`
  ${TextBig};
  color: ${COLORS.lightBlack};
  text-align: left;
`;
export const ConfirmButton = styled.button`
	${PrimaryButtonCSS};
	width: 100px;
	align-self: center;
`;
export const CopyToClipBoardWrapper = styled.div`
	flex: 2;
	color: ${COLORS.darkGrey};
`;
export const CopyToClipBoardText = styled.span`
	border-bottom: 1px dotted ${COLORS.darkGrey};
	cursor: pointer;
`;
export const CopyText = styled.div`
	padding: 10px;
	width: 100%;
	white-space: nowrap;
`;
export const ClipboardInput = styled.div`
	border-radius: 4px;
    background-color: #f5f4f4;
    overflow-y: hidden;
    min-width: 80%;
    text-align: left;
    ${TextBaseSmall};
  	color: ${COLORS.lightBlack};
  	width: 100%;
`;
export const ClipboardInputWrapper = styled.div`
	flex: 10;
`;
export const GrayText = styled.div`
	${TextBaseSmall};
  	color: ${COLORS.darkGrey};
  	width: 100%;
  	text-align: left;
`;
export const TableWrapper = styled.div`
	 ${FlexColumnBase};
	 height: 80%;
`;
export const BottomActionsWrapper = styled.div`
	${FlexColumnBase};
 	height: 20%;
 	justify-content: flex-end;
`;
