import React, { PureComponent, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';
// Atoms
import { ModalErrorMessage, ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import {
	MediumDivider,
	MediumVerticalDivider, BaseDivider,
} from '../../Atoms/Divider';
import { FullWidthFlexRow } from '../../Atoms/Wrapper';
// Molecules
import Modal from '../../Molecules/Modal';
import Input from '../../Molecules/Input/Input/Input';
// Actions
import { toggleAddTradingGatewayModal, toggleAddSessionModal } from '../../../data/ui/UIActions';
import { addNewTradingGateway, fetchTradingGateways } from '../../../data/tradingGateways/TradingGatewaysActions';
import SessionsTable from '../TableModels/SessionsTable';
import CheckBoxInput from '../../Molecules/Input/CheckBox/CheckBox';
import AddSessionModal from './AddSessionModal';
import EditSessionModal from './EditSessionModal';

class AddTradingGatewayModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			resetOnLogon: true,
			sessions: [],
			error: null,
		};
		this.name = createRef();
		this.port = createRef();
		this.overrideFields = createRef();
	}

	deleteSessions = (ids) => {
		const {
			sessions,
		} = this.state;
		let newState = [...sessions];
		for (let i = 0; i < ids.length; i += 1) {
			const id = ids[i];
			newState = newState.filter(s => s.fixSessionId !== id);
		}
		this.setState({ sessions: newState });
	};

	editSession = (session) => {
		const {
			sessions,
		} = this.state;
		const index = sessions.findIndex(s => s.fixSessionId === session.fixSessionId);
		this.setState((prevState) => {
			const newState = [...prevState.sessions];
			newState[index] = session;
			return {
				sessions: newState,
			};
		});
	};

	addSession = (session) => {
		// we need to create some id so we can differentiate sessions when deleting, creating and editing them
		session.fixSessionId = uuid();
		this.setState(prevState => ({
			sessions: [
				...prevState.sessions,
				session,
			],
		}));
	};

	handleAddTradingGateway = () => {
		const {
			match,
			actions,
		} = this.props;
		const {
			resetOnLogon,
			sessions,
		} = this.state;
		const fixSessions = sessions.map(session => ({
			fixSessionId: null,
			targetCompId: session.targetCompId,
			senderCompId: session.senderCompId,
			targetSubId: session.targetSubId,
			senderSubId: session.senderSubId,
			targetLocationId: session.targetLocationId,
			senderLocationId: session.senderLocationId,
			startTime: session.startTime,
			stopTime: session.stopTime,
			heartbeatInterval: session.heartbeatInterval,
			reconnectInterval: session.reconnectInterval,
		}));
		const data = {
			trGwyId: null,
			displayId: null,
			cancelOnDisconnect: false,
			name: this.name.current.getValue(),
			port: this.port.current.getValue(),
			overrideFields: this.overrideFields.current.getValue(),
			resetOnLogon,
			fixSessions,
		};
		const clientExchangeId = match.params.slug;
		actions.addNewTradingGateway(clientExchangeId, data).then(() => {
			this.handleCloseModal();
			actions.fetchTradingGateways(match.params.slug);
		}).catch((err) => {
			this.setState({ error: err?.response?.data?.message });
		});
	};

	handleCloseModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleAddTradingGatewayModal(false);
		this.setState({ error: null, sessions: [] });
	};

	handleResetOnLogonClick = () => {
		this.setState(prevState => ({
			resetOnLogon: !prevState.resetOnLogon,
		}));
	};

	render() {
		const {
			resetOnLogon,
			sessions,
			error,
		} = this.state;
		const {
			modalInfo,
			editSessionModalInfo,
		} = this.props;
		if (modalInfo?.get('visible') !== true) {
			return false;
		}
		const ids = editSessionModalInfo?.get('data');
		const sessionForEdit = sessions.find(s => s.fixSessionId === ids?.toJS()?.[0]);
		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleCloseModal}
				size={MODAL_SIZES.WIDE}
			>
				<FlexColumn>
					<ModalTitle>
						Add Trading Gateway
					</ModalTitle>
					{error && <ModalErrorMessage>{error}</ModalErrorMessage>}
					<MediumDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Name (max. 40 characters)"
							type="header"
							name="name"
							ref={this.name}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Port (integer >= 1024)"
							type="header"
							name="instrumentType"
							ref={this.port}
						/>
					</FullWidthFlexRow>
					<MediumDivider />
					<SessionsTable
						sessions={sessions}
						setSessions={this.setSessions}
						deleteSessions={this.deleteSessions}
					/>
					<MediumDivider />
					<FlexRow className="add-tr-modal-reset-seq-number">
						<CheckBoxInput
							value={resetOnLogon}
							onChange={this.handleResetOnLogonClick}
						/>
						<span>Reset sequence numbers on logon</span>
					</FlexRow>
					<MediumDivider />
					<FullWidthFlexRow className="add-tr-modal-override-fields">
						<Input
							placeholder="Override fields (tag=value;tag=value)"
							type="header"
							name="overrideFields"
							ref={this.overrideFields}
						/>
					</FullWidthFlexRow>
					<MediumDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton
							onClick={this.handleAddTradingGateway}
							className="add-tr-modal-save-button"
						>
							Save
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={this.handleCloseModal}>
							Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
				<AddSessionModal
					addSession={this.addSession}
				/>
				<EditSessionModal
					editSession={this.editSession}
					session={sessionForEdit}
				/>
			</Modal>
		);
	}
}

AddTradingGatewayModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		addNewTradingGateway: PropTypes.func,
		fetchTradingGateways: PropTypes.func,
		toggleAddTradingGatewayModal: PropTypes.func,
		toggleAddSessionModal: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
	editSessionModalInfo: PropTypes.shape({}).isRequired,
};
function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.ADD_TRADING_GATEWAY_MODAL]),
		editSessionModalInfo: state.getIn(['ui', 'modal', MODALS.EDIT_SESSION_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			addNewTradingGateway,
			fetchTradingGateways,
			toggleAddTradingGatewayModal,
			toggleAddSessionModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTradingGatewayModal));
