import React, { Fragment } from 'react';

// Atoms
import { TableItem, TableWrapper, OverflowWrapper } from '../../Atoms/WebSite/Table';
import { GreyText } from '../../Atoms/WebSite/Text';
import { SmallDivider } from '../../Atoms/Divider';
import { PrimaryButtonLink } from '../../Atoms/Link';

const data = [
	{
		name: 'Sites',
		personalValue: 2,
		professionValue: 10,
	},
	{
		grey: true,
		name: 'Staging sites',
		personalValue: 4,
		professionValue: 10,
	},
	{
		name: 'SSD storage',
		personalValue: '50GB',
		professionValue: '200GB',
	},
	{
		grey: true,
		name: 'Monthly Visitors',
		personalValue: '250,000',
		professionValue: '500,000',
	},
	{
		name: 'Domain names (with yearly bulling)',
		personalValue: null,
		professionValue: 2,
	},
	{
		grey: true,
		name: 'SSl certificates (with yearly billing)',
		personalValue: null,
		professionValue: 2,
	},
];

const Table = () => (
	<OverflowWrapper>
		<TableWrapper>
			<TableItem />
			<TableItem>
				<p>Managed Trading</p>
				<div>
					<GreyText>Personal</GreyText>
				</div>
			</TableItem>
			<TableItem>
				<p>Office Trading</p>
				<div>
					<GreyText>Professional</GreyText>
				</div>
			</TableItem>
			{data.map((row, index) => (
				<Fragment key={index.toString()}>
					<TableItem grey={row.grey}><p>{row.name}</p></TableItem>
					<TableItem grey={row.grey}><p>{row.personalValue}</p></TableItem>
					<TableItem grey={row.grey}><p>{row.professionValue}</p></TableItem>
				</Fragment>
			))}
			<TableItem />
			<TableItem>
				<p>$20/mo</p>
				<SmallDivider double />
				<PrimaryButtonLink to="/auth/sign-up">Join ETP Markets</PrimaryButtonLink>
			</TableItem>
			<TableItem>
				<p>$60/mo</p>
				<SmallDivider double />
				<PrimaryButtonLink to="/auth/sign-up">Join ETP Markets</PrimaryButtonLink>
			</TableItem>
		</TableWrapper>
	</OverflowWrapper>
);

export default Table;
