import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { getActiveExchange, getUsersForActiveExchange } from '../../data/exchanges/selectors';
import { SelectBoxWrapper } from '../Atoms/Wrapper';
import SelectBox from './Input/SelectBox/SelectBox';
import { setActiveUser } from '../../data/exchanges/ExchangesActions';

const ViewAsMemberBox = ({ className }) => {
	const users = useSelector(state => getUsersForActiveExchange(state));
	const selectedMember = useSelector(state => getActiveExchange(state).get('selectedMember'));
	const dispatch = useDispatch();

	if (!selectedMember) {
		dispatch(setActiveUser({
			render: '99999 (UI)',
			key: '99999',
		}));
	}
	return (
		<SelectBoxWrapper className={className}>
			<SelectBox
				onClick={user => dispatch(setActiveUser(user))}
				smaller
				label="View as member"
				options={users}
				selected={selectedMember}
			/>
		</SelectBoxWrapper>
	);
};

export default ViewAsMemberBox;
