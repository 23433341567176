import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import { HeaderRightItemsWrapper } from './Atoms';
import { MediumVerticalDivider } from '../../Atoms/Divider';
import { FlexRow } from '../../Atoms/Flex';
import { AccountStatusButton } from '../../Molecules/UserMenu/Atoms';
import { BaseLink } from '../../Atoms/Link';

// Molecules
import UserMenu from '../../Molecules/UserMenu/UserMenu';

// Organisms
import ShortcutsModal from '../Modals/ShortcutsModal/ShortcutsModal';

// Actions
import Help from '../../Molecules/Help';
import { getItem } from '../../../lib/localStorage';

const HeaderRightSide = (props) => {
	const {
		isPublic, reverse, onClick,
	} = props;

	return (
		<HeaderRightItemsWrapper isPublic={isPublic} reverse={reverse}>
			<Help />
			<FlexRow>
				{!getItem('loginInfo') && (
					<BaseLink to="/auth/sign-up">
						<AccountStatusButton hide={reverse}>START TRIAL</AccountStatusButton>
					</BaseLink>
				)}
				<MediumVerticalDivider double />
				<UserMenu onClick={onClick} />
			</FlexRow>
			<ShortcutsModal />
		</HeaderRightItemsWrapper>
	);
};

HeaderRightSide.defaultProps = {
	isPublic: null,
	reverse: false,
	onClick: () => {
	},
};

HeaderRightSide.propTypes = {
	isPublic: PropTypes.bool,
	reverse: PropTypes.bool,
	onClick: PropTypes.func,
};

export default HeaderRightSide;
