import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Molecules
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import ExchangeInstancesTable from '../../components/Organisms/TableModels/ExchangeInstancesTable';

// Actions
import { fetchCloudLocations, fetchDroplets } from '../../data/machineInstances/MachineInstanceActions';
import {
	fetchAvailableTypes,
	getFullInfoAboutExchanges,
	getStatusForAllInstances,
	fetchExchanges,
} from '../../data/exchanges/ExchangesActions';


class Dashboard extends PureComponent {
	constructor(props) {
		super(props);
		this.timeoutId = null;
	}

	componentDidMount() {
		const {
			actions,
		} = this.props;
		actions.fetchDroplets();
		actions.fetchCloudLocations();
		actions.fetchAvailableTypes();
		actions.fetchExchanges().then(() => {
			this.pollForMachineInstancesStatus();
		});
	}


	componentWillUnmount() {
		clearTimeout(this.timeoutId);
	}

	pollForMachineInstancesStatus = () => {
		const {
			actions,
		} = this.props;
		actions.getStatusForAllInstances().then(() => {
			clearTimeout(this.timeoutId);
			this.timeoutId = setTimeout(() => this.pollForMachineInstancesStatus(), 4000);
		}).catch(() => {});
	};

	render() {
		return (
			<PageWrapper
				breadcrumbs={[]}
				title="Exchange Instances"
			>
				<DocumentTitle title="ETP Markets" />
				<ExchangeInstancesTable />
			</PageWrapper>
		);
	}
}

Dashboard.propTypes = {
	actions: PropTypes.shape({
		fetchDroplets: PropTypes.func.isRequired,
		fetchCloudLocations: PropTypes.func.isRequired,
		fetchAvailableTypes: PropTypes.func.isRequired,
		getFullInfoAboutExchanges: PropTypes.func.isRequired,
		getStatusForAllInstances: PropTypes.func.isRequired,
	}).isRequired,
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		fetchDroplets,
		fetchCloudLocations,
		fetchAvailableTypes,
		getFullInfoAboutExchanges,
		getStatusForAllInstances,
		fetchExchanges,
	}, dispatch),
});
export default connect(null, mapDispatchToProps)(Dashboard);
