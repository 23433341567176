import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Constants
import { COLORS } from '../../lib/constants/Style';

// CSS
import { TextBaseSmall, TextSmall } from '../Css/Css';

// Atoms
import { FlexRow } from '../Atoms/Flex';
import { baseLink } from '../Atoms/Link';

// Component Specific
const Wrapper = styled(FlexRow)`
	padding: 13px 0 7px 0;
	letter-spacing: 0.2px;
	${TextBaseSmall};
	color: ${COLORS.darkGrey};
`;

const BreadcrumbLink = styled(Link)`
	${baseLink};
	${TextSmall};
	color: ${COLORS.darkGrey};
	text-transform: capitalize;
	margin: 0px 5px;
	font-size: 14px;
`;

class Breadcrumb extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			crumbs,
			location: {
				pathname,
			},
		} = this.props;
		const notRoot = pathname.includes('/exchanges/');
		let link = '/app';

		return (
			<Wrapper>
				{notRoot && (
					<BreadcrumbLink to="/app/exchanges">Exchanges</BreadcrumbLink>
				)}
				{crumbs.map((crumb) => {
					link += `/${crumb.link}`;
					return (
						[
							notRoot ? '/' : '',
							<BreadcrumbLink to={link} key={`breadcrumb-${crumb.name}`}>{crumb.name}</BreadcrumbLink>,
						]
					);
				})}
			</Wrapper>
		);
	}
}

Breadcrumb.defaultProps = {
	crumbs: [],
};

// export default connect(mapStateToProps, null)(Breadcrumb);
export default withRouter(Breadcrumb);
