import React from 'react';
import {
	Redirect, Route, Switch,
} from 'react-router-dom';

// Routers
// @TODO Danilo - Here we should use async call to import Private and Public router as "separate files"
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';
import AuthRouter from './AuthRouter';

const MainRouter = () => (
	<Switch>
		<Route
			path="/auth"
			component={AuthRouter}
		/>
		<Route
			path="/app"
			component={PrivateRouter}
		/>
		<Route
			path="/"
			component={PublicRouter}
		/>
		<Redirect to="/auth/sign-in" />
	</Switch>
);

export default MainRouter;
