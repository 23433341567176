import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

// Actions
import { toggleShortcutsModal } from '../../data/ui/UIActions';

// Css
import { TextBase, ZIndex } from '../Css/Css';

// Atoms
import { FlexColumnBase } from '../Atoms/Flex';
import { alternateLink } from '../Atoms/Link';
import { BaseDivider } from '../Atoms/Divider';
import {
	AccountInfoFontAwesomeIcon, HeaderLink,
} from './UserMenu/Atoms';

// Helpers
import { AppTourContext } from '../Context/AppTourContext';
import { useAuth } from '../Context/auth';
import { userTypes } from '../../lib/constants/User';
import { getItem } from '../../lib/localStorage';

const HelpWrapper = styled.div`
	padding-left: 10px;
	padding-right: 40px;
	${TextBase};
	${FlexColumnBase};
	position: relative;
	
	${HeaderLink}{
		${ZIndex.high};
		padding: 14px;
	}
	
`;
const HelpDropDown = styled.div`
	position: absolute;
	${FlexColumnBase};
	background-color: #ffffff;
	box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.14);
	padding: 55px 14px 20px 14px;
	${ZIndex.mediumTwo};
	display: none;
	${props => props.isOpen && 'display: block'}
	
`;
const HelpDropDownLink = styled.span`
	${alternateLink};
	white-space: nowrap;
	cursor: pointer;
`;

function Help({ location }) {
	const dispatch = useDispatch();
	const [helpDropDown, setHelpDropDown] = useState(false);
	const Context = useContext(AppTourContext);
	const { userType } = useAuth();
	const decideTourPage = useCallback(() => {
		let type = '';
		if (location.pathname === '/app/exchanges') {
			type = 'exchanges';
		} else if (location.pathname.match(/^\/app\/exchanges\/[1-9]+[0-9]*\/(ob-table|tg-table|mdg|core-table)$/)) {
			type = 'exchange';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/order-book\/[1-9]+[0-9]*/)) {
			type = 'orderBook';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/order-blotter/)) {
			type = 'orderBlotter';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/exchange-behavior/)) {
			type = 'exchangeBehavior';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/gateways-traffic/)) {
			type = 'messageTraffic';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/exchange-dictionary/)) {
			type = 'exchangeDictionary';
		} else if (location.pathname === '/app/subscription') {
			type = 'subscription';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/market-data-replay/)) {
			type = 'marketDataReplay';
		}
		Context.startTour(type, !(userType === userTypes.WITHOUT_SUBSCRIPTION));
	}, [Context, location.pathname, userType]);
	return (
		<HelpWrapper
			onMouseEnter={() => setHelpDropDown(true)}
			onMouseLeave={() => setHelpDropDown(false)}
		>
			<HeaderLink
				to="/help"
				href="/help"
				onClick={(e) => {
					e.preventDefault();
				}}
			>
				Help
				<AccountInfoFontAwesomeIcon icon={faChevronDown} />
			</HeaderLink>
			<HelpDropDown isOpen={helpDropDown && getItem('loginInfo')}>
				<HelpDropDownLink onClick={decideTourPage}>App tour</HelpDropDownLink>
				<BaseDivider />
				<HelpDropDownLink onClick={() => dispatch(toggleShortcutsModal(true))}>
					Keyboard shortcuts
				</HelpDropDownLink>
			</HelpDropDown>
		</HelpWrapper>
	);
}

Help.propTypes = {
	location: PropTypes.shape({}).isRequired,
};
export default withRouter(Help);
