import MACHINE_INSTANCE_TYPES from './MachineInstanceTypes';
import Api from '../../lib/api';


// Cloud locations
export function fetchCloudLocationsStart() {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_CLOUD_LOCATIONS,
	};
}
export function fetchCloudLocationsSuccess(payload) {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_CLOUD_LOCATIONS_SUCCESS,
		payload,
	};
}
export function fetchCloudLocationsFail() {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_CLOUD_LOCATIONS_FAIL,
	};
}
export function fetchCloudLocations() {
	return async (dispatch) => {
		try {
			dispatch(fetchCloudLocationsStart());
			const response = await Api.get('instances/locations');
			dispatch(fetchCloudLocationsSuccess(response.data));
		} catch (e) {
			dispatch(fetchCloudLocationsFail());
			throw e;
		}
	};
}

// Supported droplet performances
export function fetchDropletsStart() {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_DROPLETS_START,
	};
}
export function fetchDropletSuccess(payload) {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_DROPLETS_SUCCESS,
		payload,
	};
}
export function fetchDropletsFail() {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_DROPLETS_FAIL,
	};
}
export function fetchDroplets() {
	return async (dispatch) => {
		try {
			dispatch(fetchDropletsStart());
			const response = await Api.get('instances/performance');
			dispatch(fetchDropletSuccess(response.data));
		} catch (e) {
			dispatch(fetchDropletsFail());
			throw e;
		}
	};
}

// Instance status
export function fetchInstanceStatusStart() {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_INSTANCE_INFO_START,
	};
}
export function fetchInstanceStatusSuccess(payload) {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_INSTANCE_INFO_SUCCESS,
		payload,
	};
}
export function fetchInstanceStatusFail() {
	return {
		type: MACHINE_INSTANCE_TYPES.FETCH_INSTANCE_INFO_FAIL,
	};
}
export function fetchInstanceStatus(machineInstanceId) {
	return async (dispatch) => {
		try {
			dispatch(fetchInstanceStatusStart());
			const response = await Api.get(`instances/${machineInstanceId}/status`);
			dispatch(fetchInstanceStatusSuccess(response.data));
		} catch (e) {
			dispatch(fetchInstanceStatusFail());
			throw e;
		}
	};
}
