import React from 'react';
import PropTypes from 'prop-types';

// Lib
import { BEHAVIOR_OPTIONS, FILL_ORDERS_OPTIONS, FILL_UNIFORM } from '../../../lib/constants/Behavior';
import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';

// Atoms
import { LargeDivider } from '../../Atoms/Divider';
import { Title } from '../../Atoms/WebSite/Text';
import { LeftContent, TextWrapper } from './Atoms';

const BehaviorDescription = ({ selectedSettings, exchangeStatus }) => {
	const renderDescription = () => {
		if (exchangeStatus !== EXCHANGE_STATUS.OPENED) {
			return 'The exchange has to be opened, in order to configure its behavior.';
		}
		switch (selectedSettings?.settingState) {
		case BEHAVIOR_OPTIONS.default: {
			return <p>This is the exchange&apos;s default behavior, which is defined by the exchange&apos;s specification and the current trading phase the exchange is in.</p>;
		}
		case BEHAVIOR_OPTIONS.fillOrders:
			switch (selectedSettings.fillOrderState) {
			case FILL_ORDERS_OPTIONS.singleFill: {
				return <p>Fills the entire order with one fill.</p>;
			}
			case FILL_ORDERS_OPTIONS.uniformFills: {
				if (selectedSettings.fillUniformSelected === FILL_UNIFORM.count) {
					return <p>Fills the entire order with the specified number of equal partial fills.</p>;
				}

				if (selectedSettings.fillUniformSelected === FILL_UNIFORM.percentage) {
					return <p>Fills the entire order with multiple partial fills, each of which fills a specified, equal percentage of the order (or the remaining quantity).</p>;
				}
				return null;
			}
			case FILL_ORDERS_OPTIONS.randomFills:
				return <p>Fills the entire order with a random number of partial fills, each of which fills a random quantity.</p>;
			case FILL_ORDERS_OPTIONS.perShareFills:
				return <p>Fills the entire order with multiple partial fills, each of which is only for a single share (or the remaining, fractional quantity).</p>;
			default:
				return null;
			}
		case BEHAVIOR_OPTIONS.partialFillOrders:
			return <p>Fills the specified percentage of the total quantity of the order with the specified number of partial fills.</p>;
		case BEHAVIOR_OPTIONS.cancelOrders:
			return <p>Cancels all incoming orders.</p>;
		case BEHAVIOR_OPTIONS.reject:
			return <p>Rejects all orders, modifications, and/or cancels.</p>;
		default:
			return null;
		}
	};

	return (
		<LeftContent className="description-area">
			<TextWrapper>
				<Title>About Behavior Settings</Title>
				<LargeDivider />
				{renderDescription()}
			</TextWrapper>
		</LeftContent>
	);
};

BehaviorDescription.defaultProps = {
	selectedSettings: {
		settingState: null,
		fillOrderState: null,
		fillUniformSelected: null,
	},
	exchangeStatus: '',
};

BehaviorDescription.propTypes = {
	selectedSettings: PropTypes.shape({
		settingState: PropTypes.string,
		fillOrderState: PropTypes.string,
		fillUniformSelected: PropTypes.string,
	}),
	exchangeStatus: PropTypes.string,
};

export default BehaviorDescription;
