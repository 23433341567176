import React from 'react';

import { SiteMenuLink, SiteMenuWrapper } from '../Atoms';

const SiteMenu = () => (
	<SiteMenuWrapper>
		<SiteMenuLink to="/plans">Plans</SiteMenuLink>
		<SiteMenuLink to="/about">About</SiteMenuLink>
		<SiteMenuLink to="/faq">FAQ</SiteMenuLink>
		<SiteMenuLink to="/contact">Contacts</SiteMenuLink>
	</SiteMenuWrapper>
);

export default SiteMenu;
