import styled, { css } from 'styled-components';

import { COLORS } from '../../../lib/constants/Style';
import { TextBaseSmall, TextSmall } from '../../Css/Css';

import { FlexRowBase } from '../../Atoms/Flex';
import { DEVICE } from '../../../lib/constants/Device';

// Phase Bar Atoms

export const PhaseBarWrapper = styled.div`
	height: 40px;
	width: 100%;
	margin: 15px 0 35px 0;
	${FlexRowBase};
	justify-content: space-between;
	padding-left: 30px;
	
	@media ${DEVICE.laptopL} {
		margin: 0 0 30px 0;
	}
`;

// Phase Circle Atoms

const ActiveCircleCss = css`
	background-color: ${COLORS.yellow};
	border: solid 2px ${COLORS.yellow};
`;

export const Circle = styled.div`
	border-radius: 100%;
	background-color: transparent;
	border: solid 2px #d8dde2;
	height: 36px;
	width: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	${TextBaseSmall};
	color: ${COLORS.lightBlack};
	${props => props.active && ActiveCircleCss};
	position: relative;
	
	@media ${DEVICE.laptopL} {
		${TextSmall}
	}

`;
export const Line = styled.div`
	height: 2px;
	background-color: ${props => (props.active ? COLORS.yellow : '#d8dde2')};
	flex-grow: 1;
`;
export const Text = styled.div`
	position:absolute;
	bottom: -20px;
	white-space: nowrap;
`;
