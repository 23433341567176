import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { NotificationContainer } from 'react-notifications';

// Global CSS
import GlobalStyle from '../components/Css/Global';

// Store
import { store, history } from '../lib/store';

// Router
import MainRouter from './routers/MainRouter';

// High Order Component
import ScrollToTop from '../components/Hoc/ScrollToTop';

import { AuthProvider } from '../components/Context/auth';
import AppTourContainer from '../components/Organisms/AppTour/AppTour';


const ETPGems = () => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<AuthProvider history={history}>
				<GlobalStyle />
				<div id="ETPGems">
					<NotificationContainer />
					<ScrollToTop>
						<AppTourContainer>
							<MainRouter />
						</AppTourContainer>
					</ScrollToTop>
				</div>
			</AuthProvider>
		</ConnectedRouter>
	</Provider>
);

export default ETPGems;
