import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Atoms
import { BaseDivider } from '../Atoms/Divider';
import { FlexColumnBase } from '../Atoms/Flex';

// Molecules
import MarketStatus from '../Molecules/MarketStatus';

const HeaderWrapper = styled.div`
	flex: 1;
	${FlexColumnBase};
	align-items: flex-end;
`;
const MessageTrafficHeader = (props) => {
	const {
		ipAddress,
		vmStatus,
		exchangeStatus,
	} = props;
	return (
		<HeaderWrapper>
			<BaseDivider />
			<MarketStatus
				ipAddress={ipAddress}
				vmStatus={vmStatus}
				exchangeStatus={exchangeStatus}
			/>
			<BaseDivider />
		</HeaderWrapper>
	);
};
MessageTrafficHeader.propTypes = {
	exchangeStatus: PropTypes.string.isRequired,
	vmStatus: PropTypes.string.isRequired,
	ipAddress: PropTypes.string.isRequired,
};
export default MessageTrafficHeader;
