import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import { LabelText, TreeLabelWrapper, TreeTableColumn } from './TreeTable.Atoms';

// Molecules
import FirstData from './TreeTable.Molecules';

const TreeLabel = (props) => {
	const {
		open,
		text,
		onClick,
		hasChildren,
		depth,
	} = props;

	const firstItem = text[0];
	const restOfTheItems = text.slice(1);

	return (
		<TreeLabelWrapper onClick={onClick}>
			{firstItem && (
				<FirstData
					depth={depth}
					open={open}
					text={firstItem}
					hasChildren={hasChildren}
				/>
			)}
			{restOfTheItems.map((item, index) => (
				<TreeTableColumn key={`${item}${index.toString()}`}>
					<LabelText>
						{item}
					</LabelText>
				</TreeTableColumn>
			))}
		</TreeLabelWrapper>
	);
};

TreeLabel.defaultProps = {
	open: false,
	text: [],
	onClick: () => {},
	hasChildren: null,
	depth: 0,
};

TreeLabel.propTypes = {
	open: PropTypes.bool,
	text: PropTypes.arrayOf(PropTypes.string),
	onClick: PropTypes.func,
	hasChildren: PropTypes.arrayOf(PropTypes.shape({})),
	depth: PropTypes.number,
};

export default TreeLabel;
