const EVENT = {
	// Requests
	ExchangeStatusRequest: 'ExchangeStatusRequest',
	OrderBookExportRequest: 'OrderBookExportRequest',
	OrderBookStatusRequest: 'OrderBookStatusRequest',
	TickerPlantResendPacketMessagesRequest: 'TickerPlantResendPacketMessagesRequest',
	ExchangeBehaviorChangeRequest: 'ExchangeBehaviorChangeRequest',
	OrderBookAddOrderRequest: 'OrderBookAddOrderRequest',
	OrderBookAmendOrderRequest: 'OrderBookAmendOrderRequest',
	StopExchangeRequest: 'StopExchangeRequest',
	CloseExchangeRequest: 'CloseExchangeRequest',
	OpenExchangeRequest: 'OpenExchangeRequest',
	OrderBookBlotterRequest: 'OrderBookBlotterRequest',
	OrderBookImageRequest: 'OrderBookImageRequest',
	ExchangeBehaviorStatusRequest: 'ExchangeBehaviorStatusRequest',
	OrderBookTradeRequest: 'OrderBookTradeRequest',
	TradeTickerRequest: 'TradeTickerRequest',
	OrderBookBBBORequest: 'OrderBookBBBORequest',
	CloseAllOrdersInOrderBookClearTradesRequest: 'CloseAllOrdersInOrderBookClearTradesRequest',
	OrderBookCancelOrderRequest: 'OrderBookCancelOrderRequest',
	OrderBookCancelOrderReply: 'OrderBookCancelOrderReply',
	TradingGatewayMessageUpdate: 'TradingGatewayMessageUpdate',

	// Replies
	ExchangeBehaviorStatusReply: 'ExchangeBehaviorStatusReply',
	ExchangeStatusReply: 'ExchangeStatusReply',
	OrderBooksStatusReply: 'OrderBooksStatusReply',
	CloseExchangeReply: 'CloseExchangeReply',
	OpenExchangeReply: 'OpenExchangeReply',
	OrderBookAddOrderReply: 'OrderBookAddOrderReply',
	ExchangeBehaviorChangeReply: 'ExchangeBehaviorChangeReply',
	OrderBookImageReply: 'OrderBookImageReply',
	TradeTickerReplyEvent: 'TradeTickerReplyEvent',
	OrderBookBBBOReply: 'OrderBookBBBOReply',
	OrderBookTradeReply: 'OrderBookTradeReply',
	OrderBookExportReply: 'OrderBookExportReply',
	TickerPlantResendPacketMessagesReply: 'TickerPlantResendPacketMessagesReply',
	OrderBookAmendOrderReply: 'OrderBookAmendOrderReply',


	// Reject replies
	OrderBookRequestRejectReply: 'OrderBookRequestRejectReply',
	ExchangeBehaviorChangeRejectReply: 'ExchangeBehaviorChangeRejectReply',
	ExchangeActionRejectReply: 'ExchangeActionRejectReply',

	// Updates
	ComponentStatusUpdate: 'ComponentStatusUpdate',
	ExchangeStatusUpdate: 'ExchangeStatusUpdate',
	OrderBookPhaseUpdate: 'OrderBookPhaseUpdate',

	// Events
	BookRefreshJsonEvent: 'BookRefreshJsonEvent',
	BlotterRefreshJsonEvent: 'BlotterRefreshJsonEvent',
	BBBOJsonEvent: 'BBBOJsonEvent',
	TradeTickerUpdateJsonEvent: 'TradeTickerUpdateJsonEvent',
	TradeJsonEvent: 'TradeJsonEvent',
	TradingGatewayActiveSessionsReplyEvent: 'TradingGatewayActiveSessionsReplyEvent',
};

export default EVENT;
