import styled from 'styled-components';
import { FlexColumnBase, FlexRowSpaceBetweenCss } from '../../../Atoms/Flex';
import { COLORS } from '../../../../lib/constants/Style';
import IconComponent from '../../../Atoms/Icon';
import { SortableTableCSS } from '../../../Css/Table';
import { TextBase } from '../../../Css/Css';

export const EditIcon = styled(IconComponent)`
	margin-right: 8px;
	cursor: pointer;
`;
export const EditPopUp = styled.div`
	${FlexColumnBase};
	position: absolute;
	right: 14px;
	box-shadow: 0 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
    padding: 10px;
    z-index: 10000;
    background-color: ${COLORS.white};
`;
export const StyledEditRow = styled.div`
	position: relative;
	${FlexRowSpaceBetweenCss};
`;
export const EditOption = styled.div`
	cursor: pointer;
`;
export const GrayUnderlineText = styled.div`
  ${TextBase};
  text-align: left;
  color: #8c959d;
  cursor:pointer;
  border-bottom: 1px dotted #8c959d;
`;
export const MultiFillTableWrapper = styled.div`
	${SortableTableCSS};
	${FlexColumnBase};
	height: 170px;
	overflow: auto;
`;
