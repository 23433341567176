import { fromJS, Map, OrderedMap } from 'immutable';

export function transformOrderBookListToMap(orderBooks) {
	return orderBooks.reduce((o, v) => {
		const orderBookData = Map().set('name', v.name)
			.set('viewCode', v.viewCode)
			.set('isinCode', v.isinCode)
			.set('bbgCode', v.bbgCode)
			.set('ricCode', v.ricCode)
			.set('type', v.type)
			.set('defaultOpeningPrice', v.defaultOpeningPrice)
			.set('displayId', v.displayId)
			.set('orderBookId', v.orderBookId);
		return o.set(v.orderBookId, orderBookData);
	}, OrderedMap());
}

export function transformTradingGatewaysListToMap(tradingGateways) {
	return tradingGateways.reduce((t, v) => {
		const tradingGatewayData = Map().set('name', v.name)
			.set('port', v.port)
			.set('overrideFields', v.overrideFields)
			.set('resetOnLogon', v.resetOnLogon)
			.set('cancelOnDisconnect', v.cancelOnDisconnect)
			.set('fixSessions', fromJS(v.fixSessions))
			.set('displayId', v.displayId)
			.set('trGwyId', v.trGwyId);
		return t.set(v.trGwyId, tradingGatewayData);
	}, Map());
}
export function getExchangeData(response) {
	return Map().set('exchangeId', response.exchangeId)
		.set('clientExchangeId', response.clientExchangeId)
		.set('name', response.name)
		.set('description', response.description)
		.set('exchangeTypeId', response.exchangeTypeId)
		.set('exchangeType', fromJS(response.exchangeType))
		.set('userId', response.userId)
		.set('createdDate', response.createdDate)
		.set('machineInstanceId', response.machineInstanceId)
		.set('machineInstance', fromJS(response.machineInstance))
		.set('displayId', response.displayId)
		.set('availableSessions', response.availableSessions);
}

export function transformExchangeData(response, state) {
	const { orderBooks, tradingGateways, clientExchangeId: id } = response;
	// transform orderBooks list to map
	const orderBooksMap = transformOrderBookListToMap(orderBooks);
	// transform tradingGateways list to map
	const tradingGatewaysMap = transformTradingGatewaysListToMap(tradingGateways);

	const exchangeData = getExchangeData(response);

	return state.setIn(['exchanges', id], exchangeData)
		.setIn(['exchanges', id, 'orderBooks'], orderBooksMap)
		.setIn(['exchanges', id, 'tradingGateways'], tradingGatewaysMap);
}
