import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Lib
import { DEVICE } from '../../../../lib/constants/Device';

// Atoms
import { PrimaryButton } from '../../../Atoms/Button';
import { LargeDivider, SmallestDivider, SmallDivider, SmallVerticalDivider } from '../../../Atoms/Divider';
import { Title, GreyText } from '../../../Atoms/WebSite/Text';
import { FlexRow } from '../../../Atoms/Flex';
import { PublicExternalLink } from '../../../Atoms/Link';

// Molecules
import Input from '../../Input/Input/Input';
import CheckBox from '../../Input/CheckBox/CheckBox';

const FormWrapper = styled.form`
    width: 30%;
	min-width: 600px;
    padding: 90px 80px;
    background-color: white;

	@media ${DEVICE.mobileM} {
		width: 100%;
		padding: 40px 15px 50px;
		min-width: auto;
	}
`;

const FormButton = styled(PrimaryButton)`
	padding: 15px 50px;
`;

const ContactForm = ({
	emailRef, nameRef, messageRef, checkBoxValue, setCheckBoxValue, handleSubmit,
}) => (
	<FormWrapper onSubmit={(event) => {
		event.preventDefault();
		handleSubmit();
	}}
	>
		<Title>Contact Us</Title>
		<SmallDivider double/>
		<GreyText>Please fill out the form to send us a message. We will reply shortly.</GreyText>
		<LargeDivider />
		<Input
			ref={emailRef}
			placeholder="Your Email"
			type="email"
			name="email"
			validators={[
				{
					validator: 'isLength',
					options: {
						min: 1,
					},
					errorResult: false,
					message: 'Required field',
				},
				{
					validator: 'isEmail',
					errorResult: false,
					message: 'Email invalid',
				},
			]}
		/>
		<Input
			ref={nameRef}
			placeholder="Name"
			type="header"
			name="name"
			validators={[
				{
					validator: 'isLength',
					options: {
						min: 1,
					},
					errorResult: false,
					message: 'Required field',

				},
			]}
		/>
		<Input
			ref={messageRef}
			type="textarea"
			placeholder="Message"
			name="message"
			validators={[
				{
					validator: 'isLength',
					options: {
						min: 1,
					},
					errorResult: false,
					message: 'Required field',

				},
			]}
		/>
		<SmallestDivider />
		<FlexRow>
			<CheckBox value={checkBoxValue} onChange={() => setCheckBoxValue(!checkBoxValue)} />
			I agree to storage of my data according to <SmallVerticalDivider /><PublicExternalLink href='/privacy-policy' target='_blank'>Privacy Policy</PublicExternalLink>
		</FlexRow>
		<LargeDivider />
		<FormButton disabled={!checkBoxValue} type="submit">Send</FormButton>
	</FormWrapper>
);

ContactForm.propTypes = {
	emailRef: PropTypes.shape({}).isRequired,
	nameRef: PropTypes.shape({}).isRequired,
	messageRef: PropTypes.shape({}).isRequired,
	checkBoxValue: PropTypes.bool.isRequired,
	setCheckBoxValue: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

export default ContactForm;
