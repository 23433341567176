import WEBSOCKET_TYPES from './types';
import { COMPONENT_TYPE } from '../../lib/constants/Exchange';

export function updateStoreWithWebsocketData(type, payload) {
	return {
		type,
		payload,
	};
}
export function updateCoreComponentStatus(payload) {
	return {
		type: WEBSOCKET_TYPES.UPDATE_CORE_COMPONENT_STATUS,
		payload,
	};
}
export function updateOrderBookStatus(payload) {
	return {
		type: WEBSOCKET_TYPES.UPDATE_ORDER_BOOK_STATUS,
		payload,
	};
}
export function updateMDGatewayStatus(payload) {
	return {
		type: WEBSOCKET_TYPES.UPDATE_MD_GATEWAY_STATUS,
		payload,
	};
}
export function updateTradingGatewayStatus(payload) {
	return {
		type: WEBSOCKET_TYPES.UPDATE_TRADING_GATEWAY_STATUS,
		payload,
	};
}
export function updateComponentStatus(component, payload) {
	return (dispatch) => {
		switch (component) {
		case COMPONENT_TYPE.CORE: {
			dispatch(updateCoreComponentStatus(payload));
			break;
		}
		case COMPONENT_TYPE.ORDER_BOOK: {
			dispatch(updateOrderBookStatus(payload));
			break;
		}
		case COMPONENT_TYPE.TRADING_GATEWAY: {
			dispatch(updateTradingGatewayStatus(payload));
			break;
		}
		// not used yet
		case COMPONENT_TYPE.MD_GATEWAY: {
			dispatch(updateMDGatewayStatus(payload));
			break;
		}
		default: {
			break;
		}
		}
	};
}
export function pushMDReplayReportRow(data) {
	return {
		type: WEBSOCKET_TYPES.PUSH_MD_REPLAY_REPORT_ROW,
		payload: {
			data,
		},
	};
}
export function pushMDReplayTableDataRow(data) {
	return {
		type: WEBSOCKET_TYPES.PUSH_MD_REPLAY_TABLE_DATA_ROW,
		payload: {
			data,
		},
	};
}
