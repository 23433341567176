import styled, { css } from 'styled-components';
import { ORDER_SIDE } from '../../../lib/constants/Exchange';
import { COLORS } from '../../../lib/constants/Style';

const buySideCSS = css`
	color: ${COLORS.green};
`;
const sellSideCSS = css`
	color: ${COLORS.red};
`;
export const StyledOrderSide = styled.p`
	text-transform: capitalize;
	${props => (props.side === ORDER_SIDE.BUY ? buySideCSS : sellSideCSS)}
`;
