export const BEHAVIOR_OPTIONS = {
	default: 'default',
	fillOrders: 'fill',
	partialFillOrders: 'partial_fill',
	cancelOrders: 'cancel',
	reject: 'reject',
};

export const FILL_ORDERS_OPTIONS = {
	singleFill: 'single',
	uniformFills: 'uniform',
	randomFills: 'random',
	perShareFills: 'per_share',
};

export const FILL_UNIFORM = {
	count: 'uniform_count',
	percentage: 'uniform_percentage',
};

export const REJECTED_OPTIONS = {
	orders: 'orders',
	modifications: 'modifications',
	cancel: 'cancels',
};
