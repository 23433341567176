import styled, { css } from 'styled-components';

// Css
import {
	BaseBlockStyle, BaseTransition, TextBaseSmall, ZIndex,
} from '../../../Css/Css';
import { COLORS } from '../../../../lib/constants/Style';
// Atoms
import { FlexRowBase, FlexRowSpaceBetweenCss } from '../../../Atoms/Flex';
import IconComponent from '../../../Atoms/Icon';
// For styling
import { CheckBoxWrapper } from '../../../Molecules/Input/CheckBox/CheckBox.atoms';

export const BaseTable = styled.table`
	${TextBaseSmall};
  	width: 100%;
  	text-align: left;
  	position: relative;
  	table-layout: fixed;
	th {
		background: ${COLORS.white};
		position: sticky;
		top: 0;
		z-index: 22;
	}
`;
export const TableHeadingWrapper = styled.tr`
	border-bottom: 1px solid ${COLORS.borderGray};
	border-radius: 8px;
	padding-bottom: 10px;
	height: 30px;
	${props => props.height && `height: ${props.height}`};
`;
export const TableHeadingItem = styled.th`
	text-align: left;
	padding-left: 0;
	${props => props.sortable && 'cursor: pointer'};
	${props => props.width && `width: ${props.width}%`};
	${props => !props.visible && 'display: none'};
`;
export const TableRow = styled.tr`
	border-bottom: 1px solid ${COLORS.borderGray};
	border-radius: 8px;
	color: ${COLORS.lightBlack};
	${props => props.height && `height: ${props.height}`};
	${props => props.clickable && 'cursor: pointer;'};
	${props => props.selected && `background: ${COLORS.lightestGrey};`}
	&:hover {
		background: ${COLORS.lightestGrey};
	}
`;
export const TableCell = styled.td`
	vertical-align: middle;
	padding: 5px 5px 5px 0;
	${props => !props.visible && 'display: none'};
`;

export const TableCellData = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

// @NOTE - Only temporary here
export const SortIcon = styled(IconComponent)`
	stroke: ${COLORS.grey};
	width: 14px;
	height: 14px;
    ${BaseTransition};
    position: relative;
    right: 3px;
    top: 2px;
`;

export const SortIconWrapper = styled.div`
	${props => props.active === 'ASC' && css`
		${SortIcon} {
			stroke: ${COLORS.yellow};
		}
	`}
	${props => props.active === 'DESC' && css`
		${SortIcon} {
			stroke: ${COLORS.yellow};
		}
	`}
`;

export const SettingsIcon = styled(IconComponent)`
	height: 19px;
    width: 19px;
    color: ${COLORS.darkGrey};
    z-index: 22;
    cursor: pointer;
    :hover{
    	color: ${COLORS.darkestGrey};
    }
`;
export const TableCommandsWrapper = styled.div`
	padding: 0px 20px 20px 0;
	${FlexRowSpaceBetweenCss};
`;
export const TableWrapper = styled.div`
	${BaseBlockStyle};
  	box-shadow: 0px 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
    width: 100%;
    height: 100%;
    flex: 1;
  	padding: 20px 5px 20px 20px;
  	display: flex;
  	flex-direction: column;
  	overflow: hidden;
  	${props => props.noShadow && 'box-shadow: unset;'};
  	${props => props.nopadding && 'padding: unset;'};
  	// hide focus of GlobalHotkeys
  	div[tabindex="-1"]:focus {
  		outline: 0;
	}
`;
export const LeftControls = styled.div`
	${FlexRowBase};
`;
export const RightControls = styled.div`
	${FlexRowBase};
	flex: 1;
	justify-content: flex-end;
	& > div {
		:first-child{
			margin-right: 13px;
		}
	}
`;

// Table Heading
export const HeadingText = styled.p`
	color: ${COLORS.darkGrey};
	${ZIndex.medium};
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding-right: 5px;
`;
export const SettingsList = styled.div`
	background-color: ${COLORS.white};
    position: absolute;
    right: -5px;
    width: 180px;
    box-shadow: 0 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
    padding: 20px 0 20px 20px;
    ${TextBaseSmall};
    ${props => props.fixedSize && css`
		height: 180px;
		overflow: hidden;
	`}
`;
export const StyledTableSettings = styled.div`
    position: absolute;
    z-index: 50;
    right: 22px;
    top: 1px;
`;
export const TableViewWrapper = styled.div`
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;
`;

export const TableCheckboxWrapper = styled.div`
	position: relative;
	width: 16px;
	margin-right: 4px;
	height: 12px;
	${CheckBoxWrapper}{
		position: absolute;
		top: 0;
		bottom: 0;
	}
`;
