import React, {
	useCallback, useEffect, useState, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
// Atoms
import {
	RightControls, TableCommandsWrapper, TableWrapper,
} from '../Table/Atoms/TableAtoms';
import ExchangeStatus from '../../Atoms/ExchangeStatus';

// Molecules
import SearchFiled from '../../Molecules/Input/SearchField/SearchField';

// Organisms
import TableView from '../Table/TableView';

// Selectors
import { getActiveExchangeCoreComponents } from '../../../data/exchanges/selectors';

// Data
import { coreComponentsHeader } from '../../../data/placeholderData/allOrderBooksData';

// Hooks
import useSearch from '../../Hooks/useSearch';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';
import useSortBy from '../../Hooks/useSortBy';
import useSelect from '../../Hooks/useSelect';

function CoreComponentsTable() {
	// redux data
	const coreComponents = useSelector(state => getActiveExchangeCoreComponents(state));

	// local state
	const [tableRows, setTableRows] = useState([]);
	const [tableHeader, setTableHeader] = useState([...coreComponentsHeader]);
	const initialRows = useMemo(() => coreComponents?.toJS(), [coreComponents]);
	// @NOTE Table specific logic for mapping data to table rows
	const getDataToRender = useCallback(r => r?.map(row => ({
		data: [
			row.name,
			{
				render: () => <ExchangeStatus status={row.status} />,
				accessor: row.status,
			},

		],
		metaData: {
			selected: false,
			key: row.id,
		},
	})), []);

	useEffect(() => {
		setTableRows(initialRows);
	}, [initialRows]);

	// Hooks for table manipulation
	const { searchInputValue, setSearchInputValue } = useSearch(initialRows, setTableRows, tableHeader);
	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, tableRows);
	const { selectRowCallback, handleSelectAll, allSelected } = useSelect(dataToRender, setDataToRender);


	const handleHeaderVisibilityChange = useCallback((header) => {
		setTableHeader((prevState) => {
			const newState = [...prevState];
			const index = newState.findIndex(item => item.name === header);
			newState[index].visible = !newState[index].visible;
			return newState;
		});
	}, []);
	return (
		<TableWrapper className="core-components-table">
			<TableCommandsWrapper>
				<RightControls>
					<SearchFiled
						value={searchInputValue}
						onChange={setSearchInputValue}
						placeholder="Search"
					/>
				</RightControls>
			</TableCommandsWrapper>
			<TableView
				sortedBy={sortedBy}
				header={tableHeader}
				rows={dataToRender}
				hasSettings
				handleSort={handleSort}
				handleHeaderVisibilityChange={handleHeaderVisibilityChange}
				selectable={false}
				handleSelectAll={handleSelectAll}
				handleSelectRow={selectRowCallback}
				allSelected={allSelected}
			/>
		</TableWrapper>
	);
}
CoreComponentsTable.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
};
export default withRouter(CoreComponentsTable);
