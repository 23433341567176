import React, { useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Atoms
import { BaseDivider } from '../../components/Atoms/Divider';
import { FlexColumnBase } from '../../components/Atoms/Flex';
import { Title } from '../../components/Atoms/WebSite/Text';

// Molecules
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import OrderBlotterTable from '../../components/Organisms/TableModels/OrderBlotterTable';
import OrderBlotterHeader from '../../components/Organisms/OrderBlotter/OrderBlotterHeader';
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import TradeTable from '../../components/Organisms/TableModels/TradeTable';
import AddOrderBlotterModal from '../../components/Organisms/Modals/AddOrderBlotterModal/AddOrderBlotterModal';
import ModifyOrderModal from '../../components/Organisms/Modals/ModifyOrderModal';
import MultiFillModal from '../../components/Organisms/Modals/MultiFillModal/MultiFillModal';

import { toggleAddOrderBlotterModal } from '../../data/ui/UIActions';
import { getActiveExchange } from '../../data/exchanges/selectors';
import WebSocketConnectionContext from '../../components/Context/WebsocketConnectionContext';
import { requestOrderBlotterData } from '../../lib/websockets';
import { EXCHANGE_STATUS } from '../../lib/constants/Exchange';
import { showError } from '../../lib/helpers';
import { DEVICE } from '../../lib/constants/Device';
import { setOrderBlottersLoading } from '../../data/exchanges/ExchangesActions';


const OrderBlotterWrapper = styled.div`
	flex: 3;
	overflow: hidden;
	${FlexColumnBase};
	@media ${DEVICE.laptopL} {
		flex: 2;
	}
`;

const TradeTableWrapper = styled.div`
	flex: 2;
	overflow: hidden;
	${FlexColumnBase};
`;

function OrderBlotter(props) {
	const {
		actions,
		fix,
		status,
		displayId,
		clientExchangeId,
		ipAddress,
		vmStatus,
		orderBlottersLoadingStatus,
	} = props;

	const dispatch = useDispatch();
	const WebsocketContext = useContext(WebSocketConnectionContext);

	useEffect(() => {
		const {
			client,
			sessionId,
		} = WebsocketContext;
		if (client?.isConnected()) {
			requestOrderBlotterData(client, fix, sessionId);
			dispatch(setOrderBlottersLoading(true));
		}

		// If activeExchanges status changes then you should request status about order books again
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [WebsocketContext.connected]);

	useEffect(() => () => {
		dispatch(setOrderBlottersLoading(false));
	}, [dispatch]);
	const handleEnterOrderClick = useCallback(() => {
		if (status !== EXCHANGE_STATUS.OPENED) {
			showError('Exchange is not opened', "Can't send orders while exchange is not opened");
			return;
		}
		actions.toggleAddOrderBlotterModal(true);
	}, [actions, status]);
	return (
		<PageWrapper
			breadcrumbs={[
				{
					link: `exchanges/${clientExchangeId}`,
					name: displayId,
				},
				{
					link: 'order-blotter',
					name: 'Order Blotter',
				},
			]}
			title="Order Blotter"
			pageWrapperRightSide={(
				<OrderBlotterHeader
					ipAddress={ipAddress}
					vmStatus={vmStatus}
					exchangeStatus={status}
					onEnterOrderClick={handleEnterOrderClick}
				/>
			)}
		>
			<DocumentTitle title={`${displayId} - Order Blotter`} />
			<OrderBlotterWrapper>
				<OrderBlotterTable loading={orderBlottersLoadingStatus} />
			</OrderBlotterWrapper>
			<TradeTableWrapper>
				<BaseDivider />
				<Title>
					Trades
				</Title>
				<BaseDivider />
				<TradeTable />
			</TradeTableWrapper>
			<AddOrderBlotterModal />
			<ModifyOrderModal />
			<MultiFillModal />
		</PageWrapper>
	);
}
OrderBlotter.defaultProps = {
	orderBlottersLoadingStatus: false,
};
OrderBlotter.propTypes = {
	actions: PropTypes.shape({
		toggleAddOrderBlotterModal: PropTypes.func,
	}).isRequired,
	fix: PropTypes.string.isRequired,
	status: PropTypes.bool.isRequired,
	displayId: PropTypes.string.isRequired,
	clientExchangeId: PropTypes.number.isRequired,
	ipAddress: PropTypes.string.isRequired,
	vmStatus: PropTypes.string.isRequired,
	orderBlottersLoadingStatus: PropTypes.bool,
};
const mapStateToProps = (state) => {
	const activeExchange = getActiveExchange(state);
	return ({
		fix: activeExchange.getIn(['exchangeType', 'code']),
		status: activeExchange.get('status'),
		clientExchangeId: activeExchange.get('clientExchangeId'),
		displayId: activeExchange.get('displayId'),
		ipAddress: activeExchange.getIn(['machineInstance', 'ipAddress']),
		vmStatus: activeExchange.getIn(['machineInstance', 'status']),
		orderBlottersLoadingStatus: activeExchange.get('orderBlotterLoadingStatus'),
	});
};
const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		toggleAddOrderBlotterModal,
	}, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderBlotter);
