export const BILLING_MESSAGE_TYPES = {
	SUCCESSFUL_SUBSCRIPTION: 'Success',
	DOUBLE_PENDING_ERROR: 'doublePendingError',
	CRITICAL_ERROR: 'criticalError',
	MAJOR_ERROR: 'majorError',
	PAYMENT_ERROR: 'paymentError',
};

export const BILLING_MESSAGES = {
	[BILLING_MESSAGE_TYPES.DOUBLE_PENDING_ERROR]: {
		title: 'Subscription in Double Pending State Error',
		description: 'You are trying to make more than 2 subscription changes at the same time. Please make changes consistently.',
	},
	[BILLING_MESSAGE_TYPES.CRITICAL_ERROR]: {
		title: 'Critical Error',
		description: 'Critical error during subscription process please contact support.',
	},
	[BILLING_MESSAGE_TYPES.MAJOR_ERROR]: {
		title: 'Major Error',
		description: 'The payment process was successful, but an internal error occurred while saving the results. You can go to the subscription page and repeat the process with the same parameters of exchanges and sessions, In this case, the payment will not be re-charged. You can also contact support.',
	},
	[BILLING_MESSAGE_TYPES.PAYMENT_ERROR]: {
		title: 'Payment Error',
		description: 'An error occurred while trying to pay.',
	},
};