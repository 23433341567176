import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { getActiveExchange } from '../../data/exchanges/selectors';

// Atoms
import { ExchangeBehaviorWrapper } from '../../components/Organisms/ExchangeBehavior/Atoms';
import { FlexColumn } from '../../components/Atoms/Flex';
import { BaseDivider } from '../../components/Atoms/Divider';

// Molecules
import MarketStatus from '../../components/Molecules/MarketStatus';
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import BehaviorSettings from '../../components/Organisms/ExchangeBehavior/BehaviorSettings';
import BehaviorDescription from '../../components/Organisms/ExchangeBehavior/BehaviorDescription';

const ExchangeBehavior = () => {
	const activeExchange = useSelector(state => getActiveExchange(state));
	const [settingsState, setSettingsState] = useState(null);

	const getSettingState = (settingState, fillOrderState, fillUniformSelected) => {
		const stateObject = {
			settingState,
			fillOrderState,
			fillUniformSelected,
		};

		if (JSON.stringify(stateObject) !== JSON.stringify(settingsState)) {
			setSettingsState(stateObject);
		}
	};

	return (
		<PageWrapper
			breadcrumbs={[
				{
					link: `exchanges/${activeExchange.get('clientExchangeId')}`,
					name: activeExchange.get('displayId'),
				},
				{
					link: 'exchange-behavior',
					name: 'Exchange Behavior',
				},
			]}
			title="Behavior settings"
			pageWrapperRightSide={(
				<>
					<FlexColumn>
						<BaseDivider />
						<MarketStatus
							ipAddress={activeExchange?.get('machineInstance').get('ipAddress')}
							vmStatus={activeExchange?.get('machineInstance').get('status')}
							exchangeStatus={activeExchange?.get('status')}
						/>
						<BaseDivider />
					</FlexColumn>
				</>
			)}
		>
			<DocumentTitle title={`${activeExchange.get('displayId')} - Exchange Behavior`} />
			<ExchangeBehaviorWrapper>
				<BehaviorSettings getState={getSettingState} exchangeStatus={activeExchange?.get('status')} />
				<BehaviorDescription selectedSettings={settingsState} exchangeStatus={activeExchange?.get('status')} />
			</ExchangeBehaviorWrapper>
		</PageWrapper>
	);
};

export default ExchangeBehavior;
