import React, {
	useEffect, useState, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { header } from '../../../data/placeholderData/sessionsData';
// Atoms
import {
	LeftControls, RightControls, TableCommandsWrapper, TableWrapper,
} from '../Table/Atoms/TableAtoms';
import { GrayControlIcon } from '../../Atoms/ControlIcons';
import { SmallVerticalDivider } from '../../Atoms/Divider';
import { BlockTitle } from '../../Atoms/Text';
// Organisms
import TableView from '../Table/TableView';
// Hooks
import useSortBy from '../../Hooks/useSortBy';
import useSelect from '../../Hooks/useSelect';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';

// Actions
import {
	toggleAddSessionModal,
	toggleEditSessionModal,
} from '../../../data/ui/UIActions';

function SessionsTable(props) {
	const {
		sessions,
		deleteSessions,
	} = props;
	// local state
	const [tableRows, setTableRows] = useState(sessions);
	const [tableHeader, setTableHeader] = useState(header);
	// dispatch
	const dispatch = useDispatch();

	useEffect(() => {
		setTableRows(sessions);
	}, [sessions]);

	// @NOTE Table specific logic for mapping data to table rows
	const getDataToRender = useCallback(r => r.map((row, i) => ({
		data: [
			row.senderCompId, row.targetCompId,
		],
		metaData: {
			selected: false,
			key: row.fixSessionId,
			index: i,
		},
	})), []);

	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, tableRows);
	const { selectRowCallback, handleSelectAll, allSelected } = useSelect(dataToRender, setDataToRender);

	const selectedSessions = useMemo(() => dataToRender
		.filter(row => row.metaData.selected)
		.map((item => item.metaData.key)), [dataToRender]);

	return (
		<TableWrapper noShadow noPadding>
			<TableCommandsWrapper>
				<LeftControls>
					<BlockTitle>
					Sessions
					</BlockTitle>
				</LeftControls>
				<RightControls>
					<GrayControlIcon
						className="add-tr-modal-sessions-add-button"
						name="plusCircle"
						onClick={() => dispatch(toggleAddSessionModal())}
						data-tip="Add session"
					/>
					<SmallVerticalDivider />
					<SmallVerticalDivider />
					<GrayControlIcon
						disabled={selectedSessions.length !== 1}
						name="editPen"
						onClick={() => dispatch(toggleEditSessionModal(true, selectedSessions))}
						data-tip="Edit session"
					/>
					<SmallVerticalDivider />
					<SmallVerticalDivider />
					<GrayControlIcon
						disabled={selectedSessions.length <= 0}
						name="trashCan"
						onClick={() => deleteSessions(selectedSessions)}
						data-tip="Delete sessions"
					/>
				</RightControls>
			</TableCommandsWrapper>
			<TableView
				sortedBy={sortedBy}
				header={tableHeader}
				rows={dataToRender}
				hasSettings={false}
				handleSort={handleSort}
				selectable
				handleSelectAll={handleSelectAll}
				handleSelectRow={selectRowCallback}
				allSelected={allSelected}
			/>
		</TableWrapper>
	);
}
export default SessionsTable;

SessionsTable.propTypes = {
	sessions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	deleteSessions: PropTypes.func.isRequired,
};
