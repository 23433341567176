import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { toggleContactFormModal } from '../../../data/ui/UIActions';

// Constants
import { MODALS } from '../../../lib/constants/General';

// Molecules
import Modal from '../../Molecules/Modal';
import { PrimaryButton } from '../../Atoms/Button';
import { BigDivider } from '../../Atoms/Divider';

class ContactFormModal extends PureComponent {
	closeModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleContactFormModal(false);
	};
	render() {
		const {
			modalInfo,
		} = this.props;
		if (modalInfo.get('visible') !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo.get('visible')}
				closeModal={this.closeModal}
			>
				Your message has been sent successfully
				<BigDivider />
				<PrimaryButton onClick={this.closeModal}>Close</PrimaryButton>
			</Modal>
		);
	}
}

ContactFormModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleContactFormModal: PropTypes.func,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.CONTACT_FORM_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleContactFormModal,
		}, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormModal);
