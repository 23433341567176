import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';
// Actions
import { toggleModal } from '../../../data/ui/UIActions';
// Atoms
import { ModalErrorMessage, ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import {
	MediumDivider, MediumVerticalDivider, SmallDivider,
} from '../../Atoms/Divider';
import { PrimaryButton, SecondaryButton } from '../../Atoms/Button';
// Molecules
import Modal from '../../Molecules/Modal';

class DeleteConfirmationModal extends Component {
	handleClose = () => {
		const {
			actions,
		} = this.props;
		actions.toggleModal(MODALS.DELETE_CONFIRMATION_MODAL, false);
	};

	render() {
		const {
			modalInfo,
			modalData,
			onConfirm,
		} = this.props;

		if (modalInfo.get('visible') !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleClose}
				size={MODAL_SIZES.MEDIUM}
			>
				<FlexColumn alignItems="center">
					<ModalTitle>
						{modalData?.get('title')}
					</ModalTitle>
					{modalData?.get('error') && <ModalErrorMessage>{modalData?.get('error')}</ModalErrorMessage>}
					<SmallDivider />
					{modalData?.get('message')}
					<MediumDivider />
					<FlexRow>
						<PrimaryButton onClick={onConfirm}>
							Confirm
						</PrimaryButton>
						<MediumVerticalDivider />
						<SecondaryButton onClick={this.handleClose}>
							Cancel
						</SecondaryButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}
DeleteConfirmationModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleModal: PropTypes.func,
	}).isRequired,
	modalData: PropTypes.shape({
		// Title of the modal
		title: PropTypes.string,
	}).isRequired,
	// Callback to run on confirmation
	onConfirm: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.DELETE_CONFIRMATION_MODAL]),
		modalData: state.getIn(['ui', 'modal', MODALS.DELETE_CONFIRMATION_MODAL, 'data']),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmationModal));
