import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Lib
import Api from '../../../lib/api';

// Actions
import { toggleCreateExchangeModal } from '../../../data/ui/UIActions';

// Constants
import { MODALS } from '../../../lib/constants/General';

// Atoms
import { FlexRow, FlexRowCenterCss } from '../../Atoms/Flex';
import { BaseVerticalDivider, MediumDivider } from '../../Atoms/Divider';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import { Title } from '../../Atoms/WebSite/Text';
import { ErrorMessage } from '../../Molecules/Input/Input/Input.atoms';

// Molecules
import Modal from '../../Molecules/Modal';
import Input from '../../Molecules/Input/Input/Input';
import SelectBox from '../../Molecules/Input/SelectBox/SelectBox';
import CheckBoxInput from '../../Molecules/Input/CheckBox/CheckBox';
import {
	fetchAvailableSessions, fetchExchanges,
	getFullInfoAboutExchanges
} from '../../../data/exchanges/ExchangesActions';
import { createFormDataFromObject } from '../../../lib/helpers';
import { ModalErrorMessage } from '../../Atoms/Text';

const Wrapper = styled.div`
  width: 450px;
  & > h2 {
		margin-bottom: 26px;
	}
`;
const ButtonWrapper = styled.div`
	${FlexRowCenterCss};
	& > button {
		width: 137px;
		:first-child{
			margin-right: 25px;
		}
	}
`;

class CreateExchangeModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			type: {
				render: '',
				key: '',
			},
			region: {
				render: '',
				key: '',
			},
			session: {
				render: '',
				key: '',
			},
			preConfigured: true,
			error: null,
		};
		this.nameInputRef = React.createRef();
		this.descriptionInputRef = React.createRef();
	}

	closeModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleCreateExchangeModal(false);
		this.resetState();
	};

	resetState = () => {
		this.setState({
			type: {},
			region: {},
			preConfigured: true,
			session: {},
			error: null,
		});
	};

	handleTypeChange = (value) => {
		const {
			actions,
		} = this.props;
		actions.fetchAvailableSessions(value.key);
		this.setState({
			type: value,
		});
	};

	handleRegionChange = (value) => {
		this.setState({
			region: value,
		});
	};

	handlePreConfiguredSettingsClick = () => {
		this.setState(prevState => ({
			preConfigured: !prevState.preConfigured,
		}));
	};

	handleSessionChange = (value) => {
		this.setState({
			session: value,
		});
	};

	handleCreateExchangeClick = () => {
		const {
			actions,
		} = this.props;
		const {
			preConfigured,
			type,
			region,
			session,
		} = this.state;

		const formData = createFormDataFromObject({
			exchangeName: this.nameInputRef.current?.getValue(),
			exchangeDescription: this.descriptionInputRef.current?.getValue(),
			addPreConfiguredSettings: preConfigured,
			exchangeTypeId: type.key,
			'editMachineInstance.cloudLocationId': region.key,
			sessionsNumber: session.key,
		});

		Api.post('exchanges', formData).then((response) => {
			if (!response.data) {
				this.setState({ error: response.response.data.message });
			} else {
				actions.fetchExchanges();
				this.closeModal();
			}
		}).catch((err) => {
			this.setState({ error: err?.response?.data?.message });
		});
	};

	render() {
		const {
			modalInfo,
			types,
			cloudLocations,
			sessions,
		} = this.props;
		const {
			type,
			region,
			preConfigured,
			session,
			error,
		} = this.state;

		if (modalInfo.get('visible') !== true) {
			return false;
		}
		return (
			<Modal
				showModal={modalInfo.get('visible')}
				closeModal={this.closeModal}
			>
				<Wrapper>
					<Title>
						Create Exchange
					</Title>
					{error && <ModalErrorMessage>{error}</ModalErrorMessage>}
					<Input
						ref={this.nameInputRef}
						type="header"
						name="name"
						placeholder="Name"
					/>
					<SelectBox
						className="add-exchange-modal-types"
						label="Exchange type"
						onClick={this.handleTypeChange}
						options={types?.toJS().map(t => ({ render: t.name, key: t.exchangeTypeId }))}
						selected={type}
					/>
					<ErrorMessage />
					<SelectBox
						className="add-exchange-modal-locations"
						label="Region"
						onClick={this.handleRegionChange}
						options={cloudLocations?.toJS().map(t => ({ render: t.description, key: t.id }))}
						selected={region}
					/>
					<ErrorMessage />
					<SelectBox
						className="add-exchange-modal-sessions"
						label="Sessions"
						onClick={this.handleSessionChange}
						options={sessions?.toJS().map(t => ({ render: t, key: t }))}
						selected={session}
					/>
					<ErrorMessage />
					<Input
						ref={this.descriptionInputRef}
						type="textarea"
						name="description"
						placeholder="Description(100 chars)"
					/>
					<FlexRow className="add-exchange-modal-pre-config">
						<CheckBoxInput
							label="Add default components"
							value={preConfigured}
							onChange={this.handlePreConfiguredSettingsClick}
						/>
						<BaseVerticalDivider />
						<span>Add default components</span>
					</FlexRow>
					<MediumDivider />
					<ButtonWrapper>
						<PrimaryButton
							onClick={this.handleCreateExchangeClick}
							className="add-exchange-modal-confirm-button"
						>
							Create Exchange
						</PrimaryButton>
						<WhiteGrayButton onClick={this.closeModal}>Cancel</WhiteGrayButton>
					</ButtonWrapper>
				</Wrapper>
			</Modal>
		);
	}
}


function mapStateToProps(state) {
	return {
		cloudLocations: state.getIn(['machineInstance', 'cloudLocations', 'data']),
		types: state.getIn(['exchange', 'types']),
		sessions: state.getIn(['exchange', 'sessions']),
		modalInfo: state.getIn(['ui', 'modal', MODALS.CREATE_EXCHANGE_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleCreateExchangeModal,
			fetchAvailableSessions,
			getFullInfoAboutExchanges,
			fetchExchanges
		}, dispatch),
	};
}

CreateExchangeModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		fetchAvailableSessions: PropTypes.func,
		toggleCreateExchangeModal: PropTypes.func,
	}).isRequired,
	types: PropTypes.shape({}).isRequired,
	cloudLocations: PropTypes.shape({}).isRequired,
	sessions: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateExchangeModal);
