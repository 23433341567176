import React from 'react';
import {
	SkipTour, TooltipBody, TooltipContent, TooltipFooter, TooltipTitle,
} from './Tooltip.Atoms';
import { MediumDivider, SmallDivider, SmallVerticalDivider } from '../../Atoms/Divider';
import { PrimaryButton, SecondaryButton } from '../../Atoms/Button';

const Tooltip = (props) => {
	const {
		index,
		step,
		backProps,
		closeProps,
		primaryProps,
		tooltipProps,
	} = props;
	return (
		<TooltipBody {...tooltipProps}>
			{step.title && (
				<>
					<TooltipTitle>{step.title}</TooltipTitle>
					<SmallDivider />
				</>
			)}

			<TooltipContent>{step.content}</TooltipContent>
			<MediumDivider />
			<TooltipFooter>
				<SkipTour {...closeProps} id="close">
					Skip tour
				</SkipTour>
				<SmallVerticalDivider />

				<SecondaryButton {...backProps} id="back" disabled={index === 0}>
					Back
				</SecondaryButton>

				<SmallVerticalDivider />
				<PrimaryButton {...primaryProps} id="next">
					Got it, next
				</PrimaryButton>

			</TooltipFooter>
		</TooltipBody>
	);
};

export default Tooltip;
