import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { GlobalHotKeys } from 'react-hotkeys';
// CSS
import { ShowElement } from '../Css/Animations';
import { BaseOverlay, BaseTransition, ZIndex } from '../Css/Css';
// Constants
import { MODAL_SIZES } from '../../lib/constants/General';
import IconComponent from '../Atoms/Icon';
import { COLORS } from '../../lib/constants/Style';

const ModalOverlay = styled.div`
	${ShowElement()};
	${BaseOverlay};
	${ZIndex.mediumTwo};
	display: flex;
	background-color: rgb(0,0,0, 0.8);
	justify-content: center;
	align-items: center;
	flex-direction: column;
	${props => (props.hide ? 'display: none;' : '')}
`;

const ModalContent = styled.div`
	position: relative;
	background-color: #ffffff;
	padding: 70px 80px;
	text-align: center;
	max-width: 920px;
	${props => (props.size && props.size === MODAL_SIZES.SMALL && css`
		max-width: 450px;
		padding: 50px;
		width: 100%;
	`)};
	${props => (props.size && props.size === MODAL_SIZES.MEDIUM && css`
		max-width: 600px;
		padding: 50px;
		width: 100%;
	`)};
	${props => (props.size && props.size === MODAL_SIZES.BIG && css`
		width: 80%;
		height: 90%;
		padding: 30px;
		max-width: 1240px;
	`)};
	${props => (props.size && props.size === MODAL_SIZES.WIDE && css`
		min-width: 765px;
	`)};
`;

const CloseIcon = styled(IconComponent)`
	width: 12px;
	height: 12px;
	position: absolute;
	top: 15px;
	right: 15px;
	fill: ${COLORS.grey};
	${BaseTransition};
	cursor: pointer;
	&:hover {
		fill: ${COLORS.darkestGrey};
	}
`;

function Modal(props) {
	const {
		children,
		showModal,
		closeModal,
		hasCloseIcon,
		size,
	} = props;
	const handlers = {
		CLOSE_MODAL: closeModal,
	};
	const keyMap = {
		CLOSE_MODAL: 'esc',
	};
	const modalRef = useRef();
	const contentRef = useRef();

	// By default, modal is shown. But we can decide to hide it later without removing modal component
	if (!showModal) {
		return null;
	}
	return (
		<ModalOverlay
			ref={modalRef}
			onMouseDown={() => closeModal()}
		>
			<GlobalHotKeys
				keyMap={keyMap}
				handlers={handlers}
			>
				<ModalContent
					ref={contentRef}
					size={size}
					onMouseDown={event => event.stopPropagation()}
				>
					{children}
					{hasCloseIcon && (
						<CloseIcon name="close" onClick={closeModal} />
					)}
				</ModalContent>
			</GlobalHotKeys>
		</ModalOverlay>
	);
}

Modal.defaultProps = {
	showModal: true,
	size: '',
	hasCloseIcon: true,
};

Modal.propTypes = {
	showModal: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.array,
	]).isRequired,
	closeModal: PropTypes.func.isRequired,
	size: PropTypes.string,
	hasCloseIcon: PropTypes.bool,
};

export default memo(Modal);
