import styled, { css } from 'styled-components';

import { COLORS, DIVIDER_HEIGHTS, DIVIDER_WIDTHS } from '../../lib/constants/Style';
import { DEVICE } from '../../lib/constants/Device';

const baseDividerStyle = css`
	width: 100%;
	min-height: ${props => props.height || '10px'};
`;

export const CustomDivider = styled.div`
	${baseDividerStyle};
  	min-height: ${props => (props.width && props.width)};
`;

export const SmallestDivider = styled.div`
	${baseDividerStyle};
	min-height: ${props => (props.double ? `${DIVIDER_HEIGHTS.smallest * 2}px` : `${DIVIDER_HEIGHTS.smallest}px`)};
`;

export const SmallDivider = styled.div`
	${baseDividerStyle};
	min-height: ${props => (props.double ? `${DIVIDER_HEIGHTS.small * 2}px` : `${DIVIDER_HEIGHTS.small}px`)};
`;

export const BaseDivider = styled.div`
	${baseDividerStyle};
	min-height: ${props => (props.double ? `${DIVIDER_HEIGHTS.base * 2}px` : `${DIVIDER_HEIGHTS.base}px`)};
`;

export const MediumDivider = styled.div`
	${baseDividerStyle};
	min-height: ${props => (props.double ? `${DIVIDER_HEIGHTS.medium * 2}px` : `${DIVIDER_HEIGHTS.medium}px`)};
`;

export const BigDivider = styled.div`
	${baseDividerStyle};
	min-height: ${props => (props.double ? `${DIVIDER_HEIGHTS.big * 2}px` : `${DIVIDER_HEIGHTS.big}px`)};
`;

export const LargeDivider = styled.div`
	${baseDividerStyle};
	min-height: ${props => (props.double ? `${DIVIDER_HEIGHTS.large * 2}px` : `${DIVIDER_HEIGHTS.large}px`)};
`;

// VERTICAL DIVIDERS

export const SmallVerticalDivider = styled.div`
	height: 1px;
	width: ${props => (props.double ? `${DIVIDER_WIDTHS.small * 2}px` : `${DIVIDER_WIDTHS.small}px`)};
`;

export const BaseVerticalDivider = styled.div`
	height: 1px;
	width: ${props => (props.double ? `${DIVIDER_WIDTHS.base * 2}px` : `${DIVIDER_WIDTHS.base}px`)};
`;

export const MediumVerticalDivider = styled.div`
	height: 1px;
	width: ${props => (props.double ? `${DIVIDER_WIDTHS.medium * 2}px` : `${DIVIDER_WIDTHS.medium}px`)};
`;

export const BigVerticalDivider = styled.div`
	height: 1px;
	width: ${props => (props.double ? `${DIVIDER_WIDTHS.big * 2}px` : `${DIVIDER_WIDTHS.big}px`)};
`;

export const TabletScreenDivider = styled(LargeDivider)`
	@media ${DEVICE.tablet} {
		display: none;
	}	
`;

export const MobileMScreenDivider = styled(LargeDivider)`
	@media ${DEVICE.mobileM} {
		display: none;
	}	
`;

export const Line = styled.div`
	width: 100%;
	border-bottom: ${props => props.double ? '2px' : '1px'} solid ${COLORS.lighterGrey};
`;

export const VerticalLine = styled.div`
	width: 2px;
	height: 100%;
	display: block;
	background: ${COLORS.grey};
`;
