import styled from 'styled-components';
import { BaseBlockStyle, TextBase, TextBaseSmall, TextBig, TextMedium, TextSmall } from '../../Css/Css';
import IconComponent from '../../Atoms/Icon';
import { COLORS } from '../../../lib/constants/Style';
import { DEVICE } from '../../../lib/constants/Device';

export const VirtualMachineWrapper = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ${BaseBlockStyle};
    box-shadow: 0px 2px 7.8px 0.2px ${COLORS.shadeGray};
    padding: 20px 30px;
    table {
        ${TextBase};
    }
    
    @media ${DEVICE.laptopL} {
        padding: 15px 20px;
        table {
            ${TextBaseSmall};
        }
    }


  & > div {
    :first-child{
      height: 20px;
    }
  }
`;

export const ExchangeWrapper = styled.div`
    ${BaseBlockStyle};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 3;
    padding: 20px 30px;
    box-shadow: 0px 2px 7.8px 0.2px ${COLORS.shadeGray};
    table {
        ${TextBase};
    }
    @media ${DEVICE.laptopL} {
        padding: 15px 20px;
        table {
            ${TextBaseSmall};
        }
    }
`;
export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

export const SectionTitle = styled.div`
    ${TextBig};
    line-height: 0.75;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.lightBlack};
    position: relative;
    @media ${DEVICE.laptopL} {
        ${TextMedium};
    }
`;

export const SettingsIcons = styled(IconComponent)`
  width: 16px;
  height: 19px;
  color: ${COLORS.darkGrey};
  position: absolute;
  right: -28px;
  top: 2px;
`;
export const InstanceSVG = styled(IconComponent)`
  width: 94px;
  height: 135px;
  align-self: flex-end;
  justify-self: center;
`;

export const DateInfo = styled.div`
    text-align: right;
    ${TextBaseSmall};
    color: ${COLORS.darkGrey};
    @media ${DEVICE.laptopL} {
        ${TextSmall};
    }
`;
