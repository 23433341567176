import styled from 'styled-components';

// Lib
import { DEVICE } from '../../../lib/constants/Device';
import { COLORS } from '../../../lib/constants/Style';

// Atoms
import { FlexRow } from '../Flex';
import { PrimaryButton } from '../Button';

export const MaxWidthWrapper = styled.div`
    max-width: 920px;
`;

export const FaqFlex = styled(FlexRow)`
	@media ${DEVICE.laptopM} {
		flex-direction: column;
	}
`;

export const FaqSearchFlex = styled(FlexRow)`
	@media ${DEVICE.mobileM} {
		flex-direction: column;
	}
`;


export const SearchButton = styled(PrimaryButton)`
    padding: 15px 40px;
    font-size: 16px;
	display: flex;
	justify-content: center;
	@media ${DEVICE.mobileM} {
		width: 100%;
	}
`;

export const AccordionWrapper = styled.div`
	width: 60%;
	@media ${DEVICE.laptopM} {
		width: 100%;
	}
`;

export const CardWrapper = styled.div`
	width: 30%;
	text-align: center;
	padding: 10% 5%;
	margin-top: 30px;
	background-color: ${COLORS.lightestGrey};

	@media ${DEVICE.laptopM} {
		width: 100%;
	}
`;
