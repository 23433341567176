export default {
	FETCH_GENERAL_PRICES: 'FETCH_GENERAL_PRICES',
	FETCH_GENERAL_PRICES_SUCCESS: 'FETCH_GENERAL_PRICES_SUCCESS',
	FETCH_GENERAL_PRICES_FAIL: 'FETCH_GENERAL_PRICES_FAIL',
	FETCH_PRICES_FOR_ACTIVE_ADDONS: 'FETCH_PRICES_FOR_ACTIVE_ADDONS',
	FETCH_PRICES_FOR_ACTIVE_ADDONS_SUCCESS: 'FETCH_PRICES_FOR_ACTIVE_ADDONS_SUCCESS',
	FETCH_PRICES_FOR_ACTIVE_ADDONS_FAIL: 'FETCH_PRICES_FOR_ACTIVE_ADDONS_FAIL',
	CALCULATE_PRICES: 'CALCULATE_PRICES',
	CALCULATE_PRICES_SUCCESS: 'CALCULATE_PRICES_SUCCESS',
	CALCULATE_PRICES_FAIL: 'CALCULATE_PRICES_FAIL',
	ABORT_PAYMENT_REQUEST: 'ABORT_PAYMENT_REQUEST',
	ABORT_PAYMENT_REQUEST_SUCCESS: 'ABORT_PAYMENT_REQUEST_SUCCESS',
	ABORT_PAYMENT_REQUEST_FAIL: 'ABORT_PAYMENT_REQUEST_FAIL',
	ADD_EXCHANGE: 'BILLING_ADD_EXCHANGE',
	SET_NEW_SESSIONS_FOR_ADDON: 'SET_NEW_SESSIONS_FOR_ADDON',
	RESET_BILLING_ITEMS: 'RESET_BILLING_ITEMS',
	TOGGLE_BILLING_ITEM_SELECT: 'TOGGLE_BILLING_ITEM_SELECT',
	TOGGLE_BILLING_ITEMS_SELECT_ALL: 'TOGGLE_BILLING_ITEMS_SELECT_ALL',
	RESTORE_SELECTED_BILLING_ITEMS: 'RESTORE_SELECTED_BILLING_ITEMS',
	RESTORE_BILLING_ITEM: 'RESTORE_BILLING_ITEM',
	DELETE_SELECTED_BILLING_ITEMS: 'DELETE_SELECTED_BILLING_ITEMS',
	SUCCESSFUL_SUBSCRIPTION: 'SUCCESSFUL_SUBSCRIPTION',
	NEW_PAYMENT_PLAN: 'NEW_PAYMENT_PLAN',
	SUBSCRIPTION_FAIL: 'SUBSCRIPTION_FAIL',
	RESET_BILLING: 'RESET_BILLING',
}