import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FlexRowBase } from './Flex';
import { COLORS } from '../../lib/constants/Style';
import { TextBaseSmall } from '../Css/Css';
import { DEVICE } from '../../lib/constants/Device';

export const Tabs = styled.div`
	${FlexRowBase};
	width: 100%;
	overflow-x: auto;
	${TextBaseSmall};
`;

export const Placeholder = styled.div`
	width: 100%;
	height: 36px;
	border-bottom: 1.5px solid ${COLORS.borderGray};
`;

export const StateTabs = styled.div`
	cursor: pointer;
	white-space: nowrap;
	padding: 0 20px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1.5px solid ${COLORS.borderGray};
	border-right: 1.5px solid ${COLORS.borderGray};
	&.active{
		border-bottom-color: white;
	}
	${props => props.active && 'border-bottom-color: white;'};
	
	@media ${DEVICE.laptopL} {
		padding: 0 10px;
	}

`;

export const Tab = styled(NavLink)`
	cursor: pointer;
	white-space: nowrap;
	padding: 0 20px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1.5px solid ${COLORS.borderGray};
	border-right: 1.5px solid ${COLORS.borderGray};
	&.active{
		border-bottom-color: white;
	}
	
	@media ${DEVICE.laptopL} {
		padding: 0 10px;
	}

`;
