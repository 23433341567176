import { NotificationManager } from 'react-notifications';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Papa from 'papaparse';

// Errors
import { ERRORS } from './constants/Error';

export function copyToClipboard(input) {
	/* Select the header field */
	input.select();
	input.setSelectionRange(0, 99999);

	/* Copy the header inside the header field */
	document.execCommand('copy');

	// @TODO Add message that it's copied
}

export function showError(errorType, description = '', timeout = 5000, onClick = false) {
	const errorDetails = ERRORS[errorType] || {
		title: errorType, // Show custom error
	};
	return errorDetails && NotificationManager.error(description.length > 0 ? description : errorDetails.description, errorDetails.title, timeout, onClick === false ? undefined : onClick);
}

export function showSuccess(title, message, timeout = 5000) {
	return NotificationManager.success(message, title, timeout);
}

export function inProduction() {
	showSuccess('Coming Soon', 'We are currently working on this feature.');
}

export function createFormDataFromObject(object) {
	const formData = new FormData();
	// eslint-disable-next-line no-restricted-syntax
	for (const key in object) {
		// eslint-disable-next-line no-prototype-builtins
		if (object.hasOwnProperty(key)) {
			formData.append(key, object[key]);
		}
	}
	return formData;
}

export function getInPath(obj, path) {
	// Cache the current object
	let current = obj;
	// For each item in the path, dig into the object
	for (let i = 0; i < path.length; i += 1) {
		// If the item isn't found, return the default (or null)
		if (!current[path[i]]) { return null; }
		// Otherwise, update the current  value
		current = current[path[i]];
	}
	return current;
}

export const formatTradeTimeStamp = (seconds, nanos) => {
	const ts = moment(`${seconds.split(',').join('')}${nanos.toString().substring(0, 3)}`, 'x');
	return ts.format('HH:mm:ss.SSS');
};
const prepareData = (header, entries) => {
	const data = [];
	data.push(header);
	entries.forEach((item) => {
		if (item.side === 'BUY') {
			item.side = 'B';
		} else if (item.side === 'SELL') {
			item.side = 'S';
		}

		if (item.ordType === 'LIMIT') {
			item.ordType = 'Limit';
		}

		if (item.timeInForce === 'DAY') {
			item.timeInForce = 'Day';
		}
		data.push([item.memberId, item.side, item.quantity, item.price, item.ordType, item.timeInForce, '']);
	});
	return data;
};
export const downLoadXlsxData = (entries, symbol) => {
	const data = prepareData(['MEMBER_ID', 'SIDE', 'QUANTITY', 'PRICE', 'ORDER_TYPE', 'VALIDITY', 'EXPIRY_DATE'], entries);
	const wsName = 'Sheet1';

	const wb = XLSX.utils.book_new();
	const ws = XLSX.utils.aoa_to_sheet(data);

	/* add worksheet to workbook */
	wb.SheetNames.push(wsName);
	wb.Sheets[wsName] = ws;
	const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });

	saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${symbol}.xlsx`);
};

export const downloadCsvData = (entries, symbol) => {
	const data = prepareData(['MEMBER_ID', 'SIDE', 'QUANTITY', 'PRICE', 'ORDER_TYPE', 'VALIDITY', 'EXPIRY_DATE'], entries);

	const csvData = Papa.unparse(data);
	saveAs(new Blob([csvData], { type: 'application/octet-stream' }), `${symbol}.csv`);
};

export const uploadXlFile = (file, callback) => {
	const reader = new FileReader();
	reader.onload = (event) => {
		const data = event.target.result;
		const wb = XLSX.read(data, { type: 'binary' });
		const SheetName = wb.SheetNames[0];
		const ws = wb.Sheets[SheetName];
		const orderBook = XLSX.utils.sheet_to_json(ws);
		callback(orderBook);
	};
	reader.readAsBinaryString(file);
};
function parseBooks(result) {
	const csvOrderBooks = [];
	const indexOrderBooks = [];
	result.data.forEach((value, index) => {
		if (index === 0) {
			// set keys
			value.forEach((keyValue, keyIndex) => {
				indexOrderBooks[keyIndex] = keyValue;
			});
		} else {
			// set values
			const newOrderBook = {};
			value.forEach((valueValue, valueIndex) => {
				newOrderBook[indexOrderBooks[valueIndex]] = valueValue;
			});

			csvOrderBooks.push(newOrderBook);
		}
	});

	// remove last part, an empty array
	csvOrderBooks.splice(-1, 1);
	return csvOrderBooks;
}
export const uploadCsvFile = (file, callback) => {
	Papa.parse(file, {
		dynamicTyping: true,
		complete(results) {
			callback(parseBooks(results));
		},
	});
};

export const parseCsvAsync = file => new Promise((resolve, reject) => {
	Papa.parse(file, {
		dynamicTyping: true,
		complete(results) {
			const csvOrderBooks = parseBooks(results);
			resolve(csvOrderBooks);
		},
	});
});
