import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


// Constants and data
import { COLORS } from '../../../../lib/constants/Style';
import { TextBig } from '../../../Css/Css';

// Atoms
import { FlexColumn } from '../../../Atoms/Flex';
import { BaseDivider, MediumDivider, MediumVerticalDivider } from '../../../Atoms/Divider';
import { BoldText } from '../../../Atoms/WebSite/Text';

// Organisms
import ScrollBar from '../../Scrollbar';
import FieldEnumTable from '../../TableModels/FieldEnumTable';

const Wrapper = styled.div`
  & > h2 {
		margin-bottom: 26px;
	}
	display: flex;
    flex-direction: column;
    height: 100%;
`;

const Title = styled.div`
  ${TextBig};
  color: ${COLORS.lightBlack};
  text-align: left;
`;

const TextWrapper = styled.div`
	text-align: left;
`;

const TablesWrapper = styled.div`
	background: ${COLORS.borderGray};
	margin: 0 -30px -30px -30px;
	padding: 30px;
	height: 100%;
	display: flex;
	justify-content: space-between;
`;

const TableWrapper = styled.div`
	background: white;
	padding: 30px;
	width: 100%;
`;

const ReferenceItem = styled.div`
	padding: 10px 5px;
	border-bottom: 1px solid ${COLORS.borderGray};
	text-align: left;
	cursor: pointer;

	:hover {
		background: ${COLORS.borderGray}
	}
`;

const ReferenceWrapper = styled.div`
	max-height: 540px;
	overflow: auto;
`;

const ExchangeDictionaryFieldModal = ({ data, openReferencedItem }) => (
	<Wrapper>
		<FlexColumn>
			<Title>
				{`${data?.name} [${data?.tag}]`}
			</Title>
			<MediumDivider />
			<TextWrapper>
				{'Data type: '}
				<BoldText>{data?.type}</BoldText>
				<BaseDivider />
				{data?.description}
			</TextWrapper>
			<MediumDivider />
		</FlexColumn>
		<TablesWrapper>
			<TableWrapper>
				<Title>Enumerated Values</Title>
				<MediumDivider />
				{data.values && (
					<ReferenceWrapper>
						<ScrollBar>
							<FieldEnumTable rows={typeof data.values === 'object' ? data.values : {}} />
						</ScrollBar>
					</ReferenceWrapper>
				)}
				{!data.values && <TextWrapper>No enumerated values for this field</TextWrapper>}
			</TableWrapper>
			<MediumVerticalDivider double />
			<TableWrapper>
				<Title>
							Field References
				</Title>
				<MediumDivider />
				{data?.referencedBy && (
					<ReferenceWrapper>
						<ScrollBar>
							{data?.referencedBy.map(item => (
								<ReferenceItem onClick={() => openReferencedItem(item.name)} key={item.name}>
									{item.name}
								</ReferenceItem>
							))}
						</ScrollBar>
					</ReferenceWrapper>
				)}
			</TableWrapper>
		</TablesWrapper>
	</Wrapper>
);

ExchangeDictionaryFieldModal.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.shape({
			content: PropTypes.shape({}),
			length: PropTypes.func,
		})),
		PropTypes.shape({
			content: PropTypes.shape({}),
			length: PropTypes.func,
			title: PropTypes.string,
		}),
	]).isRequired,
	openReferencedItem: PropTypes.func.isRequired,
};

export default ExchangeDictionaryFieldModal;
