import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Atoms
import { HeaderWrapper } from '../Atoms';

// Organisms
import HeaderRightSide from '../HeaderRightSide';

class AppHeader extends PureComponent {
	render() {
		return (
			<HeaderWrapper>
				<HeaderRightSide />
			</HeaderWrapper>
		);
	}
}

export default AppHeader;
