import React, {
	useState, useRef, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
// Atoms
import { useSelector } from 'react-redux';
import {
	PrimaryButton, SecondaryButton,
} from '../../Atoms/Button';
import { BaseDivider, SmallDivider } from '../../Atoms/Divider';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import {
	HalfWidthWrapper, OrderEntryContent, OrderEntryWrapper,
} from './OrderBook.Atoms';

// Molecules
import { StyledDatePicker } from '../../Molecules/StyledDatePicker';
import Input from '../../Molecules/Input/Input/Input';
import SelectBox from '../../Molecules/Input/SelectBox/SelectBox';
import OrderEntryInput from '../Modals/AddOrderBlotterModal/AddOrderBlotterModal.Molecules';
import { EXCHANGE_STATUS, ORDER_SIDE, ORDER_TYPE } from '../../../lib/constants/Exchange';
import { OrderBlotterValidity, OrderBlotterValidityValueToSend } from '../../../lib/constants/Modal';
import { InputManager } from '../ExchangeBehavior/Atoms';
import { submitOrder } from '../../../lib/websockets';
import WebSocketConnectionContext from '../../Context/WebsocketConnectionContext';
import { getActiveExchange } from '../../../data/exchanges/selectors';
import { showError } from '../../../lib/helpers';
import { getRandomClientOrderId } from '../../../lib/exchange';
import Scrollbar from '../Scrollbar';

const OrderEntrySection = (props) => {
	const {
		bookId,
	} = props;
	const [side, setSide] = useState(ORDER_SIDE.BUY);
	const [orderType, setOrderType] = useState(ORDER_TYPE.LIMIT);
	const [validity, setValidity] = useState(OrderBlotterValidity.Day);
	const [pickedDate, setPickedDate] = useState(null);
	const WebsocketContext = useContext(WebSocketConnectionContext);
	const quantityRef = useRef();
	const priceRef = useRef();
	const exchange = useSelector(getActiveExchange);

	const viewCode = exchange.getIn(['orderBooks', Number(bookId), 'viewCode']);
	const fixType = exchange.getIn(['exchangeType', 'code']);

	const handleSubmit = useCallback(() => {
		if (exchange.get('status') !== EXCHANGE_STATUS.OPENED) {
			showError('Exchange not opened', "Can't submit orders if exchange is not opened");
			return;
		}
		if (quantityRef.current.hasError() || priceRef.current.hasError()) {
			return;
		}
		const {
			client,
			sessionId,
			setClientOrderId,
		} = WebsocketContext;
		const generatedClientOrderId = getRandomClientOrderId();
		const data = {
			memberId: '99999',
			instrumentCode: viewCode,
			side,
			ordType: orderType,
			price: priceRef.current.getValue(),
			quantity: quantityRef.current.getValue(),
			clientOrderId: generatedClientOrderId,
			timeInForce: OrderBlotterValidityValueToSend[validity],
		};
		if (!client || !client.isConnected()) {
			showError('Error', 'You are not connected');
			return;
		}
		setClientOrderId(generatedClientOrderId);
		submitOrder(client, fixType, sessionId, data);
	}, [WebsocketContext, exchange, fixType, orderType, side, validity, viewCode]);

	const handleReset = useCallback(() => {
		setSide(ORDER_SIDE.BUY);
		setOrderType(ORDER_TYPE.LIMIT);
		setValidity(OrderBlotterValidity.Day);
		priceRef.current.resetState();
		quantityRef.current.resetState();
	}, []);

	return (
		<OrderEntryWrapper className="order-entry-section">
			<Scrollbar>
				<OrderEntryContent>
					<FlexColumn>
						<OrderEntryInput
							selected={side}
							setSelected={setSide}
							smaller
						/>
						<SmallDivider />
						<FlexRow justifyContent="space-between">
							<HalfWidthWrapper>
								<Input
									type="header"
									name="quantity"
									placeholder="Quantity"
									smaller
									ref={quantityRef}
									validators={[
										{
											validator: 'isNumeric',
											errorResult: false,
										},
									]}
								/>
							</HalfWidthWrapper>
							<HalfWidthWrapper>
								<InputManager
									active={orderType === ORDER_TYPE.LIMIT}
								>
									<Input
										name="price"
										type="header"
										placeholder="Price"
										smaller
										ref={priceRef}
										validators={[
											{
												validator: 'isNumeric',
												errorResult: false,
											},
										]}
									/>
								</InputManager>
							</HalfWidthWrapper>
						</FlexRow>
						<BaseDivider />
						<FlexRow justifyContent="space-between">
							<HalfWidthWrapper>
								<SelectBox
									smaller
									label="Order Type"
									options={[ORDER_TYPE.LIMIT, ORDER_TYPE.MARKET]}
									selected={orderType}
									onClick={setOrderType}
								/>
							</HalfWidthWrapper>
							<HalfWidthWrapper>
								<SelectBox
									label="Validity"
									smaller
									options={[OrderBlotterValidity.Day, OrderBlotterValidity.GTC, OrderBlotterValidity.GTD, OrderBlotterValidity.IOC, OrderBlotterValidity.FOK]}
									selected={validity}
									onClick={setValidity}
								/>
							</HalfWidthWrapper>
						</FlexRow>
						<BaseDivider />
						<FlexRow justifyContent="space-between">
							<InputManager
								active={validity === OrderBlotterValidity.GTC || validity === OrderBlotterValidity.GTD}
							>
								<StyledDatePicker
									placeholder="Expiry Date"
									startDate={pickedDate}
									setStartDate={setPickedDate}
								/>
							</InputManager>
						</FlexRow>
					</FlexColumn>
					<FlexColumn>
						<BaseDivider />
						<FlexRow justifyContent="space-between">
							<PrimaryButton style={{ width: '49%' }} onClick={handleSubmit}>Submit order</PrimaryButton>
							<SecondaryButton style={{ width: '49%' }} onClick={handleReset}>Reset</SecondaryButton>
						</FlexRow>
					</FlexColumn>
				</OrderEntryContent>
			</Scrollbar>
		</OrderEntryWrapper>
	);
};

OrderEntrySection.propTypes = {
	bookId: PropTypes.string.isRequired,
};
export default OrderEntrySection;
