import React, {
	memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import Tooltip from 'react-tooltip';
import * as PropTypes from 'prop-types';
import usePrepareDataForRender from '../../../Hooks/usePrepareDataForRender';
import useSortBy from '../../../Hooks/useSortBy';
import useSearch from '../../../Hooks/useSearch';
// Data
import { messageTrafficHeader } from '../../../../data/placeholderData/messageTraffic';
import { getActiveExchange } from '../../../../data/exchanges/selectors';
import { toggleMessageTrafficModal } from '../../../../data/ui/UIActions';
// Atoms
import { FlexRow } from '../../../Atoms/Flex';
import { MediumVerticalDivider } from '../../../Atoms/Divider';
import {
	ActionsWrapper, MessageTrafficTableWrapper, ModalLink, Wrapper,
} from './MessageTrafficTableAtoms';
// Molecules
import SelectBox from '../../../Molecules/Input/SelectBox/SelectBox';
import SearchField from '../../../Molecules/Input/SearchField/SearchField';
// Organisms
import TableView from '../../Table/TableView';
import { MessageTrafficDirection } from './MessageTrafficDirection';


function MessageTrafficTable({ messages }) {
	const [tableHeader, setTableHeader] = useState([...messageTrafficHeader]);
	const [tableRows, setTableRows] = useState([]);
	const dispatch = useDispatch();

	// Values for select box filters
	const directions = useMemo(() => [...new Set(messages?.map(m => m.get('direction')))], [messages]);
	const messageTypes = useMemo(() => [...new Set(messages?.map(m => m.get('messageType')))], [messages]);
	const gateways = useMemo(() => [...new Set(messages?.map(m => m.get('trdgwy')))], [messages]);
	const sessions = useMemo(() => [...new Set(messages?.map(m => m.get('session')))], [messages]);

	useEffect(() => {
		setTableRows(messages);
	}, [messages]);

	const handleHeaderVisibilityChange = useCallback((header) => {
		setTableHeader((prevState) => {
			const newState = [...prevState];
			const index = newState.findIndex(item => item.name === header);
			newState[index].visible = !newState[index].visible;
			return newState;
		});
	}, []);

	const handleOpenModal = useCallback((data) => {
		dispatch(toggleMessageTrafficModal(true, data));
	}, [dispatch]);

	const getDataToRender = useCallback(r => r?.map((row) => {
		const dataForModal = { message: row.get('message'), rawMessage: row.get('rawMessage') };
		const dateFormat = moment(row.get('timestamp')).format('YYYY-MM-DD HH:mm:ss.SSS');
		return ({
			data: [
				{
					render: () => <p>{dateFormat}</p>,
					accessor: row.get('timestamp'),
				},
				{
					render: () => <MessageTrafficDirection direction={row.get('direction')} />,
					accessor: row.get('direction'),
				},
				row.get('seqNum') ? Number(row.get('seqNum')) : '',
				{
					render: () => <ModalLink onClick={() => handleOpenModal(dataForModal)}>{row.get('messageType')}</ModalLink>,
					accessor: row.get('messageType'),
				},

				row.get('session') || '',
				row.get('trdgwy') || '',
			],
			metaData: {
				key: row.get('orderId'),
			},
		});
	}), [handleOpenModal]);

	const { searchInputValue, setSearchInputValue } = useSearch(messages, setTableRows, tableHeader, true);
	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, tableRows);

	useEffect(() => {
		Tooltip.rebuild();
	}, [dataToRender]);

	return (
		<>
			<ActionsWrapper>
				<FlexRow className="message-traffic-filters">
					<Wrapper>
						<SelectBox
							smaller
							label="TG"
							onClick={(v) => {
								setTableHeader((prevState) => {
									const newState = [...prevState];
									newState[5].filterValue = v;
									return newState;
								});
							}}
							options={['All', ...gateways]}
							selected={tableHeader[5].filterValue}
						/>
					</Wrapper>
					<MediumVerticalDivider />
					<Wrapper>
						<SelectBox
							smaller
							label="Sessions"
							onClick={(v) => {
								setTableHeader((prevState) => {
									const newState = [...prevState];
									newState[4].filterValue = v;
									return newState;
								});
							}}
							options={['All', ...sessions]}
							selected={tableHeader[4].filterValue}
						/>
					</Wrapper>
					<MediumVerticalDivider />
					<Wrapper>
						<SelectBox
							smaller
							label="Direction"
							onClick={(v) => {
								setTableHeader((prevState) => {
									const newState = [...prevState];
									newState[1].filterValue = v;
									return newState;
								});
							}}
							options={['All', ...directions]}
							selected={tableHeader[1].filterValue}
						/>
					</Wrapper>
					<MediumVerticalDivider />
					<Wrapper>
						<SelectBox
							smaller
							label="Message Type"
							onClick={(v) => {
								setTableHeader((prevState) => {
									const newState = [...prevState];
									newState[3].filterValue = v;
									return newState;
								});
							}}
							options={['All', ...messageTypes]}
							selected={tableHeader[3].filterValue}
						/>
					</Wrapper>
					<MediumVerticalDivider />
					<Wrapper>
						<SearchField
							placeholder="Search"
							white
							onChange={setSearchInputValue}
							value={searchInputValue}
						/>
					</Wrapper>
				</FlexRow>
				<MediumVerticalDivider />
				<MediumVerticalDivider />
			</ActionsWrapper>
			<MessageTrafficTableWrapper className="message-traffic-table">

				<TableView
					sortedBy={sortedBy}
					handleSort={handleSort}
					header={tableHeader}
					rows={dataToRender}
					selectable={false}
					handleHeaderVisibilityChange={handleHeaderVisibilityChange}
					hasSettings
					headerHeight="25px"
					rowHeight="32px"
				/>
			</MessageTrafficTableWrapper>
		</>
	);
}
MessageTrafficTable.propTypes = {
	messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
function mapStateToProps(state) {
	return {
		messages: getActiveExchange(state)?.get('messages'),
	};
}
export default memo(connect(mapStateToProps, null)(MessageTrafficTable));
