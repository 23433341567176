import React, { PureComponent, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import {
	MediumDivider,
	MediumVerticalDivider, BaseDivider,
} from '../../Atoms/Divider';
import { FullWidthContainer, FullWidthFlexRow } from '../../Atoms/Wrapper';
// Molecules
import Modal from '../../Molecules/Modal';
import Input from '../../Molecules/Input/Input/Input';
// Actions
import { toggleAddSessionModal } from '../../../data/ui/UIActions';
import { fetchOrderBooks } from '../../../data/orderBooks/OrderBooksActions';

class AddSessionModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
		};
		this.senderCompId = createRef();
		this.targetCompId = createRef();
		this.senderSubId = createRef();
		this.targetSubId = createRef();
		this.senderLocationId = createRef();
		this.targetLocationId = createRef();
		this.heartbeatInterval = createRef();
		this.reconnectInterval = createRef();
		this.startTime = createRef();
		this.stopTime = createRef();
	}

	handleAddSession = () => {
		// TODO REFACTOR THIS

		const error1 = this.senderCompId.current?.hasError();
		const error2 = this.targetCompId.current?.hasError();
		const error3 = this.heartbeatInterval.current?.hasError();
		const error4 = this.reconnectInterval.current?.hasError();
		const error5 = this.startTime.current?.hasError();
		const error6 = this.stopTime.current?.hasError();
		if (error1 || error2 || error3 || error4 || error5 || error6) {
			return;
		}
		const session = {
			fixSessionId: null,
			targetCompId: this.targetCompId.current.getValue(),
			senderCompId: this.senderCompId.current.getValue(),
			targetSubId: this.targetSubId.current.getValue(),
			senderSubId: this.senderSubId.current.getValue(),
			targetLocationId: this.targetLocationId.current.getValue(),
			senderLocationId: this.senderLocationId.current.getValue(),
			startTime: this.startTime.current.getValue(),
			stopTime: this.stopTime.current.getValue(),
			heartbeatInterval: this.heartbeatInterval.current.getValue(),
			reconnectInterval: this.reconnectInterval.current.getValue(),
		};
		this.props.addSession(session);
		this.handleCloseModal();
	};

	handleCloseModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleAddSessionModal(false);
	};

	render() {
		const {
			error,
		} = this.state;
		const {
			modalInfo,
		} = this.props;

		if (modalInfo?.get('visible') !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleCloseModal}
				size={MODAL_SIZES.MEDIUM}
			>
				<FlexColumn>
					<ModalTitle>
						Add Session
					</ModalTitle>
					<MediumDivider />
					<FullWidthFlexRow>
						<FullWidthContainer>
							<Input
								placeholder="SenderCompID"
								type="text"
								name="senderCompId"
								ref={this.senderCompId}
								validators={[
									{
										validator: 'isLength',
										options: {
											min: 1,
										},
										errorResult: false,
										message: "SenderCompID can't be empty",
									},
								]}
							/>
						</FullWidthContainer>
						<MediumVerticalDivider />
						<FullWidthContainer>
							<Input
								placeholder="TargetCompID"
								type="text"
								name="targetCompId"
								ref={this.targetCompId}
								validators={[
									{
										validator: 'isLength',
										options: {
											min: 1,
										},
										errorResult: false,
										message: "TargetCompID can't be empty",
									},
								]}
							/>
						</FullWidthContainer>
					</FullWidthFlexRow>
					<FullWidthFlexRow>
						<Input
							placeholder="SenderSubID"
							type="text"
							name="instrumentType"
							ref={this.senderSubId}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="TargetSubId"
							type="text"
							name="openingPrice"
							ref={this.targetSubId}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="SenderLocationID"
							type="text"
							name="viewCode"
							ref={this.senderLocationId}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="TargetLocationID"
							type="header"
							name="bbgCode"
							ref={this.targetLocationId}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<FullWidthContainer>
							<Input
								placeholder="HeartBeatInterval"
								type="text"
								name="isinCode"
								ref={this.heartbeatInterval}
								initialValue="30"
								validators={[
									{
										validator: 'isNumeric',
										errorResult: false,
										message: 'Invalid input',
									},
								]}
							/>
						</FullWidthContainer>
						<MediumVerticalDivider />
						<FullWidthContainer>
							<Input
								placeholder="ReconnectInterval"
								type="text"
								name="ricCode"
								ref={this.reconnectInterval}
								initialValue="30"
								validators={[
									{
										validator: 'isNumeric',
										errorResult: false,
										message: 'Invalid input',
									},
								]}
							/>
						</FullWidthContainer>
					</FullWidthFlexRow>
					<FullWidthFlexRow>
						<FullWidthContainer>
							<Input
								placeholder="Start Time"
								type="text"
								name="isinCode"
								ref={this.startTime}
								initialValue="00:00:00"
								validators={[
									{
										validator: 'matches',
										errorResult: false,
										options: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
										message: 'Invalid format',
									},
								]}
							/>
						</FullWidthContainer>
						<MediumVerticalDivider />
						<FullWidthContainer>
							<Input
								placeholder="Stop Time"
								type="header"
								name="ricCode"
								ref={this.stopTime}
								initialValue="00:00:00"
								validators={[
									{
										validator: 'matches',
										errorResult: false,
										options: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
										message: 'Invalid format',
									},
								]}
							/>
						</FullWidthContainer>
					</FullWidthFlexRow>
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={this.handleAddSession}>
							Add
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={this.handleCloseModal}>
							Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}

AddSessionModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleAddOrderBookModal: PropTypes.func,
		fetchOrderBooks: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,

};
AddSessionModal.defaultProps = {

};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.ADD_SESSION_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleAddSessionModal,
			fetchOrderBooks,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSessionModal));
