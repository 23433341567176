import styled from 'styled-components';
import { TextExtraBig, TextLarge } from '../Css/Css';
import { COLORS } from '../../lib/constants/Style';
import { DEVICE } from '../../lib/constants/Device';

export const PageTitle = styled.h1`
	${TextExtraBig};
	text-transform: uppercase;
	color: ${COLORS.lightBlack};
	@media ${DEVICE.laptopL} {
		${TextLarge};
	}
`;
