import React from 'react';
// Atoms
import { HeaderWrapper, HeaderLeftSide } from '../Atoms';

// Molecules
import SiteMenu from './SiteMenu';
import HeaderRightSide from '../HeaderRightSide';
import Logo from '../../../Molecules/Layout/Logo';
import HamburgerMenu from './HamburgerMenu';


const SiteHeader = () => (
	<HeaderWrapper>
		<HeaderLeftSide>
			<Logo to="/" href="/" public />
			<SiteMenu />
		</HeaderLeftSide>
		<HeaderRightSide isPublic />
		<HamburgerMenu />
	</HeaderWrapper>
);

export default SiteHeader;
