import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { InfoTable, Wrapper } from '../Atoms/Table';
import { getActiveExchange } from '../../data/exchanges/selectors';

// TODO take props and filter them based on selected member
const SpecificMemberCountersTable = (props) => {
	const {
		memberBidsCount,
		memberOffersCount,
		memberTradesCount,
	} = props;
	const selectedMember = useSelector(state => getActiveExchange(state).get('selectedMember'));
	const bids = memberBidsCount?.get(selectedMember.key) || 0;
	const offers = memberOffersCount?.get(selectedMember.key) || 0;
	const total = bids + offers;
	return (
		<Wrapper>
			<InfoTable>
				<tbody>
					<tr>
						<td>My Orders:</td>
						<td>{total}</td>
					</tr>

					<tr>
						<td>My Bids:</td>
						<td>{bids}</td>
					</tr>
					<tr>
						<td>My Offers:</td>
						<td>{offers}</td>
					</tr>
					<tr>
						<td>My Trades:</td>
						<td>{memberTradesCount?.get(selectedMember.key) ?? 0}</td>
					</tr>

				</tbody>
			</InfoTable>
		</Wrapper>
	);
};
SpecificMemberCountersTable.propTypes = {
	memberBidsCount: PropTypes.shape({}).isRequired,
	memberOffersCount: PropTypes.shape({}).isRequired,
	memberTradesCount: PropTypes.shape({}).isRequired,
};
export default SpecificMemberCountersTable;
