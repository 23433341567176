import styled, { css } from 'styled-components';
import { COLORS, FONT_SIZES, STATUS } from '../../lib/constants/Style';

const baseStatusStyle = css`
	text-transform: uppercase;
	font-size: ${FONT_SIZES.baseSmall};
	font-weight: 600;
	letter-spacing: 0.2px;
`;

export const VMStatusSmall = styled.p`
	${baseStatusStyle};
	${(props) => {
		switch (props.status) {
		case 'RUNNING': {
			return css`
				color: ${COLORS.green};
			`;
		}
		case 'ARCHIVED':
		case 'PENDING':
		case 'RESTARTING':
		case 'SUSPENDING':
		case 'RESUMING':
		case 'TERMINATING':
		case 'ARCHIVING':
		case 'RESTORING':
		{
			return css`
				color: ${COLORS.yellow};
			`;
		}
		case 'SUSPENDED':
		case 'TERMINATED': {
			return css`
				color: ${COLORS.red};
			`;
		}
		default:
			return css`
				color: ${COLORS.darkGrey};
			`;
		}
	}
}
`;
export const ExchangeStatusSmall = styled.p`
	${baseStatusStyle};
	${(props) => {
		switch (props.status) {
		case 'STARTED': {
			return css`
				color: ${COLORS.green};
			`;
		}
		case 'OPENED': {
			return css`
				color: ${COLORS.blue};
			`;
		}
		case 'OPENING':
		case 'CLOSING':
		case 'STARTING':
		case 'STOPPING':
		case 'CLOSED':
		{
			return css`
				color: ${COLORS.yellow};
			`;
		}
		case 'STOPPED': {
			return css`
				color: ${COLORS.red};
			`;
		}
		default:
			return css`
				color: ${COLORS.darkGrey};
			`;
		}
	}
}
`;
export const Status = styled.span`
	padding: 10px 12px;
	text-align: center;
	${baseStatusStyle};
	border-radius: 4px;
	${props => props.status === STATUS.RUNNING && css`
		background: ${COLORS.lightGreen};
		color: ${COLORS.green};
	`}
	${props => props.status === STATUS.CLOSED && css`
		background: ${COLORS.lightBlue};
		color: ${COLORS.blue};
	`}
	${props => props.status === STATUS.OPENED && css`
		background: ${COLORS.lightRed};
		color: ${COLORS.red};
	`}
	
	${props => props.status === STATUS.PAID && css`
		background: ${COLORS.lightGreen};
		color: ${COLORS.green};
	`}
	${props => props.status === STATUS.UNPAID && css`
		background: ${COLORS.lightestYellow};
		color: ${COLORS.yellow};
	`}
	${props => props.status === STATUS.INACTIVE && css`
		background: ${COLORS.lightestGrey};
		color: ${COLORS.grey};
	`}
`;
