import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

import { toggleModal } from '../../../data/ui/UIActions';
// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn } from '../../Atoms/Flex';
import {
	MediumDivider,
} from '../../Atoms/Divider';
// Molecules
import Modal from '../../Molecules/Modal';
import ProgressBar from '../../Molecules/ProgressBar';
import math from '../../../lib/math';
import { COLORS } from '../../../lib/constants/Style';

const ProgressBarContainer = styled.div`
	height: 40px;
	width: 100%;
`;
class LoaderModal extends Component {
	handleClose = () => {
		const {
			actions,
		} = this.props;
		actions.toggleModal(MODALS.LOADING_MODAL, false);
	};

	render() {
		const {
			modalInfo,
			modalData,
			percentage,
		} = this.props;

		if (modalInfo.get('visible') !== true) {
			return false;
		}
		if (math.ceil(percentage) >= 100) {
			// Added this because of weird bug
			this.handleClose();
		}
		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleClose}
				size={MODAL_SIZES.MEDIUM}
			>
				<FlexColumn alignItems="center">
					<ModalTitle>
						{modalData?.get('title')}
					</ModalTitle>

					<MediumDivider />
					<ProgressBarContainer>
						<ProgressBar
							percentage={math.ceil(percentage)}
							noText
							bigger
							trailColor={COLORS.darkestGrey}
						/>
					</ProgressBarContainer>
				</FlexColumn>
			</Modal>
		);
	}
}
LoaderModal.defaultProps = {
	percentage: 0,
};
LoaderModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleModal: PropTypes.func,
	}).isRequired,
	modalData: PropTypes.shape({
		// Title of the modal
		title: PropTypes.string,
	}).isRequired,
	// Loading percentage to render
	percentage: PropTypes.number,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.LOADING_MODAL]),
		modalData: state.getIn(['ui', 'modal', MODALS.LOADING_MODAL, 'data']),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoaderModal));
