import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

// Selectors
import { getOrderBookTradeTickerData, getOrderBookBBBOData } from '../../../data/exchanges/selectors';

// Lib
import { showError, uploadCsvFile, uploadXlFile } from '../../../lib/helpers';
import { EXCHANGE_STATUS, OPERATION } from '../../../lib/constants/Exchange';
import {
	addOperation,
	cancelOperation,
	loadMdrOrderBooks,
	modifyOperation,
} from '../../../lib/orderBook';
// Css
import { TextBaseSmall } from '../../Css/Css';
// Atoms
import { FlexColumnBase, FlexRow } from '../../Atoms/Flex';
import { ImportButton, PrimaryButton } from '../../Atoms/Button';
import { BaseDivider, BigVerticalDivider } from '../../Atoms/Divider';
import { InfoTable, InfoTableCell, TableProgressBarWrapper } from '../../Atoms/Table';
// Molecules
import ProgressBar from '../../Molecules/ProgressBar';
import Scrollbar from '../Scrollbar';
import { GrayControlIcon } from '../../Atoms/ControlIcons';
import { DEVICE } from '../../../lib/constants/Device';
import WebSocketConnectionContext from '../../Context/WebsocketConnectionContext';
import ConfirmationsProgress from './ConfrimationsProgress';

const OrderBookMDReplayWrapper = styled.div`
	${FlexColumnBase};
	padding: 30px 15px 30px 30px;
	${TextBaseSmall};
	width: 100%;
	overflow: hidden;
	flex: 1;
	display: none;
	${props => props.visible && 'display: block;'};
	@media ${DEVICE.laptopL} {
		padding: 15px 0 15px 15px;
	};

`;

const header = ['TIME', 'VIEW_CODE', 'TYPE', 'SIDE', 'QUANTITY', 'PRICE', 'MEMBER_ID_BUY', 'MEMBER_ID_SELL', 'ORDER_ID_BUY', 'ORDER_ID_SELL', 'CLIENT_ORDER_ID_BUY',
	'CLIENT_ORDER_ID_SELL', 'TRADE_ID', 'BEST_BID_PRICE', 'BEST_BID_QTY', 'BEST_OFFER_PRICE', 'BEST_OFFER_QTY', 'LAST_PRICE', 'LAST_QTY', 'LAST_CHANGE'];


const OrderBookMdReplaySection = ({
	client, fix, sessionId, symbol, exchangeStatus, visible, instanceId, bookId,
}) => {
	const [uploadedFile, setUploadedFile] = useState('');
	const [fileName, setFileName] = useState('N/A');
	const [orderBookLength, setOrderBookLength] = useState(0);

	const [currentOrderIndex, setCurrentOrderIndex] = useState(0);
	// const [confirmedOrders, setConfirmedOrders] = useState(0);

	const [stopReplay, setStopReplay] = useState(false);
	const [startReplay, setStartReplay] = useState(false);
	const [pauseReplay, setPauseReplay] = useState(true);
	const [sentEvents, setSentEvents] = useState(0);

	const [exportReport, setExportReport] = useState([header]);

	const WebsocketContext = useContext(WebSocketConnectionContext);

	const tradeTickerData = useSelector(state => getOrderBookTradeTickerData(state, instanceId, bookId));
	const bbboData = useSelector(state => getOrderBookBBBOData(state, instanceId, bookId));
	const lastReplayEvent = useSelector(state => state.get('replay'));

	useEffect(() => {
		if (lastReplayEvent) {
			setExportReport([...exportReport, [
				lastReplayEvent.get('time'),
				lastReplayEvent.get('viewCode'),
				lastReplayEvent.get('type'),
				lastReplayEvent.get('side'),
				lastReplayEvent.get('quantity'),
				lastReplayEvent.get('price'),
				lastReplayEvent.get('memberIdBuy'),
				lastReplayEvent.get('memberIdSell'),
				lastReplayEvent.get('orderIdBuy'),
				lastReplayEvent.get('orderIdSell'),
				lastReplayEvent.get('clientOrderIdBuy'),
				lastReplayEvent.get('clientOrderIdSell'),
				lastReplayEvent.get('tradeId'),
				bbboData?.get('bestBidPrice'),
				bbboData?.get('bestBidQuantity'),
				bbboData?.get('bestOfferPrice'),
				bbboData?.get('bestOfferQuantity'),
				bbboData?.get('lastCrossingPrice'),
				bbboData?.get('lastCrossingQuantity'),
				tradeTickerData?.get('changeAbsolute'),
			]]);
		}
		// eslint-disable-next-line
	}, [lastReplayEvent]);

	const handleStopReplay = () => {
		setStopReplay(true);
		setStartReplay(false);
		setPauseReplay(true);
	};

	useEffect(() => {
		setOrderBookLength(uploadedFile.length);
		// eslint-disable-next-line
	}, [uploadedFile.length]);


	useEffect(() => {
		if (currentOrderIndex === uploadedFile.length) {
			handleStopReplay();
		} else if (!pauseReplay && uploadedFile.length >= currentOrderIndex) {
			setTimeout(() => setCurrentOrderIndex(count => count + 1), uploadedFile[currentOrderIndex].TIME);
		}
		return () => clearTimeout();
		// eslint-disable-next-line
	}, [currentOrderIndex, uploadedFile, pauseReplay]);

	// handle operations
	useEffect(() => {
		if (currentOrderIndex >= 0 && uploadedFile && uploadedFile[currentOrderIndex] && (uploadedFile[currentOrderIndex].VIEW_CODE === symbol) && startReplay) {
			if (uploadedFile[currentOrderIndex].OPERATION === OPERATION.ADD) {
				addOperation(client, fix, sessionId, symbol, uploadedFile[currentOrderIndex]);
				setSentEvents(e => e + 1);
			} else if (uploadedFile[currentOrderIndex].OPERATION === OPERATION.MODIFY) {
				modifyOperation(client, fix, sessionId, symbol, uploadedFile[currentOrderIndex]);
				setSentEvents(e => e + 1);
			} else if (uploadedFile[currentOrderIndex].OPERATION === OPERATION.CANCEL) {
				cancelOperation(client, fix, sessionId, symbol, uploadedFile[currentOrderIndex]);
				setSentEvents(e => e + 1);
			}
			if (currentOrderIndex === 0) {
				setExportReport([header]);
				setTimeout(() => setCurrentOrderIndex(count => count + 1), uploadedFile[currentOrderIndex].TIME);
			}
		}
	}, [currentOrderIndex, uploadedFile, symbol, client, fix, sessionId, startReplay]);

	const handleImport = (event) => {
		const { files } = event.target;
		const file = files[0];
		const extension = file.name.split('.').pop();
		if (extension === 'xlsx' || extension === 'xls') {
			uploadXlFile(file, (orderBook) => {
				try {
					loadMdrOrderBooks(orderBook, checkedOrderBook => setUploadedFile(checkedOrderBook), symbol);
					setFileName(file.name);
				} catch (error) {
					showError('File error', error.message);
				}
			});
		} else if (extension === 'csv') {
			uploadCsvFile(file, (orderBook) => {
				try {
					loadMdrOrderBooks(orderBook, checkedOrderBook => setUploadedFile(checkedOrderBook), symbol);
					setFileName(file.name);
				} catch (error) {
					showError('File error', error.message);
				}
			});
		}
	};

	const toggleReplay = () => {
		setStartReplay(true);
		if (stopReplay) {
			setCurrentOrderIndex(0);
			setStopReplay(false);
		}
		setPauseReplay(!pauseReplay);
	};

	const handleExportReplay = () => {
		const wsName = 'Sheet1';
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(exportReport);

		/* add worksheet to workbook */
		wb.SheetNames.push(wsName);
		wb.Sheets[wsName] = ws;
		const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });

		saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'mdreplay.xlsx');
	};

	return (
		<OrderBookMDReplayWrapper
			visible={visible}
			className="md-replay-section"
		>
			<Scrollbar>
				<FlexRow justifyContent="space-between" alignItems="center">
					<FlexRow>
						<PrimaryButton disabled={exchangeStatus !== EXCHANGE_STATUS.OPENED}>
							<ImportButton
								onChange={event => handleImport(event)}
								value=""
								type="file"
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
							/>
							Load from file
						</PrimaryButton>
					</FlexRow>
					<FlexRow>
						<GrayControlIcon
							noDisplay={!pauseReplay}
							data-tip="Play replay"
							name="play"
							onClick={() => {
								toggleReplay();
								if (stopReplay === true) {
									setSentEvents(0);
									WebsocketContext.countAllOrders(uploadedFile.length);
								}
							}}
							disabled={!(uploadedFile && exchangeStatus === EXCHANGE_STATUS.OPENED)}
						/>
						<GrayControlIcon
							noDisplay={pauseReplay}
							data-tip="Pause replay"
							name="pause"
							onClick={toggleReplay}
							disabled={!(uploadedFile && exchangeStatus === EXCHANGE_STATUS.OPENED)}
						/>
						<BigVerticalDivider />
						<GrayControlIcon
							data-tip="Stop replay"
							name="stopCircle"
							onClick={handleStopReplay}
							disabled={!(uploadedFile && startReplay && exchangeStatus === EXCHANGE_STATUS.OPENED)}
						/>
						<BigVerticalDivider />
						<GrayControlIcon
							data-tip="Export report"
							name="file"
							onClick={handleExportReplay}
							disabled={!(stopReplay && exchangeStatus === EXCHANGE_STATUS.OPENED && currentOrderIndex > 0)}
						/>
					</FlexRow>
				</FlexRow>
				<BaseDivider />
				<InfoTable>
					<tbody>
						<tr>
							<InfoTableCell>Source file</InfoTableCell>
							<InfoTableCell className="absolute">{fileName}</InfoTableCell>
						</tr>
						<tr>
							<InfoTableCell>Number of events</InfoTableCell>
							<InfoTableCell>{orderBookLength}</InfoTableCell>
						</tr>
						<tr>
							<InfoTableCell>Event sent</InfoTableCell>
							<InfoTableCell>{`${sentEvents}/${orderBookLength}`}</InfoTableCell>
							<TableProgressBarWrapper>
								<ProgressBar percentage={Math.round((sentEvents / orderBookLength) * 100)} />
							</TableProgressBarWrapper>
						</tr>
						<ConfirmationsProgress orderBookLength={orderBookLength} />
					</tbody>
				</InfoTable>
			</Scrollbar>
			<Tooltip />
		</OrderBookMDReplayWrapper>
	);
};

OrderBookMdReplaySection.propTypes = {
	client: PropTypes.shape({}).isRequired,
	fix: PropTypes.string.isRequired,
	sessionId: PropTypes.string.isRequired,
	symbol: PropTypes.string.isRequired,
	exchangeStatus: PropTypes.string.isRequired,
	// shows if the section should be visible or not
	visible: PropTypes.bool.isRequired,
	instanceId: PropTypes.string.isRequired,
	bookId: PropTypes.string.isRequired,
};

export default OrderBookMdReplaySection;
