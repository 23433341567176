import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import { FlexColumn } from '../Atoms/Flex';
import { BaseDivider } from '../Atoms/Divider';

// Molecules
import MarketStatus from '../Molecules/MarketStatus';


const ExchangeDictionaryHeader = (props) => {
	const {
		exchangeStatus,
		vmStatus,
		ipAddress,
	} = props;

	return (
		<FlexColumn>
			<BaseDivider />
			<MarketStatus
				ipAddress={ipAddress}
				vmStatus={vmStatus}
				exchangeStatus={exchangeStatus}
			/>
			<BaseDivider />
		</FlexColumn>
	);
};

ExchangeDictionaryHeader.defaultProps = {
	exchangeStatus: 'N/A',
	vmStatus: 'N/A',
	ipAddress: 'N/A',
};

ExchangeDictionaryHeader.propTypes = {
	exchangeStatus: PropTypes.string,
	vmStatus: PropTypes.string,
	ipAddress: PropTypes.string,
};

export default ExchangeDictionaryHeader;
