import { fromJS } from 'immutable';
import USER_TYPES from './UserTypes';


const initialState = fromJS({
	data: {},
});

export default function userReducer(state = initialState, action) {
	switch (action.type) {
	case USER_TYPES.SET_USER_DATA: {
		if (typeof action.payload.data === 'object') {
			return state.mergeIn(['data'], fromJS(action.payload.data));
		}
		return state;
	}
	case USER_TYPES.RESET_ALL: {
		return initialState;
	}
	default: {
		return state;
	}
	}
}
