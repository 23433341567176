import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Lib
import { DEVICE } from '../../../lib/constants/Device';

// Css
import { BaseText } from '../../Css/Css';

// Atoms
import { FlexRow } from '../Flex';
import { baseLink } from '../Link';
import { PrimaryButton } from '../Button';
import { FONT_SIZES } from '../../../lib/constants/Style';


const wrapperCenter = css`
    justify-content: center;
    text-align: center;
    max-width: 900px;
`;

export const Wrapper = styled.div`
	background-color: white;
	width: 100%;
	display: flex;
	max-width: 1600px;
	margin: 0 auto;
	align-items: center;
	
    ${prop => prop.center && css`
		${wrapperCenter};
	`};
    
    ${prop => prop.smaller && css`
		height: 100%;
		margin: auto auto;
		padding: 100px 0;
		@media ${DEVICE.mobileL} {
			height: auto;
			margin: 0 auto;
			padding: 0;
		}
	`};
	
	@media ${DEVICE.mobileL} {
		text-align: center;
	};
`;

export const ImageSide = styled.div`
	height: 100%;
	width: 50%;
	padding: 80px 30px 0;
	padding-top: 120px;
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: left;
	color: white;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-size: cover;
		background-position: bottom;
		background-image: url(${props => props.image});
		filter: brightness(80%);
	}

	@media ${DEVICE.tabletL} {
		padding-top: 80px;
	}	

	@media ${DEVICE.mobileL} {
		display: none;
	}
`;

export const ImageSideTextWrapper = styled.div`
	max-width: 400px;
	z-index: 1;
`;

export const ImageSideTitle = styled.h2`
	font-size: ${FONT_SIZES.large};
	line-height: 1.2;
`;

export const ImageSideDescription = styled.p`
	${BaseText};
	line-height: 1.5;
	color: white;
`;

export const FormWrapper = styled.div`
	display: flex;
	height: 100%;
	width: 50%;
	min-width: 600px;
	justify-content: center;
	align-items: flex-start;
	padding-top: 120px;

	@media ${DEVICE.mobileL} {
		width: 100%;
		min-width: auto;
		padding: 30px 15px;
	}
`;

export const Form = styled.form`
	min-width: 450px;

	@media ${DEVICE.mobileL} {
		min-width: auto;
	}
`;

export const FormLink = styled(Link)`
	${baseLink};
`;

export const FormButton = styled(PrimaryButton)`
	padding: 10px 40px; 
`;

export const LinkWrapper = styled(FlexRow)`
	justify-content: space-between;
	margin-bottom: 50px;

	@media ${DEVICE.mobileL} {
		flex-direction: column-reverse;
		height: 50px;
	}
`;

export const SendAgainWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 80%;
	flex-direction: column;
`;
