import React, { Fragment, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ReactTooltip from 'react-tooltip';

// Actions
import {
	setNewSessionsForAddon,
	validateAddons,
	toggleBillingItemSelect,
	toggleBillingItemsSelectAll,
	restoreSelectedItems,
	deleteSelectedItems,
	restoreBillingItem,
} from '../../../data/billing/BillingActions';
import { toggleBillingValidationModal } from '../../../data/ui/UIActions';

// Atoms
import {
	BaseVerticalDivider, MediumDivider,
	MediumVerticalDivider,
	BaseDivider,
	SmallVerticalDivider,
	VerticalLine,
} from '../../Atoms/Divider';
import {
	LeftWrapper, MainContainer, RightWrapper, ScrollWrapper,
} from './Atoms';
import { FlexRow } from '../../Atoms/Flex';
import { LeftControls } from '../Table/Atoms/TableAtoms';
import { GrayControlIcon } from '../../Atoms/ControlIcons';
import { NoContentInfo } from '../../Atoms/Text';
// Molecules
import CheckBoxInput from '../../Molecules/Input/CheckBox/CheckBox';
import Loader from '../../Molecules/Loader';

// Organisms
import { BillingItem } from './BillingItem';
import BillingOverview from './BillingOverview';
import Scrollbar from '../Scrollbar';

class Billing extends PureComponent {
	handlePayClick = () => {
		const {
			actions,
		} = this.props;
		actions.validateAddons();
	};

	render() {
		const {
			generalPrices,
			activeAddons,
			payNow,
			payNextMonth,
			actions,
			successfulSubscription,
			subscriptionError,
			chargebeeUrl,
			exchangeNames,
			detailedPayments,
		} = this.props;

		if (successfulSubscription) {
			return <Redirect to="/app/subscription/subscription-info?message=Success" />;
		}

		if (subscriptionError.length > 0) {
			return <Redirect to={`/app/subscription/subscription-info?message=${subscriptionError}`} />;
		}

		if (chargebeeUrl.length > 0) {
			window.location.replace(chargebeeUrl);
		}

		const newPaymentDetails = detailedPayments?.reverse();
		const activeItems = activeAddons.get('data')?.filter(item => item.get('active') === true);
		const inactiveItems = activeAddons.get('data')?.filter(item => item.get('active') === false);
		return (
			<MainContainer>
				{!activeAddons.get('loading') && activeAddons.get('data').size === 0 && (
					<NoContentInfo>
						You don't have any active subscriptions
					</NoContentInfo>
				)}
				{activeAddons.get('loading') && (
					<Loader loading transparent />
				)}
				{!activeAddons.get('loading') && activeAddons.get('data').size > 0 && (
					<>
						<LeftWrapper className="subscription-left-section">
							<LeftControls>
								<MediumVerticalDivider />
								<SmallVerticalDivider />
								<CheckBoxInput
									value={activeAddons.get('allItemsSelected')}
									onChange={() => {
										actions.toggleBillingItemsSelectAll();
									}}
								/>
								<BaseVerticalDivider />
								<VerticalLine />
								<MediumVerticalDivider />
								<FlexRow className="subscription-restore-delete-buttons">
									<GrayControlIcon
										name="trashCan"
										onClick={() => {
											actions.deleteSelectedItems();
										}}
										data-tip="Delete selected subscriptions"
									/>
									<MediumVerticalDivider />
									<GrayControlIcon
										name="restore"
										onClick={() => {
											actions.restoreSelectedItems();
										}}
										data-tip="Restore selected subscriptions"
									/>
								</FlexRow>
								<ReactTooltip />
							</LeftControls>
							<BaseDivider />
							<ScrollWrapper>
								<Scrollbar>
									{activeItems?.map((item, index) => {
										const type = item.get('type');
										const typeName = exchangeNames.get(type);
										let currentPaymentDetailsItem;
										if (item.get('userAddonExchangeId')) {
											currentPaymentDetailsItem = newPaymentDetails?.find(el => el.get('userAddonExchangeId') === item.get('userAddonExchangeId'));
										} else {
											currentPaymentDetailsItem = newPaymentDetails?.get(index);
										}

										return (
											<Fragment key={`billing-item-${item.get('id')}-${index}`}>
												<BillingItem
													name={item.get('exchangeName')}
													active={item.get('active')}
													type={typeName}
													exchangePrice={generalPrices.getIn(['data', type, 'exchangePrice'])}
													sessionPrice={generalPrices.getIn(['data', type, 'sessionPrice'])}
													sessions={item.get('sessions')}
													userAddonExchangeId={item.get('userAddonExchangeId')}
													newSessions={item.get('newSessions')}
													onItemCounterChange={(counter) => {
														actions.setNewSessionsForAddon({
															itemId: item.get('id'),
															sessions: counter,
														});
													}}
													selected={item.get('selected')}
													onItemSelectChange={() => {
														actions.toggleBillingItemSelect(item.get('id'));
													}}
													currentPaymentDetails={currentPaymentDetailsItem}
												/>
												<BaseDivider />
											</Fragment>
										);
									})}
									{inactiveItems?.map((item, index) => {
										const type = item.get('type');
										const typeName = exchangeNames.get(type);
										return (
											<Fragment key={`billing-item-${item.get('id')}-${index}`}>
												<BillingItem
													name={item.get('exchangeName')}
													active={item.get('active')}
													type={typeName}
													selected={item.get('selected')}
													sessions={item.get('sessions')}
													newSessions={item.get('newSessions')}
													userAddonExchangeId={item.get('userAddonExchangeId')}
													onItemCounterChange={(counter) => {
														actions.setNewSessionsForAddon({
															itemId: item.get('id'),
															sessions: counter,
														});
													}}
													onItemSelectChange={() => {
														actions.toggleBillingItemSelect(item.get('id'));
													}}
													onRestoreItem={() => {
														actions.restoreBillingItem(item.get('id'));
													}}
												/>
												<BaseDivider />
											</Fragment>
										);
									})}
								</Scrollbar>
							</ScrollWrapper>
						</LeftWrapper>
						<RightWrapper className="subscription-right-section">
							<MediumDivider />
							<BillingOverview
								activeAddons={activeAddons}
								generalPrices={generalPrices}
								exchangeNames={exchangeNames}
								payNow={payNow}
								payNextMonth={payNextMonth}
								onPayClick={this.handlePayClick}
								detailedPayments={detailedPayments}
							/>
						</RightWrapper>
					</>
				)}
			</MainContainer>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		setNewSessionsForAddon,
		validateAddons,
		toggleBillingValidationModal,
		toggleBillingItemSelect,
		toggleBillingItemsSelectAll,
		restoreSelectedItems,
		deleteSelectedItems,
		restoreBillingItem,
	}, dispatch),
});

function mapStateToProps(state) {
	return {
		generalPrices: state.getIn(['billing', 'generalPrices']),
		activeAddons: state.getIn(['billing', 'activeAddons']),
		payNow: state.getIn(['billing', 'calculatedPrices', 'payNow']),
		payNextMonth: state.getIn(['billing', 'calculatedPrices', 'payNextMonth']),
		successfulSubscription: state.getIn(['billing', 'successfulSubscription']),
		subscriptionError: state.getIn(['billing', 'subscriptionError']),
		chargebeeUrl: state.getIn(['billing', 'chargebeeUrl']),
		exchangeNames: state.getIn(['exchange', 'exchangeNames']),
		detailedPayments: state.getIn(['billing', 'calculatedPrices', 'detailedPayments']),
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(Billing);
