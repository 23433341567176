import { fromJS } from 'immutable';
import EVENT from '../../lib/constants/Websockets';

const initialState = fromJS({
	behavior: null,
});

export default function BehaviorReduces(state = initialState, action) {
	switch (action.type) {
	case EVENT.ExchangeBehaviorChangeReply: {
		const { data } = action.payload;
		return state.set('behavior', data);
	}

	case EVENT.ExchangeBehaviorStatusReply: {
		const { data } = action.payload;
		return state.set('behavior', data);
	}
	default: {
		return state;
	}
	}
}
