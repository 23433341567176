import { fromJS } from 'immutable';
import MACHINE_INSTANCE_TYPES from './MachineInstanceTypes';

const initialState = fromJS({
	instance: {
		status: null,
		ipAddress: '',
		domain: '',
		createdData: null,
		updatedDate: null,
		location: '',
		ram: '',
		processor: '',
	},
	instanceStatuses: {},
	cloudLocations: {
		data: [],
		loading: false,
		error: null,
	},
	droplets: {
		data: [],
		loading: false,
		error: null,
	},
});

export default function MachineInstanceReducer(state = initialState, action) {
	switch (action.type) {
	case MACHINE_INSTANCE_TYPES.FETCH_CLOUD_LOCATIONS_SUCCESS: {
		return state.setIn(['cloudLocations', 'data'], fromJS(action.payload));
	}
	case MACHINE_INSTANCE_TYPES.FETCH_DROPLETS_SUCCESS: {
		return state.setIn(['droplets', 'data'], fromJS(action.payload));
	}
	default: {
		return state;
	}
	}
}
