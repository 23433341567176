import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Atoms
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { BaseDivider, SmallVerticalDivider } from '../../Atoms/Divider';
import { PrimaryButton } from '../../Atoms/Button';
// Molecules
import MarketStatus from '../../Molecules/MarketStatus';
import ViewAsMemberBox from '../../Molecules/ViewAsMemberBox';
// Constants
import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';

const OrderBlotterHeader = (props) => {
	const {
		onEnterOrderClick,
		ipAddress,
		exchangeStatus,
		vmStatus,
	} = props;


	return (
		<FlexColumn>
			<BaseDivider />
			<MarketStatus
				ipAddress={ipAddress}
				vmStatus={vmStatus}
				exchangeStatus={exchangeStatus}
			/>
			<BaseDivider />
			<FlexRow>
				<ViewAsMemberBox className="view-as-member-order-blotter"/>
				<SmallVerticalDivider />
				<SmallVerticalDivider />
				<PrimaryButton onClick={onEnterOrderClick} className="order-blotter-enter-order">
					Enter Order
				</PrimaryButton>
			</FlexRow>
		</FlexColumn>
	);
};
OrderBlotterHeader.defaultProps = {
	exchangeStatus: EXCHANGE_STATUS.NA,
};
OrderBlotterHeader.propTypes = {
	exchangeStatus: PropTypes.string,
	vmStatus: PropTypes.string.isRequired,
	ipAddress: PropTypes.string.isRequired,
	onEnterOrderClick: PropTypes.func.isRequired,
};

export default memo(OrderBlotterHeader);
