import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Circle } from 'rc-progress';
import { COLORS, FONT_SIZES } from '../../lib/constants/Style';

const Wrapper = styled.div`
	width: 100px;
	height: 100px;
	position: relative;
`;

const Overlay = styled.div`
	position: absolute;
	width: 80%;
	height: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background: ${COLORS.lightestYellow};
`;

const Percentage = styled.div`
	font-size: ${FONT_SIZES.medium};
	font-weight: 500;
	color: ${COLORS.yellow};
`;

class ProgressCircle extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			percent: 0,
		};
	}

	componentDidMount() {
		this.increase();
	}

	componentDidUpdate(prevProps) {
		const prev = prevProps.limit || 0;
		if (prev < this.props.limit) {
			this.increase();
		}
		if (prev > this.props.limit) {
			this.decrease();
		}
	}

	decrease = () => {
		const { percent } = this.state;
		const { limit } = this.props;
		const newPercent = percent - 1;
		if (newPercent < limit) {
			clearTimeout(this.tm);
			return;
		}
		this.setState({ percent: newPercent });
		this.tm = setTimeout(this.decrease, 5);
	};

	increase = () => {
		const { percent } = this.state;
		const { limit } = this.props;
		const newPercent = percent + 1;
		if (newPercent > limit) {
			clearTimeout(this.tm);
			return;
		}
		this.setState({ percent: newPercent });
		this.tm = setTimeout(this.increase, 5);
	};

	render() {
		const { percent } = this.state;
		return (
			<Wrapper>
				<Circle strokeWidth={4} percent={percent} strokeColor={COLORS.yellow} trailWidth={2} trailColor={COLORS.lightestYellow} />
				<Overlay>
					<Percentage>
						{percent}
%
					</Percentage>
				</Overlay>
			</Wrapper>
		);
	}
}

ProgressCircle.defaultProps = {
	limit: 0,
};

ProgressCircle.propTypes = {
	limit: PropTypes.number,
};

export default ProgressCircle;
