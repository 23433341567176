import React from 'react';
import ReactDOM from 'react-dom';

// Base css
import 'react-notifications/lib/notifications.css';
import './assets/style/base.css';
import './assets/style/notification.css';

// Main app
import ETPMarkets from './containers/Main';

ReactDOM.render(<ETPMarkets />, document.getElementById('root'));
