export default {
	FETCH_INSTANCE_INFO_FAIL: 'FETCH_INSTANCE_INFO_FAIL',
	FETCH_INSTANCE_INFO_SUCCESS: 'FETCH_INSTANCE_INFO_SUCCESS',
	FETCH_INSTANCE_INFO_START: 'FETCH_INSTANCE_INFO_START',
	FETCH_INSTANCE_INFO: 'FETCH_INSTANCE_INFO',
	FETCH_DROPLETS_FAIL: 'FETCH_DROPLETS_FAIL',
	FETCH_DROPLETS_SUCCESS: 'FETCH_DROPLETS_SUCCESS',
	FETCH_DROPLETS_START: 'FETCH_DROPLETS_START',
	FETCH_DROPLETS: 'FETCH_DROPLETS',
	FETCH_CLOUD_LOCATIONS_FAIL: 'FETCH_CLOUD_LOCATIONS_FAIL',
	FETCH_CLOUD_LOCATIONS_SUCCESS: 'FETCH_CLOUD_LOCATIONS_SUCCESS',
	FETCH_CLOUD_LOCATIONS: 'FETCH_CLOUD_LOCATIONS',
};
