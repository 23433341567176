import {
	LOCAL_STORAGE_PREFIX,
} from './constants/General';

/**
 * Set item
 * @param {string} key Key of the item
 * @param {string|boolean} value Accept only string as a value. Json must be stringified before added
 * @returns {boolean}
 */
export function setItem(key, value) {
	try {
		localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${key}`, value);
		return true;
	} catch (error) {
		return false;
	}
}

/**
 * Get item
 * @param {string} key Key of the item
 * @returns {*}
 */
export function getItem(key) {
	try {
		const item = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}`);
		if (item === 'true') {
			return true;
		}
		if (item === 'false') {
			return false;
		}
		if (!item) {
			return null;
		}
		return localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}`);
	} catch (error) {
		return false;
	}
}

/**
 * Remove an item from local storage
 * @param {string} key Key of the item
 */
export function removeItem(key) {
	try {
		localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}${key}`);
	} catch (error) {}
}
