import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { EXCHANGE_STATUS, OPERATION } from '../../../lib/constants/Exchange';
import {
	addOperation, cancelOperation, loadMdrOrderBooks, modifyOperation,
} from '../../../lib/orderBook';
import { uploadCsvFile, uploadXlFile, showError } from '../../../lib/helpers';
import { ImportButton, PrimaryButton } from '../../Atoms/Button';
import { MediumVerticalDivider } from '../../Atoms/Divider';
import { FlexRow, FlexRowBase } from '../../Atoms/Flex';
import MarketDataReplyInfo from './MarketDataReplyInfo';
import MarketDataReplyProgress from './MarketDataReplyProgress';
import EVENT from '../../../lib/constants/Websockets';

const ControlButtons = styled.div`
	position: absolute;
	${FlexRowBase};
	right: 20px;
	top: 115px;
`;

const PrimaryButtonMinWidth = styled(PrimaryButton)`
	min-width: 85px;
`;

function MarketDataPlayer(props) {
	const {
		fix,
		client,
		sessionId,
		exchangeStatus,
		handleExportClick,
		startAgain,
		count,
		confirmations = 0,
	} = props;
	// const WebsocketContext = useContext(WebSocketConnectionContext);
	const [uploadedFile, setUploadedFile] = useState('');
	const [fileName, setFileName] = useState('N/A');
	const [orderBookLength, setOrderBookLength] = useState(0);

	const [currentOrderIndex, setCurrentOrderIndex] = useState(0);

	const [started, setStarted] = useState(false);
	const [paused, setPaused] = useState(true);
	const [stopped, setStopped] = useState(true);
	const [sentEvents, setSentEvents] = useState(0);

	useEffect(() => {
		let timeout = null;

		setOrderBookLength(uploadedFile.length);
		if (!paused && uploadedFile && uploadedFile[currentOrderIndex]) {
			if (uploadedFile[currentOrderIndex].OPERATION === OPERATION.ADD) {
				addOperation(client, fix, sessionId, uploadedFile[currentOrderIndex].VIEW_CODE, uploadedFile[currentOrderIndex]);
				setSentEvents(e => e + 1);
			} else if (uploadedFile[currentOrderIndex].OPERATION === OPERATION.MODIFY) {
				modifyOperation(client, fix, sessionId, uploadedFile[currentOrderIndex].VIEW_CODE, uploadedFile[currentOrderIndex]);
				setSentEvents(e => e + 1);
			} else if (uploadedFile[currentOrderIndex].OPERATION === OPERATION.CANCEL) {
				cancelOperation(client, fix, sessionId, uploadedFile[currentOrderIndex].VIEW_CODE, uploadedFile[currentOrderIndex]);
				setSentEvents(e => e + 1);
			}
			if (uploadedFile[sentEvents]) {
				timeout = setTimeout(() => setCurrentOrderIndex(c => c + 1), uploadedFile[currentOrderIndex].TIME);
			}
		}
		return () => clearTimeout(timeout);
		// eslint-disable-next-line
	}, [currentOrderIndex, uploadedFile, paused, client, sessionId, fix]);

	const handleImport = (event) => {
		const { files } = event.target;
		const file = files[0];
		const extension = file.name.split('.').pop();
		if (extension === 'xlsx' || extension === 'xls') {
			uploadXlFile(file, (orderBook) => {
				try {
					loadMdrOrderBooks(orderBook, checkedOrderBook => setUploadedFile(checkedOrderBook));
					setFileName(file.name);
				} catch (error) {
					showError('File error', error.message);
				}
			});
		} else if (extension === 'csv') {
			uploadCsvFile(file, (orderBook) => {
				try {
					loadMdrOrderBooks(orderBook, checkedOrderBook => setUploadedFile(checkedOrderBook));
					setFileName(file.name);
				}	catch (error) {
					showError('File error', error.message);
				}
			});
		}
	};

	const handleStart = () => {
		setSentEvents(0);
		count(orderBookLength, [EVENT.OrderBookAddOrderReply,
			EVENT.OrderBookCancelOrderReply,
			EVENT.OrderBookAmendOrderReply,
			// TODO SEE WHAT ARE ALL REJECT EVENTS
			EVENT.OrderBookRequestRejectReply]);
		if (stopped) {
			startAgain();
			setCurrentOrderIndex(0);
		}
		setStarted(true);
		setPaused(false);
		setStopped(false);
	};
	const toggleReplay = () => {
		setPaused(p => !p);
	};
	const handleStopReplay = () => {
		setStarted(false);
		setStopped(true);
		setPaused(true);
	};

	useEffect(() => {
		if (orderBookLength === sentEvents) {
			handleStopReplay();
		}
		// eslint-disable-next-line
	}, [sentEvents, orderBookLength]);

	const canPause = uploadedFile !== '' && started && orderBookLength > currentOrderIndex;
	const canStart = uploadedFile !== '' && stopped;
	const canStop = uploadedFile !== '' && !stopped && started && orderBookLength > currentOrderIndex;
	const canExport = uploadedFile !== '' && stopped && currentOrderIndex > 0;
	const canImport = (uploadedFile === '' && exchangeStatus === EXCHANGE_STATUS.OPENED) || canExport;
	return (
		<>
			<ControlButtons>
				<FlexRow className="play-buttons">
					<PrimaryButton disabled={!canStart} onClick={handleStart}>
					Start
					</PrimaryButton>
					<MediumVerticalDivider />
					<PrimaryButtonMinWidth disabled={!canPause} onClick={toggleReplay}>
						{paused && canPause ? 'Resume' : 'Pause'}
					</PrimaryButtonMinWidth>
					<MediumVerticalDivider />
					<PrimaryButton disabled={!canStop} onClick={handleStopReplay}>
					Stop
					</PrimaryButton>
					<MediumVerticalDivider />
				</FlexRow>
				<PrimaryButton
					disabled={!canExport}
					onClick={handleExportClick}
					className="md-export-report"
				>
					Export Report
				</PrimaryButton>
				<MediumVerticalDivider />
				<PrimaryButton disabled={!canImport} className="md-replay-load-file-button">
					<ImportButton
						onChange={event => handleImport(event)}
						value=""
						type="file"
						accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					/>
					Load from file
				</PrimaryButton>
			</ControlButtons>
			<FlexRow>
				<MarketDataReplyInfo
					fileName={fileName}
					noEvent={orderBookLength}
				/>
				<MarketDataReplyProgress
					eventsSent={`${sentEvents}/${orderBookLength}`}
					confirmationsReceived={`${confirmations}/${orderBookLength}`}
					eventsPercentageSent={Math.round((sentEvents / orderBookLength) * 100)}
					eventsPercentageConfirmed={Math.round((confirmations / orderBookLength) * 100)}
				/>
			</FlexRow>
		</>
	);
}

MarketDataPlayer.propTypes = {
	fix: PropTypes.string.isRequired,
	client: PropTypes.shape({}).isRequired,
	sessionId: PropTypes.string.isRequired,
	exchangeStatus: PropTypes.oneOf(Object.values(EXCHANGE_STATUS)).isRequired,
	handleExportClick: PropTypes.func.isRequired,
	startAgain: PropTypes.func.isRequired,
};

export default MarketDataPlayer;
