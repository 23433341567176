import React, { PureComponent, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

// Atoms
import { ModalErrorMessage, ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import {
	MediumDivider,
	MediumVerticalDivider, BaseDivider,
} from '../../Atoms/Divider';
import { FullWidthFlexRow } from '../../Atoms/Wrapper';
// Molecules
import Modal from '../../Molecules/Modal';
import Input from '../../Molecules/Input/Input/Input';
// Actions
import { toggleAddOrderBookModal } from '../../../data/ui/UIActions';
import { addNewOrderBook, fetchOrderBooks } from '../../../data/orderBooks/OrderBooksActions';
import SelectBox from '../../Molecules/Input/SelectBox/SelectBox';

class AddOrderBookModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
		};
		this.name = createRef();
		this.defaultOpeningPrice = createRef();
		this.viewCode = createRef();
		this.bbgCode = createRef();
		this.isinCode = createRef();
		this.ricCode = createRef();
	}

	validateForm = () => {
		this.name.current.hasError();
		this.defaultOpeningPrice.current.hasError();
		this.isinCode.current.hasError();
		this.viewCode.current.hasError();
		if (this.name.current.hasError() || this.defaultOpeningPrice.current.hasError()
			|| this.isinCode.current.hasError() || this.viewCode.current.hasError()) {
			return false;
		}
		return true;
	};

	handleAddOrderBook = () => {
		const { match, actions } = this.props;
		if (this.validateForm()) {
			const data = {
				name: this.name.current?.getValue(),
				isinCode: this.isinCode.current?.getValue(),
				defaultOpeningPrice: this.defaultOpeningPrice.current?.getValue(),
				type: 'EQUITY',
				viewCode: this.viewCode.current?.getValue(),
				bbgCode: this.bbgCode.current?.getValue(),
				ricCode: this.ricCode.current?.getValue(),
			};
			const clientExchangeId = match.params.slug;
			actions.addNewOrderBook(clientExchangeId, data)
				.then((response) => {
					if (!response.data) {
						this.setState({ error: response.response.data.message });
					} else {
						actions.toggleAddOrderBookModal(false);
						actions.fetchOrderBooks(clientExchangeId);
					}
				})
				.catch((err) => {
					this.setState({ error: err?.response?.data?.message });
				});
		}
	};

	handleCloseModal = () => {
		const {
			actions,
		} = this.props;
		this.setState({ error: null });
		actions.toggleAddOrderBookModal(false);
	};

	render() {
		const {
			error,
		} = this.state;
		const {
			modalInfo,
			actions,
		} = this.props;

		if (modalInfo?.get('visible') !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={() => actions.toggleAddOrderBookModal(false)}
				size={MODAL_SIZES.WIDE}
			>
				<FlexColumn>
					<ModalTitle>
						Add Order Book
					</ModalTitle>
					{error && <ModalErrorMessage>{error}</ModalErrorMessage>}
					<MediumDivider />
					<FlexColumn>
						<Input
							placeholder="Name"
							type="header"
							name="name"
							ref={this.name}
							validators={[
								{
									validator: 'isLength',
									options: {
										min: 1,
									},
									errorResult: false,
									message: 'Field required',
								},
							]}
						/>
					</FlexColumn>
					<FullWidthFlexRow>
						<FlexColumn fullWidth>
							<SelectBox
								height={50}
								label="Instrument Type"
								options={['EQUITY']}
								selected="EQUITY"
								onClick={() => {}}
							/>
							<BaseDivider />
						</FlexColumn>
						<MediumVerticalDivider />
						<FlexColumn fullWidth>
							<Input
								placeholder="Default Opening Price"
								type="header"
								name="openingPrice"
								ref={this.defaultOpeningPrice}
								validators={[
									{
										validator: 'isLength',
										options: {
											min: 1,
										},
										errorResult: false,
										message: 'Field required',
									},
									{
										validator: 'isInt',
										errorResult: false,
										message: 'Field must be a number',
									},
								]}
							/>
						</FlexColumn>
					</FullWidthFlexRow>
					<FullWidthFlexRow>
						<FlexColumn fullWidth>
							<Input
								placeholder="View Code"
								type="header"
								name="viewCode"
								ref={this.viewCode}
								validators={[
									{
										validator: 'isLength',
										options: {
											min: 1,
										},
										errorResult: false,
										message: 'Field required',
									},
								]}
							/>
						</FlexColumn>
						<MediumVerticalDivider />
						<FlexColumn fullWidth>
							<Input
								placeholder="BBG Code (optional)"
								type="header"
								name="bbgCode"
								ref={this.bbgCode}
							/>
						</FlexColumn>
					</FullWidthFlexRow>
					<FullWidthFlexRow>
						<FlexColumn fullWidth>
							<Input
								placeholder="ISIN Code"
								type="header"
								name="isinCode"
								ref={this.isinCode}
								validators={[
									{
										validator: 'isLength',
										options: {
											min: 1,
										},
										errorResult: false,
										message: 'Field required',
									},
								]}
							/>
						</FlexColumn>
						<MediumVerticalDivider />
						<FlexColumn fullWidth>
							<Input
								placeholder="RIC Code (optional)"
								type="header"
								name="ricCode"
								ref={this.ricCode}
							/>
						</FlexColumn>
					</FullWidthFlexRow>
					<BaseDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={this.handleAddOrderBook}>
							Add
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={this.handleCloseModal}>
							Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}

AddOrderBookModal.propTypes = {
	addOrderBookError: PropTypes.shape({}),
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleAddOrderBookModal: PropTypes.func,
		addNewOrderBook: PropTypes.func,
		fetchOrderBooks: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
};
AddOrderBookModal.defaultProps = {
	addOrderBookError: null,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.ADD_ORDER_BOOK_MODAL]),
		addOrderBookError: state.getIn(['orderBooks', 'addOrderBookError']),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleAddOrderBookModal,
			addNewOrderBook,
			fetchOrderBooks,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddOrderBookModal));
