import styled, { css } from 'styled-components';

// Lib
import { COLORS } from '../../../lib/constants/Style';

export const ExchangeBehaviorWrapper = styled.div`
	background-color: white;
    height: 100%;
    width: 100%;
    padding: 30px;
    display: flex;
	overflow: auto;
`;

export const RightContent = styled.div`
    width: 100%;
    max-width: 700px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
	
	${props => (props.active ? null : 'opacity: 0.5')};
	${props => (props.active ? null : 'pointer-events: none;')};
`;

export const LeftContent = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
	overflow: auto;
`;

export const TextWrapper = styled.div`
    width: 80%;
    max-width: 600px;
	line-height: 1.5;
`;

export const OptionWrapper = styled.div`
	padding: 20px 20px 5px 20px;
	background-color: ${COLORS.lightestGrey};
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid ${COLORS.lightestGrey};

    &:first-child {
        border-top: 1px solid ${COLORS.lightestGrey};
    }
`;

export const ButtonWrapper = styled.div`
	display: flex;
`;

export const SettingsWrapper = styled.div`
	overflow: auto;
	margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
	width: 100%;
	${props => (props.active ? 'opacity: 1' : 'opacity: 0.5')};
	${props => !props.selectable && 'pointer-events:none'};
`;
export const InputManager = styled.div`
	width: 100%;
	${props => (props.active ? 'opacity: 1' : 'opacity: 0.5')};
	${props => !props.active && 'pointer-events:none'};
`;
export const selectedOptionCSS = css`
	background-color: ${COLORS.yellow};
	color: ${COLORS.black};
`;

export const SelectOption = styled.p`
	padding: 15px 0;
	width: 100%;
	cursor: pointer;
	background-color: white;
	margin: 0 5px 10px 5px;
	text-align: center;
	border-radius: 4px;
	color: ${COLORS.grey};

	${props => props.selected && selectedOptionCSS};

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
`;

export const RejectOptionWrapper = styled.div`
	display: flex;
	margin-bottom: 15px;
	align-items: center;
`;
