import React from 'react';
import PropTypes from 'prop-types';
import { Label, InfoWrapper } from './Atoms';
import { FlexRow } from '../../Atoms/Flex';
import { BaseDivider, SmallVerticalDivider } from '../../Atoms/Divider';

const MarketDataReplyInfo = (props) => {
	const {
		fileName,
		noEvent,
	} = props;
	return (
		<InfoWrapper className="md-replay-info-section">
			<FlexRow>
				<Label>Source file:</Label>
				<SmallVerticalDivider />
				<p>{fileName}</p>
			</FlexRow>
			<BaseDivider />
			<FlexRow>
				<Label>Number of events:</Label>
				<SmallVerticalDivider />
				<p>{noEvent}</p>
			</FlexRow>
		</InfoWrapper>
	);
};
MarketDataReplyInfo.propTypes = {
	fileName: PropTypes.string,
	noEvent: PropTypes.number,
};
MarketDataReplyInfo.defaultProps = {
	fileName: 'N/A',
	noEvent: 'N/A',
};
export default MarketDataReplyInfo;
