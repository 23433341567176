import styled, { css } from 'styled-components';

// Images
import topSection from '../../../assets/images/MarketingSite/homePage/landingBackground.png';

// Lib
import { COLORS } from '../../../lib/constants/Style';
import { DEVICE } from '../../../lib/constants/Device';

// Atoms
import { FlexRow } from '../Flex';
import { BaseBlockStyle } from '../../Css/Css';

const SectionBackgroundImage = css`
	background-size: cover;
	background-position: bottom;
	background-image: url(${topSection});
`;

export const SectionWrapper = styled.div`
	padding: 150px 180px 0;
	${props => (props.backgroundImage ? SectionBackgroundImage : '')}

	@media ${DEVICE.laptopM} {
		padding: 100px 25px 0;
	};

	@media ${DEVICE.mobileL} {
		font-size: 14px;
	};

	@media ${DEVICE.mobileM} {
		padding: 50px 15px 0;
	};
	
	${props => props.reducedPadding && css`
		padding: 80px 180px 0;
		@media ${DEVICE.laptopM} {
			padding: 60px 25px 0;
		};
	`};
	${props => props.fullpadding && css`
		padding: 150px 180px;
		@media ${DEVICE.laptopM} {
		};
	`};
`;

const SectionCSS = css`
	width: 48%;
	display: flex;

	@media ${DEVICE.tablet} {
		width: 100%;
	}
`;

export const LeftSection = styled.div`	
	${SectionCSS};
	align-items: end;

	@media ${DEVICE.tablet} {
		margin-bottom: 30px;
	}
`;

export const RightSection = styled.div`
	${SectionCSS};
	flex-direction: column;
	justify-content: center;

	@media ${DEVICE.tabletL} {
		justify-content: end;
	}
`;

export const TwoSideSectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: ${props => (props.flip ? 'row-reverse' : 'row')};

	@media ${DEVICE.tablet} {
		flex-direction: column;
	}
`;

export const CardItemWrapper = styled.div`
	display: grid;
	grid-column-gap: 2%;
	grid-template-columns: 32% 32% 32%;

	@media ${DEVICE.tablet} {
		grid-row-gap: 30px;
		grid-template-columns: 100%;
	}

	@media ${DEVICE.mobileM} {
		display: none;
	}
`;

export const CardImageWrapper = styled.div`
	height: 133px;
	width: 133px;
	min-width: 133px;
	${BaseBlockStyle};
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 45px;

	@media ${DEVICE.mobileM} {
		margin: 0;
	}
`;

export const CardWrapper = styled.div`
	padding: 40px;
	background-color: ${COLORS.lightestGrey};
	width: 100%;
	height: 100%;

	@media ${DEVICE.tablet} {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}

	@media ${DEVICE.mobileM} {
		flex-direction: column;
		align-items: center;
		padding: 40px 25px;
	}
`;

export const AboutTextWrapper = styled.div`
	width: 90%;
	${props => (props.flip ? 'margin-right: auto;' : 'margin-left: auto;')}

	@media ${DEVICE.tabletL} {
		width: 100%;
	}
`;

const GreyBackgroundWrapperCss = css`
    background-color: ${COLORS.lightestGrey};
	display: flex;
    justify-content: space-between;
    padding: 120px 180px;

	@media ${DEVICE.desktop} {
		padding: 25px;
	};
	@media ${DEVICE.mobileM} {
		padding: 0 0 1px 0;
	};
`;

const ContactWrapperHeightCss = css`
	height: calc(100vh - 130px);

	@media ${DEVICE.desktop} {
		flex-direction: column;
		align-items: center;
		height: auto;
	};
`;

export const ContactMaxWidthWrapper = styled.div`
	display: flex;
	align-items: flex-start;
    justify-content: space-between;
	max-width: 1800px;
	width: 100%;
	margin: 0 auto;
	@media ${DEVICE.desktop} {
		flex-direction: column;
		align-items: center;
		height: auto;
	};
`;

const WrapperHeightCss = css`
	min-height: calc(100vh - 130px);
`;

export const ContactGreyBackgroundWrapper = styled.div`
	${GreyBackgroundWrapperCss};
	${ContactWrapperHeightCss};
`;

export const GreyBackgroundWrapper = styled.div`
	${GreyBackgroundWrapperCss};
	${WrapperHeightCss};
`;

export const NoMobileWrapper = styled.div`
	@media ${DEVICE.mobileM} {
		display: none;
	}	
`;

export const OnlyMobileWrapper = styled.div`
	display: none;
	@media ${DEVICE.mobileM} {
		display: block;
	}
`;

export const HomepageTitleWrapper = styled(FlexRow)`
	align-items: flex-end;
	@media ${DEVICE.mobileL} {
		flex-direction: column;
		align-items: flex-start;
	}
`;
