import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Lib
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';
import { getItem } from '../../../lib/localStorage';

import { toggleSessionExpireModal } from '../../../data/ui/UIActions';

// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn } from '../../Atoms/Flex';
import { MediumDivider, BaseDivider } from '../../Atoms/Divider';
import { PrimaryButton } from '../../Atoms/Button';

// Molecules
import Modal from '../../Molecules/Modal';

// Context
import { useAuth } from '../../Context/auth';


const SessionExpireModal = (props) => {
	const { logOut } = useAuth();
	const { modalInfo, actions } = props;

	const onConfirm = () => {
		actions.toggleSessionExpireModal(false);
		logOut();
	};

	if (modalInfo?.get('visible') !== true) {
		return null;
	}

	return (
		<Modal
			showModal={modalInfo?.get('visible') && getItem('loginInfo')}
			closeModal={() => {}}
			size={MODAL_SIZES.MEDIUM}
			hasCloseIcon={false}
		>
			<FlexColumn>
				<ModalTitle>
					Session Expired
				</ModalTitle>
				<MediumDivider />
				<p>Your session has timed out. Press OK to go back to the login page.</p>
				<MediumDivider />
				<PrimaryButton onClick={onConfirm}>
					Ok
				</PrimaryButton>
				<BaseDivider />
			</FlexColumn>
		</Modal>
	);
};

SessionExpireModal.propTypes = {
	modalInfo: PropTypes.shape({
		visible: PropTypes.bool,
	}).isRequired,
	actions: PropTypes.shape({
		toggleSessionExpireModal: PropTypes.func,
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.SESSION_EXPIRE_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleSessionExpireModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionExpireModal));
