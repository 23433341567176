import styled, { css } from 'styled-components';
import { COLORS } from '../../../../lib/constants/Style';
import { TextBaseSmall } from '../../../Css/Css';

const WhiteBackgroundCSS = css`
      background-color: ${COLORS.white};
      color: ${COLORS.gray};
      &::placeholder {
        color: ${COLORS.gray};
    };
    
`;
export const SearchWrapper = styled.div`
    background-color: ${COLORS.lightestGrey};
    color: ${COLORS.gray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    height: 36px;
     ${props => props.white && `border: 1px solid ${COLORS.lighterGrey}`};
    ${props => props.white && WhiteBackgroundCSS};
`;

export const SearchInput = styled.input`
    background-color: ${COLORS.lightestGrey};
    color: black;
    width: 100%;
    outline: none;
    border: none;
    height: 100%;
    padding: 10px 5px 10px 20px;
    ${TextBaseSmall};
    border-radius: 4px;
    &::placeholder {
        color: #8c959d;
    };
    ${props => props.white && WhiteBackgroundCSS};
    
`;

export const SearchButton = styled.div`
    cursor: pointer;
    padding: 10px 10px 10px 5px;
    color: #b5bcc2;

    &:hover {
        color: #6d777b;
    }
`;
