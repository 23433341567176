import React, { useEffect } from 'react';
import { data } from '../../data/placeholderData/treeData';

export default function useSelect(dataToRender, setDataToRender) {
	const [allSelected, setAllSelected] = React.useState(false);
	const selectRowCallback = React.useCallback((checked, index) => {
		const newData = [...dataToRender];
		newData[index].metaData.selected = checked;
		if (newData.some(row => row.metaData.selected === false)) {
			setAllSelected(false);
		} else {
			setAllSelected(true);
		}
		setDataToRender(newData);
	}, [dataToRender, setAllSelected, setDataToRender]);


	const handleSelectAll = React.useCallback((checked) => {
		const newData = [...dataToRender];
		for (let i = 0; i < newData.length; i += 1) {
			newData[i].metaData.selected = checked;
		}
		setAllSelected(checked);
		setDataToRender(newData);
	}, [dataToRender, setAllSelected, setDataToRender]);

	// This is run in case user changes data after pressing select all checkbox
	useEffect(() => {
		if (dataToRender?.length === 0) {
			setAllSelected(false);
			return;
		}
		if (dataToRender?.some(row => row?.metaData?.selected === false)) {
			setAllSelected(false);
		} else {
			setAllSelected(true);
		}
	}, [dataToRender]);
	return { selectRowCallback, handleSelectAll, allSelected };
}
