import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

// Actions
import { toggleChangeBehaviorModal } from '../../../data/ui/UIActions';

// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import {
	MediumDivider,
	MediumVerticalDivider, SmallDivider,
} from '../../Atoms/Divider';

// Molecules
import Modal from '../../Molecules/Modal';

class ChangeBehaviorModal extends PureComponent {
	render() {
		const {
			modalInfo,
			actions,
			onConfirmClick,
			onCancelClick,
		} = this.props;

		const modalInfoJS = modalInfo?.toJS();

		if (modalInfoJS.visible !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={() => actions.toggleChangeBehaviorModal(false)}
				size={MODAL_SIZES.MEDIUM}
			>
				<FlexColumn>
					<ModalTitle>
					Clear Order Books
					</ModalTitle>
					<MediumDivider />
					<SmallDivider />
                    Before setting a custom exchange behavior, all orders from all order books need to be cleared. 
                    <br />
                    Would you like to proceed with clearing the order books and setting the custom exchange behavior?
					<MediumDivider />
					<SmallDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={onConfirmClick}>
						Confirm
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={onCancelClick}>
						Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}

ChangeBehaviorModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleChangeBehaviorModal: PropTypes.func,
	}).isRequired,
	onConfirmClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.CHANGE_BEHAVIOR_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleChangeBehaviorModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeBehaviorModal));
