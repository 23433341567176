import React from 'react';
import PropTypes from 'prop-types';

// Context
import styled from 'styled-components';

// Atoms
import { FlexColumn, FlexColumnBase } from '../../Atoms/Flex';

// Modals
import TreeRow from './TreeRow';


// Organisms
import Scrollbar from '../Scrollbar';
import TreeTableHeader from './TreeTableHeader';


const TreeTableWrapper = styled.div`
	${FlexColumnBase};
	overflow: hidden;
`;

const TreeTable = ({
	data, openAllStatus, changeOpenStatus, searchValue, header, openFieldModal,
}) => (
	<>
		<TreeTableWrapper>
			<Scrollbar>
				<FlexColumn style={{ position: 'relative' }}>
					<TreeTableHeader header={header} />
					<FlexColumn style={{ overflow: 'hidden' }}>
						{data?.map(row => (
							<TreeRow
								key={`${row.id}${row.rowData}`}
								rowData={row.rowData}
								subRowData={row.children}
								data={row}
								openAllStatus={openAllStatus}
								changeOpenStatus={changeOpenStatus}
								searchValue={searchValue}
								openFieldModal={openFieldModal}
							/>
						))}
					</FlexColumn>
				</FlexColumn>
			</Scrollbar>
		</TreeTableWrapper>
	</>
);

TreeTable.defaultProps = {
	searchValue: '',
	openAllStatus: false,
	changeOpenStatus: false,
	openFieldModal: () => {},
	header: [],
};

TreeTable.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	openAllStatus: PropTypes.bool,
	changeOpenStatus: PropTypes.bool,
	searchValue: PropTypes.string,
	openFieldModal: PropTypes.func,
	header: PropTypes.arrayOf(PropTypes.string),
};

export default TreeTable;
