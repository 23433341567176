import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// Images
import logo from '../../../assets/images/logo.png';
// Constants
import { COLORS, FONT_SIZES } from '../../../lib/constants/Style';
// Atoms
import { FlexRowBase } from '../../Atoms/Flex';

const Wrapper = styled(Link)`
	${FlexRowBase};
	width: 100%;
	justify-content: flex-start;
	white-space: nowrap;
	padding-left: 15px;
	background: ${COLORS.darkestGrey};
	height: 70px;
`;

const LogoImageWrapper = styled.div`
	width: 40px;
  	height: 33px;
  	object-fit: contain;
  	margin-right: 15px;
  	img {
  		display: block;
  		height: 100%;
  	}
`;

const LogoText = styled.span`
	font-size: ${FONT_SIZES.big};
	color: ${COLORS.yellow};
`;

const Logo = (props) => {
	const { to, href, public: publicLogo } = props;
	return (
		<Wrapper to={to} href={href}>
			<LogoImageWrapper>
				<img src={logo} alt="ETP logo" />
			</LogoImageWrapper>
			{!publicLogo && (
				<LogoText>
					ETP MARKETS
				</LogoText>
			)}
		</Wrapper>
	);
};

export default Logo;
