import React from 'react';

// Images
import greyBackground from '../../../assets/images/MarketingSite/homePage/greyBackground.png';
import yourNeed from '../../../assets/images/MarketingSite/aboutPage/messageTraffic.png';
import logo from '../../../assets/images/logo.png';
import city from '../../../assets/images/MarketingSite/aboutPage/ourBusiness.png';
import client from '../../../assets/images/MarketingSite/aboutPage/clients.png';

// Atoms
import { BaseExternalLink, PrimaryButtonLink } from '../../../components/Atoms/Link';
import {
	LargeDivider,
	TabletScreenDivider,
	BigDivider,
	SmallDivider
} from '../../../components/Atoms/Divider';
import {
	AboutTextWrapper, SectionWrapper, NoMobileWrapper, OnlyMobileWrapper,
} from '../../../components/Atoms/WebSite/Wrappers';
import { Description, Title, BiggerTitle, LargeExternalLink } from '../../../components/Atoms/WebSite/Text';
import { TwoSideImage } from '../../../components/Atoms/WebSite/Images';

// Molecules
import Card from '../../../components/Molecules/WebSite/Card';
import TwoSideSection from '../../../components/Molecules/WebSite/TwoSideSection';
import Slider from '../../../components/Molecules/WebSite/Slider';
import { FooterLink } from '../../../components/Organisms/Layout/Atoms';

const About = () => (
	<>
		<SectionWrapper backgroundImage fullpadding>
			<BiggerTitle>One Platform - Many Protocols</BiggerTitle>
			<LargeDivider />
			<Description>
				A fast and effective solution to test trading algorithms as well as exchange connectivity. <br />
                Remaining compliant with MiFID's demanding regulatory technical standards is challenging
                and expensive. We aim to help you deliver it efficiently and effectively.
			</Description>
			<TabletScreenDivider />
			<LargeDivider />
			<LargeDivider />
		</SectionWrapper>
		<TwoSideSection
			leftSection={(
				<Title>
					Explore our new ETP Markets platform,
					<br />
					for Global Exchanges & Markets Simulation.
				</Title>
			)}
			rightSection={(
				<Description>
					{`Esprow ETP is a suite of technologies to automate testing of all your financial applications across asset
					classes and protocols. They all share common concepts and formats, so you only need to deploy one technology to target
					many of your financial testing requirements.`}
				</Description>
			)}
		/>
		<NoMobileWrapper>
			<TwoSideSection
				leftSection={(
					<Card
						image={logo}
						title="About Esprow"
						description={`ESPROW is a niche specialist in financial application testing. Discover how our 
						technologies deliver efficiencies to financial institutions across the globe.`}
					/>
				)}
				rightSection={(
					<Card
						image={yourNeed}
						title="Your Needs"
						description={`Looking to automate system testing and achieve compliance? Look no further.
						Esprow ETP delivers end-to-end testing and compliance across financial applications.`}
					/>
				)}
			/>
		</NoMobileWrapper>
		<OnlyMobileWrapper>
			<LargeDivider />
			<Slider>
				<Card
					image={logo}
					title="About Esprow"
					description={`ESPROW is a niche specialist in financial application testing. Discover how our 
					technologies deliver efficiencies to financial institutions across the globe.`}
				/>
				<Card
					image={yourNeed}
					title="Your Needs"
					description={`Looking to automate system testing and achieve compliance? Look no further.
					Esprow ETP delivers end-to-end testing and compliance across financial applications.`}
				/>
			</Slider>
		</OnlyMobileWrapper>

		<TwoSideSection
			leftSection={<TwoSideImage src={city} />}
			rightSection={(
				<AboutTextWrapper>
					<Title>Our Business</Title>
					<BigDivider />
					<Description>
						Esprow is a leading global provider of testing and compliance automation solutions for the financial industry
					</Description>
					<SmallDivider double />
					<Description>
						{`The Esprow Enterprise Testing Platform (Esprow ETP) provides an integrated suite of applications to automate 
						testing of multi-protocol financial systems, on-boarding and certification of counterparties and native exchange and broker simulation.`}
					</Description>
				</AboutTextWrapper>
			)}
		/>
		<TwoSideSection
			flip
			leftSection={<TwoSideImage src={client} />}
			rightSection={(
				<AboutTextWrapper flip>
					<Title>Clients</Title>
					<BigDivider />
					<Description>
						{`Our clients include Tier-1 banks, brokers, exchanges, and MTFs in all global financial centers, including 
					New York, London, Hong Kong, Tokyo, and Singapore. Our offices have a global footprint, which allows us to 
					provide round-the-clock support.`}
					</Description>
					<SmallDivider double />
					<Description>
						Twitter:<BaseExternalLink href='https://twitter.com/esprowtech' target="_blank"> @esprowtech</BaseExternalLink>
						<br />
						LinkedIn: <BaseExternalLink href='https://www.linkedin.com/company/esprow' target="_blank">https://www.linkedin.com/company/esprow</BaseExternalLink>
					</Description>
					<SmallDivider double />
					<Description>
						{`Esprow is an equal opportunity employer. Welcoming diversity gives us a competitive advantage in the global
					marketplace and drives our success. Esprow complies with applicable federal, state, and local laws prohibiting
					discrimination in employment in every jurisdiction in which it maintains facilities.`}
					</Description>
				</AboutTextWrapper>
			)}
		/>
		<TabletScreenDivider />
		<LargeDivider />
		<LargeDivider />
		<SectionWrapper backgroundImage style={{ backgroundImage: `url(${greyBackground})` }}>
			<BiggerTitle>
				No compromises.
				<br />
				Real, native, exchange connectivity.
			</BiggerTitle>
			<LargeDivider />
			<PrimaryButtonLink to="/auth/sign-up">Join ETP Markets</PrimaryButtonLink>
			<LargeDivider />
			<LargeDivider />
		</SectionWrapper>
		<TwoSideSection
			leftSection={(
				<Title>
					More information: <LargeExternalLink href="https://esprow.com/" target="_blank">esprow.com</LargeExternalLink>
				</Title>
			)}
			rightSection={(
				<Description>
					{`The Esprow Enterprise Testing Platform (Esprow ETP) provides an integrated suite of applications to
					automate testing of multi-protocol financial systems, on-boarding and certification of counterparties and
					native exchange and broker simulation.`}
				</Description>
			)}
		/>
		<TabletScreenDivider />
		<LargeDivider />
		<LargeDivider />
	</>
);

export default About;
