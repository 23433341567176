import React, {
	useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
// import ReCAPTCHA from 'react-google-recaptcha';

// Lib
import { useAuth } from '../../../components/Context/auth';

// Images
import signupBackground from '../../../assets/images/MarketingSite/signupBackground.png';

// Atoms
import { GreyBackgroundWrapper } from '../../../components/Atoms/WebSite/Wrappers';
import {
	Title, GreyText, ErrorMessage, BaseText,
} from '../../../components/Atoms/WebSite/Text';
import { BigDivider, LargeDivider, SmallDivider } from '../../../components/Atoms/Divider';
import {
	Wrapper,
	ImageSide,
	FormWrapper,
	Form,
	FormLink,
	FormButton,
	ImageSideTextWrapper,
	ImageSideDescription,
	SendAgainWrapper,
	ImageSideTitle,
	LinkWrapper,
} from '../../../components/Atoms/WebSite/Auth';
import { PrimaryButton } from '../../../components/Atoms/Button';

// Molecules
import Input from '../../../components/Molecules/Input/Input/Input';

const SignUp = ({ verifyEmail }) => {
	const { signUp, sendConfirmationEmail } = useAuth();
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const confirmPasswordRef = useRef(null);
	// const refCaptcha = useRef(null);

	const [emailValue, setEmailValue] = useState(null);
	const [passwordValue, setPasswordValue] = useState(null);
	const [confirmPasswordValue, setConfirmPasswordValue] = useState(null);
	const [authError, setAuthError] = useState(null);
	// const [reCaptcha, setReCaptcha] = useState(false);
	const [signupSuccessfully, setSignupSuccessfully] = useState(false);

	useEffect(() => {
		if (confirmPasswordValue) {
			confirmPasswordRef.current.hasError();
		}
	}, [passwordValue, confirmPasswordValue]);

	const validateForm = () => {
		emailRef.current.hasError();
		passwordRef.current.hasError();
		confirmPasswordRef.current.hasError();
		if (emailRef.current.hasError() || passwordRef.current.hasError() || confirmPasswordRef.current.hasError()) {
			return false;
		}
		return true;
	};

	const resetCaptcha = () => {
		// setReCaptcha(false);
	};

	const resetForm = () => {
		emailRef.current.resetState();
		passwordRef.current.resetState();
		resetCaptcha();
	};

	const handleSubmit = async () => {
		if (validateForm()) {
			try {
				await signUp(emailRef.current.getValue(), passwordRef.current.getValue());
				resetForm();
				setSignupSuccessfully(true);
			} catch (error) {
				setAuthError(error.description || error.name);
			}
		}
	};

	const sendAgain = async (email) => {
		try {
			await sendConfirmationEmail(email);
		} catch (error) {
			setAuthError(error.message);
		}
	};

	if (signupSuccessfully || verifyEmail) {
		return (
			<GreyBackgroundWrapper>
				<Wrapper center smaller>
					<SendAgainWrapper>
						<Title>Check Your Email</Title>
						{authError ? <ErrorMessage>{authError}</ErrorMessage> : <SmallDivider double />}
						<BaseText>
							<GreyText>
								We have sent you an email that contains a confirmation link. Please check your email and click the link to confirm your address.
								<br />
								If the email does not arrive within 10 minutes, click the &quot;Send Again&quot; button.
							</GreyText>
						</BaseText>
						<LargeDivider />
						<SmallDivider double />
						<PrimaryButton onClick={() => sendAgain(emailValue || verifyEmail)}>Send again</PrimaryButton>
						<LargeDivider />
					</SendAgainWrapper>
				</Wrapper>
			</GreyBackgroundWrapper>
		);
	}

	return (
		<GreyBackgroundWrapper>
			<Wrapper>
				<ImageSide image={signupBackground}>
					<ImageSideTextWrapper>
						<ImageSideTitle light>
							ETP MARKETS
							<br />
							THE GLOBAL EXCHANGES
							<br />
							& MARKETS SIMULATION
							<br />
							PLATFORM
						</ImageSideTitle>
						<BigDivider />
						<ImageSideDescription>
							A fast and effective solution to test trading algorithms as well as exchange connectivity.
						</ImageSideDescription>
					</ImageSideTextWrapper>
				</ImageSide>
				<FormWrapper>
					<Form onSubmit={(event) => {
						event.preventDefault();
						handleSubmit();
					}}
					>
						<Title>Sign Up</Title>
						<SmallDivider double />
						<GreyText>Enter your email and a password to sign up</GreyText>
						<LargeDivider />
						{authError && (
							<>
								<ErrorMessage>{authError}</ErrorMessage>
								<SmallDivider />
							</>
						)}
						<Input
							ref={emailRef}
							type="email"
							name="email"
							onChange={value => setEmailValue(value)}
							placeholder="Email"
							validators={[
								{
									validator: 'isLength',
									options: {
										min: 1,
									},
									errorResult: false,
									message: 'Required field',
								},
								{
									validator: 'isEmail',
									errorResult: false,
									message: 'Email invalid',
								},
							]}
						/>
						<Input
							ref={passwordRef}
							type="password"
							name="password"
							placeholder="Password"
							onChange={value => setPasswordValue(value)}
							validators={[
								{
									validator: 'isLength',
									options: {
										min: 1,
									},
									errorResult: false,
									message: 'Required field',
								},
								{
									validator: 'isLength',
									options: {
										min: 8,
									},
									errorResult: false,
									message: 'At least 8 character',
								},
							]}
						/>
						<Input
							ref={confirmPasswordRef}
							type="password"
							name="password"
							placeholder="Confirm password"
							onChange={value => setConfirmPasswordValue(value)}
							validators={[
								{
									validator: 'isLength',
									options: {
										min: 1,
									},
									errorResult: false,
									message: 'Required field',
								},
								{
									validator: 'equals',
									options: passwordValue,
									errorResult: false,
									message: 'Password does not match',
								},
							]}
						/>
						{/* <ReCAPTCHA
							ref={refCaptcha}
							sitekey="6LeZRcYUAAAAAJp41Kpeg8kDuH33G9bqbbac1_co"
							onChange={validateReCaptcha}
							onExpired={resetCaptcha}
							size="normal"
						/> */}
						<SmallDivider double />
						<FormButton>Sign Up</FormButton>
						<BigDivider double />
						<LinkWrapper>
							<FormLink to="/auth/sign-in">I have an account - Sign In</FormLink>
						</LinkWrapper>
					</Form>
				</FormWrapper>
			</Wrapper>
		</GreyBackgroundWrapper>
	);
};

SignUp.defaultProps = {
	verifyEmail: null,
};

SignUp.propTypes = {
	verifyEmail: PropTypes.string,
};

export default SignUp;
