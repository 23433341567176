import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Atoms
import { SiteContent, SiteWrapper } from '../../components/Organisms/Layout/Atoms';

// Molecules
import Footer from '../../components/Molecules/Layout/Footer';
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import SiteHeader from '../../components/Organisms/Layout/Public/SiteHeader';

class PublicLayout extends PureComponent {
	render() {
		const {
			children,
		} = this.props;

		return (
			<SiteWrapper>
				<DocumentTitle title="ETP Markets" />
				<SiteHeader />
				<SiteContent>
					{children}
				</SiteContent>
				<Footer />
			</SiteWrapper>
		);
	}
}

PublicLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PublicLayout;
