import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// External Atoms
import {
	Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, ComposedChart,
} from 'recharts';
import 'react-vis/dist/style.css';

// Helpers
import { getActiveExchange } from '../../data/exchanges/selectors';
import { formatTradeTimeStamp } from '../../lib/helpers';

// Constants
import { COLORS } from '../../lib/constants/Style';

// Atoms
import { FlexColumnBase } from '../Atoms/Flex';
import { DEVICE } from '../../lib/constants/Device';

const Wrapper = styled.div`
	${FlexColumnBase};
	flex: 1;
	overflow: hidden;
	padding: 30px 0 0 10px;
	display: flex;
	width: calc(100% - 2px);
	
	@media ${DEVICE.laptopL} {
		padding: 15px 0 0 10px;
	};
`;

class Graph extends PureComponent {
	render() {
		const { data: grafData } = this.props;
		const newData = grafData?.reverse();
		const data = newData?.map(d => ({
			timeStamp: formatTradeTimeStamp(d.get('tradeTimestamp').get('seconds'), d.get('tradeTimestamp').get('nanos')),
			price: Number(d.get('tradePrice').replace(',', '')),
			quantity: Number(d.get('tradeQuantity').replace(',', '')),
		}))?.toJS();

		return (
			<Wrapper className="price-graph">
				<ResponsiveContainer>
					<ComposedChart
						data={data}
						barSize={10}
						interval="preserveEnd"
					>
						<CartesianGrid strokeDasharray="3" />
						<XAxis
							padding={{ right: 20, left: 20 }}
							minTickGap={50}
							dataKey="timeStamp"
						/>
						<YAxis
							hide
							orientation="right"
							datakey="price"
							yAxisId="left"
							domain={['dataMin', 'dataMax']}
						/>
						<YAxis
							hide
							orientation="right"
							datakey="quantity"
							yAxisId="right"
						/>
						<Bar
							name="Quantity"
							isAnimationActive={false}
							dataKey="quantity"
							domain={['dataMin', 'dataMax']}
							fill={COLORS.lightGreen}
							yAxisId="right"
						/>
						<Line
							name="Price"
							isAnimationActive={false}
							yAxisId="left"
							type="linear"
							dataKey="price"
							stroke={COLORS.red}
							activeDot={{ r: 8 }}
						/>
						<Tooltip itemSorter={a => a.name} />
					</ComposedChart>
				</ResponsiveContainer>
			</Wrapper>
		);
	}
}

function mapStateToProps(state, props) {
	return {
		data: getActiveExchange(state).getIn(['orderBooks', Number(props?.match?.params?.bookId), 'trades']),
	};
}

Graph.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRouter(connect(mapStateToProps, null)(Graph));
