import React, { useMemo } from 'react';
import { getInPath } from '../../lib/helpers';

export default function useSearch(initialRows, setTableRows, tableHeader, immutable = false) {
	const [searchInputValue, setSearchInputValue] = React.useState('');
	const pathsToSearch = useMemo(() => tableHeader.filter(h => h.visible).map(h2 => h2.path), [tableHeader]);
	React.useEffect(() => {
		// do search for all headers
		// console.log("IN SEARCH")
		// if (searchInputValue === '') {
		// 	setTableRows(initialRows);
		// 	return;
		// }
		// filter statuses first then search
		const filtered = initialRows?.filter(row => pathsToSearch.every((path, i) => {
			if (!tableHeader[i].filter) {
				return true;
			}
			const term = immutable ? row.getIn(path) : getInPath(row, path);
			return tableHeader[i].filterValue === 'All' || term === tableHeader[i].filterValue;
		}));


		const searched = filtered?.filter((row) => {
			for (let i = 0; i < pathsToSearch.length; i += 1) {
				const path = pathsToSearch[i];
				const term = immutable ? row.getIn(path) : getInPath(row, path);
				try {
					if (term.toString().toLowerCase().includes(searchInputValue.toString().toLowerCase())) {
						return true;
					}
				} catch (e) {
					return false;
				}
			}
			return false;
		});

		setTableRows(searched || []);
	}, [immutable, initialRows, pathsToSearch, searchInputValue, setTableRows, tableHeader]);
	return { searchInputValue, setSearchInputValue };
}
