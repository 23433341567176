import React from 'react';
import styled from 'styled-components';

import { DEVICE } from '../../../lib/constants/Device';

// Atoms
import { FlexRow, FlexRowSpaceBetweenCss } from '../../Atoms/Flex';
import { baseLink } from '../../Atoms/Link';

// Component Specific
const Wrapper = styled.div`
	${FlexRowSpaceBetweenCss};
	height: 60px;
	min-height: 60px;
	max-height: 60px;
	padding: 0 20px;
	width: 100%;
	@media ${DEVICE.laptopL} {
		height: 40px;
		min-height: 40px;
		max-height: 40px;
	};

`;

const FooterLink = styled.a`
	${baseLink};
	margin-right: 25px;

	@media ${DEVICE.tabletL} {
		font-size: 14px;
	}
`;

const RightContent = styled(FlexRow)`
	@media ${DEVICE.tablet} {
		display: none;
	}
`;

const LeftContent = styled(FlexRow)`
	a:last-child {
		margin: 0;		
	}

	@media ${DEVICE.tablet} {
		margin: auto;
	}
`;

const Footer = () => (
	<Wrapper>
		<RightContent>
			<FooterLink href="https://www.esprow.com/about.php" target="_blank" rel="noopener noreferrer">
				About Esprow ETP
			</FooterLink>
			<FooterLink href="mailto:support@esprow.com" rel="noopener noreferrer">
				Report a problem
			</FooterLink>
		</RightContent>
		<LeftContent>
			<FooterLink href="/terms-of-service" target="_blank" rel="noopener noreferrer">
				Terms of Service
			</FooterLink>
			<FooterLink href="/privacy-policy" target="_blank" rel="noopener noreferrer">
				Privacy Policy
			</FooterLink>
		</LeftContent>
	</Wrapper>
);

export default Footer;
