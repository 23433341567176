import TRADING_GATEWAYS_TYPES from './TradingGatewaysTypes';
import Api from '../../lib/api';
import { createFormDataFromObject } from '../../lib/helpers';
import { toggleModal, updateModal } from '../ui/UIActions';
import { MODALS } from '../../lib/constants/General';

function fetchTradingGatewaysStart() {
	return {
		type: TRADING_GATEWAYS_TYPES.FETCH_TRADING_GATEWAYS,
	};
}
function fetchTradingGatewaysSuccess(payload) {
	return {
		type: TRADING_GATEWAYS_TYPES.FETCH_TRADING_GATEWAYS_SUCCESS,
		payload,
	};
}
function fetchTradingGatewaysFail(error) {
	return {
		type: TRADING_GATEWAYS_TYPES.FETCH_TRADING_GATEWAYS_FAIL,
		payload: {
			error,
		},
	};
}

export function fetchTradingGateways(clientExchangeId) {
	return async (dispatch) => {
		try {
			dispatch(fetchTradingGatewaysStart());
			const response = await Api.get(`exchanges/${clientExchangeId}/trading-gateways`);
			dispatch(fetchTradingGatewaysSuccess(response.data));
		} catch (err) {
			dispatch(fetchTradingGatewaysFail(err));
		}
	};
}

function addNewTradingGatewaySuccess() {
	return {
		type: TRADING_GATEWAYS_TYPES.ADD_NEW_TRADING_GATEWAY_SUCCESS,
	};
}
function addNewTradingGatewayFail(payload) {
	return {
		type: TRADING_GATEWAYS_TYPES.ADD_NEW_TRADING_GATEWAY_FAIL,
		payload,
	};
}
export function addNewTradingGateway(clientExchangeId, data) {
	return async (dispatch) => {
		try {
			await Api.post(`exchanges/${clientExchangeId}/trading-gateways`, data);
			dispatch(addNewTradingGatewaySuccess());
		} catch (err) {
			dispatch(addNewTradingGatewayFail(err?.response?.data?.message));
			throw err;
		}
	};
}

function editTradingGatewaySuccess() {
	return {
		type: TRADING_GATEWAYS_TYPES.EDIT_TRADING_GATEWAY_SUCCESS,
	};
}
function editTradingGatewayFail(payload) {
	return {
		type: TRADING_GATEWAYS_TYPES.EDIT_TRADING_GATEWAY_FAIL,
		payload,
	};
}
export function editTradingGateway(data) {
	return async (dispatch) => {
		try {
			await Api.put('exchanges/trading-gateways', data);
			dispatch(editTradingGatewaySuccess());
		} catch (err) {
			dispatch(editTradingGatewayFail(err?.response?.data?.message));
			throw err;
		}
	};
}

function deleteTradingGatewaySuccess(deleteIds) {
	return {
		type: TRADING_GATEWAYS_TYPES.DELETE_TRADING_GATEWAY_SUCCESS,
		payload: {
			deleteIds,
		},
	};
}

export function deleteTradingGateway(deleteIds) {
	return async (dispatch) => {
		try {
			await Api.delete('exchanges/trading-gateways', createFormDataFromObject({ deleteIds }));
			dispatch(deleteTradingGatewaySuccess(deleteIds));
			dispatch(toggleModal(MODALS.DELETE_CONFIRMATION_MODAL, false));
		} catch (err) {
			dispatch(updateModal(MODALS.DELETE_CONFIRMATION_MODAL, { error: err?.response?.data?.message }));
		}
	};
}
