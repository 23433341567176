import styled from 'styled-components';
import { TextBase, TextBaseSmall } from '../../Css/Css';
import { COLORS } from '../../../lib/constants/Style';

export const TooltipBody = styled.div`
  border-radius: 4px;
  box-shadow: 0px 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
  background-color: ${COLORS.white};
  padding: 25px;
`;
export const TooltipTitle = styled.span`
	${TextBase};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.lightBlack};
`;
export const TooltipContent = styled.p`
	max-width: 600px;
	line-height: 1.5;
  	letter-spacing: normal;
  	color: ${COLORS.lightBlack};
  	${TextBaseSmall};
  	text-align: justify;
`;
export const SkipTour = styled.span`
	margin-right: auto;
  	${TextBaseSmall};  
  	color: ${COLORS.darkGrey};
  	cursor: pointer;
`;
export const TooltipFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
`;
