export const rows = [
	{
		code: 'GOOGLE',
		name: 'Apple inc.',
		instrument: '10',
		tradingPhase: 'ding',
		status: 'Opened',
	},
	{
		code: 'APPLE',
		name: 'Apple inc.',
		instrument: '9',
		tradingPhase: 'Contious trading',
		status: 'Opened',
	},
	{
		code: 'MEME',
		name: 'Apple inc.',
		instrument: '8',
		tradingPhase: 'Connious trading',
		status: 'Opened',
	}, {
		code: 'PAPA',
		name: 'Apple inc.',
		instrument: '7',
		tradingPhase: 'Canious trading',
		status: 'Opened',
	}, {
		code: 'NENE',
		name: 'Apple inc.',
		instrument: '6',
		tradingPhase: 'CCContinious trading',
		status: 'Opened',
	}, {
		code: 'ZAZA',
		name: 'Apple inc.',
		instrument: '5',
		tradingPhase: 'ZZZContinious trading',
		status: 'Opened',
	}, {
		code: 'ZEZE',
		name: 'Apple inc.',
		instrument: '4',
		tradingPhase: 'YYContinious trading',
		status: 'Opened',
	}, {
		code: 'POPO',
		name: 'Apple inc.',
		instrument: '3',
		tradingPhase: 'PPContinious trading',
		status: 'Opened',
	}, {
		code: 'LOLO',
		name: 'Apple inc.',
		instrument: '2',
		tradingPhase: 'LLContinious trading',
		status: 'Opened',
	}, {
		code: 'GOGO',
		name: 'Apple inc.',
		instrument: '1',
		tradingPhase: 'CCContinious trading',
		status: 'Opened',
	},


];
export const orderBookHeader = [
	{
		name: 'View Code', sortable: true, index: 0, width: '15%', path: ['viewCode'], visible: true,
	},
	{
		name: 'Name', sortable: true, index: 1, width: '35%', path: ['name'], visible: true,
	},
	{
		name: 'ID', sortable: true, selectable: true, index: 0, width: '15%', path: ['displayId'], visible: true, required: true,
	},
	{
		name: 'ISIN Code', sortable: true, index: 5, width: '10%', path: ['isinCode'], visible: false,
	},
	{
		name: 'BBG Code', sortable: true, index: 6, width: '10%', path: ['bbgCode'], visible: false,
	},
	{
		name: 'RIC Code', sortable: true, index: 7, width: '10%', path: ['ricCode'], visible: false,
	},
	{
		name: 'Type', sortable: true, index: 2, width: '10%', path: ['type'], visible: true,
	},
	{
		name: 'Phase', sortable: true, index: 3, width: '15%', path: ['viewCode'], visible: true,
	},
	{
		name: 'Status', sortable: true, index: 4, width: '10%', path: ['status'], visible: true,
	},
];

export const tradingGatewaysHeader = [
	{
		name: 'Name', sortable: true, selectable: true, index: 0, width: '40%', path: ['name'], visible: true, required: true,
	},
	{
		name: 'Port', sortable: true, index: 1, width: '10%', path: ['port'], visible: true,
	},
	{
		name: 'Number of sessions', sortable: true, index: 2, width: '15%', path: [''], visible: true,
	},
	{
		name: 'Active sessions', sortable: true, index: 3, width: '20%', path: ['details'], visible: true,
	},
	{
		name: 'Status', sortable: true, index: 4, width: '15%', path: ['status'], visible: true,
	},
];
export const coreComponentsHeader = [
	{
		name: 'Name', sortable: true, selectable: true, index: 0, path: ['name'], visible: true, width: '80%',
	},
	{
		name: 'Status', sortable: true, index: 1, path: ['status'], visible: true,
	},
];
