import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { COLORS } from '../../../lib/constants/Style';
import { TextBaseSmall } from '../../Css/Css';

export const TreeLabelWrapper = styled.div`
	display: flex;
	flex-direction: row;
	border-bottom: 2px solid ${COLORS.borderGray};
	cursor: pointer;
	&:hover {
		background: ${COLORS.lightestGrey};
	}
`;
export const TreeHeaderLabelWrapper = styled(TreeLabelWrapper)`
	position: sticky;
	top: -1px;
	z-index: 55;
	background-color: ${COLORS.white};
`;
export const LabelText = styled.div`
	flex: 1;
	${TextBaseSmall};
  	font-stretch: normal;
  	font-style: normal;
  	line-height: 1.43;
  	letter-spacing: normal;
  	text-align: left;
  	color: ${COLORS.lightBlack};
	${props => props.open && 'font-weight: bold'}
`;
export const TreeTableColumn = styled.div`
	width: 20%;
	height: 30px;
	display: flex;
	align-items: center;
	${props => props.big && 'width: 60%'};
`;
export const FaceDownIconWrapper = styled.div`
	margin-right: 6px;
`;
export const FaceUpIconWrapper = styled.div`
	position: relative;
	bottom: -6px;
	margin-right: 6px;
`;
export const IconFaceDown = () => (
	<FaceDownIconWrapper>
		<FontAwesomeIcon
			icon={faSortDown}
			color={COLORS.grey}
		/>
	</FaceDownIconWrapper>
);
export const IconFaceUp = () => (
	<FaceUpIconWrapper>
		<FontAwesomeIcon
			icon={faSortDown}
			color={COLORS.grey}
			transform={{ rotate: 180 }}
		/>
	</FaceUpIconWrapper>
);
export const HeaderText = styled.div`
	${TextBaseSmall};
  	color: ${COLORS.darkGrey};
`;
export const Margin = styled.div`
	${props => props.margin && `margin-left: ${(props.margin + 1) * 10}px`};
	display: flex;
	flex-direction: row;

`;
export const TreeNodeWrapper = styled.div`
	width: 100%;
	${props => props.hasMargin && 'margin-left: 10px'};
	text-align: left;
	box-sizing: border-box;
`;
