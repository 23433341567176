import { fromJS } from 'immutable';
import WEBSOCKET_TYPES from './types';


const initialState = fromJS({
	report: [],
	data: [],
});

export default function wsReducer(state = initialState, action) {
	switch (action.type) {
	case WEBSOCKET_TYPES.PUSH_MD_REPLAY_TABLE_DATA_ROW: {
		return state.set('data', state.get('data').push(action.payload.data));
	}
	case WEBSOCKET_TYPES.PUSH_MD_REPLAY_REPORT_ROW: {
		return state.set('report', state.get('report').push(action.payload.data));
	}
	default: {
		return state;
	}
	}
}
