import React from 'react';
import PropTypes from 'prop-types';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { replace } from 'connected-react-router';
import { AppTourContext } from '../../Context/AppTourContext';
import {
	toggleAddExchangeModal,
	toggleAddTradingGatewayModal,
	toggleCreateExchangeModal,
} from '../../../data/ui/UIActions';
import {
	exchangeBehaviourSteps,
	exchangeDictionarySteps,
	exchangesSteps,
	exchangeSteps,
	marketDataReplaySteps,
	messageTrafficSteps,
	orderBlotterSteps,
	orderBookSteps,
	subscriptionSteps,
	subscriptionStepsNoSubscriptions,
} from '../../../lib/tourGuide';
import Tooltip from './Tooltip';

class AppTourContainer extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			runGlobal: false,
			canStart: {
				exchanges: true,
				exchange: true,
				orderBook: true,
				orderBlotter: true,
				exchangeBehavior: true,
				messageTraffic: true,
				exchangeDictionary: true,
				marketDataReplay: true,
				subscription: false,
			},
			steps: {
				exchanges: {
					steps: exchangesSteps,
					finished: false,
				},
				exchange: {
					steps: exchangeSteps,
					finished: false,
				},
				orderBook: {
					steps: orderBookSteps,
					finished: false,
				},
				orderBlotter: {
					steps: orderBlotterSteps,
					finished: false,
				},
				exchangeBehavior: {
					steps: exchangeBehaviourSteps,
					finished: false,
				},
				messageTraffic: {
					steps: messageTrafficSteps,
					finished: false,
				},
				exchangeDictionary: {
					steps: exchangeDictionarySteps,
					finished: false,
				},
				marketDataReplay: {
					steps: marketDataReplaySteps,
					finished: false,
				},
				subscription: {
					steps1: subscriptionSteps,
					steps2: subscriptionStepsNoSubscriptions,
					finished: false,
				},
			},
			stepIndex: 0, // a controlled tour
		};
	}

	handleExchangesRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;

		if (type === 'tour:end') {
			this.handleTourEnd('exchanges');
		}
		if (action === 'next' && lifecycle === 'complete' && index === 1) {
			// at this point we should trigger modal. TODO there should be a better way to achive this
			this.props.actions.toggleCreateExchangeModal(true);
		}
		if (action === 'prev' && index === 2 && lifecycle === 'complete') {
			this.props.actions.toggleCreateExchangeModal(false);
		}
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false });
			this.setState({ stepIndex: 0 });
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	};

	handleExchangeRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;

		if (type === 'tour:end') {
			this.handleTourEnd('exchange');
		}
		// TODO we should ensure that user is on order-books tab
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		// Open tr tab on this step!
		const { actions, location } = this.props;
		if (action === 'next' && lifecycle === 'complete' && index === 16) {
			// at this point we should trigger modal. TODO there should be a better way to achive this
			// ugly way of making url beacuse we don't have access to match
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/tg-table`);
		}
		// Return to previous tab if user hits back
		if (action === 'prev' && index === 17 && lifecycle === 'complete') {
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/ob-table`);
		}

		// Open add tr modal on this step!
		if (action === 'next' && lifecycle === 'complete' && index === 18) {
			// at this point we should trigger modal. TODO there should be a better way to achive this
			actions.toggleAddTradingGatewayModal(true);
		}
		if (action === 'prev' && index === 19 && lifecycle === 'complete') {
			actions.toggleAddTradingGatewayModal(false);
		}

		// Close the modal and go to mdg tab
		if (action === 'next' && lifecycle === 'complete' && index === 22) {
			// at this point we should trigger modal. TODO there should be a better way to achive this
			actions.toggleAddTradingGatewayModal(false);
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/mdg`);
		}
		if (action === 'prev' && index === 23 && lifecycle === 'complete') {
			actions.toggleAddTradingGatewayModal(true);
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/tg-table`);
		}

		// Switch to core components tab
		if (action === 'next' && lifecycle === 'complete' && index === 23) {
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/core-table`);
		}
		if (action === 'prev' && index === 24 && lifecycle === 'complete') {
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/mdg`);
		}

		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	};

	handleOrderBookRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;
		const {
			actions,
			location,
		} = this.props;
		if (type === 'tour:end') {
			this.handleTourEnd('orderBook');
		}
		// TODO we should ensure that user is on order-books tab
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		if (action === 'next' && lifecycle === 'complete' && index === 5) {
			// at this point we should trigger modal. TODO there should be a better way to achive this
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/order-entry`);
		}
		if (action === 'prev' && index === 6 && lifecycle === 'complete') {
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/graph`);
		}

		if (action === 'next' && lifecycle === 'complete' && index === 6) {
			// at this point we should trigger modal. TODO there should be a better way to achive this
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/md-replay`);
		}
		if (action === 'prev' && index === 7 && lifecycle === 'complete') {
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/order-entry`);
		}

		if (action === 'next' && lifecycle === 'complete' && index === 7) {
			// at this point we should trigger modal. TODO there should be a better way to achive this
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/counters`);
		}
		if (action === 'prev' && index === 8 && lifecycle === 'complete') {
			const foundIndex = location.pathname.lastIndexOf('/');
			const url = location.pathname.slice(0, foundIndex);
			actions.replace(`${url}/md-replay`);
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	};

	handleTourEnd = (page) => {
		this.setState({
			steps: {
				...this.state.steps,
				[page]: {
					...this.state.steps[page],
					finished: true,
				},
			},
			stepIndex: 0,
		});
	};

	handleSubscriptionRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;
		if (type === 'tour:end') {
			this.setState({
				steps: {
					...this.state.steps,
					subscription: {
						...this.state.steps.subscription,
						finished: true,
					},
				},
				stepIndex: 0,
			});
		}
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		if (action === 'next' && lifecycle === 'complete' && index === 0) {
			// at this point we should trigger modal. TODO there should be a better way to achive this
			this.props.actions.toggleAddExchangeModal(true);
		}
		if (action === 'prev' && index === 1 && lifecycle === 'complete') {
			this.props.actions.toggleAddExchangeModal(false);
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	};

	handleSubscriptionRideWithSubscriptions = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;
		if (type === 'tour:end') {
			this.setState({
				steps: {
					...this.state.steps,
					subscription: {
						...this.state.steps.subscription,
						finished: true,
					},
				},
			});
			this.setState({ stepIndex: 0 });
		}
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	}

	handleOrderBlotterRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;
		if (type === 'tour:end') {
			this.handleTourEnd('orderBlotter');
		}
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	}

	handleExchangeBehaviorRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;
		if (type === 'tour:end') {
			this.handleTourEnd('exchangeBehavior');
		}
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	}

	handleMessageTrafficRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;
		if (type === 'tour:end') {
			this.handleTourEnd('messageTraffic');
		}
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	};

	handleExchangeDictionaryRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;
		if (type === 'tour:end') {
			this.handleTourEnd('exchangeDictionary');
		}
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	};

	handleMarketDataReplayRide = (data) => {
		const {
			action, index, status, type, lifecycle,
		} = data;
		if (type === 'tour:end') {
			this.handleTourEnd('marketDataReplay');
		}
		if (action === 'close' && lifecycle === 'complete') {
			this.setState({ runGlobal: false, stepIndex: 0 });
		}
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
	};

	handleJoyrideCallback = (data) => {
		const { location } = this.props;
		if (location.pathname === '/app/exchanges') {
			this.handleExchangesRide(data);
		} else if (location.pathname.match(/^\/app\/exchanges\/[1-9]+[0-9]*\/(ob-table|tg-table|mdg|core-table)$/)) {
			this.handleExchangeRide(data);
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/order-book\/[1-9]+[0-9]*/)) {
			this.handleOrderBookRide(data);
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/order-blotter/)) {
			this.handleOrderBlotterRide(data);
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/exchange-behavior/)) {
			this.handleExchangeBehaviorRide(data);
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/gateways-traffic/)) {
			this.handleMessageTrafficRide(data);
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/exchange-dictionary/)) {
			this.handleExchangeDictionaryRide(data);
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/market-data-replay/)) {
			this.handleMarketDataReplayRide(data);
		} else if (location.pathname === '/app/subscription') {
			if (this.state.hasSubscriptions) {
				this.handleSubscriptionRideWithSubscriptions(data);
			} else {
				this.handleSubscriptionRide(data);
			}
		}
	};

	startTour = (page, hasSubscriptions = false) => {
		this.setState({
			stepIndex: 0,
			runGlobal: true,
			steps: {
				...this.state.steps,
				[page]: {
					...this.state.steps[page],
					finished: false,
				},
			},
			hasSubscriptions,
		});
	};

	setCanStart = (page, value) => {
		this.setState({ canStart: { ...this.state.canStart, [page]: value } });
	};

	render() {
		const {
			stepIndex, steps, runGlobal, hasSubscriptions, canStart,
		} = this.state;
		const { location, children } = this.props;
		let tourSteps = [];
		let finished = true;
		let currentPage = '';
		if (location.pathname === '/app/exchanges') {
			tourSteps = steps.exchanges.steps;
			finished = steps.exchanges.finished;
			currentPage = 'exchanges';
		} else if (location.pathname.match(/^\/app\/exchanges\/[1-9]+[0-9]*\/(ob-table|tg-table|mdg|core-table)$/)) {
			tourSteps = steps.exchange.steps;
			finished = steps.exchange.finished;
			currentPage = 'exchange';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/order-book\/[1-9]+[0-9]*/)) {
			tourSteps = steps.orderBook.steps;
			finished = steps.orderBook.finished;
			currentPage = 'orderBook';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/order-blotter/)) {
			tourSteps = steps.orderBlotter.steps;
			finished = steps.orderBlotter.finished;
			currentPage = 'orderBlotter';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/exchange-behavior/)) {
			tourSteps = steps.exchangeBehavior.steps;
			finished = steps.exchangeBehavior.finished;
			currentPage = 'exchangeBehavior';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/gateways-traffic/)) {
			tourSteps = steps.messageTraffic.steps;
			finished = steps.messageTraffic.finished;
			currentPage = 'messageTraffic';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/exchange-dictionary/)) {
			tourSteps = steps.exchangeDictionary.steps;
			finished = steps.exchangeDictionary.finished;
			currentPage = 'exchangeDictionary';
		} else if (location.pathname.match(/\/app\/exchanges\/[1-9]+[0-9]*\/market-data-replay/)) {
			currentPage = 'marketDataReplay';
			tourSteps = steps.marketDataReplay.steps;
			finished = steps.marketDataReplay.finished;
		} else if (location.pathname === '/app/subscription') {
			currentPage = 'subscription';
			if (hasSubscriptions) {
				tourSteps = steps.subscription.steps1;
				finished = steps.subscription.finished;
			} else {
				tourSteps = steps.subscription.steps2;
				finished = steps.subscription.finished;
			}
		}
		return (
			<AppTourContext.Provider
				value={{
					startTour: this.startTour,
					setCanStart: this.setCanStart,
				}}
			>
				<Joyride
					callback={this.handleJoyrideCallback}
					run={runGlobal && !finished && canStart[currentPage]}
					stepIndex={stepIndex}
					steps={tourSteps}
					tooltipComponent={Tooltip}
					continuous
					autostart
					styles={{ options: { zIndex: 9999999 } }}
					disableOverlayClose
				/>
				{children}
			</AppTourContext.Provider>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleCreateExchangeModal,
			toggleAddTradingGatewayModal,
			toggleAddExchangeModal,
			replace,
		}, dispatch),
	};
}

export default withRouter(connect(null, mapDispatchToProps)(AppTourContainer));


AppTourContainer.propTypes = {
	location: PropTypes.shape({}).isRequired,
	children: PropTypes.element.isRequired,
};
