import * as PropTypes from 'prop-types';
import React from 'react';
import { Arrow } from './MessageTrafficTableAtoms';

export function MessageTrafficDirection({ direction }) {
	if (direction === 'IN') {
		return (
			<Arrow
				name="arrowLeft"
				data-tip="Incoming"
			/>
		);
	}
	return <Arrow name="arrowRight" data-tip="Outgoing" />;
}

MessageTrafficDirection.propTypes = {
	direction: PropTypes.oneOf(['IN', 'OUT']).isRequired,
};
