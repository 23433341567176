import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// CSS
import { TextBase, TextBig } from '../../Css/Css';
import { COLORS } from '../../../lib/constants/Style';

// Atoms
import { FlexColumn } from '../../Atoms/Flex';
import { AccordionItem } from './Atoms';
import { BaseDivider } from '../../Atoms/Divider';

// Molecules
import { Accordion } from './Moleculs/Accordion';
import AccordionLabel from './Moleculs/AccordionLabel';


const AccordionGroupWrapper = styled.div`
	width: 100%;
	background-color: ${COLORS.white};
	& > div {
		border-bottom: 2px solid ${COLORS.borderGray};
		:first-child{
			border-top: 2px solid rgb(243, 241, 242);
		}
	}
	${TextBase};
`;
const AccordionGroupTittle = styled.span`
  	${TextBig}
`;
const ShowAllText = styled.p`
	font-weight: bold;
	margin-top: 10px;
	cursor: pointer;
	${TextBase}
`;

const AccordionGroup = (props) => {
	const {
		maxShown = 6,
		data,
		title,
	} = props;
	const [showAll, setShowAll] = useState(false);
	const [openedIndex, setOpenedIndex] = useState(-1);
	const dataToRender = useMemo(() => (showAll ? data : data.slice(0, maxShown)), [data, maxShown, showAll]);

	return (
		<FlexColumn>
			<BaseDivider double />
			<AccordionGroupTittle>
				{title}
			</AccordionGroupTittle>
			<BaseDivider double />
			<AccordionGroupWrapper>
				{dataToRender.map(item => (
					<Accordion
						isOpen={item.label === openedIndex}
						key={item.label}
						Label={<AccordionLabel text={item.label} onClick={() => setOpenedIndex(item.label === openedIndex ? -1 : item.label)}/>}
						Item={<AccordionItem text={item.text} onClick={(e) => { e.stopPropagation()}} />}
					/>
				))}
				<ShowAllText onClick={() => setShowAll((prevState => !prevState))}>
					{(data.length > maxShown && !showAll) && (
						`Show all(${data.length})`
					)}
				</ShowAllText>
			</AccordionGroupWrapper>
		</FlexColumn>
	);
};
AccordionGroup.propTypes = {
	maxShown: PropTypes.number,
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	title: PropTypes.string.isRequired,
};

AccordionGroup.defaultProps = {
	maxShown: 6,
};

export default AccordionGroup;
