import React from 'react';
import PropTypes from 'prop-types';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Atoms
import { SearchWrapper, SearchInput, SearchButton } from './SearchField.atoms';

/**
 * @param {string} value this is search field value that parent control
 * @param {string} placeholder input field placeholder
 * @param {func} onClick triggered when user click on search icon
 * @param {func} onChange set value of search field in parent component
 */

const SearchField = (props) => {
	const {
		value, placeholder, onClick, onChange, white,
	} = props;
	return (
		<SearchWrapper white={white}>
			<SearchInput
				value={value}
				white={white}
				placeholder={placeholder}
				onChange={event => onChange(event.target.value)}
			/>
			<SearchButton onClick={onClick}>
				<FontAwesomeIcon icon={faSearch} />
			</SearchButton>
		</SearchWrapper>
	);
};

SearchField.defaultProps = {
	value: '',
	placeholder: '',
	onClick: () => {},
	onChange: () => {},
};

SearchField.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	onClick: PropTypes.func,
};

export default SearchField;
