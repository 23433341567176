import React from 'react';
import styled from 'styled-components';
import { Line } from 'rc-progress';
import PropTypes from 'prop-types';

import { FlexColumnBase } from '../Atoms/Flex';
import { COLORS } from '../../lib/constants/Style';

const Wrapper = styled.div`
	${FlexColumnBase};
	height: 3px;
	width: 95%;
`;

const RelativeWrapper = styled.div`
	position:relative;
	width: 100%;
`;
const PercentageText = styled.div`
  	font-size: 12px;
	color: ${COLORS.lightBlack};
	position: absolute;
	width: 30px;
	top: -18px;
	text-align: center;
	${props => `${'left: calc('}${props.percentage}% - 12px);`};
`;

const ProgressBar = ({
	percentage, noText, bigger, trailColor,
}) => (
	<Wrapper>
		<Line
			style={{ borderRadius: '4px', transition: 'all linear 0.5s' }}
			percent={percentage || 0}
			strokeWidth={bigger ? 40 : 1}
			strokeColor={COLORS.yellow}
			trailColor={trailColor}
			trailWidth={bigger ? 40 : 1}
			strokeLinecap="square"
		/>
		{!noText && (
			<RelativeWrapper>
				<PercentageText percentage={percentage || 0}>
					{percentage || 0}
					{'%'}
				</PercentageText>
			</RelativeWrapper>
		)}

	</Wrapper>
);

ProgressBar.defaultProps = {
	percentage: 0,
	noText: false,
	bigger: false,
	trailColor: '#d8dde2',
};

ProgressBar.propTypes = {
	percentage: PropTypes.number,
	// Don't render text
	noText: PropTypes.bool,
	// Show bigger progress bar
	bigger: PropTypes.bool,
	trailColor: PropTypes.string,
};

export default ProgressBar;
