import React from 'react';

// Atoms
import {
	Line,
	MediumDivider, SmallestDivider,
	SmallVerticalDivider,
} from '../../Atoms/Divider';
import {
	BillingOverviewContent,
	BillingOverviewWrapper,
	EsprowLink, EsprowLinkWrapper,
} from './Atoms';
import { PrimaryButton } from '../../Atoms/Button';

// Organisms
import SubscriptionDetails from './SubscriptionDetails';
import SubscriptionDetailsCurrent from './SubscriptionDetailsCurrent';

const BillingOverview = (props) => {
	const {
		activeAddons,
		generalPrices,
		payNextMonth,
		payNow,
		onPayClick,
		exchangeNames,
		detailedPayments,
	} = props;
	const newAddons = [];
	activeAddons.get('data').map((item) => {
		if (item.get('newSessions') > 0 || item.get('newExchange')) {
			return newAddons.push(item.toJS());
		}
	});
	return (
		<BillingOverviewWrapper>
			<BillingOverviewContent>
				<SubscriptionDetails
					title="Monthly Subscription"
					addons={activeAddons}
					generalPrices={generalPrices}
					total={payNextMonth}
					exchangeNames={exchangeNames}
				/>
				<MediumDivider />
				<Line double />
				<MediumDivider />
				<SubscriptionDetailsCurrent
					title="Current Payment"
					generalPrices={generalPrices}
					total={payNow}
					exchangeNames={exchangeNames}
					detailedPayments={detailedPayments}
				/>
				<MediumDivider />
				<Line double />
				<MediumDivider />
				<div>
					<PrimaryButton
						onClick={onPayClick}
						className="subscription-confirm-button"
					>
						{payNow > 0 && (
							<>
								Pay
								{' '}
								$
								{payNow}
							</>
						)}
						{payNow === 0 && (
							<>
								Confirm
							</>
						)}
					</PrimaryButton>
				</div>
			</BillingOverviewContent>
			<MediumDivider />
			<EsprowLinkWrapper>
				<EsprowLink
					href="https://www.esprow.com/contact-us.php"
					target="_blank"
				>
					Contact Esprow
				</EsprowLink>
				<SmallVerticalDivider />
				<p> for enterprise licensing</p>
			</EsprowLinkWrapper>
			<SmallestDivider />
		</BillingOverviewWrapper>
	);
};

export default BillingOverview;
