import styled from 'styled-components';
import { FlexRowBase } from '../../../Atoms/Flex';
import { SortableTableCSS } from '../../../Css/Table';
import { BaseBlockStyle } from '../../../Css/Css';
import IconComponent from '../../../Atoms/Icon';

export const ActionsWrapper = styled.div`
	${FlexRowBase};
	flex: 1;
	width: 100%;
	justify-content: flex-end;
	padding-left: 30px;
	position: fixed;
    right: -10px;
    top: 116px;
    overflow: visible;
    z-index: 10;
`;
export const MessageTrafficTableWrapper = styled.div`
	${SortableTableCSS};
	${BaseBlockStyle};
	height: 100%;
	padding: 30px 15px 10px 30px;
  	box-shadow: 0px 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
  	position: relative;
  	display: flex;
  	flex-direction: column;
  	overflow: hidden;
`;
export const ModalLink = styled.p`
	cursor: pointer;
	&:hover{
		text-decoration: underline;
	}
`;
export const Wrapper = styled.div`
	max-width: 173px;
	flex: 1;
`;

export const Arrow = styled(IconComponent)`

`;
