import React from 'react';
import PropTypes from 'prop-types';
// Atoms
import {
	IconFaceDown, IconFaceUp, LabelText, Margin, TreeTableColumn,
} from './TreeTable.Atoms';

const FirstData = (props) => {
	const {
		open,
		text,
		hasChildren,
		depth,
	} = props;
	return (
		<TreeTableColumn big>
			<Margin margin={depth}>
				{hasChildren && (!open ? <IconFaceDown /> : <IconFaceUp />)}
				{' '}
				<LabelText open={hasChildren && open}>
					{' '}
					{text}
				</LabelText>
			</Margin>
		</TreeTableColumn>
	);
};

FirstData.propTypes = {
	open: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
	hasChildren: PropTypes.arrayOf(PropTypes.shape({})),
	depth: PropTypes.number,
};

FirstData.defaultProps = {
	depth: 0,
	hasChildren: null,
};

export default FirstData;
