import styled from 'styled-components';

export const TwoSideImage = styled.img`
	width: 100%;
`;

export const QuoterImage = styled.img``;

export const CardImage = styled.img`
	height: 100%;
`;
