import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import math from '../../../lib/math';
// Atoms
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton } from '../../Atoms/Button';
import { CustomDivider, MediumVerticalDivider } from '../../Atoms/Divider';
import { ActionsWrapper, ExchangeWrapper, SectionTitle } from './Atoms';
import { InfoTable } from '../../Atoms/Table';
import ExchangeStatus from '../../Atoms/ExchangeStatus';

// Molecules
import ProgressCircle from '../../Molecules/ProgressCircle';

import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';
import { handleButtonStatus } from '../../../lib/exchange';
import MachineStartDate from './MachineStartDate';
import { getActiveExchange } from '../../../data/exchanges/selectors';

function ExchangeSection(props) {
	const {
		handleOpenClick,
		handleCloseClick,
		handleStartClick,
		handleStopClick,
		totalComponents,
		openedComponents,
		name,
		instanceId,
		type,
		orderBooks,
		trGateways,
		startedOn,
		status,
		availableSessions,
	} = props;
	const {
		start, stop, open, close,
	} = handleButtonStatus(status);

	return (
		<ExchangeWrapper
			className="exchange-box-container"
		>
			<FlexRow justifyContent="space-between">
				<SectionTitle>
					Exchange
				</SectionTitle>
				<ExchangeStatus status={status} />
			</FlexRow>
			<CustomDivider width="8px" />
			<FlexColumn alignItems="flex-end">
				<MachineStartDate startedOn={startedOn} />
			</FlexColumn>
			<FlexRow justifyContent="space-between">
				<InfoTable>
					<tbody>
						<tr>
							<td>Name:</td>
							<td>{name}</td>
						</tr>
						<tr>
							<td>Instance ID:</td>
							<td>{instanceId}</td>
						</tr>
						<tr>
							<td>Type:</td>
							<td>{type}</td>
						</tr>
						<tr>
							<td>TR Gateways:</td>
							<td>{trGateways}</td>
						</tr>
						<tr>
							<td>Order Books:</td>
							<td>{orderBooks}</td>
						</tr>
						<tr>
							<td>Available Sessions:</td>
							<td>{availableSessions}</td>
						</tr>
					</tbody>
				</InfoTable>
				<div className="opened-components-progress-bar">
					<ProgressCircle
						limit={status === EXCHANGE_STATUS.OPENED ? math.round((openedComponents / totalComponents) * 100) : 0}
					/>
				</div>
			</FlexRow>
			<ActionsWrapper
				className="exchange-box-buttons-container"
			>
				<PrimaryButton disabled={!start} onClick={handleStartClick}>Start</PrimaryButton>
				<MediumVerticalDivider />
				<PrimaryButton disabled={!open} onClick={handleOpenClick}>Open</PrimaryButton>
				<MediumVerticalDivider />
				<PrimaryButton disabled={!close} onClick={handleCloseClick}>Close</PrimaryButton>
				<MediumVerticalDivider />
				<PrimaryButton disabled={!stop} onClick={handleStopClick}>Stop</PrimaryButton>
			</ActionsWrapper>
		</ExchangeWrapper>
	);
}
ExchangeSection.defaultProps = {
	status: EXCHANGE_STATUS.NA,
	startedOn: null,
	totalComponents: 0,
	openedComponents: 0,
};
ExchangeSection.propTypes = {
	handleOpenClick: PropTypes.func.isRequired,
	handleCloseClick: PropTypes.func.isRequired,
	handleStartClick: PropTypes.func.isRequired,
	handleStopClick: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	instanceId: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	orderBooks: PropTypes.number.isRequired,
	trGateways: PropTypes.number.isRequired,
	startedOn: PropTypes.number,
	status: PropTypes.string,
	availableSessions: PropTypes.number.isRequired,
	totalComponents: PropTypes.number,
	openedComponents: PropTypes.number,
};

function mapStateToProps(state) {
	const activeExchange = getActiveExchange(state);
	return {
		name: activeExchange?.get('name'),
		instanceId: activeExchange?.get('displayId'),
		type: activeExchange?.get('exchangeType').get('name'),
		trGateways: activeExchange?.get('tradingGateways').size,
		orderBooks: activeExchange?.get('orderBooks').size,
		startedOn: activeExchange?.get('exchangeStartedTime'),
		status: activeExchange?.get('status'),
		availableSessions: activeExchange?.get('availableSessions'),
		totalComponents: activeExchange?.get('totalComponents'),
		openedComponents: activeExchange?.get('openedComponents'),
	};
}
const connected = connect(mapStateToProps, null)(ExchangeSection);
export default memo(connected);
