import axios from 'axios';

// Lib
import { getItem } from './localStorage';
import { store } from './store';

// Actions
import { toggleSessionExpireModal } from '../data/ui/UIActions';
import { env } from '../env';

class ApiClient {
	constructor() {
		const instance = axios.create();

		this.refreshTokenCallback = null;

		instance.interceptors.request.use((config) => {
			const token = JSON.parse(getItem('loginInfo'))?.accessToken;
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		});

		instance.interceptors.response.use(response => response, async (error) => {
			const originalRequest = error.config;
			// eslint-disable-next-line no-underscore-dangle
			if ((error.response?.status === 403 || error.response?.status === 401) && !originalRequest._retry) {
				if (this.refreshTokenCallback) {
					// eslint-disable-next-line no-underscore-dangle
					originalRequest._retry = true;
					try {
						await this.refreshTokenCallback();
						const token = JSON.parse(getItem('loginInfo'))?.accessToken; // Assuming the token has been updated in storage
						originalRequest.headers.Authorization = `Bearer ${token}`;
						return this.axiosInstance(originalRequest);
					} catch (refreshError) {
						// Refresh token call failed or token refresh was not successful
						store.dispatch(toggleSessionExpireModal(true));
						return Promise.reject(refreshError);
					}
				}
			}
			// Token refresh did not happen, or there was another error
			// eslint-disable-next-line no-underscore-dangle
			if (!originalRequest._retry) {
				store.dispatch(toggleSessionExpireModal(true));
			}
			return Promise.reject(error);
		});

		this.axiosInstance = instance;
	}

	setRefreshTokenCallback(callback) {
		this.refreshTokenCallback = callback;
	}

	async headers() {
		return {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		};
	}

	/**
	 * Fetch a url from the API
	 * @param {string} url
	 * @returns {Promise<*>}
	 */
	get(url, config = {}, fullURL) {
		return this.xhr('get', url, {}, config, fullURL);
	}

	post(url, data = {}, config = {}, fullURL) {
		return this.xhr('post', url, data, config, fullURL);
	}

	put(url, data = {}, config = {}) {
		return this.xhr('put', url, data, config);
	}

	delete(url, data = {}, config = {}) {
		return this.xhr('delete', url, data, config);
	}

	static getApiURL() {
		return `${env.REACT_APP_BACKEND_URL}/api/v1`;
	}

	/**
	 * Base fetch method. Should not be used directly, but through above functions.
	 * @param method
	 * @param url
	 * @param data?
	 * @param config?
	 * @returns {Promise<*>}
	 */
	async xhr(method, url, data = {}, config = {}, fullURL) {
		// @TODO implement config if needed
		const API_URL = ApiClient.getApiURL();
		const setUrl = fullURL ? url : `${API_URL}/${url}`;
		const myHeaders = await this.headers();
		const headers = {
			...myHeaders,
			...config,
		};
		const options = {
			method,
			data,
			headers,
			withCredentials: true,
			url: setUrl,
			crossDomain: true,
		};
		// @TODO Add handlers
		const xhrResult = await this.axiosInstance(options);
		return xhrResult;
	}
}

const Api = new ApiClient(); // Create an instance of Api class to use throughout your application
export default Api;
