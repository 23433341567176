import styled, { css } from 'styled-components';

export const FlexRow = styled.div`
	display: flex;
	align-items: center;
	${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
	${props => props.alignItems && `align-items: ${props.alignItems}`};
	${props => props.position && `position: ${props.position}`};
	${props => props.fullWidth && 'width: 100%'};
	${props => props.fullHeight && 'height: 100%'};
`;

export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
	${props => props.alignItems && `align-items: ${props.alignItems}`};
	${props => props.position && `position: ${props.position}`};
	${props => props.fullWidth && 'width: 100%'};
`;

export const FlexRowBase = css`
	display: flex;
	align-items: center;
`;

export const FlexColumnBase = css`
	display: flex;
	flex-direction: column;
`;

export const FlexColumnSpaceBetween = css`
	${FlexColumnBase};
	align-items: center;
	justify-content: space-between;
`;

export const FlexColumnAlignCenterCss = css`
	${FlexColumnBase};
	align-items: center;
`;
export const FlexRowSpaceBetweenCss = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const FlexRowCenterCss = css`
	display: flex;
	align-items: center;
	justify-content: center;
`;
