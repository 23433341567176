import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Selectors
import { getActiveExchange } from '../../../data/exchanges/selectors';

// Helpers
import { formatTradeTimeStamp } from '../../../lib/helpers';

// Constants
import { COLORS, FONT_SIZES } from '../../../lib/constants/Style';

// Atoms
import { TimesAndSalesTableWrapper } from '../OrderBook/OrderBook.Atoms';
import { BlockTitle } from '../../Atoms/Text';
import { MediumDivider } from '../../Atoms/Divider';
import { GridHeader } from './MarketDepthTable/MarketDepthTable.Atoms';
import { DEVICE } from '../../../lib/constants/Device';
import { List } from "immutable";

const TimesAndSalesGrid = styled.div`
	display: grid;
	grid-template-columns: 4fr 3fr 3fr;
	grid-template-rows: repeat(30, 3.333%);
	height: 100%;
	width: 100%;
	border-right: 1px solid ${COLORS.borderGray};
	&:last-child {
		border-right: none;
	}
`;

const TradePrice = styled.p`
	${props => props.higher && `color:${COLORS.green}`};
	${props => props.lower && `color:${COLORS.red}`};
	border-top: 1px solid ${COLORS.borderGray};
	
	&:first-of-type{
		border-top: unset;
	}
	
	display: flex;
	align-items: center;
`;

const TradeRow = styled.p`
	border-top: 1px solid ${COLORS.borderGray};
	display: flex;
	align-items: center;
	overflow: hidden;
`;

const FirstTradeRow = styled.div`
	font-weight: bold;
	display: flex;
	align-items: center;
	grid-row-start: 2;
	grid-row-end: span 2;
	border-top: 1px solid ${COLORS.borderGray};
	font-size: ${FONT_SIZES.base};
	
	@media ${DEVICE.laptopL} {
		font-size: ${FONT_SIZES.baseSmall};
	};
`;

const RowWrapper = styled.div`
	display: contents;
	font-size: ${FONT_SIZES.baseSmall};

	:last-child div {
		border-bottom: 1px solid ${COLORS.borderGray};
	}
	
	@media ${DEVICE.laptopL} {
		font-size: ${FONT_SIZES.small};
	};
`;

function TimesAndSalesTable({ data }) {
	return (
		<TimesAndSalesTableWrapper className="times-and-sales-table">
			<BlockTitle>Times & Sales</BlockTitle>
			<MediumDivider />
			<TimesAndSalesGrid>
				<GridHeader>Time</GridHeader>
				<GridHeader>Sale</GridHeader>
				<GridHeader>Size</GridHeader>
				{data?.map((row, i) => {
					if (i === 0) {
						return (
							<RowWrapper key={i.toString()}>
								<FirstTradeRow>
									<p>{formatTradeTimeStamp(row?.get('tradeTimestamp')?.get('seconds'), row?.get('tradeTimestamp')?.get('nanos'))}</p>
								</FirstTradeRow>
								<FirstTradeRow>
									<TradePrice higher={row.get('higher')} lower={row.get('lower')}>
										{row.get('tradePrice')}
									</TradePrice>
								</FirstTradeRow>
								<FirstTradeRow>
									<p>{row.get('tradeQuantity')}</p>
								</FirstTradeRow>
							</RowWrapper>
						);
					}
					return (
						<RowWrapper key={i.toString()}>
							<TradeRow>
								{formatTradeTimeStamp(row?.get('tradeTimestamp')?.get('seconds'), row?.get('tradeTimestamp')?.get('nanos'))}
							</TradeRow>
							<TradePrice higher={row.get('higher')} lower={row.get('lower')}>
								{row.get('tradePrice')}
							</TradePrice>
							<TradeRow>
								{row.get('tradeQuantity')}
							</TradeRow>
						</RowWrapper>
					);
				})}
			</TimesAndSalesGrid>
		</TimesAndSalesTableWrapper>
	);
}
TimesAndSalesTable.defaultProps = {
	data: List(),
};
TimesAndSalesTable.propTypes = {
	data: PropTypes.shape({}),
};

function mapStateToProps(state, props) {
	const activeExchange = getActiveExchange(state);
	const { bookId } = props;

	return {
		data: activeExchange.getIn(['orderBooks', Number(bookId), 'trades']),
	};
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({}, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(TimesAndSalesTable);
