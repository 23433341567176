import styled, { css } from 'styled-components';

const CheckedToggleCss = css`
    background-color: #ffc10e;
    transform: translateX(200%);
`;

export const ToggleWrapper = styled.div`
	width: 80px;
    padding: 7px;
    background-color: #f5f4f4;
    cursor: pointer;
`;

export const ToggleBox = styled.div`
	width: 22px;
    height: 22px;
    border-radius: 4px;
    background-color: #b5bcc2;
    transition: 0.3s;
    ${props => (props.checked ? CheckedToggleCss : '')}
`;
