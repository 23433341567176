import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// Atoms
import { CountersWrapper } from './OrderBook.Atoms';

// Molecules
import AllCountersTable from '../../Molecules/AllCountersTable';
import SpecificMemberCountersTable from '../../Molecules/SpecificMemberCountersTable';
// Selectors
import { getOrderBookCounters } from '../../../data/exchanges/selectors';
import { ScrollableContentWrapper } from '../../Atoms/Wrapper';
import Scrollbar from '../Scrollbar';

const CountersSection = (props) => {
	const {
		instanceId,
		bookId,
	} = props;
	const counters = useSelector(state => getOrderBookCounters(state, instanceId, bookId));

	const openBids = counters?.get('openBidsCount');
	const openOffers = counters?.get('openOffersCount');
	const totalTrades = counters?.get('totalTradesCount');
	const total = openBids + openOffers;
	return (
		<ScrollableContentWrapper className="counters-section">
			<Scrollbar nopadding>
				<CountersWrapper>
					<AllCountersTable
						openOrder={total}
						openBids={openBids}
						openOffers={openOffers}
						totalTrades={totalTrades}
					/>
					<SpecificMemberCountersTable
						memberBidsCount={counters?.get('memberBidsCount')}
						memberOffersCount={counters?.get('memberOffersCount')}
						memberTradesCount={counters?.get('memberTradesCount')}
					/>
				</CountersWrapper>
			</Scrollbar>
		</ScrollableContentWrapper>
	);
};
CountersSection.propTypes = {
	instanceId: PropTypes.string.isRequired,
	bookId: PropTypes.string.isRequired,
};
export default CountersSection;
