export default {
	FETCH_ORDER_BOOKS: 'FETCH_ORDER_BOOKS',
	FETCH_ORDER_BOOKS_SUCCESS: 'FETCH_ORDER_BOOKS_SUCCESS',
	FETCH_ORDER_BOOKS_FAIL: 'FETCH_ORDER_BOOKS_FAIL',
	ADD_NEW_ORDER_BOOK_SUCCESS: 'ADD_NEW_ORDER_BOOK_SUCCESS',
	ADD_NEW_ORDER_BOOK_FAIL: 'ADD_NEW_ORDER_BOOK_FAIL',
	EDIT_ORDER_BOOK_SUCCESS: 'EDIT_ORDER_BOOK_SUCCESS',
	EDIT_ORDER_BOOK_FAIL: 'EDIT_ORDER_BOOK_FAIL',
	FETCH_TRADING_GATEWAYS: 'FETCH_TRADING_GATEWAYS',
	FETCH_TRADING_GATEWAYS_SUCCESS: 'FETCH_TRADING_GATEWAYS_SUCCESS',
	FETCH_TRADING_GATEWAYS_FAIL: 'FETCH_TRADING_GATEWAYS_FAIL',
	ADD_NEW_TRADING_GATEWAY_SUCCESS: 'ADD_NEW_TRADING_GATEWAY_SUCCESS',
	ADD_NEW_TRADING_GATEWAY_FAIL: 'ADD_NEW_TRADING_GATEWAY_FAIL',
	EDIT_TRADING_GATEWAY_SUCCESS: 'EDIT_TRADING_GATEWAY_SUCCESS',
	EDIT_TRADING_GATEWAY_FAIL: 'EDIT_TRADING_GATEWAY_FAIL',
	DELETE_TRADING_GATEWAY_SUCCESS: 'DELETE_TRADING_GATEWAY_SUCCESS',
	DELETE_TRADING_GATEWAY_FAIL: 'DELETE_TRADING_GATEWAY_FAIL',
};
