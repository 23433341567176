import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

// Atoms
import { FlexColumnBase } from '../../Atoms/Flex';
import { BlockTitle } from '../../Atoms/Text';
import { MediumDivider, BaseDivider, SmallestDivider } from '../../Atoms/Divider';
import { ScrollWrapper, SubscriptionDetailItem, ToggleDetails } from './Atoms';
import Scrollbar from '../Scrollbar';

const Wrapper = styled.div`
	${FlexColumnBase};
	flex: 1;
	overflow: hidden;
	max-height: 40%;
	
	${BlockTitle} {
		padding-right: 20px;
	}

`;

const SubscriptionDetailsCurrent = (props) => {
	const {
		title,
		total,
		exchangeNames,
		detailedPayments,
	} = props;
	const [showContent, setShowContent] = useState(true);
	const renderAddons = detailedPayments => detailedPayments?.map((item, index) => {
		if (item.active === false) {
			return null;
		}
		const exchangePayNow = item.get('exchangePayNow');
		const sessionPayNow = item.get('sessionPayNow');
		if (exchangePayNow > 0 || sessionPayNow > 0) {
			return (
				<Fragment key={`subscription-details-current-item-${index}`}>
					<BaseDivider />
					<SubscriptionDetailItem>
						<span>{exchangeNames.get(item.get('exchangePaymentId'))}</span>
						<span>{exchangePayNow > 0 ? `$${exchangePayNow.toFixed(2)}` : ''}</span>
					</SubscriptionDetailItem>
					<SmallestDivider />
					<SubscriptionDetailItem>
						<span>
							Sessions:
							{item.get('sessionNumber')}
						</span>
						<span>
							$
							{sessionPayNow.toFixed(2)}
						</span>
					</SubscriptionDetailItem>
					<BaseDivider />
				</Fragment>
			);
		}
		return null;
	});
	return (
		<Wrapper>
			<SubscriptionDetailItem>
				<BlockTitle>
					{title}
				</BlockTitle>
				<BlockTitle>
					$
					{total && total.toFixed(2)}
				</BlockTitle>
			</SubscriptionDetailItem>
			<MediumDivider />
			<div>
				<ToggleDetails onClick={() => setShowContent(prevState => !prevState)}>
					{!showContent && 'Show Details'}
					{showContent && 'Hide Details'}
				</ToggleDetails>
			</div>
			{showContent && (
				<ScrollWrapper>
					<Scrollbar>
						{renderAddons(detailedPayments)}
					</Scrollbar>
				</ScrollWrapper>
			)}
		</Wrapper>
	);
};

export default SubscriptionDetailsCurrent;
