import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Layouts
import PrivateLayout from '../layouts/PrivateLayout';

// Pages
import Dashboard from '../pages/Dashboard';
import Subscription from '../pages/Subscription';
import WebsocketConnectionManager from '../pages/WebsocketConnectionRouter';
import SubscriptionInfo from '../pages/SubscriptionInfo';
import Registration from '../pages/Registration';

// Context
import { useAuth } from '../../components/Context/auth';

// Hoc
import withAllow from '../../components/Hoc/withAllow';

// Lib
import { userTypes } from '../../lib/constants/User';
import { getItem } from '../../lib/localStorage';
// Actions
import { addUserData } from '../../data/user/UserActions';

// Molecules
import Loader from '../../components/Molecules/Loader';
import MarketDataReply from '../pages/MarketDataReply';

const PrivateRouter = () => {
	const { userType, decideUserType } = useAuth();
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		if (getItem('loginInfo')) {
			dispatch(addUserData({ email: JSON.parse(getItem('loginInfo')).idTokenPayload.email }));
			decideUserType().finally(() => setLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!getItem('loginInfo')) {
		return <Redirect to="/auth/sign-in" />;
	}

	if (loading) {
		return <Loader loading />;
	}

	return (
		<PrivateLayout>
			<Switch>
				<Route
					exact
					path="/app/registration"
					component={withAllow(Registration, userType, [userTypes.FIRST_TIME_LOGIN])}
				/>
				<Route
					exact
					path="/app/subscription"
					component={withAllow(Subscription, userType, [userTypes.WITHOUT_SUBSCRIPTION, userTypes.FULL_ACCESS_USER])}
				/>
				<Route
					exact
					path="/app/subscription/subscription-info"
					component={withAllow(SubscriptionInfo, userType, [userTypes.WITHOUT_SUBSCRIPTION, userTypes.FULL_ACCESS_USER])}
				/>
				<Route
					exact
					path="/app/exchanges/:slug/market-data-replay"
					component={withAllow(MarketDataReply, userType, [userTypes.FULL_ACCESS_USER])}
				/>
				<Route
					path="/app/exchanges/:slug"
					component={withAllow(WebsocketConnectionManager, userType, [userTypes.FULL_ACCESS_USER])}
				/>
				<Route
					exact
					path="/app/exchanges"
					component={withAllow(Dashboard, userType, [userTypes.FULL_ACCESS_USER])}
				/>
				<Redirect to="/app/exchanges" />
			</Switch>
		</PrivateLayout>

	);
};

export default PrivateRouter;
