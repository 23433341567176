import React, { PureComponent, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';
// Atoms
import { ModalErrorMessage, ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import {
	MediumDivider,
	MediumVerticalDivider, BaseDivider,
} from '../../Atoms/Divider';
import { FullWidthFlexRow } from '../../Atoms/Wrapper';
// Molecules
import Modal from '../../Molecules/Modal';
import Input from '../../Molecules/Input/Input/Input';
import CheckBoxInput from '../../Molecules/Input/CheckBox/CheckBox';
// Actions
import { toggleEditTradingGatewayModal, toggleAddSessionModal } from '../../../data/ui/UIActions';
import { editTradingGateway, fetchTradingGateways } from '../../../data/tradingGateways/TradingGatewaysActions';

// Organisms
import SessionsTable from '../TableModels/SessionsTable';
import AddSessionModal from './AddSessionModal';
import EditSessionModal from './EditSessionModal';

class EditTradingGatewayModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			resetOnLogon: false,
			sessions: [],
			error: null,
		};
		this.name = createRef();
		this.port = createRef();
		this.overrideFields = createRef();
	}


	componentDidUpdate(prevProps, prevState, snapshot) {
		// Stop shows if we already updated initial state. If the selected trading gateways changes we should update it again with new tr gateway sessions
		if (prevProps.selectedTradingGateway !== this.props.selectedTradingGateway) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ stop: false });
		}
		// Update
		if (prevProps.selectedTradingGateway && !prevState.stop) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({
				resetOnLogon: prevProps.selectedTradingGateway?.get('resetOnLogon'),
				sessions: prevProps.selectedTradingGateway?.get('fixSessions')?.toJS(),
				stop: true,
			});
		}
	}

	deleteSessions = (ids) => {
		const {
			sessions,
		} = this.state;
		let newState = [...sessions];
		for (let i = 0; i < ids.length; i += 1) {
			const id = ids[i];
			newState = newState.filter(s => s.fixSessionId !== id);
		}
		this.setState({ sessions: newState });
	};

	editSession = (session) => {
		const {
			sessions,
		} = this.state;
		const index = sessions.findIndex(s => s.fixSessionId === session.fixSessionId);
		this.setState((prevState) => {
			const newState = [...prevState.sessions];
			newState[index] = session;
			return {
				sessions: newState,
			};
		});
	};

	addSession = (session) => {
		this.setState(prevState => ({
			sessions: [
				...prevState.sessions,
				session,
			],
		}));
	};

	handleEditTradingGateway = () => {
		const {
			actions,
			selectedTradingGateway,
			match,
		} = this.props;
		const {
			resetOnLogon,
			sessions,
		} = this.state;
		const fixSessions = sessions.map(session => ({
			fixSessionId: session.fixSessionId,
			targetCompId: session.targetCompId,
			senderCompId: session.senderCompId,
			targetSubId: session.targetSubId,
			senderSubId: session.senderSubId,
			targetLocationId: session.targetLocationId,
			senderLocationId: session.senderLocationId,
			startTime: session.startTime,
			stopTime: session.stopTime,
			heartbeatInterval: session.heartbeatInterval,
			reconnectInterval: session.reconnectInterval,
		}));
		const data = {
			trGwyId: selectedTradingGateway.get('trGwyId'),
			displayId: null,
			cancelOnDisconnect: false,
			name: this.name.current.getValue(),
			port: this.port.current.getValue(),
			overrideFields: this.overrideFields.current.getValue(),
			resetOnLogon,
			fixSessions,
		};
		actions.editTradingGateway(data).then(() => {
			actions.fetchTradingGateways(match.params.slug);
			this.handleCloseModal();
		}).catch((err) => {
			this.setState({ error: err?.response?.data?.message });
		});
	};

	handleCloseModal = () => {
		const {
			actions,
			selectedTradingGateway,
		} = this.props;
		actions.toggleEditTradingGatewayModal(false);
		this.setState({ sessions: selectedTradingGateway?.get('fixSessions')?.toJS() });
		this.setState({ error: null });
	};

	handleCancel = () => {
		const {
			selectedTradingGateway,
		} = this.props;
		this.handleCloseModal();
		// reset sessions to initial state if user cancels changes
	};

	handleResetOnLogonClick = () => {
		this.setState(prevState => ({
			resetOnLogon: !prevState.resetOnLogon,
		}));
	};


	render() {
		const {
			resetOnLogon,
			sessions,
			error,
		} = this.state;
		const {
			modalInfo,
			editSessionModalInfo,
			selectedTradingGateway,
		} = this.props;

		if (modalInfo?.get('visible') !== true) {
			return false;
		}
		const ids = editSessionModalInfo.get('data');
		const sessionForEdit = sessions?.find(s => s.fixSessionId === ids?.toJS()?.[0]);
		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleCloseModal}
				size={MODAL_SIZES.WIDE}
			>
				<FlexColumn>
					<ModalTitle>
						Edit TR Gateway
					</ModalTitle>
					{error && <ModalErrorMessage>{error}</ModalErrorMessage>}
					<MediumDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Name (max 40 chars)"
							type="header"
							name="name"
							ref={this.name}
							initialValue={selectedTradingGateway?.get('name')}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Port (integer > 1024)"
							type="header"
							name="instrumentType"
							ref={this.port}
							initialValue={selectedTradingGateway?.get('port')}
						/>
					</FullWidthFlexRow>
					<MediumDivider />
					<SessionsTable
						sessions={sessions}
						deleteSessions={this.deleteSessions}
					/>
					<MediumDivider />
					<FlexRow>
						<CheckBoxInput
							label="Reset sequence numbers on logon"
							value={resetOnLogon}
							onChange={this.handleResetOnLogonClick}
						/>
						<span>Reset sequence numbers on logon</span>
					</FlexRow>
					<MediumDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Override fields (tag=value;tag=value)"
							type="header"
							name="overrideFields"
							ref={this.overrideFields}
						/>
					</FullWidthFlexRow>
					<MediumDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={this.handleEditTradingGateway}>
							Update
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={this.handleCancel}>
							Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
				<AddSessionModal
					addSession={this.addSession}
				/>
				<EditSessionModal
					editSession={this.editSession}
					session={sessionForEdit}
				/>
			</Modal>
		);
	}
}
EditTradingGatewayModal.defaultProps = {
	selectedTradingGateway: null,
};
EditTradingGatewayModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		editTradingGateway: PropTypes.func,
		fetchTradingGateways: PropTypes.func,
		toggleAddSessionModal: PropTypes.func,
		toggleEditTradingGatewayModal: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
	selectedTradingGateway: PropTypes.shape({}),
	editSessionModalInfo: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.EDIT_TRADING_GATEWAY_MODAL]),
		editSessionModalInfo: state.getIn(['ui', 'modal', MODALS.EDIT_SESSION_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			editTradingGateway,
			fetchTradingGateways,
			toggleEditTradingGatewayModal,
			toggleAddSessionModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTradingGatewayModal));
