export const messageTrafficHeader = [
	{
		name: 'Timestamp', sortable: true, visible: true, width: '20%', path: ['timestamp'],
	},
	{
		name: 'Direction', sortable: true, visible: true, width: '10%', path: ['direction'], filter: true, filterValue: 'All',
	},
	{
		name: 'Sequence No', sortable: true, visible: true, width: '10%', path: ['seqNum'],
	},
	{
		name: 'Message Type', sortable: true, visible: true, width: '15%', path: ['messageType'], filter: true, filterValue: 'All',
	},
	{
		name: 'Session', sortable: true, visible: true, width: '30%', path: ['session'], filter: true, filterValue: 'All',
	},
	{
		name: 'Trading Gateway', sortable: true, visible: true, width: '15%', path: ['trdgwy'], filter: true, filterValue: 'All',
	},
];
