import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';

// Actions
import {
	fetchGeneralPrices,
	fetchPricesForActiveAddons,
	resetBillingItems,
} from '../../data/billing/BillingActions';
import { getFullInfoAboutExchanges } from '../../data/exchanges/ExchangesActions';
import { fetchExchangeTypeNames } from '../../data/exchanges/ExchangesActions';
// Atoms
import { Title } from '../Atoms/WebSite/Text';
import { FlexColumn, FlexRow } from '../Atoms/Flex';
import {
	Line, MediumDivider, SmallDivider, SmallestDivider,
} from '../Atoms/Divider';
import { PrimaryButtonLink } from '../Atoms/Link';

const Wrapper = styled.div`
	padding: 50px;
	margin: auto auto;
	display: flex;
	flex-direction: column;
	background: white;
	max-width: 600px;
	text-align: center;
`;

class SuccessfulSubscription extends PureComponent {
	async componentDidMount() {
		const {
			actions,
		} = this.props;
		actions.fetchGeneralPrices();
		actions.fetchExchangeTypeNames();
		await actions.getFullInfoAboutExchanges();
		await actions.fetchPricesForActiveAddons();
	}

	componentWillUnmount() {
		const {
			actions,
		} = this.props;
		actions.resetBillingItems();
	}

	renderItem = (item, exchangeNames) => {
		if (!item.get('active')) {
			return null;
		}
		return (
			<FlexColumn key={item.get('userAddonExchangeId')}>
				<SmallestDivider double />
				<FlexRow justifyContent="space-between">
				<span>
					{exchangeNames.get(item.get('type'))}
					{' '}
					exchange
				</span>
					<span>
					{`${item.get('sessions')} session${item.get('sessions') > 1 ? 's' : ''}`}
				</span>
				</FlexRow>
				<SmallestDivider double />
				<Line />
			</FlexColumn>
		);
	};

	render() {
		const {
			activeAddons,
			exchangeNames,
		} = this.props;

		return (
			<Wrapper>
				<Title>
					Successful Subscription
				</Title>
				<SmallDivider />
				<div>Your current plan includes the following exchanges:</div>
				<MediumDivider />
				<FlexColumn>
					<Line />
					{activeAddons.map(item => this.renderItem(item, exchangeNames))}
				</FlexColumn>
				<MediumDivider />
				<PrimaryButtonLink to="/app/exchanges">
					Go to exchanges
				</PrimaryButtonLink>
			</Wrapper>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		fetchPricesForActiveAddons,
		fetchGeneralPrices,
		getFullInfoAboutExchanges,
		resetBillingItems,
		fetchExchangeTypeNames,
	}, dispatch),
});


function mapStateToProps(state) {
	return {
		activeAddons: state.getIn(['billing', 'activeAddons', 'data']),
		exchangeNames: state.getIn(['exchange', 'exchangeNames']),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessfulSubscription);
