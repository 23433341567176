export const exchangesHeader = [
	{
		name: 'Instance ID', sortable: true, selectable: true, index: 0, width: '20%', path: ['displayId'], visible: true,
	},
	{
		name: 'Name', sortable: true, index: 1, width: '40%', path: ['name'], visible: true,
	},
	{
		name: 'Type', sortable: true, index: 2, width: '20%', path: ['exchangeType', 'name'], visible: true,
	},
	{
		name: 'Status', sortable: true, index: 3, width: '20%', path: ['machineInstance', 'status'], visible: true,
	},
];
