import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { OrderBookInfoCellSmaller } from '../Atoms/Table';

const MarketDataCell = (props) => {
	const {
		data,
		formattedData,
	} = props;
	const [className, setClassName] = useState('');

	// remember previous props
	const prevPropsRef = useRef();
	useEffect(() => {
		prevPropsRef.current = data;
	});
	const prevProps = prevPropsRef.current;

	useEffect(() => {
		if (formattedData === 'N/A') {
			return;
		}
		if (prevProps < data) {
			setClassName('higher');
			setTimeout(() => {
				setClassName('');
			}, 3000);
		} else if (prevProps > data) {
			setClassName('lower');
			setTimeout(() => {
				setClassName('');
			}, 3000);
		}
	}, [data, formattedData, prevProps]);
	return <OrderBookInfoCellSmaller className={className}>{formattedData}</OrderBookInfoCellSmaller>;
};
MarketDataCell.defaultProps = {
	data: null,
	formattedData: 'N/A',
};
MarketDataCell.propTypes = {
	data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	formattedData: PropTypes.string,
};
export default MarketDataCell;
