import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Selectors
import { getOrderBookTradeTickerData } from '../../data/exchanges/selectors';

// Atoms
import { MediumDivider } from '../Atoms/Divider';
import { InfoTable, OrderBookInfoCell, Wrapper } from '../Atoms/Table';
import { BlockTitle } from '../Atoms/Text';
import { ScrollableContentWrapper } from '../Atoms/Wrapper';

// Molecules
import MarketDataCell from './MarketDataCell';

// Organisms
import Scrollbar from '../Organisms/Scrollbar';

import { stringToNumber } from '../../lib/exchange';

function OrderBookTradeTickerTable(props) {
	const { bookId, instanceId } = props;

	const tradeTickerData = useSelector(state => getOrderBookTradeTickerData(state, instanceId, bookId));

	const change = tradeTickerData?.get('changeAbsolute');
	const changePercent = tradeTickerData?.get('changePct');
	const dayHigh = tradeTickerData?.get('high');
	const dayLow = tradeTickerData?.get('low');
	const open = tradeTickerData?.get('openingPrice');
	const prevClose = tradeTickerData?.get('lastClosingPrice');
	const price = tradeTickerData?.get('price');
	const volume = tradeTickerData?.get('totalVolume');

	return (
		<Wrapper>
			<BlockTitle>
				Trade Ticker
			</BlockTitle>
			<MediumDivider />
			<ScrollableContentWrapper>
				<Scrollbar>
					<InfoTable>
						<tbody>
							<tr>
								<OrderBookInfoCell>Price:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(price)}
									formattedData={(price === '-1.00' || price === undefined) ? 'N/A' : `$${price}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Volume:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(volume)}
									formattedData={(volume === '-1.00' || volume === undefined) ? 'N/A' : `${volume}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Open:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(open)}
									formattedData={(open === '-1.00' || open === undefined) ? 'N/A' : `$${open}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Prev Close:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(prevClose)}
									formattedData={(prevClose === '-1.00' || prevClose === undefined) ? 'N/A' : `$${prevClose}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Change:</OrderBookInfoCell>
								<MarketDataCell
									data={stringToNumber(change)}
									formattedData={(change === '-1.00' || change === undefined) ? 'N/A' : `$${change}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Change%:</OrderBookInfoCell>
								<MarketDataCell
									data={changePercent}
									formattedData={(changePercent === 0 || changePercent === undefined) ? 'N/A' : `${changePercent}%`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Day&apos;s high:</OrderBookInfoCell>
								<MarketDataCell
									data={dayHigh}
									formattedData={(dayHigh === 0 || dayHigh === undefined) ? 'N/A' : `$${dayHigh}`}
								/>
							</tr>
							<tr>
								<OrderBookInfoCell>Day&apos;s low:</OrderBookInfoCell>
								<MarketDataCell
									data={dayLow}
									formattedData={(dayLow === 0 || dayLow === undefined) ? 'N/A' : `$${dayLow}`}
								/>
							</tr>
						</tbody>
					</InfoTable>
				</Scrollbar>
			</ScrollableContentWrapper>
		</Wrapper>
	);
}

OrderBookTradeTickerTable.propTypes = {
	bookId: PropTypes.string.isRequired,
	instanceId: PropTypes.string.isRequired,
};

export default OrderBookTradeTickerTable;
