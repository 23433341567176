import React, { createRef, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';
// Actions
import { toggleEditOrderBookModal } from '../../../data/ui/UIActions';
import { editOrderBook, fetchOrderBooks } from '../../../data/orderBooks/OrderBooksActions';
// Atoms
import { ModalErrorMessage, ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import { MediumDivider, MediumVerticalDivider, BaseDivider } from '../../Atoms/Divider';
import { FullWidthFlexRow } from '../../Atoms/Wrapper';
// Molecules
import Modal from '../../Molecules/Modal';
import Input from '../../Molecules/Input/Input/Input';
import { getActiveExchangeOrderBooks } from '../../../data/exchanges/selectors';


class EditOrderBookModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
		};
		this.name = createRef();
		this.instrumentType = createRef();
		this.defaultOpeningPrice = createRef();
		this.viewCode = createRef();
		this.bbgCode = createRef();
		this.isinCode = createRef();
		this.ricCode = createRef();
	}

	handleEditOrderBook = () => {
		const {
			selectedOrderBook,
			actions,
			match,
		} = this.props;
		const data = {
			orderBookId: selectedOrderBook,
			name: this.name.current?.getValue(),
			isinCode: this.isinCode.current?.getValue(),
			defaultOpeningPrice: this.defaultOpeningPrice.current?.getValue(),
			type: 'EQUITY',
			viewCode: this.viewCode.current?.getValue(),
			bbgCode: this.bbgCode.current?.getValue(),
			ricCode: this.ricCode.current?.getValue(),
		};
		const clientExchangeId = match.params.slug;
		actions.editOrderBook(data)
			.then(() => {
				actions.toggleEditOrderBookModal(false);
				actions.fetchOrderBooks(clientExchangeId);
			})
			.catch((err) => {
				this.setState({ error: err?.response?.data?.message });
			});
	};

	handleCloseModal = () => {
		const {
			actions,
		} = this.props;
		this.setState({ error: null });
		actions.toggleEditOrderBookModal(false);
	};

	render() {
		const {
			error,
		} = this.state;
		const {
			modalInfo,
			actions,
			orderBook,
			selectedOrderBook,
		} = this.props;

		if (modalInfo?.get('visible') !== true) {
			return false;
		}

		const foundOrderBook = orderBook?.find(book => book.get('orderBookId') === selectedOrderBook);
		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleCloseModal}
				size={MODAL_SIZES.WIDE}
			>
				<FlexColumn>
					<ModalTitle>
						Edit Order Book
					</ModalTitle>
					{error && <ModalErrorMessage>{error}</ModalErrorMessage>}
					<MediumDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Name"
							type="header"
							name="name"
							ref={this.name}
							initialValue={foundOrderBook?.get('name')}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Instrument Type"
							type="header"
							name="instrumentType"
							ref={this.instrumentType}
							initialValue={foundOrderBook?.get('type')}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="Default Opening Price"
							type="header"
							name="openingPrice"
							ref={this.defaultOpeningPrice}
							initialValue={foundOrderBook?.get('defaultOpeningPrice')}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="View Code"
							type="header"
							name="viewCode"
							ref={this.viewCode}
							initialValue={foundOrderBook?.get('viewCode')}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="BBG Code (optional)"
							type="header"
							name="bbgCode"
							ref={this.bbgCode}
							initialValue={foundOrderBook?.get('bbgCode')}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="ISIN Code"
							type="header"
							name="isinCode"
							ref={this.isinCode}
							initialValue={foundOrderBook?.get('isinCode')}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="RIC Code (optional)"
							type="header"
							name="ricCode"
							initialValue={foundOrderBook?.get('ricCode')}
							ref={this.ricCode}
						/>
					</FullWidthFlexRow>
					<MediumDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={this.handleEditOrderBook}>
							Save
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={() => actions.toggleEditOrderBookModal(false)}>
							Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}

EditOrderBookModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleEditOrderBookModal: PropTypes.func,
		editOrderBook: PropTypes.func,
		fetchOrderBooks: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
	selectedOrderBook: PropTypes.number,
	orderBook: PropTypes.shape({}).isRequired,
};
EditOrderBookModal.defaultProps = {
	selectedOrderBook: -1,
};
function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.EDIT_ORDER_BOOK_MODAL]),
		orderBook: getActiveExchangeOrderBooks(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleEditOrderBookModal,
			editOrderBook,
			fetchOrderBooks,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOrderBookModal));
