import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// Atoms
import { TableCell, TableCellData, TableRow } from '../Atoms/TableAtoms';
// Molecules
import CheckBoxInput from '../../../Molecules/Input/CheckBox/CheckBox';
import { FlexRowFullHeight } from '../../../Atoms/Wrapper';

const TableRowComponent = (props) => {
	const {
		selectable,
		row,
		index,
		selected = false,
		handleSelectRow,
		rowClick,
		height,
		header,
		rowRef,
		selectedRow,
	} = props;

	const rowClickCallback = useCallback(() => {
		if (rowClick) {
			rowClick(row);
		}
	}, [row, rowClick]);
	return (
		<TableRow
			onClick={rowClickCallback}
			height={height}
			ref={rowRef}
			selected={selectedRow === index}
			clickable={!!rowClick}
		>
			{row.data.map((value, i) => {
				if (i === 0 && selectable) {
					return (
						<TableCell
							visible={header[i].visible}
							key={`${value}${i}${row?.key ?? row?.metaData?.key}`}
						>
							{selectable && (
								<FlexRowFullHeight>
									<CheckBoxInput
										value={selected}
										onChange={(e) => {
											handleSelectRow(e.target.checked, index);
										}}
									/>
									{typeof value?.render === 'function' ? value.render() : <p>{value}</p>}
								</FlexRowFullHeight>
							)}
						</TableCell>
					);
				}
				return (
					<TableCell
						visible={header[i]?.visible}
						key={`${value}${i}${row?.key ?? row?.metaData?.key}`}
					>
						<TableCellData>
							{typeof value?.render === 'function' ? value?.render() : <p>{value}</p>}
						</TableCellData>
					</TableCell>
				);
			})}
		</TableRow>
	);
};

TableRowComponent.defaultProps = {
	selectable: false,
	rowRef: null,
	selected: false,
	handleSelectRow: () => {
	},
	height: null,
	selectedRow: -1,
};
TableRowComponent.propTypes = {
	selectable: PropTypes.bool,
	row: PropTypes.shape({}).isRequired,
	index: PropTypes.number.isRequired,
	selected: PropTypes.bool,
	handleSelectRow: PropTypes.func,
	rowClick: PropTypes.func.isRequired,
	height: PropTypes.string,
	header: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	rowRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	selectedRow: PropTypes.number,
};
export default TableRowComponent;
