import React from 'react';
import PropTypes from 'prop-types';
import TableRowComponent from './TableRow';
import ContextMenuTableRowComponent from './ContextMenuTableRowComponent';

const TableData = (props) => {
	const {
		rows,
		selectable,
		handleSelectRow,
		rowClick,
		height,
		header,
		withContextMenu,
		selectedRow
	} = props;
	const TableRow = withContextMenu ? ContextMenuTableRowComponent : TableRowComponent;
	return (
		<tbody>
			{rows.map((row, i) => (
				<TableRow
					selectedRow={selectedRow}
					header={header}
					key={row?.metaData?.key ?? row.key}
					index={i}
					row={row}
					rowClick={rowClick}
					selected={row?.metaData?.selected ?? row.selected}
					selectable={selectable}
					handleSelectRow={handleSelectRow}
					height={height}
				/>
			))}
		</tbody>
	);
};

TableData.defaultProps = {
	height: null,
	withContextMenu: false,
	handleSelectRow: () => {},
	selectedRow: -1,
};

TableData.propTypes = {
	header: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	rows: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.shape({})),
		PropTypes.shape({}),
	]).isRequired,
	rowClick: PropTypes.func.isRequired,
	selectable: PropTypes.bool.isRequired,
	handleSelectRow: PropTypes.func,
	height: PropTypes.string,
	withContextMenu: PropTypes.bool,
	selectedRow: PropTypes.number,
};
export default TableData;
