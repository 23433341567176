import { fromJS } from 'immutable';
import UI_TYPES from './UITypes';

import { MODALS } from '../../lib/constants/General';

const initialState = fromJS({
	modal: {
		[MODALS.CREATE_EXCHANGE_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.EXCHANGE_DICTIONARY_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.MESSAGE_TRAFFIC_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.ADD_EXCHANGE_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.STOP_EXCHANGE_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.CHOOSE_EXCHANGE_EXTENSION]: {
			visible: false,
			data: {},
		},
		[MODALS.ADD_ORDER_BOOK_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.EDIT_ORDER_BOOK_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.ADD_TRADING_GATEWAY_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.EDIT_TRADING_GATEWAY_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.ADD_SESSION_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.BILLING_VALIDATION_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.ADD_ORDER_BLOTTER_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.EDIT_SESSION_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.MODIFY_ORDER_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.CHANGE_BEHAVIOR_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.CONTACT_FORM_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.MultiFillModal]: {
			visible: false,
			data: {},
		},
		[MODALS.STARTING_EXCHANGE_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.PENDING_SUBSCRIPTION_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.SHORTCUTS_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.MESSAGE_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.LOADING_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.DELETE_CONFIRMATION_MODAL]: {
			visible: false,
			data: {},
		},
		[MODALS.SESSION_EXPIRE_MODAL]: {
			visible: false,
			data: {},
		},

	},
});

export default function UIReducer(state = initialState, action) {
	switch (action.type) {
	case UI_TYPES.TOGGLE_MODAL: {
		const visible = typeof action.payload.visible !== 'undefined' ? action.payload.visible : !state.getIn(['modal', 'ui', 'visible']);

		const {
			data = null,
		} = action.payload;

		const item = state.getIn(['modal', action.payload.modal]).toJS();
		item.visible = visible;
		item.data = data;

		return state.setIn(['modal', action.payload.modal], fromJS(item));
	}
	case UI_TYPES.UPDATE_MODAL: {
		const { modal, data } = action.payload;
		return state.mergeDeepIn(['modal', modal], fromJS(data));
	}
	default: {
		return state;
	}
	}
}
