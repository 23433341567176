import React, {
	memo, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
// Data
import { orderBookHeader } from '../../../data/placeholderData/allOrderBooksData';
// Atoms
import ExchangeStatus from '../../Atoms/ExchangeStatus';
import {
	LeftControls, RightControls, TableCommandsWrapper, TableWrapper,
} from '../Table/Atoms/TableAtoms';
import { GrayControlIcon } from '../../Atoms/ControlIcons';
import { MediumVerticalDivider } from '../../Atoms/Divider';
import { AlternateLink } from '../../Atoms/Link';
import { RelativeWrapper } from '../../Atoms/Wrapper';
import { InputManager } from '../ExchangeBehavior/Atoms';
import { ImportButton } from '../../Atoms/Button';
// Molecules
import SearchFiled from '../../Molecules/Input/SearchField/SearchField';
// Organisms
import TableView from '../Table/TableView';
import AddOrderBookModal from '../Modals/AddOrderBookModal';
import EditOrderBookModal from '../Modals/EditOrderBookModal';
// Hooks
import useSearch from '../../Hooks/useSearch';
import useSortBy from '../../Hooks/useSortBy';
import useSelect from '../../Hooks/useSelect';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';
// Actions
import { toggleAddOrderBookModal, toggleEditOrderBookModal, toggleModal } from '../../../data/ui/UIActions';
import { addNewOrderBook, deleteOrderBook, fetchOrderBooks } from '../../../data/orderBooks/OrderBooksActions';
// Context
import WebSocketConnectionContext from '../../Context/WebsocketConnectionContext';
// Selectors
import {
	getActiveExchange,
} from '../../../data/exchanges/selectors';
// Lib
import { parseCsvAsync, showError } from '../../../lib/helpers';
import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';
import { requestOrderBookStatus } from '../../../lib/websockets';
import LoaderModal from '../Modals/LoaderModal';
import { MODALS } from '../../../lib/constants/General';
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal';

function OrderBooksTable(props) {
	// redux data
	const {
		orderBooks,
		fixVersion,
		exchangeStatus,
	} = props;

	// local state
	const [tableRows, setTableRows] = useState([]);
	const [tableHeader, setTableHeader] = useState([...orderBookHeader]);
	const [importProgress, setImportProgress] = useState(0);
	const initialRows = useMemo(() => orderBooks.toList().toJS(), [orderBooks]);
	// dispatch
	const dispatch = useDispatch();

	const { match } = props;
	const WebsocketContext = useContext(WebSocketConnectionContext);

	// Fetch necessary data
	useEffect(() => {
		fetchOrderBooks(match.params.slug)(dispatch);
	}, [dispatch, match.params.slug]);


	useEffect(() => {
		setTableRows(initialRows);
	}, [initialRows, orderBooks]);

	// Request data for order book status over WebSockets
	useEffect(() => {
		const {
			connected,
			client,
			sessionId,
		} = WebsocketContext;
		if (connected) {
			const orderBookIds = orderBooks.toList().map(item => item.get('viewCode')).toJS();
			requestOrderBookStatus(client, fixVersion, sessionId, orderBookIds);
		}
		// If activeExchanges status changes then you should request status about order books again
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [WebsocketContext.connected]);


	// @NOTE Table specific logic for mapping data to table rows
	const getDataToRender = useCallback(r => r.map((row, i) => ({
		data: [
			row.viewCode,
			{
				render: () => <AlternateLink to={`/app/exchanges/${match.params.slug}/order-book/${row.orderBookId}/graph`}>{row.name}</AlternateLink>,
				accessor: row.name,
			},
			row?.isinCode || 'N/A',
			row?.bbgCode || 'N/A',
			row?.ricCode || 'N/A',
			row.displayId,
			row.type,
			row.phase?.phaseName || 'N/A',
			{
				render: () => <ExchangeStatus status={row.status} />,
				accessor: row.status,
			},
		],
		metaData: {
			selected: false,
			key: row.orderBookId,
			index: i,
			link: `/app/exchanges/${match.params.slug}/order-book/${row.orderBookId}/order-entry`,
		},
	})), [match.params.slug]);

	// Hooks for table manipulation
	const { searchInputValue, setSearchInputValue } = useSearch(initialRows, setTableRows, tableHeader);
	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, tableRows);
	const { selectRowCallback, handleSelectAll, allSelected } = useSelect(dataToRender, setDataToRender);

	// Selected ids, used on edit and delete actions
	const selectedOrderBookIds = useMemo(() => dataToRender
		.filter(row => row.metaData.selected)
		.map((item => item.metaData.key)), [dataToRender]);


	// Callback for deleting order book
	const handleDeleteOrderBook = useCallback(() => {
		dispatch(toggleModal(MODALS.DELETE_CONFIRMATION_MODAL, true, { title: 'Delete Order Book(s)', message: 'Please confirm that you would like to delete the selected order book(s)' }));
	}, [dispatch]);

	const handleHeaderVisibilityChange = useCallback((header) => {
		setTableHeader((prevState) => {
			const newState = [...prevState];
			const index = newState.findIndex(item => item.name === header);
			if (!newState[index].required) {
				newState[index].visible = !newState[index].visible;
			} else {
				showError('This field is required', '');
			}
			return newState;
		});
	}, []);

	const handleImport = useCallback(async (event) => {
		const { files } = event.target;
		const file = files[0];
		const data = await parseCsvAsync(file);
		const promises = data.map((book, i) => {
			const orderBookData = {
				defaultOpeningPrice: book.DEFAULT_OPENING_PRICE,
				isinCode: book.ISIN_CODE,
				name: book.NAME,
				type: book.TYPE,
				viewCode: book.VIEW_CODE,
				bbgCode: book.BBG_CODE,
				ricCode: book.RIC_CODE,
			};
			return new Promise((resolve, reject) => {
				// add timeout so requests don't get blocked by server
				setTimeout(() => {
					addNewOrderBook(match.params.slug, orderBookData)(dispatch).then(response => resolve(response)).catch((e) => {
						showError('Error', 'Cant create book');
						reject(e);
					}).finally(() => {
						const progress = 100 / data.length;
						setImportProgress(prevState => prevState + progress);
					});
				}, i * 300);
			});
		});
		dispatch(toggleModal(MODALS.LOADING_MODAL, true, { title: 'Importing order books' }));
		await Promise.allSettled(promises);
		fetchOrderBooks(match.params.slug)(dispatch);
		// Just to be sure that modal will be closed
		dispatch(toggleModal(MODALS.LOADING_MODAL, false));
		// Reset import progress
		setImportProgress(0);
	}, [dispatch, match.params.slug]);

	const handleAddOrderBookClick = useCallback(() => {
		// can't create new book in this case
		dispatch(toggleAddOrderBookModal());
	}, [dispatch]);
	const handleEditOrderBookClick = useCallback(() => {
		// can't edit book in this case
		if (selectedOrderBookIds?.[0]) {
			dispatch(toggleEditOrderBookModal());
		}
	}, [dispatch, selectedOrderBookIds]);
	const handleRowClick = useCallback((r) => {
		dispatch(push(r.metaData?.link));
	}, [dispatch]);
	const actionButtonsDisabled = !exchangeStatus || (exchangeStatus !== EXCHANGE_STATUS.STOPPED && exchangeStatus !== EXCHANGE_STATUS.ERROR);

	return (
		<TableWrapper className="order-books-tab">
			<TableCommandsWrapper>
				<LeftControls>
					<GrayControlIcon
						name="plusCircle"
						className="order-books-add-icon"
						onClick={handleAddOrderBookClick}
						disabled={actionButtonsDisabled}
						data-tip="Create order book"
					/>
					<MediumVerticalDivider />
					<GrayControlIcon
						name="editPen"
						onClick={handleEditOrderBookClick}
						disabled={actionButtonsDisabled}
						data-tip="Edit order book"
					/>
					<MediumVerticalDivider />
					<GrayControlIcon
						name="trashCan"
						onClick={handleDeleteOrderBook}
						disabled={actionButtonsDisabled}
						data-tip="Delete order books"
					/>
					<MediumVerticalDivider />
					<RelativeWrapper
						style={{ overflow: 'hidden' }}
					>
						<GrayControlIcon
							className="order-books-import-button"
							name="download"
							disabled={actionButtonsDisabled}
						/>
						<InputManager
							active={!actionButtonsDisabled}
							data-tip="Import order books"
						>
							<ImportButton
								onChange={event => handleImport(event).catch()}
								value=""
								type="file"
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
							/>
						</InputManager>
					</RelativeWrapper>
				</LeftControls>
				<RightControls>
					<SearchFiled
						value={searchInputValue}
						onChange={setSearchInputValue}
						placeholder="Search"
					/>
				</RightControls>
			</TableCommandsWrapper>
			<TableView
				rowClick={handleRowClick}
				sortedBy={sortedBy}
				header={tableHeader}
				rows={dataToRender}
				hasSettings
				handleSort={handleSort}
				selectable
				handleSelectAll={handleSelectAll}
				handleSelectRow={selectRowCallback}
				allSelected={allSelected}
				handleHeaderVisibilityChange={handleHeaderVisibilityChange}
			/>
			<AddOrderBookModal />
			<EditOrderBookModal
				selectedOrderBook={selectedOrderBookIds?.[0]}
			/>
			<LoaderModal
				percentage={importProgress}
			/>
			<DeleteConfirmationModal onConfirm={() => deleteOrderBook(selectedOrderBookIds)(dispatch)} />
		</TableWrapper>
	);
}
OrderBooksTable.defaultProps = {
	exchangeStatus: null,
};
OrderBooksTable.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
	orderBooks: PropTypes.shape({}).isRequired,
	fixVersion: PropTypes.string.isRequired,
	exchangeStatus: PropTypes.string,
};
function mapStateToProps(state) {
	const activeExchange = getActiveExchange(state);
	return {
		orderBooks: activeExchange.get('orderBooks'),
		fixVersion: activeExchange.get('exchangeType')?.get('code'),
		exchangeStatus: activeExchange.get('status'),
	};
}
export default memo(withRouter(connect(mapStateToProps, null)(OrderBooksTable)));
