import React from 'react';
import { Helmet } from 'react-helmet/es/Helmet';
import PropTypes from 'prop-types';

const DocumentTitle = ({ title }) => (
	<Helmet>
		<title>{title}</title>
	</Helmet>
);

DocumentTitle.propTypes = {
	title: PropTypes.string,
};

DocumentTitle.defaultProps = {
	title: 'ETP Markets',
};

export default DocumentTitle;
