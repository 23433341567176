import { createSelector } from 'reselect';

const getDataFromState = data => data;

export const getMessages = createSelector(getDataFromState, messages => messages?.map(message => ({
	data: [message.get('name'), message.get('tag')],
	content: message.get('content'),
	name: message.get('name'),
	tag: message.get('tag'),
	key: message.get('name'),
	metaData: message,
})));

export const getComponents = createSelector(getDataFromState, components => components?.map(component => ({
	data: [component.get('name'), component.get('tag')],
	content: component.get('content'),
	name: component.get('name'),
	tag: component.get('tag'),
	key: component.get('name'),
	metaData: component,
})));

export const getFields = createSelector(getDataFromState, fields => fields?.map(field => ({
	data: [field.get('name'), field.get('tag')],
	name: field.get('name'),
	tag: field.get('tag'),
	key: field.get('name'),
	metaData: field,
})));
