import React, { PureComponent, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

// Atoms
import { ModalErrorMessage, ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import {
	MediumDivider,
	MediumVerticalDivider, BaseDivider,
} from '../../Atoms/Divider';
import { FullWidthFlexRow } from '../../Atoms/Wrapper';
// Molecules
import Modal from '../../Molecules/Modal';
import Input from '../../Molecules/Input/Input/Input';
// Actions
import { toggleEditSessionModal } from '../../../data/ui/UIActions';
import { fetchOrderBooks } from '../../../data/orderBooks/OrderBooksActions';

class EditSessionModal extends PureComponent {
	constructor(props) {
		super(props);
		this.senderCompId = createRef();
		this.targetCompId = createRef();
		this.senderSubId = createRef();
		this.targetSubId = createRef();
		this.senderLocationId = createRef();
		this.targetLocationId = createRef();
		this.heartbeatInterval = createRef();
		this.reconnectInterval = createRef();
		this.startTime = createRef();
		this.stopTime = createRef();
	}

	handleEditSession = () => {
		const {
			modalInfo,
			editSession,
		} = this.props;
		const id = modalInfo.get('data').toJS()?.[0];
		const session = {
			fixSessionId: id,
			targetCompId: this.targetCompId.current.getValue(),
			senderCompId: this.senderCompId.current.getValue(),
			targetSubId: this.targetSubId.current.getValue(),
			senderSubId: this.senderSubId.current.getValue(),
			targetLocationId: this.targetLocationId.current.getValue(),
			senderLocationId: this.senderLocationId.current.getValue(),
			startTime: this.startTime.current.getValue(),
			stopTime: this.stopTime.current.getValue(),
			heartbeatInterval: this.heartbeatInterval.current.getValue(),
			reconnectInterval: this.reconnectInterval.current.getValue(),
		};
		editSession(session);
		this.handleCloseModal();
	};

	handleCloseModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleEditSessionModal(false);
	};

	render() {
		const {
			modalInfo,
			addOrderBookError,
			session,
		} = this.props;

		if (modalInfo?.get('visible') !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleCloseModal}
				size={MODAL_SIZES.SMALL}
			>
				<FlexColumn>
					<ModalTitle>
						Edit Session
					</ModalTitle>
					{addOrderBookError && <ModalErrorMessage>{addOrderBookError}</ModalErrorMessage>}
					<MediumDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="SenderCompID"
							type="header"
							name="name"
							ref={this.senderCompId}
							initialValue={session?.senderCompId}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="TargetCompID"
							type="header"
							name="name"
							ref={this.targetCompId}
							initialValue={session?.targetCompId}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="SenderSubID"
							type="header"
							name="instrumentType"
							ref={this.senderSubId}
							initialValue={session?.senderSubId}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="TargetSubId"
							type="header"
							name="openingPrice"
							ref={this.targetSubId}
							initialValue={session?.targetSubId}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="SenderLocationID"
							type="header"
							name="viewCode"
							ref={this.senderLocationId}
							initialValue={session?.senderLocationId}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="TargetLocationID"
							type="header"
							name="bbgCode"
							ref={this.targetLocationId}
							initialValue={session?.targetLocationId}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="HeartBeatInterval"
							type="header"
							name="isinCode"
							ref={this.heartbeatInterval}
							initialValue={session?.heartbeatInterval}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="ReconnectInterval"
							type="header"
							name="ricCode"
							ref={this.reconnectInterval}
							initialValue={session?.reconnectInterval}
						/>
					</FullWidthFlexRow>
					<MediumDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Start Time"
							type="header"
							name="isinCode"
							ref={this.startTime}
							initialValue={session?.startTime}
						/>
						<MediumVerticalDivider />
						<Input
							placeholder="Stop Time"
							type="header"
							name="ricCode"
							ref={this.stopTime}
							initialValue={session?.stopTime}
						/>
					</FullWidthFlexRow>
					<MediumDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={this.handleEditSession}>
							Save
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={this.handleCloseModal}>
							Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}

EditSessionModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleEditSessionModal: PropTypes.func.isRequired,
		fetchOrderBooks: PropTypes.func.isRequired,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
	addOrderBookError: PropTypes.string,
	session: PropTypes.shape({}).isRequired,
	editSession: PropTypes.func.isRequired,
};
EditSessionModal.defaultProps = {
	addOrderBookError: null,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.EDIT_SESSION_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleEditSessionModal,
			fetchOrderBooks,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditSessionModal));
