import React, {
	useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import WebSocketConnectionContext from '../../Context/WebsocketConnectionContext';
import { getActiveExchange } from '../../../data/exchanges/selectors';
import { ORDER_SIDE } from '../../../lib/constants/Exchange';
import { cancelOrder, hitOrder } from '../../../lib/websockets';
import { getRandomClientOrderId } from '../../../lib/exchange';
import { toggleModifyOrderModal, toggleMultiFillModal } from '../../../data/ui/UIActions';
import { COLORS } from '../../../lib/constants/Style';

const StyledOrderContextMenu = styled.div`
	position: fixed;
	direction: initial;
	top: ${props => props.top}px;
	left: ${props => props.left}px;
	background: ${COLORS.white};
	z-index: 999999999;
	display: flex;
	flex-direction: column;
	box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.14);
	min-width: 200px;
`;
const ContextMenuOption = styled.div`
	cursor: pointer;
	width: 100%;
	padding: 10px 20px 11px 20px;
	border-bottom: 1px solid ${COLORS.borderGray};
	
	&:last-child {
		border-bottom: none;
	}

	&:hover {
		background: ${COLORS.borderGray};
	}
`;
const OrderContextMenu = (props) => {
	const {
		top,
		left,
		order,
	} = props;
	const [containerHeight, setContainerHeight] = useState();
	const selectedMember = useSelector(state => getActiveExchange(state).get('selectedMember'));
	const WebsocketContext = useContext(WebSocketConnectionContext);
	const activeExchange = useSelector(getActiveExchange);
	const ref = useRef();
	const dispatch = useDispatch();
	useEffect(() => {
		setContainerHeight(ref?.current?.clientHeight);
	}, []);

	const handleOrderHit = () => {
		const fix = activeExchange.get('exchangeType').get('code');
		const data = {
			hit: true,
			memberId: order.get('memberId'),
			instrumentCode: order.get('instrumentCode'),
			side: order.get('side') === ORDER_SIDE.BUY ? ORDER_SIDE.SELL : ORDER_SIDE.BUY,
			ordType: order.get('ordType'),
			price: order.get('price'),
			quantity: order.get('quantity'),
			timeInForce: order.get('timeInForce'),
			// clientOrderId: new Date().getTime() + Math.random().toString().replace(/\./, ''),
			clientOrderId: order.get('clientOrderId'),
		};
		hitOrder(WebsocketContext.client, fix, WebsocketContext.sessionId, data);
	};

	const handleOrderCancel = () => {
		const fix = activeExchange.get('exchangeType').get('code');
		const data = {
			memberId: order.get('memberId'),
			instrumentCode: order.get('instrumentCode'),
			orderId: '',
			ordType: order.get('ordType'),
			origClientOrderId: order.get('clientOrderId'),
			clientOrderId: getRandomClientOrderId(),
		};
		cancelOrder(WebsocketContext.client, fix, WebsocketContext.sessionId, data);
	};

	const handleModifyClick = () => {
		dispatch(toggleModifyOrderModal(true, {
			order,
			fix: activeExchange.get('exchangeType').get('code'),
		}));
	};
	const handleOrderMultiFill = () => {
		dispatch(toggleMultiFillModal(true, {
			order,
			fix: activeExchange.get('exchangeType').get('code'),
		}));
	};
	const topValue = (Number(top) + containerHeight) > window.innerHeight ? Number(top) - containerHeight : Number(top);

	return (
		<StyledOrderContextMenu
			top={topValue}
			left={left}
			ref={ref}
			containerHeigh={containerHeight}
		>
			<ContextMenuOption onClick={handleOrderHit}>
				Hit
			</ContextMenuOption>
			<ContextMenuOption onClick={handleOrderMultiFill}>
				Multi-fill
			</ContextMenuOption>
			{selectedMember.key === order.get('memberId') && (
				<>
					<ContextMenuOption onClick={handleModifyClick}>
						Modify
					</ContextMenuOption>

					<ContextMenuOption onClick={handleOrderCancel}>
						Cancel
					</ContextMenuOption>
				</>
			)}
		</StyledOrderContextMenu>
	);
};
OrderContextMenu.propTypes = {
	// Top offset of the container returned from context menu event listener
	top: PropTypes.string.isRequired,
	// Left offset of the container
	left: PropTypes.string.isRequired,
	// Data of the selected order
	order: PropTypes.shape({}).isRequired,
};
export default OrderContextMenu;
