import Api from '../../lib/api';
import { getItem } from '../../lib/localStorage';
import BILLING_TYPES from './BillingTypes';
import { toggleBillingValidationModal } from '../ui/UIActions';
import { env } from '../../env';

function getBillingRequestData(addons) {
	const request = [];
	addons.map((item) => {
		if (item.get('active')) {
			const sessions = item.get('sessions') + (item.get('newSessions') || 0);
			const type = item.get('type');
			const exchangeId = item.get('exchangeId');
			return request.push({
				sessionNumber: sessions,
				exchangePaymentId: type,
				exchangeId,
				userAddonExchangeId: item.get('userAddonExchangeId') || '',
			});
		}
		return null;
	});
	return request;
}

// General Prices
export function fetchGeneralPricesInit() {
	return dispatch => dispatch({
		type: BILLING_TYPES.FETCH_GENERAL_PRICES,
	});
}

export function fetchGeneralPricesFail(error) {
	return dispatch => dispatch({
		type: BILLING_TYPES.FETCH_GENERAL_PRICES_FAIL,
		payload: error,
	});
}

export function fetchGeneralPricesSuccess(data) {
	return dispatch => dispatch({
		type: BILLING_TYPES.FETCH_GENERAL_PRICES_SUCCESS,
		payload: data,
	});
}

export function fetchGeneralPrices() {
	return async (dispatch) => {
		dispatch(fetchGeneralPricesInit());
		try {
			const generalPrices = await Api.get('payments/prices');
			dispatch(fetchGeneralPricesSuccess(generalPrices.data));
		} catch (e) {
			dispatch(fetchGeneralPricesFail(e));
		}
	};
}

// Active addon prices

export function fetchPricesForActiveAddonsInit() {
	return dispatch => dispatch({
		type: BILLING_TYPES.FETCH_PRICES_FOR_ACTIVE_ADDONS,
	});
}

export function fetchPricesForActiveAddonsFail(error) {
	return dispatch => dispatch({
		type: BILLING_TYPES.FETCH_PRICES_FOR_ACTIVE_ADDONS_FAIL,
		payload: error,
	});
}

export function fetchPricesForActiveAddonsSuccess(data) {
	return dispatch => dispatch({
		type: BILLING_TYPES.FETCH_PRICES_FOR_ACTIVE_ADDONS_SUCCESS,
		payload: data,
	});
}

export function fetchPricesForActiveAddons() {
	return async (dispatch) => {
		dispatch(fetchPricesForActiveAddonsInit());
		try {
			const activeAddons = await Api.get('payments/prices/active');
			dispatch(fetchPricesForActiveAddonsSuccess(activeAddons.data));
		} catch (e) {
			dispatch(fetchPricesForActiveAddonsFail(e));
		}
	};
}

// Exchanges & Sessions
export function resetBillingItems() {
	return dispatch => dispatch({
		type: BILLING_TYPES.RESET_BILLING_ITEMS,
	});
}

export function toggleBillingItemSelect(id) {
	return dispatch => dispatch({
		type: BILLING_TYPES.TOGGLE_BILLING_ITEM_SELECT,
		payload: id,
	});
}

export function toggleBillingItemsSelectAll() {
	return dispatch => dispatch({
		type: BILLING_TYPES.TOGGLE_BILLING_ITEMS_SELECT_ALL,
	});
}

// Calculate Prices

export function calculatePricesInit() {
	return dispatch => dispatch({
		type: BILLING_TYPES.CALCULATE_PRICES,
	});
}

export function calculatePricesFail(error) {
	return dispatch => dispatch({
		type: BILLING_TYPES.CALCULATE_PRICES_FAIL,
		payload: error,
	});
}

export function calculatePricesSuccess(prices) {
	return dispatch => dispatch({
		type: BILLING_TYPES.CALCULATE_PRICES_SUCCESS,
		payload: prices,
	});
}

export function calculatePrices() {
	return async (dispatch, getState) => {
		dispatch(calculatePricesInit());
		try {
			const addons = getState().getIn(['billing', 'activeAddons', 'data']);
			const prices = await Api.post('payments/prices', getBillingRequestData(addons));
			dispatch(calculatePricesSuccess(prices));
		} catch (e) {
			dispatch(calculatePricesFail(e));
		}
	};
}

export function addExchange(data) {
	return async (dispatch) => {
		await dispatch({
			type: BILLING_TYPES.ADD_EXCHANGE,
			payload: data,
		});
		return dispatch(calculatePrices());
	};
}

export function setNewSessionsForAddon(data) {
	return async (dispatch) => {
		await dispatch({
			type: BILLING_TYPES.SET_NEW_SESSIONS_FOR_ADDON,
			payload: data,
		});
		return dispatch(calculatePrices());
	};
}

export function restoreSelectedItems() {
	return async (dispatch) => {
		await dispatch({
			type: BILLING_TYPES.RESTORE_SELECTED_BILLING_ITEMS,
		});
		dispatch(calculatePrices());
	};
}

export function restoreBillingItem(item) {
	return async (dispatch) => {
		await dispatch({
			type: BILLING_TYPES.RESTORE_BILLING_ITEM,
			payload: item,
		});
		dispatch(calculatePrices());
	};
}

export function deleteSelectedItems() {
	return async (dispatch) => {
		await dispatch({
			type: BILLING_TYPES.DELETE_SELECTED_BILLING_ITEMS,
		});
		dispatch(calculatePrices());
	};
}

export function checkout(requestData) {
	return async (dispatch, getState) => {
		try {
			const addons = getState().getIn(['billing', 'activeAddons', 'data']);
			const data = requestData || getBillingRequestData(addons);

			const response = await Api.post('payments/checkout', {
				email: JSON.parse(getItem('loginInfo')).idTokenPayload.email,
				redirectUrl: `${env.REACT_APP_WEBSITE_URL}/app/subscription/subscription-info`,
				addonExchangesAudit: data,
			});
			if (response.data.redirectUrl) {
				return dispatch({
					type: BILLING_TYPES.NEW_PAYMENT_PLAN,
					payload: response.data.redirectUrl,
				});
			}
			if (response.data.message === 'Success Subscription') {
				return dispatch({
					type: BILLING_TYPES.SUCCESSFUL_SUBSCRIPTION,
				});
			}
			return dispatch({
				type: BILLING_TYPES.SUBSCRIPTION_FAIL,
				payload: response.data.message,
			});
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
}

export function validateAddons() {
	return async (dispatch, getState) => {
		try {
			const addons = getState().getIn(['billing', 'activeAddons', 'data']);
			const requestData = getBillingRequestData(addons);
			const validationInfo = await Api.post('payments/validate', requestData);
			if (validationInfo.data.message === 'OK') {
				dispatch(checkout(requestData));
			} else {
				dispatch(toggleBillingValidationModal(true, validationInfo.data.message));
			}
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
}

export function repeatSubscription() {
	return async () => {
		try {
			const request = await Api.post('payments/repeat', {
				redirectUrl: `${env.REACT_APP_WEBSITE_URL}/app/subscription/subscription-info`,
			});
			if (request.data.redirectUrl) {
				return request.data.redirectUrl;
			}
			return '';
		} catch (e) {
			console.log(e);
			throw e;
		}
	};
}

export function abortSubscription() {
	return async () => {
		try {
			await Api.delete('payments/abort');
		} catch (e) {
			console.log(e);
		}
	};
}
