import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Atoms
import { FlexRow } from '../../../Atoms/Flex';
import { SmallVerticalDivider } from '../../../Atoms/Divider';
import {
	CounterButton, CounterValue, InitialValue, MinusIcon, PlusIcon,
} from './Counter.atoms';

const Counter = (props) => {
	const {
		initialValue,
		onChange,
		active,
		newSessions,
		minValue = 0,
	} = props;
	const [counter, setCounter] = useState(newSessions);
	const [counterVisible, setCounterVisibility] = useState(false);
	return (
		<FlexRow>
			<CounterButton
				active={active}
				onClick={() => {
					if (initialValue) {
						if (
							(initialValue + counter > minValue)
								&&
							(
								(counter < 0 && Math.abs(counter) < initialValue) ||
								(counter >= 0 && initialValue > 0) ||
								(initialValue === 0 && counter > 0)
							)
						) {
							setCounter(counter - 1);
							onChange(counter - 1);
						}
						setCounterVisibility(true);
					} else {
						if (counter > 0) {
							setCounter(counter - 1);
							onChange(counter - 1);
						}
					}
				}}
			>
				<MinusIcon />
			</CounterButton>
			<CounterValue>
				{initialValue !== 0 && (
					<>
						<SmallVerticalDivider />
						<InitialValue>
							{initialValue}
						</InitialValue>
						<SmallVerticalDivider />
						{counter > 0 && (
							<p>{`+${counter}`}</p>
						)}
						{counter < 0 && (
							<p>{counter}</p>
						)}
					</>
				)}
				{!initialValue && (
					<>
						{counter > 0 && (
							<>{counter}</>
						)}
						{counter <= 0 && (
							<>{counter}</>
						)}
					</>
				)}
			</CounterValue>
			<CounterButton
				active={active}
				onClick={async () => {
					await setCounter(counter + 1);
					onChange(counter + 1);
					setCounterVisibility(true);
				}}
			>
				<PlusIcon name="plus" />
			</CounterButton>
		</FlexRow>
	);
};

Counter.defaultProps = {
	initialValue: '',
	active: true,
	newSessions: 0,
	onChange: () => {
	},
};

Counter.propTypes = {
	initialValue: PropTypes.number,
	active: PropTypes.bool,
	onChange: PropTypes.func,
	newSessions: PropTypes.number,
};

export default Counter;
