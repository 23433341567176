import USER_TYPES from './UserTypes';

export function addUserData(data) {
	return {
		type: USER_TYPES.SET_USER_DATA,
		payload: {
			data,
		},
	};
}

export function resetUser() {
	return {
		type: USER_TYPES.RESET_ALL,
	};
}
