import { Redirect } from 'react-router-dom';
import React from 'react';
import Loader from '../Molecules/Loader';
import { userTypes } from '../../lib/constants/User';

/**
 *
 * @param Component - Page Component to render if user is allowed to see it
 * @param userType - Type of the current user
 * @param types - All user types that can have access to Component
 * @returns function - React component that is rendered, either a passed one or redirection to something else
 */
const withAllow = (Component, userType, types) => {
	if (types.some(t => t === userType)) {
		return Component;
	}
	switch (userType) {
	case userTypes.WITHOUT_SUBSCRIPTION: {
		return () => <Redirect to="/app/subscription" />;
	}
	case userTypes.FULL_ACCESS_USER: {
		return () => <Redirect to="/app/exchanges" />;
	}
	case userTypes.FIRST_TIME_LOGIN: {
		return () => <Redirect to="/app/registration" />;
	}
	default: {
		return () => <Loader loading />;
	}
	}
};

export default withAllow;
