import React, { useEffect } from 'react';

export default function usePrepareDataForRender(getDataToRender, tableRows) {
	const [dataToRender, setDataToRender] = React.useState([]);
	useEffect(() => {
		const data = getDataToRender(tableRows);
		setDataToRender(data);
	}, [getDataToRender, setDataToRender, tableRows]);

	return { dataToRender, setDataToRender };
}
