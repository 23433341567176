import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { List } from 'immutable';
// Atoms
import { MarketDepthTableWrapper } from '../../OrderBook/OrderBook.Atoms';
import { BlockTitle } from '../../../Atoms/Text';
import { MediumDivider } from '../../../Atoms/Divider';
import { FlexRow } from '../../../Atoms/Flex';
import { MarketDepthGrid, GridHeader } from './MarketDepthTable.Atoms';

// Organisms
import MarketTableRow from './MarketTableRow';

// Constants
import { ORDER_SIDE } from '../../../../lib/constants/Exchange';

// Hooks
import { getActiveExchange } from '../../../../data/exchanges/selectors';

function MarketDepthTable({
	buyData, sellData, totalBuy, totalSell,
}) {
	return (
		<MarketDepthTableWrapper className="market-depth-table">
			<BlockTitle>Market depth</BlockTitle>
			<MediumDivider />
			<FlexRow fullWidth fullHeight>
				<MarketDepthGrid>
					<GridHeader>Time</GridHeader>
					<GridHeader>Bid size</GridHeader>
					<GridHeader>Bid</GridHeader>
					{buyData?.map((row, i) => (
						<MarketTableRow
							key={i}
							index={i}
							row={row}
							maxBidSize={totalBuy}
							orderSide={ORDER_SIDE.BUY}
						/>
					))}
				</MarketDepthGrid>
				<MarketDepthGrid style={{ direction: 'rtl' }}>
					<GridHeader>Time</GridHeader>
					<GridHeader>Offer size</GridHeader>
					<GridHeader>Offer</GridHeader>
					{sellData?.map((row, i) => (
						<MarketTableRow
							key={i}
							index={i}
							row={row}
							maxBidSize={totalSell}
							orderSide={ORDER_SIDE.SELL}
						/>
					))}
				</MarketDepthGrid>
			</FlexRow>
		</MarketDepthTableWrapper>
	);
}
MarketDepthTable.defaultProps = {
	sellData: List(),
	buyData: List(),
	totalBuy: 0,
	totalSell: 0,
};
MarketDepthTable.propTypes = {
	buyData: PropTypes.shape({}),
	sellData: PropTypes.shape({}),
	totalBuy: PropTypes.number,
	totalSell: PropTypes.number,
};

function mapStateToProps(state, props) {
	const activeExchange = getActiveExchange(state);
	const { bookId } = props;

	return {
		buyData: activeExchange.getIn(['orderBooks', Number(bookId)]).get('buyEntries'),
		sellData: activeExchange.getIn(['orderBooks', Number(bookId)]).get('sellEntries'),
		totalBuy: activeExchange.getIn(['orderBooks', Number(bookId)]).get('totalBuy'),
		totalSell: activeExchange.getIn(['orderBooks', Number(bookId)]).get('totalSell'),
	};
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({}, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(MarketDepthTable);
