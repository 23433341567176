import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../lib/constants/Style';
import { TextSmall } from '../Css/Css';
import { VIRTUAL_MACHINE_STATUS } from '../../lib/constants/Exchange';

export default function VMStatus(props) {
	const {
		status,
	} = props;
	switch (status) {
	case VIRTUAL_MACHINE_STATUS.RUNNING: {
		return <RunningStatus>{status}</RunningStatus>;
	}
	case VIRTUAL_MACHINE_STATUS.ARCHIVED:
	case VIRTUAL_MACHINE_STATUS.PENDING:
	case VIRTUAL_MACHINE_STATUS.RESTARTING:
	case VIRTUAL_MACHINE_STATUS.SUSPENDING:
	case VIRTUAL_MACHINE_STATUS.RESUMING:
	case VIRTUAL_MACHINE_STATUS.TERMINATING:
	case VIRTUAL_MACHINE_STATUS.ARCHIVING:
	case VIRTUAL_MACHINE_STATUS.RESTORING:
	{
		return <YellowStatus>{status}</YellowStatus>;
	}
	case VIRTUAL_MACHINE_STATUS.SUSPENDED:
	case VIRTUAL_MACHINE_STATUS.TERMINATED: {
		return <RedStatus>{status}</RedStatus>;
	}
	default: return <RedStatus>{status}</RedStatus>;
	}
}
VMStatus.defaultProps = {
	status: VIRTUAL_MACHINE_STATUS.NA,
};
VMStatus.propTypes = {
	status: PropTypes.string,
};
const RunningStatus = styled.div`
	padding: 7px 20px;	
	width: 83px;
  	border-radius: 3px;
  	background-color: #def7ef;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	${TextSmall};
  	color: ${COLORS.green};
  	font-weight: 600;
  	line-height: 1;
  	letter-spacing: 0.6px;
`;

const YellowStatus = styled(RunningStatus)`
	border: solid 1px ${COLORS.yellow};
	background-color: ${COLORS.lightestYellow};
	color: ${COLORS.yellow};
`;
const RedStatus = styled(RunningStatus)`
	border: solid 1px ${COLORS.red};
	background-color: ${COLORS.lightRed};
	color: ${COLORS.red};
`;
