import REPLAY_TYPES from './replayTypes';

function updateLastReplayEvent(payload) {
	return {
		type: REPLAY_TYPES.UPDATE_LAST_REPLAY_EVENT,
		payload,
	};
}

export default updateLastReplayEvent;
