import styled from 'styled-components';
import { BaseBlockStyle } from '../../Css/Css';
import { COLORS } from '../../../lib/constants/Style';
import { FlexColumnBase, FlexRowBase } from '../../Atoms/Flex';
import { DEVICE } from '../../../lib/constants/Device';

export const InfoWrapper = styled.div`
	flex: 2;
	${BaseBlockStyle};
	padding: 30px;
	margin-right: 25px;
`;

export const Label = styled.p`
	color: ${COLORS.darkGrey};
`;

export const ProgressWrapper = styled.div`
	flex: 3;
	${FlexColumnBase};
	${BaseBlockStyle};
	padding: 30px;
	display: flex;
`;

export const ProgressRow = styled.div`
	${FlexRowBase};
	width: 100%;
`;

export const ProgressLeft = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	margin-right: 10px;
	@media ${DEVICE.laptopL} {
		flex: 2;
	}
`;

export const ProgressRight = styled.div`
	flex: 3;
`;
