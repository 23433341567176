import styled, { css } from 'styled-components';

import {
	TextSuperBig, TextBig, TextBase, TextMedium, TextExtraBig,
} from '../../Css/Css';

import { COLORS, FONT_SIZES } from '../../../lib/constants/Style';
import { DEVICE } from '../../../lib/constants/Device';
import { alternateLink } from '../Link';

export const BigTitle = styled.h1`
	${TextSuperBig};
	margin-right: 40px;
	line-height: 1;

	@media ${DEVICE.mobileL} {
		${TextExtraBig}
	}
`;

export const BaseText = styled.p`
	${TextBase};
`;

export const BoldText = styled.span`
	font-weight: bold;
`;

export const Description = styled.p`
	${TextBase};
	line-height: 1.75;
	width: 90%;
	max-width: 720px;

	@media ${DEVICE.mobileL} {
		font-size: 14px;
	}

	@media ${DEVICE.mobileM} {
		width: 100%;
	}
`;

export const Title = styled.h2`
	${TextBig};
	line-height: 1.42;
	
	${props => props.light && css`
		color: white;
	`};

	@media ${DEVICE.mobileM} {
		${TextMedium}
	}
`;

export const BiggerTitle = styled.h1`
	font-size: 36px;
	line-height: 1.4;

	@media ${DEVICE.mobileM} {
		${TextMedium}
	};
`;

export const Quoted = styled.div`
	font-style: italic;
`;

export const GreyText = styled.span`
	${TextBase};
	color: ${COLORS.grey};
`;

export const ErrorMessage = styled.div`
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	${TextBase};
    color: #d73e3e;
`;

export const LargeExternalLink = styled.a`
	${alternateLink};
	font-size: ${FONT_SIZES.big};
	text-decoration: underline;
`;
