import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setActiveExchangeInstance, fetchSingleExchangeExtraData } from '../../data/exchanges/ExchangesActions';
import { getActiveExchangeId } from '../../data/exchanges/selectors';
import Loader from '../Molecules/Loader';

/**
 * Hoc for main part of the application that will provide all necessary data before running anything
 * @param Component - React component to wrap
 * @returns Component with all data provided
 */
export default function withData(Component) {
	class Wrapper extends React.PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				initialized: false,
			};
		}

		componentDidMount() {
			const {
				actions,
				match,
			} = this.props;
			const exchangeId = match.params.slug;

			actions.fetchSingleExchangeExtraData(exchangeId).then(() => {
				actions.setActiveExchangeInstance(exchangeId);
				this.setState({ initialized: true });
			});
		}

		render() {
			const {
				initialized,
			} = this.state;
			if (initialized) {
				return <Component {...this.props} />;
			}

			return <Loader loading />;
		}
	}
	function mapStateToProps(state) {
		return {
			activeExchangeId: getActiveExchangeId(state),
		};
	}
	function mapDispatchToProps(dispatch) {
		return {
			actions: bindActionCreators({
				setActiveExchangeInstance,
				fetchSingleExchangeExtraData,
			}, dispatch),
		};
	}


	return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
}
