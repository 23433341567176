import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useContextMenu from '../../../Hooks/useContextMenu';
// Atoms

// Molecules

// Organisms
import OrderContextMenu from '../../OrderBook/OrderContextMenu';
import TableRowComponent from './TableRow';


const ContextMenuTableRowComponent = (props) => {
	const {
		selectable,
		row,
		index,
		selected = false,
		handleSelectRow,
		rowClick,
		height,
		header,
		selectedRow,
	} = props;

	const ref = useRef();
	const {
		xPos, yPos, menu,
	} = useContextMenu(ref);

	return (
		<>
			<TableRowComponent
				rowRef={ref}
				index={index}
				selected={selected}
				handleSelectRow={handleSelectRow}
				rowClick={rowClick}
				height={height}
				header={header}
				selectable={selectable}
				row={row}
				selectedRow={selectedRow}
			/>
			{menu && (
				<OrderContextMenu
					top={yPos}
					left={xPos}
					order={row.metaData.order}
				/>
			)}
		</>
	);
};
ContextMenuTableRowComponent.defaultProps = {
	selectable: false,
	rowRef: null,
	selected: false,
	handleSelectRow: () => {},
	height: null,
};
ContextMenuTableRowComponent.propTypes = {
	selectable: PropTypes.bool,
	row: PropTypes.shape({}).isRequired,
	index: PropTypes.number.isRequired,
	selected: PropTypes.bool,
	handleSelectRow: PropTypes.func,
	rowClick: PropTypes.func.isRequired,
	height: PropTypes.string,
	header: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	rowRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),

};
export default ContextMenuTableRowComponent;
