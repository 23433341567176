import { css } from 'styled-components';
import { COLORS, FONT_SIZES } from '../../lib/constants/Style';

export const FontFamilyExo = css`
	font-family: 'Exo 2', sans-serif;
`;

export const BaseText = css`
	color: ${COLORS.lightBlack};
	line-height: 1.2;
	user-select: text;
`;

export const TextSmall = css`
	${BaseText};
	font-size: ${FONT_SIZES.small};
`;

export const TextBaseSmall = css`
	${BaseText};
	font-size: ${FONT_SIZES.baseSmall};
`;

export const TextBase = css`
	${BaseText};
	font-size: ${FONT_SIZES.base};
`;

export const TextMedium = css`
	${BaseText};
	font-size: ${FONT_SIZES.medium};
`;

export const TextBig = css`
	${BaseText};
	font-size: ${FONT_SIZES.big};
`;

export const TextLarge = css`
	${BaseText};
	font-size: ${FONT_SIZES.large};
`;

export const TextExtraBig = css`
	${BaseText};
	font-size: ${FONT_SIZES.extraBig};
`;

export const TextSuperBig = css`
	${BaseText};
	font-size: ${FONT_SIZES.superBig};
`;


// Alignment
export const AlignText = css`
	text-align: ${props => props.align || 'inherit'};
`;

// Overlays

export const BaseOverlay = css`
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	min-height: 100%;
`;

// Z-Index
export const ZIndex = {
	zero: css`
		z-index: 0;
	`,
	uiWrapper: css`
		z-index: 5;
	`,
	medium: css`
		z-index: 100;
	`,
	mediumOne: css`
		z-index: 105;
	`,
	mediumTwo: css`
		z-index: 110;
	`,
	high: css`
		z-index: 1000;
	`,
};

// Hover

// Transitions
export const BaseTransition = css`
	transition: all 0.2s ease-in-out;
`;

// Base Block Styles
export const BaseBlockStyle = css`
	border-radius: 4px;
	background-color: ${COLORS.white};
`;
