import React from 'react';
import { connect } from 'react-redux';
import {
	withRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Atoms
import { GlobalHotKeys } from 'react-hotkeys';
import { BigVerticalDivider, BaseDivider } from '../../components/Atoms/Divider';
import { LeftSideWrapper, RightSideWrapper, Wrapper } from '../../components/Atoms/Wrapper';
import { Placeholder, Tab, Tabs } from '../../components/Atoms/Tabs';

// Molecules
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import PhaseBar from '../../components/Organisms/PhaseBar/PhaseBar';
import VirtualMachineSection from '../../components/Organisms/ExchangeSections/VirtualMachineSection';
import ExchangeSection from '../../components/Organisms/ExchangeSections/ExchangeSection';
import OrderBooksTable from '../../components/Organisms/TableModels/OrderBooksTable';
import CoreComponentsTable from '../../components/Organisms/TableModels/CoreComponentsTable';
import StopExchangeModal from '../../components/Organisms/Modals/StopExchangeModal';
import TradingGatewaysTable from '../../components/Organisms/TableModels/TradingGatewaysTable';
// Actions
import {
	getFullInfoAboutExchanges,
	startExchange,
	stopExchange,
	setActiveExchangeInstance,
} from '../../data/exchanges/ExchangesActions';
import { toggleStopExchangeModal, toggleStartingExchangeModal, toggleModal } from '../../data/ui/UIActions';
// Selectors
import { getActiveExchange } from '../../data/exchanges/selectors';
// Context
import WebSocketConnectionContext from '../../components/Context/WebsocketConnectionContext';
import {
	requestExchangeClose,
	requestExchangeOpen,
	requestExchangeStop,
} from '../../lib/websockets';
import { showError } from '../../lib/helpers';
import { handleButtonStatus } from '../../lib/exchange';
import StartingExchangeModal from '../../components/Organisms/Modals/StartingExchangeModal';
import { NoContentInfo } from '../../components/Atoms/Text';
import { MODALS } from '../../lib/constants/General';
import MessageModal from '../../components/Organisms/Modals/MessageModal';
import { EXCHANGE_STATUS } from '../../lib/constants/Exchange';

class ExchangeInstance extends React.Component {
	constructor(props) {
		super(props);
		this.handlers = {
			START_EXCHANGE: this.handleStartExchange,
			STOP_EXCHANGE: this.toggleStopExchangeModal,
			OPEN_EXCHANGE: this.handleOpenExchange,
			CLOSE_EXCHANGE: this.handleCloseExchange,
			MOVE_PHASE: () => console.log('MOVEPHASE'),
			CONFIGURE_LATENCY: () => console.log('CONFIGURELATENCY'),
		};
		this.keyMap = {
			START_EXCHANGE: 'e+g',
			STOP_EXCHANGE: 'e+s',
			OPEN_EXCHANGE: 'e+o',
			CLOSE_EXCHANGE: 'e+c',
			MOVE_PHASE: 'e+p',
			CONFIGURE_LATENCY: 'e+l',
		};
	}

	componentDidMount() {
		const {
			actions,
			match,
		} = this.props;
		// Ensure we have ActiveExchangeInstanceId set if we navigate to another ExchangeInstance
		actions.setActiveExchangeInstance(match.params.slug);
	}

	preventAction = () => {
		const {
			messageModalVisibility,
			addOrderBookModalVisibility,
			editOrderBookModalVisibility,
			loadingModalVisibility,
			addTradingGatewayModalVisibility,
			editTradingGatewayModalVisibility,
			deleteConfirmationModal,
		} = this.props;
		return (messageModalVisibility
			|| addOrderBookModalVisibility
			|| editOrderBookModalVisibility
			|| loadingModalVisibility
			|| addTradingGatewayModalVisibility
			|| editTradingGatewayModalVisibility
			|| deleteConfirmationModal
		);
	};

	handleStopExchange = () => {
		if (this.preventAction()) {
			return;
		}
		const {
			actions,
			exchangeStatus,
			fix,
		} = this.props;
		const {
			client,
			sessionId,
		} = this.context;
		const {
			stop,
		} = handleButtonStatus(exchangeStatus);
		if (!stop) {
			showError('Error', `Exchange ${exchangeStatus}`);
			return;
		}
		requestExchangeStop(client, fix, sessionId);
		actions.toggleStopExchangeModal(false);
	};


	handleOpenExchange = () => {
		if (this.preventAction()) {
			return;
		}
		const {
			client,
			sessionId,
		} = this.context;
		const {
			fix,
			exchangeStatus,
		} = this.props;

		const {
			open,
		} = handleButtonStatus(exchangeStatus);
		if (!open) {
			showError('Error', `Exchange ${exchangeStatus}`);
			return;
		}
		if (client && client.isConnected()) {
			requestExchangeOpen(client, fix, sessionId);
		} else {
			showError('Error', 'You are not connected');
		}
	};

	handleCloseExchange = () => {
		if (this.preventAction()) {
			return;
		}
		const {
			client,
			sessionId,
		} = this.context;
		const {
			exchangeStatus,
			fix,
		} = this.props;
		const {
			close,
		} = handleButtonStatus(exchangeStatus);
		if (!close) {
			showError('Error', `Exchange ${exchangeStatus}`);
			return;
		}
		if (client && client.isConnected()) {
			requestExchangeClose(client, fix, sessionId);
		} else {
			showError('Error', 'You are not connected');
		}
	};

	handleStartExchange = () => {
		if (this.preventAction()) {
			return;
		}
		const {
			match, actions, exchangeStatus,
		} = this.props;
		const {
			start,
		} = handleButtonStatus(exchangeStatus);
		if (!start) {
			showError('Error', `Exchange ${exchangeStatus}`);
			return;
		}
		actions.startExchange(match.params.slug).then((response) => {
			actions.toggleStartingExchangeModal(true);
			if (response.data?.tradingGatewaysInfo.length > 0) {
				setTimeout(() => {
					actions.toggleModal(MODALS.MESSAGE_MODAL, true, {
						title: 'Subscription Limit Exceeded',
						data: response.data.tradingGatewaysInfo,
					});
				}, 700);
			}
		});
	};

	toggleStopExchangeModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleStopExchangeModal();
	};

	render() {
		const {
			match,
			actions,
			name,
			displayId,
		} = this.props;
		return (
			<PageWrapper
				breadcrumbs={[
					{
						link: `exchanges/${match.params.slug}`,
						name: displayId,
					},
				]}
				title={name}
			>
				<DocumentTitle title={`${displayId} - Exchange instance`} />
				<PhaseBar />
				<Wrapper>
					<GlobalHotKeys handlers={this.handlers} keyMap={this.keyMap} allowChanges>
						<LeftSideWrapper>
							<ExchangeSection
								handleOpenClick={this.handleOpenExchange}
								handleCloseClick={this.handleCloseExchange}
								handleStartClick={this.handleStartExchange}
								handleStopClick={this.toggleStopExchangeModal}
							/>
							<BaseDivider />
							<VirtualMachineSection />
						</LeftSideWrapper>
						<BigVerticalDivider />
						<RightSideWrapper className="main-tabs-container">
							<Tabs>
								<Tab
									replace
									to={`/app/exchanges/${match.params.slug}/ob-table`}
								>
									<p>Order Books</p>
								</Tab>
								<Tab
									replace
									to={`/app/exchanges/${match.params.slug}/tg-table`}
								>
									<p>Trading Gateways</p>
								</Tab>
								<Tab
									replace
									to={`/app/exchanges/${match.params.slug}/mdg`}
								>
									<p>Market Data Gateways</p>
								</Tab>
								<Tab
									replace
									to={`/app/exchanges/${match.params.slug}/core-table`}
								>
									<p>Core Components</p>
								</Tab>
								<Placeholder />
							</Tabs>
							<Switch>
								<Route
									path="/app/exchanges/:slug/ob-table"
									component={OrderBooksTable}
								/>
								<Route
									path="/app/exchanges/:slug/tg-table"
									component={TradingGatewaysTable}
								/>
								<Route
									path="/app/exchanges/:slug/mdg"
									component={() => (
										<NoContentInfo className="md-gateways-tab">
											<p>Coming soon</p>
										</NoContentInfo>
									)}
								/>
								<Route
									path="/app/exchanges/:slug/core-table"
									component={CoreComponentsTable}
								/>
								<Redirect to="/app/exchanges/:slug/ob-table" />
							</Switch>
						</RightSideWrapper>
						<MessageModal />
						<StopExchangeModal
							onConfirmClick={this.handleStopExchange}
							onCancelClick={() => actions.toggleStopExchangeModal(false)}
						/>
					</GlobalHotKeys>
				</Wrapper>
				<StartingExchangeModal />
			</PageWrapper>
		);
	}
}

ExchangeInstance.defaultProps = {
	exchangeStatus: EXCHANGE_STATUS.NA,
};
ExchangeInstance.propTypes = {
	actions: PropTypes.shape({
		getFullInfoAboutExchanges: PropTypes.func,
		stopExchange: PropTypes.func,
		startExchange: PropTypes.func,
		toggleStopExchangeModal: PropTypes.func,
		setActiveExchangeInstance: PropTypes.func,
		toggleStartingExchangeModal: PropTypes.func,
		toggleModal: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
	exchangeStatus: PropTypes.string,
	displayId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	fix: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
	const activeExchange = getActiveExchange(state);
	return {
		exchangeStatus: activeExchange?.get('status'),
		displayId: activeExchange?.get('displayId'),
		name: activeExchange?.get('name'),
		fix: activeExchange?.get('exchangeType')?.get('code'),
		messageModalVisibility: state.getIn(['ui', 'modal', MODALS.MESSAGE_MODAL, 'visible']),
		addOrderBookModalVisibility: state.getIn(['ui', 'modal', MODALS.ADD_ORDER_BOOK_MODAL, 'visible']),
		editOrderBookModalVisibility: state.getIn(['ui', 'modal', MODALS.EDIT_ORDER_BOOK_MODAL, 'visible']),
		loadingModalVisibility: state.getIn(['ui', 'modal', MODALS.LOADING_MODAL, 'visible']),
		addTradingGatewayModalVisibility: state.getIn(['ui', 'modal', MODALS.ADD_TRADING_GATEWAY_MODAL, 'visible']),
		editTradingGatewayModalVisibility: state.getIn(['ui', 'modal', MODALS.EDIT_TRADING_GATEWAY_MODAL, 'visible']),
		deleteConfirmationModal: state.getIn(['ui', 'modal', MODALS.DELETE_CONFIRMATION_MODAL, 'visible']),
	};
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		getFullInfoAboutExchanges,
		stopExchange,
		startExchange,
		toggleStopExchangeModal,
		setActiveExchangeInstance,
		toggleStartingExchangeModal,
		toggleModal,
	}, dispatch),
});
ExchangeInstance.contextType = WebSocketConnectionContext;
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExchangeInstance));
