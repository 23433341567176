import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
// Dummy Data
import { marketReplyHeader } from '../../../data/placeholderData/marketReply';
// Styles
import { BaseBlockStyle } from '../../Css/Css';
import { SortableTableCSS } from '../../Css/Table';
// Organisms
import TableView from '../Table/TableView';
import { HeaderGroups } from '../Table/Moleculs/TableHeading';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';
import useSortBy from '../../Hooks/useSortBy';
import { TableHeadingItem, TableViewWrapper } from '../Table/Atoms/TableAtoms';

const MarketDataReplyTableWrapper = styled.div`
	${SortableTableCSS};
	${BaseBlockStyle};
	height: 100%;
	padding: 30px 15px 10px 30px;
  	box-shadow: 0px 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
  	display: flex;
  	flex-direction: column;
  	overflow: hidden;
  	${HeaderGroups}{
  	 	display: table-row
  	}
	${TableHeadingItem} {
		top: 25px!important
	}
`;

function MarketDataReplyTable(props) {
	const {
		data,
	} = props;
	const getDataToRender = useCallback(r => r.map((row, i) => ({
		data: row,
		metaData: {
			key: i,
		},
	})), []);

	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, data);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, marketReplyHeader, data);
	return (
		<MarketDataReplyTableWrapper className="md-replay-main-table">
			<TableView
				handleSort={handleSort}
				sortedBy={sortedBy}
				header={marketReplyHeader}
				rows={dataToRender}
				selectable={false}
				hasSettings={false}
				headerHeight="25px"
				rowHeight="32px"
			/>
		</MarketDataReplyTableWrapper>
	);
}

export default memo(MarketDataReplyTable);
