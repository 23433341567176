import React, { useRef, useState } from 'react';

// Lib
import { useAuth } from '../../../components/Context/auth';

// Atoms
import { GreyBackgroundWrapper } from '../../../components/Atoms/WebSite/Wrappers';
import { PrimaryButton } from '../../../components/Atoms/Button';
import {
	Title, GreyText, BaseText, ErrorMessage,
} from '../../../components/Atoms/WebSite/Text';
import { LargeDivider, SmallDivider } from '../../../components/Atoms/Divider';
import {
	Wrapper, FormWrapper, Form, SendAgainWrapper,
} from '../../../components/Atoms/WebSite/Auth';

// Molecules
import Input from '../../../components/Molecules/Input/Input/Input';

const ForgotPassword = () => {
	const { changePassword } = useAuth();
	const emailRef = useRef(null);
	const [authError, setAuthError] = useState(null);
	const [messageSent, setMessageSent] = useState(false);
	const [userEmail, setUserEmail] = useState(null);

	const handleSubmit = async () => {
		if (!emailRef.current.hasError()) {
			try {
				await changePassword(emailRef.current.getValue());
				setUserEmail(emailRef.current.getValue());
				setMessageSent(true);
			} catch (error) {
				setAuthError(error);
			}
			return null;
		}
		return null;
	};

	const sendAgain = async () => {
		try {
			await changePassword(userEmail);
		} catch (error) {
			setAuthError(error);
		}
	};

	if (messageSent) {
		return (
			<GreyBackgroundWrapper>
				<Wrapper center smaller>
					<SendAgainWrapper>
						<Title>Check Your Email</Title>
						{authError ? <ErrorMessage>{authError.message}</ErrorMessage> : <SmallDivider double />}
						<BaseText>
							<GreyText>
								We have sent a letter to your mail where you can reset your password.
								<br />
								If the letter does not arrive within 10 minutes, click the &quot;send again&quot; button.
							</GreyText>
						</BaseText>
						<LargeDivider />
						<SmallDivider double />
						<PrimaryButton onClick={sendAgain}>Send again</PrimaryButton>
						<LargeDivider />
					</SendAgainWrapper>
				</Wrapper>
			</GreyBackgroundWrapper>
		);
	}

	return (
		<GreyBackgroundWrapper>
			<Wrapper center smaller>
				<FormWrapper>
					<Form onSubmit={(event) => {
						event.preventDefault();
						handleSubmit();
					}}
					>
						<Title>Forgot Your Password?</Title>
						{authError ? <ErrorMessage>{authError.message}</ErrorMessage> : <SmallDivider double />}
						<BaseText>
							<GreyText>Enter your email address and we will send you a password reset link</GreyText>
						</BaseText>
						<LargeDivider />
						<Input
							ref={emailRef}
							type="email"
							name="email"
							placeholder="Email"
							validators={[
								{
									validator: 'isLength',
									options: {
										min: 1,
									},
									errorResult: false,
									message: 'Required field',
								},
								{
									validator: 'isEmail',
									errorResult: false,
									message: 'Email invalid',
								},
							]}
						/>
						<SmallDivider double />
						<PrimaryButton>Reset password</PrimaryButton>
					</Form>
				</FormWrapper>
			</Wrapper>
		</GreyBackgroundWrapper>
	);
};

export default ForgotPassword;
