import React from 'react';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Atoms
import {
	BaseCheckBox, CheckBoxLabel, CheckBoxWrapper, FontAwesomeWrapper,
} from './CheckBox.atoms';

const CheckBoxInput = (props) => {
	const { onChange, value } = props;

	return (
		<CheckBoxWrapper
			onClick={e => e.stopPropagation()}
		>
			<CheckBoxLabel>
				<BaseCheckBox
					value={value}
					checked={value}
					type="checkbox"
					onChange={(e) => {
						onChange(e);
					}}
				/>
				<FontAwesomeWrapper>
					<FontAwesomeIcon
						icon={faCheck}
						color={value ? '' : 'white'}
						size="xs"
					/>
				</FontAwesomeWrapper>
			</CheckBoxLabel>
		</CheckBoxWrapper>
	);
};

CheckBoxInput.defaultProps = {
	value: false,
};

CheckBoxInput.propTypes = {
	value: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default CheckBoxInput;
