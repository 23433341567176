import React from 'react';

// Constants
import { STATUS } from '../../../lib/constants/Style';

// Atoms
import { FlexRow } from '../../Atoms/Flex';
import {
	BaseVerticalDivider,
	BaseDivider,
	SmallestDivider,
	SmallVerticalDivider, BigVerticalDivider, MediumVerticalDivider
} from '../../Atoms/Divider';
import { Status } from '../../Atoms/Status';
import {
	BillingItemWrapper,
	CurrentPaymentData,
	CurrentPaymentWrapper,
	HelperText,
	InstanceData,
	InstanceWrapper, RestoreSection,
	SessionData,
	SessionWrapper,
} from './Atoms';
import { BlockTitle } from '../../Atoms/Text';

// Molecules
import CheckBoxInput from '../../Molecules/Input/CheckBox/CheckBox';
import Counter from '../../Molecules/Input/Counter/Counter';
import { GrayControlIcon } from '../../Atoms/ControlIcons';

export const BillingItem = (props) => {
	const {
		active,
		selected,
		name,
		type,
		exchangePrice,
		sessionPrice,
		sessions,
		onItemCounterChange,
		onItemSelectChange,
		userAddonExchangeId,
		currentPaymentDetails,
		onRestoreItem,
		newSessions,
	} = props;

	return (
		<BillingItemWrapper active={active}>
			<InstanceWrapper>
				<FlexRow>
					<CheckBoxInput
						value={selected}
						onChange={onItemSelectChange}
					/>
					<BlockTitle>{name || '[Exchange not created yet]'}</BlockTitle>
				</FlexRow>
				<BaseDivider />
				<InstanceData>
					<FlexRow>
						<HelperText>Type:</HelperText>
						<SmallVerticalDivider />
						<p>{type}</p>
					</FlexRow>
					<BigVerticalDivider />
					<FlexRow>
						<HelperText>Price:</HelperText>
						<SmallVerticalDivider />
						<p>${exchangePrice}</p>
					</FlexRow>
					<MediumVerticalDivider />
					{userAddonExchangeId && (
						<Status status={STATUS.PAID}>Paid</Status>
					)}
					{!userAddonExchangeId && (
						<Status status={STATUS.UNPAID}>Unpaid</Status>
					)}
				</InstanceData>
			</InstanceWrapper>
			<SessionWrapper>
				<SessionData>
					<p>Sessions</p>
					<BaseDivider />
					<FlexRow>
						<p>Price: ${sessionPrice}</p>
						<BaseVerticalDivider />
						<Counter
							newSessions={newSessions}
							active={active}
							initialValue={sessions}
							minValue={1}
							onChange={(counter) => {
								onItemCounterChange(counter);
							}}
						/>
					</FlexRow>
				</SessionData>
			</SessionWrapper>
			<CurrentPaymentWrapper>
				{active && (
					<CurrentPaymentData>
						<p>Current Payment</p>
						<BaseDivider />
						<SmallestDivider double />
						{currentPaymentDetails && (
							<p>${(currentPaymentDetails.get('sessionPayNow') + currentPaymentDetails.get('exchangePayNow')).toFixed(2)}</p>
						)}
					</CurrentPaymentData>
				)}
				{!active && (
					<RestoreSection>
						<Status status={STATUS.INACTIVE}>Pending Deletion</Status>
						<BaseVerticalDivider/>
						<GrayControlIcon
							name="restore"
							data-tip="Restore selected subscriptions"
							onClick={onRestoreItem}
						/>
					</RestoreSection>
				)}
			</CurrentPaymentWrapper>
		</BillingItemWrapper>
	);
};
