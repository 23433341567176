import React from 'react';
import { Circle, Line, Text } from '../Atoms';

export const PhaseCircle = (props) => {
	const {
		active,
		number,
		text,
		first,
	} = props;
	if (first) {
		return (
			<Circle active={active}>
				{number}
				<Text>{text}</Text>
			</Circle>
		);
	}
	return (
		<>
			<Line active={active} />
			<Circle active={active}>
				{number}
				<Text>{text}</Text>
			</Circle>
		</>
	);
};