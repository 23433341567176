import styled from 'styled-components';

import { COLORS } from '../../../lib/constants/Style';

export const TableWrapper = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	min-width: 525px;
`;

export const TableItem = styled.div`
	${props => (props.grey ? `background-color: ${COLORS.lightestGrey}` : '')};
	padding: 20px;
`;

export const OverflowWrapper = styled.div`
	overflow: auto;
`;
