export const ERROR_TYPES = {
	FETCH_EXCHANGES: 'fetchExchanges',
	FETCH_LOGGED_IN_USER: 'fetchLoggedInUser',
	UPDATE_LOGGED_IN_USER: 'updateLoggedInUser',
	// DEFINE GLOBAL ERRORS
	// ACTION: 'actionName',
};

export const ERRORS = {
	[ERROR_TYPES.FETCH_EXCHANGES]: {
		title: 'Error while Fetching Exchanges',
		description: '(Error details)',
	},
	[ERROR_TYPES.FETCH_LOGGED_IN_USER]: {
		title: 'Error while Loading User Data',
		description: '(Error details)',
	},
	[ERROR_TYPES.UPDATE_LOGGED_IN_USER]: {
		title: 'Error while Updating User Data',
		description: '(Error details)',
	},
};
