import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import {
	ToggleBox, ToggleWrapper,
} from './ToggleButton.atoms';

const ToggleButton = (props) => {
	const { checked, onClick } = props;
	return (
		<ToggleWrapper onClick={onClick}>
			<ToggleBox checked={checked} />
		</ToggleWrapper>
	);
};

ToggleButton.defaultProps = {
	checked: false,
};

ToggleButton.propTypes = {
	checked: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

export default ToggleButton;
