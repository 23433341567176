import React, {
	useCallback, useContext, useRef, useState,
} from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { MODAL_SIZES, MODALS } from '../../../../lib/constants/General';

import { toggleMultiFillModal } from '../../../../data/ui/UIActions';
// Atoms
import { ModalTitle } from '../../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../../Atoms/Button';
import {
	MediumDivider, MediumVerticalDivider, SmallDivider, SmallVerticalDivider,
} from '../../../Atoms/Divider';
// Molecules
import Modal from '../../../Molecules/Modal';
import { FullWidthFlexRow } from '../../../Atoms/Wrapper';
import Input from '../../../Molecules/Input/Input/Input';
import WebSocketConnectionContext from '../../../Context/WebsocketConnectionContext';
import TableView from '../../Table/TableView';
import { header } from '../../../../data/placeholderData/multiFillTable';
import usePrepareDataForRender from '../../../Hooks/usePrepareDataForRender';
import useSortBy from '../../../Hooks/useSortBy';
import { submitOrder } from '../../../../lib/websockets';
import EditRow from './MultiFillModal.Molecules';
import { GrayUnderlineText, MultiFillTableWrapper } from './MultiFillModal.Atoms';


function MultiFillModal(props) {
	const {
		modalInfo,
		modalData,
	} = props;
	const dispatch = useDispatch();
	const modalInfoJS = modalInfo?.toJS();
	const priceRef = useRef(null);
	const quantityRef = useRef(null);
	const [tableRows, setTableRows] = useState(List());
	const [editMode, setEditMode] = useState(false);
	const WebsocketContext = useContext(WebSocketConnectionContext);
	const handleClose = () => {
		dispatch(toggleMultiFillModal(false));
	};
	const handleSubmit = () => {
		const order = modalData.get('order');
		const fix = modalData.get('fix');
		tableRows.forEach((row) => {
			const price = row.get('price');
			const quantity = row.get('quantity');
			const data = {
				instrumentCode: order.get('instrumentCode'),
				side: order.get('side'),
				ordType: order.get('ordType'),
				price,
				quantity,
				clientOrderId: order.get('clientOrderId'),
				memberId: order.get('memberId'),
				timeInForce: order.get('timeInForce'),
			};
			submitOrder(WebsocketContext.client, fix, WebsocketContext.sessionId, data);
		});
		handleClose();
	};
	const clearAll = () => {
		priceRef.current.resetState();
		quantityRef.current.resetState();
		setEditMode(false);
	};
	const handleAddPosition = () => {
		const price = priceRef.current.getValue();
		const quantity = quantityRef.current.getValue();
		if (price === '' || quantity === '') {
			return;
		}
		setTableRows((prevState) => {
			const index = prevState.findIndex(item => item.get('quantity') === quantity);

			if (index !== -1) {
				return prevState.setIn([index, 'price'], price);
			}
			return prevState.push(
				Map()
					.set('price', price)
					.set('quantity', quantity)
			);
		});
		clearAll();
	};
	const handleDelete = (quantity) => {
		setTableRows((prevState) => {
			const index = prevState.findIndex(item => item.get('quantity') === quantity);
			if (index === -1) {
				return prevState;
			}
			return prevState.delete(index);
		});
	};
	const handleEdit = useCallback((quantity) => {
		const row = tableRows.find(item => item.get('quantity') === quantity);
		priceRef.current.setValue(row.get('price'));
		quantityRef.current.setValue(row.get('quantity'));
		setEditMode(quantity);
	}, [tableRows]);


	const handleEditPosition = () => {
		const price = priceRef.current.getValue();
		const quantity = quantityRef.current.getValue();
		if (price === '' || quantity === '') {
			return;
		}
		setTableRows((prevState) => {
			const index = prevState.findIndex(item => item.get('quantity') === editMode);
			if (index === -1) {
				return prevState;
			}
			return prevState.set(index, Map()
				.set('price', price)
				.set('quantity', quantity));
		});
		setEditMode(false);
		clearAll();
	};
	const getDataToRender = useCallback(r => r.map((row, i) => ({
		data: [
			row.get('price'),
			<EditRow
				handleDeleteClick={() => handleDelete(row.get('quantity'))}
				handleEdit={() => handleEdit(row.get('quantity'))}
			>
				{row.get('quantity')}
			</EditRow>,
		],
		metaData: {
			key: row.get('quantity'),
			index: i,
		},
	})), [handleEdit]);


	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, header, tableRows);
	if (modalInfoJS.visible !== true) {
		return <></>;
	}
	return (
		<Modal
			showModal={modalInfo?.get('visible')}
			closeModal={handleClose}
			size={MODAL_SIZES.WIDE}
		>
			<FlexColumn>
				<ModalTitle>
					Multi Fill
				</ModalTitle>
				<MediumDivider />
				<FullWidthFlexRow>
					<Input
						name="price"
						type="header"
						placeholder="Price"
						ref={priceRef}
					/>
					<SmallVerticalDivider />
					<Input
						name="quantity"
						type="header"
						placeholder="Quantity"
						ref={quantityRef}
					/>

				</FullWidthFlexRow>
				<SmallDivider />
				<SmallDivider />
				<FullWidthFlexRow />
				<FullWidthFlexRow>
					<GrayUnderlineText onClick={editMode ? handleEditPosition : handleAddPosition}>
						{editMode ? 'Edit' : 'Add position'}
					</GrayUnderlineText>
				</FullWidthFlexRow>
				<MediumDivider />
				<MultiFillTableWrapper>
					<TableView
						sortedBy={sortedBy}
						header={header}
						rows={dataToRender}
						hasSettings={false}
						handleSort={handleSort}
						selectable={false}
					/>
				</MultiFillTableWrapper>
				<MediumDivider />
				<span onClick={() => clearAll()}>Clear</span>
				<MediumDivider />
				<FlexRow justifyContent="center">
					<PrimaryButton onClick={handleSubmit}>
						Submit
					</PrimaryButton>
					<MediumVerticalDivider />
					<WhiteGrayButton onClick={handleClose}>
						Cancel
					</WhiteGrayButton>
				</FlexRow>
			</FlexColumn>
		</Modal>
	);
}

MultiFillModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleMultiFillModal: PropTypes.func,
	}).isRequired,
	modalData: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.MultiFillModal]),
		modalData: state.getIn(['ui', 'modal', MODALS.MultiFillModal]).get('data'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleMultiFillModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiFillModal));
