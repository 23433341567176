export default {
	FETCH_ORDER_BOOKS: 'FETCH_ORDER_BOOKS_START',
	FETCH_ORDER_BOOKS_SUCCESS: 'FETCH_ORDER_BOOKS_SUCCESS',
	FETCH_ORDER_BOOKS_FAIL: 'FETCH_ORDER_BOOKS_FAIL',
	ADD_NEW_ORDER_BOOK_SUCCESS: 'ADD_NEW_ORDER_BOOK_SUCCESS',
	ADD_NEW_ORDER_BOOK_FAIL: 'ADD_NEW_ORDER_BOOK_FAIL',
	EDIT_ORDER_BOOK_SUCCESS: 'EDIT_ORDER_BOOK_SUCCESS',
	EDIT_ORDER_BOOK_FAIL: 'EDIT_ORDER_BOOK_FAIL',
	CLEAR_ADD_ORDER_BOOK_ERROR: 'CLEAR_ADD_ORDER_BOOK_ERROR',
	DELETE_ORDER_BOOK_SUCCESS: 'DELETE_ORDER_BOOK_SUCCESS',
	DELETE_ORDER_BOOK_FAIL: 'DELETE_ORDER_BOOK_FAIL',
	// Clean store for other exchange
	CLEAR_ORDER_BOOKS: 'CLEAR_ORDER_BOOKS',
	// Remove book after delete
	REMOVE_BOOKS: 'REMOVE_BOOKS',
};
