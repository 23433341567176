export const header = [
	{
		name: 'Code', sortable: true, visible: true,
	},
	{
		name: 'Side', sortable: true, visible: true,
	},
	{
		name: 'Status', sortable: true, visible: true,
	},
	{
		name: 'Type', sortable: true, visible: true,
	},
	{
		name: 'Leaves Qty', sortable: true, visible: true,
	},
	{
		name: 'Price', sortable: true, visible: true,
	},
	{
		name: 'Avg Price', sortable: true, visible: true,
	},
	{
		name: 'Order ID', sortable: true, visible: true,
	},
	{
		name: 'Entered Time', sortable: true, visible: true,
	},

];
export const rows = [

];
export const tradesHeader = [
	{
		name: 'Exec Type', sortable: true, visible: true, width: '10%',
	},
	{
		name: 'Last Qty', sortable: true, visible: true, width: '10%',
	},
	{
		name: 'Cum Qty', sortable: true, visible: true, width: '10%',
	},
	{
		name: 'Leaves Qty', sortable: true, visible: true, width: '10%',
	},
	{
		name: 'Last price', sortable: true, visible: true, width: '10%',
	},
	{
		name: 'Avg price', sortable: true, visible: true, width: '10%',
	},
	{
		name: 'Exec ID', sortable: true, visible: true, width: '10%',
	},
	{
		name: 'Order ID', sortable: true, visible: true, width: '10%',
	},
	{
		name: 'Entered Time', sortable: true, visible: true, width: '10%',
	},
];
