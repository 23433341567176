import { createSelector } from 'reselect';
import { EXCHANGE_STATUS } from '../../lib/constants/Exchange';

export const getActiveExchangeId = state => state.getIn(['exchange', 'activeExchangeInstanceId']);

export const getExchanges = state => state.getIn(['exchange', 'exchanges']);

export const getAllExchangeStatuses = createSelector(getExchanges, exchange => ['All', ...new Set(exchange.toList()?.map(e => e.getIn(['machineInstance', 'status'])))]);

export const getActiveExchange = createSelector(getActiveExchangeId, getExchanges,
	(id, exchanges) => exchanges.get(Number(id)));

export const getActiveExchangeFixType = createSelector(getActiveExchange, ex => ex.get('exchangeType')?.get('code'));

export const getActiveExchangeOrderBooks = createSelector(getActiveExchange, exchange => exchange.get('orderBooks'));

export const getActiveExchangeTradingGateways = createSelector(getActiveExchange, exchange => exchange.get('tradingGateways'));

export const getActiveExchangeCoreComponents = createSelector(getActiveExchange, exchange => exchange.get('coreComponents'));

export const getUsersForActiveExchange = createSelector(getActiveExchange, (exchange) => {
	let fixSessions = [];
	// getAll sessions
	exchange.get('tradingGateways').toList().forEach((tr) => {
		fixSessions.push(...tr.get('fixSessions'));
	});
	// format
	fixSessions = fixSessions.map(session => ({
		render: `${session.get('senderCompId')} -> ${session.get('targetCompId')}`,
		key: session.get('senderCompId'),
	}));
	// Add default one
	fixSessions.unshift({
		render: '99999 (UI)',
		key: '99999',
	});
	return fixSessions;
});
export const getOrderBookBBBOData = (state, exchangeId, bookId) => state.getIn(['exchange', 'exchanges', Number(exchangeId), 'orderBooks', Number(bookId), 'bbbo']);

export const getOrderBookTradeTickerData = (state, exchangeId, bookId) => state.getIn(['exchange', 'exchanges', Number(exchangeId), 'orderBooks', Number(bookId), 'tradeTicker']);

export const getOrderBookCounters = (state, exchangeId, bookId) => state.getIn(['exchange', 'exchanges', Number(exchangeId), 'orderBooks', Number(bookId), 'counters']);

export const getOpenedComponents = createSelector(getActiveExchange, (e) => {
	const tr = e.get('tradingGateways').toList();
	const ob = e.get('orderBooks').toList();
	const core = e.get('coreComponents');
	const trCount = tr?.reduce((acc, value) => {
		if (value.get('status') === EXCHANGE_STATUS.OPENED) {
			return acc + 1;
		}
		return acc;
	}, 0);
	const obCount = ob?.reduce((acc, value) => {
		if (value.get('status') === EXCHANGE_STATUS.OPENED) {
			return acc + 1;
		}
		return acc;
	}, 0);
	const coreCount = core?.reduce((acc, value) => {
		if (value.get('status') === EXCHANGE_STATUS.OPENED) {
			return acc + 1;
		}
		return acc;
	}, 0);

	const total = trCount + obCount + coreCount;
	return isNaN(total) ? 0 : total;
});
export const getTotalComponents = createSelector(getActiveExchange, (e) => {
	const tr = e.get('tradingGateways').toList();
	const ob = e.get('orderBooks').toList();
	const core = e.get('coreComponents');

	return (tr?.size ?? 0) + (ob?.size ?? 0) + (core?.size ?? 0);
});
