import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Hooks
import useSortBy from '../../Hooks/useSortBy';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';

// Organisms
import TableView from '../Table/TableView';

const FieldEnumTable = ({ rows }) => {
	const getDataToRender = useCallback(r => r?.map(row => ({
		data: Object.values(row),
		key: row.value,
	})), []);

	const header = [{
		name: 'Value', sortable: true, index: 0, visible: true,
	},
	{
		name: 'Name', sortable: true, index: 1, visible: true,
	}];

	const [tableRows, setTableRows] = useState();
	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, header, tableRows);

	useEffect(() => {
		setTableRows(rows);
	}, [rows]);

	return (
		<TableView
			sortedBy={sortedBy}
			header={header}
			handleSort={handleSort}
			rows={dataToRender}
			selectable={false}
			hasSettings={false}
		/>
	);
};

FieldEnumTable.propTypes = {
	rows: PropTypes.oneOfType([
		PropTypes.shape({}),
		PropTypes.arrayOf(PropTypes.shape({})),
	]).isRequired,
};

export default FieldEnumTable;
