import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Atoms
import {
	SelectBoxWrapper, SelectBoxLabel, Select, Options, OptionWrapper, ArrowDown,
} from './SelectBox.atoms';

const SelectBox = (props) => {
	const {
		options,
		label,
		selected,
		onClick,
		backgroundColor,
		smaller,
		noBorder,
		className,
	} = props;

	const [openOptions, setOpenOptions] = useState(false);
	const [value, setValue] = useState(typeof selected === 'object' ? selected.render : selected);

	useEffect(() => {
		setValue(typeof selected === 'object' ? selected.render : selected);
	}, [selected]);

	const renderOptions = () => (
		<OptionWrapper
			showOptions={openOptions}
			backgroundColor={backgroundColor}
		>
			{options.map(option => (
				<Options
					key={option.key || option}
					onMouseDown={() => onClick(option)}
					smaller={smaller}
				>
					{option?.render || option}
				</Options>
			))}
		</OptionWrapper>
	);

	return (
		<SelectBoxWrapper
			backgroundColor={backgroundColor}
			noBorder={noBorder}
			className={className}
		>
			<Select
				onBlur={() => setOpenOptions(false)}
				onClick={() => setOpenOptions(state => !state)}
				value={value}
				smaller={smaller}
				className="select"
				readOnly
			/>
			{label && (
				<SelectBoxLabel smaller={smaller}>{label}</SelectBoxLabel>
			)}
			<ArrowDown smaller={smaller} className="arrow" />
			{renderOptions(smaller)}
		</SelectBoxWrapper>

	);
};

SelectBox.defaultProps = {
	label: null,
	selected: {},
	options: [],
	backgroundColor: null,
	smaller: false,
	noBorder: false,
	className: null,
};

SelectBox.propTypes = {
	className: PropTypes.string,
	smaller: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	selected: PropTypes.oneOfType([
		PropTypes.shape({
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			render: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		PropTypes.string,
	]),
	label: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string, PropTypes.number])),
	backgroundColor: PropTypes.string,
	noBorder: PropTypes.bool,
};

export default SelectBox;
