import React, { createRef, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODALS } from '../../../../lib/constants/General';
// Atoms
import { ModalTitle } from '../../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../../Atoms/Button';
import { MediumDivider, MediumVerticalDivider, BaseDivider } from '../../../Atoms/Divider';
import { FullWidthFlexRow } from '../../../Atoms/Wrapper';
import { InputManager } from '../../ExchangeBehavior/Atoms';
import { ResetButton } from './AddOrderBlotterModal.Atoms';
// Molecules
import Modal from '../../../Molecules/Modal';
import Input from '../../../Molecules/Input/Input/Input';
import SelectBox from '../../../Molecules/Input/SelectBox/SelectBox';
import { StyledDatePicker } from '../../../Molecules/StyledDatePicker';
import OrderEntryInput from './AddOrderBlotterModal.Molecules';
// Actions constants selectors etc...
import { toggleAddOrderBlotterModal } from '../../../../data/ui/UIActions';
import { getActiveExchange } from '../../../../data/exchanges/selectors';
import { OrderBlotterValidity, OrderBlotterValidityValueToSend } from '../../../../lib/constants/Modal';
import { submitOrder } from '../../../../lib/websockets';
import WebSocketConnectionContext from '../../../Context/WebsocketConnectionContext';
import { ORDER_TYPE } from '../../../../lib/constants/Exchange';
import { getRandomClientOrderId } from '../../../../lib/exchange';

class AddOrderBlotterModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			pickedDate: null,
			orderType: '',
			symbol: '',
			validity: '',
			selected: null,
		};
		this.quantity = createRef();
		this.price = createRef();
	}

	handleAddOrderBlotter = () => {
		const {
			activeExchange,
		} = this.props;
		const {
			client, sessionId,
		} = this.context;
		const {
			symbol, orderType, selected, validity,
		} = this.state;
		const data = {
			virtualServerNo: symbol,
			instrumentCode: symbol,
			side: selected,
			ordType: orderType,
			price: this.price.current.getValue(),
			quantity: this.quantity.current.getValue(),
			clientOrderId: getRandomClientOrderId(),
			memberId: '99999',
			timeInForce: OrderBlotterValidityValueToSend[validity],
		};
		submitOrder(client, activeExchange.get('exchangeType').get('code'), sessionId, data);
		this.handleCloseModal();
	};

	resetFormData = () => {
		this.setState({
			pickedDate: null,
			orderType: '',
			symbol: '',
			validity: '',
			selected: null,
		});
		this.quantity.current.resetState();
		this.price.current.resetState();
		this.clientOrderId.current.resetState();
	};

	handleCloseModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleAddOrderBlotterModal(false);
	};

	render() {
		const {
			modalInfo,
			activeExchange,
		} = this.props;
		const {
			orderType, pickedDate, symbol, validity, selected,
		} = this.state;
		if (modalInfo?.get('visible') !== true) {
			return false;
		}

		// maybe memoize this
		const orderBookViewCodes = activeExchange.get('orderBooks').toList().map(book => book.get('viewCode')).toJS();
		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleCloseModal}
			>
				<FlexColumn style={{ width: '600px' }}>
					<ModalTitle>
						Enter Order
					</ModalTitle>
					{/* {addOrderBookError && <ModalErrorMessage>{addOrderBookError}</ModalErrorMessage>} */}
					<MediumDivider />
					<FullWidthFlexRow>
						<OrderEntryInput
							selected={selected}
							setSelected={v => this.setState({ selected: v })}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Quantity"
							type="header"
							name="quantity"
							ref={this.quantity}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<SelectBox
							label="Symbol"
							options={orderBookViewCodes}
							selected={symbol}
							onClick={v => this.setState({ symbol: v })}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<Input
							placeholder="Price"
							type="header"
							name="openingPrice"
							ref={this.price}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<SelectBox
							label="Order Type"
							options={[ORDER_TYPE.LIMIT, ORDER_TYPE.MARKET]}
							selected={orderType}
							onClick={v => this.setState({ orderType: v })}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<SelectBox
							label="Validity"
							options={[OrderBlotterValidity.Day, OrderBlotterValidity.GTC, OrderBlotterValidity.GTD, OrderBlotterValidity.IOC, OrderBlotterValidity.FOK]}
							selected={validity}
							onClick={v => this.setState({ validity: v })}
						/>
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						<InputManager
							active={validity === OrderBlotterValidity.GTC || validity === OrderBlotterValidity.GTD}
						>
							<StyledDatePicker
								height
								big
								placeholder="Expiry Date"
								startDate={pickedDate}
								setStartDate={v => this.setState({ pickedDate: v })}
							/>
						</InputManager>
					</FullWidthFlexRow>
					<MediumDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={this.handleAddOrderBlotter}>
							Submit Order
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={this.handleCloseModal}>
							Cancel
						</WhiteGrayButton>
					</FlexRow>
					<MediumDivider />
					<ResetButton onClick={this.resetFormData}>
						Reset
					</ResetButton>
				</FlexColumn>
			</Modal>
		);
	}
}

AddOrderBlotterModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleAddOrderBlotterModal: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string.isRequired,
		}),
	}).isRequired,
	activeExchange: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.ADD_ORDER_BLOTTER_MODAL]),
		activeExchange: getActiveExchange(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleAddOrderBlotterModal,
		}, dispatch),
	};
}
AddOrderBlotterModal.contextType = WebSocketConnectionContext;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddOrderBlotterModal));
