import { Map } from 'immutable';
import moment from 'moment';
import REPLAY_TYPES from './replayTypes';
import { REPLAY_OPERATIONS, ORDER_SIDE } from '../../lib/constants/Exchange';

const initialState = Map({
	time: '',
	viewCode: '',
	type: '',
	side: '',
	quantity: '',
	price: '',
	memberIdBuy: '',
	memberIdSell: '',
	orderIdBuy: '',
	orderIdSell: '',
	clientOrderIdBuy: '',
	clientOrderIdSell: '',
	tradeId: '',
});

export default function replayReducer(state = initialState, action) {
	switch (action.type) {
	case REPLAY_TYPES.UPDATE_LAST_REPLAY_EVENT: {
		const { message, operation } = action.payload;
		let newState = null;
		let type = null;

		const timeStamp = moment(`${message.order.receivedDateTime.seconds.split(',').join('')}${message.order.receivedDateTime.nanos.toString().substring(0, 3)}`, 'x');

		if (operation === REPLAY_OPERATIONS.ADD || operation === REPLAY_OPERATIONS.AMEND) {
			type = operation === REPLAY_OPERATIONS.ADD ? 'O' : 'M';
			if (message.order.side === ORDER_SIDE.BUY) {
				newState = initialState.set('viewCode', message.order.instrumentCode)
					.set('type', type)
					.set('quantity', message.order.quantity)
					.set('price', message.order.price)
					.set('memberIdBuy', message.order.memberId)
					.set('orderIdBuy', message.order.orderId)
					.set('side', 'B')
					.set('clientOrderIdBuy', message.order.clientOrderId)
					.set('time', timeStamp.format('HH:mm:ss.SSS'));
			} else if (message.order.side === ORDER_SIDE.SELL) {
				newState = initialState.set('viewCode', message.order.instrumentCode)
					.set('type', type)
					.set('quantity', message.order.quantity)
					.set('price', message.order.price)
					.set('memberIdSell', message.order.memberId)
					.set('orderIdSell', message.order.orderId)
					.set('side', 'S')
					.set('time', timeStamp.format('HH:mm:ss.SSS'))
					.set('clientOrderIdSell', message.order.clientOrderId);
			}
		}

		if (operation === REPLAY_OPERATIONS.TRADE) {
			type = 'T';
			console.log(message, 'TRADE');
		}
		return newState;
	}
	default: {
		return state;
	}
	}
}
