import React, { useRef, useState } from 'react';
import useOnClickOutside from '../../../Hooks/useOnClickOutside';
import {
	EditIcon, EditOption, EditPopUp, StyledEditRow,
} from './MultiFillModal.Atoms';
import { SmallDivider } from '../../../Atoms/Divider';

const EditRow = (props) => {
	const { handleDeleteClick, handleEdit, children } = props;
	const [visible, setVisible] = useState(false);
	const ref = useRef(null);
	useOnClickOutside(ref, () => setVisible(false));
	return (
		<StyledEditRow ref={ref}>
			<div>
				{children}
			</div>
			<EditIcon name="ellipses" onClick={() => setVisible(true)} />
			{visible && (
				<EditPopUp>
					<EditOption onClick={handleEdit}>Edit</EditOption>
					<SmallDivider />
					<EditOption onClick={handleDeleteClick}>Delete</EditOption>
				</EditPopUp>
			)}
		</StyledEditRow>
	);
};


export default EditRow;
