import styled from 'styled-components';
import React from 'react';
import { COLORS } from '../../../../lib/constants/Style';
import { FlexRow, FlexRowCenterCss, FlexRowSpaceBetweenCss } from '../../../Atoms/Flex';
import { MediumVerticalDivider } from '../../../Atoms/Divider';
import { WhiteGrayButton } from '../../../Atoms/Button';
import { TextBase } from '../../../Css/Css';

const StyledShortCutRow = styled.div`
	border-top: 2px solid ${COLORS.borderGray};
	height: 39px;
	${FlexRowSpaceBetweenCss};
	width: 100%;
	${TextBase};
	color: ${COLORS.lightBlack};
	
`;
const KeyCircle = styled.div`
  	width: 28px;
  	height: 27px;
  	background-color: ${COLORS.lightestGrey};
  	${FlexRowCenterCss};
  	border-radius: 100%;
`;
const ShortCutsWrapper = styled.div`
	width: 100%;
	${StyledShortCutRow}:last-child{
		border-bottom: 2px solid ${COLORS.borderGray};
	}
`;
export const CloseButton = styled(WhiteGrayButton)`
	align-self: center;
`;
// TODO move this to molecules and write props types
export const ShortCuts = (props) => {
	const {
		shortcuts,
	} = props;
	return (
		<ShortCutsWrapper>
			{shortcuts.map(shortcut => (
				<StyledShortCutRow key={`shortcut-${shortcut.name}`}>
					<span>{shortcut.name}</span>
					<FlexRow>
						<KeyCircle>{shortcut.key1}</KeyCircle>
						<MediumVerticalDivider />
						<span>and</span>
						<MediumVerticalDivider />
						<KeyCircle>{shortcut.key2}</KeyCircle>
					</FlexRow>
				</StyledShortCutRow>
			))}
		</ShortCutsWrapper>
	);
};
