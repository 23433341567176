import React, { useRef } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

// Atoms
import { connect } from 'react-redux';
import { FlexRow } from '../../Atoms/Flex';
import {
	MediumDivider,
} from '../../Atoms/Divider';
import {
	InstanceSVG, SectionTitle, SettingsIcons, VirtualMachineWrapper,
} from './Atoms';
import { InfoTable } from '../../Atoms/Table';
import VMStatus from '../../Atoms/VMStatus';
import { InvisibleInput } from '../../Atoms/Input';
import { copyToClipboard } from '../../../lib/helpers';
import { getActiveExchange } from '../../../data/exchanges/selectors';

function VirtualMachineSection(props) {
	const {
		virtualMachine,
	} = props;
	const copyRef = useRef(null);
	return (
		<VirtualMachineWrapper
			className="vm-box-container"
		>
			<FlexRow justifyContent="space-between">
				<SectionTitle>
                    Virtual Machine
				</SectionTitle>
				<VMStatus status={virtualMachine?.get('status')} />
			</FlexRow>
			<MediumDivider />
			<InvisibleInput ref={copyRef} value={virtualMachine?.get('ipAddress') || ''} onChange={() => {}} />
			<FlexRow justifyContent="space-between">
				<InfoTable>
					<tbody>
						<tr>
							<td>IP Address:</td>
							<td>{virtualMachine?.get('ipAddress')}</td>
							<td><span onClick={() => copyToClipboard(copyRef.current)}>Copy</span></td>

						</tr>
						<tr>
							<td>RAM:</td>
							<td>{virtualMachine?.get('ram')}</td>
						</tr>
						<tr>
							<td>Cores:</td>
							<td>{virtualMachine?.get('processor')}</td>
						</tr>
						<tr>
							<td>Created by:</td>
							<td>Esprow</td>
						</tr>
						<tr>
							<td>Region:</td>
							<td>{virtualMachine?.get('location')}</td>
						</tr>
					</tbody>
				</InfoTable>
				<div>
					<InstanceSVG name="instance" />
				</div>
			</FlexRow>
		</VirtualMachineWrapper>
	);
}
VirtualMachineSection.propTypes = {
	virtualMachine: PropTypes.shape({}).isRequired,
};
function mapStateToProps(state) {
	return {
		virtualMachine: getActiveExchange(state)?.get('machineInstance'),
	};
}

export default withRouter(connect(mapStateToProps, null)(VirtualMachineSection));
