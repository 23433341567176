import React, {
	useState, useRef, useContext, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { toggleChangeBehaviorModal } from '../../../data/ui/UIActions';

// Lib
import {
	BEHAVIOR_OPTIONS, FILL_ORDERS_OPTIONS, FILL_UNIFORM, REJECTED_OPTIONS,
} from '../../../lib/constants/Behavior';
import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';

// Selector
import { getActiveExchangeFixType } from '../../../data/exchanges/selectors';

// WebSockets
import { exchangeBehaviorChangeRequest, exchangeBehaviorStatusRequest } from '../../../lib/websockets';

// Context
import WebSocketConnectionContext from '../../Context/WebsocketConnectionContext';

// Atoms
import { FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import { BaseVerticalDivider, BigVerticalDivider, SmallDivider } from '../../Atoms/Divider';
import { ModalErrorMessage } from '../../Atoms/Text';
import {
	RightContent, SettingsWrapper, Row, SelectOption, OptionWrapper, RejectOptionWrapper, ButtonWrapper, InputWrapper,
} from './Atoms';

// Molecules
import ToggleButton from '../../Molecules/Input/ToggleButton/ToggleButton';
import Input from '../../Molecules/Input/Input/Input';
import CheckBoxInput from '../../Molecules/Input/CheckBox/CheckBox';

// Organisms
import ChangeBehaviorModal from '../Modals/ChangeBehaviorModal';

const BehaviorSettings = ({ getState, exchangeStatus }) => {
	// redux
	const behavior = useSelector(state => state.getIn(['behavior', 'behavior']));
	const dispatch = useDispatch();

	// state
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedFillOrder, setSelectedFillOrder] = useState(null);
	const [fillUniformSelected, setFillUniformSelected] = useState(null);
	const [selectedRejectItems, setSelectedRejectItems] = useState([]);
	const [selectedRejectError, setSelectedRejectError] = useState(null);
	const [behaviorActive, setBehaviorActive] = useState(false);

	const [count, setCount] = useState('');
	const [percentage, setPercentage] = useState('');


	const [initialFillCountValue, setInitialFillCountValue] = useState('');
	const [initialFillPercentageValue, setInitialFillPercentageValue] = useState('');

	const [initialPartialFillCountValue, setInitialPartialFillCountValue] = useState('');
	const [initialPartialFillPercentageValue, setInitialPartialFillPercentageValue] = useState('');

	// context
	const WebsocketContext = useContext(WebSocketConnectionContext);
	const { client, connected, sessionId } = WebsocketContext;

	// selectors
	const fix = useSelector(state => getActiveExchangeFixType(state));

	// ref
	const countRef = useRef(null);
	const percentageRef = useRef(null);

	const setInitialRejectOption = (items) => {
		setSelectedRejectItems(items);
	};

	useEffect(() => {
		switch (behavior?.behavior.behaviorType.toLowerCase()) {
		case BEHAVIOR_OPTIONS.default:
			setSelectedOption(behavior?.behavior.behaviorType.toLowerCase());
			break;
		case BEHAVIOR_OPTIONS.fillOrders: {
			const fillType = behavior?.behavior.fillType.toLowerCase().split('_');
			switch (fillType[0]) {
			case FILL_ORDERS_OPTIONS.uniformFills:
				setSelectedOption(behavior?.behavior.behaviorType.toLowerCase());
				setSelectedFillOrder(fillType[0]);
				if (behavior?.behavior.count > 0) {
					setInitialFillCountValue(behavior?.behavior.count.toString());
					setFillUniformSelected(FILL_UNIFORM.count);
				}
				if (behavior?.behavior.percentage > 0) {
					setInitialFillPercentageValue(behavior?.behavior.percentage.toString());
					setFillUniformSelected(FILL_UNIFORM.percentage);
				}
				break;
			default:
				setSelectedOption(behavior?.behavior.behaviorType.toLowerCase());
				setSelectedFillOrder(behavior?.behavior.fillType.toLowerCase());
				break;
			}
			break;
		}
		case BEHAVIOR_OPTIONS.partialFillOrders:
			setSelectedOption(behavior?.behavior.behaviorType.toLowerCase());
			setInitialPartialFillCountValue(behavior?.behavior.count.toString());
			setInitialPartialFillPercentageValue(behavior?.behavior.percentage.toString());
			break;
		case BEHAVIOR_OPTIONS.cancelOrders:
			setSelectedOption(behavior?.behavior.behaviorType.toLowerCase());
			break;
		case BEHAVIOR_OPTIONS.reject: {
			setSelectedOption(behavior?.behavior.behaviorType.toLowerCase());
			const array = behavior.behavior.rejectTypes.map(item => item.toLowerCase());
			setInitialRejectOption(array);
			break;
		}
		default:
			break;
		}
	}, [behavior]);

	useEffect(() => {
		if (behavior?.behavior.behaviorType.toLowerCase() !== selectedOption) {
			return setBehaviorActive(false);
		}
		if (behavior?.behavior.behaviorType.toLowerCase() === BEHAVIOR_OPTIONS.fillOrders) {
			if (behavior?.behavior.fillType.toLowerCase() === selectedFillOrder && selectedFillOrder !== FILL_ORDERS_OPTIONS.uniformFills) {
				return setBehaviorActive(true);
			}
			if (behavior?.behavior.count.toString() === countRef.current.getValue().toString()) {
				return setBehaviorActive(true);
			}
			if (behavior?.behavior.percentage.toString() === percentageRef.current.getValue().toString()) {
				return setBehaviorActive(true);
			}
			return setBehaviorActive(false);
		}
		if (behavior?.behavior.behaviorType.toLowerCase() === BEHAVIOR_OPTIONS.partialFillOrders) {
			if (behavior?.behavior.count.toString() === countRef.current.getValue().toString() &&
				behavior?.behavior.percentage.toString() === percentageRef.current.getValue().toString()) {
				return setBehaviorActive(true);
			}
			return setBehaviorActive(false);
		}
		if (behavior?.behavior.behaviorType.toLowerCase() === BEHAVIOR_OPTIONS.reject) {
			const isEqual = behavior?.behavior.rejectTypes.length === selectedRejectItems.length && behavior?.behavior.rejectTypes.sort()
				.every((value, index) => value.toLowerCase() === selectedRejectItems.sort()[index].toLowerCase());
			return setBehaviorActive(isEqual);
		}
		return setBehaviorActive(true);
	}, [behavior, selectedOption, count, percentage, selectedRejectItems, selectedFillOrder]);

	useEffect(() => {
		if (connected) {
			exchangeBehaviorStatusRequest(client, fix, sessionId, {});
		}
	}, [connected, client, fix, sessionId]);

	useEffect(() => {
		if (selectedOption !== BEHAVIOR_OPTIONS.reject) {
			setSelectedRejectError(null);
		}
		if (selectedOption !== BEHAVIOR_OPTIONS.fillOrders) {
			setSelectedFillOrder(FILL_ORDERS_OPTIONS.singleFill);
		}
	}, [selectedOption]);

	useEffect(() => {
		getState(selectedOption, selectedFillOrder, fillUniformSelected);
	}, [selectedOption, selectedFillOrder, fillUniformSelected, getState]);

	const setData = (defaultSettings) => {
		switch (defaultSettings || selectedOption) {
		case BEHAVIOR_OPTIONS.default: {
			return {
				behaviorType: defaultSettings || selectedOption,
			};
		}
		case BEHAVIOR_OPTIONS.fillOrders:
			switch (selectedFillOrder) {
			case FILL_ORDERS_OPTIONS.singleFill: {
				return {
					behaviorType: selectedOption,
					fillType: selectedFillOrder,
				};
			}
			case FILL_ORDERS_OPTIONS.uniformFills: {
				const data = {
					behaviorType: selectedOption,
				};

				if (fillUniformSelected === FILL_UNIFORM.count && !countRef.current.hasError()) {
					data.fillType = FILL_UNIFORM.count;
					data.count = countRef.current.getValue();
					return data;
				}

				if (fillUniformSelected === FILL_UNIFORM.percentage && !percentageRef.current.hasError()) {
					data.fillType = FILL_UNIFORM.percentage;
					data.percentage = percentageRef.current.getValue();
					return data;
				}
				return null;
			}
			case FILL_ORDERS_OPTIONS.randomFills:
				return {
					behaviorType: selectedOption,
					fillType: selectedFillOrder,
				};
			case FILL_ORDERS_OPTIONS.perShareFills:
				return {
					behaviorType: selectedOption,
					fillType: selectedFillOrder,
				};
			default:
				return null;
			}
		case BEHAVIOR_OPTIONS.partialFillOrders:
			countRef.current.hasError();
			percentageRef.current.hasError();
			if (!countRef.current.hasError() && !percentageRef.current.hasError()) {
				return {
					behaviorType: selectedOption,
					count: countRef.current.getValue(),
					percentage: percentageRef.current.getValue(),
				};
			}
			return null;
		case BEHAVIOR_OPTIONS.cancelOrders:
			return {
				behaviorType: selectedOption,
			};
		case BEHAVIOR_OPTIONS.reject:
			if (selectedRejectItems.length > 0) {
				return {
					behaviorType: selectedOption,
					rejectTypes: selectedRejectItems,
				};
			}
			setSelectedRejectError('Please select at least one item.');
			return null;
		default:
			return null;
		}
	};

	const addRejectOption = (itemSelected) => {
		setSelectedRejectError(null);
		if (selectedRejectItems.find(item => item === itemSelected)) {
			setSelectedRejectItems(oldState => oldState.filter(item => item !== itemSelected));
		} else {
			setSelectedRejectItems(oldState => [...oldState, itemSelected]);
		}
	};

	const openModal = () => {
		const data = setData();
		if (data) {
			dispatch(toggleChangeBehaviorModal(true));
		}
	};

	const setBehavior = (reset) => {
		const data = setData(reset);
		if (connected && data) {
			exchangeBehaviorChangeRequest(client, fix, sessionId, data);
			dispatch(toggleChangeBehaviorModal(false));
		} else if (!connected) {
			console.error('not connected');
		}
	};

	const onUniformInputClick = (inputClicked) => {
		setFillUniformSelected(inputClicked);
		if (inputClicked === FILL_UNIFORM.count) {
			percentageRef.current.resetState();
		} else {
			countRef.current.resetState();
		}
	};

	return (
		<RightContent active={exchangeStatus === EXCHANGE_STATUS.OPENED} className="radio-button-area">
			<ChangeBehaviorModal
				onConfirmClick={() => setBehavior()}
				onCancelClick={() => dispatch(toggleChangeBehaviorModal(false))}
			/>
			<SettingsWrapper>
				<Row>
					<p>Default</p>
					<ToggleButton
						checked={selectedOption === BEHAVIOR_OPTIONS.default}
						onClick={() => setSelectedOption(BEHAVIOR_OPTIONS.default)}
					/>
				</Row>
				<Row>
					<p>Fill Orders</p>
					<ToggleButton
						checked={selectedOption === BEHAVIOR_OPTIONS.fillOrders}
						onClick={() => setSelectedOption(BEHAVIOR_OPTIONS.fillOrders)}
					/>
				</Row>
				{selectedOption === BEHAVIOR_OPTIONS.fillOrders && (
					<OptionWrapper>
						<FlexRow justifyContent="space-between">
							<SelectOption
								selected={selectedFillOrder === FILL_ORDERS_OPTIONS.singleFill}
								onClick={() => {
									setSelectedFillOrder(FILL_ORDERS_OPTIONS.singleFill);
									percentageRef.current.resetState();
									countRef.current.resetState();
								}}
							>
							Single
							</SelectOption>
							<SelectOption
								selected={selectedFillOrder === FILL_ORDERS_OPTIONS.uniformFills}
								onClick={() => {
									setSelectedFillOrder(FILL_ORDERS_OPTIONS.uniformFills);
									setFillUniformSelected(FILL_UNIFORM.count);
									percentageRef.current.resetState();
									countRef.current.resetState();
								}}
							>
							Uniform
							</SelectOption>
							<SelectOption
								selected={selectedFillOrder === FILL_ORDERS_OPTIONS.randomFills}
								onClick={() => {
									setSelectedFillOrder(FILL_ORDERS_OPTIONS.randomFills);
									percentageRef.current.resetState();
									countRef.current.resetState();
								}}
							>
							Random
							</SelectOption>
							<SelectOption
								selected={selectedFillOrder === FILL_ORDERS_OPTIONS.perShareFills}
								onClick={() => {
									setSelectedFillOrder(FILL_ORDERS_OPTIONS.perShareFills);
									percentageRef.current.resetState();
									countRef.current.resetState();
								}}
							>
							Per-Share
							</SelectOption>
						</FlexRow>
						<FlexRow>
							<InputWrapper
								onClick={() => onUniformInputClick(FILL_UNIFORM.count)}
								active={fillUniformSelected === FILL_UNIFORM.count && selectedFillOrder === FILL_ORDERS_OPTIONS.uniformFills}
								selectable={selectedFillOrder === FILL_ORDERS_OPTIONS.uniformFills}
							>
								<Input
									initialValue={initialFillCountValue}
									onChange={value => setCount(value)}
									type="header"
									name="Count"
									ref={countRef}
									placeholder="Count"
									validators={[
										{
											validator: 'isInt',
											options: {
												min: 1,
												max: 100,
											},
											errorResult: false,
											message: 'Must be a number between 1 and 100',
										},
									]}
								/>
							</InputWrapper>
							<BaseVerticalDivider />
							<InputWrapper
								onClick={() => onUniformInputClick(FILL_UNIFORM.percentage)}
								active={fillUniformSelected === FILL_UNIFORM.percentage && selectedFillOrder === FILL_ORDERS_OPTIONS.uniformFills}
								selectable={selectedFillOrder === FILL_ORDERS_OPTIONS.uniformFills}
							>
								<Input
									initialValue={initialFillPercentageValue}
									onChange={value => setPercentage(value)}
									type="header"
									name="Value"
									ref={percentageRef}
									placeholder="Value"
									validators={[
										{
											validator: 'isInt',
											options: {
												min: 1,
												max: 99,
											},
											errorResult: false,
											message: 'Must be a number between 1 and 99',
										},
									]}
								/>
							</InputWrapper>
						</FlexRow>
					</OptionWrapper>
				)}
				<Row>
					<p>Partial Fill Orders</p>
					<ToggleButton
						checked={selectedOption === BEHAVIOR_OPTIONS.partialFillOrders}
						onClick={() => setSelectedOption(BEHAVIOR_OPTIONS.partialFillOrders)}
					/>
				</Row>
				{selectedOption === BEHAVIOR_OPTIONS.partialFillOrders && (
					<OptionWrapper>
						<Input
							initialValue={initialPartialFillPercentageValue}
							name="Value"
							type="header"
							onChange={value => setPercentage(value)}
							ref={percentageRef}
							placeholder="Total Percentage to be Filled"
							validators={[
								{
									validator: 'isInt',
									options: {
										min: 1,
										max: 99,
									},
									errorResult: false,
									message: 'Must be a number between 1 and 99',
								},
							]}
						/>
						<Input
							initialValue={initialPartialFillCountValue}
							type="header"
							name="Count"
							onChange={value => setCount(value)}
							ref={countRef}
							placeholder="Count (of Partial Fills)"
							validators={[
								{
									validator: 'isInt',
									options: {
										min: 1,
										max: 100,
									},
									errorResult: false,
									message: 'Must be a number between 1 and 100',
								},
							]}
						/>
					</OptionWrapper>
				)}
				<Row>
					<p>Cancel Orders</p>
					<ToggleButton
						checked={selectedOption === BEHAVIOR_OPTIONS.cancelOrders}
						onClick={() => setSelectedOption(BEHAVIOR_OPTIONS.cancelOrders)}
					/>
				</Row>
				<Row>
					<p>Reject</p>
					<ToggleButton
						checked={selectedOption === BEHAVIOR_OPTIONS.reject}
						onClick={() => setSelectedOption(BEHAVIOR_OPTIONS.reject)}
					/>
				</Row>
				{selectedOption === BEHAVIOR_OPTIONS.reject && (
					<OptionWrapper>
						<RejectOptionWrapper>
							<CheckBoxInput
								value={!!selectedRejectItems.find(item => item === REJECTED_OPTIONS.orders)}
								onChange={() => addRejectOption(REJECTED_OPTIONS.orders)}
							/>
							<p>Orders</p>
							<BigVerticalDivider />
							<CheckBoxInput
								value={!!selectedRejectItems.find(item => item === REJECTED_OPTIONS.modifications)}
								onChange={() => addRejectOption(REJECTED_OPTIONS.modifications)}
							/>
							<p>Modifications</p>
							<BigVerticalDivider />
							<CheckBoxInput
								value={!!selectedRejectItems.find(item => item === REJECTED_OPTIONS.cancel)}
								onChange={() => addRejectOption(REJECTED_OPTIONS.cancel)}
							/>
							<p>Cancels</p>
							<BigVerticalDivider />
						</RejectOptionWrapper>
						{selectedRejectError ? <ModalErrorMessage>{selectedRejectError}</ModalErrorMessage> : <SmallDivider double />}
					</OptionWrapper>
				)}
			</SettingsWrapper>
			<ButtonWrapper className="exchange-behaviour-button-area">
				<PrimaryButton
					disabled={behaviorActive}
					onClick={openModal}
				>
					Set Behavior
				</PrimaryButton>
				<BaseVerticalDivider />
				<WhiteGrayButton
					disabled={behavior?.behavior.behaviorType.toLowerCase() === BEHAVIOR_OPTIONS.default}
					onClick={() => setBehavior(BEHAVIOR_OPTIONS.default)}
				>
					Reset to Default
				</WhiteGrayButton>
			</ButtonWrapper>
		</RightContent>
	);
};

BehaviorSettings.defaultProps = {
	exchangeStatus: '',
};

BehaviorSettings.propTypes = {
	getState: PropTypes.func.isRequired,
	exchangeStatus: PropTypes.string,
};

export default BehaviorSettings;
