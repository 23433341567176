import styled, { css, keyframes } from 'styled-components';
import { BaseTransition, TextBaseSmall, TextSmall } from '../Css/Css';
import { COLORS } from '../../lib/constants/Style';

export const baseButton = css`
	${TextBaseSmall};
	${BaseTransition};
	background: none;
	border: none;
	text-align: center;
	border-radius: 4px;
	user-select: text;
	padding: 10px 15px;
	cursor: pointer;

	${props => props.fillspace && 'flex: 1'};
	
	&:hover {
		color: ${COLORS.lightBlack};
	}
	&:disabled {
		opacity: 0.5;
		pointer-events: none;
		background-color: ${COLORS.lightGrey};
		color: ${COLORS.darkGrey};
	}
`;
export const SecondaryButtonCSS = css`
	${baseButton};
	background-color: ${COLORS.lightGrey};
	color: ${COLORS.darkGrey};
	:hover {
		background-color: ${COLORS.lighterGrey};
	}
`;
const disabledButtonCss = css`
	${SecondaryButtonCSS};
	opacity: 0.5;
	pointer-events:none;
`;
const disabledButtonCssWithPointer = css`
	${SecondaryButtonCSS};
	opacity: 0.5;
`;
export const PrimaryButtonCSS = css`
	${baseButton};
	white-space: nowrap;
	color: ${COLORS.black};
	background-color: ${COLORS.yellow};
	&:hover {
		background-color: ${COLORS.lightYellow};
	}
`;

const basicLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const buttonLoading = css`
	display: inline-block;
	color: transparent;
	transition: color 0s;

	:hover {
		color: transparent;
		background-color: ${COLORS.yellow};
	}
	
	&:after {
		content: " ";
		display: block;
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 2px solid #000;
		top: calc(50% - 10px);
		left: calc(50% - 10px);
		border-color: #000 transparent #000 transparent;
		animation: ${basicLoader} 1.2s linear infinite ;
	}
`;

export const PrimaryButton = styled.button`
	${PrimaryButtonCSS};
	${props => props.disabled && disabledButtonCss};
	${props => props.disabledWithPointerEvents && disabledButtonCssWithPointer}
	${props => props.loading && buttonLoading}
	position: relative;
	overflow: hidden;
`;
export const SecondaryButton = styled.button`
	${baseButton};
	background-color: ${COLORS.lightGrey};
	color: ${COLORS.darkGrey};
	:hover {
		background-color: ${COLORS.lighterGrey};
	}
`;

export const DarkGrayButton = styled.button`
	${baseButton};
	background-color: ${COLORS.darkestGrey};
	color: ${COLORS.white};
	:hover {
		background-color: ${COLORS.darkGrey};
	}
`;
export const WhiteYellowButton = styled.button`
	${baseButton};
	border: solid 2px ${COLORS.yellow};
	padding: 10px 12px;
	${TextSmall};
	color: ${COLORS.lightYellow};

	&:hover {
		background-color:${COLORS.yellow};
	}
`;

export const WhiteGrayButton = styled.button`
	${baseButton};
	border: solid 1px ${COLORS.lighterGrey};
	color: ${COLORS.darkGrey};
	&:hover {
		background-color: ${COLORS.lighterGrey};
	}
`;

export const GreenButton = styled.button`
	${baseButton};
	border: solid 1px ${COLORS.green};
	background-color: ${COLORS.lightGreen};
	color: ${COLORS.green};;
	padding: 10px 20px;

	&:hover {
  		background-color:${COLORS.lightGreen};
		color: ${COLORS.green};
	}
`;
export const RedButtonCss = css`
	${baseButton};
	border: solid 1px ${COLORS.darkPink};
	background-color: ${COLORS.pink};
	color: ${COLORS.red};
	padding: 10px 20px;

	&:hover {
		background-color: ${COLORS.darkPink};
		color: ${COLORS.red};
	}
`;
export const RedButton = styled.button`
	${RedButtonCss};
`;
export const NoBorderButton = styled.button`
	${baseButton};
	color: ${COLORS.dimBlack};
`;
export const GreenButtonCSS = css`
	${baseButton};
	border: solid 1px ${COLORS.green};
	background-color: ${COLORS.lightGreen};
	color: ${COLORS.green};;
	padding: 10px 20px;	
	&:hover {
		background-color:${COLORS.lightGreen};
		color: ${COLORS.green};
	}
`;

const selectedCSS = css`
	${baseButton};
	background-color: ${COLORS.darkestGrey};
	color: ${COLORS.white};
	:hover {
		background-color: ${COLORS.darkGrey};
	}
`;

const notSelectedCSS = css`
	${baseButton};
	background-color: ${COLORS.lightGrey};
	color: ${COLORS.darkGrey};
	:hover {
		background-color: ${COLORS.lighterGrey};
	}
`;

export const SelectButton = styled.button`
	${props => (props.active ? selectedCSS : notSelectedCSS)}
`;


export const ImportButton = styled.input`
	opacity: 0;
	height: calc(100% + 20px);
	width: 100%;
	position: absolute;
	top: -20px;
	left: 0;
	cursor: pointer;
`;
