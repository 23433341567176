
import { useEffect, useCallback, useState } from 'react';

const useContextMenu = (outerRef) => {
	const [xPos, setXPos] = useState('0');
	const [yPos, setYPos] = useState('0');
	const [menu, showMenu] = useState(false);
	const handleContextMenu = useCallback(
		(event) => {
			if (outerRef.current && outerRef.current.contains(event.target)) {
				event.preventDefault();
				outerRef.current.click();
				setXPos(`${event.pageX}`);
				setYPos(`${event.pageY}`);
				showMenu(true);
			} else {
				showMenu(false);
			}
		},
		[showMenu, outerRef, setXPos, setYPos]
	);

	const handleClick = useCallback(() => {
		showMenu(false);
	}, [showMenu]);

	useEffect(() => {
		document.addEventListener('click', handleClick);
		document.addEventListener('contextmenu', handleContextMenu);
		return () => {
			document.addEventListener('click', handleClick);
			document.removeEventListener('contextmenu', handleContextMenu);
		};
	});

	return { xPos, yPos, menu };
};

export default useContextMenu;
