import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { MediumDivider } from '../../Atoms/Divider';
import { DateInfo } from './Atoms';

function MachineStartDate(props) {
	const {
		startedOn,
	} = props;
	if (startedOn) {
		return (
			<>
				<DateInfo>Started on</DateInfo>
				<DateInfo>{moment(startedOn).format('MMMM DD, YYYY, hh:mm a')}</DateInfo>
			</>
		);
	}
	return <MediumDivider />;
}

MachineStartDate.defaultProps = {
	startedOn: null,
};
MachineStartDate.propTypes = {
	startedOn: PropTypes.number,
};
export default MachineStartDate;
