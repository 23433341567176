import { fromJS } from 'immutable';
import DICTIONARIES_TYPES from './DictionariesTypes';

const initialState = fromJS({
	isLoading: false,
	error: null,
	name: '',
	version: '',
	fileName: '',
	messages: [],
	components: [],
	fields: [],
	dataTypes: [],
});

export default function DictionariesReduces(state = initialState, action) {
	switch (action.type) {
	case DICTIONARIES_TYPES.DICTIONARIES_FETCH: {
		return state
			.set('isLoading', true);
	}
	case DICTIONARIES_TYPES.DICTIONARIES_FETCH_SUCCESS: {
		const {
			version, components, fields, dataTypes, name, messages, fileName,
		} = action.payload;
		return state
			.set('isLoading', false)
			.set('name', name)
			.set('version', version)
			.set('fileName', fileName)
			.set('messages', fromJS(messages))
			.set('components', fromJS(components))
			.set('fields', fromJS(fields))
			.set('dataTypes', fromJS(dataTypes));
	}
	case DICTIONARIES_TYPES.DICTIONARIES_FETCH_FAIL: {
		return state
			.set('isLoading', false)
			.set('error', fromJS(action.payload));
	}

	case DICTIONARIES_TYPES.DICTIONARIES_RESET: {
		return initialState;
	}
	default: {
		return state;
	}
	}
}
