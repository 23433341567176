import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Hooks
import useSearch from '../../Hooks/useSearch';
import useSortBy from '../../Hooks/useSortBy';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';

// Atoms
import { TextBig } from '../../Css/Css';
import { COLORS } from '../../../lib/constants/Style';
import { SortableTableCSS } from '../../Css/Table';
import { FullWidthContainer } from '../../Atoms/Wrapper';
import { BaseDivider } from '../../Atoms/Divider';

// Molecules
import SearchFiled from '../../Molecules/Input/SearchField/SearchField';
import Loader from '../../Molecules/Loader';

// Organisms
import TableView from '../Table/TableView';

const ExchangeDictionaryTableWrapper = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	flex: 1;
	background-color: ${COLORS.white};
	margin-right: 26px;
	:last-child{
		margin-right: unset;
	}
	height: 100%;
	padding: 30px;
	${SortableTableCSS};
`;

const ExchangeDictionaryTableTittle = styled.h2`
	${TextBig};
  	font-size: 24px;
  	color: ${COLORS.lightBlack};
`;

const ExchangeDictionaryTable = ({
	title, header, rows, rowClick,
}) => {
	const getDataToRender = useCallback(r => r?.map(row => ({
		data: row.data,
		content: row.content,
		name: row.name,
		tag: row.tag,
		key: row.key,
		metaData: row.metaData,
	})), []);

	const [isLoading, setIsLoading] = useState(true);
	const [tableRows, setTableRows] = useState();
	const { searchInputValue, setSearchInputValue } = useSearch(rows, setTableRows, header);
	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, tableRows);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, header, tableRows);

	useEffect(() => {
		setTableRows(rows);
	}, [rows]);

	useEffect(() => {
		if (dataToRender?.size > 0) {
			setIsLoading(false);
		}
	}, [dataToRender]);

	return (
		<ExchangeDictionaryTableWrapper>
			<ExchangeDictionaryTableTittle>
				{title}
			</ExchangeDictionaryTableTittle>
			<BaseDivider />
			<FullWidthContainer>
				<SearchFiled
					value={searchInputValue}
					onChange={setSearchInputValue}
					placeholder="Search"
				/>
			</FullWidthContainer>
			<BaseDivider />
			{isLoading && (
				<Loader loading />
			)}
			{!isLoading && (
				<TableView
					sortedBy={sortedBy}
					rowClick={rowClick}
					header={header}
					handleSort={handleSort}
					rows={dataToRender}
					selectable={false}
					hasSettings={false}
				/>
			)}
		</ExchangeDictionaryTableWrapper>
	);
};
ExchangeDictionaryTable.defaultProps = {
	title: 'Messages',
	rowClick: () => {},
};

ExchangeDictionaryTable.propTypes = {
	title: PropTypes.string,
	header: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	rows: PropTypes.shape({}).isRequired,
	rowClick: PropTypes.func,
};

export default ExchangeDictionaryTable;
