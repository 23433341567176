import { EXCHANGE_STATUS } from './constants/Exchange';

/**
 *
 * @param status {string}
 * @returns {{stop: boolean, start: boolean, close: boolean, open: boolean}}
 * boolean value for each button that says if the button should be available or not. True for enabled button and false for disabled button
 */
export function handleButtonStatus(status) {
	switch (status) {
	case EXCHANGE_STATUS.STOPPED: {
		return {
			start: true,
			stop: false,
			open: false,
			close: false,
		};
	}
	case EXCHANGE_STATUS.STARTED: {
		return {
			start: false,
			stop: true,
			open: true,
			close: false,
		};
	}
	case EXCHANGE_STATUS.OPENED: {
		return {
			start: false,
			stop: true,
			open: false,
			close: true,
		};
	}
	case EXCHANGE_STATUS.CLOSED: {
		return {
			start: false,
			stop: true,
			open: true,
			close: false,
		};
	}
	case EXCHANGE_STATUS.ERROR: {
		return {
			start: true,
			stop: false,
			open: false,
			close: false,
		};
	}
	case EXCHANGE_STATUS.NA: {
		return {
			start: false,
			stop: false,
			open: false,
			close: false,
		};
	}
	case null: {
		return {
			start: false,
			stop: false,
			open: false,
			close: false,
		};
	}
	default: {
		return {
			start: false,
			stop: false,
			open: false,
			close: false,
		};
	}
	}
}

export const getRandomClientOrderId = () => (new Date().getTime() + Math.random()).toString().replace(/\./, '');

/**
 * converts 1,230,000.000 like string to number
 * @param str {string}
 * @returns {number}
 */
export const stringToNumber = str => Number(str?.replace?.(/,/g, ''));
