import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

// Atoms
import {
	SideMenuWrapper,
	SideMenuContent,
	SideMenuItem,
	SideMenuItemText,
} from '../Atoms';
import { BigDivider } from '../../../Atoms/Divider';
import IconComponent from '../../../Atoms/Icon';

// Molecules
import Logo from '../../../Molecules/Layout/Logo';

// Organisms
import Scrollbar from '../../Scrollbar';
import UserProfile from './UserProfile';

// Helpers
import { inProduction } from '../../../../lib/helpers';

class SideMenu extends Component {
	constructor(props) {
		super(props);
		const {
			pathname,
		} = props.location;
		this.state = {
			expanded: pathname === '/app/subscription' || pathname === '/app/exchanges',
			userProfileVisible: pathname === '/app/subscription' || pathname === '/app/exchanges',
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({
				expanded: this.props.location.pathname === '/app/subscription' || this.props.location.pathname === '/app/exchanges',
				userProfileVisible: this.props.location.pathname === '/app/subscription' || this.props.location.pathname === '/app/exchanges',
			});
		}
	}

	renderItem(item) {
		return (
			<SideMenuItem
				exact

				to={item.url}
				href={item.url}
				key={item.name}
				onClick={(e) => {
					if (item.url === 'unknown') {
						e.preventDefault();
						inProduction();
					}
				}}
			>
				<IconComponent name={item.icon} className={item.className} />
				<SideMenuItemText>
					{item.name}
				</SideMenuItemText>
			</SideMenuItem>
		);
	}

	render() {
		const {
			expanded,
			userProfileVisible,
		} = this.state;
		const {
			sideBarItem,
			location: {
				pathname,
			},
		} = this.props;
		const sideBarItemJs = sideBarItem.toJS();
		const registeredUser = !pathname.includes('/app/registration');
		return (
			<SideMenuWrapper
				userProfileVisible={userProfileVisible}
			>
				<SideMenuContent
					className="side-menu"
					expanded={(expanded || userProfileVisible) && registeredUser}
					onMouseEnter={() => {
						this.setState({
							expanded: true,
						});
					}}
					onMouseLeave={() => {
						this.setState({
							expanded: false,
						});
					}}
				>
					<Logo to="/app/dashboard" href="/app/dashboard" />
					{registeredUser && (
						<Scrollbar nopadding>
							<BigDivider double />
							{!userProfileVisible && sideBarItemJs.map(item => this.renderItem(item))}
							{userProfileVisible && <UserProfile />}
						</Scrollbar>
					)}
				</SideMenuContent>
			</SideMenuWrapper>
		);
	}
}
function mapStateToProps(state) {
	return {
		sideBarItem: state.get('sideBarReducer').get('sideBarItems'),
	};
}
export default connect(mapStateToProps, null)(withRouter(SideMenu));
