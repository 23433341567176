export const SCREEN_SIZES = {
	mobileExtraS: '350px',
	mobileS: '400px',
	mobileM: '620px',
	mobileL: '850px',
	tablet: '1024px',
	tabletL: '1200px',
	laptopM: '1360px',
	laptopL: '1440px',
	desktop: '1600px',
	desktopL: '2560px',
};

export const DEVICE = {
	mobileExtraS: `(max-width: ${SCREEN_SIZES.mobileExtraS})`,
	mobileS: `(max-width: ${SCREEN_SIZES.mobileS})`,
	mobileM: `(max-width: ${SCREEN_SIZES.mobileM})`,
	mobileL: `(max-width: ${SCREEN_SIZES.mobileL})`,
	tablet: `(max-width: ${SCREEN_SIZES.tablet})`,
	tabletL: `(max-width: ${SCREEN_SIZES.tabletL})`,
	laptopM: `(max-width: ${SCREEN_SIZES.laptopM})`,
	laptopL: `(max-width: ${SCREEN_SIZES.laptopL})`,
	desktop: `(max-width: ${SCREEN_SIZES.desktop})`,
	desktopL: `(max-width: ${SCREEN_SIZES.desktopL})`,
};
