export default {
	FETCH: 'EXCHANGE_FETCH',
	FETCH_SUCCESS: 'EXCHANGE_FETCH_SUCCESS',
	FETCH_FAIL: 'EXCHANGE_FETCH_FAIL',
	STARTING_EXCHANGE: 'STARTING_EXCHANGE',
	START_EXCHANGE_SUCCESS: 'START_EXCHANGE_SUCCESS',
	START_EXCHANGE_FAIL: 'START_EXCHANGE_FAIL',
	STOPPING_EXCHANGE: 'STOPPING_EXCHANGE',
	STOP_EXCHANGE_SUCCESS: 'STOP_EXCHANGE_SUCCESS',
	STOP_EXCHANGE_FAIL: 'STOP_EXCHANGE_FAIL',
	FETCH_TYPES_SUCCESS: 'FETCH_TYPES_SUCCESS',
	FETCH_SESSIONS_SUCCESS: 'FETCH_SESSIONS_SUCCESS',
	FETCH_EXCHANGES_EXTRA_DATA_SUCCESS: 'FETCH_EXCHANGES_EXTRA_DATA_SUCCESS',
	DELETE_EXCHANGES_START: 'DELETE_EXCHANGES_START',
	DELETE_EXCHANGES_SUCCESS: 'DELETE_EXCHANGES_SUCCESS',
	DELETE_EXCHANGES_FAIL: 'DELETE_EXCHANGES_FAIL',
	SET_ACTIVE_EXCHANGE_INSTANCE: 'SET_ACTIVE_EXCHANGE_INSTANCE',
	UPDATE_EXCHANGE_STATUS: 'UPDATE_EXCHANGE_STATUS',
	FETCH_SINGLE_EXCHANGE_EXTRA_DATA: 'FETCH_SINGLE_EXCHANGE_EXTRA_DATA',
	REMOVE_EXCHANGES: 'REMOVE_EXCHANGES',
	UPDATE_ALL_INSTANCES_STATUS: 'UPDATE_ALL_INSTANCES_STATUS',
	RESET_ACTIVE_EXCHANGE: 'RESET_ACTIVE_EXCHANGE',
	SET_EXCHANGE_ACTIVE_USER: 'SET_EXCHANGE_ACTIVE_USER',
	FETCH_EXCHANGE_TYPES_NAMES_SUCCESS: 'FETCH_EXCHANGE_TYPES_NAMES_SUCCESS',
	// Trading gateways
	FETCH_TRADING_GATEWAYS_SUCCESS: 'FETCH_TRADING_GATEWAYS_SUCCESS',
	DELETE_TRADING_GATEWAY_SUCCESS: 'DELETE_TRADING_GATEWAY_SUCCESS',

	// Order books
	DELETE_ORDER_BOOK_SUCCESS: 'DELETE_ORDER_BOOK_SUCCESS',
	FETCH_ORDER_BOOKS_SUCCESS: 'FETCH_ORDER_BOOKS_SUCCESS',
	CLEAR_ORDER_BOOK_TRADES: 'CLEAR_ORDER_BOOK_TRADES',

	// Order blotter
	SET_ORDER_BLOTTER_TRADES: 'SET_ORDER_BLOTTER_TRADES',
	SET_ORDER_BLOTTERS_LOADING_STATUS: 'SET_ORDER_BLOTTERS_LOADING_STATUS',
	UPDATE_LOADED_ORDER_BLOTTERS: 'UPDATE_LOADED_ORDER_BLOTTERS',

	CHOOSE_EXPORT_EXTENSION: 'CHOOSE_EXPORT_EXTENSION',
};
