import styled, { css } from 'styled-components';
// Css
import {
	AlignText, TextBase, TextBig, TextMedium,
} from '../Css/Css';
import { COLORS, FONT_SIZES } from '../../lib/constants/Style';
import { FlexRowCenterCss } from './Flex';

export const Paragraph = styled.p`
	${TextMedium};
	${AlignText};
	line-height: 167%;
	margin: 0;
`;

export const BlockTitle = styled.span`
	${TextBase};
	user-select: text;
	font-weight: bold;
	color: ${COLORS.lightBlack};
`;

Paragraph.defaultProps = {
	align: 'center',
};
export const GrayLabel = styled.span`
	color: #8c959d;
	font-size: ${FONT_SIZES.baseSmall};
	margin-right: 2px;
    display: inline-block;
    text-transform: uppercase;
    user-select: all;
    letter-spacing: 0.2px;
`;

export const ModalTitle = styled.h2`
	${TextBig};
	line-height: 1.42;
`;
export const ModalErrorMessage = styled.div`
    ${FlexRowCenterCss};
	height: 30px;
    ${TextBase};
    color: #d73e3e;
    text-align: center;
`;

export const NoContentInfo = styled.div`
	margin: auto auto;
	user-select: text;
	font-size: ${FONT_SIZES.medium};
	color: ${COLORS.grey};
	
	${props => props.absolute && css`
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
	`}
`;
