import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';

// Helpers
import Api from '../../lib/api';
import { useAuth } from '../../components/Context/auth';
import { setItem, getItem } from '../../lib/localStorage';

// Atoms
import { PrimaryButton } from '../../components/Atoms/Button';
import { Title } from '../../components/Atoms/WebSite/Text';
import { LargeDivider, MediumDivider, SmallDivider } from '../../components/Atoms/Divider';

// Molecules
import Input from '../../components/Molecules/Input/Input/Input';
import { userTypes } from '../../lib/constants/User';
import DocumentTitle from '../../components/Molecules/DocumentTitle';


const MainWrapper = styled.div`
	background: white;
	margin: auto auto;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	padding: 30px;
`;

function Registration() {
	const { decideUserType, userType } = useAuth();
	const nameRef = useRef();
	const countryRef = useRef();

	const handleSubmit = useCallback(() => {
		const userData = JSON.parse(getItem('userData'));
		userData.metadata = {
			country: countryRef.current.getValue(),
			name: nameRef.current.getValue(),
		};
		setItem('userData', JSON.stringify(userData));
		Api.put('users/profile', {
			name: nameRef.current.getValue(),
			country: countryRef.current.getValue(),
		}).finally(() => {
			decideUserType().then(() => {
				if (userType === userTypes.WITHOUT_SUBSCRIPTION) {
					replace('/app/subscription');
				}
			});
		});
	}, [decideUserType, userType]);

	return (
		<MainWrapper>
			<DocumentTitle title="Registration" />
			<Title>
				Please provide the following information to start using ETP Markets
			</Title>
			<MediumDivider />
			<Input
				placeholder="Name"
				name="name"
				type="text"
				ref={nameRef}
			/>
			<SmallDivider />
			<Input
				placeholder="Country"
				name="country"
				type="text"
				ref={countryRef}
			/>
			<LargeDivider />
			<PrimaryButton onClick={handleSubmit}>
				Submit
			</PrimaryButton>
		</MainWrapper>
	);
}

export default connect(null, { replace })(Registration);
