import React from 'react';
import PropTypes from 'prop-types';

// Lib
import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';

// Atoms
import { BaseVerticalDivider, BaseDivider } from '../../Atoms/Divider';
import { PrimaryButton, ImportButton } from '../../Atoms/Button';
import { GrayLabel } from '../../Atoms/Text';
import { OrderBookHeaderRow, OrderBookHeaderWrapper, TradingPhase } from './OrderBook.Atoms';
import { FlexRow } from '../../Atoms/Flex';
// Molecules
import MarketStatus from '../../Molecules/MarketStatus';
import ViewAsMemberBox from '../../Molecules/ViewAsMemberBox';

const OrderBookHeader = (props) => {
	const {
		tradingPhaseStatus,
		onImportClick,
		onExportClick,
		onExportAsImageClick,
		onClearOrderBookClick,
		ipAddress,
		vmStatus,
		exchangeStatus,
		exportImageLoader,
	} = props;

	return (
		<OrderBookHeaderWrapper>
			<BaseDivider />
			<OrderBookHeaderRow>
				<GrayLabel>Trading phase:</GrayLabel>
				<TradingPhase>{tradingPhaseStatus}</TradingPhase>
				<BaseVerticalDivider />
				<MarketStatus
					ipAddress={ipAddress}
					vmStatus={vmStatus}
					exchangeStatus={exchangeStatus}
				/>
			</OrderBookHeaderRow>
			<BaseDivider />
			<OrderBookHeaderRow>
				<ViewAsMemberBox className="view-as-member-box" />
				<BaseVerticalDivider />
				<FlexRow className="order-book-actions">
					<PrimaryButton disabled={exchangeStatus !== EXCHANGE_STATUS.OPENED}>
						<ImportButton
							onChange={event => onImportClick(event)}
							value=""
							type="file"
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						/>
					Import
					</PrimaryButton>

					<BaseVerticalDivider />
					<PrimaryButton onClick={onExportClick}>
					Export
					</PrimaryButton>
					<BaseVerticalDivider />
					<PrimaryButton
						loading={exportImageLoader}
						onClick={onExportAsImageClick}
					>
					Export as image
					</PrimaryButton>
					<BaseVerticalDivider />
					<PrimaryButton onClick={onClearOrderBookClick}>
					Clear Order Book
					</PrimaryButton>
				</FlexRow>
			</OrderBookHeaderRow>
		</OrderBookHeaderWrapper>
	);
};
OrderBookHeader.propTypes = {
	tradingPhaseStatus: PropTypes.string,
	ipAddress: PropTypes.string,
	onImportClick: PropTypes.func.isRequired,
	onExportClick: PropTypes.func.isRequired,
	onExportAsImageClick: PropTypes.func.isRequired,
	onClearOrderBookClick: PropTypes.func.isRequired,
	vmStatus: PropTypes.string,
	exchangeStatus: PropTypes.string,
	exportImageLoader: PropTypes.bool,
};
OrderBookHeader.defaultProps = {
	tradingPhaseStatus: 'N/A',
	vmStatus: 'N/A',
	ipAddress: '',
	exchangeStatus: 'N/A',
	exportImageLoader: false,
};


export default OrderBookHeader;
