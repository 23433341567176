export const OrderBlotterValidity = {
	Day: 'Day',
	GTC: 'Good till Cancel(GTC)',
	GTD: 'Good till Date(GTD)',
	IOC: 'Immediate or Cancel (IOC)',
	FOK: 'Fill or Kill(FOK)',
};
export const OrderBlotterValidityValueToSend = {
	[OrderBlotterValidity.Day]: 'DAY',
	[OrderBlotterValidity.GTC]: 'GOOD_TILL_CANCEL',
	[OrderBlotterValidity.GTD]: 'GOOD_TILL_DATE',
	[OrderBlotterValidity.IOC]: 'IMMEDIATE_OR_CANCEL',
	[OrderBlotterValidity.FOK]: 'FILL_OR_KILL',
};
