import React from 'react';

// Atoms
import { connect } from 'react-redux';
import { BaseDivider, BigVerticalDivider } from '../../Atoms/Divider';
import { PrimaryButton } from '../../Atoms/Button';
import { PhaseBarWrapper } from './Atoms';

// Molecules
import { PhaseCircle } from './Molecules/PhaseCircle';
import { getActiveExchange } from '../../../data/exchanges/selectors';
import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';

// TODO FIX THIS COMPONENT
const PhaseBar = ({ status }) => (
	<PhaseBarWrapper className="phases">
		<PhaseCircle
			number="1"
			text="Closed"
			active={status === EXCHANGE_STATUS.CLOSED ||
			status === EXCHANGE_STATUS.OPENED || status === EXCHANGE_STATUS.STARTED}
			first
		/>
		<PhaseCircle number="2" text="Pre-Open" active={status === EXCHANGE_STATUS.OPENED} />
		<PhaseCircle number="3" text="Continuous Trading" active={status === EXCHANGE_STATUS.OPENED} />
		<PhaseCircle number="4" text="Pre-Close" />
		<BigVerticalDivider />
		<PrimaryButton
			data-tip="Coming soon"
			disabledWithPointerEvents
		>
			Next Phase
		</PrimaryButton>
	</PhaseBarWrapper>
);

function mapStateToProps(state) {
	const activeExchange = getActiveExchange(state);
	return {
		status: activeExchange.get('status'),
	};
}

export default connect(mapStateToProps, null)(PhaseBar);
