import React from 'react';
import PropTypes from 'prop-types';

import { BigDivider, MediumDivider } from '../../Atoms/Divider';
import { CardImage } from '../../Atoms/WebSite/Images';
import { CardImageWrapper, CardWrapper } from '../../Atoms/WebSite/Wrappers';
import { Title, Description } from '../../Atoms/WebSite/Text';

const Card = ({ image, title, description }) => (
	<CardWrapper>
		<CardImageWrapper>
			<CardImage src={image} />
		</CardImageWrapper>
		<BigDivider/>
		<div>
			<Title>{title}</Title>
			<MediumDivider />
			<Description>{description}</Description>
		</div>
	</CardWrapper>
);

Card.defaultProps = {
	image: null,
	title: '',
	description: '',
};

Card.propTypes = {
	image: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
};

export default Card;
