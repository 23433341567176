import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

import { toggleStopExchangeModal } from '../../../data/ui/UIActions';
// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import {
	MediumDivider,
	MediumVerticalDivider, BaseDivider,
} from '../../Atoms/Divider';
// Molecules
import Modal from '../../Molecules/Modal';

class StopExchangeModal extends PureComponent {
	render() {
		const {
			modalInfo,
			actions,
			onConfirmClick,
			onCancelClick,
		} = this.props;

		const modalInfoJS = modalInfo?.toJS();

		if (modalInfoJS.visible !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={() => actions.toggleStopExchangeModal(false)}
				size={MODAL_SIZES.MEDIUM}
			>
				<FlexColumn>
					<ModalTitle>
					Stop Exchange
					</ModalTitle>
					<MediumDivider />
					<BaseDivider />
				Are you sure you want to stop this exchange?
					<MediumDivider />
					<BaseDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={onConfirmClick}>
						Confirm
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={onCancelClick}>
						Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}

StopExchangeModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleStopExchangeModal: PropTypes.func,
	}).isRequired,
	onConfirmClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.STOP_EXCHANGE_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleStopExchangeModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StopExchangeModal));
