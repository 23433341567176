import React from 'react';
import styled from 'styled-components';
import { BoldText } from '../../../components/Atoms/WebSite/Text';
import { MediumVerticalDivider, SmallDivider, SmallVerticalDivider } from '../../../components/Atoms/Divider';
import { Wrapper, FlexRow, IndentedRow } from './TermsOfService';
import { BaseOverlay } from '../../../components/Css/Css';

const StyledBulletSection = styled.div`
	display: inline-block;
`;
const PrivacyPolicy = () => (
	<Wrapper>
		<BoldText>
			ETP GEMS PRIVACY POLICY

		</BoldText>
		<SmallDivider />
		<div>
			<BoldText>Last modified: </BoldText>
			<span>10 July 2019</span>
		</div>
		<SmallDivider />
		<p>
			Please read through this privacy policy (
			<BoldText>"Privacy Policy"</BoldText>
			) carefully as it explains to
			you how Esprow Pte. Ltd. (UEN No. 200804918D), a company duly incorporated in Singapore
			("
			<BoldText>"Esprow"</BoldText>
			", "
			<BoldText>"we"</BoldText>
			", "
			<BoldText>"us"</BoldText>
			",
			"
			<BoldText>"our"</BoldText>
			" as the context permits), collects, uses, discloses, retains and otherwise
			processes Personal Data.
		</p>
		<SmallDivider />
		<p>
			<BoldText>"Personal Data"</BoldText>
			{' '}
			means data, whether true or not, about a person who can be identified:
			(a) from that data; or (b) from that data and other information to which we have or are likely to have
			access. However, Personal Data does not include information that has been made anonymous or aggregated so
			that it can no longer be used to identify a specific person, whether in combination with other information
			or otherwise.
		</p>
		<SmallDivider />
		<p>
			By accessing, visiting, browsing and/or using the Website and/or the Service, you consent to us collecting,
			using, disclosing, storing and processing your Personal Data as described in this Privacy Policy. If you do
			not agree with any of its terms, you should immediately discontinue use of the Website and/or Service.
		</p>
		<SmallDivider />
		<p>
			In this Privacy Policy,
			{' '}
			<BoldText>"include"</BoldText>
			{' '}
			(and its derivatives, such
			as
			{' '}
			<BoldText>"including"</BoldText>
			" and
			{' '}
			<BoldText>"includes"</BoldText>
			") means to include without
			limitation and every occurrence of this term shall be construed as defined aforesaid, whether or not
			capitalised. Unless otherwise defined, capitalised terms used in this Privacy Policy have the same meanings
			given to them in our
			{' '}
			<a href="/terms-of-service">Terms of Service</a>
		</p>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>1.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>INFORMATION WE COLLECT</BoldText></span>
				<p>
					You acknowledge and agree that we (or service providers acting on our behalf) may collect certain
					information from you and any devices you use, including your Personal Data, whenever you access
					and/or use the Website and/or Service, or interact with us. We may also require you to provide us or
					our service providers with your Personal Data in order for us to provide our services to you, and/or
					in order for our service providers to provide their services to us.
				</p>
				<SmallDivider />
				<p>
					We generally do not collect your Personal Data unless:

				</p>
				<p>
					(a) it is provided to us voluntarily by you directly or via a third party who has been duly
					authorised by you to disclose your Personal Data to us (your "
					<BoldText>
						authorised
						representative"
					</BoldText>
					), you (or
					your authorised representative) have been notified of the purposes for which it is collected and you
					(or your authorised representative) have provided your consent to its collection and usage for such
					purposes (by virtue of this Privacy Policy or a separate consent process);

				</p>
				<p>
					(b) it is provided to us voluntarily by you for a purpose, and it is reasonable in the circumstances
					that you are deemed to have consented to our collection, use and disclosure of that Personal Data
					for that purpose; or

				</p>
				<p>
					(c) collection and use of Personal Data without consent is permitted or required by applicable laws.

				</p>
				<SmallDivider />
				<p>
					Personal Data which we (or service providers acting on our behalf) collect include the following:


				</p>
				<SmallDivider />
				<p>
					<BoldText>Account Information.</BoldText>
					{' '}
					We use customer registration, authentication and identity
					management services provided by the following service provider. When you register for an Account
					with us, they will collect and store your usernames and passwords, full name, gender, email
					address, residential address, country of residence, contact number, date of birth , job title,
					business name, business type, business website and business VAT/GST This information is used to
					register your Account, to set up your user profile and to allow you to access and/or use the
					Service using your username and password. Your Personal Data will be stored and/or processed in
					the location(s) below in compliance with applicable laws, including the EU General Data
					Protection Regulation ("
					<BoldText>GDPR</BoldText>
					"). For more information, please refer to their
					terms of service
					and/or privacy policy.


				</p>
				<p>
					<BoldText>
						Service provider:
					</BoldText>
					Auth0, Inc.
				</p>
				<p>
					<BoldText>
						Place of storage and/or processing
					</BoldText>
					:
					European Union, United States of America
				</p>
				<p>
					<BoldText>
						Terms of Service
					</BoldText>
					:
					<a href="https://cdn.auth0.com/blog/website/legal/files/Auth0-SS-TOS.pdf">https://cdn.auth0.com/blog/website/legal/files/Auth0-SS-TOS.pdf</a>
				</p>


				<SmallDivider />
				<p>
					<BoldText> Payment Information.</BoldText>
					{' '}
					We use subscription and payment management and processing services by the following service
					provider. When you register for a Trial, purchase a Subscription Plan and/or otherwise make payments
					using their service, they will collect and store your name on your credit/debit card(s), card
					number(s), card expiry date(s), CVV code, residential address, billing address, email address,
					records of transactions made - This information is used to process payments by credit card, debit
					card, PayPal account or other approved methods, to facilitate billing, to prevent, detect and
					investigate fraud or other prohibited activities, to facilitate dispute resolution such as
					chargebacks or refunds, to send you invoices and for other purposes associated with the acceptance
					of credit and debit cards, PayPal accounts or other approved payment methods. Your personal data
					will be stored and/or processed in the location(s) below in compliance with applicable laws,
					including the GDPR. For more information, please refer to their terms of service and/or privacy
					policy.
				</p>
				<p>
					<BoldText>
						Service provider:
					</BoldText>
					Chargebee, Inc.
				</p>
				<p>
					<BoldText>
						Place of storage and/or processing
					</BoldText>
					:
					European Union, United States of America
				</p>
				<p>
					<BoldText>
						Privacy Policy
					</BoldText>
					:
					<a href="https://www.chargebee.com/privacy/">
						https://www.chargebee.com/privacy/
					</a>
					<a href="https://www.chargebee.com/docs/eu-gdpr.html">https://www.chargebee.com/docs/eu-gdpr.html</a>
				</p>
				<SmallDivider />
				<p>
					<BoldText>
						Communications.
					</BoldText>
					If you send us personal correspondence, contact us or communicate with us in any way (e.g. by
					sending us emails, messages or mail and/or by making phone calls to us), we may collect and maintain
					records of such communications.
				</p>
				<SmallDivider />
				<p>
					We also use a customer support platform provided by the following service provider. If you
					correspond
					with, contact or communicate with us through our customer support platform, the service provider
					will collect, maintain records of and disclose to us such communications. Your Personal Data will be
					stored and/or processed in the location(s) below in compliance with applicable laws, including the
					GDPR. For more information, please refer to their terms of service and/or privacy policy.

				</p>
				<p>
					<BoldText>
						Service provider
					</BoldText>
					:
					Zendesk, Inc.
				</p>
				<p>
					<BoldText>
						Place of storage and/or processing
					</BoldText>
					:
					European Economic Area, United States of America
				</p>
				<p>
					<BoldText>
						Terms of service
					</BoldText>
					:
					<a href="https://www.zendesk.com/company/customers-partners/master-subscription-agreement/">https://www.zendesk.com/company/customers-partners/master-subscription-agreement/</a>
				</p>
				<SmallDivider />
				<p>
					Information relating to your communications will be used by us and/or our service provider to
					respond to, process and handle your queries, requests, feedback and Submissions and to generally
					administer and manage our relationship with you.
				</p>
				<SmallDivider />
				<p>
					You acknowledge that links to the terms of service and/or privacy policies of our service providers
					are provided herein for your convenience only and may not be current or updated. You agree to refer
					to the respective websites of these service providers for up-to-date information on how they
					collect, use, disclose, retain and otherwise process your Personal Data.
				</p>
			</StyledBulletSection>

		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>2</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>HOW WE USE AND SHARE INFORMATION</BoldText></span>
			</StyledBulletSection>
		</FlexRow>

		<FlexRow>
			<div>2.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>How we use your Personal Data.</BoldText>
					Apart from the purposes described in
					{' '}
					<BoldText>Section 1</BoldText>
					{' '}
					above, we may generally use your
					Personal Data for the following purposes:
				</p>
				<p>
					(a) performing obligations in the course of or in connection with our provision of the services
					requested by you;
				</p>
				<p>
					(b) providing you with personalised services and/or to customise your user experience of the Website
					and/or Service;
				</p>
				<p>
					(c) verifying your identity;
				</p>
				<p>
					(d) responding to, handling, and processing queries, requests, applications, complaints, feedback
					and
					Submissions from you;
				</p>
				<p>
					(e) generally administering and/or managing your relationship with us;
				</p>
				<p>
					(f) processing payment or credit transactions;
				</p>
				<p>
					(g) maintaining records of your instructions and our interactions with you;
				</p>
				<p>
					(h) internal reporting and analysis related to our business operations;
				</p>
				<p>
					(i) sending you information on any updates or changes to the Website and/or Service (including
					notifying you of any planned or unscheduled downtime) and/or Subscription Plans;
				</p>
				<p>
					(j) sending you information on any updates or changes to our Terms, Policies and/or other legal or
					administrative information;
				</p>
				<p>
					(k) sending you updates and publications (including our newsletters), or advertising, marketing and
					promotional communications (including updates on our Promotions), where you have requested or
					consented to be on our mailing list or to receive such communications respectively;
				</p>
				<p>
					(l) any other purposes for which you have provided the information;
				</p>
				<p>
					(m) any other incidental business and/or administrative purposes related to or in connection with
					the
					above; and/or
				</p>
				<p>
					(n) such as we believe to be necessary and/or appropriate to:
				</p>
				<IndentedRow double>
					(i) comply with applicable laws, regulations, guidelines and/or codes of conduct (or any request or
					direction of any public, government or regulatory authorities, including those outside your country
					of residence);
				</IndentedRow>
				<IndentedRow double>
					(ii) comply with legal process (including assisting in law enforcement and investigations conducted
					by any governmental and/or regulatory authority);
				</IndentedRow>
				<IndentedRow double>
					(iii) protect the rights, privacy, safety property and/or operations of any person (including us and
					any person accessing and/or using the Website and/or Service);
				</IndentedRow>
				<IndentedRow double>
					(iv) protect the safety or integrity of the Website and/or Service (including to help prevent spam,
					abuse, or malicious actors on the Website and/or Service);
				</IndentedRow>
				<IndentedRow double>
					(v) prevent, detect, mitigate and investigate potentially illegal acts, fraud and/or security
					breaches and to assess and manage risk, including to alert you if fraudulent activities have been
					detected on your Account(s);
				</IndentedRow>
				<IndentedRow double>
					(vi) enforce terms and conditions applicable to the Website and/or Service; and/or
				</IndentedRow>
				<IndentedRow double>
					(vii) allow us to pursue available remedies or limit the damages we may sustain,
				</IndentedRow>
				<p>
					(o) transmitting to any unaffiliated third parties including our third party service providers,
					agents and other organisations we have engaged, and relevant governmental and/or regulatory
					authorities, whether in Singapore or abroad, for the aforementioned purposes.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>2.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Sharing of Personal Data with third parties.</BoldText>
					Apart from the service providers identified in
					{' '}
					<BoldText>Section 1</BoldText>
					{' '}
					above, we may share
					your Personal Data in the ordinary course of our business with other third parties to use on our
					behalf for purposes that are consistent with this Privacy Policy (including any of the purposes set
					out in
					{' '}
					<BoldText>Clause 2.1</BoldText>
					{' '}
					above). Such third parties include:
				</p>
				<p>
					(a) if you are an End-User, your Company and other End-Users linked to your Company's Account;

				</p>
				<p>
					(b) our related companies such as our subsidiaries and holding company (if any);

				</p>
				<p>
					(c) our lawyers, auditors and professional advisors;

				</p>
				<p>
					(d) service providers who provide services to us (including marketing, email delivery services,
					analytics and search engine providers, website hosting, web design, maintenance, database hosting,
					data analysis, IT services, payment processing, customer service, infrastructure provision,
					logistics, storage, auditing services and other similar services), or to which we may outsource one
					or more aspects of our business;

				</p>
				<p>
					(e) third parties in the event of any reorganisation, merger, sale, joint venture, assignment,
					transfer or other disposition of all or any portion of our business, assets or stock (including in
					connection with any liquidation or similar proceedings, and/or to an acquirer of our business or
					assets or any part thereof); and

				</p>
				<p>
					(f) our partners and investors or potential partners and investors and other parties with a view to
					a
					potential business partnership, collaboration, join venture or otherwise in furtherance of our
					business.

				</p>
				<SmallDivider />
				<p>
					We will only share your Personal Data with such third parties to the extent that this is necessary
					for us to perform our services for you and/or for them to perform their services for us.
				</p>

			</StyledBulletSection>
		</FlexRow>

		<SmallDivider />
		<FlexRow>
			<span><BoldText>3</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>ADDITIONAL INFORMATION WE RECEIVE ABOUT YOU</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>3.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Cookies</BoldText>
				</p>
				<p>
					We may collect data by way of "cookies" when you access and/or use the Website and/or Service. A
					cookie is a small data file that is stored on your computer or mobile device. We use cookies to help
					us improve the quality of access to and/or use of the Website and/or Service, and to provide a more
					tailored and user-friendly service to our visitors and users. For example, we use cookies to:
				</p>
				<p>
					(a) store user preferences and track user trends (such as how people access and/or use the Website
					and/or Service and their access and/or usage preferences);
				</p>
				<p>
					(b) obtain information on how and when pages in the Website and/or Service are accessed and/or used
					and by how many people;
				</p>
				<p>
					(c) help you complete tasks on the Website and/or Service without having to re‑enter information
					when browsing from one page to another or when accessing and/or using the Website and/or Service
					later (e.g. to enable you to enter your Login Codes or billing information less frequently and to
					help us retrieve your configuration settings between uses from our servers); and
				</p>
				<p>
					(d) make the advertisements and content you see more relevant to you.
				</p>
				<SmallDivider />
				<p>
					You can choose to accept or decline cookies. Most web browsers and devices automatically accept
					cookies, but you can usually modify your browser or device settings to decline cookies, delete
					installed cookies or to notify you when you receive a cookie. Doing so will limit the functionality
					we can provide you when you access and/or use the Website and/or Service. You can also opt-out of or
					withdraw your consent to our use of cookies by following the instructions on our Website.


				</p>
				<SmallDivider />
				<p>
					For more information on the types of cookies we use, and the type of information they collect,
					please
					refer to our Cookie Policy.
					<a href="cookiepolicy.html">Cookie Policy</a>
				</p>

			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>3.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Non-Personal Data</BoldText>
				</p>
				<p>
					When you access and/or use the Website and/or Service, we may collect, use, disclose, retain and
					otherwise process (or our systems may automatically collect, use, disclose, retain and process)
					directly or through third party services and applications:


				</p>
				<p>
					(a) log data, including your unique user device number, the IP address of your computer or device,
					information about your computer or mobile internet browser type and version, computer or device
					operating system, the dates and times of your access to and/or use of the Website and/or Service,
					the number and frequency of visitors to the Website, the URL that you just came from and the URL you
					next go to (whether these URLs are on the Website and/or Service or not);


				</p>
				<p>
					(b) session and usage data about your access to and/or use of the Website and/or Service, including
					connection and service-related data such as information relating to the connection request, server
					communication and data sharing, network measurements, quality of service and date, time and location
					of usage; and/or


				</p>
				<p>
					(c) aggregate information about the access to and/or use of the Website and/or Service (which may
					contain log data and session and usage data) in respect of a group or category of services or users
					but which contains no personally identifiable information about the users,


				</p>
				<p>
					(collectively "
					<BoldText>Non-Personal Data</BoldText>
					").
				</p>
				<SmallDivider />
				<p>
					For the avoidance of doubt, "
					<BoldText>Non-Personal Data</BoldText>
					" is information that does not
					identify you or any other individual and does not include Personal Data.


				</p>
				<SmallDivider />
				<p>
					We may use Non-Personal Data for any purpose, including:
				</p>
				<p>
					(a) for our own internal business purposes;

				</p>
				<p>
					(b) to measure traffic patterns;

				</p>
				<p>
					(c) for the purpose of system administration;

				</p>
				<p>
					(d) to enable us to analyse, research and track access to and/or usage of the Website and/or Service
					(including conducting internal research on user demographics, interests, behaviour and trends among
					users);

				</p>
				<p>
					(e) to provide, improve and modify the Website and/or Service;

				</p>
				<p>
					(f) to improve our algorithms, measure Website and/or Service access and/or usage, publish summaries
					online or offline, develop new features such as recognising popular activity routes, areas and
					places; and/or

				</p>
				<p>
					(g) for promotion and marketing purposes.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>4</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>WITHDRAWING CONSENT</BoldText></span>
				<p>
					The consent you provide for the collection, use and disclosure of your Personal Data will remain
					valid until it is withdrawn by you in writing. You may withdraw your consent and request that we
					stop collecting, using, disclosing, retaining and otherwise processing your Personal Data for any or
					all of the purposes listed above by submitting a request in writing to our Data Protection Officer
					at the contact details provided below.
				</p>
				<SmallDivider />
				<p>
					Whilst we respect your decision to withdraw your consent, please note that depending on the nature
					and scope of your request, we may not be in a position to continue to provide access to and/or use
					of the Website and/or Service to you if you withdraw your consent to us collecting, using,
					disclosing, retaining or otherwise processing your Personal Data for certain purposes. In such
					circumstances, we will notify you before completing the processing of your request. Should you
					decide to cancel your withdrawal of consent, please inform our Data Protection Officer in writing at
					the contact details provided below.
				</p>
				<SmallDivider />
				<p>
					Upon receipt of your written request to withdraw your consent, we may require reasonable time
					(depending on the complexity of the request and its impact on our relationship with you) for your
					request to be processed and for us to notify you of the consequences of us acceding to the same,
					including any legal consequences which may affect your rights and liabilities to us. In general, we
					shall seek to process your request within fourteen (14) business days of receiving it. Should we not
					be able to respond to your request within thirty (30) days after receiving your request, we will
					inform you in writing within thirty (30) days of the time by which we will be able to respond to
					your request. If we are unable to accede to your request, we shall generally inform you of the
					reasons why we are unable to do so (except where we are not required to do so under applicable
					laws).
				</p>
				<SmallDivider />
				<p>
					Notwithstanding that you may have withdrawn consent, you acknowledge we may continue to collect,
					use,
					disclose, retain or otherwise process your Personal Data where such collection, use, disclosure,
					retention or processing is permitted or required under applicable laws.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>5</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>YOUR RIGHTS</BoldText></span>
				<p>
					You may exercise the following rights, subject to limits, conditions and exceptions under applicable
					laws. For further information on each of those rights, including the circumstances in which they
					apply and the limits, conditions and exceptions applicable to the exercise of such rights, please
					contact our Data Protection Officer.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>5.1</span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Right to access to Personal Data.</BoldText>
					You have the right to access your Personal
					Data. You may access and download most of your Personal Data through the Account(s) you have with
					us. To request for a copy of your Personal Data that is not available on your Account(s), or for
					information about the ways in which we use or disclose your Personal Data, you may write to our Data
					Protection Officer.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>5.2</span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Right to rectification and addition to Personal Data</BoldText>
					You can correct, delete or
					modify most of the Personal Data using the tools and settings under your Account(s).
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>5.3</span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Right to erasure. </BoldText>
					You have the right to request for the prompt erasure of your
					Personal Data if:
				</p>
				<p>
					(a) it is no longer necessary in relation to the purposes for which it was collected or otherwise
					processed;

				</p>
				<p>
					(b) you withdraw your consent to the processing of Personal Data and there is no other legal ground
					for the processing;
				</p>
				<p>
					(c) you object to the processing of your Personal Data and/or there are no overriding legitimate
					grounds for the processing;
				</p>
				<p>
					(d) your Personal Data has been processed unlawfully;
				</p>
				<p>
					(e) your Personal Data has to be erased for compliance with legal obligations under applicable laws;
				</p>
				<p>
					subject to certain conditions under applicable laws.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>5.4</span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Right to restrict processing.</BoldText>
					You have the right to request that we restrict the processing of your Personal Data in certain
					situations (e.g. if you contest the accuracy of the Personal Data).
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>5.5</span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Right to portability of Personal Data.</BoldText>
					You have the right to obtain your Personal Data in a structured, commonly used, and machine-readable
					format and the right to provide such data to another controller, provided that processing is carried
					out by automated means and based on the performance of an agreement between us or on your consent.
					Please note that under your Account(s), you can download a copy of some of your Personal Data.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>5.6</span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Automated individual decision making. </BoldText>
					You have the right not to be subject to a decision based solely on automated processing (including
					profiling) that produces legal effects concerning you or similarly significantly affects you.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>5.7</span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Right to file complaint with supervisory authority.</BoldText>
					You have the right to file a complaint with the relevant supervisory authority, i.e. the Office for
					Personal Data Protection in the European Union.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>5.8</span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Right to object.</BoldText>
					You have the right to object at any time to the processing of your Personal Data for direct
					marketing purposes and in certain other situations to our continued processing of your Personal
					Data. You can opt out of receiving or unsubscribe from any SMS, email or other marketing
					communications from us by using the unsubscribe facility that is provided with such SMS, email or
					other communication, or by writing in to our Data Protection Officer with your request. Please note
					that if you do opt out of receiving marketing-related communications from us, we may still send you
					important legal or administrative messages, and that you cannot opt out of receiving such messages.
				</p>
				<SmallDivider />
				<p>
					If you would like to exercise any of the above rights, please:

				</p>
				<p>
					(a) inform our Data Protection Officer in writing at the contact details provided below; and

				</p>
				<p>
					(b) provide our Data Protection Officer with enough information to identify you (e.g. your full
					name,
					email address, residential address, contact number, address and customer reference number);

				</p>
				<p>
					(c) provide our Data Protection officer with proof of your identity (e.g. a copy of your driving
					licence, passport or recent utility or credit card bill); and

				</p>
				<p>
					(d) let our Data Protection Officer know what right you want to exercise and the information to
					which
					your request relates.

				</p>
				<SmallDivider />
				<p>
					We will respond to your request as soon as reasonably possible. In general, we shall seek to process
					your request within fourteen (14) business days of receiving it. Should we not be able to respond to
					your request within thirty (30) days after receiving your request, we will inform you in writing
					within thirty (30) days of the time by which we will be able to respond to your request. If we are
					unable to provide you with any Personal Data or to make a correction requested by you, we shall
					generally inform you of the reasons why we are unable to do so (except where we are not required to
					do so under applicable laws).
				</p>
				<SmallDivider />
				<p>
					Please note that a reasonable fee may be charged for requests that you make in connection with the
					above rights, where permitted under applicable laws. If so, we will inform you of the fee before
					processing your request.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>6.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>SECURITY</BoldText></span>
				<p>
					We use commercially reasonable security measures to protect your Personal Data from unauthorised
					access, collection, use, disclosure, copying, modification, disposal or similar risks. We limit
					access to your personal information to those who have a genuine business need to access it. Those
					processing your information will do so only in an authorised manner and are subject to a duty of
					confidentiality.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>7.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>ACCURACY</BoldText></span>
				<p>
					We generally rely on Personal Data provided by you (or your authorised representative). In order to
					ensure that your Personal Data is current, complete and accurate, please update us if there are
					changes to your Personal Data by informing our Data Protection Officer or contacting us at the
					contact details provided below.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span><BoldText>8.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>ACCURACY</BoldText></span>
				<p>
					We will retain your Personal Data (including Personal Data from closed Accounts) for as long as is
					necessary to fulfil the purpose(s) for which it was collected, as required or permitted by
					applicable laws (including to satisfy legal, regulatory, accounting or other regulatory
					requirements) or as we deem necessary and relevant to protect our legitimate interests.
				</p>
				<SmallDivider />
				<p>
					When it is reasonable for us to assume that retention of your Personal Data no longer serves the
					purpose for which the Personal Data was collected, and is no longer necessary for legal, regulatory,
					accounting or business purposes, we will cease to retain your Personal Data, or remove the means by
					which the Personal Data can be associated with you.
				</p>
				<SmallDivider />
				<p>
					We may use any aggregated data derived from or incorporating your Personal Data after you terminate
					your Account(s), but not in a manner that would identify you personally unless permitted by law.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>9.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>WHERE YOUR INFORMATION IS HELD</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />

		<FlexRow>
			<span>9.1</span>
			<StyledBulletSection>
				<p>
					Your Personal Data may be stored at our offices and those of our group companies, third party
					agencies, service providers, representatives and agents as described above. Some of these third
					parties may be based outside your country of residence.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>9.2</span>
			<StyledBulletSection>
				<p>
					<BoldText>Cross-border transfers.</BoldText>
					{' '}
					To deliver services to you, it is sometimes necessary
					for us to transfer your Personal Data out of your country of residence e.g.:


				</p>
				<p>
					(a) to our offices outside your country of residence;

				</p>
				<p>
					(b) to our service providers located outside your country of residence;

				</p>
				<p>
					(c) if you are based outside the country where we are located; and/or

				</p>
				<p>
					(d) where there is an international dimension to the services we are providing to you.

				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>9.3</span>
			<StyledBulletSection>
				<p>
					Where we transfer your Personal Data to countries outside of your country of residence, we will do
					so in accordance with applicable laws, including (where transfers are made outside of Singapore), to
					take steps to ensure that your Personal Data continues to receive a standard of protection that is
					at least comparable to that provided under the Singapore Personal Data Protection Act 2012 (No. 26
					of 2012).
				</p>

			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>9.4</span>
			<StyledBulletSection>
				<p>
					You acknowledge and specifically consent to your Personal Data being transferred to and stored in
					our offices in Singapore and the United States of America, and the locations where our service
					providers store and process your Personal Data, as identified under
					{' '}
					<BoldText>
						Section
						1
					</BoldText>
					{' '}
					above.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>10.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>MINIMUM AGE</BoldText></span>
				<p>
					We do not knowingly collect or solicit Personal Data from those below the Minimum Age, or knowingly
					allow such persons to register for an Account with us. The Service is not intended for use by
					persons under the Minimum Age. If you are under the Minimum Age, please do not attempt to register
					for an Account and/or use the Service, or provide us with any of your Personal Data.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>11.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>CHANGES TO THE PRIVACY POLICY</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>11.1</span>
			<StyledBulletSection>
				<p>
					This Privacy Policy applies in conjunction with any other notices, contractual clauses and consent
					clauses that apply in relation to the collection, use and disclosure of your Personal Data by us.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>11.2</span>
			<StyledBulletSection>
				<p>
					We may revise this Privacy Policy from time to time in the exercise of our sole discretion without
					any prior notice to you (save where required by applicable laws). If we make a change to this
					Privacy Policy that we consider is material, we will endeavour to notify you by:
				</p>
				<p>
					(a) posting the revised Privacy Policy on the Website;
				</p>
				<p>
					(b) displaying the new terms on-screen when you next access and/or use the Service;

				</p>
				<p>
					(c) sending you notice in accordance with the Terms; and/or

				</p>
				<p>
					(d) any combination of the foregoing.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>11.3</span>
			<StyledBulletSection>
				<p>
					Notwithstanding the foregoing, you agree that you are responsible for periodically reviewing this
					Privacy Policy for any changes and keeping yourself informed of the most up-to-date version of this
					Privacy Policy. You may determine if any such revision to this Privacy Policy has taken place by
					referring to the date on which this Privacy Policy was last updated.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<span>11.4</span>
			<StyledBulletSection>
				<p>
					If you do not agree to any revision to this Privacy Policy, you are to stop accessing and/or using
					the Website and/or Service immediately. Your continued use of the Website and/or Service after a
					change has been made will constitute your assent to and acceptance of the revised Privacy Policy.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>12.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>CONTACTING US</BoldText></span>
				<SmallDivider />
				<p>
					Details of the data controller are as follows:
				</p>
				<BoldText>
					Data Controller: Esprow Pte. Ltd.
				</BoldText>
				<p>
					<BoldText>Address (in Singapore)</BoldText>
					: 55 Mohamed Sultan Road, 01-04 Sultan Link, 238995,
					Singapore
				</p>
				<SmallDivider />
				<p>
					If you have any questions or wish to contact us about any matter relating to this Privacy Policy,
					please write to our Data Protection Officer at the contact details below:

				</p>
				<p>
					<BoldText>Data Protection Officer</BoldText>
					: Federico Lo Conte
				</p>
				<p>
					<BoldText>Address</BoldText>
					: 55 Mohamed Sultan Road, 01-04 Sultan Link, 238995, Singapore
				</p>
				<p>
					<BoldText>E-mail</BoldText>
					: administration@esprow.com
				</p>
				<SmallDivider />
				<p>
					Notwithstanding that you may have opted out of communications, or your Account(s) with us have been
					terminated, and/or you have removed your billing information from your Account(s), you acknowledge
					that certain Personal Data may be retained by us for the purpose of complying with relevant laws.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<p>
			<BoldText>
				This Privacy Policy shall be subject to, governed by and construed in accordance with Singapore law.
			</BoldText>
		</p>
		<SmallDivider />
		<p>Effective date: 10 July 2019</p>
		<SmallDivider />
		<p>Last updated: 10 July 2019</p>
	</Wrapper>
);

export default PrivacyPolicy;
