import React from 'react';

import { ReactComponent as OrderBlotter } from '../../assets/icons/sidebar/Orderblotter.svg';
import { ReactComponent as OrderBooks } from '../../assets/icons/sidebar/Orderbooks.svg';
import { ReactComponent as Dictionaries } from '../../assets/icons/sidebar/Dictionaries.svg';
import { ReactComponent as ExchangeBehavior } from '../../assets/icons/sidebar/ExchangeBehavior.svg';
import { ReactComponent as ExchangeEvents } from '../../assets/icons/sidebar/ExchangeEvents.svg';
import { ReactComponent as Latency } from '../../assets/icons/sidebar/Latency.svg';
import { ReactComponent as MarketDataReply } from '../../assets/icons/sidebar/MarketDataReply.svg';
import { ReactComponent as MessageTraffic } from '../../assets/icons/sidebar/MessageTraffic.svg';
import { ReactComponent as ReferenceData } from '../../assets/icons/sidebar/ReferenceData.svg';
import { ReactComponent as PlusCircle } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as TrashCan } from '../../assets/icons/trash.svg';
import { ReactComponent as SettingsSlider } from '../../assets/icons/sliders.svg';
import { ReactComponent as Instance } from '../../assets/icons/instance.svg';
import { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Pause } from '../../assets/icons/pause.svg';
import { ReactComponent as Play } from '../../assets/icons/play.svg';
import { ReactComponent as File } from '../../assets/icons/file-text.svg';
import { ReactComponent as StopCircle } from '../../assets/icons/stop-circle.svg';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as EditPen } from '../../assets/icons/edit-2.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as Sort } from '../../assets/icons/sort.svg';
import { ReactComponent as Ellipses } from '../../assets/icons/ellipses.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as Restore } from '../../assets/icons/restore.svg';

const iconTypes = {
	orderBlotter: OrderBlotter,
	orderBooks: OrderBooks,
	dictionaries: Dictionaries,
	exchangeBehavior: ExchangeBehavior,
	exchangeEvents: ExchangeEvents,
	latency: Latency,
	marketDataReply: MarketDataReply,
	messageTraffic: MessageTraffic,
	referenceData: ReferenceData,
	plusCircle: PlusCircle,
	plus: Plus,
	trashCan: TrashCan,
	settingsSlider: SettingsSlider,
	instance: Instance,
	settings: Settings,
	play: Play,
	pause: Pause,
	file: File,
	stopCircle: StopCircle,
	calendar: Calendar,
	editPen: EditPen,
	download: Download,
	user: User,
	sort: Sort,
	ellipses: Ellipses,
	close: Close,
	arrowLeft: ArrowLeft,
	arrowRight: ArrowRight,
	arrowDown: ArrowDown,
	arrowUp: ArrowUp,
	restore: Restore,
};

const IconComponent = ({ name, ...props }) => {
	const Icon = iconTypes[name];
	if (!Icon) {
		return null;
	}

	return <Icon {...props} />;
};

export default IconComponent;
