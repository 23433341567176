import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

// Reducers
import user from './user/UserReducer';
import ui from './ui/UIReducer';
import exchange from './exchanges/ExchangeReducer';
import behavior from './behavior/BehaviorReducer';
import machineInstance from './machineInstances/MachineInstanceReducer';
import billing from './billing/BillingReducer';
import sideBarReducer from './sidebar/SidebarReducer';
import dictionaries from './exchangeDictionaries/DictionariesReducer';
import websockets from './websockets/reducer';
import replay from './replay/replayReducer';

export default history => combineReducers({
	router: connectRouter(history),
	billing,
	user,
	ui,
	exchange,
	dictionaries,
	machineInstance,
	sideBarReducer,
	behavior,
	websockets,
	replay,
});
