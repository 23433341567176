import UI_TYPES from './UITypes';
import { MODALS } from '../../lib/constants/General';
import { clearAddOrderBookError } from '../orderBooks/OrderBooksActions';

export function toggleModal(modal, visible, data = {}) {
	return async dispatch => dispatch({
		type: UI_TYPES.TOGGLE_MODAL,
		payload: {
			modal,
			visible,
			data,
		},
	});
}
export function updateModal(modal, data = {}) {
	return async dispatch => dispatch({
		type: UI_TYPES.UPDATE_MODAL,
		payload: {
			modal,
			data,
		},
	});
}
export function toggleSessionExpireModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.SESSION_EXPIRE_MODAL, visible, data));
}
export function toggleCreateExchangeModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.CREATE_EXCHANGE_MODAL, visible, data));
}
export function toggleExchangeDictionariesModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.EXCHANGE_DICTIONARY_MODAL, visible, data));
}
export function toggleMessageTrafficModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.MESSAGE_TRAFFIC_MODAL, visible, data));
}
export function toggleStopExchangeModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.STOP_EXCHANGE_MODAL, visible, data));
}
export function toggleChooseExtensionModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.CHOOSE_EXCHANGE_EXTENSION, visible, data));
}

export function toggleAddExchangeModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.ADD_EXCHANGE_MODAL, visible, data));
}

export function toggleAddOrderBookModal(visible, data) {
	return (dispatch) => {
		if (!visible) {
			dispatch(clearAddOrderBookError());
		}
		dispatch(toggleModal(MODALS.ADD_ORDER_BOOK_MODAL, visible, data));
	};
}
export function toggleEditOrderBookModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.EDIT_ORDER_BOOK_MODAL, visible, data));
}
export function toggleAddTradingGatewayModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.ADD_TRADING_GATEWAY_MODAL, visible, data));
}
export function toggleEditTradingGatewayModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.EDIT_TRADING_GATEWAY_MODAL, visible, data));
}
export function toggleAddSessionModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.ADD_SESSION_MODAL, visible, data));
}
export function toggleEditSessionModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.EDIT_SESSION_MODAL, visible, data));
}
export function toggleAddOrderBlotterModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.ADD_ORDER_BLOTTER_MODAL, visible, data));
}
export function toggleBillingValidationModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.BILLING_VALIDATION_MODAL, visible, data));
}
export function toggleChangeBehaviorModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.CHANGE_BEHAVIOR_MODAL, visible, data));
}
export function toggleModifyOrderModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.MODIFY_ORDER_MODAL, visible, data));
}

export function toggleContactFormModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.CONTACT_FORM_MODAL, visible, data));
}
export function toggleMultiFillModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.MultiFillModal, visible, data));
}

export function toggleStartingExchangeModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.STARTING_EXCHANGE_MODAL, visible, data));
}

export function togglePendingSubscriptionModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.PENDING_SUBSCRIPTION_MODAL, visible, data));
}
export function toggleShortcutsModal(visible, data) {
	return dispatch => dispatch(toggleModal(MODALS.SHORTCUTS_MODAL, visible, data));
}
