import { css } from 'styled-components';
import { SortIcon } from '../Organisms/Table/Atoms/TableAtoms';

export const SortableTableCSS = css`
	table{
		font-size: 14px !important;
		tr > td{
			:first-child{
				padding-left: 15px;
			}
		}
		tr > th{
			:first-child{
				padding-left: 15px;
			}
		}
	}
`;
