import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Dummy Data
import { connect, useDispatch } from 'react-redux';
import { List } from 'immutable';
import { header as orderBlotterHeader } from '../../../data/placeholderData/orderBlotterTableData';
// Styles
import { BaseBlockStyle } from '../../Css/Css';
import { SortableTableCSS } from '../../Css/Table';
// Organisms
import TableView from '../Table/TableView';
import { getActiveExchange } from '../../../data/exchanges/selectors';
import { formatTradeTimeStamp } from '../../../lib/helpers';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';
import useSortBy from '../../Hooks/useSortBy';
import { StyledOrderSide } from '../../Atoms/ExchangeAtoms/OrderBlotter';
import { getBlotterStatus } from '../../../lib/orderBlotter';
import { setClickedRowTrades } from '../../../data/exchanges/ExchangesActions';
import { stringToNumber } from '../../../lib/exchange';
import { NoContentInfo } from '../../Atoms/Text';

const OrderBlotterTableWrapper = styled.div`
	${SortableTableCSS};
	${BaseBlockStyle};
	position: relative;
	height: 100%;
	padding: 30px 15px 10px 30px;
  	box-shadow: 0px 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
  	z-index: 33;
`;

function OrderBlotterTable(props) {
	const { blotters: blottersMap, selectedMember, loading } = props;
	const [tableHeader, setTableHeader] = useState([...orderBlotterHeader]);
	const [selectedRow, setSelectedRow] = useState(-1);
	const dispatch = useDispatch();
	const blotters = useMemo(() => blottersMap?.toList().reduce((acc, value) => acc.push(...value.filter(v => v.get('memberId') === selectedMember.key)), List()), [blottersMap, selectedMember]);

	const handleHeaderVisibilityChange = useCallback((header) => {
		setTableHeader((prevState) => {
			const newState = [...prevState];
			const index = newState.findIndex(item => item.name === header);
			newState[index].visible = !newState[index].visible;
			return newState;
		});
	}, []);
	const getDataToRender = useCallback(r => r?.map((row, i) => ({
		data: [
			row.get('instrumentCode'),
			{
				render: () => (
					<StyledOrderSide side={row.get('side')}>
						{row.get('side').toLowerCase()}
					</StyledOrderSide>
				),
				accessor: row.get('side'),
			},
			getBlotterStatus(row.get('quantity'), row.get('totalQuantity')),
			row.get('ordType'),
			{
				render: () => <p>{row.get('quantity')}</p>,
				accessor: stringToNumber(row.get('quantity')),
			},
			{
				render: () => <p>{row.get('price')}</p>,
				accessor: stringToNumber(row.get('price')),
			},
			{
				render: () => <p>{row.get('avgPrice')}</p> || 0,
				accessor: stringToNumber(row.get('avgPrice')) || 0,
			},
			row.get('orderId'),
			formatTradeTimeStamp(row.get('receivedDateTime').get('seconds'), row.get('receivedDateTime').get('nanos')),
		],
		metaData: {
			key: row.get('orderId'),
			order: row,
			index: i,
		},
	})), []);

	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, blotters);
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, blotters);

	const handleRowClick = useCallback((row) => {
		const instrumentCode = row.metaData.order.get('instrumentCode');
		const orderId = row.metaData.order.get('orderId');
		setSelectedRow(row.metaData.index);
		dispatch(setClickedRowTrades(instrumentCode, orderId));
	}, [dispatch]);

	useEffect(() => {
		setSelectedRow(-1);
	}, [dataToRender]);

	return (
		<OrderBlotterTableWrapper className="order-blotter-table">
			<TableView
				selectedRow={selectedRow}
				sortedBy={sortedBy}
				rowClick={handleRowClick}
				handleSort={handleSort}
				header={tableHeader}
				rows={loading ? [] : dataToRender}
				selectable={false}
				handleHeaderVisibilityChange={handleHeaderVisibilityChange}
				hasSettings
				headerHeight="25px"
				rowHeight="32px"
				withContextMenu
			/>
			{loading && <NoContentInfo absolute>Loading orders...</NoContentInfo>}
			{!loading && dataToRender?.size === 0 && <NoContentInfo absolute>No orders</NoContentInfo>}
		</OrderBlotterTableWrapper>
	);
}
function mapStatToProps(state) {
	const activeExchange = getActiveExchange(state);
	return {
		selectedMember: activeExchange?.get('selectedMember'),
		blotters: activeExchange?.get('blotters'),
	};
}
export default connect(mapStatToProps, null)(OrderBlotterTable);

OrderBlotterTable.propTypes = {
	selectedMember: PropTypes.shape({}).isRequired,
	blotters: PropTypes.shape({}).isRequired,
};
