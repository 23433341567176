import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { toggleAddExchangeModal } from '../../../../data/ui/UIActions';
import { addExchange } from '../../../../data/billing/BillingActions';
// Constants
import { MODAL_SIZES, MODALS } from '../../../../lib/constants/General';

// Atoms
import { FlexRow } from '../../../Atoms/Flex';
import {
	Line, MediumDivider, MediumVerticalDivider, BaseDivider, SmallestDivider,
} from '../../../Atoms/Divider';
import { BoldText, Title } from '../../../Atoms/WebSite/Text';
import { PrimaryButton, WhiteGrayButton } from '../../../Atoms/Button';

// Molecules
import Modal from '../../../Molecules/Modal';
import SelectBox from '../../../Molecules/Input/SelectBox/SelectBox';
import Counter from '../../../Molecules/Input/Counter/Counter';

class AddExchangeModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedType: {
				render: '',
				key: '',
			},
			sessions: 0,
			total: 0,
		};
	}

	closeModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleAddExchangeModal(false);
	};

	// TODO NMNJ:REFACTOR THIS PIECE OF SHIT
	calculateTotal = () => {
		const {
			generalPrices,
		} = this.props;
		const {
			sessions,
			selectedType,
		} = this.state;

		if (!selectedType.key) {
			return 0;
		}
		const exchangePrice = generalPrices.getIn([selectedType.key, 'exchangePrice']);
		return parseInt(exchangePrice, 10) + sessions * parseInt(generalPrices.getIn([selectedType.key, 'sessionPrice']), 10);
	};

	handleTypeChange = async (type) => {
		await this.setState({
			selectedType: type,
		});
		this.setState({
			total: this.calculateTotal(),
		});
	};

	handleSessionCounterChange = async (counter) => {
		await this.setState({
			sessions: counter,
		});
		this.setState({
			total: this.calculateTotal(),
		});
	};

	handleAddExchange = () => {
		const {
			actions,
		} = this.props;
		const {
			selectedType,
			sessions,
		} = this.state;
		actions.addExchange({
			sessions,
			type: selectedType.key,
		});

		this.setState({
			selectedType: {
				render: '',
				key: '',
			},
			sessions: 0,
			total: 0,
		});
		this.closeModal();
	};

	handleCancel = () => {
		this.closeModal();
		this.setState({
			sessions: 0,
			selectedType: {},
			total: 0,
		});
	};

	render() {
		const {
			modalInfo,
			generalPrices,
			exchangeNames,
		} = this.props;
		const {
			selectedType,
			sessions,
			total,
		} = this.state;
		const selectBoxValues = [];
		const modalInfoJS = modalInfo.toJS();
		generalPrices.map((item) => {
			selectBoxValues.push({
				render: exchangeNames.get(item.get('type')) || item.get('type'),
				key: item.get('type'),
			});
		});

		selectBoxValues.sort((a, b) => {
			if (a.render < b.render) { return -1; }
			if (a.render > b.render) { return 1; }
			return 0;
		});

		if (modalInfoJS.visible !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo.get('visible')}
				closeModal={this.closeModal}
				size={MODAL_SIZES.SMALL}
			>
				<Title>
					Add New Exchange
				</Title>
				<BaseDivider />
				<SelectBox
					className="subscription-add-exchange-dialog-protocol-type"
					height={50}
					label="Protocol type"
					onClick={this.handleTypeChange}
					selected={selectedType}
					options={selectBoxValues}
				/>
				<BaseDivider />
				<FlexRow
					justifyContent="space-between"
					className="subscription-add-exchange-dialog-sessions"
				>
					<span>Number of Sessions</span>
					<Counter
						initialValue={0}
						onChange={this.handleSessionCounterChange}
					/>
				</FlexRow>
				<MediumDivider />
				{selectedType.key && (
					<>
						<FlexRow justifyContent="space-between">
							<span>Protocol:</span>
							<span>
								$
								{generalPrices.getIn([selectedType.key, 'exchangePrice'])}
							</span>
						</FlexRow>
						<SmallestDivider double />
						<FlexRow justifyContent="space-between">
							<span>Sessions:</span>
							<span>
								$
								{(generalPrices.getIn([selectedType.key, 'sessionPrice']) * sessions).toFixed(1)}
							</span>
						</FlexRow>
					</>
				)}
				<MediumDivider />
				<Line />
				<BaseDivider />
				<FlexRow
					justifyContent="space-between"
					className="subscription-cost"
				>
					<BoldText>Total cost:</BoldText>
					<BoldText>
						$
						{total.toFixed(1)}
					</BoldText>
				</FlexRow>
				<BaseDivider />
				<Line />
				<MediumDivider />
				<FlexRow justifyContent="center">
					<PrimaryButton
						fillspace
						disabled={!selectedType.key || sessions === 0}
						onClick={this.handleAddExchange}
						className="subscription-add-button"
					>
						Add
					</PrimaryButton>
					<MediumVerticalDivider />
					<WhiteGrayButton
						fillspace
						onClick={this.handleCancel}
					>
						Cancel
					</WhiteGrayButton>
				</FlexRow>
			</Modal>
		);
	}
}

AddExchangeModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleAddExchangeModal: PropTypes.func,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.ADD_EXCHANGE_MODAL]),
		generalPrices: state.getIn(['billing', 'generalPrices', 'data']),
		exchangeNames: state.getIn(['exchange', 'exchangeNames']),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleAddExchangeModal,
			addExchange,
		}, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExchangeModal);
