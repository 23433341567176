import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { togglePendingSubscriptionModal } from '../../../../data/ui/UIActions';
import { abortSubscription, repeatSubscription } from '../../../../data/billing/BillingActions'
	;
// Constants
import { MODAL_SIZES, MODALS } from '../../../../lib/constants/General';

// Atoms
import { FlexRow } from '../../../Atoms/Flex';
import { PrimaryButton, SecondaryButton } from '../../../Atoms/Button';
import { BaseVerticalDivider, MediumDivider } from '../../../Atoms/Divider';
import { Title } from '../../../Atoms/WebSite/Text';

// Molecules
import Modal from '../../../Molecules/Modal';

class PendingSubscriptionModal extends PureComponent {
	closeModal = () => {
		const {
			actions,
		} = this.props;
		actions.togglePendingSubscriptionModal(false);
	};
	repeatSubscription = async () => {
		const {
			actions,
		} = this.props;
		const url = await actions.repeatSubscription();
		if (url.length > 0) {
			window.location.replace(url);
		}
	};

	abortSubscription = () => {
		const {
			actions,
		} = this.props;
		actions.abortSubscription();
		this.closeModal();
	};

	render() {
		const {
			modalInfo,
		} = this.props;
		if (modalInfo.get('visible') !== true) {
			return false;
		}
		return (
			<Modal
				showModal={modalInfo.get('visible')}
				size={MODAL_SIZES.MEDIUM}
				hasCloseIcon={false}
				closeModal={() => {}}
			>
				<Title>
					Process Pending Subscription
				</Title>
				<MediumDivider />
				<div>You have a pending subscription. Do you want repeat or remove it?</div>
				<MediumDivider />
				<FlexRow justifyContent="flex-end">
					<PrimaryButton onClick={this.repeatSubscription}>
						Repeat
					</PrimaryButton>
					<BaseVerticalDivider />
					<SecondaryButton onClick={this.abortSubscription}>
						Remove
					</SecondaryButton>
				</FlexRow>
			</Modal>
		);
	}
}

PendingSubscriptionModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleBillingValidationModal: PropTypes.func,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.PENDING_SUBSCRIPTION_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			togglePendingSubscriptionModal,
			abortSubscription,
			repeatSubscription,
		}, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingSubscriptionModal);
