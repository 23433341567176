import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// CSS
import { BaseText, BaseTransition } from '../Css/Css';

// Lib
import { COLORS, FONT_SIZES } from '../../lib/constants/Style';

// Atoms
import { baseButton } from './Button';

const linkReset = css`
	text-decoration: none;
`;

export const baseLink = css`
	${linkReset};
	${BaseTransition};
	font-size: ${FONT_SIZES.baseSmall};
	user-select: text;
	color: ${COLORS.darkGrey};
	&:hover {
		color: ${COLORS.lightBlack};
	}
	${props => props.active && css`
		color: ${COLORS.yellow};
	`}
`;

export const alternateLink = css`
	${linkReset};
	${BaseTransition};
	${FONT_SIZES.base};
	user-select: text;
	color: ${COLORS.lightBlack};
	&:hover {
		color: ${COLORS.yellow};
	}
`;

export const BaseLink = styled(Link)`
	${baseLink};
`;

export const BaseExternalLink = styled.a`
	${baseLink};
`;

export const AlternateLink = styled(Link)`
	${alternateLink};
`;

export const PublicExternalLink = styled.a`
	${linkReset};
	${BaseTransition};
	${BaseText};
	color: ${COLORS.yellow};
	&:hover {
		color: ${COLORS.lightBlack};
	}
`;

export const PrimaryButtonLink = styled(Link)`
	${baseButton};
	white-space: nowrap;
	color: ${COLORS.black};
	background-color: ${COLORS.yellow};
	&:hover {
		background-color: ${COLORS.lightYellow};
	}
`;
