import styled from 'styled-components';
import { COLORS } from '../../../../lib/constants/Style';
import { BaseTransition } from '../../../Css/Css';
import IconComponent from '../../../Atoms/Icon';

export const PlusIcon = styled(IconComponent)`
	width: 12px;
	height: 12px;
`;

export const MinusIcon = styled.span`
	width: 6px;
	height: 1px;
	background: ${COLORS.darkGrey};
`;

export const CounterButton = styled.div`
	font-weight: 600;
	min-height: 32px;
	min-width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${COLORS.lighterGrey};
	color: ${COLORS.darkGrey};
	${BaseTransition};
	border-radius: 4px;
	cursor: pointer;
	${props => (props.active || 'pointer-events: none')};

	&:hover {
		background: ${COLORS.grey};
		color: ${COLORS.darkestGrey};
		
		${MinusIcon} {
			background: ${COLORS.darkestGrey};
		}
	}
`;

export const InitialValue = styled.span`
	user-select: text;
	color: ${COLORS.grey};
`;

export const CounterValue = styled.div`
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
