import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import PropTypes from 'prop-types';
import { BaseText, TextBaseSmall, ZIndex } from '../Css/Css';
import IconComponent from '../Atoms/Icon';
import { COLORS } from '../../lib/constants/Style';
import { FlexColumnAlignCenterCss } from '../Atoms/Flex';

const CalendarIcon = styled(IconComponent)`
	position: absolute;
	right: 5px;
	top: 9px;
	width: 17px;
	height: 17px;
	color: ${COLORS.darkGrey};
	${props => props.big && css`
		top: 16px;
	`};
`;

const DatePickerStyles = styled.div`
	width: 100%;
	${BaseText};
	position: relative;
	color: ${COLORS.lightBlack};
	& input {
	${props => props.big && 'height: 50px;'};
	}
	.react-datepicker-popper{
		position: fixed !important;
		top: auto !important;
		left: auto !important;
		transform: translate3d(-16px, -358px, 0px);
		${ZIndex.high};
	}
	.react-datepicker{
		width: 420px;
		border: unset;
  		border-radius: 4px;
  		box-shadow: 0px 2px 23.5px 0.5px rgba(0, 0, 0, 0.1);
  		background-color: #ffffff;
	}
	.react-datepicker__header{
		border: unset;
		background-color: #ffffff;
		padding: 19px 0 10px 0;
		${FlexColumnAlignCenterCss};
	}
	.react-datepicker__current-month{
		font-size: 16px;
  		font-weight: normal;
	}
	.react-datepicker__day-names{
		padding-top: 10px;
		display: flex;
		flex: 1;
	}
	.react-datepicker__day-name{
		margin: 0.4rem 0.4rem 0.4rem 0.8rem;
		width: 2.5rem;
	  	height: 1rem;
	  	text-align: center;
	}
	.react-datepicker__day{
		margin: 0.4rem 0.4rem 0.4rem 0.8rem;
		width: 2.5rem;
	  	height: 2.5rem;
	  	display: inline-flex;
	  	justify-content: center;
	  	align-items: center;
	  	flex: 1;
	  	:hover{
	  		border-radius: 100%;
	  	}
	}
	.react-datepicker__day--selected{ 
		margin: 0.4rem 0.4rem 0.4rem 0.8rem;
		width: 2.5rem;
	  	height: 2.5rem;
	  	background-color: #ffc10e;
	  	border-radius: 100%;
	  	display: inline-flex;
	  	justify-content: center;
	  	align-items: center;
	  	:hover{
			background-color: gray;
			border-radius: 100%;
		}
	}
	.react-datepicker__day--keyboard-selected{
		margin: 0.4rem 0.4rem 0.4rem 0.8rem;
		width: 2.5rem;
	  	height: 2.5rem;
	  	background-color: #ffc10e;
	  	border-radius: 100%;
	  	display: inline-flex;
	  	justify-content: center;
	  	align-items: center;
	  	:hover{
			background-color: gray;
			border-radius: 100%;
		}
	}
	.react-datepicker__input-container > input{
		width: 100%;
  		height: 36px;
  		border-radius: 4px;
  		border: solid 1px #e2eaf0;
 	 	background-color: #ffffff;
  		font-size: 14px;
  		font-weight: normal;
 		font-stretch: normal;
 		font-style: normal;
  		line-height: 1.29;
  		letter-spacing: normal;
  		text-align: left;
  		color: ${COLORS.black};
  		padding-left: 10px;
  		&::placeholder{
  			${TextBaseSmall};
			color: #8c959d;
  		}
  		${props => props.big && 'height: 50px;'};
  	
	}

	.react-datepicker__week{
		display: flex;
	}
	
	.react-datepicker-wrapper{
		width: 100%;
	}
	.react-datepicker__month{
		margin: unset;
	}
	.react-datepicker__navigation--previous {
		left: 20px;
		top: 20px;
	}
	.react-datepicker__navigation--next{
		right: 20px;
		top: 20px;
	}
	.react-datepicker__day--outside-month{
		visibility: hidden;
	}
`;
/**
 *
 * @param startDate {Date} - initially selected date
 * @param setStartDate {function} - function called when selected date changes
 * @param big {Boolean} -
 * @param placeholder {String} - placeholder for input
 */
export const StyledDatePicker = ({
	startDate, setStartDate, placeholder, big,
}) => (
	<DatePickerStyles big={big}>
		<DatePicker
			selected={startDate}
			onChange={date => setStartDate(date)}
			placeholderText={placeholder}
			showPopperArrow={false}
			popperClassName="some-custom-class"
			popperPlacement="top-end"
			popperModifiers={{
				offset: {
					enabled: true,
					offset: '5px, 10px',
				},
				preventOverflow: {
					enabled: true,
					escapeWithReference: false,
					boundariesElement: 'viewport',
				},
			}}
		/>
		<CalendarIcon name="calendar" big={big} />
	</DatePickerStyles>
);

StyledDatePicker.propTypes = {
	startDate: PropTypes.shape({}),
	setStartDate: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	big: PropTypes.bool,
};
StyledDatePicker.defaultProps = {
	startDate: undefined,
	big: false,
};
