import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Validator from 'validator';

// Atoms
import {
	BaseInputTextArea, ErrorMessage, InputElement, InputLabel, InputWrapper,
} from './Input.atoms';

// To use the inputs you need to set ref to that element.
// Use ref.current.hasError() to check if error exist on that field.
// Use ref.current.getValue() to read input value.

const Input = forwardRef((props, ref) => {
	const {
		validators, name, type, placeholder, smaller, onChange, initialValue,
	} = props;

	const [inputValue, setInputValue] = useState(initialValue);
	const [errors, setErrors] = useState([]);

	// returning values and errors to parent
	useImperativeHandle(ref, () => ({
		hasError: () => {
			const newErrors = validators.filter(validator => Validator[validator.validator](inputValue, validator.options || {}) === validator.errorResult);
			setErrors(newErrors);
			return newErrors.length > 0;
		},
		getValue: () => inputValue,
		resetState: () => {
			setInputValue('');
			setErrors([]);
		},
		setValue: value => setInputValue(value),
	}));

	// trigger on value change
	const handleOnChange = (event) => {
		const { value } = event.target;
		const newErrors = validators.filter(validator => Validator[validator.validator](value, validator.options || {}) === validator.errorResult);
		setErrors(newErrors);
		setInputValue(value);
		onChange(value);
	};
	if (type === 'textarea') {
		return (
			<>
				<BaseInputTextArea
					ref={ref}
					type={type}
					name={name}
					placeholder={placeholder}
					value={inputValue}
					onChange={event => handleOnChange(event)}
					error={(errors.length > 0)}
				/>
				<ErrorMessage>
					{(errors.length > 0) ? errors[0].message : ''}
				</ErrorMessage>
			</>
		);
	}
	return (
		<>
			<InputWrapper>
				<InputElement
					type={type}
					value={inputValue}
					name={name}
					onChange={event => handleOnChange(event)}
					error={(errors.length > 0)}
					ref={ref}
					smaller={smaller}
				/>
				<InputLabel smaller={smaller}>
					{placeholder}
				</InputLabel>
			</InputWrapper>
			<ErrorMessage>
				{(errors.length > 0) ? errors[0].message : ''}
			</ErrorMessage>
		</>
	);
});

Input.defaultProps = {
	validators: [],
	placeholder: null,
	smaller: false,
	onChange: () => {},
	initialValue: '',
};

Input.propTypes = {
	onChange: PropTypes.func,
	smaller: PropTypes.bool,
	placeholder: PropTypes.string,
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	validators: PropTypes.arrayOf(PropTypes.object),
	initialValue: PropTypes.string,
};

export default Input;
