import styled, { css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

// Lib
import { COLORS } from '../../../lib/constants/Style';
import { DEVICE } from '../../../lib/constants/Device';

// Atoms
import {
	FlexColumnSpaceBetween, FlexColumnAlignCenterCss, FlexRowBase, FlexColumn,
} from '../../Atoms/Flex';
import { baseLink } from '../../Atoms/Link';
import IconComponent from '../../Atoms/Icon';

// CSS
import { BaseTransition, FontFamilyExo, TextBase, ZIndex } from '../../Css/Css';

// App items
export const AppWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	${FontFamilyExo};
	padding-left: 70px;
	
	${props => props.userProfileVisible && css`
		padding-left: 0;
	`};
`;

export const AppContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 100%;
	width: 100%;
	background: ${COLORS.lightestGrey};
`;

export const AppScrollable = styled.div`
	${FlexColumnSpaceBetween};
	align-items: flex-start;
	justify-content: flex-start;
	height: calc(100vh - 130px);
	overflow-y: auto;
	width: 100%;
	padding: 0px 20px;
	@media ${DEVICE.laptopL} {
		height: calc(100vh - 110px);
	}
`;

export const SideMenuWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	${ZIndex.mediumTwo};
	
	${props => props.userProfileVisible && css`
		position: relative;
	`};
`;

export const SideMenuContent = styled.div`
	${FlexColumnAlignCenterCss};
	${BaseTransition};
	height: 100%;
	width: 70px;
	position: relative;
	background: ${COLORS.darkestGrey};
	overflow: hidden;
	${props => props.expanded && css`
		width: 260px;
	`}
`;

export const SideMenuScrollableWrapper = styled.div`
	width: 100%;
	height: 100%;
	background: ${COLORS.lightBlack};
	${FlexColumnAlignCenterCss};
`;

// Site items
export const SiteWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
`;

export const SiteContent = styled.div`
	width: 100%;
`;

export const HeaderWrapper = styled.div`
	${FlexRowBase};
	${TextBase};
	justify-content: space-between;
	background: white;
	height: 70px;
	z-index: 100;
	width: 100%;
	position: relative;
	border-bottom: 1px solid ${COLORS.lightGrey};
`;

const HeaderRightItemsWrapperPublic = css`
	flex-direction: row-reverse;
	justify-content: space-between;
`;

export const HeaderRightItemsWrapper = styled.div`
	${FlexRowBase};
	width: 100%;
	justify-content: flex-end;
	> a {
		margin-right: 40px;
	}

	@media ${DEVICE.tablet} {
		${prop => (prop.isPublic ? 'display: none' : '')}
		${prop => (prop.reverse ? HeaderRightItemsWrapperPublic : '')}
	}
`;

export const HeaderLeftSide = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

export const SiteMenuLink = styled(Link)`
	padding: 0 20px;
	user-select: text;

	:hover {
		color: #ffc10e;
	}
`;

export const SiteMenuWrapper = styled.div`
	margin-left: 100px;

	@media ${DEVICE.tablet} {
		display: none;
	}
`;

// Sidebar Item
export const SideMenuItem = styled(NavLink)`
	${FlexRowBase};
	${baseLink};
	color: ${COLORS.grey};
	width: 100%;
	position: relative;
	flex-wrap: nowrap;
	padding-left: 22px;
	white-space: nowrap;
	margin-bottom: 25px;
	&:hover {
		color: ${COLORS.white};
		${IconComponent} {
			* {
				stroke: ${COLORS.white};
			}
		}
	}
	&.active {
		color: ${COLORS.yellow};
		${IconComponent} {
			* {
				stroke: ${COLORS.yellow};
			}
		}
	}
`;

export const SideMenuItemText = styled.p`
	left: 70px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
`;


// Hamburger specific Atoms

export const MobileMenuWrapper = styled(FlexColumn)`
	display:none;
	margin-right: 20px;
	cursor: pointer;

	@media ${DEVICE.tablet} {
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}
`;

const BarItemTopCss = css`
	transform: rotate(45deg) translate(4px, 4px);
	border-radius: 4px;
`;
const BarItemBottomCss = css`
	transform: rotate(-45deg) translate(6px, -6px);
	 border-radius: 4px;
`;

export const BarItemTop = styled.div`
  	transform: rotate(0deg);
  	transition: 250ms ease-in;
	width: 20px;
	height: 3px;
	background-color: #000000;
	margin: 2px;
	${props => (props.opened ? BarItemTopCss : '')};
`;

export const BarItemMiddle = styled(BarItemTop)`
	${props => (props.opened ? 'opacity: 0; width: 0' : '')};
`;

export const BarItemBottom = styled(BarItemTop)`
	${props => (props.opened ? BarItemBottomCss : '')};
`;

export const OpenedHamburgerMenuWrapper = styled.div`
	display: none;
	position: fixed;
	overscroll-behavior: contain;

	@media ${DEVICE.tablet} {
		background: #fff;
		display: block;
		width: 100%;
		height: calc(100vh - 70px);
		overflow: auto;
		top: 70px;
		left: 0;
		z-index: 4;
	}
`;

export const LinkWrapper = styled.div`
	margin-left: 20px;
`;

export const MenuWrapper = styled(OpenedHamburgerMenuWrapper)`
	@media ${DEVICE.tablet} {
		z-index: 20;
	}
`;

export const HamburgerHeaderWrapper = styled.div`
	padding: 30px calc(15% - 40px);
	background: ${COLORS.lightestGrey};
`;

export const HamburgerFooterWrapper = styled.div`
	background-color: white;
	padding: 70px 15% 70px 15%;
`;

export const FooterLink = styled.a`
	${baseLink};
	margin-right: 25px;

	@media ${DEVICE.tabletL} {
		font-size: 14px;
	}
`;
