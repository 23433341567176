import * as Paho from 'paho-mqtt';
import EVENT from './constants/Websockets';
import { showError } from './helpers';

export function requestOrderBookStatus(wsClient, fixVersion, sessionId, orderBookIds,) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = { symbols: orderBookIds };
	payload.requestName = EVENT.OrderBookStatusRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}

export function requestExchangeStatus(wsClient, fixVersion, sessionId) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {};
	payload.requestName = EVENT.ExchangeStatusRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);

	// this code is same as one above
	// wsClient.publish(topic, jsonString);
}

export function requestExchangeStop(wsClient, fixVersion, sessionId) {
	try {
		if (wsClient && wsClient.isConnected()) {
			const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
			const payload = {};
			payload.sessionId = sessionId;
			payload.payload = {};
			payload.requestName = EVENT.StopExchangeRequest;

			const message = new Paho.Message(JSON.stringify(payload));
			message.destinationName = topic;
			wsClient.send(message);
		}
	} catch (e) {
		showError('Error', 'You are not connected');
	}
}

export function requestExchangeOpen(wsClient, fixVersion, sessionId) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {};
	payload.requestName = EVENT.OpenExchangeRequest;

	const message = new Paho.Message(JSON.stringify(payload));
	message.destinationName = topic;
	wsClient.send(message);
}

export function requestExchangeClose(wsClient, fixVersion, sessionId) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {};
	payload.requestName = EVENT.CloseExchangeRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}
export function submitOrder(wsClient, fixVersion, sessionId, data) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/command`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {
		hit: false,
		...data,
	};
	payload.requestName = EVENT.OrderBookAddOrderRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}

export function requestOrderBlotterData(wsClient, fixVersion, sessionId) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {
		symbol: '',
	};
	payload.requestName = EVENT.OrderBookBlotterRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}

export function exchangeBehaviorChangeRequest(wsClient, fixVersion, sessionId, data) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = data;
	payload.requestName = EVENT.ExchangeBehaviorChangeRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}

export function orderBookExportRequest(wsClient, fixVersion, sessionId, symbol) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = { symbol };
	payload.requestName = EVENT.OrderBookExportRequest;
	const jsonString = JSON.stringify(payload);
	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}

export function exchangeBehaviorStatusRequest(wsClient, fixVersion, sessionId, data) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = data;
	payload.requestName = EVENT.ExchangeBehaviorStatusRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}

export function requestOrderBookImage(wsClient, fixVersion, sessionId, symbol) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {
		symbol,
	};
	payload.requestName = EVENT.OrderBookImageRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}

export function orderBookTradeRequest(wsClient, fixVersion, sessionId, symbol) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {
		symbol,
	};
	payload.requestName = EVENT.OrderBookTradeRequest;

	const message = new Paho.Message(JSON.stringify(payload));
	message.destinationName = topic;
	wsClient.send(message);
}

export function orderBookTradeTickerRequest(wsClient, fixVersion, sessionId, symbol) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {
		symbol,
	};
	payload.requestName = EVENT.TradeTickerRequest;

	const message = new Paho.Message(JSON.stringify(payload));
	message.destinationName = topic;
	wsClient.send(message);
}

export function orderBookBBBORequest(wsClient, fixVersion, sessionId, symbol) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {
		symbol,
	};
	payload.requestName = EVENT.OrderBookBBBORequest;

	const message = new Paho.Message(JSON.stringify(payload));
	message.destinationName = topic;
	wsClient.send(message);
}

export function clearOrderBook(wsClient, fixVersion, symbol) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.payload = {
		symbol,
	};
	payload.requestName = EVENT.CloseAllOrdersInOrderBookClearTradesRequest;

	const message = new Paho.Message(JSON.stringify(payload));
	message.destinationName = topic;
	wsClient.send(message);
}

export function hitOrder(wsClient, fixVersion, sessionId, data) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/command`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = data;
	payload.requestName = 'OrderBookAddOrderRequest';
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}
export function cancelOrder(wsClient, fixVersion, sessionId, data) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/command`;

	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = data;
	payload.requestName = EVENT.OrderBookCancelOrderRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}

export function modifyOrder(wsClient, fixVersion, sessionId, data) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/command`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = data;
	payload.requestName = EVENT.OrderBookAmendOrderRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}
export function requestTickerPlantMessage(wsClient, fixVersion, sessionId) {
	const topic = `com/esprow/etp/gems/${fixVersion}/ctrlgwy/request/data`;
	const payload = {};
	payload.sessionId = sessionId;
	payload.payload = {
		beginSeqNo: 1,
		endSeqNo: -1,
	};
	payload.requestName = EVENT.TickerPlantResendPacketMessagesRequest;
	const jsonString = JSON.stringify(payload);

	const message = new Paho.Message(jsonString);
	message.destinationName = topic;
	wsClient.send(message);
}
