export const VIRTUAL_MACHINE_STATUS = {
	RUNNING: 'RUNNING',
	ARCHIVED: 'ARCHIVED',
	PENDING: 'PENDING',
	RESTARTING: 'RESTARTING',
	SUSPENDING: 'SUSPENDING',
	RESUMING: 'RESUMING',
	TERMINATING: 'TERMINATING',
	ARCHIVING: 'ARCHIVING',
	RESTORING: 'RESTORING',
	SUSPENDED: 'SUSPENDED',
	TERMINATED: 'TERMINATED',
	NA: 'N/A',
};
export const EXCHANGE_STATUS = {
	STARTED: 'STARTED',
	OPENED: 'OPENED',
	OPENING: 'OPENING',
	CLOSING: 'CLOSING',
	STARTING: 'STARTING',
	STOPPING: 'STOPPING',
	CLOSED: 'CLOSED',
	STOPPED: 'STOPPED',
	ERROR: 'ERROR',
	NA: 'N/A',
};

export const ORDER_SIDE = {
	BUY: 'BUY',
	SELL: 'SELL',
};
export const ORDER_TYPE = {
	LIMIT: 'LIMIT',
	MARKET: 'MARKET',
};

export const COMPONENT_TYPE = {
	CORE: 'CORE',
	ORDER_BOOK: 'ORDER_BOOK',
	TRADING_GATEWAY: 'TRADING_GATEWAY',
	MD_GATEWAY: 'MD_GATEWAY',
};
export const OPERATION = {
	ADD: 'A',
	MODIFY: 'M',
	CANCEL: 'C',
};

export const REPLAY_OPERATIONS = {
	ADD: 'ADD',
	AMEND: 'AMEND',
	CANCEL: 'CANCEL',
	FILL: 'FILL',
	TRADE: 'TRADE',
};
