import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uuid from 'uuid';
import * as Paho from 'paho-mqtt';
// Organisms
import ExchangeDictionary from './ExchangeDictionary';
import OrderBlotter from './OrderBlotter';
import MessageTraffic from './MessageTraffic';
import ExchangeBehavior from './ExchangeBehavior';
import ExchangeInstance from './ExchangeInstance';
// Actions
import {
	fetchSingleExchangeExtraData,
	getExchangeLiveStatus,
	resetActiveExchangeId,
	setActiveExchangeInstance,
	updateLoadedOrderBlotters,
} from '../../data/exchanges/ExchangesActions';
import { resetExchangeDictionary } from '../../data/exchangeDictionaries/DictionariesActions';
import {
	updateComponentStatus, updateStoreWithWebsocketData, pushMDReplayTableDataRow, pushMDReplayReportRow,
} from '../../data/websockets/actions';
import updateLastReplayEvent from '../../data/replay/replayActions';
import OrderBook from './OrderBook';
import WebSocketConnectionContext from '../../components/Context/WebsocketConnectionContext';
import EVENT from '../../lib/constants/Websockets';
import { requestExchangeStatus } from '../../lib/websockets';
import withData from '../../components/Hoc/withData';
import { getActiveExchange } from '../../data/exchanges/selectors';
import { EXCHANGE_STATUS, VIRTUAL_MACHINE_STATUS, REPLAY_OPERATIONS } from '../../lib/constants/Exchange';
import Api from '../../lib/api';

import {
	downloadCsvData, downLoadXlsxData, showError, showSuccess,
} from '../../lib/helpers';

import MessageModal from '../../components/Organisms/Modals/MessageModal';


class WebsocketConnectionRouter extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			connected: false,
			uuid: uuid(),
			totalImports: -1,
			importedOrders: 0,
			totalOrdersCounted: 0,
			totalOrders: -1,
		};
		this.clientOrderId = null;
	}

	componentDidMount() {
		const {
			machineInstanceStatus,
		} = this.props;
		this.makeClient();
		this.pollForConnection();
		this.pollForExchangeStatus();
		// Pass initial status
		this.pollForMachineInstanceStatus(machineInstanceStatus).catch(() => {});
	}

	componentWillUnmount() {
		const {
			actions,
		} = this.props;
		clearTimeout(this.timeoutId);
		clearTimeout(this.exchangeStatusTimeoutId);
		clearTimeout(this.connectionTimeoutId);
		// this.disconnect();
		actions.resetActiveExchangeId();
		actions.resetExchangeDictionary();
	}

	pollForConnection = () => {
		this.connectToWs().then(() => {
			clearTimeout(this.timeoutId);
			clearTimeout(this.connectionTimeoutId);
		}).catch(() => {
			this.connectionTimeoutId = setTimeout(() => this.pollForConnection(), 5000);
		});
	};

	/**
	 * Function for counting progress of importing orders on order book page
	 * Used in conjunction with handleImportsCounting
	 * @param num -{Number} number of orders to import
	 */
	countImports = (num) => {
		this.setState({ totalImports: num, importedOrders: 0 });
	};

	handleImportsCounting = () => {
		if (this.state.totalImports !== -1) {
			this.setState(prevState => ({ importedOrders: prevState.importedOrders + 1 }));
		}
	};

	countAllOrders = (num) => {
		this.setState({ totalOrders: num, totalOrdersCounted: 0 });
	};

	handleCountingOfAllOrders = () => {
		if (this.state.totalOrders !== -1) {
			this.setState(prevState => ({ totalOrdersCounted: prevState.totalOrdersCounted + 1 }));
		}
	};

	makeClient = () => {
		const {
			webSocketUrl,
		} = this.props;
		if (webSocketUrl && !this.client) {
			console.log('creating client', webSocketUrl);
			this.client = new Paho.Client(webSocketUrl, 8443, '');
		}
	};

	pollForExchangeStatus = () => {
		const {
			actions,
			match,
		} = this.props;
		actions.getExchangeLiveStatus(match.params.slug);

		this.exchangeStatusTimeoutId = setTimeout(() => {
			this.pollForExchangeStatus();
		}, 3000);
	};

	pollForMachineInstanceStatus = async (prevStatus) => {
		const {
			actions,
			match,
			machineInstanceId,
		} = this.props;

		try {
			const response = await Api.get(`instances/${machineInstanceId}/status`);
			const newStatus = response.data.status;
			if (prevStatus !== VIRTUAL_MACHINE_STATUS.RUNNING && newStatus === VIRTUAL_MACHINE_STATUS.RUNNING) {
				actions.fetchSingleExchangeExtraData(match.params.slug).then(() => {
					actions.setActiveExchangeInstance(match.params.slug);
				});
			}
			this.timeoutId = setTimeout(() => this.pollForMachineInstanceStatus(response.data.status), 3000);
		} catch (error) {
			console.log(error);
		}
	};

	connectToWs = () => new Promise((resolve, reject) => {
		if (!this.client) {
			this.makeClient();
		}
		if (this.client && !this.client.isConnected()) {
			this.client.connect({
				onSuccess: (context) => {
					resolve();
					this.setState({ uuid: uuid() });
					this.onConnectCallback(context);
				},
				onFailure: () => reject(),
				useSSL: true,
				keepAliveInterval: 20,
				reconnect: false,
			});
		}
		reject();
	});

	disconnect = () => {
		if (this.client?.isConnected()) {
			console.log('CALLING DISCONNET');
			this.client.disconnect();
			this.client = null;
		}
	};

	onConnectCallback = (options) => {
		const { fix, exchangeStatus } = this.props;
		const { uuid: sessionId } = this.state;

		// Maybe add resubscription on fail !!!
		this.client.subscribe(`com/esprow/etp/gems/${fix}/ctrlgwy/publications`, {});
		this.client.subscribe(`com/esprow/etp/gems/${fix}/ctrlgwy/reply/data/${sessionId}`, {});
		this.client.subscribe(`com/esprow/etp/gems/${fix}/ctrlgwy/reply/command/${sessionId}`, {});
		this.client.subscribe(`com/esprow/etp/gems/${fix}/trdgwy/activesessions`, {});
		this.client.subscribe(`com/esprow/etp/gems/${fix}/obk/+`, {});
		this.client.subscribe(`com/esprow/etp/gems/${fix}/plant/trdmsg`, {});

		requestExchangeStatus(this.client, fix, sessionId);

		this.client.onMessageArrived = (msg) => {
			try {
				this.handleMessage(msg.payloadString);
			} catch (e) {
				// console.log(e);
			}
		};

		this.client.onConnectionLost = (c1, c2) => {
			this.pollForConnection();
			this.setState({ connected: false });
		};
		this.setState({ connected: true });
	};

	setClientOrderId = (clientOrderId) => {
		this.clientOrderId = clientOrderId;
	};

	handleMessage = (unparsedMessage) => {
		const {
			actions,
			fix,
			orderBlottersLoadingStatus,
		} = this.props;
		const { uuid: sessionId } = this.state;

		const message = JSON.parse(unparsedMessage);
		switch (message.requestName) {
		case EVENT.OrderBooksStatusReply: {
			actions.updateStoreWithWebsocketData(EVENT.OrderBooksStatusReply, {
				orderBooks: message.orderBooks,
			});
			break;
		}
		case EVENT.OrderBookPhaseUpdate: {
			actions.updateStoreWithWebsocketData(EVENT.OrderBookPhaseUpdate, {
				orderBookId: message.orderBookId,
				orderBookPhase: message.orderBookPhase,
			});
			break;
		}
		case EVENT.ExchangeStatusReply: {
			actions.updateStoreWithWebsocketData(EVENT.ExchangeStatusReply, {
				exchangeStartedTime: message.exchangeStartedTime,
				status: message.status,
				components: message.components,
				openedComponents: message.openedComponents,
				totalComponents: message.totalComponents,
			});
			break;
		}
		case EVENT.OpenExchangeReply: {
			requestExchangeStatus(this.client, fix, sessionId);
			actions.updateStoreWithWebsocketData(EVENT.OpenExchangeReply, {
				status: message.status,
			});
			break;
		}
		case EVENT.CloseExchangeReply: {
			requestExchangeStatus(this.client, fix, sessionId);
			actions.updateStoreWithWebsocketData(EVENT.CloseExchangeReply, {
				status: message.status,
			});
			break;
		}
		case EVENT.ComponentStatusUpdate: {
			actions.updateComponentStatus(message.componentType, {
				componentId: message.componentId,
				componentStatus: message.componentStatus,
			});
			break;
		}
		case EVENT.ExchangeStatusUpdate: {
			actions.updateStoreWithWebsocketData(EVENT.ExchangeStatusUpdate, {
				status: message.status,
			});
			break;
		}
		case EVENT.OrderBookImageReply: {
			actions.updateStoreWithWebsocketData(EVENT.OrderBookImageReply, {
				symbol: message.symbol,
				entries: message.entries,
			});
			break;
		}
		case EVENT.OrderBookExportReply: {
			const { entries, symbol } = message;
			const { exportExtension } = this.props;
			if (exportExtension === 'csv') {
				downloadCsvData(entries, symbol);
			} else if (exportExtension === 'xls') {
				downLoadXlsxData(entries, symbol);
			}
			break;
		}
		case EVENT.ExchangeBehaviorChangeReply: {
			actions.updateStoreWithWebsocketData(EVENT.ExchangeBehaviorChangeReply, {
				data: message,
			});
			break;
		}
		case EVENT.ExchangeBehaviorChangeRequest: {
			actions.updateStoreWithWebsocketData(EVENT.ExchangeBehaviorChangeRequest, {
				data: message,
			});
			break;
		}
		case EVENT.ExchangeBehaviorStatusReply: {
			actions.updateStoreWithWebsocketData(EVENT.ExchangeBehaviorStatusReply, {
				data: message,
			});
			break;
		}
		case EVENT.OrderBookBBBOReply: {
			actions.updateStoreWithWebsocketData(EVENT.OrderBookBBBOReply, {
				orderBookBBBO: message.orderBookBBBO,
			});
			break;
		}
		case EVENT.TradeTickerReplyEvent: {
			actions.updateStoreWithWebsocketData(EVENT.TradeTickerReplyEvent, {
				tradeTicker: message.tradeTicker,
			});
			break;
		}
		case EVENT.OrderBookTradeReply: {
			actions.updateStoreWithWebsocketData(EVENT.OrderBookTradeReply, {
				trades: message.trades,
			});
			break;
		}
		case EVENT.OrderBookRequestRejectReply: {
			this.handleCountingOfAllOrders();
			if (this.clientOrderId) {
				if (message.order.clientOrderId === this.clientOrderId) {
					showError('Order rejected', message.rejectionMessage);
					this.clientOrderId = null;
				}
			} else {
				showError('Error', message.rejectionMessage);
			}
			break;
		}
		case EVENT.OrderBookAddOrderReply: {
			this.handleImportsCounting();
			this.handleCountingOfAllOrders();
			actions.updateLastReplayEvent({ message, operation: REPLAY_OPERATIONS.ADD });
			if (this.clientOrderId) {
				if (message.order.clientOrderId === this.clientOrderId) {
					showSuccess('Success', 'Order successfully placed');
					this.clientOrderId = null;
				}
			}
			break;
		}
		case EVENT.OrderBookAmendOrderReply: {
			this.handleCountingOfAllOrders();
			actions.updateLastReplayEvent({ message, operation: REPLAY_OPERATIONS.AMEND });
			break;
		}
		case EVENT.TickerPlantResendPacketMessagesReply: {
			actions.updateStoreWithWebsocketData(EVENT.TickerPlantResendPacketMessagesReply, {
				messages: message.messages,
			});
			break;
		}
		case EVENT.TradingGatewayMessageUpdate: {
			actions.updateStoreWithWebsocketData(EVENT.TradingGatewayMessageUpdate, {
				messages: message.message,
			});
			break;
		}
		case EVENT.TradingGatewayActiveSessionsReplyEvent: {
			actions.updateStoreWithWebsocketData(EVENT.TradingGatewayActiveSessionsReplyEvent, {
				entries: message.entries,
				name: message.tradingGatewayName,
				port: message.tradingGatewayPort,
			});
			break;
		}
		case EVENT.ExchangeBehaviorChangeRejectReply: {
			showError('Error', message.rejectionMessage);
			break;
		}
		case EVENT.ExchangeActionRejectReply: {
			showError('Error', message.rejectionMessage);
			break;
		}
		default: {
			break;
		}
		}
		// !IMPORTANT Some updates have type instead of requestName
		switch (message.type) {
		case EVENT.BookRefreshJsonEvent: {
			actions.updateStoreWithWebsocketData(EVENT.BookRefreshJsonEvent, {
				entries: message.entries,
				sym: message.sym,
			});
			break;
		}
		case EVENT.BlotterRefreshJsonEvent: {
			actions.updateStoreWithWebsocketData(EVENT.BlotterRefreshJsonEvent, {
				sym: message.sym,
				entries: message.entries,
			});
			if (orderBlottersLoadingStatus) {
				actions.updateLoadedOrderBlotters();
			}
			break;
		}
		case EVENT.TradeJsonEvent: {
			actions.updateStoreWithWebsocketData(EVENT.TradeJsonEvent, {
				sym: message.sym,
				entries: message.entries,
			});
			actions.updateLastReplayEvent({ message, operation: REPLAY_OPERATIONS.TRADE });
			break;
		}
		case EVENT.BBBOJsonEvent: {
			actions.updateStoreWithWebsocketData(EVENT.BBBOJsonEvent, {
				entries: message.entries,
				sym: message.sym,
			});
			break;
		}
		case EVENT.TradeTickerUpdateJsonEvent: {
			actions.updateStoreWithWebsocketData(EVENT.TradeTickerUpdateJsonEvent, {
				entries: message.entries,
				sym: message.sym,
			});
			break;
		}

		default: {
			break;
		}
		}
	};

	render() {
		const {
			connected, uuid: sessionId,
		} = this.state;

		return (
			<WebSocketConnectionContext.Provider value={{
				client: this.client,
				connectToWs: this.connectToWs,
				sessionId,
				connected,
				disconnectFromWs: this.disconnect,
				countImports: this.countImports,
				setClientOrderId: this.setClientOrderId,
				importedOrders: this.state.importedOrders,
				totalImports: this.state.totalImports,
				countAllOrders: this.countAllOrders,
				totalOrdersCounted: this.state.totalOrdersCounted,
			}}
			>
				<Switch>
					<Route
						path="/app/exchanges/:slug/exchange-dictionary"
						component={ExchangeDictionary}
					/>
					<Route
						path="/app/exchanges/:slug/order-book/:bookId"
						component={OrderBook}
					/>
					<Route
						path="/app/exchanges/:slug/order-blotter"
						component={OrderBlotter}
					/>
					<Route
						path="/app/exchanges/:slug/gateways-traffic"
						component={MessageTraffic}
					/>

					<Route
						path="/app/exchanges/:slug/exchange-behavior"
						component={ExchangeBehavior}
					/>
					<Route
						path="/app/exchanges/:slug"
						component={ExchangeInstance}
					/>
				</Switch>
				<MessageModal />
			</WebSocketConnectionContext.Provider>

		);
	}
}

WebsocketConnectionRouter.defaultProps = {
	exportExtension: null,
	exchangeStatus: EXCHANGE_STATUS.NA,
	orderBlottersLoadingStatus: false,
};
WebsocketConnectionRouter.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			slug: PropTypes.string,
		}),
	}).isRequired,
	actions: PropTypes.shape({
		setActiveExchangeInstance: PropTypes.func.isRequired,
		updateStoreWithWebsocketData: PropTypes.func.isRequired,
		updateComponentStatus: PropTypes.func.isRequired,
		resetActiveExchangeId: PropTypes.func.isRequired,
		fetchSingleExchangeExtraData: PropTypes.func.isRequired,
		getExchangeLiveStatus: PropTypes.func.isRequired,
		resetExchangeDictionary: PropTypes.func,
		updateLastReplayEvent: PropTypes.func,
	}).isRequired,
	exportExtension: PropTypes.string,
	fix: PropTypes.string.isRequired,
	machineInstanceId: PropTypes.number.isRequired,
	webSocketUrl: PropTypes.string.isRequired,
	machineInstanceStatus: PropTypes.string.isRequired,
	exchangeStatus: PropTypes.string,
	orderBlottersLoadingStatus: PropTypes.bool,
};
function mapStateToProps(state) {
	const activeExchange = getActiveExchange(state);
	return {
		exportExtension: activeExchange?.get('exportExtension'),
		fix: activeExchange?.get('exchangeType')?.get('code'),
		machineInstanceId: activeExchange?.get('machineInstanceId'),
		webSocketUrl: activeExchange.get('machineInstance').get('domain'),
		machineInstanceStatus: activeExchange?.get('machineInstance')?.get('status'),
		exchangeStatus: activeExchange?.get('status'),
		orderBlottersLoadingStatus: activeExchange?.get('orderBlotterLoadingStatus'),
	};
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			setActiveExchangeInstance,
			updateStoreWithWebsocketData,
			updateComponentStatus,
			resetActiveExchangeId,
			fetchSingleExchangeExtraData,
			getExchangeLiveStatus,
			resetExchangeDictionary,
			pushMDReplayTableDataRow,
			pushMDReplayReportRow,
			updateLastReplayEvent,
			updateLoadedOrderBlotters,
		}, dispatch),
	};
}
export default withData((connect(mapStateToProps, mapDispatchToProps)(withRouter((WebsocketConnectionRouter)))));
