import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

// lib
import Api from '../../lib/api';
import { getItem, setItem } from '../../lib/localStorage';
// Actions
import { togglePendingSubscriptionModal } from '../../data/ui/UIActions';

// Atoms
import { AppContent, AppScrollable, AppWrapper } from '../../components/Organisms/Layout/Atoms';

// Molecules
import Footer from '../../components/Molecules/Layout/Footer';

// Organisms
import SideMenu from '../../components/Organisms/Layout/App/SideMenu';
import AppHeader from '../../components/Organisms/Layout/App/AppHeader';
import PendingSubscriptionModal from '../../components/Organisms/Modals/BillingModals/PendingSubscriptionModal';
import SessionExpireModal from '../../components/Organisms/Modals/SessionExpireModal';

class PrivateLayout extends PureComponent {
	// Open pending subscription modal if user has unfinished subscription flow
	componentDidMount() {
		const {
			actions,
		} = this.props;
		Api.get('users/session').then((response) => {
			if (response?.data.isPending) {
				actions.togglePendingSubscriptionModal(true);
			}
		}).catch(error => console.log(error));
	}

	render() {
		const {
			children,
			location: {
				pathname,
			}
		} = this.props;
		const isProfilVisible = pathname === '/app/subscription' || pathname === '/app/exchanges';
		return (
			<AppWrapper
				userProfileVisible={isProfilVisible}
			>
				<SideMenu />
				<AppContent>
					<AppHeader />
					<AppScrollable>
						{children}
					</AppScrollable>
					<PendingSubscriptionModal />
					<Footer />
					<SessionExpireModal />
				</AppContent>
			</AppWrapper>
		);
	}
}

PrivateLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			togglePendingSubscriptionModal,
		}, dispatch),
	};
}

export default connect(null, mapDispatchToProps)(withRouter(PrivateLayout));
