const ORDER_BLOTTER_STATUS = {
	PARTIALLY_FILLED: 'Partially Filled',
	NEW: 'New',
	FILLED: 'Filled',
};

export const getBlotterStatus = (quantity, totalQuantity) => {
	let status = ORDER_BLOTTER_STATUS.PARTIALLY_FILLED;
	if (quantity === totalQuantity) {
		status = ORDER_BLOTTER_STATUS.NEW;
	} else if (quantity === 0) {
		status = ORDER_BLOTTER_STATUS.FILLED;
	}
	return status;
};
