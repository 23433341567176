import React from 'react';
import styled from 'styled-components';

// Images
import highFidelity from '../../../assets/images/MarketingSite/homePage/highFidelitySimulation.png';
import scalability from '../../../assets/images/MarketingSite/homePage/massiveScalability.png';
import connectivity from '../../../assets/images/MarketingSite/homePage/nativeConnectivity.png';
import orderBook from '../../../assets/images/MarketingSite/homePage/multipleOrderBooks.png';
import greyBackground from '../../../assets/images/MarketingSite/homePage/greyBackground.png';
import marketData from '../../../assets/images/MarketingSite/homePage/marketDataReply.png';
import behavior from '../../../assets/images/MarketingSite/homePage/exchangeBehavior.png';
import compliance from '../../../assets/images/MarketingSite/homePage/referenceData.png';

import hitBTC from '../../../assets/images/MarketingSite/homePage/TestemonialImages/hitBTC.png';
import hitBTCQuoter from '../../../assets/images/MarketingSite/homePage/TestemonialImages/hitBTCQuoter.png';
import liquilImage from '../../../assets/images/MarketingSite/homePage/TestemonialImages/liquiImage.png';
import liquilQuoter from '../../../assets/images/MarketingSite/homePage/TestemonialImages/liquiQuoter.png';
import nyseImage from '../../../assets/images/MarketingSite/homePage/TestemonialImages/nyseImage.png';
import nyseQuoter from '../../../assets/images/MarketingSite/homePage/TestemonialImages/nyseQuoter.png';
import tradingViewImage from '../../../assets/images/MarketingSite/homePage/TestemonialImages/tradingViewImage.png';
import tradingViewQuoter from '../../../assets/images/MarketingSite/homePage/TestemonialImages/tradingViewQuoter.png';

// Atoms
import { PrimaryButtonLink } from '../../../components/Atoms/Link';
import {
	LargeDivider,
	TabletScreenDivider,
	BigDivider,
	SmallDivider,
	BaseVerticalDivider,
	SmallestDivider,
} from '../../../components/Atoms/Divider';
import { TwoSideImage } from '../../../components/Atoms/WebSite/Images';
import {
	SectionWrapper, CardItemWrapper, OnlyMobileWrapper, HomepageTitleWrapper,
} from '../../../components/Atoms/WebSite/Wrappers';
import {
	Description, Title, BigTitle, BiggerTitle, Quoted,
} from '../../../components/Atoms/WebSite/Text';

// Molecules
import Card from '../../../components/Molecules/WebSite/Card';
import TwoSideSection from '../../../components/Molecules/WebSite/TwoSideSection';
import Testimonial from '../../../components/Molecules/WebSite/Testimonial';
import Slider from '../../../components/Molecules/WebSite/Slider';
import { FlexRow, FlexColumn } from '../../../components/Atoms/Flex';
import { COLORS } from '../../../lib/constants/Style';
import { TextBaseSmall } from '../../../components/Css/Css';

const QuoterImage = styled.img`
	height: 30px;
`;

const QuoterName = styled.p`
	${TextBaseSmall}
`;

const QuoterPosition = styled.p`
	${TextBaseSmall}
	color: ${COLORS.grey};
`;


const renderRightSection = (description, title, quotes, quoterImage, quoterName, quoterPosition, quoterLogo) => (
	<>
		<Title>{title}</Title>
		<BigDivider />
		<Description>
			{description}
		</Description>
		<LargeDivider />
		<BigDivider />
		<Quoted>
			{quotes}
		</Quoted>
		<SmallDivider double />
		<FlexRow>
			<QuoterImage src={quoterImage} />
			<BaseVerticalDivider />
			<FlexColumn>
				<QuoterName>{quoterName}</QuoterName>
				<SmallestDivider />
				<QuoterPosition>{quoterPosition}</QuoterPosition>
			</FlexColumn>
			<BaseVerticalDivider />
			<QuoterImage src={quoterLogo} />
		</FlexRow>
	</>
);

const Home = () => (
	<>
		<SectionWrapper backgroundImage fullpadding>
			<HomepageTitleWrapper>
				<BigTitle>
					ETP Markets
				</BigTitle>
				<Title>
					The Global Exchanges
					<br />
					& Markets Simulation Platform
				</Title>
			</HomepageTitleWrapper>
			<LargeDivider />
			<Description>
				A fast and effective solution to test trading algorithms as well as exchange connectivity.
				{' '}
				<br />
				Remaining compliant with MiFID&apos;s demanding regulatory technical standards is challenging and expensive.
				We aim to help you deliver it efficiently and effectively.
			</Description>
			<LargeDivider />
			<PrimaryButtonLink to="/auth/sign-up">Join ETP Markets</PrimaryButtonLink>
			<TabletScreenDivider />
			<LargeDivider />
			<LargeDivider />
		</SectionWrapper>
		<TwoSideSection
			leftSection={<TwoSideImage src={highFidelity} />}
			rightSection={renderRightSection(
				`Based on Esprow's multi-protocol technology, ETP Markets lets you spin off exchange simulators running on virtual
				machines, each one simulating the exact behavior of a real exchange, market, dark pool, or MTF. You get a matching
				engine, order books, trading phases, native trading gateways, native market data gateways, a ticker plant, reference
				data, and much more.`,
				'High-Fidelity Simulation',
				'“This is the best feature we have worked with for many years.”',
				tradingViewQuoter,
				'Mark Stoneberg',
				'Head of Marketing',
				tradingViewImage,
			)}
		/>
		<TwoSideSection
			flip
			leftSection={<TwoSideImage src={scalability} />}
			rightSection={renderRightSection(
				`Esprow ETP Markets can be deployed on public and private VM hypervisors (cloud platforms), enabling your firm to
				support the testing needs of multiple departments across all regions. Each virtual machine is configurable individually
				according to your needs - from small 1-core/512MB instances, up to powerful 32-core/224GB multi-core machines that truly
				leverage ETP Markets multi-threaded technology, to help you carry out performance testing of trading algorithms.`,
				'Massive Scalability',
				'“No compromises. Real, native, exchange connectivity.”',
				hitBTCQuoter,
				'Alex Schiller',
				'CEO',
				hitBTC,
			)}
		/>
		<TwoSideSection
			leftSection={<TwoSideImage src={connectivity} />}
			rightSection={renderRightSection(
				`ETP Markets is the only platform available today that supports truly native trading and market data connectivity.
				Your trading systems connect to ETP Markets transparently, as if they were connected to the real exchange. You can
				configure any number of trading gateways, whether based on an exchange's FIX protocol or binary protocol, to replicate
				realistic connectivity configurations. Gateways also support cancel-on-disconnect functionality and message throttling.
				Contact Esprow for a list of exchange protocols supported by ETP Markets.`,
				'Native Connectivity',
				'“This is the best feature we have worked with for many years.”',
				nyseQuoter,
				'Michael Aleksandrov',
				'Head of Marketing',
				nyseImage,
			)}
		/>
		<TwoSideSection
			flip
			leftSection={<TwoSideImage src={orderBook} />}
			rightSection={renderRightSection(
				`Whether you are testing basic VWAP/TWAP algorithms, or complex PAIRS and SOR algorithms that require multiple
				concurrent order books and exchanges, ETP Markets lets you create and manage as many order books as you need - each one
				controlled individually from the ETP Markets Web UI or from ETP Studio test commands, for seamless integration with
				end-to-end regression tests. You get access to real-time market depths, BBBO tickers, orders and trades counters,
				replay of market-data files, import/export of order book micro-structures, trading phases, hit-and-trade from the
				Web UI, and much more.`,
				'Multiple Order Books',
				'“No compromises. Real, native, exchange connectivity.”',
				liquilQuoter,
				'Maria Doe',
				'CEO company',
				liquilImage,
			)}
		/>
		<TabletScreenDivider />
		<LargeDivider />
		<LargeDivider />
		<SectionWrapper backgroundImage style={{ backgroundImage: `url(${greyBackground})` }}>
			<BiggerTitle>
				No compromises.
				<br />
				Real, native, exchange connectivity.
			</BiggerTitle>
			<LargeDivider />
			<Description>
				Testing trading algorithms is a complex task. Often requiring investments in building custom
				back-testing frameworks, which have to be designed, implemented, and maintained. ETP Markets fast tracks
				this process by giving you immediate access to a wide range of advanced exchange simulators, covering
				global markets, supported, documented, and upgraded in sync with regular exchange upgrades.
			</Description>
			<LargeDivider />
			<PrimaryButtonLink to="/auth/sign-up">Join ETP Markets</PrimaryButtonLink>
			<LargeDivider />
			<LargeDivider />
		</SectionWrapper>
		<SectionWrapper>
			<CardItemWrapper>
				<Card
					image={marketData}
					title="Market Data Replay"
					description={`One of the major requirements of MifID II is to certify that all algorithmic trading components 
					do not contribute to disorderly market conditions and that they will behave well under stressed market conditions.`}
				/>
				<Card
					image={behavior}
					title="Programmable Behavior"
					description={`Unique testing requirements under MiFID II demand the ability to control the exchange behavior. ETP Markets offers many
					of features to achieve that, including different matching strategies (price-time, pro-rata, equilibrium price, and so on),
					trading phases (pre-open, open, continuous, close, and so on), ...`}
				/>
				<Card
					image={compliance}
					title="Audit & Compliance"
					description={`ETP Markets support enterprise auditing and compliance reporting, enabling multiple users to collect test data across
					multiple trading components, record test sessions, store documents, and generate reports.`}
				/>
			</CardItemWrapper>
			<OnlyMobileWrapper>
				<Slider>
					<Card
						image={marketData}
						title="Market Data Replay"
						description={`One of the major requirements of MifID II is to certify that all algorithmic trading components 
						do not contribute to disorderly market conditions and that they will behave well under stressed market conditions.`}
					/>
					<Card
						image={behavior}
						title="Programmable Behavior"
						description={`Unique testing requirements under MiFID II demand the ability to control the exchange behavior. ETP Markets offers many
						of features to achieve that, including different matching strategies (price-time, pro-rata, equilibrium price, and so on),
						trading phases (pre-open, open, continuous, close, and so on), ...`}
					/>
					<Card
						image={compliance}
						title="Audit & Compliance"
						description={`ETP Markets support enterprise auditing and compliance reporting, enabling multiple users to collect test data across
						multiple trading components, record test sessions, store documents, and generate reports.`}
					/>
				</Slider>
			</OnlyMobileWrapper>
		</SectionWrapper>
		<LargeDivider />
		<LargeDivider />
		<TabletScreenDivider />
		<SectionWrapper backgroundImage style={{ backgroundImage: `url(${greyBackground})` }}>
			<BiggerTitle>
				Plans and Pricing
			</BiggerTitle>
			<LargeDivider />
			<Description>
				Our pricing is based on the number of exchange types (protocols) and sessions you subscribe for.
			</Description>
			<LargeDivider />
			<PrimaryButtonLink to="/plans">See Plans and Pricing</PrimaryButtonLink>
			<LargeDivider />
			<LargeDivider />
		</SectionWrapper>
		<Testimonial />
	</>
);

export default Home;
