import styled from 'styled-components';
import { BaseBlockStyle, TextBaseSmall, TextSmall } from '../Css/Css';
import { COLORS } from '../../lib/constants/Style';
import { DEVICE } from '../../lib/constants/Device';

export const InfoTable = styled.table`
	${TextBaseSmall};
	table-layout: fixed;
	tr {
		vertical-align: middle;
	}
	tr > td {
		:first-child{
			color: ${COLORS.darkGrey};
		}
		color: ${COLORS.lightBlack};
		vertical-align: middle;
	}
	td {
		padding-right: 29px;
		padding-bottom: 10px;
		vertical-align: middle;
	}
	.higher {
		background-color: ${COLORS.lightGreen};
	}
	.lower {
		background-color: ${COLORS.lightRed};
	}
	.absolute {
		position: absolute;
	}
	
	span {
    	border-bottom: 1px dotted ${COLORS.darkGrey};
    	color: ${COLORS.darkGrey};
    	cursor: pointer;
   	}
   	
   	@media ${DEVICE.laptopL} {
		td {
			padding-right: 15px;
			padding-bottom: 10px;
			&:last-of-type {
				padding-right: 0;
			}
		}
	};

`;
export const InfoTableCell = styled.td`
	overflow: hidden;
	min-width: 70px;
	width: 10%;
	padding-right: 15px!important;
	&:first-of-type {
		width: 10%;
		overflow: hidden;
		padding-right: auto;
	}
`;
export const Wrapper = styled.div`
	width: 100%;
	${BaseBlockStyle};
	padding: 30px 15px 30px 30px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
	@media ${DEVICE.laptopL} {
		padding: 15px 0 15px 15px;
	};
	
	${InfoTable} {
		table-layout: fixed;
   		width: 100%;
	}
`;

export const TableProgressBarWrapper = styled.td`
	padding-bottom: 0!important;
`;

export const OrderBookInfoCell = styled.td`
	padding: 5px 0px 5px 5px!important;
	width: 65%;
	@media ${DEVICE.laptopL} {
		${TextSmall}
	};
`;

export const OrderBookInfoCellSmaller = styled.td`
	padding: 5px 0px 5px 5px!important;
	@media ${DEVICE.laptopL} {
		${TextSmall}
	};
`;
