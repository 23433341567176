import React, {
	createContext, useContext, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';

// Lib
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Api from '../../lib/api';
import { userTypes } from '../../lib/constants/User';
import { addUserData, resetUser } from '../../data/user/UserActions';
import { getItem, removeItem, setItem } from '../../lib/localStorage';
import { env } from '../../env';

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

const base64UrlDecode = (input) => {
	const base64 = input.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

	return JSON.parse(jsonPayload);
};

export const AuthProvider = ({ children, history }) => {
	const [userType, setUserType] = useState(null);
	const dispatch = useDispatch();

	const refreshAccessToken = useCallback(async () => {
		const storedLoginInfo = JSON.parse(getItem('loginInfo'));
		if (!storedLoginInfo || !storedLoginInfo.refreshToken) {
			throw new Error('Refresh token is missing');
		}

		const params = new URLSearchParams();
		params.append('grant_type', 'refresh_token');
		params.append('client_id', env.REACT_APP_KEYCLOAK_CLIENT_ID);
		params.append('refresh_token', storedLoginInfo.refreshToken);

		try {
			const response = await axios.post(
				`${env.REACT_APP_KEYCLOAK_URL}/realms/${env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
				params,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);

			if (response.status !== 200) {
				throw new Error('Failed to refresh access token');
			}

			// Successfully refreshed token, update local storage
			const newLoginInfo = {
				...storedLoginInfo,
				accessToken: response.data.access_token,
				refreshToken: response.data.refresh_token, // Optionally update the refresh token (if returned by the server)
				idToken: response.data.id_token,
				idTokenPayload: base64UrlDecode(response.data.id_token.split('.')[1]),
			};

			setItem('loginInfo', JSON.stringify(newLoginInfo));
			setItem('userData', JSON.stringify(newLoginInfo.idTokenPayload));

			return true; // Token refreshed successfully
		} catch (error) {
			// Handle any errors that occurred during the token refresh process
			throw new Error('Failed to refresh token');
		}
	}, []);

	useEffect(() => {
		Api.setRefreshTokenCallback(refreshAccessToken);
	}, [refreshAccessToken]);

	const logOut = (noRedirect) => {
		dispatch(resetUser());
		removeItem('loginInfo');
		removeItem('userData');
		if (!noRedirect) {
			history.push('/auth/sign-in');
		}
	};

	const decideUserType = useCallback(async () => {
		Api.get('users').then((r) => {
			if (r?.data.additionalInfo) {
				Api.get('users/subscription').then((re) => {
					if (re.data.isSubscribed) {
						setUserType(userTypes.FULL_ACCESS_USER);
					} else {
						setUserType(userTypes.WITHOUT_SUBSCRIPTION);
					}
				});
				Api.get('users/profile').then((response) => {
					dispatch(addUserData(response.data));
				});
			} else {
				setUserType(userTypes.FIRST_TIME_LOGIN);
			}
		}).catch((error) => {
			console.log(error);
		});
	}, [dispatch]);

	const logIn = async (email, password) => {
		const params = new URLSearchParams();
		params.append('grant_type', 'password');
		params.append('client_id', env.REACT_APP_KEYCLOAK_CLIENT_ID);
		params.append('username', email);
		params.append('password', password);
		params.append('scope', 'openid profile email');

		const response = await axios.post(
			`${env.REACT_APP_KEYCLOAK_URL}/realms/${env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
			params,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		);

		if (response.status !== 200) {
			throw new Error('access_denied');
		}

		const idTokenPayload = base64UrlDecode(response.data.id_token.split('.')[1]);

		const loginInfo = {
			accessToken: response.data.access_token,
			refreshToken: response.data.refresh_token,
			idToken: response.data.id_token,
			idTokenPayload,
		};
		setItem('loginInfo', JSON.stringify(loginInfo));
		setItem('userData', JSON.stringify(idTokenPayload));
	};

	// eslint-disable-next-line no-unused-vars
	const signUp = (email, password) => new Promise((resolve, reject) => {
		throw new Error('Not implemented');
		// This function do not work now with Keycloak
		// webAuth.signup({
		// 	connection: env.REACT_APP_AUTH_REALM,
		// 	email,
		// 	password,
		// }, (error, response) => {
		// 	if (error) {
		// 		return reject(error);
		// 	}
		// 	return resolve(response);
		// });
	});

	// eslint-disable-next-line no-unused-vars
	const changePassword = email => new Promise((resolve, reject) => {
		throw new Error('Not implemented');
		// This is not working for now with Keycloak. Need to be implemented
		// webAuth.changePassword({
		// 	connection: env.REACT_APP_AUTH_REALM,
		// 	email,
		// }, (error, response) => {
		// 	if (error) {
		// 		return reject(error);
		// 	}
		// 	return resolve(response);
		// });
	});

	const sendConfirmationEmail = async (email) => {
		Api.post('users/verification', { email }).then((response) => {
			console.log(response);
		}).catch((error) => {
			console.error(error);
		});
	};

	return (
		<AuthContext.Provider value={{
			userType,
			logIn,
			refreshAccessToken,
			signUp,
			logOut,
			changePassword,
			sendConfirmationEmail,
			decideUserType,
		}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
};
