import styled from 'styled-components';
import React from 'react';
import { TextBase } from '../../Css/Css';

export const AccordionWrapper = styled.div`
  ${props => props.hasMargin && 'margin-left: 10px'};
`;
const AccordionItemWrapper = styled.div`
	padding-bottom: 16px;
	padding-right: 107px;
	white-space: pre-wrap;
	${TextBase};
`;
export const AccordionItem = (props) => {
	const {
		text,
	} = props;
	return <AccordionItemWrapper>{text}</AccordionItemWrapper>;
};
