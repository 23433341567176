/**
 * Local storage prefix to use for the project
 * @type {string}
 */
export const LOCAL_STORAGE_PREFIX = 'ETP_GEMS_';

// List of supported modals
export const MODALS = {
	SESSION_EXPIRE_MODAL: 'SESSION_EXPIRE_MODAL',
	CHOOSE_EXCHANGE_EXTENSION: 'CHOOSE_EXCHANGE_EXTENSION',
	CHANGE_BEHAVIOR_MODAL: 'changeBehaviorModal',
	CREATE_EXCHANGE_MODAL: 'createExchangeModal',
	EXCHANGE_DICTIONARY_MODAL: 'exchangeDictionaryModal',
	MESSAGE_TRAFFIC_MODAL: 'messageTrafficModal',
	ADD_EXCHANGE_MODAL: 'addExchangeModal',
	STOP_EXCHANGE_MODAL: 'stopExchangeModal',
	ADD_ORDER_BOOK_MODAL: 'ADD_ORDER_BOOK_MODAL',
	EDIT_ORDER_BOOK_MODAL: 'EDIT_ORDER_BOOK_MODAL',
	ADD_TRADING_GATEWAY_MODAL: 'ADD_TRADING_GATEWAY_MODAL',
	EDIT_TRADING_GATEWAY_MODAL: 'EDIT_TRADING_GATEWAY_MODAL',
	ADD_SESSION_MODAL: 'ADD_SESSION_MODAL',
	EDIT_SESSION_MODAL: 'EDIT_SESSION_MODAL',
	BILLING_VALIDATION_MODAL: 'BILLING_VALIDATION_MODAL',
	ADD_ORDER_BLOTTER_MODAL: 'ADD_ORDER_BLOTTER_MODAL',
	MODIFY_ORDER_MODAL: 'MODIFY_ORDER_MODAL',
	CONTACT_FORM_MODAL: 'CONTACT_FORM_MODAL',
	MultiFillModal: 'MultiFillModal',
	STARTING_EXCHANGE_MODAL: 'STARTING_EXCHANGE_MODAL',
	PENDING_SUBSCRIPTION_MODAL: 'PENDING_SUBSCRIPTION_MODAL',
	SHORTCUTS_MODAL: 'SHORTCUTS_MODAL',
	MESSAGE_MODAL: 'MESSAGE_MODAL',
	LOADING_MODAL: 'LOADING_MODAL',
	DELETE_CONFIRMATION_MODAL: 'DELETE_CONFIRMATION_MODAL',
};

export const MODAL_SIZES = {
	SMALL: 'small',
	MEDIUM: 'medium',
	BIG: 'big',
	WIDE: 'wide',
};
