import React from 'react';
import { InfoTable, Wrapper } from '../Atoms/Table';

const AllCountersTable = (props) => {
	const {
		openOrder,
		openBids,
		openOffers,
		totalTrades,
	} = props;
	return (
		<Wrapper>
			<InfoTable>
				<tbody>
					<tr>
						<td>Open Orders:</td>
						<td>{openOrder}</td>
					</tr>

					<tr>
						<td>Open Bids:</td>
						<td>{openBids}</td>
					</tr>
					<tr>
						<td>Open Offers:</td>
						<td>{openOffers}</td>
					</tr>
					<tr>
						<td>Total Trades:</td>
						<td>{totalTrades}</td>
					</tr>
				</tbody>
			</InfoTable>
		</Wrapper>
	);
};

export default AllCountersTable;
