import { fromJS } from 'immutable';
import EXCHANGE_TYPES from '../exchanges/ExchangeTypes';

const initialState = fromJS({
	sideBarItems: [
		{
			name: 'Exchange Instance',
			icon: 'orderBooks',
			url: '/app/exchanges',
			className: 'exchange-instance-icon',
		},
		{
			name: 'Order Blotter',
			icon: 'orderBlotter',
			url: 'order-blotter',
			className: 'order-blotter-icon',
		},
		{
			name: 'Exchange Behavior',
			icon: 'exchangeBehavior',
			url: 'exchange-behavior',
			className: 'exchange-behaviour-icon',
		},
		{
			name: 'Gateways Traffic',
			icon: 'messageTraffic',
			url: 'gateways-traffic',
			className: 'message-traffic-icon',
		},
		{
			name: 'Exchange Dictionary',
			icon: 'dictionaries',
			url: 'exchange-dictionary',
			className: 'exchange-dictionary-icon',
		},
		{
			name: 'Market Data Replay',
			icon: 'marketDataReply',
			url: 'market-data-replay',
			className: 'market-data-replay-icon',
		},
		{
			name: 'Exchange Events',
			icon: 'exchangeEvents',
			url: 'unknown',
		},
		{
			name: 'Reference Data',
			icon: 'referenceData',
			url: 'unknown',
		},
		{
			name: 'Latency Management',
			icon: 'latency',
			url: 'unknown',
		},
	],
});
export default function sideBarReducer(state = initialState, action) {
	switch (action.type) {
	// TODO FIND A WAY TO FIX THIS!
	case EXCHANGE_TYPES.SET_ACTIVE_EXCHANGE_INSTANCE: {
		return state.setIn(['sideBarItems', 0, 'url'], `/app/exchanges/${action.payload.id}/`)
			.setIn(['sideBarItems', 1, 'url'], `/app/exchanges/${action.payload.id}/order-blotter`)
			.setIn(['sideBarItems', 2, 'url'], `/app/exchanges/${action.payload.id}/exchange-behavior`)
			.setIn(['sideBarItems', 3, 'url'], `/app/exchanges/${action.payload.id}/gateways-traffic`)
			.setIn(['sideBarItems', 4, 'url'], `/app/exchanges/${action.payload.id}/exchange-dictionary`)
			.setIn(['sideBarItems', 5, 'url'], `/app/exchanges/${action.payload.id}/market-data-replay`);
	}
	default:
		return state;
	}
}
