import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

import { toggleModal } from '../../../data/ui/UIActions';
// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import {
	MediumDivider, MediumVerticalDivider, SmallDivider,
} from '../../Atoms/Divider';
// Molecules
import Modal from '../../Molecules/Modal';
import { SecondaryButton } from '../../Atoms/Button';
import { GrayText } from './MessageTrafficModal/MessageTrafficModal.Atoms';


class MessageModal extends PureComponent {
	handleClose = () => {
		const {
			actions,
		} = this.props;
		actions.toggleModal(MODALS.MESSAGE_MODAL, false);
	};

	render() {
		const {
			modalInfo,
			modalData,
		} = this.props;

		if (modalInfo.get('visible') !== true) {
			return false;
		}
		const data = modalData?.get('data');
		console.log(data);
		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleClose}
				size={MODAL_SIZES.WIDE}
			>
				<FlexColumn alignItems="center">
					<ModalTitle>
						{modalData?.get('title')}
					</ModalTitle>
					<MediumDivider />
					<LeftAlignText>
						You have exceeded the number of subscribed sessions for this exchange.
					</LeftAlignText>
					<MediumDivider />
					<LeftAlignText>
					The following components have been disabled:
					</LeftAlignText>
					<MediumDivider />
					{data.map(tr => (
						<>
							<LeftAlignText>
								<MediumVerticalDivider />
								<div style={{ marginLeft: '20px' }}>
									<span>&#8226;</span>
Trading Gateway
									{' '}
									<GrayText as="span">
										{tr.get('name')}
									</GrayText>
								, port
									{' '}
									{tr.get('port')}
									{' '}
									{tr.get('allSessionsDisabled') ? 'with all sessions' : (
										<div style={{ marginLeft: '20px' }}>
											<SmallDivider />
											{tr.get('sessions').map(session => (
												<div>
-
													<span>
												Session with SenderCompId =
														{' '}
														<GrayText as="span">
															{session.get('senderCompId')}
														</GrayText>
														{' '}
													and TargetCompId =
														{' '}
														<GrayText as="span">
															{session.get('targetCompId')}
														</GrayText>
													</span>
												</div>
											))}
											<SmallDivider />
										</div>
									)}
								</div>
							</LeftAlignText>
						</>
					))}
					<MediumDivider />
					<LeftAlignText>
						Please purchase additional sessions to be able to use all configured components.
					</LeftAlignText>
					<MediumDivider />
					<FlexRow>
						<SecondaryButton onClick={this.handleClose}>Ok</SecondaryButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}
const LeftAlignText = styled.div`
	text-align: left;
	align-self: flex-start;
`;

MessageModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleModal: PropTypes.func,
	}).isRequired,
	modalData: PropTypes.shape({
		// Title of the modal
		title: PropTypes.string,
		// Message to show
		message: PropTypes.string,
		// Marks if the message is in html form
		isHtml: PropTypes.bool,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.MESSAGE_MODAL]),
		modalData: state.getIn(['ui', 'modal', MODALS.MESSAGE_MODAL, 'data']),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageModal));
