import { fromJS } from 'immutable';
import BILLING_TYPES from './BillingTypes';

const initialState = fromJS({
	generalPrices: {
		loading: false,
		error: false,
		data: {},
	},
	activeAddons: {
		loading: false,
		error: false,
		data: [],
		allItemsSelected: false,
	},
	calculatedPrices: {
		payNow: '',
		payNextMonth: '',
		detailedPayments: [],
	},
	hasNewAddons: false,
	successfulSubscription: false,
	subscriptionError: '',
	chargebeeUrl: '',
});

export default function BillingReducer(state = initialState, action) {
	switch (action.type) {
		// General Prices
		case BILLING_TYPES.FETCH_GENERAL_PRICES: {
			return state.setIn(['generalPrices', 'loading'], true);
		}
		case BILLING_TYPES.FETCH_GENERAL_PRICES_FAIL: {
			return state.setIn(['generalPrices', 'error'], action.payload)
				.setIn(['generalPrices', 'loading'], false);
		}
		case BILLING_TYPES.FETCH_GENERAL_PRICES_SUCCESS: {
			const generalPrices = action.payload;
			for (const [key] of Object.entries(generalPrices)) {
				generalPrices[key].type = key;
			}
			return state.setIn(['generalPrices', 'data'], fromJS(generalPrices))
				.setIn(['generalPrices', 'loading'], false);
		}

		// Active Addon Prices
		case BILLING_TYPES.FETCH_PRICES_FOR_ACTIVE_ADDONS: {
			return state.setIn(['activeAddons', 'loading'], true);
		}
		case BILLING_TYPES.FETCH_PRICES_FOR_ACTIVE_ADDONS_FAIL: {
			return state.setIn(['activeAddons', 'error'], action.payload)
				.setIn(['activeAddons', 'loading'], false);
		}
		case BILLING_TYPES.FETCH_PRICES_FOR_ACTIVE_ADDONS_SUCCESS: {
			const data = action.payload;
			const activeAddons = [];
			for (const [key] of Object.entries(data)) {
				data[key].forEach((item) => {
					item.type = key;
					activeAddons.push(item);
				});
			}
			activeAddons.forEach((item, index) => {
				item.id = item.userAddonExchangeId;
				item.active = true;
				item.newExchange = false;
				item.newSessions = 0;
				item.selected = false;
				activeAddons[index] = item;
			});
			return state.setIn(['activeAddons', 'data'], fromJS(activeAddons))
				.setIn(['activeAddons', 'loading'], false);
		}

		case BILLING_TYPES.TOGGLE_BILLING_ITEM_SELECT: {
			const id = action.payload;
			const itemIndex = state.getIn(['activeAddons', 'data']).findIndex(item => item.get('id') === id);
			const currentValue = state.getIn(['activeAddons', 'data', itemIndex, 'selected']);

			const selectAllValue = state.getIn(['activeAddons', 'allItemsSelected']);

			return state.setIn(['activeAddons', 'data', itemIndex, 'selected'], !currentValue)
				.setIn(['activeAddons', 'allItemsSelected'], (selectAllValue && currentValue) ? !selectAllValue : selectAllValue);
		}

		case BILLING_TYPES.TOGGLE_BILLING_ITEMS_SELECT_ALL: {
			const selectAllItems = state.getIn(['activeAddons', 'allItemsSelected']);
			return state.updateIn(['activeAddons', 'data'], val => val.map(el => el.set('selected', !selectAllItems)))
				.setIn(['activeAddons', 'allItemsSelected'], !selectAllItems);
		}

		case BILLING_TYPES.DELETE_SELECTED_BILLING_ITEMS: {
			return state.updateIn(['activeAddons', 'data'], val => val.map((el) => {
				if (el.get('selected')) {
					return el.set('active', false);
				}
				return el;
			})).set('hasNewAddons', true);
		}

		case BILLING_TYPES.RESTORE_SELECTED_BILLING_ITEMS: {
			return state.updateIn(['activeAddons', 'data'], val => val.map((el) => {
				if (el.get('selected')) {
					return el.set('active', true);
				}
				return el;
			})).set('hasNewAddons', true);
		}

		case BILLING_TYPES.RESTORE_BILLING_ITEM: {
			return state.updateIn(['activeAddons', 'data'], val => val.map((el) => {
				if (el.get('id') === action.payload) {
					return el.set('active', true);
				}
				return el;
			}))
		}

		// Add Exchange
		case BILLING_TYPES.ADD_EXCHANGE: {
			const item = action.payload;
			const addonSize = state.getIn(['activeAddons', 'data']).size;
			item.active = true;
			item.newExchange = true;
			item.newSessions = 0;
			item.id = `addon-${addonSize}`;
			return state.updateIn(['activeAddons', 'data'], arr => arr.push(fromJS(item)))
				.set('hasNewAddons', true);
		}

		// Add Sessions
		case BILLING_TYPES.SET_NEW_SESSIONS_FOR_ADDON: {
			const data = action.payload;
			return state.updateIn(['activeAddons', 'data'], (addons) => {
				const addonIndex = addons.findIndex(item => item.get('id') === data.itemId);
				return addons.setIn([addonIndex, 'newSessions'], data.sessions);
			}).set('hasNewAddons', true);
		}

		// Calculate Prices
		case BILLING_TYPES.CALCULATE_PRICES_SUCCESS: {
			const { data } = action.payload;
			return state.set('calculatedPrices', fromJS(data));
		}

		case BILLING_TYPES.SUCCESSFUL_SUBSCRIPTION: {
			return state.set('successfulSubscription', true);
		}
		case BILLING_TYPES.SUBSCRIPTION_FAIL: {
			return state.set('subscriptionError', action.payload);
		}
		case BILLING_TYPES.NEW_PAYMENT_PLAN: {
			return state.set('chargebeeUrl', action.payload);
		}
		case BILLING_TYPES.RESET_BILLING_ITEMS: {
			return state.set('activeAddons', fromJS({
				loading: false,
				error: false,
				data: [],
			})).set('calculatedPrices', fromJS({
				payNow: '',
				payNextMonth: '',
			})).set('hasNewAddons', false)
				.set('successfulSubscription', false)
				.set('subscriptionError', '')
				.set('chargebeeUrl', '');
		}
		default: {
			return state;
		}
	}
}
