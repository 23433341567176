export const FONT_SIZES = {
	small: '12px',
	baseSmall: '14px',
	base: '16px',
	medium: '21px',
	big: '24px',
	large: '30px',
	extraBig: '36px',
	superBig: '72px',
};

export const COLORS = {
	black: '#000000',
	lightYellow: '#ffcc39',
	lightestYellow: '#fff6dd',
	lightBlack: '#1e3c48',
	darkestGrey: '#4f5e6d',
	darkGrey: '#8c959d',
	grey: '#a2adb7',
	lighterGrey: '#dde3e9',
	lightGrey: '#d8dde2',
	lightestGrey: '#f5f4f4',
	lightRed: '#f7e2de',
	darkPink: '#f1cfcd',
	red: '#d73e3e',
	yellow: '#ffc10e',
	lightGreen: '#b9e7d6',
	green: '#1ec992',
	lightBlue: '#eaefff',
	blue: '#578bff',
	white: '#ffffff',
	shadeGray: 'rgba(0, 0, 0, 0.03)',
	borderGray: 'rgb(243, 241, 242)',
};

export const DIVIDER_HEIGHTS = {
	smallest: 5,
	small: 10,
	base: 15,
	medium: 30,
	big: 40,
	large: 50,
};

export const DIVIDER_WIDTHS = {
	small: 5,
	base: 10,
	medium: 15,
	big: 25,
};

export const STATUS = {
	CLOSED: 'red',
	RUNNING: 'green',
	OPEN: 'blue',
	PAID: 'green',
	UNPAID: 'yellow',
	INACTIVE: 'grey',
};
