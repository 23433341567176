import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import { HeaderText, TreeHeaderLabelWrapper, TreeTableColumn } from './TreeTable.Atoms';

const TreeTableHeader = (props) => {
	const { header } = props;

	return (
		<TreeHeaderLabelWrapper>
			{header.map((item, i) => (
				<TreeTableColumn
					key={item}
					big={i === 0}
				>
					<HeaderText>
						{item}
					</HeaderText>
				</TreeTableColumn>
			))}

		</TreeHeaderLabelWrapper>
	);
};

TreeTableHeader.defaultProps = {
	header: ['Name', 'Tag', 'Length', 'Repeating', 'Required'],
};

TreeTableHeader.propTypes = {
	header: PropTypes.arrayOf(PropTypes.string),
};

export default TreeTableHeader;
