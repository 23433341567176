import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Lib
import { DEVICE } from '../../../../lib/constants/Device';

// Atoms
import { LargeDivider, SmallDivider, } from '../../../Atoms/Divider';
import { Title, GreyText } from '../../../Atoms/WebSite/Text';

const InfoSectionTabs = styled.div`
	@media ${DEVICE.desktop} {
		margin-bottom: 100px;
	};

	@media ${DEVICE.tabletL} {
		margin-top: 75px;
	};

	@media ${DEVICE.mobileM} {
		width: 100%;
		margin: 30px 0 40px;
	};
`;

const Tabs = styled.div`
	display: flex;
	white-space: nowrap;
	overflow: auto;
`;

const Tab = styled.div`
	padding: 10px 15px;
	color: #8c959d;
	font-weight: 600;
	cursor: pointer;
	${props => (props.selected ? '' : '')}
	${props => (props.selected ? 'border-bottom: 2px solid #ffc10e' : 'border-bottom: 1px solid #8c959d')};
	${props => (props.selected ? 'color: #1e3c48;' : '')}
`;

const LargeDividerWithMedia = styled(LargeDivider)`
	@media ${DEVICE.mobileM} {
		display: none;
	}
`;

const InfoSectionWrapper = styled.div`
	display: flex;
	width: 100%;

	&:nth-child(4) {
		margin-bottom: 50px;
	}

	@media ${DEVICE.mobileM} {
		display: block;
		padding: 0 15px;

		&:nth-child(4) {
			margin-bottom: 20px;
		}
	}
`;

const InfoWrapper = styled.div`
	width: 300px;
`;

const BorderBottom = styled.div`
	width: 100%;
	border-bottom: 1px solid #8c959d;
`;

const contactData = [
	{
		id: 'United States',
		phoneNumber: '+1 (646) 583 0711',
		email: 'info@esprow.com',
		fax: '+1 (646) 712 9712',
		address:
			<>
				Esprow LLC
				<br />
				60 Broad St.
				<br />
				24th Fl. Suite # 2407
				<br />
				New York, NY 10004
				<br />
				United States of America
			</>,
	},
	{
		id: 'Singapore',
		phoneNumber: '+65 3159 3682',
		email: 'info@esprow.com',
		address:
			<>
				Esprow Pte. Ltd.
				<br />
				55 Mohamed Sultan Road
				<br />
				#01-04 Sultan Link
				<br />
				Singapore 238995
				<br />
				&nbsp;
			</>,
	},
	{
		id: 'Japan',
		phoneNumber: '+81 (0)3 4520 8927',
		email: 'info@esprow.com',
		fax: '+81 (0)3 4520 8927',
		address:
			<>
				Esprow K.K.
				<br />
				Kyobashi Edogrand SENQ 3F
				<br />
				2-2-1 Kyobashi, Chuo-ku
				<br />
				Tokyo, 104-0031
			</>,
	},
	{
		id: 'Russia',
		phoneNumber: '+7 (911) 249 35 66',
		email: 'info@esprow.com',
		fax: '',
		address:
			<>
				Esprow Pte. Ltd.
				<br />
				12/2 Smolyachkova street Office 303
				<br />
				Saint Petersburg Russia 194044
			</>,
	},
	{
		id: 'India',
		phoneNumber: '',
		email: 'info@esprow.com',
		fax: '',
		address:
			<>
				Esprow Pte. Ltd.
				<br />
				H3, JK INFOTECH 2,
				<br />
				Rajiv Gandhi Infotech Park,
				<br />
				Phase 1, Hinjawadi, Pune,
				<br />
				Maharashtra 411057
			</>,
	},
];

const ContactInfo = ({ selectedTab, selectTab }) => (
	<InfoSectionTabs>
		<Tabs>
			{contactData.length > 0 && contactData.map((data, index) => (
				<Tab key={data.id} selected={selectedTab === index} onClick={() => selectTab(index)}>{data.id}</Tab>))}
			<BorderBottom />
		</Tabs>
		<LargeDividerWithMedia />
		<LargeDivider />
		<InfoSectionWrapper>
			<InfoWrapper>
				{contactData[selectedTab].phoneNumber && (
					<>
						<GreyText>Phone Number</GreyText>
						<SmallDivider double />
						<Title>{contactData[selectedTab].phoneNumber}</Title>
					</>
				)}
			</InfoWrapper>
			<InfoWrapper>
				<GreyText>Email</GreyText>
				<SmallDivider double />
				<Title>{contactData[selectedTab].email}</Title>
			</InfoWrapper>
		</InfoSectionWrapper>
		<InfoSectionWrapper>
			<InfoWrapper>
				{contactData[selectedTab].fax && (
					<>
						<GreyText>Fax</GreyText>
						<SmallDivider double />
						<Title>{contactData[selectedTab].fax}</Title>
					</>
				)}
			</InfoWrapper>
			<InfoWrapper>
				<GreyText>Address</GreyText>
				<SmallDivider double />
				<Title>{contactData[selectedTab].address}</Title>
			</InfoWrapper>
		</InfoSectionWrapper>
	</InfoSectionTabs>
);

ContactInfo.propTypes = {
	selectedTab: PropTypes.number.isRequired,
	selectTab: PropTypes.func.isRequired,
};

export default ContactInfo;
