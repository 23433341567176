import styled, { css } from 'styled-components';
import IconComponent from './Icon';
import { COLORS } from '../../lib/constants/Style';

const disabledIconCSS = css`
	pointer-events: none;
	cursor: unset;
	opacity: 0.3;
`;
export const GrayControlIcon = styled(IconComponent)`
	width: 17px;
  	height: 17px;
	color: ${COLORS.grey};
	cursor: pointer;
	${props => props.noDisplay && 'display: none'};
	${props => props.disabled && disabledIconCSS};
	:hover{
		color: ${COLORS.darkestGrey};
	}
`;
export const TrashCanWrapper = styled(IconComponent)`
	width: 17px;
  	height: 17px;
	color: ${COLORS.grey};
	cursor: pointer;
`;
