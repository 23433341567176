import EXCHANGE_TYPES from './ExchangeTypes';
import Api from '../../lib/api';
import { createFormDataFromObject } from '../../lib/helpers';
import { fetchCloudLocations, fetchDroplets } from '../machineInstances/MachineInstanceActions';
import { toggleModal, updateModal } from '../ui/UIActions';
import { MODALS } from '../../lib/constants/General';

export function fetchStart() {
	return {
		type: EXCHANGE_TYPES.FETCH,
	};
}
export function fetchSuccess(exchanges) {
	return {
		type: EXCHANGE_TYPES.FETCH_SUCCESS,
		payload: {
			exchanges,
		},
	};
}
export function fetchFail() {
	return {
		type: EXCHANGE_TYPES.FETCH_FAIL,
	};
}

export function fetchExchanges() {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			const response = await Api.get('exchanges');
			dispatch(fetchSuccess(response.data));
		} catch (e) {
			dispatch(fetchFail());
			throw e;
		}
	};
}
export function setActiveExchangeInstance(id) {
	return {
		type: EXCHANGE_TYPES.SET_ACTIVE_EXCHANGE_INSTANCE,
		payload: {
			id,
		},
	};
}
export function resetActiveExchangeId() {
	return {
		type: EXCHANGE_TYPES.RESET_ACTIVE_EXCHANGE,

	};
}
export function fetchExchangeExtraDataSuccess(payload) {
	return {
		type: EXCHANGE_TYPES.FETCH_EXCHANGES_EXTRA_DATA_SUCCESS,
		payload,
	};
}
export function getFullInfoAboutExchanges() {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			const response = await Api.get('exchanges');
			const exchanges = response.data;
			const promises = exchanges?.map(exchange => Api.get(`exchanges/${exchange.clientExchangeId}`).then(r => r.data));
			const all = await Promise.all(promises);
			dispatch(fetchExchangeExtraDataSuccess(all));
		} catch (e) {
			dispatch(fetchFail());
		}
	};
}
export function startingExchange(clientExchangeId) {
	return {
		type: EXCHANGE_TYPES.STARTING_EXCHANGE,
		payload: clientExchangeId,
	};
}
export function startExchangeSuccess(clientExchangeId) {
	return {
		type: EXCHANGE_TYPES.START_EXCHANGE_SUCCESS,
		payload: clientExchangeId,
	};
}
export function startExchangeFail(clientExchangeId) {
	return {
		type: EXCHANGE_TYPES.START_EXCHANGE_FAIL,
		payload: clientExchangeId,
	};
}
export function startExchange(clientExchangeId) {
	return async (dispatch) => {
		try {
			dispatch(startingExchange(clientExchangeId));
			const response = await Api.post(`exchanges/${clientExchangeId}/start`);
			dispatch(startExchangeSuccess(clientExchangeId));
			return response;
		} catch (e) {
			dispatch(startExchangeFail(clientExchangeId));
			throw e;
		}
	};
}

function deleteExchangeStart() {
	return {
		type: EXCHANGE_TYPES.DELETE_EXCHANGES_START,
	};
}
function deleteExchangeSuccess(deleteIds) {
	return {
		type: EXCHANGE_TYPES.DELETE_EXCHANGES_SUCCESS,
		payload: {
			deleteIds,
		},
	};
}
export function deleteExchanges(deleteIds) {
	return async (dispatch) => {
		try {
			dispatch(deleteExchangeStart());
			await Api.delete('exchanges', createFormDataFromObject({ deleteIds }));
			dispatch(deleteExchangeSuccess(deleteIds));
			dispatch(toggleModal(MODALS.DELETE_CONFIRMATION_MODAL, false));

			// This data can change after delete so fetch it again
			dispatch(fetchCloudLocations());
			dispatch(fetchDroplets());
			dispatch(fetchAvailableTypes());
		} catch (e) {
			dispatch(updateModal(MODALS.DELETE_CONFIRMATION_MODAL, { error: e?.response?.data?.message }));
		}
	};
}
export function stoppingExchange(clientExchangeId) {
	return {
		type: EXCHANGE_TYPES.STOPPING_EXCHANGE,
		payload: clientExchangeId,
	};
}
export function stopExchangeSuccess(clientExchangeId) {
	return {
		type: EXCHANGE_TYPES.STOP_EXCHANGE_SUCCESS,
		payload: clientExchangeId,
	};
}
export function stopExchangeFail(clientExchangeId) {
	return {
		type: EXCHANGE_TYPES.STOP_EXCHANGE_FAIL,
		payload: clientExchangeId,
	};
}

export function fetchTypesSuccess(payload) {
	return {
		type: EXCHANGE_TYPES.FETCH_TYPES_SUCCESS,
		payload,
	};
}
export function fetchSessionsSuccess(payload) {
	return {
		type: EXCHANGE_TYPES.FETCH_SESSIONS_SUCCESS,
		payload,
	};
}

export function fetchExchangeTypeNamesSuccess(payload) {
	return {
		type: EXCHANGE_TYPES.FETCH_EXCHANGE_TYPES_NAMES_SUCCESS,
		payload,
	};
}

export function fetchAvailableTypes() {
	return async (dispatch) => {
		try {
			const response = await Api.get('exchanges/types');
			dispatch(fetchTypesSuccess(response.data));
		} catch (e) {
			console.log(e);
		}
	};
}

export function fetchExchangeTypeNames() {
	return async (dispatch) => {
		try {
			const response = await Api.get('exchanges/names');
			dispatch(fetchExchangeTypeNamesSuccess(response.data));
		} catch (e) {
			console.log(e);
		}
	};
}

export function fetchAvailableSessions(exchangeTypeId) {
	return async (dispatch) => {
		try {
			const response = await Api.get(`exchanges/sessions?exchangeTypeId=${exchangeTypeId}`);
			dispatch(fetchSessionsSuccess(response.data));
		} catch (e) {
			console.log(e);
		}
	};
}
export function stopExchange(clientExchangeId) {
	return async (dispatch) => {
		try {
			dispatch(stoppingExchange(clientExchangeId));
			await Api.post(`exchanges/${clientExchangeId}/stop`);
			dispatch(stopExchangeSuccess(clientExchangeId));
		} catch (e) {
			dispatch(stopExchangeFail(clientExchangeId));
			throw e;
		}
	};
}
export function updateClientExchangeStatus(clientExchangeId, status) {
	return {
		type: EXCHANGE_TYPES.UPDATE_EXCHANGE_STATUS,
		payload: {
			clientExchangeId,
			status,
		},
	};
}
export function getExchangeLiveStatus(clientExchangeId) {
	return async (dispatch) => {
		try {
			const response = await Api.get(`exchanges/${clientExchangeId}/status`);
			dispatch(updateClientExchangeStatus(clientExchangeId, response.data));
		} catch (e) {
			console.log(e);
		}
	};
}
export function fetchSingleExchangeExtraDataSuccess(payload) {
	return {
		type: EXCHANGE_TYPES.FETCH_SINGLE_EXCHANGE_EXTRA_DATA,
		payload,
	};
}
export function fetchSingleExchangeExtraData(clientExchangeId) {
	return async (dispatch) => {
		try {
			const response = await Api.get(`exchanges/${clientExchangeId}`);
			dispatch(fetchSingleExchangeExtraDataSuccess(response.data));
		} catch (e) {
			dispatch(fetchFail());
			throw e;
		}
	};
}
function updateInstancesStatus(payload) {
	return {
		type: EXCHANGE_TYPES.UPDATE_ALL_INSTANCES_STATUS,
		payload,
	};
}
export function getStatusForAllInstances() {
	return async (dispatch, getState) => {
		const exchangeIds = getState().get('exchange').get('exchanges').toList()
			.map(e => e.get('machineInstanceId'))
			.toJS();
		if (exchangeIds?.length === 0) {
			return;
		}
		try {
			const response = await Api.post('instances/status', createFormDataFromObject({ ids: exchangeIds }));
			dispatch(updateInstancesStatus(response.data));
		} catch (error) {
			console.log(error);
		}
	};
}

/**
 *
 * @param exchangeId - id of the exchange where the book is located in
 * @param orderBookId - id of the book for trades delete
 * @returns {{payload: {exchangeId: *, orderBookId: *}, type: string}}
 */
export function clearOrderBookTrades(exchangeId, orderBookId) {
	return {
		type: EXCHANGE_TYPES.CLEAR_ORDER_BOOK_TRADES,
		payload: {
			exchangeId,
			orderBookId,
		},
	};
}

export const setClickedRowTrades = (instrumentCode, orderId) => ({
	type: EXCHANGE_TYPES.SET_ORDER_BLOTTER_TRADES,
	payload: {
		instrumentCode,
		orderId,
	},
});

export const setActiveUser = user => ({
	type: EXCHANGE_TYPES.SET_EXCHANGE_ACTIVE_USER,
	payload: {
		user,
	},
});

export const chooseExportExtension = extension => ({
	type: EXCHANGE_TYPES.CHOOSE_EXPORT_EXTENSION,
	payload: {
		extension,
	},
});

export const setOrderBlottersLoading = status => ({
	type: EXCHANGE_TYPES.SET_ORDER_BLOTTERS_LOADING_STATUS,
	payload: {
		status,
	},
});
export const updateLoadedOrderBlotters = () => ({
	type: EXCHANGE_TYPES.UPDATE_LOADED_ORDER_BLOTTERS,
});
