import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const ScrollBar = styled(SimpleBar)`
	height: 100%;
	width: 100%;
	padding-right: 15px;
	${props => props.nopadding === 'true' && css`
		padding-right: 0;
	`}
`;

const Scrollbar = (props) => {
	const {
		children,
		theme,
		noScrollbar,
		nopadding,
	} = props;
	if (noScrollbar) {
		return children;
	}
	return (
		<ScrollBar
			nopadding={nopadding ? 'true' : ''}
			theme={theme}
		>
			{children}
		</ScrollBar>
	);
};

Scrollbar.propTypes = {
	nopadding: PropTypes.bool,
};
export default Scrollbar;
