export const exchangesActionShortcuts = [
	{
		name: 'Create exchange instance',
		key1: 'a',
		key2: 'c',
	},
	{
		name: 'View exchange instance details',
		key1: 'a',
		key2: 'v',
	},
	{
		name: 'Shutdown exchange instance(s) (forced)',
		key1: 'a',
		key2: 's',
	},
	{
		name: 'Delete exchange instance(s)',
		key1: 'a',
		key2: 'd',
	},
];
export const exchangeActionShortcuts = [
	{
		name: 'Start exchange instance',
		key1: 'e',
		key2: 'g',
	},
	{
		name: 'Stop exchange instance',
		key1: 'e',
		key2: 's',
	},
	{
		name: 'Open exchange',
		key1: 'e',
		key2: 'o',
	},
	{
		name: 'Close exchange',
		key1: 'e',
		key2: 'c',
	},
	{
		name: 'Move exchange to next phase',
		key1: 'e',
		key2: 'p',
	},
	{
		name: 'Configure latency management',
		key1: 'e',
		key2: 'l',
	},
];
export const orderBookActionShortcuts = [
	{
		name: 'Configure data source',
		key1: 'd',
		key2: 'c',
	},
	{
		name: 'Start data source',
		key1: 'd',
		key2: 'g',
	},
];
