import React from 'react';
import styled from 'styled-components';

// Lib
import { DEVICE } from '../../../lib/constants/Device';
import { COLORS } from '../../../lib/constants/Style';

// Atoms
import {
	LargeDivider, Line, SmallDivider,
} from '../../../components/Atoms/Divider';
import { NoMobileWrapper } from '../../../components/Atoms/WebSite/Wrappers';
import { Title, GreyText } from '../../../components/Atoms/WebSite/Text';

// Molecules
import Table from '../../../components/Molecules/WebSite/Table';

const PlansPageWrapper = styled.div`
    background-color: ${COLORS.lightestGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 130px);
	@media ${DEVICE.laptopL} {
    	min-height: calc(100vh - 110px);
	};
`;

const TableWrapper = styled.div`
    padding: 30px;
    margin: 30px;
    max-width: 1600px;
    width: 100%;
    background-color: white;
    @media ${DEVICE.tabletL} {
    	padding: 15px;
    	margin: 15px;
	};
`;

const Contact = () => (
	<PlansPageWrapper>
		<TableWrapper>
			<Title>Pick Your Plan</Title>
			<SmallDivider double />
			<NoMobileWrapper>
				<GreyText>Save with an annual plan. In the Cart, select yearly, 2 year, or 3 year under Terms.</GreyText>
				<LargeDivider />
				<SmallDivider double />
			</NoMobileWrapper>
			<Line />
			<SmallDivider double />
			<Table />
		</TableWrapper>
	</PlansPageWrapper>
);

export default Contact;
