import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset-advanced';

import { FontFamilyExo } from './Css';

const GlobalStyle = createGlobalStyle`
	${reset}
	body {
		${FontFamilyExo};

		* {
			user-select: text;
		}
	}
`;

export default GlobalStyle;
