import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Lib
import { getItem } from '../../../../lib/localStorage';

// Constants
import { COLORS, FONT_SIZES } from '../../../../lib/constants/Style';

// Atoms
import { MediumDivider, BaseDivider } from '../../../Atoms/Divider';
import { FlexColumn } from '../../../Atoms/Flex';

// Molecules
import { AccountStatusButton } from '../../../Molecules/UserMenu/Atoms';

// Component Specific
const ProfileImageWrapper = styled.div`
	width: 135px;
	height: 135px;
	background: ${COLORS.yellow};
`;

const Name = styled.p`
	font-size: ${FONT_SIZES.big};
	color: ${COLORS.white};
	text-align: center;
	padding: 0 20px;
    overflow-wrap: break-word;
    width: 100%;
`;

const Email = styled.p`
	font-size: ${FONT_SIZES.base};
	color: ${COLORS.white};
	text-align: center;
	padding: 0 20px;
    overflow-wrap: break-word;
    width: 100%;
`;

const AccountDueDate = styled.p`
	font-size: ${FONT_SIZES.baseSmall};
	color: ${COLORS.darkGrey};
`;

const UserProfile = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');

	useEffect(() => {
		setEmail(JSON.parse(getItem('userData')).name);
		if (getItem('userData') && JSON.parse(getItem('userData')).metadata) {
			setName(JSON.parse(getItem('userData')).metadata.name);
		}
	}, []);

	return (
		<FlexColumn alignItems="center">
			<ProfileImageWrapper />
			<MediumDivider double />
			<Name>{name}</Name>
			<BaseDivider />
			<Email>{email}</Email>
			<MediumDivider />
			<AccountStatusButton>TRIAL</AccountStatusButton>
			<BaseDivider />
			<AccountDueDate>Until forever</AccountDueDate>
		</FlexColumn>
	);
};

export default UserProfile;
