import styled, { css } from "styled-components";
import { FlexRowCenterCss } from "../../../Atoms/Flex";
import { TextBase } from "../../../Css/Css";
import { COLORS } from "../../../../lib/constants/Style";

export const ResetButton = styled.div`
	${FlexRowCenterCss};
	${TextBase};
	color: ${COLORS.darkGrey};
`;
export const BuyCheckBox = styled.div`
	width: 49%;
	height: 100%;
	border-radius: 4px;
  	border: solid 1px #b9e7d6;
  	background-color: #def7ef;
  	${FlexRowCenterCss};
  	color: ${COLORS.green};
  	font-weight: bold;
  	cursor: pointer;
  	${props => props.selected === 'BUY' && css`
		background-color: #b9e7d6;
	`}
`;
export const SellCheckBox = styled.div`
	width: 49%;
	height: 100%;
	border-radius: 4px;
  	border: solid 1px #f1cfcd;
  	background-color: #f7e2de;
  	${FlexRowCenterCss};
  	color: #d04640;
  	font-weight: bold;
  	cursor: pointer;
  	${props => props.selected === 'SELL' && css`
		background-color: #f1cfcd;
	`}
`;
