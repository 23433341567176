import React, { useRef, useState } from 'react';

// Constants
import FAQ_DATA from '../../../lib/constants/FAQ';

// Images
import background from '../../../assets/images/MarketingSite/faqPage/darkBackground.png';

// Atoms
import { SectionWrapper } from '../../../components/Atoms/WebSite/Wrappers';
import { Title, BaseText, BoldText } from '../../../components/Atoms/WebSite/Text';
import {
	MaxWidthWrapper, FaqFlex, FaqSearchFlex, CardWrapper, AccordionWrapper, SearchButton,
} from '../../../components/Atoms/WebSite/FAQPageAtoms';
import {
	LargeDivider, BigVerticalDivider, TabletScreenDivider, MobileMScreenDivider, SmallDivider,
} from '../../../components/Atoms/Divider';

// Molecules
import Input from '../../../components/Molecules/Input/Input/Input';

// Organisms
import AccordionGroup from '../../../components/Organisms/Accordion/AccordionGroup';

const FAQ = () => {
	const searchRef = useRef(null);
	const [displayData, setDisplayData] = useState(FAQ_DATA);

	const searchResults = (value) => {
		const searchedData = [];
		for (let i = 0; i < FAQ_DATA.length; i += 1) {
			const section = {
				title: FAQ_DATA[i].title,
				data: [],
			};
			for (let j = 0; j < FAQ_DATA[i].data.length; j += 1) {
				if (FAQ_DATA[i].data[j].label.toLowerCase().includes(value.toLowerCase()) || FAQ_DATA[i].data[j].text.toLowerCase().includes(value.toLowerCase())) {
					section.data.push(FAQ_DATA[i].data[j]);
				}
			}
			searchedData.push(section);
		}
		setDisplayData(searchedData);
	};

	const onChange = (value) => {
		searchResults(value);
	};

	return (
		<>
			<SectionWrapper backgroundImage style={{ backgroundImage: `url(${background})` }}>
				<Title style={{ color: 'white' }}>Do you have questions?</Title>
				<SmallDivider double />
				<MaxWidthWrapper>
					<FaqSearchFlex>
						<Input
							onChange={onChange}
							ref={searchRef}
							name="search"
							type="header"
							placeholder="Search for an answer"
						/>
						<BigVerticalDivider />
						<SearchButton>Search</SearchButton>
					</FaqSearchFlex>
				</MaxWidthWrapper>
				<TabletScreenDivider />
				<MobileMScreenDivider />
				<LargeDivider />
			</SectionWrapper>
			<SectionWrapper reducedPadding>
				<FaqFlex justifyContent="space-between" alignItems="flex-start">
					<AccordionWrapper>
						{displayData.length > 0 && displayData.map(item => (
							<div key={item.title}>
								<AccordionGroup
									title={item.title}
									data={item.data}
								/>
								<LargeDivider />
							</div>
						))}
					</AccordionWrapper>
					<CardWrapper>
						<Title>
							Did not find an answer
							<br />
							to your question?
						</Title>
						<LargeDivider />
						<Title><BoldText><a href="mailto:info@esprow.com">info@esprow.com</a></BoldText></Title>
						<SmallDivider double />
						<BaseText>Write to us and we will help you</BaseText>
					</CardWrapper>
				</FaqFlex>
			</SectionWrapper>
			<LargeDivider />
			<LargeDivider />
		</>
	);
};

export default FAQ;
