import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Lib
import axios from 'axios';
import { useAuth } from '../../../components/Context/auth';
import { getItem, setItem } from '../../../lib/localStorage';

// Images
import signinBackground from '../../../assets/images/MarketingSite/signingBackground.png';

// Atoms
import { GreyBackgroundWrapper } from '../../../components/Atoms/WebSite/Wrappers';
import { Title, GreyText, ErrorMessage } from '../../../components/Atoms/WebSite/Text';
import {
	BigDivider, SmallDivider, MediumDivider,
} from '../../../components/Atoms/Divider';
import {
	Wrapper,
	ImageSide,
	FormWrapper,
	Form,
	FormLink,
	LinkWrapper,
	FormButton,
	ImageSideTextWrapper,
	ImageSideDescription, ImageSideTitle,
} from '../../../components/Atoms/WebSite/Auth';

// Molecules
import Input from '../../../components/Molecules/Input/Input/Input';
import { toggleSessionExpireModal } from '../../../data/ui/UIActions';
import store from '../../../lib/store';

const SignIn = ({ history }) => {
	const { logIn } = useAuth();
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const [authError, setAuthError] = useState(null);

	useEffect(() => {
		store.dispatch(toggleSessionExpireModal(false));
	}, []);

	const validateForm = () => {
		emailRef.current.hasError();
		passwordRef.current.hasError();
		if (emailRef.current.hasError() || passwordRef.current.hasError()) {
			return false;
		}
		return true;
	};

	const handleSubmit = async () => {
		if (validateForm()) {
			try {
				await logIn(emailRef.current.getValue(), passwordRef.current.getValue());
				history.push('/app/exchanges');
			} catch (error) {
				console.log(error);
				setAuthError('access_denied');
			}
		}
	};

	if (getItem('loginInfo') && !authError) {
		return <Redirect to="/app" />;
	}

	return (
		<GreyBackgroundWrapper>
			<Wrapper>
				<ImageSide image={signinBackground}>
					<ImageSideTextWrapper>
						<ImageSideTitle light>
							ETP MARKETS
							<br />
							THE GLOBAL EXCHANGES
							<br />
							& MARKETS SIMULATION
							<br />
							PLATFORM
						</ImageSideTitle>
						<BigDivider />
						<ImageSideDescription>
							A fast and effective solution to test trading algorithms as well as exchange connectivity.
						</ImageSideDescription>
					</ImageSideTextWrapper>
				</ImageSide>
				<FormWrapper>
					<Form onSubmit={(event) => {
						event.preventDefault();
						handleSubmit();
					}}
					>
						<Title>Log in to ETP Markets</Title>
						<SmallDivider double />
						<GreyText>Enter your email address and password to sign in</GreyText>
						<MediumDivider />
						{!authError && (<MediumDivider />)}
						{authError && (
							<>
								<ErrorMessage>Login error</ErrorMessage>
								<SmallDivider />
							</>
						)}
						<Input
							ref={emailRef}
							name="email"
							type="email"
							placeholder="Email"
							validators={[
								{
									validator: 'isLength',
									options: {
										min: 1,
									},
									errorResult: false,
									message: 'Required field',
								},
								{
									validator: 'isEmail',
									errorResult: false,
									message: 'Email invalid',
								},
							]}
						/>
						<Input
							ref={passwordRef}
							name="password"
							type="password"
							placeholder="Password"
							validators={[
								{
									validator: 'isLength',
									options: {
										min: 8,
									},
									errorResult: false,
									message: 'At least 8 character',
								},
							]}
						/>
						<SmallDivider double />
						<FormButton>Sign In</FormButton>
						<BigDivider double />
						<LinkWrapper>
							<FormLink to="/auth/sign-up">I don&apos;t have an account - Sign Up</FormLink>
							<FormLink to="/auth/forgot-password">Forgot password?</FormLink>
						</LinkWrapper>
					</Form>
				</FormWrapper>
			</Wrapper>
		</GreyBackgroundWrapper>
	);
};

SignIn.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
};

export default SignIn;
