import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Modals
import TreeLabel from './TreeLabel';

const TreeRow = ({
	rowData, subRowData, depth, openAllStatus, changeOpenStatus, searchValue, parentExist, openFieldModal, data,
}) => {
	const [showRow, setShowRow] = useState(true);
	const [showSubRows, setShowSubRows] = useState(false);
	const currentDepth = depth + 1;

	useEffect(() => {
		if (openAllStatus === true) {
			return setShowSubRows(true);
		}
		if (openAllStatus === false) {
			return setShowSubRows(false);
		}
		return false;
	}, [openAllStatus, changeOpenStatus]);

	useEffect(() => {
		if (depth > 0) {
			return setShowRow(parentExist || rowData.toString().toLowerCase().includes(searchValue.toLowerCase()));
		}
		return setShowRow(rowData.toString().toLowerCase().includes(searchValue.toLowerCase()));
	}, [searchValue, rowData, depth, parentExist]);

	const handleClick = () => {
		setShowSubRows(!showSubRows);
	};

	return (
		<>
			{showRow && (
				<TreeLabel
					onClick={subRowData ? handleClick : () => openFieldModal(data.name)}
					text={rowData}
					depth={depth}
					hasChildren={subRowData && subRowData.length > 0}
					open={showSubRows}
				/>
			)}
			{showSubRows && subRowData && subRowData.map(subRow => (
				<TreeRow
					data={subRow}
					key={`${subRow.id}${subRow.rowData?.name}`}
					rowData={subRow.rowData}
					subRowData={subRow.children}
					depth={currentDepth}
					openAllStatus={openAllStatus}
					changeOpenStatus={changeOpenStatus}
					searchValue={searchValue}
					parentExist={showRow}
					openFieldModal={openFieldModal}
				/>
			))}
		</>

	);
};

TreeRow.defaultProps = {
	rowData: null,
	subRowData: null,
	depth: 0,
	parentExist: false,
	data: {},
	openFieldModal: () => {},
};

TreeRow.propTypes = {
	openFieldModal: PropTypes.func,
	rowData: PropTypes.arrayOf(PropTypes.string),
	subRowData: PropTypes.arrayOf(PropTypes.shape({
		rowData: PropTypes.arrayOf(PropTypes.string),
		children: PropTypes.arrayOf(PropTypes.shape({})),
		map: PropTypes.func,
	})),
	depth: PropTypes.number,
	openAllStatus: PropTypes.bool.isRequired,
	changeOpenStatus: PropTypes.bool.isRequired,
	searchValue: PropTypes.string.isRequired,
	parentExist: PropTypes.bool,
	data: PropTypes.shape({
		name: PropTypes.string,
	}),
};

export default TreeRow;
