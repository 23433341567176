import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// Icons
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

// Images
import userImage from '../../../assets/images/logo.png';

// Atoms
import {
	AccountInfoWrapper,
	HeaderLinkFake,
	AccountInfoFontAwesomeIcon, HeaderLink, UserImage, AccountInfo, UserIcon,
} from './Atoms';
import { BaseVerticalDivider, MediumVerticalDivider } from '../../Atoms/Divider';

// Molecules
import DropdownMenu from './DropdownMenu';
import { getItem } from '../../../lib/localStorage';

const UserMenu = (props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { onClick, location } = props;
	const [name, setName] = useState('');

	const userStoreData = useSelector(state => state.getIn(['user', 'data']));
	const userData = getItem('userData');

	useEffect(() => {
		if (userData && JSON.parse(userData).metadata && userStoreData.size > 0) {
			setName(JSON.parse(getItem('userData')).metadata.name);
		}
	}, [userData, userStoreData]);

	const renderLoggedIn = () => (
		<AccountInfo>
			<HeaderLinkFake>
				<UserImage src={userImage} alt="user image" />
				<MediumVerticalDivider />
				{name}
				<AccountInfoFontAwesomeIcon icon={faChevronDown} />
			</HeaderLinkFake>
		</AccountInfo>
	);

	const renderPublic = () => (
		<HeaderLink to="/auth/sign-in" onClick={onClick}>
			{name && (
				<>
					<UserImage src={userImage} alt="user image" />
					<MediumVerticalDivider />
					{name}
				</>
			)}
			{!name && (
				<>
					<UserIcon name="user" alt="User Icon" />
					<BaseVerticalDivider />
					Sign In
				</>
			)}
		</HeaderLink>
	);

	// Temporary
	const locationPath = location.pathname;

	return (
		<AccountInfoWrapper
			className={isMenuOpen ? 'open' : ''}
			onMouseEnter={() => setIsMenuOpen(true)}
			onMouseLeave={() => setIsMenuOpen(false)}
		>
			{locationPath.includes('/app/') ? renderLoggedIn() : renderPublic()}
			<DropdownMenu isOpen={isMenuOpen && locationPath.includes('/app/')} />
		</AccountInfoWrapper>
	);
};

UserMenu.defaultProps = {
	onClick: () => {
	},
};

UserMenu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string,
		includes: PropTypes.func,
	}).isRequired,
	onClick: PropTypes.func,
};

export default withRouter(UserMenu);
