import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

import { toggleStartingExchangeModal } from '../../../data/ui/UIActions';
// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn } from '../../Atoms/Flex';
import {
	MediumDivider,
	BaseDivider,
} from '../../Atoms/Divider';
// Molecules
import Modal from '../../Molecules/Modal';
import { getActiveExchange } from '../../../data/exchanges/selectors';
import { EXCHANGE_STATUS } from '../../../lib/constants/Exchange';
import MessageModal from './MessageModal';

class StartingExchangeModal extends PureComponent {
	render() {
		const {
			modalInfo,
			status,
			actions,
		} = this.props;

		const modalInfoJS = modalInfo?.toJS();

		if (modalInfoJS.visible !== true) {
			return false;
		}

		if (status === EXCHANGE_STATUS.STARTED) {
			actions.toggleStartingExchangeModal(false);
		}
		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={() => {}}
				size={MODAL_SIZES.MEDIUM}
				hasCloseIcon={false}
			>
				<FlexColumn>
					<ModalTitle>
						Starting exchange...
					</ModalTitle>
					<MediumDivider />
					<BaseDivider />
				</FlexColumn>
			</Modal>
		);
	}
}
StartingExchangeModal.defaultProps = {
	status: null,
};
StartingExchangeModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleStartingExchangeModal: PropTypes.func,
	}).isRequired,
	status: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.STARTING_EXCHANGE_MODAL]),
		status: getActiveExchange(state).get('status'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleStartingExchangeModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartingExchangeModal));
