import styled from 'styled-components';
import { BaseBlockStyle } from '../Css/Css';
import { FlexColumnBase, FlexRowBase } from './Flex';

export const FullWidthContainer = styled.div`
	width: 100%;
`;
export const SelectBoxWrapper = styled.div`
	min-width: 260px;
`;
export const SearchFieldWrapper = styled.div`
	width: 261px;	
`;


// @NOTE THIS IS TEMPORARY HERE
export const LeftSideWrapper = styled.div`
	${FlexColumnBase};
	justify-content: space-between;
	min-width: 532px;
	overflow: auto;
`;
export const RightSideWrapper = styled.div`
	${BaseBlockStyle};
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;
  	box-shadow: 0 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
  	display: flex;
  	flex-direction: column;
`;
export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-self: flex-start;
	overflow: hidden;
	// hide focus of GlobalHotKeys
  	div[tabindex="-1"]:focus {
  		outline: 0;
	}
`;
export const FullWidthFlexRow = styled.div`
	${FlexRowBase};
	width: 100%;
	justify-content: space-between;
`;
export const RelativeWrapper = styled.div`
	position: relative;
`;

export const FlexRowFullHeight = styled.div`
	${FlexRowBase};
	height: 100%;
`;

export const ScrollableContentWrapper = styled.div`
	flex: 1;
	overflow: hidden;
	width: 100%;
`;
