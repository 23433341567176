import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

// Atoms
import { FlexColumnBase } from '../../Atoms/Flex';
import { BlockTitle } from '../../Atoms/Text';
import { MediumDivider, BaseDivider, SmallestDivider } from '../../Atoms/Divider';
import { SubscriptionDetailItem, ToggleDetails, ScrollWrapper } from './Atoms';

// Organisms
import Scrollbar from '../Scrollbar';

const Wrapper = styled.div`
	${FlexColumnBase};
	flex: 2;
	overflow: hidden;
	
	${BlockTitle} {
		padding-right: 20px;
	}
`;

const SubscriptionDetails = (props) => {
	const {
		title,
		addons,
		total,
		generalPrices,
		exchangeNames,
	} = props;
	const [showContent, setShowContent] = useState(true);
	return (
		<Wrapper>
			<SubscriptionDetailItem>
				<BlockTitle>
					{title}
				</BlockTitle>
				<BlockTitle>
					{`$${total && total.toFixed(1)}`}
				</BlockTitle>
			</SubscriptionDetailItem>
			<MediumDivider />
			<div>
				<ToggleDetails onClick={() => setShowContent(prevState => !prevState)}>
					{!showContent && 'Show Details'}
					{showContent && 'Hide Details'}
				</ToggleDetails>
			</div>
			{showContent && (
				<ScrollWrapper>
					<Scrollbar>
						<BaseDivider />
						{addons.get('data').map((item) => {
							if (item.get('active')) {
								const sessions = item.get('sessions') + item.get('newSessions');
								const type = item.get('type');
								return (
									<Fragment key={`subscription-details-item-${item.get('id')}`}>
										<SubscriptionDetailItem>
											<p>{exchangeNames.get(type)}</p>
											<p>
$
												{generalPrices.getIn(['data', item.get('type'), 'exchangePrice'])}
											</p>
										</SubscriptionDetailItem>
										<SmallestDivider />
										<SubscriptionDetailItem>
											<p>
Sessions:
												{item.get('sessions') + item.get('newSessions')}
											</p>
											<p>
$
												{(generalPrices.getIn(['data', item.get('type'), 'sessionPrice']) * sessions).toFixed(1)}
											</p>
										</SubscriptionDetailItem>
										<BaseDivider />
									</Fragment>
								);
							}
							return null;
						})}
					</Scrollbar>
				</ScrollWrapper>
			)}
		</Wrapper>
	);
};

export default SubscriptionDetails;
