import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import React from 'react';

// Css
import { ZIndex } from '../../../Css/Css';

// Lib
import { ORDER_SIDE } from '../../../../lib/constants/Exchange';
import { COLORS, FONT_SIZES } from '../../../../lib/constants/Style';

// Data
import { getActiveExchange } from '../../../../data/exchanges/selectors';
import { DEVICE } from '../../../../lib/constants/Device';

export const MarketDepthGrid = styled.div`
	display: grid;
	grid-template-columns: 3fr 3fr 3fr;
	grid-template-rows: repeat(30, 3.333%);
	height: 100%;
	width: 100%;

	border-right: 1px solid ${COLORS.borderGray};
	&:last-child {
		border-right: none;
	}
`;

export const GridHeader = styled.p`
	font-size: ${FONT_SIZES.baseSmall};
	letter-spacing: normal;
	color: #8c959d;
	display: flex;
	align-items: center;
	padding-left: 5px;
	overflow: hidden;
	@media ${DEVICE.laptopL} {
		font-size: ${FONT_SIZES.small};
	};
`;

const FirstRowCSS = css`
	grid-row-start: 2;
	grid-row-end: span 2;
	font-weight: bold;
	font-size: ${FONT_SIZES.base};
	color: ${props => (props.bidSide === ORDER_SIDE.BUY ? COLORS.green : COLORS.red)};
	
	@media ${DEVICE.laptopL} {
		font-size: ${FONT_SIZES.baseSmall};
	};
`;

export const Timestamp = styled.p`
	display: flex;
	align-items: center;
	border-top: 1px solid ${COLORS.borderGray};
	${props => (props.bidSide === ORDER_SIDE.SELL ? 'padding-right: 5px' : 'padding-left: 5px')};
	${props => props.isFirst && FirstRowCSS};
`;

const StyledBidSize = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	z-index: 1;
	background: transparent;
	border-top: 1px solid ${COLORS.borderGray};
	justify-content: flex-end;

	${props => props.isFirst && FirstRowCSS};

	:first-child {
		border-top: none;
	}

	::before {
		position: absolute;
		height: 100%;
		content: '';
		width: 100%;
		background-color: ${props => (props.bidSide === ORDER_SIDE.BUY ? '#dbf6ed' : '#f6dfdb')};
		${props => `transform: scale(${props.size / props.totalSize}, 1)`};
		transform-origin: left;
	}
`;

const StyledBid = styled.p`
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	border-top: 1px solid ${COLORS.borderGray};
	justify-content: flex-end;
	${props => props.selected && 'background-color: #FFF2D0;'};
	${props => (props.bidSide === ORDER_SIDE.BUY ? 'padding-right: 5px;' : 'padding-left: 5px;')};
	${props => props.isFirst && FirstRowCSS};

	:first-child {
		border-top: none;
	}
`;

export const RowWrapper = styled.div`
	display: contents;
	cursor: pointer;
	font-size: ${FONT_SIZES.baseSmall};

	:last-child ${StyledBidSize},
	:last-child ${StyledBid}, 
	:last-child ${Timestamp} {
		border-bottom: 1px solid ${COLORS.borderGray};
	}

	&:hover {
		${Timestamp}, ${StyledBidSize}, ${StyledBidSize}:before, ${StyledBid} {
			background-color: ${COLORS.borderGray};
		}
	}
	
	@media ${DEVICE.laptopL} {
		font-size: ${FONT_SIZES.small};
	};
`;


const ChildrenWrapper = styled.p`
	${ZIndex.mediumTwo};
	padding: 0 5px;
`;

export const BidSize = (props) => {
	const {
		isFirst, selected, size, children, totalSize, bidSide,
	} = props;

	return (
		<StyledBidSize
			selected={selected}
			isFirst={isFirst}
			size={size}
			totalSize={totalSize}
			bidSide={bidSide}
		>
			<ChildrenWrapper>
				{children}
			</ChildrenWrapper>
		</StyledBidSize>

	);
};

export const Bid = (props) => {
	const {
		bidSide, children, member, isFirst,
	} = props;
	const selectedMember = useSelector(state => getActiveExchange(state).get('selectedMember'));
	return (
		<StyledBid
			selected={selectedMember.key === member}
			isFirst={isFirst}
			bidSide={bidSide}
		>
			{children}
		</StyledBid>
	);
};
