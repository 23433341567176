import {
	createStore,
	applyMiddleware,
	compose,
} from 'redux';
import {
	routerMiddleware,
} from 'connected-react-router/immutable';
import Immutable from 'immutable';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';

import reducers from '../data/index';

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();

const middleware = [
	routerMiddleware(history),
	thunk,
];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Now you can dispatch navigation actions from anywhere!
// store.dispatch(push('/foo'))

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
export const store = createStore(
	reducers(history),
	Immutable.Map(),
	composeEnhancers(
		applyMiddleware(...middleware)
	)
);

export default store;
