import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from '../../../Hooks/useOnClickOutside';
import { SettingsIcon, SettingsList, StyledTableSettings } from '../Atoms/TableAtoms';
import { FlexRow } from '../../../Atoms/Flex';
import CheckBoxInput from '../../../Molecules/Input/CheckBox/CheckBox';
import { BaseDivider } from '../../../Atoms/Divider';
import Scrollbar from '../../Scrollbar';

const TableSettings = (props) => {
	const {
		header,
		handleHeaderVisibilityChange,
	} = props;
	const [visible, setVisible] = useState(false);
	const ref = useRef();
	useOnClickOutside(ref, () => setVisible(false));
	return (
		<StyledTableSettings ref={ref}>
			<SettingsIcon
				className="cogwheel"
				onClick={(e) => {
					e.stopPropagation();
					setVisible(prevState => !prevState);
				}}
				name="settings"
			/>
			{visible && (
				<SettingsList fixedSize={header.length > 5}>
					<Scrollbar>
						{header.map(h => (
							<>
								<FlexRow>
									<CheckBoxInput
										value={h.visible}
										onChange={() => handleHeaderVisibilityChange(h.name)}
									/>
									<span>{h.name}</span>
								</FlexRow>
								<BaseDivider />
							</>
						))}
					</Scrollbar>
				</SettingsList>
			)}
		</StyledTableSettings>

	);
};
TableSettings.propTypes = {
	header: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	handleHeaderVisibilityChange: PropTypes.func,
};
TableSettings.defaultProps = {
	handleHeaderVisibilityChange: () => {
	},
};
export default TableSettings;
