import React, { useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Actions
import { toggleMessageTrafficModal } from '../../../../data/ui/UIActions';
// Constants and data
import { MODAL_SIZES, MODALS } from '../../../../lib/constants/General';
// Atoms
import { FlexColumn, FlexRow } from '../../../Atoms/Flex';
import { BaseDivider, MediumDivider } from '../../../Atoms/Divider';
import {
	BottomActionsWrapper,
	ClipboardInput,
	ClipboardInputWrapper,
	ConfirmButton,
	CopyText,
	CopyToClipBoardText,
	CopyToClipBoardWrapper,
	GrayText,
	ModalTitle,
	TableWrapper,
} from './MessageTrafficModal.Atoms';
// Molecules
import Modal from '../../../Molecules/Modal';
// Organisms
import TreeTable from '../../TreeTable';
import Scrollbar from '../../Scrollbar';
import { copyToClipboard } from '../../../../lib/helpers';
import { InvisibleInput } from '../../../Atoms/Input';

function MessageTrafficModal(props) {
	const { modalInfo, actions } = props;
	const copyRef = useRef(null);
	const closeModal = () => {
		actions.toggleMessageTrafficModal(false);
	};
	const data = modalInfo.get('data');
	let tableData = null;
	let rows = null;
	try {
		if (data.size > 0) {
			tableData = JSON.parse(data?.get('message'));
			rows = tableData.content.reduce((acc, value) => [...acc, {
				rowData: [value.name, value.tag || '', value.value, value.required ? 'Y' : 'N'],
				id: value.name,
				children: value.content?.reduce((a, v) => [...a, { id: value.tag, rowData: [v.name, v.tag || '', v.value, v.required ? 'Y' : 'N'], children: [] }], []),
			}], []);
		}
	} catch (e) {
		console.log(e);
	}

	return (
		<Modal
			showModal={modalInfo.get('visible')}
			closeModal={closeModal}
			size={MODAL_SIZES.BIG}
		>
			<TableWrapper>
				<FlexColumn>
					<ModalTitle>
						Message Details:
						{' '}
						{tableData?.name}
(
						{tableData?.msgType}
)
					</ModalTitle>
				</FlexColumn>
				<MediumDivider />
				<FlexColumn style={{ overflow: 'auto', height: '100%' }}>
					{rows?.length > 0 && (
						<TreeTable
							data={rows}
							header={['Message Element', 'Tag', 'Value', 'Required']}
							openAllStatus
						/>
					)}
				</FlexColumn>
				<MediumDivider />
			</TableWrapper>
			<BottomActionsWrapper>
				<MediumDivider />
				<GrayText>Raw Message:</GrayText>
				<BaseDivider />
				<FlexRow>
					<InvisibleInput
						ref={copyRef}
						value={data.get('rawMessage')}
					/>
					<ClipboardInputWrapper>
						<Scrollbar>
							<ClipboardInput>
								<CopyText>
									{data.get('rawMessage')}
								</CopyText>
							</ClipboardInput>
						</Scrollbar>
					</ClipboardInputWrapper>
					<CopyToClipBoardWrapper>
						<CopyToClipBoardText onClick={() => copyToClipboard(copyRef.current)}>
							Copy to clipboard
						</CopyToClipBoardText>
					</CopyToClipBoardWrapper>
				</FlexRow>
				<MediumDivider />
				<ConfirmButton onClick={() => actions.toggleMessageTrafficModal(false)}>
					Ok
				</ConfirmButton>
			</BottomActionsWrapper>
		</Modal>
	);
}

MessageTrafficModal.propTypes = {
	modalInfo: PropTypes.shape({
		visible: PropTypes.bool,
	}).isRequired,
	actions: PropTypes.shape({
		toggleMessageTrafficModal: PropTypes.func,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.MESSAGE_TRAFFIC_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleMessageTrafficModal,
		}, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageTrafficModal);
