import styled, { css } from 'styled-components';
import { COLORS } from '../../../lib/constants/Style';
import { FlexColumnBase, FlexRowBase, FlexRowSpaceBetweenCss } from '../../Atoms/Flex';
import { BaseBlockStyle, BaseTransition, TextBaseSmall } from '../../Css/Css';
import { baseLink } from '../../Atoms/Link';
import { DEVICE } from '../../../lib/constants/Device';

// Billing Page
export const MainContainer = styled.div`
	${FlexRowBase};
	overflow: hidden;
	align-items: flex-start;
	position: relative;
	flex: 1;
	@media ${DEVICE.laptopL} {
		${TextBaseSmall};
	};
`;

export const LeftWrapper = styled.div`
	${FlexColumnBase};
	color: ${COLORS.lightBlack};
	flex: 3 1 0;
	height: 100%;
	position: relative;
`;

export const RightWrapper = styled.div`
	display: block;
	flex: 1 1 0;
	overflow: hidden;
	height: 100%;
`;

// Billing Item
export const BillingItemWrapper = styled.div`
	background: ${COLORS.white};
	${FlexRowBase};
	align-items: stretch;
	justify-content: space-between;
	position: relative;
	padding: 20px;
	overflow: hidden;
    flex: 1;
    width: 100%;
	margin-right: 5px;
	${props => props.active === false && css`
		opacity: 0.5;
		order: 2;
	`}
`;

export const InstanceWrapper = styled.div`
	${FlexColumnBase};
	justify-content: flex-start;
	flex-basis: 40%;
	height: 100%;
	overflow: hidden;
	border-right: 1px solid ${COLORS.lighterGrey};
	padding-right: 15px;
`;

export const InstanceData = styled.div`
	${FlexRowBase};
	justify-content: flex-start;
	width: 100%;
	padding-left: 20px;
`;

export const SessionWrapper = styled.div`
	justify-content: flex-start;
	${FlexColumnBase};
	align-items: center;
	overflow: hidden;
	flex-basis: 35%;
	border-right: 1px solid ${COLORS.lighterGrey};
`;

export const SessionData = styled.div`
	${FlexColumnBase};
	align-items: flex-start;
`;

export const CurrentPaymentWrapper = styled.div`
	justify-content: center;
	align-items: center;
	overflow: hidden;
	${FlexColumnBase};
	flex-basis: 25%;
	padding-left: 20px;
`;

export const CurrentPaymentData = styled.div`
	${FlexColumnBase};
	align-items: flex-start;
`;

export const HelperText = styled.span`
	user-select: text;
	color: ${COLORS.grey};
`;

// Billing Overview
export const BillingOverviewWrapper = styled.div`
	${FlexColumnBase};
	justify-content: flex-start;
	width: 100%;
	overflow: hidden;
`;

export const BillingOverviewContent = styled.div`
	${BaseBlockStyle};
	padding: 20px 5px 20px 20px;
	height: 100%;
    overflow: hidden;
	${FlexColumnBase};
`;

export const EsprowLinkWrapper = styled.div`
	${FlexRowBase};
	justify-content: center;
	flex-wrap: wrap;
	line-height: 1.2;
	align-items: baseline;
`;

export const EsprowLink = styled.a`
	${baseLink};
	color: ${COLORS.yellow};
	&:hover {
		color: ${COLORS.lightBlack};
	}
`;

// Subscription Details

export const SubscriptionDetailItem = styled.div`
	${FlexRowSpaceBetweenCss};
`;

export const ToggleDetails = styled.span`
	color: ${COLORS.grey};
	cursor: pointer;
	border-bottom: 1px dotted ${COLORS.grey};
	flex-grow: 0;
	flex-shrink: 0;
	${BaseTransition};
	
	&:hover {
		color: ${COLORS.lightBlack};
		border-bottom: 1px dotted ${COLORS.lightBlack};
	}
`;

export const ScrollWrapper = styled.div`
	${FlexColumnBase};
	overflow: hidden;
`;

export const RestoreSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
