import React from 'react';
import { SmallDivider } from '../components/Atoms/Divider';

export const exchangesSteps = [
	{
		disableBeacon: true,
		title: 'Exchange instances',
		target: '.exchange-instances-table',
		content: [
			<p>
			This table lists all the exchanges you've created.
			</p>,
			<p>
			Each exchange is independent and
			isolated from the others and is running on its own virtual machine.
			</p>,
			<p>Clicking the row takes you to the exchange's page.</p>,
		],
	},

	{
		disableBeacon: true,
		title: 'Create Exchange',
		target: '.add-exchange-icon',
		content: 'To create a new exchange, press this button.',
	},
	{
		disableBeacon: true,
		title: 'Exchange Type',
		target: '.add-exchange-modal-types',
		content: 'Only the exchange types for which you have subscribed (on the Subscription page) are listed.',
	},
	{
		disableBeacon: true,
		title: 'VM Region',
		target: '.add-exchange-modal-locations',
		content: 'Select the location where you would like to have the exchange. You should select a location close to where you are based to reduce latency.',
	},
	{
		disableBeacon: true,
		title: 'Number of Sessions',
		target: '.add-exchange-modal-sessions',
		content: 'Select the number of Sessions you would like to allocate to this exchange.',
	},
	{
		disableBeacon: true,
		title: 'Default Components',
		target: '.add-exchange-modal-pre-config',
		content: 'If you enable this, a default Trading Gateway and Order Book will be created for you,' +
			' so that you can directly start using the exchange without having to add those components first.',
	},
	{
		disableBeacon: true,
		title: 'Create Exchange',
		target: '.add-exchange-modal-confirm-button',
		content: 'When you are ready, click this button. We will provision a virtual machine for your exchange. It might take a few minutes until it is ready.',
	},
];
export const exchangeSteps = [
	{
		disableBeacon: true,
		title: 'Exchange menu',
		target: '.side-menu',
		content: 'You can access various exchange tools via this menu.',
	},
	{
		disableBeacon: true,
		title: 'Exchange Instance',
		target: '.exchange-instance-icon',
		content: 'This is the main page of the exchange that allows you to monitor and manage the exchange\'s state, phases and components.',
		placement: 'right',
	},
	{
		disableBeacon: true,
		title: 'Order Blotter',
		target: '.order-blotter-icon',
		content: 'The order blotter allows you to enter, manage and monitor orders and their related executions using an interface with which you can be familiar from any trading application.',
		placement: 'right',
	},
	{
		disableBeacon: true,
		title: 'Exchange Behavior',
		target: '.exchange-behaviour-icon',
		content: 'On this page you can configure a custom behavior for the exchange, for example, that all orders are partially filled to 50%, or that all modification are rejected.',
		placement: 'right',
	},
	{
		disableBeacon: true,
		title: 'Gateways Traffic',
		target: '.message-traffic-icon',
		content: 'This page shows you the list of incoming and outgoing messages via the trading gateways of the exchange.',
		placement: 'right',
	},
	{
		disableBeacon: true,
		title: 'Exchange Dictionary',
		target: '.exchange-dictionary-icon',
		content: 'Here you can browse the dictionary (also known as protocol specification or ROE) of the exchange, see the supported message types and their structures.',
		placement: 'right',
	},
	{
		disableBeacon: true,
		title: 'Market Data Replay',
		target: '.market-data-replay-icon',
		content: 'This tool allows you to replay market data for one or more order books on the exchange and monitor the results.',
		placement: 'right',
	},
	{
		disableBeacon: true,
		title: 'VM details',
		target: '.vm-box-container',
		content: 'Here you can see the details of the VM on which the exchange is running.' +
			' You will need the IP address to connect your exchange (the port number is defined in the Trading Gateway, from which you can have multiple).',
	},
	{
		disableBeacon: true,
		title: 'Exchange Details',
		target: '.exchange-box-container',
		content: [<p>Here you can see the details of the exchange, including the current status.</p>,
			<p>You can edit exchange components only when the exchange is stopped.</p>,
			<p>You can connect to the exchange only when the exchange is opened.</p>],
	},
	{
		disableBeacon: true,
		title: 'Exchange Controls',
		target: '.exchange-box-buttons-container',
		content: 'You can control the exchange state from here. You can start or stop the VM, and when the VM is started, you can open or close the exchange.',
	},
	{
		disableBeacon: true,
		title: 'Exchange Status',
		target: '.opened-components-progress-bar',
		content: [<p>This progress indicator shows you how many components of the exchange are open.</p>,
			<p>Once you open the exchange, this should go up to 100%. If it doesn't, there has been an error starting some of the components.</p>],
	},
	{
		disableBeacon: true,
		title: 'Trading Phases:',
		target: '.phases',
		content: [<p>Different exchanges have different trading phases. The order acceptance and matching behavior varies depending on the trading phase.</p>,
			<p>This bar shows you the available phases of the current exchange. Once you open the exchange, you can switch between phases using the Next Phase button.</p>,
			<p>This feature is coming soon. Currently, only the Continuous Trading phase is available.</p>],
	},
	{
		disableBeacon: true,
		title: 'Exchange Components',
		target: '.main-tabs-container',
		content: [<p>You can configure and monitor the exchange components, such as Order Books and Trading Gateways.</p>,
			<p>Components can only be modified when the exchange is stopped.</p>],
	},
	{
		disableBeacon: true,
		title: 'Order Books',
		target: '.order-books-tab',
		content: [<p>Order books contain orders for a given instrument.</p>,
			<p>The list of order books available in the current exchange are listed here.</p>,
			<p>Clicking the row takes you to the order book's page.</p>],
	},
	{
		disableBeacon: true,
		title: 'Table Settings',
		target: '.cogwheel',
		content: 'In all tables, you can configure the visible columns using this button.',
	},
	{
		disableBeacon: true,
		title: 'Add Order Book',
		target: '.order-books-add-icon',
		content: 'To add a new order book, click this button.',
	},
	{
		disableBeacon: true,
		title: 'Import Order Book',
		target: '.order-books-import-button',
		content: 'Alternatively, you can bulk import multiple order books from an Excel or CSV file. The required file format is described on [this help page].',
	},
	{
		disableBeacon: true,
		title: 'Trading Gateways',
		target: '.trading-gateways-tab',
		content: 'Trading Gateways are the entry points to the exchange to which you connect with your trading application.' +
			' Each trading gateway has a port number (the IP address is defined by the VM) and a list of available sessions (CompID pairs).',
	},
	{
		disableBeacon: true,
		title: 'Add Trading Gateway',
		target: '.tr-add-button',
		content: 'To add a new trading gateway, click this button.',
	},
	{
		disableBeacon: true,
		title: 'Add Sessions',
		target: '.add-tr-modal-sessions-add-button',
		content: 'To add sessions (CompIDs) to the trading gateway, click this button.',
	},
	{
		disableBeacon: true,
		title: 'Reset Sequence Numbers on Logon',
		target: '.add-tr-modal-reset-seq-number',
		content: 'If this option is enabled, on every logon, the sequence numbers will be reset to 1-1.',
	},
	{
		disableBeacon: true,
		title: 'Default Field Overrides',
		target: '.add-tr-modal-override-fields',
		content: 'Here you can define default tag values which will be populated on all outgoing messages from the exchange. Separate the tag=value entries with a semi-colon.',
	},
	{
		disableBeacon: true,
		title: 'Add Trading Gateway',
		target: '.add-tr-modal-save-button',
		content: [<p>When you are ready, click this button. The trading gateway will be added to the exchange.</p>,
			<p>You have to open the exchange before you can connect to the trading gateway.</p>],
	},
	{
		disableBeacon: true,
		title: 'Market Data Gateways',
		target: '.md-gateways-tab',
		content: [<p>Market Data Gateways allow clients to connect and receive market data published by the exchange.</p>,
			<p>This feature is coming soon.</p>],
	},
	{
		disableBeacon: true,
		title: 'Core Components',
		target: '.core-components-table',
		content: 'Here you can see the status of various core components. Core components cannot be modified.',
	},
];
export const orderBookSteps = [
	{
		disableBeacon: true,
		title: 'Market Depth',
		target: '.market-depth-table',
		content: [<p>This table shows the orders that are on both sides of the book. In other words, it contains the bids and offers (or buys and sells).</p>,
			<p>Orders are not aggregated for each price level, but are displayed as separate orders. That's why you can see multiple orders with the same price.</p>,
			<SmallDivider />,
			<p>You can right-click orders to trade against them.</p>,
			<p>You can also modify or cancel orders, if they are your own.</p>,
			<p>Own orders are highlighted in yellow.</p>,
			<SmallDivider />,
			<p>
				The number of orders displayed is limited, so there might be additional orders on the book which have a price that deviates more from the top of the book.
				You can see the total number of orders in the Counters tab.
			</p>,
		],
	},
	{
		disableBeacon: true,
		title: 'Times & Sales',
		target: '.times-and-sales-table',
		content: [<p>This table shows the latest trades that have happened on this book.</p>,
			<p>If the price is highlighted in green, that means that there was a price increase compared to the previous trade. Red indicates a price decrease.</p>],
	},
	{
		disableBeacon: true,
		title: 'Member Selection',
		target: '.view-as-member-box',
		content: [<p>The exchange has multiple participants, each one with a unique ID (usually called CompID).</p>,
			<p>There is also a special "UI" exchange participant that is used for all orders that are submitted from the web UI (as opposed to via the Trading Gateways).</p>,
			<SmallDivider />,
			<p>You can choose as which exchange participant you would like to see this page.</p>,
			<p>A participant's own orders are highlighted in yellow in the Market Depth table. Own orders can be modified or cancelled.</p>,
		],
	},
	{
		disableBeacon: true,
		title: 'Order Book Functions',
		target: '.order-book-actions',
		content: 'You can export the orders from the book into an Excel or CSV file, and you can later import them back to re-create the order book\'s state.',
	},
	{
		disableBeacon: true,
		title: 'BBBO and Trade Ticker',
		target: '.bbbo-and-tt-table',
		content: 'Here you can see statistics about the book. When a price changes, the change is highlighted in green or red, depending on whether it was an increase or decrease.',
	},
	{
		disableBeacon: true,
		title: 'Price Graph',
		target: '.price-graph',
		content: 'This graph is a visual representation of the times & sales.' +
			' It shows how the price of the instrument changes over time, and gives an indication about the trade sizes at different time points.',
	},
	{
		disableBeacon: true,
		title: 'Order Entry',
		target: '.order-entry-section',
		content: 'With this order entry ticket, you can enter orders even without connecting your trading system to the trading gateways.' +
			' Orders submitted from here will be under the special "UI" member\'s name. They will be matched against any other orders on the book.\n',
	},
	{
		disableBeacon: true,
		title: 'Market Data Replay',
		target: '.md-replay-section',
		content: 'You can replay market data (add, modify and cancel orders) for the current order book. The required file format is described on [this help page].',
	},
	{
		disableBeacon: true,
		title: 'Order Book Counters',
		target: '.counters-section',
		content: 'This tab shows statistics about the order book.',
	},
];

export const orderBlotterSteps = [
	{
		disableBeacon: true,
		title: 'Order Blotter',
		target: '.order-blotter-table',
		content: [<p>The order blotter allows you to enter, manage and monitor orders and their related executions using an interface with which you can be familiar from any trading application.</p>,
			<SmallDivider />,
			<p>This table lists the orders of the selected member across all order books.</p>,
			<p>You can right-click orders to trade against them. You can also modify or cancel orders.</p>],
	},
	{
		disableBeacon: true,
		title: 'Member Selection',
		target: '.view-as-member-order-blotter',
		content: [<p>The exchange has multiple participants, each one with a unique ID (usually called CompID).</p>,
			<p>There is also a special "UI" exchange participant that is used for all orders that are submitted from the web UI (as opposed to via the Trading Gateways).</p>,
			<SmallDivider />,
			<p>You can choose as which exchange participant you would like to see this page.</p>,
			<p>A participant's own orders will be shown in the table below.</p>],
	},
	{
		disableBeacon: true,
		title: 'Enter Order',
		target: '.order-blotter-enter-order',
		content: [<p>Press this button to open the order entry dialog. After configuring the order's details, you can submit it to the exchange.</p>,
			<p>Orders submitted from here will be under the special "UI" member's name. They will be matched against any other orders on the book.</p>],
	},
	{
		disableBeacon: true,
		title: "Order's Trades",
		target: '.order-blotter-trades-table',
		content: 'When you select an order in the table above, the related executions will be displayed here.',
	},
];

export const exchangeBehaviourSteps = [
	{
		disableBeacon: true,
		title: 'Behaviors',
		target: '.radio-button-area',
		content: 'You can configure a custom behavior for the exchange, for example, that all orders are partially filled to 50%, or that all modification are rejected.',
	},
	{
		disableBeacon: true,
		title: 'Behavior Description',
		target: '.description-area',
		content: 'When you select a custom behavior, a short description of it is shown on the right.',
	},
	{
		disableBeacon: true,
		title: 'Apply Behavior Changes',
		target: '.exchange-behaviour-button-area',
		content: 'Use those buttons to apply the selected behavior or reset to the default behavior.',
	},
];
export const messageTrafficSteps = [
	{
		disableBeacon: true,
		title: 'Message Traffic',
		target: '.message-traffic-table',
		content: [<p>This table shows the list of incoming and outgoing messages via all the trading gateways of the exchange.</p>,
			<p>If you click on a message, you can see its content and its raw format.</p>,
			<SmallDivider />,
			<p>Note that UI orders submitted from the web interface do not go through the trading gateways and thus are not listed here.</p>],
	},
	{
		disableBeacon: true,
		title: 'Message Traffic Filters',
		target: '.message-traffic-filters',
		content: 'You can filter the table based on various properties and free text search.',
	},
];

export const exchangeDictionarySteps = [
	{
		disableBeacon: true,
		title: 'Exchange Dictionary',
		target: '.exchange-dictionary-main-area',
		content: [<p>You can browse the dictionary (also known as protocol specification or ROE) of the exchange. You can see all the supported message types, components and fields.</p>,
			<p>By clicking on any element, you can see its structure and description.</p>],
	},
];
export const marketDataReplaySteps = [
	{
		disableBeacon: true,
		title: 'Load Market Data File',
		target: '.md-replay-load-file-button',
		content: [<p>You can replay market data for one or more order books on the exchange and monitor the results.</p>,
			<SmallDivider />,
			<p>The first step is to load a market data file. The required file format is described on [this help page].</p>],
	},
	{
		disableBeacon: true,
		title: 'Market Data File Details',
		target: '.md-replay-info-section',
		content: 'Here you can see the currently active file and the number of market data events it contains.',
	},
	{
		disableBeacon: true,
		title: 'Replay Controls',
		target: '.play-buttons',
		content: [<p>Click start to begin the replay of the market data file.</p>,
			<p>While the data is being replayed on the exchange, you can Pause/Resume and Stop the execution.</p>],
	},
	{
		disableBeacon: true,
		title: 'Replay Progress',
		target: '.events-confirmations-box',
		content: 'Here you can monitor the replay progress during execution. The progress bars show you how many messages have been sent to the exchange and how many responses have been received.',
	},
	{
		disableBeacon: true,
		title: 'Market Data Events',
		target: '.md-replay-main-table',
		content: 'This table lists the submitted messages from the market data file, and for each of them, it shows the details of the order,' +
			' its effect on the order book, and a snapshot of the order book state.',
	},
	{
		disableBeacon: true,
		title: 'Export Report',
		target: '.md-export-report',
		content: 'When the replay has finished, you can export a report in Excel format.',
	},
];
export const subscriptionStepsNoSubscriptions = [
	{
		disableBeacon: true,
		title: 'Add Exchange Subscription',
		target: '.subscription-add-exchange-button',
		content: [<p>Before you can create an exchange, you need to purchase a corresponding subscription.</p>,
			<SmallDivider />,
			<p>Click this button to add a subscription for a specific exchange type and sessions.</p>,
		],
	},
	{
		disableBeacon: true,
		title: 'Exchange Type',
		target: '.subscription-add-exchange-dialog-protocol-type',
		content: 'Select which exchange type you would like to use.',
	},
	{
		disableBeacon: true,
		title: 'Number of Sessions',
		target: '.subscription-add-exchange-dialog-sessions',
		content: [<p>One session is a CompID pair. With the same session, only one user can connect to the exchange.</p>,
			<p>If you add multiple sessions, once you create the exchange, you can distribute your available sessions across as many trading gateways as you like.</p>],
	},
	{
		disableBeacon: true,
		title: 'Exchange and Sessions Cost',
		target: '.subscription-cost',
		content: 'This is the total monthly cost for the selected exchange and the number of sessions.',
	},
	{
		disableBeacon: true,
		title: 'Add Exchange Subscription',
		target: '.subscription-add-button',
		content: 'Click this button to add the selected exchange type and sessions. ' +
			'Note that no payment is required at this step. You will have a chance to modify your selection and add other exchange types before checking out.',
	},
];
export const subscriptionSteps = [
	{
		disableBeacon: true,
		title: 'Exchange Subscriptions',
		target: '.subscription-left-section',
		content: 'Here you can see a list of the exchange types you\'ve subscribed for. ' +
			'It includes both already paid exchanges and ones that you have added, but not paid for yet. ' +
			'You can easily add or remove sessions for all exchanges.The Current Payment column shows the cost of the outstanding changes.',
	},
	{
		disableBeacon: true,
		title: 'Delete and Restore Subscriptions',
		target: '.subscription-restore-delete-buttons',
		content: [<p>You can remove unneeded exchange types.</p>,
			<p>Your next monthly bill will be reduced with the corresponding amount. However, cost for the remaining days from the current month cannot be refunded.</p>,
			<p>If you change your mind, you can restore deleted exchanges before you confirm the changes.</p>,
		],
	},
	{
		disableBeacon: true,
		title: 'Cost Summary',
		target: '.subscription-right-section',
		content: 'Here you can see a textual summary of your active monthly subscription, and also the current changes you\'ve made.',
		placement: 'left',
	},
	{
		disableBeacon: true,
		title: 'Discard Changes',
		target: '.subscription-discard-all',
		content: 'If you decide not to pay for the new exchanges and sessions you\'ve added, click this button to discard them.',
	},
	{
		disableBeacon: true,
		title: 'Apply Changes and Check Out',
		target: '.subscription-confirm-button',
		content: 'Once you are happy with the current exchange and session subscription, click this button to complete the payment process.',
	},
];
