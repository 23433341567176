import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Constants
import { COLORS, FONT_SIZES } from '../../../lib/constants/Style';

// Base Atoms
import { FlexColumnAlignCenterCss, FlexRowBase } from '../../Atoms/Flex';
import { TextBase } from '../../Css/Css';
import { alternateLink } from '../../Atoms/Link';
import { baseButton } from '../../Atoms/Button';
import IconComponent from '../../Atoms/Icon';

export const AccountInfoIcon = styled.img`
	height: 27px;
	width: 27px;
	background: ${COLORS.lightBlack};
	border-radius: 4px;
	margin-right: 15px;
`;

export const AccountInfoFontAwesomeIcon = styled(FontAwesomeIcon)`
	font-size: ${FONT_SIZES.small};
	z-index: 101;
	margin-left: 8px;
	margin-bottom: -5px;
`;

export const HeaderLink = styled(Link)`
	${alternateLink};
	display: flex;
	align-items: center;
`;

export const HeaderLinkFake = styled.span`
	${alternateLink};
`;

export const AccountInfo = styled.span`
	min-width: 150px;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
`;


export const AccountInfoWrapper = styled.div`
	${FlexRowBase};
	${TextBase};
	padding-right: 10px;
	padding-left: 10px;
	margin-right: 10px;
	position: relative;
	cursor: pointer;
	> img {
		margin-right: 10px;
	}

	> svg {
		margin-left: 10px;
	}
	
	&.open {
		${AccountInfoIcon} {
			opacity: 1;
		}
	}
	
	${HeaderLinkFake} {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 101;
	}
`;

export const AccountStatusButton = styled.div`
	${baseButton};
	font-size: ${FONT_SIZES.small};
	font-weight: 600;
	padding: 6px 12px;
	color: ${COLORS.yellow};
	border: 2px solid ${COLORS.yellow};
	${props => (props.hide ? 'display: none' : '')};
	&:hover {
		background: ${COLORS.yellow};
	}
`;

// Dropdown Menu

export const AccountInfoDropdownWrapper = styled.div`
	${FlexColumnAlignCenterCss};
	background-color: #ffffff;
	position: absolute;
	width: 100%;
	top: -10px;
	left: 0;
	padding-top: 55px;
	padding-left: 52px;
    padding-bottom: 20px;	
	box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.14);
	justify-content: space-between;
	align-items: flex-start;
	${props => (props.open ? 'display: flex' : 'display: none')};
	${HeaderLink} {
		display: block;
	}
`;

export const UserImage = styled.img`
	width: 27px;
	height: 27px;
	display: block;
	border-radius: 4px;
`;

export const UserIcon = styled(IconComponent)`
	stroke: ${COLORS.grey};
`;
