import React from 'react';

// Atoms
import {
	Label, ProgressLeft, ProgressRight, ProgressRow, ProgressWrapper,
} from './Atoms';
import { BaseDivider, SmallVerticalDivider } from '../../Atoms/Divider';

// Molecules
import ProgressBar from '../../Molecules/ProgressBar';

const MarketDataReplyProgress = (props) => {
	const {
		eventsSent,
		eventsPercentageSent,
		eventsPercentageConfirmed,
		confirmationsReceived,
	} = props;
	return (
		<ProgressWrapper className="events-confirmations-box">
			<ProgressRow>
				<ProgressLeft>
					<Label>Events Sent:</Label>
					<SmallVerticalDivider />
					<p>{eventsSent}</p>
				</ProgressLeft>
				<ProgressRight>
					<ProgressBar percentage={eventsPercentageSent} />
				</ProgressRight>
			</ProgressRow>
			<BaseDivider />
			<ProgressRow>
				<ProgressLeft>
					<Label>Confirmations received:</Label>
					<SmallVerticalDivider />
					<p>{confirmationsReceived}</p>
				</ProgressLeft>
				<ProgressRight>
					<ProgressBar percentage={eventsPercentageConfirmed} />
				</ProgressRight>
			</ProgressRow>
		</ProgressWrapper>
	);
};

export default MarketDataReplyProgress;
