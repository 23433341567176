import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import WebSocketConnectionContext from '../../Context/WebsocketConnectionContext';
import { InfoTableCell, TableProgressBarWrapper } from '../../Atoms/Table';
import ProgressBar from '../../Molecules/ProgressBar';

function ConfirmationsProgress({ orderBookLength }) {
	const WebsocketContext = useContext(WebSocketConnectionContext);
	return (
		<tr>
			<InfoTableCell>Confirmations received</InfoTableCell>
			<InfoTableCell>{`${WebsocketContext.totalOrdersCounted}/${orderBookLength}`}</InfoTableCell>
			<TableProgressBarWrapper>
				<ProgressBar percentage={Math.round((WebsocketContext.totalOrdersCounted / orderBookLength) * 100)} />
			</TableProgressBarWrapper>
		</tr>
	);
}

ConfirmationsProgress.propTypes = {
	orderBookLength: PropTypes.number.isRequired,
};

export default ConfirmationsProgress;
