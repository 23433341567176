import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

// Types
import UI_TYPES from '../../data/ui/UITypes';

// Actions
import { addUserData } from '../../data/user/UserActions';

// Layouts
import PublicLayout from '../layouts/PublicLayout';

// Lib
import Api from '../../lib/api';
import { getItem } from '../../lib/localStorage';

// Context
import { useAuth } from '../../components/Context/auth';

// Pages
import Home from '../pages/Public/Home';
import About from '../pages/Public/About';
import FAQ from '../pages/Public/FAQ';
import Contact from '../pages/Public/Contact';
import Plans from '../pages/Public/Plans';
import PrivacyPolicy from '../pages/Public/PrivacyPolicy';
import TermsOfService from '../pages/Public/TermsOfService';

const PublicRouter = () => {
	const auth = useAuth();
	const dispatch = useDispatch();
	const userData = getItem('userData');

	useEffect(() => {
		Api.get('users/profile').then((response) => {
			if (response.type === UI_TYPES.TOGGLE_MODAL) {
				auth.logOut(true);
			} else {
				dispatch(addUserData(response.data));
			}
		});
	}, [auth, userData, dispatch]);

	return (
		<PublicLayout>
			<Switch>
				<Route
					exact
					path="/"
					component={Home}
				/>
				<Route
					exact
					path="/plans"
					component={Plans}
				/>
				<Route
					exact
					path="/about"
					component={About}
				/>
				<Route
					exact
					path="/faq"
					component={FAQ}
				/>
				<Route
					exact
					path="/contact"
					component={Contact}
				/>
				<Route
					exact
					path="/privacy-policy"
					component={PrivacyPolicy}
				/>
				<Route
					exact
					path="/terms-of-service"
					component={TermsOfService}
				/>
				<Redirect to="/" />
			</Switch>
		</PublicLayout>
	);
};
export default PublicRouter;
