import React, { createRef, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../lib/constants/General';

import { toggleModifyOrderModal } from '../../../data/ui/UIActions';
// Atoms
import { ModalTitle } from '../../Atoms/Text';
import { FlexColumn, FlexRow } from '../../Atoms/Flex';
import { PrimaryButton, WhiteGrayButton } from '../../Atoms/Button';
import {
	MediumDivider,
	MediumVerticalDivider, BaseDivider, SmallDivider,
} from '../../Atoms/Divider';
// Molecules
import Modal from '../../Molecules/Modal';
import { FullWidthFlexRow } from '../../Atoms/Wrapper';
import Input from '../../Molecules/Input/Input/Input';
import { COLORS } from '../../../lib/constants/Style';
import WebSocketConnectionContext from '../../Context/WebsocketConnectionContext';
import { modifyOrder } from '../../../lib/websockets';
import { getRandomClientOrderId, stringToNumber } from '../../../lib/exchange';

const LineDivider = styled.div`
	height: 2px;
	width: 100%;
	background: ${COLORS.borderGray};
`;

class ModifyOrderModal extends PureComponent {
	constructor(props) {
		super(props);
		this.quantity = createRef();
		this.price = createRef();
	}

	handleSubmit = () => {
		const {
			modalData,
		} = this.props;
		const {
			client,
			sessionId,
		} = this.context;
		const fix = modalData.get('fix');
		const order = modalData.get('order');
		const data = {
			price: this.price.current.getValue(),
			quantity: this.quantity.current.getValue(),
			memberId: order.get('memberId'),
			instrumentCode: order.get('instrumentCode'),
			orderId: '',
			ordType: order.get('ordType'),
			origClientOrderId: order.get('clientOrderId'),
			clientOrderId: getRandomClientOrderId(),
			side: order.get('side'),
			timeInForce: order.get('timeInForce'),
		};
		modifyOrder(client, fix, sessionId, data);
		this.handleClose();
	};

	handleClose = () => {
		const {
			actions,
		} = this.props;
		actions.toggleModifyOrderModal(false);
	};

	render() {
		const {
			modalInfo,
			modalData,
		} = this.props;

		if (modalInfo.get('visible') !== true) {
			return false;
		}
		const totalQuantity = modalData.getIn(['order', 'totalQuantity']);
		const quantity = modalData.getIn(['order', 'quantity']);
		const price = modalData.getIn(['order', 'price']);

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleClose}
				size={MODAL_SIZES.WIDE}
			>
				<FlexColumn>
					<ModalTitle>
						Modify Order
					</ModalTitle>
					<MediumDivider />
					<FullWidthFlexRow>
						<Input
							name="quantity"
							type="header"
							placeholder="Total quantity"
							initialValue={totalQuantity}
							ref={this.quantity}
						/>
					</FullWidthFlexRow>
					<SmallDivider />
					<FullWidthFlexRow>
						<Input
							name="price"
							type="header"
							placeholder="Price"
							initialValue={price}
							ref={this.price}
						/>
					</FullWidthFlexRow>
					<MediumDivider />
					<FullWidthFlexRow>
						Leaves quantity =
						{' '}
						{quantity}
					</FullWidthFlexRow>
					<BaseDivider />
					<FullWidthFlexRow>
						Cumulative quantity =
						{' '}
						{stringToNumber(totalQuantity) - stringToNumber(quantity)}
					</FullWidthFlexRow>
					<BaseDivider />
					<LineDivider />
					<MediumDivider />
					<FlexRow justifyContent="center">
						<PrimaryButton onClick={this.handleSubmit}>
							Modify
						</PrimaryButton>
						<MediumVerticalDivider />
						<WhiteGrayButton onClick={this.handleClose}>
							Cancel
						</WhiteGrayButton>
					</FlexRow>
				</FlexColumn>
			</Modal>
		);
	}
}

ModifyOrderModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleModifyOrderModal: PropTypes.func,
	}).isRequired,
	modalData: PropTypes.shape({}).isRequired,
};

ModifyOrderModal.contextType = WebSocketConnectionContext;

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.MODIFY_ORDER_MODAL]),
		modalData: state.getIn(['ui', 'modal', MODALS.MODIFY_ORDER_MODAL, 'data']),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleModifyOrderModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModifyOrderModal));
