import React, { memo } from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import * as Paho from 'paho-mqtt';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { List } from 'immutable';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';

// Atoms
import { BaseDivider, SmallestDivider } from '../../components/Atoms/Divider';

// Molecules
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import MarketDataReplyHeader from '../../components/Organisms/MarketDataReply/MarketDataReplyHeader';
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import MarketDataReplyTable from '../../components/Organisms/TableModels/MarketDataReplyTable';
import MarketDataPlayer from '../../components/Organisms/MarketDataReply/MarketDataPlayer';


import { getActiveExchange } from '../../data/exchanges/selectors';
import { ORDER_SIDE } from '../../lib/constants/Exchange';
import withData from '../../components/Hoc/withData';
import { resetActiveExchangeId } from '../../data/exchanges/ExchangesActions';
import { updateStoreWithWebsocketData } from '../../data/websockets/actions';
import { requestExchangeStatus } from '../../lib/websockets';
import EVENT from '../../lib/constants/Websockets';


class MarketDataReply extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			uuid: uuid(),
			report: List(),
			data: List(),
			exportBBBOJsonEventData: {
				bestBidPrice: '',
				bestBidQty: '',
				bestOfferQty: '',
				bestOfferPrice: '',
				lastPrice: '',
				lastQty: '',
				lastChange: '',
				bidsAdded: '',
				bidsModified: '',
				bidsCancelled: '',
				offersAdded: '',
				offersModified: '',
				offersCancelled: '',
				tradesCount: '',
				tradesSize: '',
				tradesAvgPrice: '',
			},
		};
	}

	componentDidMount() {
		this.pollForConnection();
	}


	componentWillUnmount() {
		const {
			actions,
		} = this.props;
		clearTimeout(this.timeoutId);
		this.disconnect();
		actions.resetActiveExchangeId();
	}

	pollForConnection = () => {
		this.connectToWs().then(() => {
			clearTimeout(this.timeoutId);
		}).catch(() => {
			this.timeoutId = setTimeout(() => this.pollForConnection(), 5000);
		});
	};

	disconnect = () => {
		if (this.client?.isConnected()) {
			this.client.disconnect();
			this.client = null;
		}
	};

	makeClient = () => {
		const {
			webSocketUrl,
		} = this.props;
		if (webSocketUrl && !this.client) {
			console.log('creating client', webSocketUrl);
			this.client = new Paho.Client(webSocketUrl, 8443, '');
		}
	};

	connectToWs = () => new Promise((resolve, reject) => {
		if (!this.client) {
			this.makeClient();
		}
		if (this.client && !this.client.isConnected()) {
			this.client.connect({
				onSuccess: (context) => {
					resolve();
					this.setState({ uuid: uuid() });
					this.onConnectCallback(context);
				},
				onFailure: () => reject(),
				useSSL: true,
				keepAliveInterval: 20,
			});
		}
	});

	onConnectCallback = (options) => {
		const { fix } = this.props;
		const { uuid: sessionId } = this.state;

		// Maybe add resubscription on fail !!!
		this.client.subscribe(`com/esprow/etp/gems/${fix}/ctrlgwy/publications`, {});
		this.client.subscribe(`com/esprow/etp/gems/${fix}/ctrlgwy/reply/data/${sessionId}`, {});
		this.client.subscribe(`com/esprow/etp/gems/${fix}/ctrlgwy/reply/command/${sessionId}`, {});
		this.client.subscribe(`com/esprow/etp/gems/${fix}/obk/+`, {});

		requestExchangeStatus(this.client, fix, sessionId);

		this.client.onMessageArrived = (msg) => {
			try {
				this.handleMessage(msg.payloadString);
			} catch (e) {
				// console.log(e);
			}
		};

		this.client.onConnectionLost = (c1, c2) => {
			this.pollForConnection();
		};
	};

	handleUpdate = (order, type) => {
		const {
			orderId,
			clientOrderId,
			memberId,
			side,
			receivedDateTime,
			price,
			totalQuantity,
			instrumentCode,
		} = order;
		// const id = Number(match.params.slug);
		const timeStamp = moment(`${receivedDateTime.seconds.split(',').join('')}${receivedDateTime.nanos.toString().substring(0, 3)}`, 'x');
		let memberIdBuy = '';
		let memberIdSell = '';
		let orderIdBuy = '';
		let orderIdSell = '';
		let clientOrderIdBuy = '';
		let clientOrderIdSell = '';
		if (side === ORDER_SIDE.BUY) {
			memberIdBuy = memberId;
			orderIdBuy = orderId;
			clientOrderIdBuy = clientOrderId;
		} else if (side === ORDER_SIDE.SELL) {
			memberIdSell = memberId;
			orderIdSell = orderId;
			clientOrderIdSell = clientOrderId;
		}

		this.setState(prevState => ({
			report: prevState.report.push([
				timeStamp.format('HH:mm:ss.SSS'),
				instrumentCode,
				type,
				side === ORDER_SIDE.BUY ? 'B' : 'S',
				totalQuantity,
				price,
				memberIdBuy,
				memberIdSell,
				orderIdBuy,
				orderIdSell,
				clientOrderIdBuy,
				clientOrderIdSell,
				'',
				this.state.exportBBBOJsonEventData.bestBidPrice,
				this.state.exportBBBOJsonEventData.bestBidQty,
				this.state.exportBBBOJsonEventData.bestOfferPrice,
				this.state.exportBBBOJsonEventData.bestOfferQty,
				this.state.exportBBBOJsonEventData.lastPrice,
				this.state.exportBBBOJsonEventData.lastQty,
				this.state.exportBBBOJsonEventData.lastChange,
			]),
			data: prevState.data.push([
				instrumentCode,
				price,
				this.state.exportBBBOJsonEventData.lastChange,
				this.state.exportBBBOJsonEventData.bestBidPrice,
				this.state.exportBBBOJsonEventData.bestOfferPrice,
				this.state.exportBBBOJsonEventData.bidsAdded,
				this.state.exportBBBOJsonEventData.bidsModified,
				this.state.exportBBBOJsonEventData.bidsCancelled,
				this.state.exportBBBOJsonEventData.offersAdded,
				this.state.exportBBBOJsonEventData.offersModified,
				this.state.exportBBBOJsonEventData.offersCancelled,
				this.state.exportBBBOJsonEventData.tradesCount,
				this.state.exportBBBOJsonEventData.tradesSize,
				this.state.exportBBBOJsonEventData.tradesAvgPrice]),
		}));
	};

	handleMessage = (unparsedMessage) => {
		const { actions } = this.props;
		const message = JSON.parse(unparsedMessage);
		// console.log(message);
		switch (message.requestName) {
		case EVENT.ExchangeStatusReply: {
			actions.updateStoreWithWebsocketData(EVENT.ExchangeStatusReply, {
				exchangeStartedTime: message.exchangeStartedTime,
				status: message.status,
				components: message.components,
				openedComponents: message.openedComponents,
				totalComponents: message.totalComponents,
			});
			break;
		}
		case EVENT.OrderBookCancelOrderReply: {
			this.handleUpdate(message.order, 'C');
			this.handleCounting(EVENT.OrderBookCancelOrderReply);
			break;
		}
		case EVENT.OrderBookAmendOrderReply: {
			this.handleUpdate(message.order, 'M');
			this.handleCounting(EVENT.OrderBookAmendOrderReply);
			break;
		}
		case EVENT.OrderBookAddOrderReply: {
			this.handleUpdate(message.order, 'O');
			this.handleCounting(EVENT.OrderBookAddOrderReply);
			break;
		}
		case EVENT.OrderBookRequestRejectReply: {
			this.handleCounting(EVENT.OrderBookRequestRejectReply);
			break;
		}
		default: {

		}
		}
		switch (message.type) {
		case EVENT.TradeTickerUpdateJsonEvent: {
			this.setState({
				exportBBBOJsonEventData: {
					...this.state.exportBBBOJsonEventData,
					lastChange: message.entries.changeAbsolute,
				},
			});
			break;
		}
		case EVENT.TradeJsonEvent: {
			const {
				tradeTimestamp, instrumentCode, tradeId, tradeQuantity, tradePrice, buyOrder: {
					memberId: buyMemberId,
					orderId: buyOrderId,
					clientOrderId: buyClientOrderId,
				},
				sellOrder: {
					memberId: sellMemberId,
					orderId: sellOrderId,
					clientOrderId: sellClientOrderId,
				},
			} = message.entries[0];
			const timeStamp = moment(`${tradeTimestamp.seconds.split(',').join('')}${tradeTimestamp.nanos.toString().substring(0, 3)}`, 'x');

			this.setState(prevState => ({
				report: prevState.report.push([
					timeStamp.format('HH:mm:ss.SSS'),
					instrumentCode,
					'T',
					'',
					tradeQuantity,
					tradePrice,
					buyMemberId,
					sellMemberId,
					buyOrderId,
					sellOrderId,
					buyClientOrderId,
					sellClientOrderId,
					tradeId,
					this.state.exportBBBOJsonEventData.bestBidPrice,
					this.state.exportBBBOJsonEventData.bestBidQty,
					this.state.exportBBBOJsonEventData.bestOfferPrice,
					this.state.exportBBBOJsonEventData.bestOfferQty,
					this.state.exportBBBOJsonEventData.lastPrice,
					this.state.exportBBBOJsonEventData.lastQty,
					this.state.exportBBBOJsonEventData.lastChange,
				]),
			}));
			break;
		}
		case EVENT.BBBOJsonEvent: {
			// console.log(message.entries);
			const {
				bestBidPrice,
				bestBidQuantity,
				bestOfferPrice,
				bestOfferQuantity,
				lastCrossingPrice,
				lastCrossingQuantity,
				orderBookCounters,
			} = message.entries;
			const exportBBBOJsonEventData = {};
			exportBBBOJsonEventData.bestBidPrice = bestBidPrice;
			exportBBBOJsonEventData.bestBidQty = bestBidQuantity;
			exportBBBOJsonEventData.bestOfferPrice = bestOfferPrice;
			exportBBBOJsonEventData.bestOfferQty = bestOfferQuantity;
			exportBBBOJsonEventData.lastPrice = lastCrossingPrice;
			exportBBBOJsonEventData.lastQty = lastCrossingQuantity;


			exportBBBOJsonEventData.bidsAdded = `${(orderBookCounters.memberTotalBidsCount['99999']) ? orderBookCounters.memberTotalBidsCount['99999'] : '0'}/${orderBookCounters.totalBidsCount}`;
			exportBBBOJsonEventData.offersAdded = `${(orderBookCounters.memberTotalOffersCount['99999']) ? orderBookCounters.memberTotalOffersCount['99999'] : '0'}/${orderBookCounters.totalOffersCount}`;
			exportBBBOJsonEventData.tradesCount = `${(orderBookCounters.memberTradesCount['99999']) ? orderBookCounters.memberTradesCount['99999'] : '0'}/${orderBookCounters.totalTradesCount}`;

			exportBBBOJsonEventData.bidsModified = (orderBookCounters.memberBidsModified['99999']) ? orderBookCounters.memberBidsModified['99999'] : '0';
			exportBBBOJsonEventData.bidsCancelled = (orderBookCounters.memberBidsCancelled['99999']) ? orderBookCounters.memberBidsCancelled['99999'] : '0';
			exportBBBOJsonEventData.offersModified = (orderBookCounters.memberOffersModified['99999']) ? orderBookCounters.memberOffersModified['99999'] : '0';
			exportBBBOJsonEventData.offersCancelled = (orderBookCounters.memberOffersCancelled['99999']) ? orderBookCounters.memberOffersCancelled['99999'] : '0';


			exportBBBOJsonEventData.tradesSize = `${(orderBookCounters.memberTradesVolume['99999']) ? orderBookCounters.memberTradesVolume['99999'] : '0'}/${orderBookCounters.totalVolume}`;

			const avgPrice1 = (parseFloat(Number(orderBookCounters.totalTurnover.toString().replace(/[^0-9\.]+/g, '')) / Number(orderBookCounters.totalVolume.toString().replace(/[^0-9\.]+/g, ''))).toFixed(2));
			const avgPrice2 = (parseFloat(Number(((orderBookCounters.memberTradesTurnover['99999']) ? orderBookCounters.memberTradesTurnover['99999'].toString().replace(/[^0-9\.]+/g, '') : '0')) / Number(((orderBookCounters.memberTradesVolume['99999']) ? orderBookCounters.memberTradesVolume['99999'].toString().replace(/[^0-9\.]+/g, '') : '0'))).toFixed(2));
			exportBBBOJsonEventData.tradesAvgPrice = `${isNaN(avgPrice1) ? 0 : avgPrice1}/${isNaN(avgPrice2) ? 0 : avgPrice2}`;
			this.setState({ exportBBBOJsonEventData });
			break;
		}
		default: {

		}
		}
	}

	/**
	 * Function that tells websocket router to count for specific events
	 * Can be triggered from anywhere within context
	 * @param num - {Number} Number of events to count
	 * @param events - {Array} Array of event types to count for
	 */
	count = (num, events) => {
		// Set counting true to all passed events
		const counting = events.reduce((acc, event) => ({ ...acc, [event]: true }), {});
		// Set finish and starting line of counting
		this.setState({ counting, finishOn: num, current: 0 });
	};


	handleCounting = (event) => {
		const {
			counting,
			current,
			finishOn,
		} = this.state;

		if (current === finishOn) {
			const newCounting = { ...counting, [event]: false };
			this.setState({ counting: newCounting, finishOn: -1 });
			return;
		}
		// This decides if we should count number of these events
		if (counting[event]) {
			this.setState(prevState => ({
				current: prevState.current + 1,
			}));
		}
	};

	handleExport = () => {
		const wsName = 'Sheet1';

		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(this.state.report.unshift(
			[
				'TIME',
				'VIEW_CODE',
				'TYPE',
				'SIDE',
				'QUANTITY',
				'PRICE',
				'MEMBER_ID_BUY',
				'MEMBER_ID_SELL',
				'ORDER_ID_BUY',
				'ORDER_ID_SELL',
				'CLIENT_ORDER_ID_BUY',
				'CLIENT_ORDER_ID_SELL',
				'TRADE_ID',
				'BEST_BID_PRICE',
				'BEST_BID_QTY',
				'BEST_OFFER_PRICE', 'BEST_OFFER_QTY', 'LAST_PRICE', 'LAST_QTY', 'LAST_CHANGE']
		).toJS());

		/* add worksheet to workbook */
		wb.SheetNames.push(wsName);
		wb.Sheets[wsName] = ws;
		const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });

		saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'mdreplay.xlsx');
	};

	startAgain = () => {
		this.setState({
			data: List(),
			report: List(),
			exportBBBOJsonEventData: {
				bestBidPrice: '',
				bestBidQty: '',
				bestOfferQty: '',
				bestOfferPrice: '',
				lastPrice: '',
				lastQty: '',
				lastChange: '',
				bidsAdded: '',
				bidsModified: '',
				bidsCancelled: '',
				offersAdded: '',
				offersModified: '',
				offersCancelled: '',
				tradesCount: '',
				tradesSize: '',
				tradesAvgPrice: '',
			},
		});
	};

	render() {
		const {
			clientExchangeId,
			displayId,
			fix,
			exchangeStatus,
		} = this.props;

		const {
			data,
			uuid: sessionId,
			current,
		} = this.state;
		return (
			<PageWrapper
				breadcrumbs={[
					{
						link: `exchanges/${clientExchangeId}`,
						name: displayId,
					},
					{
						link: 'market-data-replay',
						name: 'Market Data Replay',
					},
				]}
				title="Market Data Replay"
				pageWrapperRightSide={<MarketDataReplyHeader />}
			>
				<DocumentTitle title={`${displayId} - Market Data Replay`} />
				<MarketDataPlayer
					fix={fix}
					client={this.client}
					sessionId={sessionId}
					handleExportClick={this.handleExport}
					startAgain={this.startAgain}
					exchangeStatus={exchangeStatus}
					count={this.count}
					confirmations={current}
				/>
				<SmallestDivider double />
				<BaseDivider />
				{/* <Scrollbar> */}
				{/*	<FlexColumn> */}
				{/*		{data.map(row => ( */}
				{/*			<FlexRow> */}
				{/*				<div>{row[0]}</div> */}
				{/*				<div>{row[1]}</div> */}
				{/*				<div>{row[2]}</div> */}
				{/*				<div>{row[3]}</div> */}
				{/*				<div>{row[4]}</div> */}
				{/*				<div>{row[5]}</div> */}
				{/*				<div>{row[6]}</div> */}
				{/*				<div>{row[7]}</div> */}
				{/*				<div>{row[8]}</div> */}
				{/*				<div>{row[9]}</div> */}
				{/*				<div>{row[10]}</div> */}
				{/*				<div>{row[11]}</div> */}
				{/*			</FlexRow> */}
				{/*		))} */}
				{/*	</FlexColumn> */}
				{/* </Scrollbar> */}
				<MarketDataReplyTable data={data} />
			</PageWrapper>

		);
	}
}

function mapStateToProps(state) {
	const activeExchange = getActiveExchange(state);
	return {
		webSocketUrl: activeExchange?.get('machineInstance').get('domain'),
		clientExchangeId: activeExchange?.get('clientExchangeId'),
		displayId: activeExchange?.get('displayId'),
		fix: activeExchange?.get('exchangeType')?.get('code'),
		exchangeStatus: activeExchange?.get('status'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			resetActiveExchangeId,
			updateStoreWithWebsocketData,
		}, dispatch),
	};
}
MarketDataReply.propTypes = {
	webSocketUrl: PropTypes.string.isRequired,
	clientExchangeId: PropTypes.string.isRequired,
	displayId: PropTypes.string.isRequired,
	fix: PropTypes.string.isRequired,
	exchangeStatus: PropTypes.string.isRequired,
};
export default withData(memo(connect(mapStateToProps, mapDispatchToProps)(MarketDataReply)));
