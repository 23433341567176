import React, { useEffect } from 'react';

export default function useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, tableRows) {
	const [sortBy, setSortBy] = React.useState({ index: -1, sort: 'DEFAULT' });
	useEffect(() => {
		// do search
		if (sortBy.sort === 'DEFAULT') {
			setDataToRender(getDataToRender(tableRows));
			return;
		}

		if (!dataToRender) {
			return;
		}

		const dataToSort = [...dataToRender];

		setDataToRender(dataToSort.sort((v1, v2) => {
			const value1 = v1.data[sortBy.index].accessor ? v1.data[sortBy.index].accessor : v1.data[sortBy.index];
			const value2 = v2.data[sortBy.index].accessor ? v2.data[sortBy.index].accessor : v2.data[sortBy.index];
			if (value1 > value2) {
				return sortBy.sort === 'ASC' ? -1 : 1;
			}
			if (value1 < value2) {
				return sortBy.sort === 'ASC' ? 1 : -1;
			}
			if (value1 === value2) {
				return 0;
			}
			return 0;
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortBy]);

	const handleSort = React.useCallback((v) => {
		let index = -1;
		if (tableHeader[0]?.accessor) {
			index = tableHeader.findIndex(item => item.accessor === v.accessor);
		} else {
			index = tableHeader.findIndex(item => item.name === v.name);
		}
		if (sortBy.index === index) {
			if (sortBy.sort === 'DEFAULT') {
				setSortBy({ index, sort: 'ASC' });
				return;
			}
			if (sortBy.sort === 'ASC') {
				setSortBy({ index, sort: 'DESC' });
				return;
			}
			if (sortBy.sort === 'DESC') {
				setSortBy({ index, sort: 'DEFAULT' });
			}
		} else {
			setSortBy({ index, sort: 'ASC' });
		}
	}, [sortBy.index, sortBy.sort, tableHeader]);

	return { handleSort, sortedBy: sortBy };
}
