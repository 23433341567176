import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MODAL_SIZES, MODALS } from '../../../../lib/constants/General';
// Atoms
import { BlockTitle, ModalTitle } from '../../../Atoms/Text';
import { FlexColumn } from '../../../Atoms/Flex';
import { CloseButton, ShortCuts } from './ShortcutsModal.Atoms';
import { MediumDivider, SmallDivider } from '../../../Atoms/Divider';
// Molecules
import Modal from '../../../Molecules/Modal';
// Actions
import { toggleShortcutsModal } from '../../../../data/ui/UIActions';
// Data
import {
	exchangeActionShortcuts,
	exchangesActionShortcuts,
	orderBookActionShortcuts,
} from '../../../../data/placeholderData/shortcuts';

class ShortcutsModal extends PureComponent {
	handleCloseModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleShortcutsModal(false);
	};

	render() {
		const {
			modalInfo,
		} = this.props;

		if (modalInfo?.get('visible') !== true) {
			return false;
		}

		return (
			<Modal
				showModal={modalInfo?.get('visible')}
				closeModal={this.handleCloseModal}
				size={MODAL_SIZES.MEDIUM}
			>
				<FlexColumn alignItems="flex-start">
					<ModalTitle>
						Keyboard shortcuts
					</ModalTitle>
					<MediumDivider />
					<BlockTitle>
						Exchanges page
					</BlockTitle>
					<SmallDivider />
					<ShortCuts
						shortcuts={exchangesActionShortcuts}
					/>
					<MediumDivider />
					<BlockTitle>
						Exchange page
					</BlockTitle>
					<SmallDivider />
					<ShortCuts
						shortcuts={exchangeActionShortcuts}
					/>
					<MediumDivider />
					<BlockTitle>
						Order Book page
					</BlockTitle>
					<SmallDivider />
					<ShortCuts shortcuts={orderBookActionShortcuts} />
					<MediumDivider />
					<CloseButton onClick={this.handleCloseModal}>Close</CloseButton>
				</FlexColumn>
			</Modal>
		);
	}
}

ShortcutsModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleShortcutsModal: PropTypes.func,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.SHORTCUTS_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleShortcutsModal,
		}, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShortcutsModal));
