import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { toggleBillingValidationModal } from '../../../../data/ui/UIActions';
import { checkout } from '../../../../data/billing/BillingActions'
	;
// Constants
import { MODAL_SIZES, MODALS } from '../../../../lib/constants/General';

// Atoms
import { FlexRow } from '../../../Atoms/Flex';
import { PrimaryButton, SecondaryButton } from '../../../Atoms/Button';
import { LargeDivider, MediumVerticalDivider } from '../../../Atoms/Divider';

// Molecules
import Modal from '../../../Molecules/Modal';
import { Redirect } from 'react-router';

class BillingValidationModal extends PureComponent {
	closeModal = () => {
		const {
			actions,
		} = this.props;
		actions.toggleBillingValidationModal(false);
	};

	createMarkup(message) {
		return {
			__html: message,
		}
	};

	confirmSubscription = () => {
		const {
			actions,
		} = this.props;
		actions.checkout();
		actions.toggleBillingValidationModal(false);
	};

	render() {
		const {
			modalInfo,
		} = this.props;
		if (modalInfo.get('visible') !== true) {
			return false;
		}
		const message = modalInfo.get('data');
		return (
			<Modal
				showModal={modalInfo.get('visible')}
				closeModal={this.closeModal}
				size={MODAL_SIZES.SMALL}
			>
				<div dangerouslySetInnerHTML={this.createMarkup(message)} />
				<LargeDivider />
				<FlexRow justifyContent="flex-end">
					<PrimaryButton onClick={this.confirmSubscription}>Confirm</PrimaryButton>
					<MediumVerticalDivider />
					<SecondaryButton onClick={this.closeModal}>Cancel</SecondaryButton>
				</FlexRow>
			</Modal>
		);
	}
}

BillingValidationModal.propTypes = {
	modalInfo: PropTypes.shape({}).isRequired,
	actions: PropTypes.shape({
		toggleBillingValidationModal: PropTypes.func,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.BILLING_VALIDATION_MODAL]),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleBillingValidationModal,
			checkout,
		}, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingValidationModal);
