import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Atoms
import {
	HeadingText, SortIcon, SortIconWrapper, TableCheckboxWrapper, TableHeadingItem, TableHeadingWrapper,
} from '../Atoms/TableAtoms';
import { FlexRow } from '../../../Atoms/Flex';
import { COLORS } from '../../../../lib/constants/Style';

// Molecules
import CheckBoxInput from '../../../Molecules/Input/CheckBox/CheckBox';

const HeaderGroup = styled.th`
	text-align: center;
	color: ${COLORS.grey};
	padding-bottom: 10px;
	padding-right: 20px;
	z-index: 23 !important;
	&:before {
		content: '';
		position: absolute;
		left: -10px;
		top: 0;
		width: 1px;
		height: 52px;
		background-color: ${COLORS.borderGray};;
	}
`;
export const HeaderGroups = styled.tr`
	display: none;
`;
const TableHeading = (props) => {
	const {
		header,
		handleSort,
		handleSelectAll,
		allSelected,
		height,
		selectable,
		sortedBy,
	} = props;

	return (
		<>
			<thead>
				<HeaderGroups>
					<th colSpan={5} />
					<HeaderGroup colSpan={3}>BIDS</HeaderGroup>
					<HeaderGroup colSpan={3}>OFFERS</HeaderGroup>
					<HeaderGroup colSpan={3}>TRADE</HeaderGroup>
				</HeaderGroups>
				<TableHeadingWrapper
					height={height}
				>
					{header.map((heading, i) => (
						<TableHeadingItem
							onClick={() => {
								if (heading.sortable) {
									handleSort(heading);
								}
							}}
							key={heading.name}
							width={heading.width}
							sortable={heading.sortable}
							visible={heading.visible}
						>

							<FlexRow>
								{selectable && i === 0 && (
									<TableCheckboxWrapper>
										<CheckBoxInput
											onChange={(e) => {
												handleSelectAll(e.target.checked);
											}}
											value={allSelected}
										/>
									</TableCheckboxWrapper>
								)}
								<HeadingText>{heading.name}</HeadingText>
								{heading.sortable && (
									<SortIconWrapper active={i === sortedBy?.index ? sortedBy?.sort : false}>
										<SortIcon
											name={(i === sortedBy?.index && sortedBy?.sort === 'ASC') ? 'arrowUp' : 'arrowDown'}
										/>
									</SortIconWrapper>
								)}
							</FlexRow>

						</TableHeadingItem>

					))}
				</TableHeadingWrapper>
			</thead>
		</>
	);
};

TableHeading.defaultProps = {
	height: null,
	allSelected: false,
	sortedBy: {},
};

TableHeading.propTypes = {
	header: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	handleSort: PropTypes.func.isRequired,
	handleSelectAll: PropTypes.func.isRequired,
	allSelected: PropTypes.bool,
	height: PropTypes.string,
	selectable: PropTypes.bool.isRequired,
	sortedBy: PropTypes.shape({}),
};

export default memo(TableHeading);
