import React, { PureComponent } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
// Actions
import {
	resetBillingItems,
} from '../../data/billing/BillingActions';

// Constants
import { BILLING_MESSAGE_TYPES, BILLING_MESSAGES } from '../../lib/constants/Billing';

// Atoms
import { PrimaryButton } from '../../components/Atoms/Button';
import { Title } from '../../components/Atoms/WebSite/Text';
import { MediumDivider, SmallDivider } from '../../components/Atoms/Divider';

// Molecules
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import SuccessfulSubscription from '../../components/Organisms/SuccessfulSubscription';
import { AuthContext } from '../../components/Context/auth';

const Wrapper = styled.div`
	padding: 50px;
	margin: auto auto;
	display: flex;
	flex-direction: column;
	background: white;
	max-width: 600px;
	text-align: center;
`;

class SubscriptionInfo extends PureComponent {
	handleBack = () => {
		const {
			actions,
		} = this.props;
		actions.resetBillingItems();
		actions.push('/app/subscription');
	};

	render() {
		const {
			location: {
				search,
			},
		} = this.props;

		// Extract message parameter from redirect url query
		const message = search.slice(9);

		// Redirect to subscription if user deleted
		if (message.includes('Removed')) {
			this.context.decideUserType().finally(() => {
				this.handleBack();
			});
		}
		// Redirect if the message parameter is not good
		if (!Object.values(BILLING_MESSAGE_TYPES).includes(message)) {
			return <Redirect to="/app/exchanges" />;
		}


		if (message === BILLING_MESSAGE_TYPES.SUCCESSFUL_SUBSCRIPTION) {
			return (
				<SuccessfulSubscription />
			);
		}

		return (
			<Wrapper>
				<DocumentTitle title="Subscription" />
				<Title>
					{BILLING_MESSAGES[message].title}
				</Title>
				<SmallDivider />
				<div>{BILLING_MESSAGES[message].description}</div>
				<MediumDivider />
				<PrimaryButton onClick={this.handleBack}>
					Go to exchange page
				</PrimaryButton>
			</Wrapper>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		resetBillingItems,
		push,
	}, dispatch),
});

SubscriptionInfo.contextType = AuthContext;
export default connect(null, mapDispatchToProps)(SubscriptionInfo);
