const FAQ_DATA = [
	{
		title: 'General',
		data: [
			{
				label: 'What is etpmarkets.com?',
				text: 'Etpmarkets.com is a cloud platform to create exchange simulators.',
			},
			{
				label: 'Do I have full control of the simulator?',
				text: 'Yes, you control the exchange simulator as if you were the exchange operator.',
			},
			{
				label: 'Are simulator instances shared?',
				text: 'No, they are not shared. You are the only one operating the simulator and connecting to it.',
			},
			{
				label: 'What regions are the simulator available?',
				text: `Simulators are available in the following regions: \n
					\n I. NYC1, NYC2, NYC3: New York City, United States
					\n II. AMS2, AMS2: Amsterdam, the Netherlands
					\n III. SFO1, SFO2: San Francisco, United States
					\n IV. SGP1: Singapore
					\n V. LON1: London, United Kingdom
					\n VI. FRA1: Frankfurt, Germany
					\n VII. TOR1: Toronto, Canada
					\n VIII. BLR1: Bangalore, India`,
			},
			{
				label: 'What protocols the simulators support?',
				text: `Currently only the FIX protocol is support – all versions. Simulators for FIX-specific venues and for binary venues are almost ready and will be deployed soon. Subscribe to our newsletter to stay up to date!`,
			},
			{
				label: 'What venue-specific binary protocols will you support in the upcoming simulators?',
				text: 'This is a list of binary protocols we plan to support:',
			},
			{
				label: 'What venue-specific FIX protocols will you support in the upcoming simulators?',
				text: 'This is a list of FIX protocols we plan to support:',
			},
			{
				label: 'Do simulator support trading phases?',
				text: 'Yes, they support all the trading phases supported by the real market. At the moment trading phases are not enabled since the current simulators are vanilla FIX.',
			},
			{
				label: 'Do simulators support multiple concurrent order books?',
				text: 'Yes, simulators support multiple order books like the normal exchange. The number of order books is only limited by the virtual machine specifications.',
			},
			{
				label: 'Do the simulators support different matching strategies?',
				text: `Yes, venue-specific simulators implement the specific market’s matching strategy. However, our technology supports pluggable matching strategies. Contact Esprow sales directly for more information.`,
			},
			{
				label: 'Can I deploy the simulators on bigger virtual machines?',
				text: 'Yes, you can select the size of the virtual machine where the simulator is deployed when creating the simulator.',
			},
			{
				label: 'Can I deploy the simulator in-house on my own hardware?',
				text: 'This is possible but not through etpmarkets.com, since it requires an enterprise deployment. Contact Esprow sales directly for more information.',
			},
			{
				label: 'Do the simulators support multiple trading sessions?',
				text: 'Yes, you can configure as many trading sessions as needed. The total number of sessions is only limited by the package you purchased.',
			},
			{
				label: 'Can I replay market data into the simulator order book?',
				text: 'Yes, you can replay your own synthetic market data. Currently data must be provided through Excel (CSV).',
			},
			{
				label: 'Can I replay market data through an API?',
				text: 'At the moment only Excel (CSV) files are supported for market data but we work on an API is almost completed. Subscribe to our newsletter to stay up to date!',
			},
			{
				label: 'Do the simulators provide a market data feed?',
				text: 'Yes, but the market data feeds have not been made available yet through etpmarkets.com. They will be made available soon.',
			},
			{
				label: 'Can I control the simulator through a REST API?',
				text: 'No at the moment, but we are working on this.',
			},
			{
				label: 'Can I synchronize the simulator’s system clock with mine?',
				text: 'No at the moment, but we would like to hear from your about what specific requirement you would need. Please send us your feedback.',
			},
		],
	},
];

export default FAQ_DATA;
