import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Actions
import fetchDictionaries from '../../data/exchangeDictionaries/DictionariesActions';
import { toggleExchangeDictionariesModal } from '../../data/ui/UIActions';

// Selectors
import { getMessages, getComponents, getFields } from '../../data/exchangeDictionaries/selectors';
import { getActiveExchange } from '../../data/exchanges/selectors';

// Atoms
import { FlexRow } from '../../components/Atoms/Flex';

// Molecules
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import ExchangeDictionaryModal from '../../components/Organisms/Modals/ExchangeDictionaryModal/ExchangeDictionary';
import ExchangeDictionaryTable from '../../components/Organisms/TableModels/ExchangeDictionaryTable';
import ExchangeDictionaryHeader from '../../components/Organisms/ExchangeDictionaryheader';

// Move this to selector
const addFieldData = (field, fields) => {
	const selectedField = fields.find(item => item.tag === field.get('tag'));
	let metaData = null;
	if (selectedField?.metaData) {
		metaData = selectedField.metaData.set('required', field.get('required'));
	}
	const required = field.get('required') ? 'Y' : 'N';
	return ({
		rowData: [field.get('name'), field.get('tag'), '', '', required],
		name: field.get('name'),
		id: field.get('tag'),
		open: false,
		children: field.get('content'),
		metaData,
	});
};

const addGroupData = (item, components, fields, formatEachChild) => {
	const content = item.get('content').map(child => formatEachChild(child, components, fields));
	const itemWithComponentData = item.set('content', content);
	const required = item.get('required') ? 'Y' : 'N';


	return ({
		rowData: [itemWithComponentData.get('name'), itemWithComponentData.get('tag'), '', '', required],
		id: itemWithComponentData.get('tag'),
		name: itemWithComponentData.get('name'),
		open: false,
		children: itemWithComponentData.get('content'),
		metaData: itemWithComponentData,
	});
};

const addComponentData = (item, components, fields, formatEachChild) => {
	const component = components.find(componentItem => componentItem.metaData.get('name') === item.get('name'));
	const content = component.content.map(child => formatEachChild(child, components, fields));
	const itemWithComponentData = item.set('content', content);
	const required = item.get('required') ? 'Y' : 'N';

	return ({
		rowData: [itemWithComponentData.get('name'), '', '', '', required],
		id: itemWithComponentData.get('tag'),
		name: itemWithComponentData.get('name'),
		open: false,
		children: itemWithComponentData.get('content'),
		metaData: itemWithComponentData,
	});
};

const formatEachChild = (child, components, fields) => {
	if (child.get('type') === 'component') {
		return addComponentData(child, components, fields, formatEachChild);
	}
	if (child.get('type') === 'group') {
		return addGroupData(child, components, fields, formatEachChild);
	}
	if (child.get('type') === 'field') {
		return addFieldData(child, fields);
	}
	return null;
};

const dataForModal = (data, components, fields) => data.map(item => formatEachChild(item, components, fields));

const ExchangeDictionary = ({
	actions,
	messages,
	components,
	fields,
	activeExchange,
}) => {
	useEffect(() => {
		const exchangeFix = activeExchange.getIn(['exchangeType', 'name']).split(' ').join('');
		actions.fetchDictionaries(`${exchangeFix.slice(0, 3)}.${exchangeFix.slice(3)}`);
	}, [activeExchange, actions]);

	const getReferenceData = (name) => {
		const selectedComponent = components.find(component => component.name === name);
		if (selectedComponent) {
			return { data: dataForModal(selectedComponent.content, components, fields).toJS(), title: `${selectedComponent.name}` };
		}
		const selectedMessage = messages.find(message => message.name === name);
		if (selectedMessage) {
			return { data: dataForModal(selectedMessage.content, components, fields).toJS(), title: `${selectedMessage.name} [${selectedMessage.tag}]` };
		}
		const selectedField = fields.find(field => field.name === name);
		if (selectedField) {
			return { data: selectedField.metaData.toJS(), title: 'Field' };
		}
		return null;
	};

	const openModal = (data, title) => {
		if (title === 'Messages' || title === 'Components') {
			const modalData = dataForModal(data.content, components, fields);
			const titleText = title === 'Messages' ? `${data.name} [${data.tag}]` : data.name;
			actions.toggleExchangeDictionariesModal(true, { data: modalData, getReferenceData, title: titleText });
		} else {
			actions.toggleExchangeDictionariesModal(true, { data: data.metaData, getReferenceData, title: 'Field' });
		}
	};

	const messageHeader = [
		{
			name: 'Name', sortable: true, index: 0, visible: true, path: ['name'], width: '80%',
		},
		{
			name: 'Type', sortable: true, index: 1, visible: true, path: ['tag'], width: '20%',
		},
	];

	const componentsHeader = [
		{
			name: 'Name', sortable: true, index: 0, visible: true, path: ['name'], width: '100%',
		},
	];

	const fieldHeader = [
		{
			name: 'Name', sortable: true, index: 0, visible: true, path: ['name'], width: '80%',
		},
		{
			name: 'Tag', sortable: true, index: 1, visible: true, path: ['tag'], width: '20%',
		},
	];

	return (
		<PageWrapper
			breadcrumbs={[
				{
					link: `exchanges/${activeExchange.get('clientExchangeId')}`,
					name: activeExchange.get('displayId'),
				},
				{
					link: 'exchange-dictionary',
					name: 'Exchange Dictionary',
				},
			]}
			pageWrapperRightSide={(
				<ExchangeDictionaryHeader
					ipAddress={activeExchange?.get('machineInstance').get('ipAddress')}
					vmStatus={activeExchange?.get('machineInstance').get('status')}
					exchangeStatus={activeExchange?.get('status')}
				/>
			)}
		>
			<DocumentTitle title={`${activeExchange.get('displayId')} - Exchange Dictionary`} />
			<FlexRow style={{ height: '100%' }} className="exchange-dictionary-main-area">
				<ExchangeDictionaryTable
					rowClick={data => openModal(data, 'Messages')}
					header={messageHeader}
					rows={messages}
					title="Messages"
				/>
				<ExchangeDictionaryTable
					header={componentsHeader}
					rowClick={data => openModal(data, 'Components')}
					rows={components}
					title="Components"
				/>
				<ExchangeDictionaryTable
					header={fieldHeader}
					rowClick={data => openModal(data, 'Field')}
					rows={fields}
					title="Fields/Tags"
				/>
				<ExchangeDictionaryModal />
			</FlexRow>
		</PageWrapper>
	);
};

ExchangeDictionary.propTypes = {
	actions: PropTypes.shape({
		fetchDictionaries: PropTypes.func,
		toggleExchangeDictionariesModal: PropTypes.func,
	}).isRequired,
	messages: PropTypes.shape({}).isRequired,
	components: PropTypes.shape({}).isRequired,
	fields: PropTypes.shape({}).isRequired,
	activeExchange: PropTypes.shape({
		get: PropTypes.func,
	}).isRequired,
	// dataTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
	messages: getMessages(state.getIn(['dictionaries', 'messages'])),
	components: getComponents(state.getIn(['dictionaries', 'components'])),
	fields: getFields(state.getIn(['dictionaries', 'fields'])),
	activeExchange: getActiveExchange(state),
	// dataTypes: getDataTypes(state.getIn(['dictionaries', 'dataType'])),
});
const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		fetchDictionaries,
		toggleExchangeDictionariesModal,
	}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeDictionary);
