import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import { AccordionWrapper } from '../Atoms';

export const Accordion = React.memo((props) => {
	const {
		isOpen,
		Item,
		Label,
		hasMargin,
	} = props;
	// If you need to add additional props to React element you have to clone it
	const RenderLabel = React.useMemo(() => React.cloneElement(Label, { open: isOpen }), [Label, isOpen]);
	return (
		<AccordionWrapper hasMargin={hasMargin}>
			{RenderLabel}
			{isOpen && Item}
		</AccordionWrapper>
	);
}, (p1, p2) => p1.isOpen === p2.isOpen);
Accordion.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	Item: PropTypes.shape({}).isRequired,
	Label: PropTypes.shape({}).isRequired,
	hasMargin: PropTypes.bool,
};
Accordion.defaultProps = {
	hasMargin: false,
};
