import styled from 'styled-components';

// Css
import { COLORS } from '../../../lib/constants/Style';
import { BaseBlockStyle, TextBaseSmall, TextSmall } from '../../Css/Css';

// Atoms
import { FlexColumn, FlexColumnBase, FlexRowBase } from '../../Atoms/Flex';
import { DEVICE } from '../../../lib/constants/Device';
import { BaseDivider, MediumDivider, SmallDivider } from '../../Atoms/Divider';

export const RightSectionWrapper = styled.div`
	${FlexColumn};
	height: 100%;
	flex: 4;
	${FlexColumnBase};
	justify-content: space-between;
`;
export const TabsSection = styled.div`
	${BaseBlockStyle};
	height: 48%;
	${TextBaseSmall};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const Tables = styled.div`
	${FlexRowBase};
	height: 50%;
	width: 100%;
	align-items: flex-start;
	
	@media ${DEVICE.laptopL} {
		${MediumDivider} {
			min-height: 15px;
		}
	};
`;
export const LeftTable = styled.div`
	margin-right: 25px;
	width: 100%;
	height: 100%;
	${BaseBlockStyle};
  	box-shadow: 0px 2px 7.8px 0.2px ${COLORS.shadeGray};
  	flex: 1;
  	overflow: hidden; 	
  	@media ${DEVICE.laptopL} {
		margin-right: 15px;
	};
`;
export const RightTable = styled.div`
	height: 100%;
	width: 100%;
	flex: 1;
	overflow: hidden;
`;
export const MarketDepthTablesWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: hidden;
	table {
		th {
			padding-left: 10px;
			&:last-of-type {
				padding-left: 35px;
			}	
		}
		tr td > div > div {
			padding-left: 10px;
		}
	}
	
	& > div:last-of-type {
		table{
			th:first-child{
				margin-left: 10px;
			}
			tr td:first-child{
				margin-left: 10px;
			}
		}
	}
`;
export const MarketDepthTableWrapper = styled.div`
	${BaseBlockStyle};
	padding: 30px 15px 30px 30px;
  	box-shadow: 0px 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
	margin-right: 25px;
	flex: 5;
	${FlexColumnBase};
	overflow: hidden;
	  	
  	@media ${DEVICE.laptopL} {
  		padding: 15px;
		margin-right: 15px;
	};
`;
export const TimesAndSalesTableWrapper = styled.div`
	${BaseBlockStyle};
	padding: 30px 15px 30px 30px;
 	box-shadow: 0px 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
	margin-right: 25px;
	flex: 2.5;
	${FlexColumnBase};
	overflow: hidden;
  	@media ${DEVICE.laptopL} {
  		padding: 15px;
		margin-right: 15px;
		flex: 2;
	};
`;
export const OrderBookHeaderWrapper = styled.div`
	${FlexColumnBase};
	${TextSmall};
`;
export const OrderBookHeaderRow = styled.div`
	${FlexRowBase};
	align-self: flex-end;
`;
export const TradingPhase = styled.p`
	color: ${COLORS.lightBlack};
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.2px;
`;

// Entry Section
export const OrderEntryWrapper = styled.div`
	${FlexColumnBase};
	overflow: auto;
	justify-content: space-between;
	flex: 1;
	padding: 30px 15px 30px 30px;
	${TextBaseSmall};
	width: 100%;	
	.simplebar-content {
		height: 100%;
	}
	@media ${DEVICE.laptopL} {
		padding: 15px 0 15px 15px;
		${BaseDivider} {
			min-height: 5px;
		}
		${SmallDivider} {
			min-height: 5px;
		}
	};
`;

export const OrderEntryContent = styled.div`
	${FlexColumnBase};
	height: 100%;
	flex: 1;
	justify-content: space-between;
`;

export const HalfWidthWrapper = styled.div`
	width: 49%;
	height: 36px;
`;
export const CountersWrapper = styled.div`
	${FlexRowBase};
	margin-top: 20px;
	width: 100%;
	@media ${DEVICE.laptopL} {
		margin-top: 0px;
	};
	
`;
export const OrderBookContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	flex: 1;
`;
