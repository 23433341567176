import React from 'react';
import PropTypes from 'prop-types';

// Lib
import { useAuth } from '../../Context/auth';

// Atoms
import { BaseDivider } from '../../Atoms/Divider';
import { AccountInfoDropdownWrapper, HeaderLink } from './Atoms';

const DropdownMenu = ({ isOpen }) => {
	const { logOut } = useAuth();
	return (
		<AccountInfoDropdownWrapper open={isOpen}>
			<HeaderLink to="/app/subscription" href="/app/subscription">
				Subscription
			</HeaderLink>
			<BaseDivider />
			<HeaderLink
				to="#"
				href="#"
				onClick={logOut}
			>
				Logout
			</HeaderLink>
		</AccountInfoDropdownWrapper>
	);
};

DropdownMenu.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};

export default DropdownMenu;
