import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 60;
	background: ${props => (props.transparent ? 'transparent' : '#fff')};
	opacity: ${props => (props.loader === 'main' ? '1' : '0.7')};
`;

const basicLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const BasicLoader = styled.div`
	display: inline-block;
	width: 80px;
	height: 80px;
	
	&:after {
		content: " ";
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid #000;
		border-color: #000 transparent #000 transparent;
		animation: ${basicLoader} 1.2s linear infinite ;
	}
`;

const Loader = (props) => {
	const {
		loading,
	} = props;

	if (!loading) {
		return <></>;
	}

	return (
		<Wrapper transparent>
			<BasicLoader />
		</Wrapper>
	);
};

Loader.defaultProps = {
	theme: 'white',
	loader: '',
	loading: false,
};

Loader.propTypes = {
	loading: PropTypes.bool,
	theme: PropTypes.string,
	loader: PropTypes.string,
};

export default Loader;
