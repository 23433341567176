import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { toggleExchangeDictionariesModal } from '../../../../data/ui/UIActions';

// Constants and data
import { MODAL_SIZES, MODALS } from '../../../../lib/constants/General';

// Molecules
import Modal from '../../../Molecules/Modal';

// Organisms
import ExchangeDictionaryFieldModal from './ExchangeDictionaryFieldModal';
import ExchangeDictionaryModal from './ExchangeDictionaryModal';

const ExchangeDictionary = ({ modalInfo, actions }) => {
	const [modalData, setModalData] = useState(null);
	const [modalTitle, setModalTitle] = useState(modalInfo.data.title);

	useEffect(() => {
		setModalData(modalInfo.data.data);
		setModalTitle(modalInfo.data.title);
		return () => {
			setModalData(null);
		};
	}, [modalInfo.data.data, modalInfo.data.title]);

	const openReferencedItem = (name) => {
		setModalData(modalInfo.data.getReferenceData(name).data);
		setModalTitle(modalInfo.data.getReferenceData(name).title);
	};

	const closeModal = () => {
		actions.toggleExchangeDictionariesModal(false);
	};

	const renderModal = () => {
		if (modalTitle === 'Field') {
			return <ExchangeDictionaryFieldModal data={modalData} openReferencedItem={openReferencedItem} />;
		}
		return <ExchangeDictionaryModal data={modalData} title={modalTitle} openReferencedItem={openReferencedItem} />;
	};

	if (modalInfo.visible !== true || modalData === null) {
		return null;
	}

	return (
		<Modal
			showModal={modalInfo.visible}
			closeModal={closeModal}
			size={MODAL_SIZES.BIG}
		>
			{renderModal()}
		</Modal>
	);
};

ExchangeDictionary.propTypes = {
	modalInfo: PropTypes.shape({
		title: PropTypes.string,
		data: PropTypes.shape({
			getReferenceData: PropTypes.func,
			title: PropTypes.string,
			fieldModal: PropTypes.bool,
			messageModal: PropTypes.bool,
			data: PropTypes.oneOfType([
				PropTypes.arrayOf(PropTypes.shape({
					content: PropTypes.shape({}),
					length: PropTypes.func,
				})),
				PropTypes.shape({
					content: PropTypes.shape({}),
					length: PropTypes.func,
					title: PropTypes.string,
				}),
			]),
		}).isRequired,
		visible: PropTypes.bool,
	}).isRequired,
	actions: PropTypes.shape({
		toggleExchangeDictionariesModal: PropTypes.func,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		modalInfo: state.getIn(['ui', 'modal', MODALS.EXCHANGE_DICTIONARY_MODAL]).toJS(),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			toggleExchangeDictionariesModal,
		}, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeDictionary);
