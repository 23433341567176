import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
// Dummy Data
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { tradesHeader } from '../../../data/placeholderData/orderBlotterTableData';
// Styles
import { BaseBlockStyle } from '../../Css/Css';
// Organisms
import TableView from '../Table/TableView';
import { SortableTableCSS } from '../../Css/Table';
import { getActiveExchange } from '../../../data/exchanges/selectors';
import { formatTradeTimeStamp } from '../../../lib/helpers';
import usePrepareDataForRender from '../../Hooks/usePrepareDataForRender';
import useSortBy from '../../Hooks/useSortBy';
import { ORDER_SIDE } from '../../../lib/constants/Exchange';
import { NoContentInfo } from '../../Atoms/Text';

const TradeTableWrapper = styled.div`
	${SortableTableCSS};
	${BaseBlockStyle};
	height: 100%;
	overflow: hidden;
	padding: 27px 15px 10px 27px;
  	box-shadow: 0px 2px 7.8px 0.2px rgba(0, 0, 0, 0.03);
  	position: relative;
  	z-index: 11;
  	flex: 1;
`;

function TradeTable() {
	const [tableHeader, setTableHeader] = useState([...tradesHeader]);
	const exchange = useSelector(getActiveExchange);
	const data = exchange?.get('blotterTradesData');
	const code = data?.get('instrumentCode');
	const id = data?.get('orderId');
	const trades = exchange.getIn(['blotters', code])?.find(e => e.get('orderId') === id);
	const handleHeaderVisibilityChange = useCallback((header) => {
		setTableHeader((prevState) => {
			const newState = [...prevState];
			const index = newState.findIndex(item => item.name === header);
			newState[index].visible = !newState[index].visible;
			return newState;
		});
	}, []);

	const getDataToRender = useCallback(r => r?.map((row) => {
		let orderId = '';
		let avgPrice = '';
		let leavesQty = '';
		let cumQty = '';
		if (trades.get('side') === ORDER_SIDE.BUY) {
			orderId = row.get('sellOrder').get('orderId');
			avgPrice = row.get('buyOrder').get('avgPrice');
			leavesQty = row.get('buyOrder').get('quantity');
			cumQty = parseFloat(row.get('buyOrder').get('totalQuantity').toString().replace(/,/g, '')) - parseFloat(row.get('buyOrder').get('quantity').toString().replace(/,/g, ''));
		} else if (trades.get('side') === ORDER_SIDE.SELL) {
			orderId = row.get('buyOrder').get('orderId');
			avgPrice = row.get('sellOrder').get('avgPrice');
			leavesQty = row.get('sellOrder').get('quantity');
			cumQty = parseFloat(row.get('sellOrder').get('totalQuantity').toString().replace(/,/g, '')) - parseFloat(row.get('sellOrder').get('quantity').toString().replace(/,/g, ''));
		}
		return ({
			data: [
				'Trade',
				row?.get('tradeQuantity') || '',
				cumQty,
				leavesQty,
				row?.get('tradePrice') || '',
				avgPrice,
				row?.get('tradeId') || '',
				orderId,
				formatTradeTimeStamp(row.get('tradeTimestamp').get('seconds'), row.get('tradeTimestamp').get('nanos')) || '',
			],
			metaData: {
				key: row.get('tradeId'),
			},
		});
	}), [trades]);
	//
	const { dataToRender, setDataToRender } = usePrepareDataForRender(getDataToRender, trades?.get('trades'));
	const { handleSort, sortedBy } = useSortBy(dataToRender, setDataToRender, getDataToRender, tableHeader, trades?.get('trades'));

	return (
		<TradeTableWrapper className="order-blotter-trades-table">
			<TableView
				sortedBy={sortedBy}
				handleHeaderVisibilityChange={handleHeaderVisibilityChange}
				handleSort={handleSort}
				header={tableHeader}
				rows={dataToRender}
				selectable={false}
				hasSettings
				headerHeight="25px"
				rowHeight="32px"
			/>
			{typeof data === 'undefined' && <NoContentInfo absolute>Please select an order</NoContentInfo>}
			{dataToRender?.size === 0 && <NoContentInfo absolute>This order does not have any trades yet</NoContentInfo>}
		</TradeTableWrapper>
	);
}

export default withRouter(TradeTable);
