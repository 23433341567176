import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Organisms
import OrderContextMenu from '../../OrderBook/OrderContextMenu';

// Hooks
import useContextMenu from '../../../Hooks/useContextMenu';
import { formatTradeTimeStamp } from '../../../../lib/helpers';
import {
	Bid, BidSize, Timestamp, RowWrapper,
} from './MarketDepthTable.Atoms';

const MarketTableRow = ({
	index, row, maxBidSize, orderSide,
}) => {
	const ref = useRef();
	const {
		xPos, yPos, menu,
	} = useContextMenu(ref);

	if (!row.get('instrumentCode')) {
		return null;
	}

	return (
		<RowWrapper key={index.toString()} ref={ref}>
			<Timestamp
				isFirst={index === 0}
				bidSide={orderSide}
			>
				{formatTradeTimeStamp(row?.get('receivedDateTime')?.get('seconds'), row?.get('receivedDateTime')?.get('nanos'))}
			</Timestamp>
			<BidSize
				isFirst={index === 0}
				bidSide={orderSide}
				totalSize={maxBidSize}
				size={Number(row.get('quantity').replace(',', ''))}
			>
				{row.get('quantity')}
			</BidSize>
			<Bid
				isFirst={index === 0}
				bidSide={orderSide}
				member={row.get('memberId')}
			>
				{row.get('price')}
			</Bid>
			{menu && (
				<OrderContextMenu
					top={yPos}
					left={xPos}
					order={row}
				/>
			)}
		</RowWrapper>
	);
};

MarketTableRow.propTypes = {
	index: PropTypes.number.isRequired,
	row: PropTypes.shape({
		get: PropTypes.func,
	}).isRequired,
	maxBidSize: PropTypes.number.isRequired,
	orderSide: PropTypes.string.isRequired,
};

export default MarketTableRow;
