import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Atoms
import { LargeDivider, SmallDivider } from '../../../Atoms/Divider';
import {
	SiteMenuLink, MobileMenuWrapper, BarItemBottom, BarItemMiddle, BarItemTop, MenuWrapper, LinkWrapper,
	HamburgerFooterWrapper, HamburgerHeaderWrapper, FooterLink, OpenedHamburgerMenuWrapper,
} from '../Atoms';

// Molecules
import HeaderRightSide from '../HeaderRightSide';

const HamburgerMenu = (props) => {
	const { user } = props;
	const [showMenu, setShowMenu] = useState(false);

	const handleHamburgerMenu = () => {
		setShowMenu(!showMenu);
	};

	return (
		<>
			<MobileMenuWrapper onClick={handleHamburgerMenu}>
				<BarItemTop opened={showMenu} />
				<BarItemMiddle opened={showMenu} />
				<BarItemBottom opened={showMenu} />
			</MobileMenuWrapper>
			{showMenu && (
				<>
					<MenuWrapper>
						<HamburgerHeaderWrapper>
							<HeaderRightSide
								user={user}
								onClick={handleHamburgerMenu}
								reverse
							/>
							<LargeDivider />
							<LinkWrapper>
								<SiteMenuLink
									bold="true"
									onClick={handleHamburgerMenu}
									to="/plans"
								>
									Plans
								</SiteMenuLink>
								<SmallDivider double />
								<SiteMenuLink
									bold="true"
									onClick={handleHamburgerMenu}
									to="/about"
								>
									About
								</SiteMenuLink>
								<SmallDivider double />
								<SiteMenuLink
									bold="true"
									onClick={handleHamburgerMenu}
									to="/faq"
								>
									FAQ
								</SiteMenuLink>
								<SmallDivider double />
								<SiteMenuLink
									bold="true"
									onClick={handleHamburgerMenu}
									to="/contact"
								>
									Contact
								</SiteMenuLink>
							</LinkWrapper>
							<SmallDivider double />
						</HamburgerHeaderWrapper>
						<HamburgerFooterWrapper>
							<FooterLink href="https://www.esprow.com/" target="_blank" rel="noopener noreferrer">
								Esprow ETP
							</FooterLink>
							<SmallDivider double />
							<FooterLink href="https://www.esprow.com/products/exchange-simulation/etp-gems-mifid2.php" target="_blank" rel="noopener noreferrer">
								Global Exchanges & Markets Simulation Server
							</FooterLink>
							<SmallDivider double />
							<FooterLink href="https://www.esprow.com/about.php" target="_blank" rel="noopener noreferrer">
								About Esprow ETP
							</FooterLink>
							<SmallDivider double />
							<FooterLink href="mailto:support@esprow.com" rel="noopener noreferrer">
								Report a problem
							</FooterLink>
						</HamburgerFooterWrapper>
					</MenuWrapper>
					<OpenedHamburgerMenuWrapper onClick={handleHamburgerMenu} />
				</>
			)}
		</>
	);
};

HamburgerMenu.defaultProps = {
	user: null,
};


HamburgerMenu.propTypes = {
	user: PropTypes.shape({}),
};

export default HamburgerMenu;
