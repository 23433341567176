import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Actions
import { toggleAddExchangeModal } from '../../data/ui/UIActions';
import {
	fetchGeneralPrices, fetchPricesForActiveAddons, calculatePrices, resetBillingItems,
} from '../../data/billing/BillingActions';
import { fetchExchangeTypeNames } from '../../data/exchanges/ExchangesActions';

// Atoms
import {
	BaseVerticalDivider,
} from '../../components/Atoms/Divider';
import { FlexRowBase } from '../../components/Atoms/Flex';
import { PrimaryButton } from '../../components/Atoms/Button';

// Molecules
import DocumentTitle from '../../components/Molecules/DocumentTitle';

// Organisms
import PageWrapper from '../../components/Organisms/Layout/App/PageWrapper';
import Billing from '../../components/Organisms/Billing/Billing';
import AddExchangeModal from '../../components/Organisms/Modals/BillingModals/AddExchangeModal';
import BillingValidationModal from '../../components/Organisms/Modals/BillingModals/BillingValidationModal';
import { AppTourContext } from '../../components/Context/AppTourContext';


// Page specific
const ProfileActionWrapper = styled.div`
	${FlexRowBase};
	align-self: flex-end;
`;

class Subscription extends PureComponent {
	async componentDidMount() {
		this.timeoutId = setTimeout(() => this.context.setCanStart('subscription', true), 1200);
		const {
			actions,
		} = this.props;
		actions.fetchExchangeTypeNames();
		actions.fetchGeneralPrices();
		await actions.fetchPricesForActiveAddons();
		actions.calculatePrices();
	}

	componentWillUnmount() {
		this.context.setCanStart('subscription', false);
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
	}

	async handleDiscardChanges() {
		const {
			actions,
		} = this.props;
		await actions.resetBillingItems();
		await actions.fetchPricesForActiveAddons();
		actions.calculatePrices();
	}

	renderProfileActions() {
		const {
			actions,
			hasNewAddons,
		} = this.props;
		return (
			<ProfileActionWrapper>
				<DocumentTitle title="Subscription" />
				<PrimaryButton
					onClick={() => this.handleDiscardChanges()}
					disabled={!hasNewAddons}
					className="subscription-discard-all"
				>
					Discard All Changes
				</PrimaryButton>
				<BaseVerticalDivider />
				<PrimaryButton
					className="subscription-add-exchange-button"
					onClick={() => actions.toggleAddExchangeModal()}
				>
					Add Exchange
				</PrimaryButton>
			</ProfileActionWrapper>
		);
	}

	render() {
		return (
			<PageWrapper
				breadcrumbs={[
					{
						link: 'subscription',
						name: 'Subscription',
					},
				]}
				title="Subscription"
				pageWrapperRightSide={this.renderProfileActions()}
			>
				<Billing />
				<AddExchangeModal />
				<BillingValidationModal />
			</PageWrapper>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		toggleAddExchangeModal,
		fetchPricesForActiveAddons,
		fetchGeneralPrices,
		calculatePrices,
		resetBillingItems,
		fetchExchangeTypeNames,
	}, dispatch),
});

function mapStateToProps(state) {
	return {
		hasNewAddons: state.getIn(['billing', 'hasNewAddons']),
	};
}
Subscription.contextType = AppTourContext;
export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
