import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../lib/constants/Style';
import { TextSmall } from '../Css/Css';
import { EXCHANGE_STATUS } from '../../lib/constants/Exchange';

export default function ExchangeStatus(props) {
	const {
		status,
	} = props;
	switch (status) {
	case EXCHANGE_STATUS.STARTED: {
		return <GreenStatus>{status}</GreenStatus>;
	}
	case EXCHANGE_STATUS.OPENED: {
		return <BlueStatus>{status}</BlueStatus>;
	}
	case EXCHANGE_STATUS.OPENING:
	case EXCHANGE_STATUS.CLOSING:
	case EXCHANGE_STATUS.STARTING:
	case EXCHANGE_STATUS.STOPPING:
	case EXCHANGE_STATUS.CLOSED:
	{
		return <YellowStatus>{status}</YellowStatus>;
	}
	case EXCHANGE_STATUS.STOPPED:
	case EXCHANGE_STATUS.ERROR:
	{
		return <RedStatus>{status}</RedStatus>;
	}
	default: return <GrayStatus>N/A</GrayStatus>;
	}
}
ExchangeStatus.propTypes = {
	status: PropTypes.string,
};
ExchangeStatus.defaultProps = {
	status: EXCHANGE_STATUS.NA,
};
const BaseStatus = styled.div`
	padding: 7px 20px;	
	width: 83px;
  	border-radius: 3px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	${TextSmall};
  	font-weight: 600;
  	line-height: 1;
  	letter-spacing: 0.6px;
`;
const GreenStatus = styled(BaseStatus)`
	background-color: #def7ef;
  	color: #1ec992;
`;
const YellowStatus = styled(BaseStatus)`
	border: solid 1px ${COLORS.lightYellow};
	background-color: ${COLORS.lightestYellow};
	color: ${COLORS.yellow};
`;
const RedStatus = styled(BaseStatus)`
	border: solid 1px ${COLORS.red};
	background-color: ${COLORS.lightRed};
	color: ${COLORS.red};
`;
const BlueStatus = styled(BaseStatus)`
	background-color: ${COLORS.lightBlue};
	color: ${COLORS.blue};
`;
const GrayStatus = styled(BaseStatus)`
	color: #a2adb7;
	background-color: #f5f4f4;
`;
