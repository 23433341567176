import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

// Atoms
import { ContactGreyBackgroundWrapper, ContactMaxWidthWrapper } from '../../../components/Atoms/WebSite/Wrappers';

// Molecules
import ContactInfo from '../../../components/Molecules/WebSite/Contact/ContactInfo';
import ContactForm from '../../../components/Molecules/WebSite/Contact/ContactForm';
import ContactFormModal from '../../../components/Organisms/Modals/ContactFormModal';
import { toggleContactFormModal } from '../../../data/ui/UIActions';

const Contact = () => {
	const [selected, setSelected] = useState(0);
	const emailRef = useRef(null);
	const nameRef = useRef(null);
	const messageRef = useRef(null);
	const [checkBoxValue, setCheckBoxValue] = useState(false);
	const dispatch = useDispatch();

	const validateForm = () => {
		emailRef.current.hasError();
		nameRef.current.hasError();
		messageRef.current.hasError();
		if (emailRef.current.hasError() || nameRef.current.hasError() || messageRef.current.hasError() || !checkBoxValue) {
			return false;
		}
		return true;
	};

	const resetForm = () => {
		emailRef.current.resetState();
		nameRef.current.resetState();
		messageRef.current.resetState();
		setCheckBoxValue(false);
	};

	const handleSubmit = () => {
		if (validateForm()) {
			const data = {
				email: emailRef.current.getValue(),
				name: nameRef.current.getValue(),
				message: messageRef.current.getValue(),
			};
			dispatch(toggleContactFormModal(true));
			resetForm();
		}
		console.log('error');
	};

	return (
		<ContactGreyBackgroundWrapper>
			<ContactMaxWidthWrapper>
				<ContactInfo
					selectedTab={selected}
					selectTab={setSelected}
				/>
				<ContactForm
					emailRef={emailRef}
					nameRef={nameRef}
					messageRef={messageRef}
					checkBoxValue={checkBoxValue}
					setCheckBoxValue={setCheckBoxValue}
					handleSubmit={handleSubmit}
				/>
			</ContactMaxWidthWrapper>
			<ContactFormModal />
		</ContactGreyBackgroundWrapper>
	);
};

export default Contact;
