import React, { PureComponent } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

import {
	withRouter,
} from 'react-router-dom';

class ScrollToTop extends PureComponent {
	componentDidUpdate(prevProps) {
		const {
			location,
		} = this.props;

		if (location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		const {
			children,
		} = this.props;

		return children;
	}
}

ScrollToTop.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}).isRequired,
	children: PropTypes.node.isRequired,
};

export default withRouter(ScrollToTop);
