import React from 'react';
import styled from 'styled-components';
import { BoldText } from '../../../components/Atoms/WebSite/Text';
import { SmallDivider, SmallVerticalDivider } from '../../../components/Atoms/Divider';
import { TextBase } from '../../../components/Css/Css';

export const Wrapper = styled.div`
	padding: 30px 150px;
	p {
		text-align: justify;
	}
	a {
		color: blue;
	}
	* {
		${TextBase};
	}
`;
export const FlexRow = styled.div`
	display: flex;
`;
export const IndentedRow = styled.p`
	${props => props.double && 'margin-left: 40px;'}
`;
const TermsOfService = () => (
	<Wrapper>
		<BoldText>ETP GEMS TERMS OF SERVICE</BoldText>
		<SmallDivider />
		<div>
			<BoldText>Last modified: </BoldText>
			<span>09 July 2019</span>
		</div>
		<SmallDivider />
		<p>
			Please take the time to read and understand these Terms of Service ("
			<BoldText>Terms</BoldText>
			") carefully as they apply to your access to and use of the Website and Service.
		</p>
		<SmallDivider />
		<p>
			By accessing, visiting, browsing and/or using the Website and/or the Service, you ("
			<BoldText>you</BoldText>
			") are entering into a binding contract with us, and acknowledging that you have read, understood, accepted
			and agreed to be bound by these Terms and any additional terms and conditions referenced herein, including
			our Privacy Policy.
		</p>
		<SmallDivider />
		<p>
			You may access and/or use the Service as an individual (sole proprietor) or as a company or other business
			entity. If you are accessing and/or using the Service on behalf of your company or business ("
			<BoldText>Company</BoldText>
			"), you are agreeing to these Terms for your Company and personally guaranteeing and representing to us that
			you have the legal authority to act on behalf of the Company and to bind the Company to these Terms, in
			which case "you" shall mean you, the natural person and End-User, as well as the Company you represent and "
			<BoldText>your</BoldText>
			" and "
			<BoldText>yours</BoldText>
			" shall have corresponding meanings.
		</p>
		<SmallDivider />
		<p>
			We may modify or revise these Terms at any time in the exercise of our sole discretion by posting a revised
			version of these Terms on this page. Material changes to these Terms (such as to the Fees) will become
			effective no sooner than fourteen (14) days after notice of such change is posted on the Website.
			Non-material changes and changes made for legal reasons or for compliance with laws will become effective
			immediately. You can track all changes to these Terms on this page and in our archives. You agree that you
			are responsible for periodically reviewing these Terms for any changes and keeping yourself informed of the
			most up-to-date version of these Terms. Your access to and/or use of the Website and/or Service thereafter
			following any revision to these Terms shall constitute your acceptance of the revised Terms. If at any time
			you do not agree with these Terms (or any revised versions thereof), you should immediately stop accessing
			and using the Website and Service and cancel your Subscription in accordance with
			{' '}
			<BoldText>Clause 3.5</BoldText>
			{' '}
			below.
		</p>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>1.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>Definitions</BoldText></span>
			</StyledBulletSection>

		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<div><BoldText>1.1</BoldText></div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					In these Terms, the following terms shall have the following respective meanings (unless the context
					otherwise requires):
				</p>
				<p>
					<BoldText>"Account"</BoldText>
					{' '}
					means a Company Account and/or End-User Account as the context requires.
				</p>
				<p>
					<BoldText>"API"</BoldText>
					{' '}
					means our application programming interface, which is to be used as an interface between the
					Service and your applications, and includes the documents (in whatever media) that accompany such
					application programming interface and API Data.

				</p>
				<p>
					<BoldText>"API Data"</BoldText>
					{' '}
					means all content, images, photographs, illustrations, icons, text, video, audio, written
					materials, software or other content, materials or data that you access or otherwise use on the
					Service as part of your use of the API.
				</p>
				<p>
					<BoldText>"include"</BoldText>
					(and its derivatives, such as
					{' '}
					<BoldText>"including"</BoldText>
					{' '}
					"
					<BoldText>"includes"</BoldText>
					") shall mean to include without
					limitation.
				</p>
				<p>
					<BoldText>"Privacy Policy"</BoldText>
					means the privacy policy at https://qa.etpmarkets.com/privacypolicy.html.
				</p>
				<p>
					<BoldText>"Service"</BoldText>
					means the ETP GEMS Global Exchanges and Markets Simulation Platform we make available at
					the Website, including all upgrades, updates and enhancements thereto, and such content, products,
					services, features, functionalities and API as we may offer in connection therewith from time to
					time.
				</p>
				<p>
					<BoldText>"Service Content"</BoldText>
					means the information, materials and content including user guides, manuals and
					any other types of content which we may make available to you under the Service.
				</p>
				<p>
					<BoldText>"Subscription Plans"</BoldText>
					means the different types of subscription plans to the Service which we may
					offer from time to time, the pricing and details of which are specified at
					https://qa.etpmarkets.com.
				</p>
				<p>
					<BoldText>"us," "we," "our", "Esprow"</BoldText>
					means Esprow Pte. Ltd., a company duly incorporated in Singapore with
					its registered address at 55 Mohamed Sultan Road, #01-04 Sultan Link, 238995, Singapore.
				</p>
				<p>
					<BoldText>"Website"</BoldText>
					means the website at and includes any other website, web page, subpage, sub-domain and/or
					its successor page(s) as may from time to time be owned by us or under our control, partial or
					otherwise.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>2.</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>Your Account</BoldText></span>
			</StyledBulletSection>

		</FlexRow>
		<FlexRow>
			<div>2.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Signing up for an Account (General).</BoldText>
					{' '}
					You may browse the Website to a limited
					extent without registering for an Account. However, in order to access and/or use the Service, you
					will have to register for an Account with us in the manner specified herein. All Accounts are
					non-transferable. We reserve the right, in the exercise of our sole discretion, to refuse your
					application for an Account and to change the eligibility criteria therefor at any time, for any
					reason and without prior notice to you.
				</p>
			</StyledBulletSection>

		</FlexRow>
		<FlexRow>
			<div>2.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Minimum Age.</BoldText>
					{' '}
					The Service is only intended for, available to, and may only be
					used by individuals who are eighteen (18) years and older and who can form legally binding contracts
					under applicable law ("Minimum Age"). In signing up for an Account, you represent and warrant to us
					that you (and, where you are the Company, your End-Users) are of the Minimum Age or older, and have
					the full right, power, and authority to agree to these Terms.
				</p>
			</StyledBulletSection>

		</FlexRow>
		<FlexRow>
			<div>2.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Company Account.</BoldText>
					{' '}
					In order for you, the Company, to start accessing and using
					the Service, you must register for an account under your registered corporate name (
					<BoldText>
						"Company
						Account"
					</BoldText>
					). Once your Company Account has been successfully registered, you may send email
					invitations to one or more individuals (including an employee, agent or service provider) whom you
					designate and authorize to access and/or use the Service on your behalf (each
					an
					<BoldText>"End-User"</BoldText>
					) under
					an Account (
					<BoldText>"End-User Account"</BoldText>
					) linked to your Company Account. We may allow
					you to designate
					different levels of access and permissions for each End-User. The number of individuals accessing
					and using the Service on your behalf must not exceed the number of End-User Accounts we have issued
					for your Company Account, which will vary depending on the Subscription Plan you select for
					purchase. You shall be solely responsible for your End-Users' access to and use of the Service, and
					shall procure and ensure their agreement to and compliance with these Terms. You agree to be bound
					by all the actions of your End-Users (as if they were your actions) and all activity under your
					Company Account (and linked End-User Accounts) in respect of the access to and/or use of the Service
					(whether or not these were in fact done by or authorized by you). References to
					<BoldText>
						"your
						Account"
					</BoldText>
					{' '}
					under
					these Terms shall, where you are the Company (and the context permits), refer to your Company
					Account and all End-User Accounts linked thereto.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>2.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Account Owner.</BoldText>
					{' '}
					You, the Company, shall assign the role of "Account Owner" to
					one (1) of your End-Users. Each Company Account shall have only one (1) Account Owner. The Account
					Owner will have access to top level administrative features (including with respect to payment,
					security and the management of other End-User Accounts) that other End-Users will not have access
					to.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>2.5</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>End-User Account.</BoldText>
					{' '}
					In order for you, an End-User, to start accessing and using
					the Service, you must register for an End-User Account by accepting an invitation from your Company
					to do so, and by following our instructions. You acknowledge and agree that by registering for an
					End-User Account and using the Service, your Company, as well as other End-Users who are registered
					under your Company's Account, will be able to access, obtain, collect, store and/or use, information
					(including your personal data) which you submit, upload or make available on the Service. You
					acknowledge that the responsibility of limiting the disclosure of your information lies entirely
					with you.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>2.6</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Account Information.</BoldText>
					{' '}
					When you register for an Account, and in the course of
					accessing and/or using the Service, you may be asked to provide us with certain information
					(including, if you are an individual, your personal data). You represent that all the information
					you provide to us on registration or at any time is:
				</p>
				<p>
					(a) true, accurate, current and complete, and that you will promptly update such information on your
					Account to ensure that it remains so at all times; and
				</p>
				<p>
					(b) owned by you, and/or that you have the right to use and disclose the same, or allow us to do so
					in accordance with the Privacy Policy.
				</p>
				<p>
					You agree that we may collect, use and process personal data in accordance with our Privacy
					Policy.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>2.7</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Login Codes.</BoldText>
					{' '}
					You will be prompted to use your unique email login and create a unique password ("Login Codes")
					when you register for an Account. You shall keep your Login Codes confidential and must not disclose
					them to anyone else or allow anyone else to access and/or use the Service using your Login Codes.
					Each set of Login Codes may only be used by one (1) person i.e., a single set of Login Codes may not
					be shared by multiple persons. Any access to and/or use of the Service and any acts, information,
					data, instructions, transactions or communications referable to your Login Codes and/or your Account
					shall be deemed to be access to and/or use of the Service by you, or acts, information, data,
					instructions, transactions or communications emanating from you, whether or not they were in fact
					made or authorized by you. You agree that you shall be solely and fully liable for any disclosure
					and/or unauthorized use of your Login Codes.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>2.8</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Security.</BoldText>
					{' '}
					You are responsible for maintaining the security of your Account and Login Codes. We may offer tools
					such as two-factor authentication to help you maintain your Account's security, but the content of
					your Account and its security are your sole responsibility. You will promptly notify us in writing
					if you become aware of any unauthorized access to and/or use of the Service through your Account,
					including any unauthorized use of your Login Codes, or if the security of your Account or Login
					Codes is compromised in any way.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>3</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>Your Subscription</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>3.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Subscription plans.</BoldText>
					You agree that depending on the Subscription Plan you select for purchase, the services, privileges,
					content and features made available to you on the Service, the subscription fees for the use of the
					same ("Subscription Fees") and the subscription period ("Subscription Period") will differ. The
					Subscription Plan you select will also determine how many End-User Accounts you are entitled to
					under your Company Account, and the level or type of settings you can assign to the applicable
					End-Users. To view specific details of the Subscription Plan you purchased ("Subscription") and the
					end date of your Subscription Period, please refer to your Account. Additional policies, guidelines,
					terms and conditions may apply depending on which Subscription Plan you select, and all such terms
					shall be deemed to be incorporated in these Terms. We reserve the right to make changes to any
					Subscription Plan, or to add a new or remove an existing Subscription Plan in the exercise of our
					sole discretion, without liability to you.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>3.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Trial Subscriptions.</BoldText>
					We may from time to time at our sole discretion offer trial subscriptions to the Service. If you
					register for a trial of the Service ("
					<BoldText>Trial</BoldText>
					"), we will make the Service (or
					parts thereof) available
					to you on a trial basis either free of charge or at a reduced rate for a limited period of time
					specified by us ("
					<BoldText>Trial Period</BoldText>
					"), if not earlier terminated at our sole
					discretion. Trials may be
					subject to additional terms and conditions. We reserve the right in our sole discretion, to (i)
					determine your eligibility for a Trial, (ii) refuse or reject your registration for a Trial, (iii)
					modify the terms and conditions of a Trial, and/or (iv) terminate your Trial without prior notice to
					you. You will be required to enter your billing information in order to sign up for Trials even if
					they are offered free of charge. On the last day of the Trial Period, unless you have cancelled your
					Trial in accordance with Clause 3.5, you agree that you will be deemed to have purchased the
					Subscription Plan you have selected under the Trial, and we may automatically commence charging you
					Subscription Fees for the Subscription on a recurring basis.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>3.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Subscription Fees.</BoldText>
					Upon confirmation of your purchase of a Subscription Plan, you agree to pay Subscription Fees for
					the entire Subscription Period.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>3.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Auto Sign-up and Auto-Renewal.</BoldText>
					Upon the expiry of your current Subscription Period or Trial Period, your Subscription will
					automatically renew or commence respectively and you will be charged for it within twenty-four (24)
					hours prior to the end of the current Subscription Period or Trial Period (as applicable) or
					thereafter (unless you have cancelled your Subscription or Trial in accordance with
					{' '}
					<BoldText>
						Clause
						3.5
					</BoldText>
					).
					Subscription Fees may change upon the renewal of your Subscription or the commencement of your
					Subscription (upon the expiry of your Trial). We will notify you of any changes to the Subscription
					Fees in advance by posting the same on the Website. If you do not agree to the changes in the
					Subscription Fees, you must cancel your Subscription or Trial in accordance with
					{' '}
					<BoldText>
						Clause
						3.5
					</BoldText>
					.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>3.5</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Subscription/Trial Cancellation.</BoldText>
					You, the Company, may choose not to renew or commence your Subscription at any time by selecting the
					relevant option under your Company Account settings to cancel your Subscription or Trial or as
					otherwise instructed by following the instructions in our FAQ at
					{' '}
					<a
						href="https://qa.etpmarkets.com/faq"
					>
						https://qa.etpmarkets.com/faq
					</a>
					. We
					are not able to cancel Subscriptions or Trials in response to an email or phone request. It is your
					responsibility to properly cancel your Subscription or Trial in accordance with our instructions.
					You must do so at least twenty-four (24) hours before the end of the current Subscription Period or
					Trial Period (as applicable) to avoid being charged for the next Subscription Period. Any
					cancellation of a Subscription or Trial will take effect only at the end of the Subscription Period
					or Trial Period respectively. Once your Subscription or Trial is cancelled, you will no longer be
					able to access and use your Account(s) and/or the Service under the cancelled Subscription or Trial.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>3.6</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Upgrades, Downgrades and Changes.</BoldText>
					If you upgrade to a higher-level Subscription Plan, we will bill you for the upgraded Subscription
					Plan immediately. You may change your level of service or billing frequency at any time by selecting
					a different Subscription Plan under the 'Upgrade' option or adjusting the 'Billing' settings under
					your Account respectively. You acknowledge that if your Subscription is downgraded to a lower level
					Subscription Plan, you will immediately lose access to the Service Content, privileges, features,
					functionalities and/or capacity that were provided under your previous Subscription and any
					associated information, data and/or configuration settings in relation thereto (for which we have no
					responsibility to save, restore and back up) and you assume all risks relating to the same.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>4</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>FEES, BILLING & PAYMENT</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>4.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You agree to pay all fees imposed by us for the access to and/or use of the Service
					("
					<BoldText>Fees</BoldText>
					") in a timely manner, in accordance with the billing and payment terms
					on the Website.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>4.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Changes to Fees, Billing and Payment.</BoldText>
					{' '}
					We reserve the right to waive or vary the
					Fees (including the Subscription Fees) and the billing and payment terms from time to time, with
					respect to all users generally or only with respect to certain users, without liability to you.
					Where changes to the Fees will affect users generally, we shall use commercially reasonable efforts
					to give at least fourteen (14) days' notice of such changes by posting the same on the Website, save
					that no advance notice will be given with respect to temporary promotions where any Fees are reduced
					or waived temporarily.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>4.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Payment Gateway.</BoldText>
					The Service works with third party payment processor(s) and payment gateway service provider(s)
					("
					<BoldText>Payment Gateway</BoldText>
					") to enable online payments. You acknowledge that your use of
					the Payment Gateway is governed by their terms, conditions and privacy policies, such as may be
					introduced or amended by them from time to time. We are not liable for any loss or damage in
					relation to any transaction processed via the Payment Gateway (including any errant or invalid
					transactions, or unprocessed transactions due to network communication errors, or any other reason).
					You agree that we are not responsible for missed payments, fraud, unavailability or hacking of the
					Payment Gateway's systems or services or any other issues related to payments.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>4.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Billing Information.</BoldText>
					Fees will be billed and charged to you, the Company, based on your billing information and selected
					payment method on record with us or the Payment Gateway. We only accept payment methods which are
					accepted by the Payment Gateway (including MasterCard and Visa credit and debit cards), which are
					subject to change without prior notice to you. You shall provide us with accurate and complete
					billing information and provide a valid payment method. By submitting your billing information and
					indicating your payment method to us or the Payment Gateway through the Service, you are deemed to
					have authorized us to charge all Fees payable by you, whenever due, to your billing information and
					selected payment method, and you shall ensure that there are sufficient funds for such charges.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>4.5</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText> No Refunds.</BoldText>
					All Fees (including Subscription Fees) paid by you are non-refundable under any circumstances,
					including if your Subscription is terminated prior to the end of your Subscription Period such that
					part of your Subscription Period is unused, you downgrade your Subscription, we take any actions
					pursuant to
					{' '}
					<BoldText>Clause 13.1</BoldText>
					{' '}
					or there is any change in these Terms or the Service.
					We may however in the
					exercise of our sole discretion, consider requests for refunds on a case-by-case basis.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>4.6</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Unsuccessful Billing.</BoldText>
					If Subscription Fees for the upcoming Subscription Period are not paid before the end of the current
					Subscription Period or Trial Period as the case may be for whatever reason (including if your
					payment method is declined, your billing information is wrong, or if we or the Payment Gateway are
					unable to successfully charge your payment method), you may not be able to renew, commence or
					upgrade your Subscription, and we may downgrade, allow to lapse or cancel your Subscription and/or
					suspend or terminate your Account(s). You are responsible for all charges incurred up to the time of
					termination of your Account(s).
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>4.7</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Taxes.</BoldText>
					You are solely responsible for paying all applicable taxes associated with the access to and use of
					the Service under your Account(s). All payments made by you to us shall be free of all deductions,
					including any withholding taxes which shall be borne by you.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>5</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>OUR LICENCE TO YOU</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>5.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You acknowledge that rights in the Service are licensed (not sold) to you, and that you have no
					rights in or to the Service other than the right to use it in accordance with these Terms. You
					acknowledge that you have no right to access the Service or any part thereof in source-code form.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>5.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>License to Use.</BoldText>
					In consideration of you agreeing to abide by these Terms and the payment of applicable Subscription
					Fees, we hereby grant you and each of your End-Users a non-exclusive, non-transferable,
					non-sublicensable, revocable and limited license to access and use the Service during the
					Subscription Period, subject to these Terms and the terms of the Subscription Plan you purchased
					(the "
					<BoldText>License</BoldText>
					").


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>5.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Use Restrictions.</BoldText>
					You agree not to
				</p>
				<p>
					(a) copy, reproduce, modify, adapt, translate, publish, display, distribute, transfer, sell, lease,
					sub-license, transmit, make available or otherwise exploit or use for any commercial or other
					purposes, the Website and/or Service (or any content therein);
				</p>
				<p>
					(b) use the Website and/or Service to import or copy any local files you do not have the legal right
					to import or copy in this manner;
				</p>
				<p>
					(c) use the API in a way which contravenes our API Policy (if any), or which exceeds any limits that
					we may in our sole discretion impose on the number and frequency of API requests;
				</p>
				<p>
					(d) disassemble, decompile, reverse-engineer or create derivative works based on the whole or any
					part of the Service, or otherwise attempt to discover our source codes and algorithms, except to the
					extent that applicable law expressly permits despite this limitation;
				</p>
				<p>
					(e) do anything that would disrupt, alter, destroy, impair, interfere with, overburden or limit the
					functionality, operation or security of the Website and/or Service, or interfere with another user's
					use and enjoyment thereof, including hacking into the Website and/or Service, or transmitting any
					virus or malicious code or sending mass unsolicited messages or 'flooding' servers;
				</p>
				<p>
					(f) probe, scan or test or attempt to probe, scan or test the vulnerability of the Service or any
					system or network, or breach or circumvent any security or authentication measures or other
					protection measures applicable to the Website and/or Service (or any content therein);
				</p>
				<p>
					(g) use any linking, deep-linking, mirroring, framing, crawling, page-scraping technology or other
					automated means, including bots, robots, spiders, scripts or other similar processes or technology,
					to access, allow access to, copy, distribute, display, monitor or enable the mass download,
					collection or extraction of data from the Website and/or Service;
				</p>
				<p>
					(h) alter, remove or destroy any copyright, trade mark or other proprietary markings or notices on
					or
					provided through the Service or on any data files, information, applications, text, audio, image or
					other files or materials produced, generated or displayed in the Website and/or Service; and/or
				</p>
				<p>(i) engage in any fraudulent, unlawful or illegal activity.</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>6</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>THE SERVICE</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>6.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Service Content.</BoldText>
					Service Content. Under the Service, we may make available to you various types of Service Content
					which we believe to be useful to you.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>6.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>No Regulated Activity. </BoldText>
					We provide the Service for testing purposes only. The Service is not an actual exchange platform, or
					a financial advisory service. We are not and shall not be deemed to be an entity licensed, regulated
					or authorized (or required to be licensed, regulated or authorized) by the Monetary Authority of
					Singapore or any other regulatory authority anywhere in the world.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>6.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Service Suspension.</BoldText>
					We reserve the right to change, suspend, remove or disable access to or discontinue the Service or
					any feature, component or content thereof at any time, temporarily or permanently, without prior
					notice and liability to you. We do not guarantee that any specific feature, component and/or content
					will always be available on the Website and/or Service. Notwithstanding the foregoing, if you have
					paid Subscription Fees for Subscription Plans that we permanently discontinue prior to the end of
					your Subscription Period, we may refund you the Subscription Fees corresponding to the unused
					portion of your paid Subscription Period (i.e. from the effective date of the discontinuance of the
					applicable Subscription Plan to the end of your paid Subscription Period) on a pro-rated basis, on a
					case by case basis, and subject to such conditions as we may impose. We may credit the refunded
					amount through the same payment method which you have selected for the Service, or in such other
					manner as we may deem appropriate.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>6.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Service Availability.</BoldText>
					We are not obliged to provide any maintenance and support services (including maintenance releases,
					patches, fixes or extensions), or to provide any updates or upgrades to the Service. We may
					interrupt or suspend your access to the Service from time to time, for routine, scheduled or
					unplanned maintenance or downtime, backups, updates, upgrades or other reasons. You acknowledge and
					agree that such interruptions may result in temporary unavailability of our Service. We will attempt
					to notify you in advance of any scheduled downtime (including on the Website, by email or other
					communication channels) but you acknowledge that this may not be reasonably practicable for
					unscheduled downtime (including emergency maintenance) which are due to reasons beyond our control.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>6.5</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Service Updates.</BoldText>
					Service Updates. We may from time to time issue updates to the Website and/or Service. Depending on
					the update, you may not be able to use and/or access the Website, the Service, and/or your
					Account(s) until you have accepted any new or additional terms and conditions of use.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>7</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>EXCHANGE SIMULATORS</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>7.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Under the Service, we will make available to you various exchange simulators (collectively
					"
					<BoldText>
						Exchange
						Simulators
					</BoldText>
					") which will enable you to test your trading algorithms and exchange
					connectivity. Please
					refer to our Website for a detailed description of how the Exchange Simulators work.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>7.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					The Exchange Simulators are commercial professional tools intended to be used by trained
					professionals only, and are not a substitute for your professional judgment or independent testing.
					Due to the variety of potential applications for the Exchange Simulators, the Exchange Simulators
					have not been tested in all situations under which they may be used. We will not be liable in any
					manner whatsoever for any results, simulation or output obtained through use of the Exchange
					Simulators ("Output"), and the performance of the Exchange Simulators (including how they process
					your inputs and variables). You are responsible for the supervision, management, and control of the
					Exchange Simulators and any Output. You therefore agree to familiarize yourself with how the
					Exchange Simulators work, their limitations, and how to use them (and any Output) appropriately, and
					to determine the appropriate selection of the Exchange Simulators and other computer programs and
					materials to help achieve your intended results. You are also responsible for establishing the
					adequacy of independent procedures for testing the reliability, accuracy, completeness, and other
					characteristics of any Output, including all items designed with the assistance of the Exchange
					Simulators. You further acknowledge and agree that the Exchange Simulators form part of our total
					unique hardware and software environment to deliver specific functionality, and that the Exchange
					Simulators may not achieve the results you desire within our design, analysis, simulation,
					estimation, and/or testing constraints.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<div>7.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					While our Exchange Simulators are intended to be a close simulation of selected exchanges, we do not
					warrant that they are a complete or perfect simulation thereof including as regards exchange
					behavior, interface, functions, performance, connectivity, reactivity, environment, response and
					activity. You acknowledge that your actual trading activity on these exchanges and the use of your
					trading algorithms in respect thereof may produce results which are different from the use of our
					Exchange Simulators. You agree not to rely on the Exchange Simulators or any Output in isolation
					without conducting independent assessment and testing of your trading algorithms.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>7.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You agree that we have no duty or obligation to supplement, amend, update or revise any Service
					Content, Exchange Simulator and/or Output.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>8</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>THIRD PARTY APPLICATIONS</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>8.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You acknowledge and agree that some parts of the Service are provided or made available to you by
					third party applications, software, devices, systems, networks and services that connect, link to or
					are integrated with the Service (
					<BoldText>"Third Party Applications"</BoldText>
					) (including Digital
					Ocean, Auth0,
					Chargebee and Zendesk). Information may be collected from you through and/or provided to us by such
					Third-Party Applications and their operators, licensors and providers ("
					<BoldText>
						Third
						Party/Parties"
					</BoldText>
					).
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>8.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				Please be aware that while these Terms are our full agreement with you, the terms and conditions and
				policies of these Third Parties ("
				<BoldText>Third Party Terms</BoldText>
				") will govern your use of Third
				Party Applications,
				and apply to the collection, use, disclosure, retention and processing of information from you by such
				Third Parties.


			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>8.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					By accessing and/or using the Service, you acknowledge and agree to abide by the Third Party Terms,
					as they apply to your use of the Third Party Applications through the Service, and agree not to
					access and use the Service in a way that would cause us to be in breach of our contractual
					obligations to such Third Parties.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>8.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Digital Ocean. Without prejudice to the foregoing, you acknowledge that Exchange Simulators are
					deployed on virtual machines or 'Droplets' made available by Digital Ocean LLC, and you agree as our
					'sub-user' to abide by all their terms and conditions (available on their website at
					<a href="https://www.digitalocean.com/legal/ "> https://www.digitalocean.com/legal/ </a>
					{' '}
					as may be
					updated by Digital Ocean from time to time) governing
					access to and/or use thereof.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>9</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>Linked sites</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>9.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					We may provide links, plug-ins, widgets, integrations or other connections to third party websites,
					advertisements, applications and/or services ("
					<BoldText>Linked Sites</BoldText>
					"). When you click
					on Linked Sites, you
					may leave the Website and/or Service. We have no control over, and are not responsible for the
					services or content on or obtained through the Linked Sites or for any damage you may incur from
					using and/or accessing the Linked Sites (including any virus, spyware, malware, worms, errors or
					damaging material contained in the Linked Sites) or the availability, accuracy or reliability of any
					of the Linked Sites. The inclusion of an advertisement of, or link or accessibility to, a Linked
					Site, does not imply endorsement of the same by us.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>9.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You access and use all Linked Sites at your sole risk, and you hereby irrevocably waive any
					claim against us with respect to the Linked Sites. We do not guarantee any of the Linked Sites, and
					expressly disclaim all liabilities and responsibilities arising in relation to any Linked Sites.
					When you visit a Linked Site, the applicable operator's terms and conditions will apply, including
					its privacy policy. You will need to make your own independent judgment regarding your interaction
					with any Linked Sites, including with regard to the purchase and/or use of any products or services
					from them.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>10</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>INTELLECTUAL PROPERTY RIGHTS</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>10.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					The Website and Service are owned, operated and maintained by Esprow.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>10.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Unless otherwise indicated, all intellectual property rights (whether registered or not, including
					rights in inventions, patent rights, registered designs, trade marks, copyright and all rights of
					whatever nature in computer programs) in the Website and Service (including in their data, text,
					content, design, compilations, 'look', 'feel', 'appearance', 'graphical user interface', Exchange
					Simulator and Service Content) ("
					<BoldText>Intellectual Property Rights</BoldText>
					") belong to us
					and/or our licensors,
					and we reserve and retain all rights in the same.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>10.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You shall not copy, reproduce, modify, adapt, display, sell, or distribute our Intellectual Property
					Rights, or use our Intellectual Property Rights in any other way for any public or commercial
					purpose without our prior written consent. Unless explicitly stated herein, nothing in these Terms
					shall be construed as conferring to you in any manner, whether by implication, estoppel or
					otherwise, any title or ownership of, or exclusive use-rights to, any intellectual property or other
					rights and any goodwill associated therewith.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>10.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Printing, copying or downloading material from the Website and/or Service is strictly prohibited
					save that permission is granted to you to print hard copies of material for internal use only where
					a 'print' or download option or button for the same is made available by us. You agree not to change
					or delete any proprietary notices from materials printed or downloaded from the Website and/or
					Service. You acknowledge that we and/or third-party content providers remain the owners of such
					material and that you do not acquire any ownership rights in the material by printing or downloading
					it.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>10.5</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You assume full responsibility and risk of loss resulting from your printing, downloading and/or use
					of files, information, Service Content or other material obtained from the Website and/or Service.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>11</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>INDEMNITY</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>11.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You shall fully indemnify and hold us, our subsidiaries, related companies, officers, directors,
					employees, partners and agents ("
					<BoldText>Indemnitees</BoldText>
					") harmless from and against any
					and all damages,
					awards, expenses, losses, claims, actions, liabilities, penalties, costs and/or demands (including
					statutory liability and liability to third parties, economic loss, and court and legal costs
					assessed on a solicitor-client basis), suffered, incurred by or brought against any of the
					Indemnitees, due to or arising out of, whether directly or indirectly:

				</p>
				<p>
					(a) your (or your End-User's) act, omission, negligence, willful default, mistake, misconduct,
					dishonesty or fraud;
				</p>
				<p>(b) your (or your End-User's) breach of these Terms or any third-party terms;</p>
				<p>(c) any use of your Account referable to your (or your End-User's) Login Codes;</p>
				<p>
					(d) any breach, violation or infringement by you (or your End-User) of any applicable laws or any
					third-party rights (including rights in contract, intellectual property, confidential information
					and/or personal data);
				</p>
				<p>(e) any connection to, access and/or use of the Website and/or Service by you (or your End-User);</p>
				<p>
					(f) your (or your End-User's) instructions, and/or our acting in good faith and taking or refusing
					to
					take action based thereon; and/or
				</p>
				<p>(g) any claim by a third party against any Indemnitee in respect of any of the above.</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>12</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>DISCLAIMERS & LIMITATIONS OF LIABILITY</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>12.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					The Website and Service are provided to you 'as is', 'as available', without representation,
					warranty or condition of any kind, whether express, implied or statutory (including any implied
					warranty or condition of merchantability or satisfactory quality, fitness for a particular purpose,
					non-infringement, compliance with any description or any implied warranty arising from course of
					performance, course of dealing, usage of trade or otherwise), all of which are expressly disclaimed
					to the maximum extent permitted under applicable laws. Without limiting the foregoing and without
					prejudice to any other disclaimers under these Terms, we make no representation or warranty:
				</p>
				<p>
					(a) that the Website and/or Service will be uninterrupted, error-free or virus-free, or that bugs
					and
					defects therein will be corrected;
				</p>
				<p>
					(b) that use of the Service will be sufficient to enable you to comply with any applicable laws or
					regulatory requirements;
				</p>
				<p>
					(c) that use of the Service will consistently or conclusively identify any bugs, errors,
					deficiencies
					or other issues with your trading algorithms;
				</p>
				<p>
					(d) that the transmission of any information through the Service will be entirely private or
					secure;
				</p>
				<p>
					(e) as to the correctness, accuracy, completeness, reliability, safety, timeliness, quality,
					suitability or availability of the Website, Service, Exchange Simulators, Output or Service Content
					or any of our services, products, software, tools or information; and/or
				</p>
				<p>(f) relating to any third party or any other product or service provided by a third party.</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>12.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You acknowledge and agree that the entire risk arising out of your access to and/or use of the
					Website and Service remains solely with you, to the maximum extent permitted by law. You agree at
					all times while accessing and/or using the Website and/or Service to employ good judgement and
					exercise caution.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>12.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					By using the Service, you acknowledge and agree that internet transmissions are never completely
					private or secure, and that it is possible that the data on the Service can be mistakenly released,
					lost, hacked or accessed by unauthorized users. You further acknowledge that operation of and access
					to the Service and/or Website may be interrupted or interfered with as a result of technical errors
					or issues or other factors outside of our control.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>12.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Subject to
					{' '}
					<BoldText>Clause 12.6 </BoldText>
					{' '}
					and without prejudice to any other limitations of
					liability under these Terms, we shall not be liable (to you or any third party) for:
				</p>
				<p>
					(a) consequential, indirect, incidental, punitive, exemplary or special losses, whether foreseeable
					or not, and even if we are aware of or advised of the possibility of the same;
				</p>
				<p>
					(b) any of the following (whether direct or indirect, or foreseeable or not, and even if we are
					aware of or advised of the possibility of the same): loss of profit, loss of data, loss of use, loss
					of production, loss of contract, loss of opportunity, loss of savings, discount or rebate (whether
					actual or anticipated), harm to reputation and/or loss of goodwill;
				</p>
				<p>
					(c) any loss, damage, cost or expenses (whether direct or indirect, or foreseeable or not, and even
					if we are aware of or advised of the possibility of the same) resulting from or in connection with:
					<IndentedRow double>
						(i) the Service generally or the software or systems that make the
						Service available (including
						Third Party Applications) and for the design, development or administration thereof;
					</IndentedRow>

					<IndentedRow double>
						(ii) any error, omission, defect, deficiency, malfunction,
						unreliability, nonconformity, delay or
						disruption in or of the Service (including glitches, bugs or inaccuracies of any kind) or your
						inability to use the same;
					</IndentedRow>

					<IndentedRow double>
						(iii) any human or electronic error, including in the administration
						of simulations performed on the
						Service;
					</IndentedRow>

					<IndentedRow double>
						(iv) any use of and reliance on the Website, Service and/or Service Content by you or a third
						party;
					</IndentedRow>
					<IndentedRow double>
						(v) unauthorized, wrongful or fraudulent access to and/or use of your Account(s), Login Codes,
						User
						Data, or the disclosure of or alteration of your transmissions, as a result of the unauthorized
						acts
						of third parties or your failure to safeguard the security of your Login Codes, User Data or
						Account(s);
					</IndentedRow>
					<IndentedRow double>
						(vi) any modification, change in Fees, suspension or discontinuance of the Service or any other
						action taken by us with respect to your Account(s) or pursuant to these Terms;
					</IndentedRow>
					<IndentedRow double>
						(vii) inaccurate transcription of entry information;
					</IndentedRow>
					<IndentedRow double>
						(viii) entries that are stolen, misdirected, garbled, delayed by computer transmissions, lost or
						damaged;
					</IndentedRow>
					<IndentedRow double>
						(ix) technical, network or computer failures of any kind;
					</IndentedRow>
					<IndentedRow double>
						(x) errors in any promotional or marketing materials;
					</IndentedRow>
					<IndentedRow double>
						(xi) goods and services provided to you by a third party;
					</IndentedRow>
					<IndentedRow double>

						(xii) the error, act or omission, negligence, willful default, misconduct, fraud or breach of
						any
						user (including you) or any third party;
					</IndentedRow>
					<IndentedRow double>
						(xiii) the failure by any user (including you) to comply with applicable laws;
					</IndentedRow>
					<IndentedRow double>
						(xiv) third party claims against you; and/or
					</IndentedRow>
					<IndentedRow double>
						(xv) changes we make to these Terms.
					</IndentedRow>
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>12.5</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Subject to Clause 12.6</BoldText>
					, in the event that we are liable for damages despite the
					provisions in these Terms, you agree that our total liability (regardless of whether such liability
					arises in tort, contract or in any other way and whether or not caused by negligence or
					misrepresentation) shall not exceed:
				</p>
				<p>
					(a) an amount equal to the Subscription Fees paid by you to us in the twelve (12)-month period
					immediately preceding the first (1st) incident giving rise to the loss; or
				</p>
				<p>
					(b) for incidents occurring in the first twelve (12) months of your agreement to these Terms, an
					amount equal to the Subscription Fees paid by you for that period.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>12.6</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Nothing in these Terms shall exclude or limit our liability for any liability that cannot be
					excluded or limited by applicable laws.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>12.7</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You agree that the exclusions and limitations of liability under these Terms are fair and reasonable
					and that the Subscription Fees would be substantially higher in the absence of such exclusions and
					limitations.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>13</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText> SUSPENSION & TERMINATION</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>13.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>
						Termination by
						Us.
					</BoldText>
					{' '}
					We may in our sole discretion and without penalty or liability whatsoever,
					suspend, restrict, or terminate your Account(s) and/or Subscription and/or revoke your License for
					any reason and at any time, without prior notice to you.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>13.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Without prejudice to the generality of the above, where:
				</p>
				<p>(a) you have committed a breach of any provision of these Terms or any applicable laws;</p>
				<p>
					(b) there is any fraudulent, unlawful or illegal activity in connection with your access to and/or
					use of the Website and/or Service;
				</p>
				<p>
					(c) you provide any information that is untrue, inaccurate, not current or incomplete, or we have
					reasonable grounds to suspect that this is the case; and/or
				</p>
				<p>
					(d) we determine that you have engaged in unprofessional or unethical conduct or practices,

					we shall be entitled to take such action as we may deem appropriate without prejudice to our other
					rights and remedies hereunder or at law, including suspending, restricting or terminating your
					Account(s) and/or Subscription and/or revoking your License with immediate effect.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>13.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>

					Failure to comply with any provision of these Terms (including a breach of
					{' '}
					<BoldText>
						Clause
						5.3
					</BoldText>
					) constitutes a material breach. We will determine in our sole discretion whether a
					breach has occurred through your access to and/or use of the Service. You agree to allow us on
					reasonable notice to you, access to your place of business to conduct a physical inspection and
					examine your books and records in order to review and ascertain your compliance with these Terms.
					Your failure to allow us such access as requested within five (5) days may result in suspension or
					termination of your Account(s).


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>13.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Termination by You.</BoldText>
					You may request for the termination of your Account(s) at any time by selecting the relevant option
					under your Company Account settings to terminate your Account(s) or as otherwise instructed by
					following the instructions in our FAQ at
					{' '}
					<a
						href="https://qa.etpmarkets.com/faq"
					>
						https://qa.etpmarkets.com/faq
					</a>
					.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>13.5</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Effects of Termination.</BoldText>
				</p>
				<p>
					(a) The termination of your Account(s) shall not affect your liability or obligations under these
					Terms (including any payment of Fees already due to us from you). Upon termination of your
					Account(s), the License and all rights granted to you hereunder shall immediately cease and
					terminate, and you must immediately discontinue access to and use of the Service.
				</p>
				<p>
					{' '}
					(b) The termination of a Company Account will automatically terminate all End-User Accounts and
					Subscriptions under such Company Account.
				</p>
				<p>
					(c) You acknowledge and agree that even after the cancelling of your Subscription or termination of
					your Account(s), some information relating to your cancelled Subscription or terminated Account(s)
					may remain in our records, and still be retained and/or used by us as we deem necessary to comply
					with our legal obligations, resolve disputes, enforce our agreements, for evidentiary purposes and
					in accordance with our Privacy Policy. For the avoidance of doubt, we are under no obligation to you
					to retain, save, recover or provide you with any information and/or User Data under your cancelled
					Subscription or terminated Account(s), and shall be entitled to delete your full profile and such
					information and/or User Data upon cancellation or termination as we see fit in the exercise of our
					sole discretion.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>14</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>SUBMISSIONS</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>14.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Please do not send us any unsolicited ideas, suggestions, or proposals for which you expect to
					receive a reward, benefit or payment or which you want to own or claim rights in, other than on our
					specific request so as to prevent misunderstandings or disputes if we develop new functionalities
					and features for the Service which you perceive to be similar or even identical to your ideas,
					suggestions or proposals.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>14.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					However, if you do send us an unsolicited suggestion, idea, or proposal, or if you send, at our
					request, a comment, suggestion, idea or proposal to improve the Service (collectively,
					"
					<BoldText>Submissions</BoldText>
					"), such Submissions shall not be considered your confidential
					information or otherwise
					proprietary to you, and we shall be entitled to use the same in accordance with
					{' '}
					<BoldText>
						Clause
						14.4
					</BoldText>
					{' '}
					without
					any restriction or compensation to you. We shall have no obligations concerning any Submissions,
					contractual or otherwise (including any obligation to keep the Submissions confidential), and shall
					not be liable for the use and/or disclosure of any Submissions.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>14.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You represent and warrant that your Submissions do not violate the law and/or any person's rights
					(whether at law or under contract) nor consist of or contain software viruses, solicitation or any
					form of 'spam'. You may not use a false email address, impersonate any person or entity, or
					otherwise mislead us as to the origin of any Submissions. You warrant and represent that you own all
					of the rights to your Submissions and that its use thereof by us as in accordance with these Terms
					will not infringe the rights of any third party.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>14.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					14.4 By making any Submissions to us, you hereby grant us a non-exclusive, fully-paid, royalty-free,
					perpetual, irrevocable, and sub-licensable world-wide license to use, display, reproduce, modify,
					adapt, publish, broadcast and distribute your Submissions (in any form including digitally and in
					print, without any duty to account to you for any such use) for any purpose as we may require in our
					sole discretion, subject to our Privacy Policy.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>15</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>PUBLICITY</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>15.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You are permitted to state publicly that you are a user of the Service, save that any use of our
					trade marks is subject to our approval and must comply with any trade mark use guidelines issued by
					us.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>15.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You agree that we may use your Company's name and trade marks to identify you as a user of the
					Service on our Website or in promotional materials, or verbally to other parties.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>16</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>CONFIDENTIALITY OBLIGATIONS</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>16.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					cknowledge and agree that all proprietary rights in the Confidential Information belongs to us, and
					that you shall not use and/or disclose any Confidential Information except where expressly and
					specifically permitted by us in writing or under these Terms. Provided that this clause shall not
					extend to information which was rightfully in your possession prior to the effective date of our
					agreement with you formed by these Terms or your access to and/or use of the Service (whichever is
					earlier), or which is already public knowledge or becomes so at a future date, otherwise than as a
					result of your (or any of your employees', contractors', agents' and/or End-Users') breach of this
					clause. You shall ensure that all your employees, contractors, agents and/or End-Users are aware of
					and comply with the provisions of this Clause. The obligations contained in this
					{' '}
					<BoldText>
						Clause
						16
					</BoldText>
					{' '}
					shall survive the expiry or termination of these Terms.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>16.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>"Confidential Information"</BoldText>
					{' '}
					shall mean information:
				</p>
				<p>
					(a) disclosed to or obtained by you (and/or your employees, contractors, agents and/or End-Users) in
					connection with the Service (whether intentionally or due to our or any party's negligent act or
					omission);
				</p>
				<p>
					(b) all data and information relating to our business, operations and activities including our
					documents, business practices, object code, source code, management styles, day-to-day business
					operations, capabilities, systems, current and future strategies, marketing information, financial
					information, software, technologies, processes, procedures, methods and applications, or other
					aspects of our business; and
				</p>
				<p>
					(c) new product or service plans, customer identities, customer needs, marketing plans, design data,
					pricing and other trade secrets, discoveries, ideas, concepts, know-how, techniques, designs,
					specifications, data, computer programs and technical information.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>17</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>NOTICES</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>17.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Notices to you.</BoldText>
					{' '}
					You agree that we may provide notices to you by posting them
					on the Website, sending them to you through the Service, emailing them to the email address in
					your Account, or mailing them to the street address listed in your Account. Notices shall be
					considered to be received by you within twenty-four (24) hours of the time they are posted to
					the Website, sent to you through the Service, or emailed to you (unless we receive notice that
					the email was not delivered), or three (3) business days after it is sent by postal mail.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>17.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Notices to us.</BoldText>
					Unless otherwise stated in these Terms, you may contact us if you have any questions relating to the
					Website, Service, or the Terms by using any of the following methods:

				</p>
				<p>
					(a) For support issues, by email or telephone to the support contacts listed for the relevant time
					zones as specified at
					{' '}
					<a href="mailto:support@etpmarkets.com">support@etpmarkets.com</a>
					{' '}
					or
				</p>
				<p>
					(b) For service of legal notice which is required by contract or any law or regulation, by email in
					writing to
					{' '}
					<a href="mailto:admin@etpmarkets.com">admin@etpmarkets.com</a>
					. For the avoidance of
					doubt, communications sent to any other email address or to any support contacts or via in-Service
					communications will not constitute effective legal notice to us or any of our officers, employees,
					agents and/or representatives.
				</p>
				<p>
					(c) new product or service plans, customer identities, customer needs, marketing plans, design data,
					pricing and other trade secrets, discoveries, ideas, concepts, know-how, techniques, designs,
					specifications, data, computer programs and technical information.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>18</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>OUR RECORDS</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>18.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You acknowledge and agree that our records, whether stored in printed or electronic form, shall be
					binding on you for all purposes whatsoever and shall be conclusive evidence of the contents thereof
					(including of notices, communications, transactions, instructions, messages or operations made,
					performed, processed or effected through or in connection with the Service) and/or your liability to
					us. You hereby agree that all such records are admissible in evidence and that you shall not
					challenge or dispute the admissibility, reliability, accuracy or the authenticity of the contents of
					such records and hereby waive any of your rights (if any) to so object.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>19</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>DISPUTE RESOLUTION</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>19.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					If for whatever reason you are dissatisfied with the Service, you must send a written notice to us
					by email in order to provide us with the opportunity to attempt in good faith to resolve the issue
					with you. If we are unable to resolve the issue, you may pursue resolution of the issue through the
					methods stated herein.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>19.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					You hereby agree to submit to the non-exclusive jurisdiction of the Singapore courts for the
					purposes of any legal action or proceedings (including any summons, motion or petition, and any
					application to court for injunctive, equitable and other relief) brought by us against you. You
					agree that we shall be entitled at our election to refer any claim not exceeding Singapore Dollars
					Twenty Thousand (S$20,000) (or the highest limit prescribed by law) to the State Courts' Small
					Claims Tribunal. You agree at our request to execute all documents (including executing where
					applicable a Memorandum of Consent) and to perform all acts necessary to enable such dispute to be
					referred to the Small Claims Tribunal at our election.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>19.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					Subject to
					{' '}
					<BoldText>Clause 19.2</BoldText>
					{' '}
					above, you hereby agree that any dispute, controversy or
					claim that you may
					bring against us arising out of or in connection with these Terms and your agreement with us,
					including any question you may raise regarding its existence, validity, breach or termination, shall
					be referred to and finally resolved by arbitration in Singapore at the Singapore International
					Arbitration Centre ("
					<BoldText>SIAC</BoldText>
					") or at such other venue in Singapore as the parties
					may agree in writing
					in accordance with the SIAC Rules. In relation to such arbitration: (a) the law of the arbitration
					shall be the Singapore International Arbitration Act (Cap. 143A); (b) a tribunal shall consist of a
					single arbitrator to be appointed by the Chairman of the Singapore International Arbitration Centre
					(c) the language of the arbitration shall be English; (d) the parties hereto undertake to keep the
					arbitration proceedings and all information, pleadings, documents, evidence and all matters relating
					thereto confidential; and (e) no arbitration will be combined with another without the prior written
					consent of all parties to all affected arbitrations.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>19.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					These Terms and all matters relating to your access and use of the Website and/or Service shall be
					subject to, governed by and construed in accordance with the laws of Singapore.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>20</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>GENERAL TERMS</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>20.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Survival of Clauses.</BoldText>
					{' '}
					The following Clauses of these Terms and all other terms
					which by their nature should survive, shall survive the termination of your Account:
					<BoldText>
						Clauses
						10
					</BoldText>
					(Intellectual Property
					Rights),
					{' '}
					<BoldText>11</BoldText>
					{' '}
					(Indemnity),
					{' '}
					<BoldText>12</BoldText>
					{' '}
					(Disclaimers & Limitations of
					Liability),
					{' '}
					<BoldText>13</BoldText>
					(Suspension & Termination),
					{' '}
					<BoldText>17</BoldText>
					{' '}
					(Notices),
					{' '}
					<BoldText>18</BoldText>
					{' '}
					(Our Records)
					and
					{' '}
					<BoldText>19</BoldText>
					{' '}
					(Dispute Resolution).
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>20.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Severability.</BoldText>
					{' '}
					If any provision of these Terms is found to be illegal, void or
					unenforceable under any applicable law or if any court of competent jurisdiction in a final decision
					so determines, these Terms shall continue in force save that such provision shall be deemed to be
					deleted.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>20.3</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Force Majeure.</BoldText>
					{' '}
					We shall not be liable for any failure or delay in the
					performance of our obligations or the operation of the Website and/or Service or any unavailability
					thereof that is due, in whole or in part, directly or indirectly to an event, circumstance or
					failure which is beyond our reasonable control including (i) acts of God, nature, court or
					government; (ii) strikes, lockouts, industrial action or labour disputes; (iii) riots, civil unrest,
					war or threat of war, criminal or terrorist acts; (iv) epidemic, quarantine restrictions; (v)
					disruption to supply lines, and general failure or impossibility of the use of aircraft, shipping,
					aircraft, motor transport or other means of public or private transport, or political interference
					with the normal operations of any party; (vi) utility failures, power outages, failure or
					interruption in public or private telecommunication networks, communications channels or information
					systems used in the provision of services; (vii) acts or omissions of a third party service provider
					or party for whom we are not responsible; (viii) delay, failure or interruption in, or
					unavailability of, third party services and Linked Sites; and (ix) viruses, other malicious computer
					codes or the hacking by any party of the Website and/or Service, third party services or Linked
					Sites.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>20.4</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Assignment.</BoldText>
					{' '}
					We may assign, transfer or delegate any and all of our rights and
					obligations under these Terms in whole or in part to another person or entity at any time
					without your consent and without prior notice to you. You may not assign, transfer or delegate
					any of your rights or obligations or any part of them under these Terms to another person or
					entity without our prior written consent, and any unauthorized assignment, transfer or
					delegation by you is void.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>20.5</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Waiver.</BoldText>
					Failure or neglect by us to enforce at any time any of the provisions in these Terms shall not be
					construed or deemed to be a waiver of our rights hereunder, nor in any way affect the validity of
					the whole or any part of these Terms or prejudice our right to take subsequent action. No waiver of
					any rights or remedies by us shall be effective unless made in writing and signed by our authorized
					representative.


				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>20.6</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Relationship.</BoldText>
					Nothing in these Terms shall constitute or be deemed to constitute
					an agency, partnership or joint venture between us and you and neither party shall have any
					authority to bind the other in any way.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>20.7</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Third Party Rights.</BoldText>
					A person or entity who is not a party to the agreement
					formed by these Terms shall have no right under the Contracts (Rights of Third Parties) Act (Cap.
					53B) of Singapore to enforce any term herein, regardless of whether such person or entity has been
					identified by name, as a member of a class or as answering a particular description.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<SmallDivider />
		<FlexRow>
			<span><BoldText>21</BoldText></span>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<span><BoldText>AGREEMENT</BoldText></span>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>21.1</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Policies and Additional Terms.</BoldText>
					{' '}
					You shall comply with all operating rules,
					policies and procedures
					that may be published by us from time to time on the Website (collectively
					"
					<BoldText>Policies</BoldText>
					"). Other
					aspects of your access to and use of the Website and/or Service may also be governed by additional
					terms ("
					<BoldText>Additional Terms"</BoldText>
					). Without limiting the foregoing, the following
					Policies and Additional
					Terms are incorporated into these Terms by reference and provide additional terms and conditions
					related to specific services offered by us:
				</p>
				<p>
					(a) Privacy Policy at
					<a
						href="https://qa.etpmarkets.com/privacypolicy.html"
					>
						https://qa.etpmarkets.com/privacypolicy.html
					</a>
				</p>
				<p>
					(b) API Policy at
					<a
						href="https://qa.etpmarkets.com/apipolicy.html"
					>
						https://qa.etpmarkets.com/apipolicy.html
					</a>
				</p>
				<p>
					(c) FAQ at
					<a href="https://qa.etpmarkets.com/faq">https://qa.etpmarkets.com/faq</a>
					and
				</p>
				<p>
					(d) Billing and Payment Terms at
					<a
						href="https://www.chargebee.com/company/terms/"
					>
						https://www.chargebee.com/company/terms/
					</a>
				</p>
				<p>
					In the event of any conflict or inconsistency between these Terms and any other provision in the
					Policies and Additional Terms, these Terms shall prevail.
				</p>
			</StyledBulletSection>
		</FlexRow>
		<FlexRow>
			<div>21.2</div>
			<SmallVerticalDivider />
			<StyledBulletSection>
				<p>
					<BoldText>Entire Agreement.</BoldText>
					Together with the Policies and the Additional Terms, these Terms set forth
					the complete and exclusive statement of the agreement between you and us with respect to the Website
					and Service, which supersedes any proposal or prior agreement oral or written, and any other
					communications between you and us relating to the subject matter of these Terms.
				</p>
			</StyledBulletSection>
		</FlexRow>
	</Wrapper>
);
const StyledBulletSection = styled.div`
	display: inline-block;
`;
export default TermsOfService;
