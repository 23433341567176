import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

// Constants and data
import { TextBig } from '../../../Css/Css';

// Atoms
import { FlexColumn, FlexRow } from '../../../Atoms/Flex';
import { PrimaryButton, SelectButton } from '../../../Atoms/Button';
import { MediumVerticalDivider, BaseDivider } from '../../../Atoms/Divider';

// Molecules
import SearchField from '../../../Molecules/Input/SearchField/SearchField';

// Organisms
import TreeTable from '../../TreeTable';
import { COLORS } from '../../../../lib/constants/Style';

const Wrapper = styled.div`
  & > h2 {
		margin-bottom: 26px;
	}
	display: flex;
    flex-direction: column;
    height: 100%;
`;

const Title = styled.div`
  ${TextBig};
  color: ${COLORS.lightBlack};
  text-align: left;
`;

const SearchFiledWrapper = styled.div`
	width: 420px;
`;

const ExchangeDictionaryModal = ({ data, title, openReferencedItem }) => {
	const [search, setSearch] = useState('');
	const [openAllStatus, setOpenAllStatus] = useState(false);
	const [changeOpenStatus, setChangeOpenStatus] = useState(false);
	const [tableData, setTableData] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState('all');

	useEffect(() => {
		if (data) {
			setTableData([...data]);
		}
		return () => {
			setSearch('');
			setOpenAllStatus(false);
			setSelectedFilter('all');
		};
	}, [data]);

	const handleOpenAllStatus = (status) => {
		setChangeOpenStatus(!changeOpenStatus);
		setOpenAllStatus(status);
	};

	const handleSearch = (value) => {
		setSearch(value);
		return value === '' ? handleOpenAllStatus(false) : handleOpenAllStatus(true);
	};

	const handleSelectFilter = (type) => {
		const immutableTable = fromJS(data);
		if (type === 'customOnly') {
			handleOpenAllStatus(true);
			setSelectedFilter('custom');
			const filteredTable = immutableTable.filter(item => item.get(['id' > 5000]));
			const filterTableChildren = filteredTable.map((row) => {
				const filteredChildren = row.get('children')?.filter(children => children.get('id') > 5000);
				const rowWithChildren = row.set('children', filteredChildren);
				return rowWithChildren;
			});
			return setTableData([...filterTableChildren.toJS()]);
		}
		if (type === 'requiredOnly') {
			handleOpenAllStatus(true);
			setSelectedFilter('required');
			const filteredTable = immutableTable.filter(item => item.getIn(['metaData', 'required']));
			const filterTableChildren = filteredTable.map((row) => {
				const filteredChildren = row.get('children')?.filter(children => children.getIn(['metaData', 'required']));
				const rowWithChildren = row.set('children', filteredChildren);
				return rowWithChildren;
			});
			return setTableData([...filterTableChildren.toJS()]);
		}
		setSelectedFilter('all');
		return setTableData(data);
	};

	return (
		<Wrapper>
			<FlexColumn>
				<Title>
					{`${title} Structure`}
				</Title>
				<BaseDivider />
				<FlexRow justifyContent="space-between">
					<SearchFiledWrapper>
						<SearchField
							placeholder="Search"
							onChange={handleSearch}
							value={search}
						/>
					</SearchFiledWrapper>
					<FlexRow>
						<SelectButton active={selectedFilter === 'all'} onClick={() => handleSelectFilter()}>
								All
						</SelectButton>
						<MediumVerticalDivider />
						<SelectButton active={selectedFilter === 'required'} onClick={() => handleSelectFilter('requiredOnly')}>
								Required Only
						</SelectButton>
						<MediumVerticalDivider />
						<SelectButton active={selectedFilter === 'custom'} onClick={() => handleSelectFilter('customOnly')}>
								Custom Only
						</SelectButton>
					</FlexRow>
					<FlexRow>
						<PrimaryButton onClick={() => handleOpenAllStatus(false)}>
									Collapse all
						</PrimaryButton>
						<MediumVerticalDivider />
						<PrimaryButton onClick={() => handleOpenAllStatus(true)}>
									Expand all
						</PrimaryButton>
					</FlexRow>
				</FlexRow>
				<BaseDivider />
			</FlexColumn>
			{tableData?.length > 0 && (
				<TreeTable
					header={['Name', 'Tag', 'Length', 'Repeating', 'Required']}
					data={tableData}
					openAllStatus={openAllStatus}
					changeOpenStatus={changeOpenStatus}
					searchValue={search}
					openFieldModal={openReferencedItem}
				/>
			)}
		</Wrapper>
	);
};

ExchangeDictionaryModal.defaultProps = {
	data: null,
	title: '',
	openReferencedItem: () => {},
};

ExchangeDictionaryModal.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.shape({
			content: PropTypes.shape({}),
			length: PropTypes.func,
		})),
		PropTypes.shape({
			content: PropTypes.shape({}),
			length: PropTypes.func,
			title: PropTypes.string,
		}),
	]),
	title: PropTypes.string,
	openReferencedItem: PropTypes.func,
};

export default ExchangeDictionaryModal;
