import styled from 'styled-components';
import { COLORS } from '../../../../lib/constants/Style';

export const CheckBoxWrapper = styled.div`
	align-items: center;
	position: relative;
	display: inline-flex;
	margin-right: 5px;
`;

export const CheckBoxLabel = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const BaseCheckBox = styled.input`
	height: 16px;
	width: 16px;
	margin: 0px;
	appearance: none;
	position: relative;
	cursor: pointer;

	&:after {
		box-sizing: border-box;
		border-radius: 3px;
		position: absolute;
		background-color: #ffffff;
		content: ' ';
		height: 16px;
		width: 16px;
		border: 2px solid ${COLORS.grey};
	}

	&:checked:after {
		background-color: orange;
		border: 1px solid orange;
		content: '';
	}

	&:focus {
		outline: none;
	}
`;

export const FontAwesomeWrapper = styled.div`
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
`;
